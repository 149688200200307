import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DesitionBoxComponent } from 'src/app/modals/desition-box/desition-box.component';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { Beneficiaria } from 'src/app/model/beneficiaria';
import { Paginador } from 'src/app/model/configuracion';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CsvService } from 'src/app/services/csv.service';
import { ParametrosService } from 'src/app/services/parametros.service';
import { SesionService } from 'src/app/services/sesion.service';

const ELEMENT_DATA: Beneficiaria[] = [];

@Component({
  selector: 'app-gestor-beneficiaria',
  templateUrl: './gestor-beneficiaria.component.html',
  styleUrls: ['./gestor-beneficiaria.component.scss']
})
export class GestorBeneficiariaComponent implements OnInit {
  phpPath:string;
  acciones:any=[];
  tieneAccionCrear = false;
  tieneAccionEliminar = false;
  tieneAccionEditar = false;
  isLoading = false;
  paginador:Paginador;
  displayedColumns: string[] = ['position','nombre', 'estatus','etapa', 'motivo_ingreso', 'discapacidad','canalizador'];
  dataSource = new MatTableDataSource<Beneficiaria>(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  canalizadores:any;
  motivosDeIngreso:any;
  isEvaluaciones:boolean = false;
  constructor(
    public _csvService:CsvService,
    public _activatedRoute:ActivatedRoute,
    public dialog: MatDialog,
    private _beneficiariaService:BeneficiariaService,
    private _config:ConfigurationService,
    private _parametros:ParametrosService,
    private _sesion:SesionService,
    private _conf:ConfigurationService
    ) { 
      this.phpPath=_conf.getConfiguration().embedPhpPath;
    this.paginador = this._config.getConfiguration().ui.paginador;
    this._activatedRoute.url.subscribe(url=>{
      this.isEvaluaciones = url[0].path.indexOf("evaluaciones") >-1;

      if(this.isEvaluaciones){
        this.displayedColumns.push('habilidades');
      }else{
        this.displayedColumns.push('mas');
      }
    });
    this.tieneAccionEliminar = this.consultarAccionUsuario("/beneficiarias/delete");
    console.log("this.tieneAccionEliminar: ", this.tieneAccionEliminar);
    this.tieneAccionEditar = this.consultarAccionUsuario("/beneficiarias/datos-generales");
    console.log("this.tieneAccionEditar: ", this.tieneAccionEditar);
    this.tieneAccionCrear = this.consultarAccionUsuario("/beneficiarias/datos-generales");
    console.log("this.tieneAccionCrear: ", this.tieneAccionCrear);
  }

  formulario = new UntypedFormGroup({
    buscar:new UntypedFormControl("",[]),
    estatus:new UntypedFormControl("",[])
  })

  consultarAccionUsuario(urlRecibida){
    if(this._sesion.getSession().getUsuario().nombre!=undefined){
      this.acciones = this._sesion.getSession().getAcciones();
      if(this.acciones.filter(x => x.url == urlRecibida)[0]){
        return true;        
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  corregirOrtografia(palabra){
    if(palabra == "Historico"){
      palabra = "Histórico";
    }
    return palabra.replace("ion","ión");
  }

  public laravelapi: any;
  ngOnInit(): void {
    this.laravelapi = this._beneficiariaService.getLaravelApi();
    this.dataSource.paginator = this.paginator;
    console.info("Entrando a beneficiarias");
    this.getEstatus();
    this.getCanalizadores();
    this.getMotivosDeIngreso();
    this.buscar(1,this.paginador.pageSize);
  }

  sendForm(){
    this.buscar(this.paginator.pageIndex+1,this.paginator.pageSize);
  }
  buscar(page:number,size:number){
    this.dataSource = new MatTableDataSource<Beneficiaria>([]);
    this.isLoading = true;
    this._beneficiariaService.findAll(this.formulario.controls["buscar"].value,this.formulario.controls["estatus"].value,page,size).subscribe(result=>{
      this.isLoading = false;
      console.info(result);
      this.paginator.length = result.data.count;
      this.getDiscapacidades(result.data.registros);
      
    },()=>{
      this.isLoading = false;
    });
  }
  getDiscapacidades(array:any){
    let ids = "";
    for(let i=0;i<array.length;i++){
      if(i+1==array.length){
        ids += array[i].id;
      }else{
        ids += array[i].id+",";
      }
    }
    this._beneficiariaService.findDiscapacidades(ids).subscribe(result=>{
      for(let i =0; i<result.data.length;i++){
        for(let x = 0; x<array.length;x++){
          if(array[x].id == result.data[i].id_beneficiaria && result.data[i].des_tipo_catalogo.length>0){
            array[x].idDiscapacidad = result.data[i].des_tipo_catalogo;
          }
        }
      }

      this.dataSource = new MatTableDataSource<Beneficiaria>(array);

    })
  }
  pager(event){
    this.paginator.pageSize = event.pageSize;
    this.paginator.pageIndex = event.pageIndex;
    this.buscar(event.pageIndex + 1 ,event.pageSize);
  }

  confirmaEliminacion(id:number): void {
    const dialogRef = this.dialog.open(DesitionBoxComponent, {
      data: {titulo: "Eliminando expediente", mensaje: "Está eliminando un expediente, la información no podrá ser recuperada, ¿Desea continuar?", desicion:true}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if(result){
        this.eliminar(id);
      }
    });
  }
  eliminar(id:number){
    this.isLoading = true;
    this._beneficiariaService.deleteById(id).subscribe(result=>{
      this.isLoading = false;
      this.dialog.open(MessageBoxComponent, {
        data: {titulo: "Expediente eliminado", mensaje: "El expediente ha sido eliminado", desicion:true}
      });
      this.buscar(1,this.paginator.pageSize);
    },()=>{
      this.isLoading = false;
    });
  }
  estados:any[];
  getEstatus(){
    this._parametros.getParametros("1").subscribe(result=>{
      if(result.codigo = "PRM000"){
        this.estados = result.data.parametros;
      }
    },()=>{
      this.estados = [];
    })
  }
  getCanalizadores(){
    //param canaliza
    this._parametros.getParametros("2").subscribe(result=>{
      this.canalizadores = result.data.parametros;
    });
  }
  getMotivosDeIngreso(){
    this._parametros.getParametros("3").subscribe(result=>{
      this.motivosDeIngreso = result.data.parametros;
    });
  }
  getEstatusById(id:string){
    for(let i=0; i<this.estados.length; i++){
      if(this.estados[i].idParametro == id ){
        return this.estados[i].valor;
      }
    }
    return "N/D";
  }

  getCanalizadorById(id){
    for(let i=0; i<this.canalizadores.length; i++){
      if(this.canalizadores[i].idParametro == id ){
        return this.canalizadores[i].valor;
      }
    }
    return "N/D";
  }

  getMotivoIngresoById(id){
    for(let i=0; i<this.motivosDeIngreso.length; i++){
      if(this.motivosDeIngreso[i].idParametro == id ){
        return this.motivosDeIngreso[i].valor;
      }
    }
    return "N/D";
  }
  exportarCSV(){
    this.isLoading = true;
    this._beneficiariaService.exportarCSV().subscribe(result=>{
      this.isLoading = false;
      console.info(result);
      this._csvService.downloadFile(result.data,"beneficiarias-reporte-"+new Date().toLocaleString()+"");
    },()=>{
      this.isLoading = false;
    });
  }
  
  filtroEstatus(etapa){
    let etapas = this._config.getConfiguration()["etapasActivas"];
    if(etapas.indexOf(etapa.toUpperCase())>-1){
      return "ACTIVO";
    }else{
      return "NO ACTIVO";
    }
  }

}

export function checkExistRow(rows){
  if (!rows || !rows.length) {
    return;
  }
}

export function checkExistHeader(headers, keys){
  if (headers) {
    return headers;
  } else {
    return keys;
  }
}


export function assignValueCell(row, k){
  if (row[k] === null || row[k] === undefined) {
    return '';
  } else {
    return row[k];
  }
}

export const exportToCsv = (filename: string, rows: object[], headers?: string[]): void => {
  
  checkExistRow(rows);
  // if (!rows || !rows.length) {
  //     return;
  // }
  const separator: string = ",";

  const keys: string[]  = Object.keys(rows[0]);

  // let columHearders: string[];
  let columHearders = checkExistHeader(headers,keys);

  // if (headers) {
  //     columHearders = headers;
  // } else {
  //     columHearders = keys;
  // }

  const csvContent =
      "sep=,\n" +
      columHearders.join(separator) +
      '\n' +
      rows.map(row => {
          return keys.map(k => {
            let cell = assignValueCell(row, k);
              // let cell = row[k] === null || row[k] === undefined ? '' : row[k];

              cell = cell instanceof Date 
                  ? cell.toLocaleString()
                  : cell.toString().replace(/"/g, '""');

              if (navigator.msSaveBlob) {
                  cell = cell.replace(/[^\x20-\x7F]/g, ""); //remove non-ascii characters
              }
              if (cell.search(/("|,|\n)/g) >= 0) {
                  cell = `"${cell}"`;
              }
              return cell;
          }).join(separator);
      }).join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // In case of IE 10+
      navigator.msSaveBlob(blob, filename);
  } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
  }
}
