import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DesitionBoxComponent } from 'src/app/modals/desition-box/desition-box.component';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { Usuario } from 'src/app/model/usuario';
import { ParametrosService } from 'src/app/services/parametros.service';
import { PerfilesService } from 'src/app/services/perfiles.service';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-abc-usuarios',
  templateUrl: './abc-usuarios.component.html',
  styleUrls: ['./abc-usuarios.component.scss']
})
export class AbcUsuariosComponent implements OnInit {
  isLoading = false;
  regexPassword = "^(?!(?=(?:.*\d){1}))(?!(?=(?:.*[A-Z]){1}))\S{10,20}$";
  esModificacion = false;
  perfiles = [];
  selectedUser:Usuario;
  leyenda_password = "Contraseña mínima de 10 caracteres, 1 letra mayúscula y un número";
  constructor(
    private router:Router,
    private _usuariosService:UsuariosService,
    private _perfilesService:PerfilesService,
    private activatedRoute:ActivatedRoute,
    private dialog:MatDialog) {
   }
  formulario = new UntypedFormGroup({
    correo : new UntypedFormControl("",[Validators.required, Validators.maxLength(100),Validators.email]),
    password : new UntypedFormControl("",[Validators.maxLength(100),Validators.minLength(10)]),
    password2 : new UntypedFormControl("",[Validators.maxLength(100),Validators.minLength(10)]),
    nombre : new UntypedFormControl("",[Validators.required, Validators.maxLength(100)]),
    apellidoPaterno : new UntypedFormControl("",[Validators.required, Validators.maxLength(100)]),
    apellidoMaterno : new UntypedFormControl("",[Validators.required, Validators.maxLength(100)]),
    puesto : new UntypedFormControl("",[Validators.required, Validators.maxLength(100)]),
    telefono : new UntypedFormControl("",[]),
    username : new UntypedFormControl("",[Validators.required, Validators.maxLength(100)]),
    idPerfil : new UntypedFormControl("",[Validators.required]),
    cedula : new UntypedFormControl("",[Validators.required, Validators.maxLength(50)]),
    idUsuario: new UntypedFormControl("",[])
  })
  setUser(){
    
    this.form.apellidoMaterno.setValue(this.selectedUser.apellidoMaterno);
    this.form.apellidoPaterno.setValue(this.selectedUser.apellidoPaterno);
    this.form.correo.setValue(this.selectedUser.correo);
    //this.form.idEmpresa.setValue(this.selectedUser.idEmpresa);
    //this.form.apellidoMaterno.setValue(this.selectedUser.idUsuario);
    this.form.nombre.setValue(this.selectedUser.nombre);
    this.form.puesto.setValue(this.selectedUser.puesto);
    //this.form.telefono.setValue(this.selectedUser.telefono);
    this.form.username.setValue(this.selectedUser.username);
    this.form.cedula.setValue(this.selectedUser.cedula);
    this.form.idPerfil.setValue(this.selectedUser.idPerfil);
    this.form.cedula.setValue(this.selectedUser.cedula);
  }
  get form(){
    return this.formulario.controls;
  }
  ngOnInit(): void {
    this.getLicencias();
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info("Parametros");
      console.info(params["id"]);
      if(params["id"]){
        this.esModificacion=true;
        this.consultar(params["id"]);
        this.form.username.disable();
        this.form.idUsuario.setValue(params["id"]);
      }else{
        this.esModificacion=false;
        this.form.password.setValidators([Validators.required,Validators.maxLength(100),Validators.minLength(10)]);
        this.form.password2.setValidators([Validators.required,Validators.maxLength(100),Validators.minLength(10)]);
      }
    });
  }

  getLicencias(){
    this._perfilesService.getCatalogoPerfiles().subscribe(result=>{
      if(result.codigo=="PRF000"){
        this.perfiles = result.data;
      }
    })
  }
  sendForm(){
    if(this.formulario.valid && this.contraseñasIguales){
      const dialogRef = this.dialog.open(DesitionBoxComponent,{
        data: {titulo: "¿La información es correcta?", mensaje: "Si la información es correcta, oprima la opción aceptar para continuar con la operación", desicion:true}
      });

      dialogRef.afterClosed().subscribe(desition => {
        //console.log('The dialog was closed');
        if(desition){
          this.isLoading = true;
          this._usuariosService.save(this.formulario.getRawValue()).subscribe(result=>{
            if(result.codigo=="USR000"){
              this.isLoading = false;
              this.router.navigate(["/administrador/usuarios"]);
              this.dialog.open(MessageBoxComponent,{
                data:{titulo:"El usuario ha sido creado/modificado con éxito",mensaje:"El usuario ya puede ingresar al sistema"}
              })
            }else{
              this.isLoading = false;
            }
          })
        }
      });
      
    }else{
      console.info(this.formulario.valid);
    }
  }
  contraseñasIguales(){
    return this.form.password.value == this.form.password2.value;
  }

  consultar(id:number){
    this.isLoading=true;
    this._usuariosService.findById(id).subscribe(result=>{
      this.isLoading =false
      if(result.codigo =="USR000"){
        this.selectedUser = result.data[0];
      }
      this.setUser();
    })
  }
}
