
<div class="card-container" id="top">
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                <div class="row">
                    <h1 *ngIf="form2.value.calendario == 1">Calendario escolizado</h1>
                    <h1 *ngIf="form2.value.calendario == 2">Calendario sin escolarizar</h1>
                </div>
                
                <!--<div class="row p-3">
                    Programación de evento de {{titulo}}
                </div>-->
            </mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
        <form novalidate [formGroup]="form2" autocomplete="off">
            
            
            <div class="row text-align-right">

                <div class="col">
                    
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Tipo de calendario</mat-label>
                            <mat-select formControlName="calendario" (ngModelChange)="getConsultarCalendarioBusqueda(1)" >
                                <mat-option [value]="1">Calendario escolarizada</mat-option>
                                <mat-option [value]="2">Calendario sin escolarizar</mat-option>
                            </mat-select>
                        </mat-form-field>
                   
                </div>

                <div class="col">
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>Tipo de búsqueda</mat-label>
                        <mat-select formControlName="tipo"  (ngModelChange)="getConsultarCalendarioBusqueda(1)">
                            <mat-option [value]="1">Configuración</mat-option>
                            <mat-option [value]="2">Eventos</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <div class="col">
                    
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>Tipo de configuración</mat-label>
                        <mat-select formControlName="numero_configuracion"  (ngModelChange)="getConsultarCalendarioBusqueda(1)">
                            <mat-option [value]="1">Estructura</mat-option>
                            <mat-option [value]="2">Individual</mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                </div>
                    
                
                
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                   
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Mex</mat-label>
                            <mat-select formControlName="numero_mes" (ngModelChange)="getConsultarCalendarioBusqueda(1)">
                                <mat-option [value]="1">Enero</mat-option>
                                <mat-option [value]="2">Febrero</mat-option>
                                <mat-option [value]="3">Marzo</mat-option>
                                <mat-option [value]="4">Abril</mat-option>
                                <mat-option [value]="5">Mayo</mat-option>
                                <mat-option [value]="6">Junio</mat-option>
                                <mat-option [value]="7">Julio</mat-option>
                                <mat-option [value]="8">Agosto</mat-option>
                                <mat-option [value]="9">Septiembre</mat-option>
                                <mat-option [value]="10">Octubre</mat-option>
                                <mat-option [value]="11">Noviembre</mat-option>
                                <mat-option [value]="12">Diciembre</mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                    
                </div>
                <div class="col">
                    <div class="row">
                
                        <div class="col">
                            <button [routerLink]="['/calendario']" [queryParams]="{tipo: tipo_general}" class="flat" color="primary" mat-flat-button>
                                Calendario
                            </button>
        
                            <!--<button (click)="modalCal(1, -1, 'registrar')" class="flat" color="primary" mat-flat-button>
                                Nuevo evento
                            </button>
                            <button (click)="modalCal(2, -1, 'registrar')" class="flat" color="primary" mat-flat-button>
                                Nuevo evento
                            </button>-->
                            &nbsp;
                            <button mat-flat-button class="flat" color="primary" [matMenuTriggerFor]="menu">Nuevo evento</button>
                            <mat-menu #menu="matMenu">
                            <button mat-menu-item  (click)="modalCal(1, -1, 'registrar', null)">Estructura</button>
                            <button mat-menu-item  (click)="modalCal(2, -1, 'registrar', null)">Individual</button>
                            </mat-menu>
        
                        </div>
                        
                    </div>
                </div>
                <!--<div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                    <button mat-mini-fab (click)="getConsultarCalendarioBusqueda(1)">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>-->

                <!--<div class="col">
                    <button class="flat" color="primary" mat-flat-button (click)="postNuevaBitacora(form.value.numero_periodo)">
                        Nueva bitacora
                    </button>
                </div>-->
                <!--<div class="col">
                    <a  color="accent" class="flat" mat-flat-button  href="{{laravelapi}}/ExcelBitacoras" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon>&nbsp;Registro en Excel
                    </a>
                </div>-->
                
                
                
                <!--<a  color="accent" class="flat" mat-flat-button  href="{{laravelapi}}/ReporteExcelTerapias" target="_blank" rel=noopener>
                    <mat-icon>list_alt</mat-icon>&nbsp;Registro en Excel
                </a>-->
                
                <!--<button class="flat" color="primary" mat-flat-button>Comparativo</button>
                    <button class="flat" color="primary" mat-flat-button>Gráfica (Pizza)</button>-->
            </div>
        </form>

                <!-- tabla -->
                <div class="row mt-3 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="id" >
                            <th id="id" mat-header-cell *matHeaderCellDef style="width: 120px !important"> ID </th>
                            <td mat-cell *matCellDef="let element">

                                <span *ngIf="element?.id_configuracion_calendario_pk">{{element?.id_configuracion_calendario_pk}}</span>
                                <span *ngIf="element?.id_control_fecha_pk">{{element?.id_control_fecha_pk}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="calendario">
                            <th id="calendario" mat-header-cell *matHeaderCellDef  style="width: 120px !important"> CALENDARIO </th>
                            <td mat-cell *matCellDef="let element">
                                
                                <!--    {{element?.nombre_actividad}}-->
                                <span *ngIf="element?.tipo_calendario?.tipo_calendario">{{element?.tipo_calendario?.tipo_calendario}}</span>
                                <span *ngIf="element?.configuracion_calendario?.tipo_calendario?.tipo_calendario">{{element?.configuracion_calendario?.tipo_calendario?.tipo_calendario}}</span>

                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="estructura">
                            <th id="estructura" mat-header-cell *matHeaderCellDef> ESTRUCTURA </th>
                            <td mat-cell *matCellDef="let element">

                                <span *ngIf="element?.estructura_tipo?.estructura">{{element?.estructura_tipo?.estructura}}</span>
                                <span *ngIf="element?.configuracion_calendario?.estructura_tipo?.estructura">{{element?.configuracion_calendario?.estructura_tipo?.estructura}}</span>

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="nombre">
                            <th id="nombre" mat-header-cell *matHeaderCellDef> NOMBRE </th>
                            <td mat-cell *matCellDef="let element">{{element?.nombre_actividad}}

                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="fi">
                            <th id="fi" mat-header-cell *matHeaderCellDef> FECHA INICIO </th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{element?.fecha_inicio}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ft">
                            <th id="ft" mat-header-cell *matHeaderCellDef> FECHA TERMINO </th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{element?.fecha_fin}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="i">
                            <th id="i" mat-header-cell *matHeaderCellDef> INICIO </th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{element?.hora_inicio}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="t">
                            <th id="t" mat-header-cell *matHeaderCellDef> TERMINO </th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{element?.hora_fin}}</span>
                            </td>
                        </ng-container>




                        <ng-container matColumnDef="consultar">
                            <th id="consultar" mat-header-cell *matHeaderCellDef> <span class="d-flex justify-content-center">CONSULTAR </span> </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="d-flex justify-content-center">
                                    <button *ngIf="form2.value.tipo == 1"  type="button" mat-mini-fab color="primary" >
                                        <mat-icon class="material-icons" (click)="modalCal(element?.id_tipo_calendario_fk , element?.id_configuracion_calendario_pk, 'modificar', null)">
                                            open_in_new
                                        </mat-icon>
                                    </button>

                                    <button *ngIf="form2.value.tipo == 2"  type="button" mat-mini-fab color="primary" >
                                        <mat-icon class="material-icons" (click)="modalCal(element?.configuracion_calendario?.tipo_configuracion, element?.id_control_fecha_pk, 'modificar', {modificar_control: true})">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                                </span>
                                
                            </td>
                        </ng-container>



                        <!-- fin contenido -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- fin tabla -->

                
                <!--<div class="mt-5 d-flex justify-content-center">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos']" >
                        Regresar
                    </button>
                </div>-->

                
            </mat-card-content>
            <mat-card-actions align="start">
                <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"  aria-label="Selecione una pagina">
                </mat-paginator>
            </mat-card-actions>
        </mat-card>
    </div>
