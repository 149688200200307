//General
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { SesionService } from 'src/app/services/sesion.service';
import * as moment from 'moment';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { ControlVacunacionService } from 'src/app/services/control-vacunacion.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios

@Component({
  selector: 'app-ac-control-vacunacion',
  templateUrl: './ac-control-vacunacion.component.html',
  styleUrls: ['./ac-control-vacunacion.component.scss'],
  providers: [ ControlVacunacionService ]
})
export class AcControlVacunacionComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    numero_beneficiaria: new UntypedFormControl(-1, []),

    vacuna: new UntypedFormControl('', [Validators.required]),
    edad_aplicacion: new UntypedFormControl('', [Validators.required]),
    comentarios: new UntypedFormControl('', [Validators.required]),
    fecha_aplicacion: new UntypedFormControl('', [Validators.required]),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id: any;
  public laravelapiStorage = this._ControlVacunacionService.getLaravelApiStorage();
  public laravelapi = this._ControlVacunacionService.getLaravelApi();
  public t: any;
  public data: any;
  public acciones:any=[];
  public f_c: any;
  public f_n: any;

  public tieneAccionRegistrar: boolean = false;
  public tieneAccionEliminar: boolean = false;
  public tieneAccionModificar: boolean = false;


  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _ControlVacunacionService: ControlVacunacionService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
    private _sesion:SesionService,
  ) { 
    /*modificar*/
    this.form.markAllAsTouched();
    this._route.queryParams.subscribe(
      params => {
        this.id = +params['id'];
        this.f_c = params['f_c'];
        if(this.id){
          console.log('IDDDD: ' + this.id);
          console.log('Nacimiento: ' + this.f_c);
          this.form.patchValue({ numero_beneficiaria: this.id});
          this.getConsultarCartilla(this.id);
          
          //this.getDatosClinicosForm();

          
          

        }
      });

    this.tieneAccionEliminar = this.consultarAccionUsuario("/medico/expedientes-medicos/control-vacunacion/eliminar");
    console.log("this.tieneAccionEliminar: ", this.tieneAccionEliminar);
    this.tieneAccionModificar = this.consultarAccionUsuario("/medico/expedientes-medicos/control-vacunacion/modificar");
    console.log("this.tieneAccionModificar: ", this.tieneAccionModificar);
    this.tieneAccionRegistrar = this.consultarAccionUsuario("/medico/expedientes-medicos/control-vacunacion/registrar");
    console.log("this.tieneAccionRegistrar: ", this.tieneAccionRegistrar);
    /*fin modificar*/
  }

  public term: string = '';
  filteredData: any = [];
  filterData() {
    // Filtra la lista completa de elementos según el término de búsqueda
    this.filteredData = this.data?.vacunas.filter((item: any) =>
      item.vacuna.toLowerCase().includes(this.term.toLowerCase())
    );
  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[4].split("?")[0];
    console.log(this.accion);


   


  
  
  }

  public consultarAccionUsuario(urlRecibida){
    if(this._sesion.getSession().getUsuario().nombre!=undefined){
      this.acciones = this._sesion.getSession().getAcciones();
      if(this.acciones.filter(x => x.url == urlRecibida)[0]){
        return true;        
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  public getConsultarCartilla(id: number){
    console.log('asjsajsdhas');
    this.isLoading = true;
    this._ControlVacunacionService.getConsultarCartilla(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.data = response.data;
          

          
          


          /*setTimeout(() => {
            this.data.vacunas.sort((a, b) => {
              // Convertir los valores de edad_aplicacion a un número para poder compararlos
              console.log(a);
              console.log(b);
              const ageA = Number(a.edad_aplicacion.split(',')[0].split(' ')[0]);
              const ageB = Number(b.edad_aplicacion.split(',')[0].split(' ')[0]);
              console.log(ageA, ageB)
          
              // Devolver un número negativo si a es menor que b, 0 si son iguales,
              // o un número positivo si a es mayor que b
              return ageB - ageA;
            });
          }, 1000); // se ejecutara despues de 1 segundo*/
          

          

          console.log(this.data.vacunas);
          this.filteredData = this.data?.vacunas;


        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }



  public postRegistrarVacuna(){
    console.log(this.form.value);
    this.isLoading = true;
    this._ControlVacunacionService.postRegistrarVacuna(this.form.value).subscribe(
      response => {console.log(response);
        this.clearForm();
        this.r(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public clearForm(){
    this.form.patchValue({
      vacuna: '',
      edad_aplicacion: '',
      comentarios: '',
      fecha_aplicacion: '',
    });
  }

  public postEliminarVacuna(id: number){
    console.log(this.form.value);
    this.isLoading = true;
    this._ControlVacunacionService.postEliminarVacuna({numero_vacuna: id}).subscribe(
      response => {console.log(response);
        this.r(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postModificarVacuna(i: any){
    console.log(this.form.value);
    this.isLoading = true;
    this.data.vacunas[i].numero_beneficiaria = this.id;
    this.data.vacunas[i].numero_vacuna = this.data.vacunas[i].id_vacuna_pk;
    this.data.vacunas[i].edad_aplicacion = this.calcular(this.f_c, this.data.vacunas[i].fecha_aplicacion ,i);
    this._ControlVacunacionService.postModificarVacuna(this.data.vacunas[i]).subscribe(
      response => {console.log(response);
        this.r(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postRegistrarDocCartilla(event: any): any{

    
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      console.log(file.type);
      var ext = event.target.files[0].name.split('.')[1];
      console.log(ext);
      if (file.type.startsWith('application/pdf')) {//text/xml
        console.log(this.id);
        const formData = new FormData();
        formData.set('documento', file);
        formData.set('numero_beneficiaria',  this.id.toString());
        this.isLoading = true;
        this._ControlVacunacionService.postRegistrarDocCartilla(formData).subscribe(
          response => {console.log(response);
            this.r(response);
            
          },
          error => {console.log(<any>error);
            this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
            this.isLoading = false;
        });


      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato', icon:'error'}});
      }
    }  
  }


  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }

  public r(response: any){
    this.isLoading = false;
            if(response.estado == 200){
              this.getConsultarCartilla(this.id);
              this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            }else{
              this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
            }
  }


 /* public calcular(fechaUsuario: any, fechaAplicacion: any, i: number){
    //El siguiente fragmento de codigo lo uso para igualar la fecha de nacimiento con la fecha de hoy del usuario
    var a = new Date(fechaUsuario);
    var b = new Date(fechaAplicacion);
    //console.log("Fecha de Nacimiento: "+a);
    //console.log(b);

    const diferenciaMs = b.getTime() - a.getTime();
      const anios = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24 * 365));
      const meses = Math.floor((diferenciaMs % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
      const dias = Math.floor((diferenciaMs % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
      return `${anios} años, ${meses} meses, ${dias} días`;
  }*/

  public calcular(fechaUsuario: any, fechaAplicacion: any, i: number){

    let edad:Number = moment(fechaAplicacion).diff(fechaUsuario, 'years',true);
    let laedad:String = edad.toString();

    let años = laedad.split(".")[0];
    let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];

    //let fu = moment(fechaUsuario);
    //let fa = moment(fechaAplicacion);

    //let dias = fa.diff(fu,'days');

    if (meses == 'NaN') {
      meses = "0";     
    }

    let detalleEdad = años + " años "+ meses + " meses ";
    return detalleEdad;
  }

  public getFI(){
    if(localStorage.getItem('fi') != '-'){
      const fechaTexto = localStorage.getItem('fi');
      var partesFecha = fechaTexto.split('/');
      const fecha = new Date(+partesFecha[2], +partesFecha[1] - 1, +partesFecha[0]);
      const anio = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      const dia = fecha.getDate();

      // Formatea la nueva fecha en el formato 'año-mes-dia'
      const nuevaFechaTexto = `${anio}-${mes < 10 ? '0' + mes : mes}-${dia < 10 ? '0' + dia : dia}`;
      console.log("Fecha Nacimiento: "+nuevaFechaTexto); // mostrará '2023-04-11' en la consola


      return nuevaFechaTexto;
    }
    
  }



}
