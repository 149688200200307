//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';



@Component({
  selector: 'app-acciones-rrhh-area-departamento',
  templateUrl: './acciones-rrhh-area-departamento.component.html',
  styleUrls: ['./acciones-rrhh-area-departamento.component.scss'],
  providers: [ RrhhService ]
})
export class AccionesRrhhAreaDepartamentoComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    departamento: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
    numero_depa: new UntypedFormControl('', []),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public iddepa: number = -1;

  constructor(
    private fb: UntypedFormBuilder,
    public  dialog: MatDialog,
    private rrhhService: RrhhService,
    private router: Router,
    private route: ActivatedRoute,
  ){
    /*modificar*/
    this.route.queryParams.subscribe(
      params => {
        this.iddepa = +params['id_depa'];
        if(this.iddepa){
          this.form.patchValue({ numerodepa: this.iddepa})
          this.getConsultarDepartamento(this.iddepa);
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    //accion
    let urllist = this.router.url.split("/");
    this.accion = urllist[4].split("?")[0];
  }

  public postRegistrarDepartamento(){
    this.isLoading = true;
    this.rrhhService.postRegistrarDepartamento(this.form.value).subscribe(
      response => {
        this.respuesta_ad(response);
      },
      error => {
        this.dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postModificarDepartamento(){
    this.isLoading = true;
    this.rrhhService.postModificarDepartamento(this.form.value).subscribe(
      response => {
        this.respuesta_ad(response);
      },
      error => {
        this.dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public respuesta_ad(response: any){
    this.isLoading = false;
    if(response.estado == 200){
      this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
      this.router.navigate(['rrhh/gestor-de-puestos-de-trabajo/gestor-area-departamento']);
    }else{
      this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
    }
  }


  public getConsultarDepartamento(id: number){
    this.isLoading = true;
    this.rrhhService.getConsultarDepartamento(id).subscribe(
      response => {
        this.isLoading = false;
        if(response.estado == 200){this.setFormrrhhAreaDepartamento(response.data);}
      },
      error => {
        this.dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public setFormrrhhAreaDepartamento(data: any){
    this.form.patchValue({
      departamento: data.departamento,
      numero_depa: data.id_departamento_pk, 
    });
  }

}
