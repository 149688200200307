<div class="card-container">
    
    <mat-card class="example-card table-container">
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner color="accent"></mat-spinner>
        </div>
        <mat-card-header>
            <mat-card-title>Nuevo documento</mat-card-title>
            <mat-card-subtitle>Alta de un nuevo documento</mat-card-subtitle>
        </mat-card-header>
    
        <mat-card-content>
            <form #formu (ngSubmit)="_send_Form_()" autocomplete="off" [formGroup]="formulario">
                <mat-form-field appearance="fill" class="bt col-md-6">
                    <mat-label>Categoría</mat-label>
                    <mat-select formControlName="categoria" #categoria_selected >
                        <mat-option value="">-- Seleccione una opción --</mat-option>
                        <mat-option  *ngFor="let categoria of categorias" [value]="categoria.id">{{categoria.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-6">
                    <mat-label>Subcategoría</mat-label>
                    <mat-select formControlName="subcategoria" #subcategoria_selected (change)="tipo_docto.reset()">
                        <mat-option value="">-- Seleccione una opción --</mat-option>
                        <ng-container *ngFor="let subcategoria of subcategorias">
                            <mat-option *ngIf="subcategoria.id_categoria==categoria_selected.value" [value]="subcategoria.id">{{subcategoria.nombre}}</mat-option>
                        </ng-container>
                        
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6">
                    <mat-label>Tipo de documento</mat-label>
                    <mat-select (selectionChange)="_change_Select_Document_Type_($event)" formControlName="tipo_documento" #tipo_docto>
                        <mat-option value="">-- Seleccione una opción --</mat-option>
                        <ng-container *ngFor="let tipo of tipoDocumentos">
                            <mat-option *ngIf="tipo.id_subcategoria==subcategoria_selected.value" [value]="tipo.id">{{tipo.nombre}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6">
                    <mat-label>Nombre</mat-label>
                    <input matInput placeholder="Nombre del documento" formControlName="nombre" maxlength="50">
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-12">
                    <mat-label>Descripción</mat-label>
                    <input matInput placeholder="Descripción del documento" formControlName="descripcion" maxlength="1000">
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6">
                    <mat-label>Fecha de alta</mat-label>                    
                    <input matInput [matDatepicker]="picker" formControlName="fecha_alta" readonly>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6">
                    <mat-label>Carga de Documento</mat-label>
                    <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo.value" formControlName="documentoRuta" readonly>
                    <button mat-icon-button matSuffix (click)="archivo.click()" type="button">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <input type="file" #archivo class="hide" formControlName="documento" (change)="_handle_File_Input_($event.target.files)"/>
                </mat-form-field>
                <mat-card-actions>
                    
                    <button type="submit" color="primary" mat-flat-button>Guardar</button>
                    &nbsp;
                    <a type="button" color="primary" mat-stroked-button [routerLink]="['/centro-informacion-institucional']">Cancelar</a>
                </mat-card-actions>
            </form>
            
        </mat-card-content>
        
    </mat-card>
</div>