import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ParametrosService } from 'src/app/services/parametros.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Beneficiaria } from 'src/app/model/beneficiaria';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-vista-info-general',
  templateUrl: './vista-info-general.component.html',
  styleUrls: ['./vista-info-general.component.scss']
})
export class VistaInfoGeneralComponent implements OnInit {

  @Input() beneficiaria:Beneficiaria;
  hostapi:string;
  discapacidadesf = new UntypedFormControl();
  imagen = "";
  discapacidades = ["Intelectual","Motriz","Conductual","Mental"];
  existente=false;
  formulario:UntypedFormGroup;
  isLoading = false;

  constructor(
    private _documentosService:DocumentosService,
    private router:Router,
    private dialog:MatDialog,
    private _parametrosService:ParametrosService,
    private activatedRoute:ActivatedRoute,
    private _beneficiariaService:BeneficiariaService,
    private _conf:ConfigurationService) { 
      this.hostapi=_conf.getConfiguration().hostapi;
    this.formulario = new UntypedFormGroup({
      nombre: new UntypedFormControl("",[Validators.required]),
      fechaAlta: new UntypedFormControl("",Validators.required),
      fechaNacimiento: new UntypedFormControl("",Validators.required),
      fechaIngreso: new UntypedFormControl("",[]),
      idCurp: new UntypedFormControl("",[]),
      idCanaliza: new UntypedFormControl("",[Validators.required]),
      desCanaliza: new UntypedFormControl("",[]),
      idMotivoIngreso: new UntypedFormControl("",[Validators.required]),
      desMotivoIngreso: new UntypedFormControl("",[]),
      desHitoria: new UntypedFormControl("",[]),
      idDiscapacidad: new UntypedFormControl("",[]),
      desAntecedente: new UntypedFormControl("",[]),
      fechaEgreso: new UntypedFormControl("",[]),
      desLogros:new UntypedFormControl("",[]),
      idEmpresa: new UntypedFormControl("1",[]),
      
      discapacidad: new UntypedFormControl("",[]),
      
      diagnosticoNeurologico: new UntypedFormControl("",[]),
      dnArchivo:new UntypedFormControl("",[]),
      dnDescripcion:new UntypedFormControl("",[]),
      dnRuta:new UntypedFormControl("",[]),

      edadMental: new UntypedFormControl("",[]),
      emArchivo: new UntypedFormControl("",[]),
      emRuta:new UntypedFormControl("",[]),

      diagnosticoMotriz: new UntypedFormControl("",[]),
      dmArchivo: new UntypedFormControl("",[]),
      dmRuta:new UntypedFormControl("",[]),

      diagnosticoPsicologico: new UntypedFormControl("",[]),
      dpArchivo: new UntypedFormControl("",[]),
      dpRuta:new UntypedFormControl("",[]),

      diagnosticoMedico: new UntypedFormControl("",[]),
      dmedArchivo: new UntypedFormControl("",[]),
      dmedRuta:new UntypedFormControl("",[]),
    });
  }
  idBeneficiaria:number;
  ngOnInit(): void {
    
    this.vig_carga__Catalogos__();
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info("Parametros");
      console.info(params["id"]);
      if(params["id"]){
        this.idBeneficiaria= params["id"];
        this.existente = true;
        this.isLoading = true;
        this._beneficiariaService.findById(this.idBeneficiaria).subscribe(respo=>{
          this.beneficiaria = respo.data;
          console.log(this.beneficiaria);
          this.isLoading = false;
          this.vig_set__Data_();
          this.vig_get__Documentos_(this.idBeneficiaria);
        },()=>{
          this.isLoading = false;
        });
        
      }else{
        this.existente = false;
      }
    });
    
  }

  vig_set__Data_(){
    this.formulario.controls.nombre.setValue(this.beneficiaria.nombre);
    this.formulario.controls.nombre.disable();
    this.formulario.controls.fechaAlta.setValue(new Date(this.beneficiaria.fechaAlta["date"]));
    this.formulario.controls.fechaAlta.disable();
    this.formulario.controls.fechaNacimiento.setValue(new Date(this.beneficiaria.fechaNacimiento["date"]));
    this.formulario.controls.fechaNacimiento.disable();
    if(this.beneficiaria.fechaIngreso){
      this.formulario.controls.fechaIngreso.setValue(new Date(this.beneficiaria.fechaIngreso["date"]));
    }
    this.formulario.controls.fechaIngreso.disable();
    this.formulario.controls.desCanaliza.setValue(this.beneficiaria.desCanaliza);
    this.formulario.controls.desCanaliza.disable();
    this.formulario.controls.idCurp.setValue(this.beneficiaria.idCurp);
    this.formulario.controls.idCurp.disable();
    this.formulario.controls.idCanaliza.setValue(this.beneficiaria.idCanaliza);
    this.formulario.controls.idCanaliza.disable();
    this.formulario.controls.idMotivoIngreso.setValue(this.beneficiaria.idMotivoIngreso);
    this.formulario.controls.idMotivoIngreso.disable();
    this.formulario.controls.desHitoria.setValue(this.beneficiaria.desHitoria);
    this.formulario.controls.desHitoria.disable();
    
    let arrayDiscapacidades = [];
    if(this.beneficiaria.idDiscapacidad){
      arrayDiscapacidades = this.beneficiaria.idDiscapacidad.split(",");
    }
    this.formulario.controls.idDiscapacidad.setValue(arrayDiscapacidades);
    this.formulario.controls.idDiscapacidad.disable();

    this.vig_get__Fotos_(this.beneficiaria.id);
  }
  get form(){
    return this.formulario.controls;
  }

  motivoIngreso:any[];
  canaliza:any[];
  diagnosticoNeurologico:any[];
  discapacidad:any[];

  vig_carga__Catalogos__(){
    //param motivo ingreso
    this._parametrosService.getParametros("3").subscribe(respo=>{
      this.motivoIngreso = respo.data.parametros;
    });
    //param canaliza
    this._parametrosService.getParametros("2").subscribe(respo=>{
      this.canaliza = respo.data.parametros;
    });
    this._parametrosService.getParametros("4").subscribe(respo=>{
      this.diagnosticoNeurologico = respo.data.parametros;
    });
    this._parametrosService.getParametros("5").subscribe(respo=>{
      this.discapacidad = respo.data.parametros;
    });
  }
  vig_tiene__Bandera_(id,arreglo:any[]){
    if(arreglo){
      for(let vig=0; vig<arreglo.length;vig++){
        if(arreglo[vig].idParametro == id){
          return arreglo[vig].bndEspecificar;
        }
      }
      return 0;
    }else{
      return 0;
    }
    
  }
  vig_send__Form_(){
    if(this.formulario.invalid){
      return false;
    }
    console.info(this.formulario.getRawValue());
    let beneficiaria : Beneficiaria = this.formulario.getRawValue();
    if(!this.existente){
      beneficiaria.foto = "URL FOTO";
    }
    beneficiaria.fechaAlta = this.formulario.controls.fechaAlta.value.toJSON().replace("T"," ").replace(".000Z","");
    //beneficiaria.fechaIngreso = this.formulario.controls.fechaIngreso.value.toJSON().replace("T"," ").replace(".000Z","");
    beneficiaria.fechaNacimiento = this.formulario.controls.fechaNacimiento.value.toJSON().replace("T"," ").replace(".000Z","");
    let discapacidad = "";
    for(let vig = 0; vig<beneficiaria.idDiscapacidad.length; vig++){
      if(vig>0){
        discapacidad += ",";
      }
      discapacidad += beneficiaria.idDiscapacidad[vig];
    }
    beneficiaria.idDiscapacidad = discapacidad;

    this.isLoading=true;
    this._beneficiariaService.save(beneficiaria,this.idBeneficiaria).subscribe(respo=>{
      this.isLoading=false;
      this.beneficiaria.id = respo.data.id;
      
    },()=>{
      this.isLoading=false;
    });
  }
  
  get edad(): String {
    //console.info("("+this.form.fechaNacimiento.value+")");
    if(this.form.fechaNacimiento.value=="" || this.form.fechaNacimiento.value==null){
      return "-";
    }else{
      let edad:Number = moment(new Date()).diff(this.form.fechaNacimiento.value, 'years',true);
      let laedad:String = edad.toString();
      let años = laedad.split(".")[0];
      let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let detalleEdad = años + " años "+ meses + " meses";
      return detalleEdad;
    }
  }
  

  get edadIngreso(){
    if((this.form.fechaNacimiento.value=="" || this.form.fechaNacimiento.value==null || this.form.fechaIngreso.value=="" || this.form.fechaIngreso.value==null)){
      return "-";
    }else{
      //console.info(this.beneficiaria.fechaIngreso["date"]);
      let edad:Number = moment(this.form.fechaIngreso.value).diff(this.form.fechaNacimiento.value, 'years',true);
      //console.info(edad);
      let laedad:String = edad.toString();
      let años = laedad.split(".")[0];
      let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let detalleEdad = años + " años "+ meses + " meses";
      return detalleEdad;
    }
  }

  get tiempoDeAntiguedad(){
    if( this.form.fechaIngreso.value=="" || this.form.fechaIngreso.value==null ){
      return "0";
    }else{
      //console.info(this.beneficiaria.fechaIngreso["date"]);
      let edad:Number = moment(this.form.fechaIngreso.value).diff(new Date(), 'years',true);
      //console.info(edad);
      let laedad:String = edad.toString();
      let años = laedad.split(".")[0];
      let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let detalleEdad = años + " años "+ meses + " meses";
      return detalleEdad;
    }
  }
  fotos:any;
  vig_get__Fotos_(id){
    this._beneficiariaService.getFotos(id).subscribe(respo=>{
      console.log(respo);
      this.fotos=respo.data;
    },()=>{
      // This is intentional
    });
  }

  vig_mensaje__De__Exito_(){
    const dialogRef= this.dialog.open(MessageBoxComponent,{
      data:{titulo: "Expediente creado" ,mensaje: "Se ha creado un nuevo expediente"}
    });
    this.router.navigate(["/beneficiarias"]);
  }
  vig_mensaje__De__Exito2_(){
    const dialogRef= this.dialog.open(MessageBoxComponent,{
      data:{titulo: "Expediente modificado" ,mensaje: "Se ha modificado el expediente correctamente"}
    });
    dialogRef.afterClosed().subscribe(()=>{
      document.getElementById("resetForm").click();
      this.ngOnInit();
    })
  }
  documentos:any;
  documento1 = null;
  documento2 = null;
  documento3 = null;
  documento4 = null;
  documento5 = null;

  vig_get__Documentos_(id:number){
    this.isLoading = true;
    this._documentosService.getAllByBeneficiaria(id).subscribe(respo=>{
      this.isLoading = false;
      this.documentos = respo.data.documentos;

      for(let vig = 0; vig< this.documentos.length; vig++){
        if(this.documentos[vig].idTipoDocumento == 52){
          this.formulario.controls.diagnosticoNeurologico.setValue(this.documentos[vig].idTipoCatalogo);
          this.formulario.controls.diagnosticoNeurologico.disable();
          this.formulario.controls.dnDescripcion.setValue(this.documentos[vig].desTipoCatalogo);
          this.formulario.controls.dnDescripcion.disable();
          this.formulario.controls.dnRuta.disable();
          //this.formulario.controls.dnRuta.setValue(this.documentos[vig].docTipoCatalogo);

          this.documento1 = this.documentos[vig].docTipoCatalogo;
        }else if(this.documentos[vig].idTipoDocumento == 53){
          this.formulario.controls.edadMental.setValue(this.documentos[vig].desTipoCatalogo);
          this.formulario.controls.edadMental.disable();
          this.formulario.controls.emRuta.disable();
          //this.formulario.controls.emRuta.setValue(this.documentos[vig].docTipoCatalogo);

          this.documento2 = this.documentos[vig].docTipoCatalogo;
        }else if(this.documentos[vig].idTipoDocumento == 54){
          this.formulario.controls.diagnosticoMotriz.setValue(this.documentos[vig].desTipoCatalogo);
          this.formulario.controls.diagnosticoMotriz.disable();
          this.formulario.controls.dmRuta.disable();
          //this.formulario.controls.dmRuta.setValue(this.documentos[vig].docTipoCatalogo);

          this.documento3 = this.documentos[vig].docTipoCatalogo;
        }else if(this.documentos[vig].idTipoDocumento == 55){
          this.formulario.controls.diagnosticoPsicologico.setValue(this.documentos[vig].desTipoCatalogo);
          this.formulario.controls.diagnosticoPsicologico.disable();
          this.formulario.controls.dpRuta.disable();
          //this.formulario.controls.dpRuta.setValue(this.documentos[vig].docTipoCatalogo);

          this.documento4 = this.documentos[vig].docTipoCatalogo;
        }else if(this.documentos[vig].idTipoDocumento == 56){
          this.formulario.controls.diagnosticoMedico.setValue(this.documentos[vig].desTipoCatalogo);
          this.formulario.controls.diagnosticoMedico.disable();
          this.formulario.controls.dmedRuta.disable();
          //this.formulario.controls.dmedRuta.setValue(this.documentos[vig].docTipoCatalogo);

          this.documento5 = this.documentos[vig].docTipoCatalogo;
        }
      }

    },()=>{
      this.isLoading = false;
    })
  }
}
