<div class="card-container" id="top">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Capacitaciones</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>


            <form novalidate [formGroup]="form" autocomplete="off" >
                <div class="row">
                    <mat-radio-group  class="m-0"  formControlName="tipo_capa">
                        <br>
                        <mat-radio-button class="m-0" [value]="1">Interna </mat-radio-button>
                        &nbsp;&nbsp;
                        <mat-radio-button class="m-0" [value]="2">Externa </mat-radio-button>
                    </mat-radio-group>
                </div> 
                <div class="row">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Nombre de la capacitación </mat-label>
                        <textarea type="text" matInput  formControlName="nombre_capa" rows="4"></textarea>
                        <mat-error *ngIf="form.controls['nombre_capa'].hasError('maxLength')">
                            Excedió el número de caracteres
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Descripción </mat-label>
                        <textarea type="text" matInput  formControlName="descripcion" rows="4"></textarea>
                        <mat-error *ngIf="form.controls['descripcion'].hasError('maxLength')">
                            Excedió el número de caracteres
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>¿Quién impartió la capacitación? </mat-label>
                        <textarea type="text" matInput  formControlName="quien_impartio" rows="4"></textarea>
                        <mat-error *ngIf="form.controls['quien_impartio'].hasError('maxLength')">
                            Excedió el número de caracteres
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Fecha inicio </mat-label>
                            <input type="date" matInput  formControlName="fecha_inicio" >
                            <mat-error *ngIf="form.controls['fecha_inicio'].hasError('required')">
                                Campo obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Fecha fin </mat-label>
                            <input type="date" matInput  formControlName="fecha_fin" >
                            <mat-error *ngIf="form.controls['fecha_fin'].hasError('required')">
                                Campo obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>

            <div class="row">
                <div class="col">
                    <form novalidate [formGroup]="form" autocomplete="off" >
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Nombre</mat-label>
                            <mat-select formControlName="trabajador"  >
                                <mat-option *ngFor="let t of empleados" [value]="t.id_info_empleado_pk">{{t?.id_info_empleado_pk}} - {{t?.apellidop}} {{t?.apellidom}} {{t?.nombre}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                <div class="col">
                    <button *ngIf="accion == 'modificar' || accion == 'registrar' " type="button" color="primary"  mat-mini-fab  (click)="elegir(form.value.trabajador  )" >
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>

            <div class="row mt-2" *ngFor="let a of elegidos; let i = index">
                <div class="col">
                    ID: {{a?.numero_empleado}}
                </div>
                <div class="col">
                    Nombre: {{a?.nombre}}
                </div>
                <div class="col">
                    <button *ngIf="accion == 'modificar' || accion == 'registrar' " type="button" color="warn"  mat-flat-button  (click)="quitar(i)" >
                        Eliminar
                    </button>
                </div>
            </div>


            <div class="row" *ngIf="accion == 'modificar' || accion == 'consultar'">
                <strong>Fotografías</strong>
            </div>
            <div class="row mt-3" *ngIf="accion == 'modificar'">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Nombre de la foto </mat-label>
                        <input type="text" matInput  [(ngModel)]="nombreFoto">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <div  class="input-group">
                        <input    type="file"  class="form-control" (change)="regitsrarFoto($event)" >
                        
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <button type="button" color="primary"  mat-flat-button  (click)="RegistrarFotoCapa(id_capacitacion)" >
                        Registrar
                    </button>
                </div>
            </div>

            <div class="row" *ngIf="accion == 'modificar' || accion == 'consultar'">
                <div class="row mt-3" *ngFor="let a of data?.fotografias" >
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre de la foto </mat-label>
                            <input type="text" matInput  [(ngModel)]="a.nombre_foto">
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <div  class="input-group">
                            <input    type="file"  class="form-control" (change)="regitsrarFoto($event, 2, a)" >
                            
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-1 col-xl-1 col-xxl-">
                        <a *ngIf="a ?.ruta_relativa != null"  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{a?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                            <mat-icon class="material-icons">
                                file_open
                            </mat-icon>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <button  *ngIf="accion == 'modificar'" type="button" color="primary"  mat-flat-button  (click)="postModificarFotoCapa(a)" >
                            Modificar
                        </button>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                        <button  *ngIf="accion == 'modificar'" type="button" color="warn"  mat-flat-button  (click)="postEliminarFotoCapa(a?.id_foto_pk)" >
                            x
                        </button>
                    </div>
                </div>
            </div>


            <div class="row mt-5" *ngIf="accion == 'modificar' || accion == 'consultar'">
                <strong>Documentos</strong>
            </div>

            <div class="row mt-3" *ngIf="accion == 'modificar'">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Nombre del documento </mat-label>
                        <input type="text" matInput  [(ngModel)]="nombreDocumento">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <div  class="input-group">
                        <input    type="file"  class="form-control" (change)="regitsrarDocumento($event)" >
                        
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <button type="button" color="primary"  mat-flat-button  (click)="RegistrarDocumentoCapa(id_capacitacion)" >
                        Registrar
                    </button>
                </div>
            </div>

            <div class="row" *ngIf="accion == 'modificar' || accion == 'consultar'">
                <div class="row mt-3" *ngFor="let a of data?.documentos" >
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del documento </mat-label>
                            <input type="text" matInput  [(ngModel)]="a.nombre_archivo">
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <div  class="input-group">
                            <input    type="file"  class="form-control" (change)="regitsrarDocumento($event, 2, a)" >
                            
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-1 col-xl-1 col-xxl-">
                        <a *ngIf="a ?.ruta_relativa != null"  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{a?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                            <mat-icon class="material-icons">
                                file_open
                            </mat-icon>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <button  *ngIf="accion == 'modificar'" type="button" color="primary"  mat-flat-button  (click)="postModificarDocumentoCapa(a)" >
                            Modificar
                        </button>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                        <button  *ngIf="accion == 'modificar'" type="button" color="warn"  mat-flat-button  (click)="postEliminarDocumentoCapa(a?.id_archivo_pk)" >
                            x
                        </button>
                    </div>
                </div>
            </div>
           
               
           
            <!--<div class="row">
                <div class="col">
                    <a *ngIf="data?.ruta_relativa != null"  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{data?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                        <mat-icon class="material-icons">
                            file_open
                        </mat-icon>
                    </a>
                </div>
                <div class="col">
                    <button *ngIf="accion == 'modificar'" type="button" color="warn"  mat-flat-button  (click)="postEliminarDocumentoIncidentes(data?.id_incidente_pk)" >
                        Eliminar
                    </button>
                </div>
            </div>-->






            
            
            <div class="d-flex justify-content-center gap-2 mt-5">
                <button *ngIf="accion == 'modificar'" class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones']">
                    Regresar
                </button>


                <form novalidate [formGroup]="form" autocomplete="off" (ngSubmit)="postRegistrarCapacitacion()">
                    <button [disabled]="form.invalid" *ngIf="accion == 'registrar'"  type="submit" class="flat" color="primary" mat-flat-button  >
                        Guardar
                    </button>
                </form>

                <form novalidate [formGroup]="form" autocomplete="off" (ngSubmit)="postModificarCapacitacion()">
                    <button [disabled]="form.invalid" *ngIf="accion == 'modificar'"  type="submit" class="flat" color="primary" mat-flat-button  >
                        Modificar
                    </button>
                </form>
            </div>







        </mat-card-content>
    </mat-card>
</div>