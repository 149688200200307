<div class="card-container" id="top">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Ficha técnica</mat-card-title>
            <mat-card-subtitle>Información general del empleado</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="row mt-1 p-3">
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <img src="{{laravelapiStorage}}/{{data?.ruta_relativa+'?t='+t}}" alt="img-exp-inf-emp" width="100%" height="auto">
                    </div>
                </div>
                <div class="col">
                    <div class="row mt-1">
                        <div class="col">
                            <strong>Nombre Completo:</strong>
                        </div>
                        <div class="col bg-ft">
                            {{data?.apellidop}} {{data?.apellidom}} {{data?.nombre}}
                        </div>
                    </div>
                    
                    <div class="row mt-1">
                        <div class="col">
                            <strong>Escolaridad:</strong>
                        </div>
                        <div class="col bg-ft">
                            {{data?.datos_puesto?.estado_escolaridad?.estado_escolaridad}}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <strong>Telefono celular:</strong>
                        </div>
                        <div class="col bg-ft">
                            {{data?.celular}}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <strong>Fecha de nacimiento</strong>
                        </div>
                        <div class="col bg-ft">
                            {{data?.fecha_nacimiento}}
                        </div>
                    </div>
                   
                    <div class="row mt-1">
                        <div class="col">
                            <strong>Carrera</strong>
                        </div>
                        <div class="col bg-ft">
                            {{data?.datos_puesto?.escolaridad}}
                        </div>
                    </div>


                    <div class="row mt-1">
                        <div class="col">
                            <strong>Edad:</strong>
                        </div>
                        <div class="col bg-ft">
                            {{getEdad()}}
                        </div>
                    </div>



                </div>

                <div class="col">

                    <div class="row mt-1">
                        <div class="col">
                            <strong>Puesto:</strong>
                        </div>
                        <div class="col bg-ft">
                            {{data?.datos_puesto?.puestos_reporte?.puestos_reporte}}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <strong>Tipo de empleado:</strong>
                        </div>
                        <div class="col bg-ft">
                            {{data?.datos_puesto?.tipo_puestos?.tipo_puesto}}
                        </div>
                    </div>

                    <div class="row mt-1">
                        <div class="col">
                            <strong>Departamento</strong>
                        </div>
                        <div class="col bg-ft">
                            {{data?.datos_puesto?.departamentos[0]?.departamento}}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <strong>Estatus</strong>
                        </div>
                        <div class="col bg-ft">
                            
                            <span *ngIf="data?.datos_puesto?.tarjeta_nomina?.baja_empleado == 1">
                                Baja
                            </span>
        
                            <span *ngIf="data?.datos_puesto?.tarjeta_nomina?.baja_empleado == 0 || data?.datos_puesto?.tarjeta_nomina?.baja_empleado == null">
                                Activo
                            </span>
                        </div>
                    </div>
                   
                    <div class="row mt-1">
                        <div class="col">
                            <strong>Fecha de ingreso:</strong>
                        </div>
                        <div class="col bg-ft">
                            {{data?.datos_puesto?.fecha_ingreso}}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <strong>Antiguedad:</strong>
                        </div>
                        <div class="col bg-ft">
                            {{getTiempoLaborando()}}
                        </div>
                    </div>
                   
                </div>
                <div class="col">
                    <div class="row mt-1">
                        <div class="col">
                            <strong>Último día laborando:</strong>
                        </div>
                        <div class="col bg-ft">
                            <span *ngIf="data?.datos_puesto?.tarjeta_nomina?.baja_empleado == 1">{{data?.datos_puesto?.tarjeta_nomina?.updated_at.split('T')[0]}}</span>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <strong>Motivo de salida:</strong>
                        </div>
                        <div class="col bg-ft">
                            <span *ngIf="data?.datos_puesto?.tarjeta_nomina?.baja_empleado == 1">{{data?.datos_puesto?.tarjeta_nomina?.tipo_baja?.tipo_baja}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>