import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { jsPDF } from "jspdf";
import "jspdf-autotable";


import { DesitionBoxComponent } from 'src/app/modals/desition-box/desition-box.component';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { Beneficiaria } from 'src/app/model/beneficiaria';
import { Paginador } from 'src/app/model/configuracion';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EvaluacionesService } from 'src/app/services/evaluaciones.service';
import { PerfilesService } from 'src/app/services/perfiles.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { environment } from 'src/environments/environment';

import { ChartType,ChartOptions } from 'chart.js';
import { MultiDataSet, Label,SingleOrMultiDataSet } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-gestor-de-pruebas',
  templateUrl: './gestor-de-pruebas.component.html',
  styleUrls: ['./gestor-de-pruebas.component.scss']
})
export class GestorDePruebasComponent implements OnInit {

  // Doughnut
  public pieChartLegend = false;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [],
    },
  ];
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public doughnutChartLabels: Label[] = [];
  public doughnutChartData = [];
  public doughnutChartType: ChartType = 'doughnut';

  // events
  public chartClicked(e: any): void {
    if (e.active.length > 0) {
    const chart = e.active[0]._chart;
    const activePoints = chart.getElementAtEvent(e.event);
      if ( activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        let label = chart.data.labels[clickedElementIndex];
        // get value by index
        const value = chart.data.datasets[0].data[clickedElementIndex];
        console.log(clickedElementIndex,"|", label,"|",  value);
        // label == this.selectedCategoria ? label = "":""; 
        if(this.selectedCategoria){
          label = "";
        }
        this.selectedCategoria = label;
        this.getSpecialReport(this.idRerpot,label);
      }
     }
     
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //console.log(event, active);
  }



  @ViewChild('content', {static: false}) el!: ElementRef;
  count = 0;
  id:number = 0;
  usuarios:any=[];
  cuestionarios:any=[];
  isLoading = false;
  paginador:Paginador;
  displayedColumns: string[] = ['position','fechaDePrueba','nombreEncuesta','evaluador','estatus','porcentaje','prueba','grafica','eliminar'];
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  constructor(
    private activatedRoute:ActivatedRoute,
    public dialog: MatDialog,
    private _config:ConfigurationService,
    private _evaluacionesService:EvaluacionesService
  ) { 
    this.paginador = this._config.getConfiguration().ui.paginador;
    this.activatedRoute.queryParams.subscribe(params=>{
      if(params["id"]!=undefined){
        this.id = params["id"];
      }
    });
  }

  ngOnInit(): void {
    
    this.dataSource.paginator = this.paginator;
    this.buscar(1 ,this.paginador.pageSize);
    
  }

  buscar(page:number,size:number){
    this.isLoading = true;
    this._evaluacionesService.getEvaluaciones(this.id,page,size).subscribe(result=>{
      this._evaluacionesService.getPorcentajeDePruebas(this.id).subscribe(porcentajes=>{


        for(let i =0; i<result.data.encuestas.length;i++){
          for(let y = 0; y<porcentajes.data.length; y++){
            if(parseInt(porcentajes.data[y].id_aplicacion_pk) === result.data.encuestas[i].idPrueba){
              result.data.encuestas[i].nombreEncuesta = porcentajes.data[y].nombre;
              result.data.encuestas[i].porcentaje = porcentajes.data[y].porcentaje;
            }
          }
        }

        this.dataSource = new MatTableDataSource<any>(result.data.encuestas);
        this.cuestionarios = [];
        for(let i=result.data.cuestionarios.length-1; i>=0; i--){
          this.cuestionarios.push(result.data.cuestionarios[i]);
        }
        //result.data.cuestionarios;
        //this.cuestionarios = result.data.cuestionarios;
        this.usuarios = result.data.usuarios;
        this.count = result.data.conteoEncuestas;
        this.isLoading = false;


      })
      
    },()=>{
      this.isLoading = false;
    });
  }


  pager(event){
    this.paginator.pageSize = event.pageSize;
    this.paginator.pageIndex = event.pageIndex;
    this.buscar(event.pageIndex + 1 ,event.pageSize);
  }

  getEvaluador(id:number){
    let nombre = "-";
    if(id){
      for(let i=0; i<this.usuarios.length;i++){
        if(this.usuarios[i].idUsuario == id){
          nombre = this.usuarios[i].nombreUsuario;
        }
      }
    }
    return nombre;
  }

  eliminar(id){
    const dialogRef = this.dialog.open(DesitionBoxComponent,{
      data : { titulo: "¿Desea eliminar el evento?" , mensaje : "La información del evento será eliminada de manera permanente, ¿Desea continuar?", desicion : true }
    });
    dialogRef.afterClosed().subscribe(result=>{
      console.info(result);
      if(result){
        this.isLoading = true;
        this._evaluacionesService.eliminarEvaluacion(id).subscribe(result=>{
          this.isLoading = false;
          this.mensaje("Evento eliminado","El evento fue eliminado de forma satisfactoria");
        },()=>{
          this.isLoading = false;
        })
      }
    });
  }

  mensaje(titulo, mensaje){
    const dialogRef = this.dialog.open(MessageBoxComponent,{
      data:{titulo: titulo ,mensaje: mensaje}
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.buscar(1 ,this.paginador.pageSize);
    })
  }
  clasificaciones=[];
  dataset = [];
  comparativo:any = {};

  separarEvaluaciones(data:any){
    let evaluaciones = [];
    for(let i =0 ; i<data.length;i++){
      if(i == 0 || data[i].id_aplicacion!= data[i-1].id_aplicacion){
        evaluaciones.push({evaluacion: data[i].id_aplicacion,data:[]});
      }
      evaluaciones[evaluaciones.length-1].data.push(data[i]);
    }

    console.warn("Imprimiendo evaluaciones");
    //console.info(this.descomponerComparativo(evaluaciones[0].data));
    this.ultimos = [];
    for(let i=0; i<evaluaciones.length;i++){
      this.ultimos.push(this.descomponerComparativo(evaluaciones[i].data));
      this.ultimos[this.ultimos.length-1].fecha_app = evaluaciones[i].data[0].fecha_app;
    }
    console.info(this.ultimos);
    return this.descomponerComparativo(evaluaciones[0].data);
  }
  descomponerComparativo(data:any):any{
    let objtoComparativo = {clasificaciones:
      [
        { 
          nombre: "",
          subclasificaciones: [{
            nombre:"",
            preguntas:[
              {
                descripcion:"",
                respuesta:""
              }
            ]
          }]
        }
      ]
    };
    
    objtoComparativo.clasificaciones = [];
    for(let i =0 ; i<data.length;i++){
      if(i==0 || data[i].clasificacion != data[i-1].clasificacion){
        
        objtoComparativo.clasificaciones.push({nombre: data[i].clasificacion,subclasificaciones:[]});
        
        
      }

      

      if(i==0 || data[i].subclasificacion != data[i-1].subclasificacion ){
        //objtoComparativo.clasificaciones[objtoComparativo.clasificaciones.length-1].subclasificaciones.push({nombre: data[i].subclasificacion,preguntas:[]});
        objtoComparativo.clasificaciones[objtoComparativo.clasificaciones.length-1].subclasificaciones.push({
          nombre : data[i].subclasificacion,
          preguntas: []
        });
      }

      let ultimaSubclasificacionInsertada = objtoComparativo.clasificaciones[objtoComparativo.clasificaciones.length-1].subclasificaciones.length-1;
      objtoComparativo.clasificaciones[objtoComparativo.clasificaciones.length-1].subclasificaciones[ultimaSubclasificacionInsertada].preguntas.push(
        {
          descripcion: data[i].pregunta,
          respuesta: data[i].respuesta
        }
      );
      
    }
    //console.log(objtoComparativo);
    return objtoComparativo;
  }

  ultimos = [];
  exportarComparativaPDF(idPrueba){
    this.dataset = [];
    this.isLoading = true;
    this._evaluacionesService.getNombreEvaluacion(idPrueba).subscribe(evaluacion=>{
      
      this._evaluacionesService.getComparativo(this.beneficiaria.id, evaluacion.data[0].id_encuesta_pk).subscribe(result=>{
        if(result.codigo == "PRM000"){
          this.comparativo = this.separarEvaluaciones(result.data);
        }
        console.info(this.comparativo);
        //console.info(this.clasificaciones);
        console.info("Creando PDF");
        let doc = new jsPDF('p','px','a4');
        doc.setFont("helvetica", "bold");
        doc.text("REPORTE COMPARATIVO DE EVALUACIONES (ÚLTIMAS 3)", 90, 20,{align:"left"},null);
        doc.setFont("helvetica", "normal");
        doc.text((evaluacion.data[0].nombre), 90, 35,{align:"left"},null);
        doc.setFontSize(10);
        
        doc.addImage("assets/img/logo-goretti.jpg","JPEG", 8, 0, 80.4, 57.2);
        let iy=55;
        if(this.beneficiaria.foto!="URL FOTO"){
          //this.convertImgToBase64("/apiphp/"+this.beneficiaria.foto,null);
          doc.addImage(this._config.getConfiguration().hostapi+"/"+this.beneficiaria.foto,"JPEG",15, iy+5, 80, 80);
        }else{
          doc.addImage("assets/img/unknown.jpg","JPEG", 12, iy+7, 50, 51);
        }
        
        this.imprimirFichaTecnica(doc,iy);

        
        setTimeout(()=>{
          this.makePDF("RCOM-"+this.obtenerIniciales(evaluacion.data[0].nombre)+"-BE"+this.beneficiaria.id,idPrueba,doc,iy+130);
        },0);

      });
    });
  }

  public insertPreguntasExportPizzaPDF(data, clasific){
    console.log("insertPreguntas data: ", data);
    console.log("insertPreguntas clasific: ", clasific);
    for(let i =0; i< data.data.preguntas.length; i++){
      if(clasific.indexOf(data.data.preguntas[i].idClasificacion)<0){
        clasific.push(data.data.preguntas[i].idClasificacion);
      }
    }
  }

  public insertClasificacionesExportPizzaPDF(data, clasific){
    for(let i=0; i<data.data.clasificaciones.length; i++){
      if(clasific.indexOf(data.data.clasificaciones[i].idClasificacion)>-1){
        this.clasificaciones.push(data.data.clasificaciones[i]);
      }
    }
  }

  public checkBeneficiariaTieneFotoExportPizzaPDF(benef, doc, iy){
    if(benef.foto!="URL FOTO"){
      //this.convertImgToBase64("/apiphp/"+this.beneficiaria.foto,null);
      doc.addImage(this._config.getConfiguration().hostapi+"/"+benef.foto,"JPEG",15, iy+5, 80, 80);
    }else{
      doc.addImage("assets/img/unknown.jpg","JPEG", 12, iy+7, 50, 51);
    }
  }

  public checkTieneFichaTecnicaExportPizzaPDF(i,doc,iy){
    if(i==0){
      this.imprimirFichaTecnica(doc,iy);
    }
  }

  public insertPreguntasPorCategoriaExportPizzaPDF(data, clasific, i, preguntas){
    for(let x=0; x < data.data.preguntas.length;x++){
      if(clasific[i].idClasificacion == data.data.preguntas[x].idClasificacion){
        preguntas.push(data.data.preguntas[x]);
      }
    }
  }
  
  public insertSubcategoriasExportPizzaPDF(preguntas, subCat){
    for(let x=0; x<preguntas.length; x++){
      if(subCat.indexOf(preguntas[x].descripcion.split("|")[0])<0){
        subCat.push(preguntas[x].descripcion.split("|")[0]);
      }
    }
  }

  public getPreguntasPorCategoriaExportPizzaPDF(subCategoria, objeto, preguntasxSubcategoria, preguntas, clasific, data, dataset, i){
    for(let x=0; x<subCategoria.length; x++){
      objeto.titulo = null;
      objeto.subtitulo = null;
      objeto.datos = [];

      preguntasxSubcategoria.length = 0;
      
      for(let y = 0; y<preguntas.length;y++){
        if(subCategoria[x] == preguntas[y].descripcion.split("|")[0]){
          preguntasxSubcategoria.push(preguntas[y]);
        }
      }

      //console.info(" >" +subCategoria[x]);
      let titulo = null;
      let subtitulo = null;
      let datos = [];
      if(x == 0){
        
        
        titulo = clasific[i].nombre;
        
        
      }else{
        
        objeto.titulo = null;
        
        
      }

      subtitulo = subCategoria[x];
      datos = this.generateData(preguntasxSubcategoria,data.data);
      datos = this.clearDatos(datos);
      if(datos.length>0){
        dataset.push({"titulo":titulo,"subtitulo":subtitulo, "datos":datos});
      }
    }
  }

  exportarPizzaPDF(idPrueba){
    this.getSpecialReport(idPrueba,"");
    this.dataset = [];
    let objeto = {datos:[],titulo:null,subtitulo:null};
    this.isLoading = true;
    this._evaluacionesService.getEvaluacion(this.beneficiaria.id).subscribe(result=>{
      console.info(result);
      this._evaluacionesService.getNombreEvaluacion(idPrueba).subscribe(evaluacion=>{

        let clasificaciones = [];
      
        let data = result;
        console.log("data antes de enviar: ", data);
        this.insertPreguntasExportPizzaPDF(data, clasificaciones);
        // for(let i =0; i< data.data.preguntas.length; i++){
          // if(clasificaciones.indexOf(data.data.preguntas[i].idClasificacion)<0){
          //   clasificaciones.push(data.data.preguntas[i].idClasificacion);
          // }
        // }
        let clasificacion=[];
        this.insertClasificacionesExportPizzaPDF(data, clasificaciones);
        // for(let i=0; i<data.data.clasificaciones.length; i++){
        //   if(clasificaciones.indexOf(data.data.clasificaciones[i].idClasificacion)>-1){
        //     this.clasificaciones.push(data.data.clasificaciones[i]);
        //   }
        // }
        //console.info(this.clasificaciones);
        console.info("Creando PDF");
        let doc = new jsPDF('p','px','a4');
        doc.setFont("helvetica", "bold");
        doc.text("GRAFICO DE PIZZA", 90, 20,{align:"left"},null);
        doc.setFont("helvetica", "normal");
        doc.text((evaluacion.data[0].nombre), 90, 35,{align:"left"},null);
        doc.setFontSize(10);
        
        doc.addImage("assets/img/logo-goretti.jpg","JPEG", 8, 0, 80.4, 57.2);
        let iy=55;


        this.checkBeneficiariaTieneFotoExportPizzaPDF(this.beneficiaria.foto, doc, iy);
        // if(this.beneficiaria.foto!="URL FOTO"){
        //   //this.convertImgToBase64("/apiphp/"+this.beneficiaria.foto,null);
        //   doc.addImage(environment.apiUrl+"/"+this.beneficiaria.foto,"JPEG",15, iy+5, 80, 80);
        // }else{
        //   doc.addImage("assets/img/unknown.jpg","JPEG", 12, iy+7, 50, 51);
        // }

        //*********CODE COPy */

        for(let i=0; i<this.clasificaciones.length;i++){
          //console.info("Categoría > " +this.clasificaciones[i].nombre);
          /**Se comenta para pasar a html */
          
          this.checkTieneFichaTecnicaExportPizzaPDF(i,doc,iy)
          // if(i==0){
          //   this.imprimirFichaTecnica(doc,iy);
          // }
  
          //Selección de preguntas x categoría
          let preguntas=[];
          this.insertPreguntasPorCategoriaExportPizzaPDF(data, this.clasificaciones, i, preguntas);
          // for(let x=0; x < data.data.preguntas.length;x++){
          //   if(this.clasificaciones[i].idClasificacion == data.data.preguntas[x].idClasificacion){
          //     preguntas.push(data.data.preguntas[x]);
          //   }
          // }
  
          //Selección de subcategorías
          let subCategoria = [];
          this.insertSubcategoriasExportPizzaPDF(preguntas, subCategoria);
          // for(let x=0; x<preguntas.length; x++){
          //   if(subCategoria.indexOf(preguntas[x].descripcion.split("|")[0])<0){
          //     subCategoria.push(preguntas[x].descripcion.split("|")[0]);
          //   }
          // }
          //Obteniendo preguntas por subcategoría
          let preguntasxSubcategoria=[];
          this.getPreguntasPorCategoriaExportPizzaPDF(subCategoria, objeto, preguntasxSubcategoria, preguntas, this.clasificaciones, data, this.dataset, i);
          // for(let x=0; x<subCategoria.length; x++){
          //   objeto.titulo = null;
          //   objeto.subtitulo = null;
          //   objeto.datos = [];
  
          //   preguntasxSubcategoria=[];
          //   for(let y = 0; y<preguntas.length;y++){
          //     if(subCategoria[x] == preguntas[y].descripcion.split("|")[0]){
          //       preguntasxSubcategoria.push(preguntas[y]);
          //     }
          //   }
  
          //   //console.info(" >" +subCategoria[x]);
          //   let titulo = null;
          //   let subtitulo = null;
          //   let datos = [];
          //   if(x == 0){
              
              
          //     titulo = this.clasificaciones[i].nombre;
              
              
          //   }else{
              
          //     objeto.titulo = null;
              
              
          //   }
  
          //   subtitulo = subCategoria[x];
          //   datos = this.generateData(preguntasxSubcategoria,data.data);
          //   datos = this.clearDatos(datos);
          //   if(datos.length>0){
          //     this.dataset.push({"titulo":titulo,"subtitulo":subtitulo, "datos":datos});
          //   }
          // }
        }
        //***CODE COPY */
        
        setTimeout(()=>{
          this.makePDF("RPIZ-"+this.obtenerIniciales(evaluacion.data[0].nombre)+"-EV"+idPrueba+"BE"+this.beneficiaria.id,idPrueba,doc,iy+130);
        },0);

      });
    });
  }

  public insertPreguntasExportPrioridadesPDF(data, clasific){
    for(let i =0; i< data.preguntas.length; i++){
      if(clasific.indexOf(data.preguntas[i].idClasificacion)<0){
        clasific.push(data.preguntas[i].idClasificacion);
      }
    }
  }

  public insertClasificacionesExportPrioridadesPDF(data, clasific){
    for(let i=0; i<data.clasificaciones.length; i++){
      if(clasific.indexOf(data.clasificaciones[i].idClasificacion)>-1){
        this.clasificaciones.push(data.clasificaciones[i]);
      }
    }
  }

  public checkBeneficiariaTieneFotoExportPrioridadesPDF(benef, doc, iy){
    if(benef.foto!="URL FOTO"){
      //this.convertImgToBase64("/apiphp/"+this.beneficiaria.foto,null);
      doc.addImage(this._config.getConfiguration().hostapi+"/"+this.beneficiaria.foto,"JPEG",15, iy+5, 80, 80);
    }else{
      doc.addImage("assets/img/unknown.jpg","JPEG", 12, iy+7, 50, 51);
    } 
  }

  public checkTieneFichaTecnicaExportPrioridadesPDF(i,doc,iy){
    if(i==0){
      this.imprimirFichaTecnica(doc,iy);
    }
  }

  public insertPreguntasPorCategoriaExportPrioridadesPDF(data, clasific, i, preguntas){
    for(let x=0; x < data.preguntas.length;x++){
      if(clasific[i].idClasificacion == data.preguntas[x].idClasificacion){
        preguntas.push(data.preguntas[x]);
      }
    }
  }
  
  public insertSubcategoriasExportPrioridadesPDF(preguntas, subCat){
    for(let x=0; x<preguntas.length; x++){
      if(subCat.indexOf(preguntas[x].descripcion.split("|")[0])<0){
        subCat.push(preguntas[x].descripcion.split("|")[0]);
      }
    }
  }

  public getPreguntasPorCategoriaExportPrioridadesPDF(subCategoria, objeto, preguntas, clasific, data, dataset, i){
    // for(let x=0; x<subCategoria.length; x++){
    //   objeto.titulo = null;
    //   objeto.subtitulo = null;
    //   objeto.datos = [];

    //   preguntasxSubcategoria=[];
    //   for(let y = 0; y<preguntas.length;y++){
    //     if(subCategoria[x] == preguntas[y].descripcion.split("|")[0]){
    //       preguntasxSubcategoria.push(preguntas[y]);
    //     }
    //   }
    //   preguntasxSubcategoria.length = 0;
    //   //console.info(" >" +subCategoria[x]);
    //   let titulo = null;
    //   let subtitulo = null;
    //   let datos = [];
    //   if(x == 0){
        
        
    //     titulo = clasific[i].nombre;
        
        
    //   }else{
        
    //     objeto.titulo = null;
        
        
    //   }

    //   subtitulo = subCategoria[x];
    //   datos = this.generateData(preguntasxSubcategoria,data);
    //   datos = this.clearDatos(datos);
    //   if(datos.length>0){
    //     dataset.push({"titulo":titulo,"subtitulo":subtitulo, "datos":datos});
    //   }
    // }
    for(let x=0; x<subCategoria.length; x++){
      objeto.titulo = null;
      objeto.subtitulo = null;
      objeto.datos = [];

      let preguntasxSubcategoria=[];
      for(let y = 0; y<preguntas.length;y++){
        if(subCategoria[x] == preguntas[y].descripcion.split("|")[0]){
          preguntasxSubcategoria.push(preguntas[y]);
        }
      }

      //console.info(" >" +subCategoria[x]);
      let titulo = null;
      let subtitulo = null;
      let datos = [];
      if(x == 0){
        
        
        titulo = this.clasificaciones[i].nombre;
        
        
      }else{
        
        objeto.titulo = null;
        
        
      }

      subtitulo = subCategoria[x];
      datos = this.generateData(preguntasxSubcategoria,data);
      datos = this.clearDatos(datos);
      if(datos.length>0){
        this.dataset.push({"titulo":titulo,"subtitulo":subtitulo, "datos":datos});
      }
    }
  }

  exportarPrioridadesPDF(idPrueba){
    this.dataset = [];
    let objeto = {datos:[],titulo:null,subtitulo:null};
    
    this.clasificaciones=[];
    this.isLoading = true;
    this._evaluacionesService.getEvaluacionPrioritarias(idPrueba).subscribe(result=>{

      //console.info(result);
      this._evaluacionesService.getNombreEvaluacion(idPrueba).subscribe(evaluacion=>{

        let clasificaciones = [];
      
        let data = result;
        console.log("data: ", data);
        console.log("data: ", clasificaciones);
        this.insertPreguntasExportPrioridadesPDF(data, clasificaciones);
        console.log("data desp insertPreguntasExportPrioridadesPDF: ", data);
        console.log("clasificaciones desp insertPreguntasExportPrioridadesPDF: ", clasificaciones);
        // for(let i =0; i< data.preguntas.length; i++){
        //   if(clasificaciones.indexOf(data.preguntas[i].idClasificacion)<0){
        //     clasificaciones.push(data.preguntas[i].idClasificacion);
        //   }
        // }
        let clasificacion=[];
        this.insertClasificacionesExportPrioridadesPDF(data, clasificaciones);
        console.log("data desp insertClasificacionesExportPrioridadesPDF: ", data);
        console.log("clasificaciones desp insertClasificacionesExportPrioridadesPDF: ", clasificaciones);
        // for(let i=0; i<data.clasificaciones.length; i++){
        //   if(clasificaciones.indexOf(data.clasificaciones[i].idClasificacion)>-1){
        //     this.clasificaciones.push(data.clasificaciones[i]);
        //   }
        // }
        //console.info(this.clasificaciones);
        console.info("Creando PDF");
        let doc = new jsPDF('p','px','a4');
        doc.setFont("helvetica", "bold");
        doc.text("REPORTE GENERAL DE EVALUACIONES (PRIORIDADES)", 90, 20,{align:"left"},null);
        doc.setFont("helvetica", "normal");
        doc.text((evaluacion.data[0].nombre), 90, 35,{align:"left"},null);
        doc.setFontSize(10);
        
        doc.addImage("assets/img/logo-goretti.jpg","JPEG", 8, 0, 80.4, 57.2);
        let iy=55;
        this.checkBeneficiariaTieneFotoExportPrioridadesPDF(this.beneficiaria, doc, iy);
        // if(this.beneficiaria.foto!="URL FOTO"){
        //   //this.convertImgToBase64("/apiphp/"+this.beneficiaria.foto,null);
        //   doc.addImage(environment.apiUrl+"/"+this.beneficiaria.foto,"JPEG",15, iy+5, 80, 80);
        // }else{
        //   doc.addImage("assets/img/unknown.jpg","JPEG", 12, iy+7, 50, 51);
        // } 

        





        //*********CODE COPy */
        for(let i=0; i<this.clasificaciones.length;i++){
          //console.info("Categoría > " +this.clasificaciones[i].nombre);
          /**Se comenta para pasar a html */
          
          this.checkTieneFichaTecnicaExportPrioridadesPDF(i,doc,iy)
          // if(i==0){
          //   this.imprimirFichaTecnica(doc,iy);
          // }
          
          //Selección de preguntas x categoría
          let preguntas=[];
          this.insertPreguntasPorCategoriaExportPrioridadesPDF(data, this.clasificaciones, i, preguntas)
          // for(let x=0; x < data.preguntas.length;x++){
          //   if(this.clasificaciones[i].idClasificacion == data.preguntas[x].idClasificacion){
          //     preguntas.push(data.preguntas[x]);
          //   }
          // }
  
          //Selección de subcategorías
          let subCategoria = [];
          this.insertSubcategoriasExportPrioridadesPDF(preguntas, subCategoria)
          // for(let x=0; x<preguntas.length; x++){
          //   if(subCategoria.indexOf(preguntas[x].descripcion.split("|")[0])<0){
          //     subCategoria.push(preguntas[x].descripcion.split("|")[0]);
          //   }
          // }
          //Obteniendo preguntas por subcategoría
          let preguntasxSubcategoria=[];
          // this.getPreguntasPorCategoriaExportPrioridadesPDF(subCategoria, objeto, preguntasxSubcategoria, preguntas, this.clasificaciones, data, this.dataset, i)
          this.getPreguntasPorCategoriaExportPrioridadesPDF(subCategoria, objeto, preguntas, this.clasificaciones, data, this.dataset, i)
          // for(let x=0; x<subCategoria.length; x++){
          //   objeto.titulo = null;
          //   objeto.subtitulo = null;
          //   objeto.datos = [];
  
          //   preguntasxSubcategoria=[];
          //   for(let y = 0; y<preguntas.length;y++){
          //     if(subCategoria[x] == preguntas[y].descripcion.split("|")[0]){
          //       preguntasxSubcategoria.push(preguntas[y]);
          //     }
          //   }
  
          //   //console.info(" >" +subCategoria[x]);
          //   let titulo = null;
          //   let subtitulo = null;
          //   let datos = [];
          //   if(x == 0){
              
              
          //     titulo = this.clasificaciones[i].nombre;
              
              
          //   }else{
              
          //     objeto.titulo = null;
              
              
          //   }
  
          //   subtitulo = subCategoria[x];
          //   datos = this.generateData(preguntasxSubcategoria,data);
          //   datos = this.clearDatos(datos);
          //   if(datos.length>0){
          //     this.dataset.push({"titulo":titulo,"subtitulo":subtitulo, "datos":datos});
          //   }
          // }
        }
        //***CODE COPY */
        //console.info(this.dataset);

        setTimeout(()=>{
          this.makePDF("RPRI-"+this.obtenerIniciales(evaluacion.data[0].nombre)+"-EV"+idPrueba+"BE"+this.beneficiaria.id,idPrueba,doc,iy+130);
        },0);

      });
      
      
      

    });
  }
  clearDatos(datos){
    let datos2=[];
    for(let i=0; i<datos.length;i++){
      if(datos[i].COMENTARIOS.trim().length>0 || datos[i].RESULTADO.trim().length>0){
        datos2.push(datos[i]);
      }
    }
    return datos2;
  }

  public insertPreguntasExportPDF(data, clasific){
    for(let i =0; i< data.data.preguntas.length; i++){
      if(clasific.indexOf(data.data.preguntas[i].idClasificacion)<0){
        clasific.push(data.data.preguntas[i].idClasificacion);
      }
    }
  }

  public insertClasificacionesExportPDF(data, clasific){
    for(let i=0; i<data.data.clasificaciones.length; i++){
      if(clasific.indexOf(data.data.clasificaciones[i].idClasificacion)>-1){
        this.clasificaciones.push(data.data.clasificaciones[i]);
      }
    }
  }

  public checkBeneficiariaTieneFotoExportPDF(benef, doc, iy){
    if(benef.foto!="URL FOTO"){
        doc.addImage(this._config.getConfiguration().hostapi+"/"+this.beneficiaria.foto,"JPEG",15, iy+5, 80, 80);
      }else{
        doc.addImage("assets/img/unknown.jpg","JPEG", 12, iy+7, 50, 51);
      }
  }

  public checkTieneFichaTecnicaExportPDF(i,doc,iy){
    if(i==0){
      this.imprimirFichaTecnica(doc,iy);
    }
  }

  public insertPreguntasPorCategoriaExportPDF(data, clasific, i, preguntas){
    for(let x=0; x < data.data.preguntas.length;x++){
      if(clasific[i].idClasificacion == data.data.preguntas[x].idClasificacion){
        preguntas.push(data.data.preguntas[x]);
      }
    }
  }
  
  public insertSubcategoriasExportPDF(preguntas, subCat){
    for(let x=0; x<preguntas.length; x++){
      if(subCat.indexOf(preguntas[x].descripcion.split("|")[0])<0){
        subCat.push(preguntas[x].descripcion.split("|")[0]);
      }
    }
  }

  public getPreguntasPorCategoriaExportPDF(subCategoria, objeto, preguntasxSubcategoria, preguntas, clasific, data, dataset, i){
    for(let x=0; x<subCategoria.length; x++){
      objeto.titulo = null;
      objeto.subtitulo = null;
      objeto.datos = [];

      preguntasxSubcategoria.length = 0;
      for(let y = 0; y<preguntas.length;y++){
        if(subCategoria[x] == preguntas[y].descripcion.split("|")[0]){
          preguntasxSubcategoria.push(preguntas[y]);
        }
      }

      //  console.info(" >" +subCategoria[x]);
      let titulo = null;
      let subtitulo = null;
      let datos = [];
      if(x == 0){
        
        //doc.text(subCategoria[x], 10, iy+90);
        //doc.setDrawColor("gray");
        //doc.setFillColor("red");
        titulo = clasific[i].nombre;
        
        //doc.table(10, iy+95, this.generateData(preguntasxSubcategoria,data), this.headers, { autoSize: false, fontSize: 10,  });
      }else{
        //doc.text(this.clasificaciones[i].nombre, 10, 10);
        //doc.text(subCategoria[x], 10, 20);
        objeto.titulo = null;
        
        //doc.table(10, 30, this.generateData(preguntasxSubcategoria,data), this.headers, { autoSize: false, fontSize: 10 });
      }

      subtitulo = subCategoria[x];
      datos = this.generateData(preguntasxSubcategoria,data.data);
      this.dataset.push({"titulo":titulo,"subtitulo":subtitulo, "datos":datos});
      
    }
  }
  exportarPDF(idPrueba){
    this.dataset = [];
    let objeto = {datos:[],titulo:null,subtitulo:null};
    
    this.clasificaciones=[];
    this.isLoading = true;
    this._evaluacionesService.getEvaluacion(idPrueba).subscribe(result=>{

      this._evaluacionesService.getNombreEvaluacion(idPrueba).subscribe(evaluacion=>{
        
        
      //this.createForm(result.data.preguntas);
      //this.setRespuestas(result.data.resultados);
      let clasificaciones = [];
      
      let data = result;
      //const doc = new jsPDF();
      this.insertPreguntasExportPDF(data, clasificaciones);
      // for(let i =0; i< data.data.preguntas.length; i++){
      //   if(clasificaciones.indexOf(data.data.preguntas[i].idClasificacion)<0){
      //     clasificaciones.push(data.data.preguntas[i].idClasificacion);
      //   }
      // }
      let clasificacion=[];
      this.insertClasificacionesExportPDF(data, clasificaciones);
      // for(let i=0; i<data.data.clasificaciones.length; i++){
      //   if(clasificaciones.indexOf(data.data.clasificaciones[i].idClasificacion)>-1){
      //     this.clasificaciones.push(data.data.clasificaciones[i]);
      //   }
      // }
      

      console.info("Creando PDF");
      
          
      let doc = new jsPDF('p','px','a4');
      doc.setFont("helvetica", "bold");
      doc.text("REPORTE GENERAL DE EVALUACIONES", 90, 20,{align:"left"},null);
      doc.setFont("helvetica", "normal");
      doc.text((evaluacion.data[0].nombre), 90, 35,{align:"left"},null);
      doc.setFontSize(10);
      
      doc.addImage("assets/img/logo-goretti.jpg","JPEG", 8, 0, 80.4, 57.2);
      let iy=55;
      this.checkBeneficiariaTieneFotoExportPDF(this.beneficiaria, doc, iy)
      // if(this.beneficiaria.foto!="URL FOTO"){
      //   //this.convertImgToBase64("/apiphp/"+this.beneficiaria.foto,null);
      //   doc.addImage(environment.apiUrl+"/"+this.beneficiaria.foto,"JPEG",15, iy+5, 80, 80);
      // }else{
      //   doc.addImage("assets/img/unknown.jpg","JPEG", 12, iy+7, 50, 51);
      // }
      
      for(let i=0; i<this.clasificaciones.length;i++){
        // console.info("Categoría > " +this.clasificaciones[i].nombre);
        /**Se comenta para pasar a html */
        
        this.checkTieneFichaTecnicaExportPDF(i,doc,iy)
        // if(i==0){
        //   this.imprimirFichaTecnica(doc,iy);
        // }

        /*doc.setFillColor("#000");
        doc.setFont("helvetica", "bold");
        doc.text(this.clasificaciones[i].nombre, 10, i==0 ? iy+85:15);
        doc.setFont("helvetica", "normal");
        */
        

        //Selección de preguntas x categoría
        let preguntas=[];
        this.insertPreguntasPorCategoriaExportPDF(data, this.clasificaciones, i, preguntas)
        // for(let x=0; x < data.data.preguntas.length;x++){
        //   if(this.clasificaciones[i].idClasificacion == data.data.preguntas[x].idClasificacion){
        //     preguntas.push(data.data.preguntas[x]);
        //   }
        // }

        //Selección de subcategorías
        let subCategoria = [];
        this.insertSubcategoriasExportPDF(preguntas, subCategoria)
        // for(let x=0; x<preguntas.length; x++){
        //   if(subCategoria.indexOf(preguntas[x].descripcion.split("|")[0])<0){
        //     subCategoria.push(preguntas[x].descripcion.split("|")[0]);
        //   }
        // }
        //Obteniendo preguntas por subcategoría
        let preguntasxSubcategoria=[];
        this.getPreguntasPorCategoriaExportPDF(subCategoria, objeto, preguntasxSubcategoria, preguntas, this.clasificaciones, data, this.dataset, i);
        // for(let x=0; x<subCategoria.length; x++){
        //   objeto.titulo = null;
        //   objeto.subtitulo = null;
        //   objeto.datos = [];

        //   // preguntasxSubcategoria=[];
        //   preguntasxSubcategoria.length = 0
          
        //   for(let y = 0; y<preguntas.length;y++){
        //     if(subCategoria[x] == preguntas[y].descripcion.split("|")[0]){
        //       preguntasxSubcategoria.push(preguntas[y]);
        //     }
        //   }

        //    console.info(" >" +subCategoria[x]);
        //   let titulo = null;
        //   let subtitulo = null;
        //   let datos = [];
        //   if(x == 0){
            
        //     //doc.text(subCategoria[x], 10, iy+90);
        //     //doc.setDrawColor("gray");
        //     //doc.setFillColor("red");
        //     titulo = this.clasificaciones[i].nombre;
            
        //     //doc.table(10, iy+95, this.generateData(preguntasxSubcategoria,data), this.headers, { autoSize: false, fontSize: 10,  });
        //   }else{
        //     //doc.text(this.clasificaciones[i].nombre, 10, 10);
        //     //doc.text(subCategoria[x], 10, 20);
        //     objeto.titulo = null;
            
        //     //doc.table(10, 30, this.generateData(preguntasxSubcategoria,data), this.headers, { autoSize: false, fontSize: 10 });
        //   }

        //   subtitulo = subCategoria[x];
        //   datos = this.generateData(preguntasxSubcategoria,data.data);
        //   this.dataset.push({"titulo":titulo,"subtitulo":subtitulo, "datos":datos});
          
        // }
        

      }
      /*for(let i=0; i<this.clasificaciones.length;i++){
        doc.setFontSize(10);
        doc.text(this.clasificaciones[i].nombre, 10, 10);
        doc.table(10, 20, this.generateData(data.data.preguntas), this.headers, { autoSize: false, fontSize: 10 });
        doc.addPage();
      }*/
      
      console.info(this.dataset);
      //doc.close();
      setTimeout(()=>{
        this.makePDF("RGEN-"+this.obtenerIniciales(evaluacion.data[0].nombre)+"-EV"+idPrueba+"BE"+this.beneficiaria.id,idPrueba,doc,iy+130);
      },0);
     
    
      
      
      
      //this.obtenerClasificaciones();
      })
      
    },()=>{
      this.isLoading = false;
    });
    
  }

  obtenerIniciales(cadena:string){
    let iniciales = "";
    let arreglo=cadena.split(" ");
    for(let i=0; i<arreglo.length;i++){
      iniciales+=arreglo[i][0];
    }
    return iniciales.toUpperCase();
  }
  imprimirFichaTecnica(doc,iy){
    doc.rect(10, iy, 425, 120);
    doc.setFont("helvetica", "bold");
    doc.text("Nombre completo".toUpperCase(),100,iy+10);
    doc.text("Fecha de nacimiento".toUpperCase(),100,iy+25);
    doc.text("Canalizada por".toUpperCase(),100,iy+40);
    doc.text("Fecha de ingreso".toUpperCase(),100,iy+55);
    doc.text("Tipo de discapacidad".toUpperCase(),100,iy+70);
    doc.text("Diagnóstico DN".toUpperCase(),100,iy+85);
    doc.text("Condición relevante".toUpperCase(),100,iy+100);

    doc.text("Curp".toUpperCase(),270,iy+10);
    doc.text("Edad actual".toUpperCase(),270,iy+25);
    doc.text("Motivo de ingreso".toUpperCase(),270,iy+40);
    doc.text("Edad al ingreso".toUpperCase(),270,iy+55);
    doc.text("Tiempo de estancia".toUpperCase(),270,iy+70);
    doc.text("Diagnóstico DM".toUpperCase(),270,iy+85);
    doc.text("Tipo de sangre".toUpperCase(),270,iy+100);
    
    doc.setFont("helvetica", "normal");
    doc.text(this.ficha.nombreCompleto,100,iy+16);
    doc.text(this.ficha.fechaNacimiento,100,iy+31);
    doc.text(this.ficha.canalizadaPor ,100,iy+46);
    doc.text(this.ficha.fechaIngreso,100,iy+61);
    doc.text(this.ficha.tipoDeDiscapacidad,100,iy+76);
    doc.text(this.ficha.diagnosticoDN,100,iy+91);
    doc.text(this.ficha.condicionRelevante,100,iy+106);

    //doc.text(this.beneficiaria.nombre,50,10);

    doc.text(this.ficha.curp ? this.ficha.curp : "-" ,270,iy+16);
    doc.text(this.ficha.edadActual,270,iy+31);
    doc.text(this.ficha.motivoDeIngreso,270,iy+46);
    doc.text(this.ficha.edadIngreso,270,iy+61);
    doc.text(this.ficha.tiempoDeEstancia,270,iy+76);
    doc.text(this.ficha.diagnosticoDM,270,iy+91);
    doc.text(this.ficha.tipoDeSangre,270,iy+106);
  }
  makePDF(titulo,idPrueba,doc,altura){
    
    doc.setFontSize(9);
      doc.html(this.el.nativeElement,{
          callback:(doc)=>{
          doc.save(titulo+""+".pdf");
          this.isLoading = false;
        
        },x:10,y:altura
      });
  }
  generateData = function(preguntas,data) {
    var result = [];
    for(var i = 0; i < preguntas.length; i += 1){
      let respuesta = this.buscaRespuesta(preguntas[i].idPregunta,data);

      result.push({
        //NO: "NO",
        ACTIVIDAD: (preguntas[i].descripcion.split("|")[1]).toString(),
        RESULTADO: " "+respuesta.idRespuesta != null ? respuesta.idRespuesta: " ",
        COMENTARIOS: " "+respuesta.texto
      });
    }
    //console.info(result);
    return result;

  };

  obtenerDescripcionRespuesta(idRespuesta,data){
    for(let i= 0; i< data.respuestas.length;i++){
      if(idRespuesta == data.respuestas[i].idRespuesta){
        return data.respuestas[i].descripcion;
      }
    }
    return " ";
  }
  buscaRespuesta(idPregunta,data){
    for(let i= 0; i< data.resultados.length;i++){
      if(idPregunta == data.resultados[i].idPregunta){
        let respuesta = data.resultados[i];
        respuesta.idRespuesta = this.obtenerDescripcionRespuesta(respuesta.idRespuesta,data);
        return respuesta;
      }
    }
    return {
      texto : " ", idRespuesta: " "
    };
  }
  headers = this.createHeaders([
    //"NO",
    "ACTIVIDAD",
    "RESULTADO",
    "COMENTARIOS"
  ]);

  
  createHeaders(keys) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 85,
        align: "left",
        padding: 0,
        headerBackgroundColor: "#000000",
      });
    }
    return result;
  }
  beneficiaria:Beneficiaria = new Beneficiaria();
  ficha:any;
  recibeBeneficiaria($event){
    console.info("Beneficiaria recibida");
    console.info($event);
    this.beneficiaria = $event;
  }
  recibeFichaTecnica($event){
    console.info("Ficha técnica recibida");
    console.info($event);
    this.ficha = $event;
  }
  idRerpot;
  getSpecialReport(id,categoria){
    this.idRerpot = id;
    let categorias=[];
    let subcategorias = [];
    let respuestas = []
    let nombreCategorias = [];
    this._evaluacionesService.getSpecialReport(id,categoria).subscribe(result=>{
      //obteniendo Categorías
      for(let i =0; i<result.data.length; i++){

        respuestas.push(result.data[i].lo_logra);
        respuestas.push(result.data[i].proceso);
        respuestas.push(result.data[i].no_lo_logra);
        respuestas.push(result.data[i].no_aplica);


        if(i>0){
          if(result.data[i].clasificacion === result.data[i-1].clasificacion){
            categorias[categorias.length-1] = categorias[categorias.length-1] + this.parser(result.data[i].lo_logra) + this.parser(result.data[i].no_lo_logra) + this.parser(result.data[i].proceso) + this.parser(result.data[i].no_aplica);
            if(result.data[i].subcategoria === result.data[i-1].subcategoria){
              subcategorias[subcategorias.length-1] = subcategorias[subcategorias.length-1] + this.parser(result.data[i].lo_logra) + this.parser(result.data[i].no_lo_logra) + this.parser(result.data[i].proceso) + this.parser(result.data[i].no_aplica);
            }else{
              subcategorias.push(this.parser(result.data[i].lo_logra) + this.parser(result.data[i].no_lo_logra) + this.parser(result.data[i].proceso) + this.parser(result.data[i].no_aplica));
            }
          }else{
            nombreCategorias.push(result.data[i].clasificacion);
            categorias.push(this.parser(result.data[i].lo_logra) + this.parser(result.data[i].no_lo_logra) + this.parser(result.data[i].proceso) + this.parser(result.data[i].no_aplica)); 
            subcategorias.push(this.parser(result.data[i].lo_logra) + this.parser(result.data[i].no_lo_logra) + this.parser(result.data[i].proceso) + this.parser(result.data[i].no_aplica));
          }
        }else{
          nombreCategorias.push(result.data[i].clasificacion);
          categorias.push(this.parser(result.data[i].lo_logra) + this.parser(result.data[i].no_lo_logra) + this.parser(result.data[i].proceso) + this.parser(result.data[i].no_aplica));
          subcategorias.push(this.parser(result.data[i].lo_logra) + this.parser(result.data[i].no_lo_logra) + this.parser(result.data[i].proceso) + this.parser(result.data[i].no_aplica));
        }
      }
      this.doughnutChartLabels = nombreCategorias;
      
      this.doughnutChartData = [
        categorias,
        subcategorias,
        respuestas
      ];
      //No entiendo que intentaban devolviendo ambas cosas con las dos condicionales
      // if(this.selectedCategoria){
      //   this.doughnutChartData = [
      //     categorias,
      //     subcategorias,
      //     respuestas
      //   ];
      // }else{
      //   this.doughnutChartData = [
      //     categorias,
      //     subcategorias,
      //     respuestas
      //   ];
      // }
      
    });
  }
  selectedCategoria=null;
  parser(data){
    if(data){
      data = parseInt(data);
    }
    return data;
  }
}
