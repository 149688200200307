//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { MedicoService } from 'src/app/services/medico.service';
import { SesionService } from 'src/app/services/sesion.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios

@Component({
  selector: 'app-gestor-alm-inventario',
  templateUrl: './gestor-alm-inventario.component.html',
  styleUrls: ['./gestor-alm-inventario.component.scss'],
  providers: [ MedicoService ]
})
export class GestorAlmInventarioComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    buscador: new UntypedFormControl('', []),
    ruta: new UntypedFormControl('', []),
  });
  /*tabla*/
  public gai_length: number = 100;;
  public gai_pageSize: number = 25;
  public gai_pageSizeOptions: Array<number> = [5, 10, 25, 100];
  public gai_page: number = 1;
  public gai_displayedColumns: any =[
    'gai_foto', 
    'gai_tipo',
    'gai_nombre',
    'gai_presentacion',
    'gai_inventario',
    'gai_consultar',
    'gai_modificar',
    'gai_eliminar'];
  public dataSource: any;
  /*fin tabla*/
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public laravelapi: any;
  public laravelapistorage: any;
  public acciones: any;
  public gai_registrar: boolean = false;
  public gai_consultar: boolean = false;
  public gai_modificar: boolean = false;
  public gai_eliminar: boolean = false;
  public t: any;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _medicoService: MedicoService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _sesionService:SesionService,
    private _cdRef:ChangeDetectorRef,
  ){ 
    this.getConsultaMedicamentosInv(1);
    this.laravelapi = this._medicoService.getLaravelApi();
    this.laravelapistorage = this._medicoService.getLaravelApiStorage();
    this.gai_registrar = this.consultarAccionUsuario("/medico/almacen-de-medicamentos/gestor-inventario/registrar");
    this.gai_consultar = this.consultarAccionUsuario("/medico/almacen-de-medicamentos/gestor-inventario/consultar");
    this.gai_modificar = this.consultarAccionUsuario("/medico/almacen-de-medicamentos/gestor-inventario/modificar");
    this.gai_eliminar = this.consultarAccionUsuario("/medico/almacen-de-medicamentos/gestor-inventario/eliminar");


  }

  ngOnInit(): void {
  }

  /*permisos*/
  public consultarAccionUsuario(url: any){
    if(this._sesionService.getSession().getUsuario().nombre != undefined){
      this.acciones = this._sesionService.getSession().getAcciones();
      console.log(this.acciones);
      if(this.acciones.filter(x => x.url == url)[0]){
        return true;        
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  /*fin permisos*/

  public getConsultaMedicamentosInv(page: number){
    this.isLoading = true;
    this.gai_page = page;
    this._medicoService.getConsultaMedicamentosInv(this.gai_page, this.gai_pageSize, this.form.value.buscador).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.gai_length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.gai_pageSize = event.pageSize;
    this.getConsultaMedicamentosInv(event.pageIndex + 1);
  }

  public getEliminarMedicamentosInv(id: number){
    this.isLoading = true;
    this._medicoService.getEliminarMedicamentosInv(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Correct', mensaje: 'Eliminado Correctamente.', icon:'correct'}});
          this.getConsultaMedicamentosInv(this.gai_page);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el servicio', icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }

}
