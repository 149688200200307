<div class="card-container" id="top">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Formulario de perfil y descripción de puesto</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <mat-tab-group mat-align-tabs="start" [(selectedIndex)]="selectedIndex">
                <mat-tab label="Descripción del puesto">
                    <fieldset>
                        <legend></legend>
                        <app-rrhh-descripcion-de-puesto [selectedIndex]="selectedIndex" (siguienteEvento)="siguienteEvento($event)"></app-rrhh-descripcion-de-puesto>
                    </fieldset>
                </mat-tab>
                <mat-tab label="Funciones">
                    <fieldset>
                        <legend></legend>
                        <app-rrhh-funciones [selectedIndex]="selectedIndex" (siguienteEvento)="siguienteEvento($event)"></app-rrhh-funciones>
                    </fieldset>
                </mat-tab>
                <mat-tab label="Perfil de puesto">
                  <fieldset>
                    <legend></legend>
                    <app-rrhh-perfil-de-puesto [selectedIndex]="selectedIndex" (siguienteEvento)="siguienteEvento($event)"></app-rrhh-perfil-de-puesto>
                </fieldset>
            </mat-tab>
        </mat-tab-group>

    </mat-card-content>
</mat-card>
</div>