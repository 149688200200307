<app-rrhh-ficha-informativa></app-rrhh-ficha-informativa>


<div class="card-container" id="top">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title class="fw-bold fs-5">Control de vacaciones</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>

            


            <div class="row mt-5">
              
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-start">
                        <div class="row">
                            <span class="fw-bold">  Hoja de planeación de vacaciones</span>
                            <div class="col">
                                <div  class="input-group-sm">
                                    <input  *ngIf="accion == 'modificar' && data?.empleado?.datos_puesto?.vaca_emp_doc?.length > 1; else NoDoc"  type="file"  class="form-control" (change)="postSubirDocumentoPlaneacionVacaciones($event, data?.empleado?.datos_puesto?.id_datos_puestos_pk, data?.empleado?.datos_puesto?.vaca_emp_doc[0]?.id_vaca_emp_doc_pk)" >
                                    <ng-template #NoDoc>
                                        <input  *ngIf="accion == 'modificar'"  type="file"  class="form-control" (change)="postSubirDocumentoPlaneacionVacaciones($event, data?.empleado?.datos_puesto?.id_datos_puestos_pk)" >
                                    </ng-template>
                                </div>
                            </div>
                           
                            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                               
                                    <a *ngIf="data?.empleado?.datos_puesto?.vaca_emp_doc[0]?.ruta_relativa != null"  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{data?.empleado?.datos_puesto?.vaca_emp_doc[0]?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                                      
                                        <mat-icon class="material-icons">
                                            file_open
                                        </mat-icon>
                                    </a>
                                
                            </div>
                        </div>
                       
                            
                           
                       
                       
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-end">
                        <span>  . </span> <br>
                        <div class="row">
                            <div class="col">
                                <a  color="accent" class="flat" mat-flat-button  href="{{laravelapi}}/ExcelReporteVacacion" target="_blank" rel=noopener>
                                    <mat-icon>list_alt</mat-icon>&nbsp;Registro Excel Vacaciones
                                </a>
                            </div>
                            
                        </div>
                       
                    </div>
                  
               
            </div>

            <div class="row mt-5">
                <div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Fecha de ingreso </mat-label>
                        <input readonly="true" type="text" matInput  [value]="data?.fecha_ingreso">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Antigüedad (años, meses, dias)  </mat-label>
                        <input readonly="true" type="text" matInput  [value]="data?.tiempo_laborando">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Días que corresponden </mat-label>
                        <input readonly="true" type="text" matInput  [value]="data?.vacaciones_corresponden">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">

                </div>
                <div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Días ejercidos </mat-label>
                        <input readonly="true" type="text" matInput  [value]="data?.vacaciones_tomadas_empleado">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Días programados </mat-label>
                        <input readonly="true" type="text" matInput  [value]="data?.vacaciones_por_tomar">
                    </mat-form-field>
                </div>
            </div>


            <!--apartado 2-->
            {{diasSeleccionados()}}
            {{noDias()}}
            <div class="row mb-3">
                <span class="fw-bold fs-5">Registro de periodos de vacaciones</span><br><br>
                <span >“Oprima el icono del calendario y seleccione los días de vacaciones que desea programar”</span><br>
                
            </div>
            <form novalidate [formGroup]="form" autocomplete="off">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>Seleccionar los días </mat-label>
                                <mat-chip-list #chipList  (click)="picker.open()">
                                  <mat-chip
                                    *ngFor="let value of model"
                                    [selectable]="false"
                                    [removable]="true"
                                    (removed)="remove(value, model)"
                                  >
                                  <!--{{ value | date }}-->
                                    {{ value  }}
                                    <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                                  </mat-chip>
                                  <input
                                    [value]="resetModel"
                                    matInput
                                    [matDatepicker]="picker"
                                    placeholder="Choose a date"
                                    (dateChange)="dateChanged($event, model)"
                                    [matChipInputFor]="chipList"
                                    hidden
                                    
                                  />
                                </mat-chip-list>
                                <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker
                                  #picker
                                  [startAt]="init"
                                ></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <!--<div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>Días seleccionados </mat-label>
                                    <input readonly="true"  type="text" matInput  [value]="ds">
                                </mat-form-field>
                            </div>-->
                            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>No de Días </mat-label>
                                    <input readonly="true"  type="text" matInput  [value]="nds">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>Comentarios </mat-label>
                                    <textarea rows="2"  type="text" matInput  formControlName="comentarios"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <button *ngIf="accion == 'modificar'" type="button" color="primary"  mat-mini-fab  (click)="postRegistrarVacacion()" >
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </form>

            <div class="row">
                <span class="fw-bold fs-5">Validación de periodos</span><br>
            </div>
           
            <div class="row mt-1" *ngFor="let y of data?.lista_vacaciones; let q = index">
                <div class="row">
                    <span class="fs-6">Registro periodo {{q + 1}}</span><br>
                </div>
                <div class="col">
                    <div class="row">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Seleccionar los días </mat-label>
                            <mat-chip-list #chipList  (click)="picker.open()">
                                
                              <mat-chip
                                *ngFor="let value of y.model"
                                [selectable]="false"
                                [removable]="true"
                                (removed)="remove(value, y.model)"
                              >
                              <!--{{ value | date }}-->
                                {{ value  }}
                                <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                              </mat-chip>
                              <input
                                [value]="resetModel"
                                matInput
                                [matDatepicker]="picker"
                                placeholder="Choose a date"
                                (dateChange)="dateChanged($event, y.model)"
                                [matChipInputFor]="chipList"
                                hidden
                                
                              />
                            </mat-chip-list>
                            <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker
                              #picker
                              [startAt]="init"
                            ></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <!--<div class="col">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>Días seleccionados </mat-label>
                                <input readonly="true"  type="text" matInput  [value]="modelModificados(y?.model)">
                            </mat-form-field>
                        </div>-->
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>No de Días </mat-label>
                                <input readonly="true"  type="text" matInput  [value]="y?.model?.length">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>Comentarios </mat-label>
                                <textarea rows="2"  type="text" matInput [(ngModel)]="y.comentarios" ></textarea>
                            </mat-form-field>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col">
                            <div  class="input-group">
                                <input  *ngIf="accion == 'modificar' && y?.id_estado_boton_fk == 1"  type="file"  class="form-control" (change)="postSubirDocumentoVacacion($event, y?.id_vacacion_empleado)" >
                                
                            </div>
                        </div>
                        <div class="col">
                            <a *ngIf="y?.ruta_relativa != null"  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{y?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                                Planeación de vacaciones actualizada
                                <mat-icon class="material-icons">
                                    file_open
                                </mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-xl-2 col-xxl-2">
                    <button *ngIf="accion == 'modificar' && y?.id_estado_boton_fk == 1"  (click)="postModificarVacacion(y)" class="flat" color="primary" mat-flat-button type="button">Modificar</button>
                    <br><br>
                    <button *ngIf="accion == 'modificar' && y?.id_estado_boton_fk == 1 && y?.ruta_relativa != null" (click)="postModificarVacacion(y, 11)" class="flat" color="primary" mat-flat-button type="button">&nbsp;&nbsp;Validar&nbsp;&nbsp;</button>
                </div>
            </div>  


            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <span class="fw-bold fs-5">Histórico de vacaciones</span><br>
            <div class="row mt-3">
                <div class="col">
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>Seleccione el año</mat-label>
                        <mat-select [(ngModel)]="anns" (ngModelChange)="getConsultarHistoricoVacacion( form.value.numero_datos_puestos, anns)">
                            <mat-option  *ngFor="let p of data?.control_vacaciones" [value]="p.id_control_vacaciones_pk">{{p.etiqueta}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <div class="row" *ngFor="let h of data_h; let o = index">
                        <span class="fw-bold">Solicitud {{o + 1}} . {{h?.dias_solicitados}} días solicitados </span><br>
                        <mat-chip-list>
                            <mat-chip
                              cdkDrag
                              *ngFor="let g of h?.intervalos_vacaciones.split('/')">
                              {{g}}
                            </mat-chip>
                        </mat-chip-list>
                        <br><br>
                    </div>
                </div>
            </div>
            


        </mat-card-content>
    </mat-card>
</div>