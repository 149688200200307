//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//Fin Tabla


@Component({
  selector: 'app-gestor-rrhh-area-departamento',
  templateUrl: './gestor-rrhh-area-departamento.component.html',
  styleUrls: ['./gestor-rrhh-area-departamento.component.scss'],
  providers: [ RrhhService ]
})
export class GestorRrhhAreaDepartamentoComponent implements OnInit {

  /*tabla*/
  public length: number = 100;;
  public pageSize: number = 25;
  public pageSizeOptions: Array<number> = [5, 10, 25, 100];
  public page: number = 1;
  public displayedColumns: any =[
    'id', 
    'area_o_departamento',
    'modificar',
    'eliminar'];
  public dataSource: any;
  /*fin tabla*/
  public isLoading: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _rrhhService: RrhhService,
    private _router: Router,
    private _route: ActivatedRoute,
  ){ 
    
  }

  ngOnInit(): void {
    this.getConsultarDepartamentos(1);
  }

  public getConsultarDepartamentos(page: number){
    this.isLoading = true;
    this.page = page;
    this._rrhhService.getConsultarDepartamentos(this.page, this.pageSize).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.pageSize = event.pageSize;
    this.getConsultarDepartamentos(event.pageIndex + 1);
  }

  public postEliminarDepartamento(id: number){
    this.isLoading = true;
    this._rrhhService.postEliminarDepartamento({numero_depa: id}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultarDepartamentos(this.page);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }



}
