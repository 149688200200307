//General
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';


@Component({
  selector: 'app-rrhh-descripcion-de-puesto',
  templateUrl: './rrhh-descripcion-de-puesto.component.html',
  styleUrls: ['./rrhh-descripcion-de-puesto.component.scss'],
  providers: [ RrhhService ]
})
export class RrhhDescripcionDePuestoComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    numero_descripcion: new UntypedFormControl(-1, []),
    titulo_puesto: new UntypedFormControl('', [Validators.required]),
    numero_departamento: new UntypedFormControl('', [Validators.required]),
    numero_puesto: new UntypedFormControl('', [Validators.required]),
    puesto_reporta_aux: new UntypedFormControl('', []),
    puesto_reporta: [],
    codigo_puesto: new UntypedFormControl('', [Validators.maxLength(250)]),
    sub_area: new UntypedFormControl('', [Validators.maxLength(250), Validators.required]),
    numero_plazas: new UntypedFormControl('', [Validators.maxLength(3), Validators.pattern("^[0-9]*$")]),
    numero_personas: new UntypedFormControl('0', [Validators.maxLength(3), Validators.pattern("^[0-9]*$")]),
    horario_inicio: new UntypedFormControl('', [Validators.required]),
    horario_fin: new UntypedFormControl('', [Validators.required]),
    dias_laborales: new UntypedFormControl('', []),
    dias_descanso: new UntypedFormControl('', []),
    objetivo_principal: new UntypedFormControl('', [Validators.maxLength(2000), Validators.required]),
    contribucion: new UntypedFormControl('', [Validators.maxLength(2000), Validators.required]),
    responsabilidad: new UntypedFormControl('', [Validators.maxLength(4000)]),
    indicadores: new UntypedFormControl('', [Validators.maxLength(2000)]),
    contactos_internos: new UntypedFormControl('', [Validators.maxLength(500)]),
    contactos_externos: new UntypedFormControl('', [Validators.maxLength(500)]),



    dl_l: new UntypedFormControl(false, []),
    dl_ma: new UntypedFormControl(false, []),
    dl_mi: new UntypedFormControl(false, []),
    dl_j: new UntypedFormControl(false, []),
    dl_v: new UntypedFormControl(false, []),
    dl_s: new UntypedFormControl(false, []),
    dl_d: new UntypedFormControl(false, []),
    dd_l: new UntypedFormControl(false, []),
    dd_ma: new UntypedFormControl(false, []),
    dd_mi: new UntypedFormControl(false, []),
    dd_j: new UntypedFormControl(false, []),
    dd_v: new UntypedFormControl(false, []),
    dd_s: new UntypedFormControl(false, []),
    dd_d: new UntypedFormControl(false, []),





    estatus: new UntypedFormControl(1, []),

  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  @Input() selectedIndex : number = 0;
  @Output() siguienteEvento = new EventEmitter<string>();
  public departamento: any;
  public puesto: any;
  public id_dp: any;
  public descripcion_puesto: any;


  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _rrhhService: RrhhService,
    private _router: Router,
    private _route: ActivatedRoute,
    ){ 
    this.form.markAllAsTouched()
    this.getDatosFormDescripcionPuesto();
    /*modificar*/
    this._route.queryParams.subscribe(
      params => {
        this.id_dp = +params['id_dp'];
        if(this.id_dp){
          console.log('ID: ' + this.id_dp);
          this.form.patchValue({ numero_depa: this.id_dp})
          this.getConsultaDescripcion(this.id_dp);
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[3].split("?")[0];
    console.log(this.accion);
  }

  /*default*/
  public getDatosFormDescripcionPuesto(){
    this.isLoading = true;
    this._rrhhService.getDatosFormDescripcionPuesto().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.departamento = response.data.departamento;
          this.puesto = response.data.puesto;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }
  public helperDiasLaboralesDescanso(){
    if(this.form.value.dl_l == true && this.form.value.dl_l == this.form.value.dd_l){
      this.form.patchValue({dd_l: false});
    }
    if(this.form.value.dl_ma == true && this.form.value.dl_ma == this.form.value.dd_ma){
      this.form.patchValue({dd_ma: false});
    }
    if(this.form.value.dl_mi == true && this.form.value.dl_mi == this.form.value.dd_mi){
      this.form.patchValue({dd_mi: false});
    }
    if(this.form.value.dl_j == true && this.form.value.dl_j == this.form.value.dd_j){
      this.form.patchValue({dd_j: false});
    }
    if(this.form.value.dl_v == true && this.form.value.dl_v == this.form.value.dd_v){
      this.form.patchValue({dd_v: false});
    }
    if(this.form.value.dl_s == true && this.form.value.dl_s == this.form.value.dd_s){
      this.form.patchValue({dd_s: false});
    }
    if(this.form.value.dl_d == true && this.form.value.dl_d == this.form.value.dd_d){
      this.form.patchValue({dd_d: false});
    }
  }
  /*fin default data*/

  /*registrar*/
  public setDL(){
    var c = '';
    if(this.form.value.dl_l == true){
      c = c + '1/';
    }
    if(this.form.value.dl_ma == true){
      c = c + '2/';
    }
    if(this.form.value.dl_mi == true){
      c = c + '3/';
    }
    if(this.form.value.dl_j == true){
      c = c + '4/';
    }
    if(this.form.value.dl_v == true){
      c = c + '5/';
    }
    if(this.form.value.dl_s == true){
      c = c + '6/';
    }
    if(this.form.value.dl_d == true){
      c = c + '7/';
    }
    this.form.patchValue({dias_laborales:c});
  }

  public setDD(){
    var c = '';
    if(this.form.value.dd_l == true){
      c = c + '1/';
    }
    if(this.form.value.dd_ma == true){
      c = c + '2/';
    }
    if(this.form.value.dd_mi == true){
      c = c + '3/';
    }
    if(this.form.value.dd_j == true){
      c = c + '4/';
    }
    if(this.form.value.dd_v == true){
      c = c + '5/';
    }
    if(this.form.value.dd_s == true){
      c = c + '6/';
    }
    if(this.form.value.dd_d == true){
      c = c + '7/';
    }
    this.form.patchValue({dias_descanso:c});
  }

  public setPuestoReporta(){
    this.form.value.puesto_reporta = [];
    var p = [];
    for(let i = 0; i < this.form.value.puesto_reporta_aux.length; i++){
      var obj = {numero_puesto: this.form.value.puesto_reporta_aux[i]};
      p.push(obj);
    }
    console.log(p);
    this.form.patchValue({
      puesto_reporta: p
    })
    console.log(this.form.value);
  }

  public postRegistroDescripcion(){
    this.setDL();
    this.setDD();
    this.setPuestoReporta();
    console.log(this.form.value);
    console.log(this.form.value.dias_laborales.split('/'));
    this.isLoading = true;
    //this.form.value.puesto_reporta = this.form.value.puesto_reporta.toString();
    //this.form.value.numero_departamento = this.form.value.numero_departamento.toString();
    //this.form.value.numero_puesto = this.form.value.numero_puesto.toString();
    this._rrhhService.postRegistroDescripcion(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          //this._router.navigate(['rrhh/gestor-de-puestos-de-trabajo/gestor-area-departamento']);
          this._router.navigate(['rrhh/gestor-de-puestos-de-trabajo/modificar'], 
            { queryParams:
              {id_dp: response.data.id_desc_puestos_pk}
            });
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  /*fin registro*/

  /*modificar*/
  public getConsultaDescripcion(id: number){
    this.isLoading = true;
    this._rrhhService.getConsultaDescripcion(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.descripcion_puesto = response.data;
          this.setForm(response.data);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public setForm(data: any){
    this.form.patchValue({
      numero_descripcion: data.id_desc_puestos_pk,
      titulo_puesto: data.id_titulo_puesto_fk,
      numero_departamento: data.id_departamento_fk,
      numero_puesto: data.id_puesto_reporta_fk,
      codigo_puesto: data.codigo_puesto,
      sub_area: data.sub_area,
      numero_plazas: data.numero_plazas,
      numero_personas: data.numero_personas,
      horario_inicio: data.horario_inicio,
      horario_fin: data.horario_fin,
      objetivo_principal: data.objetivo_principal,
      contribucion: data.contribucion,
      responsabilidad: data.responsabilidad,
      indicadores: data.indicadores,
      contactos_internos: data.contactos_internos,
      contactos_externos: data.contactos_externos,
      estatus: data.id_estatus_general_fk,
    });
    this.setFormDL(data);
    this.setFormDD(data);
    this.setPuestosReporta(data);

  }

  public setFormDL(data: any){
    if(data?.dias_laborales){
      var cad = data.dias_laborales.split('/');
      console.log(cad);
      if(cad){
        if(cad.find((item: any) => item == '1')){ this.form.patchValue({dl_l: true});}
        if(cad.find((item: any) => item == '2')){ this.form.patchValue({dl_ma: true});}
        if(cad.find((item: any) => item == '3')){ this.form.patchValue({dl_mi: true});}
        if(cad.find((item: any) => item == '4')){ this.form.patchValue({dl_j: true});}
        if(cad.find((item: any) => item == '5')){ this.form.patchValue({dl_v: true});}
        if(cad.find((item: any) => item == '6')){ this.form.patchValue({dl_s: true});}
        if(cad.find((item: any) => item == '7')){ this.form.patchValue({dl_d: true});}
      }
    }
    
  }

  public setFormDD(data: any){
     if(data?.dias_laborales){
      var cad = data.dias_descanso.split('/');
      console.log(cad);
      if(cad){
        if(cad.find((item: any) => item == '1')){ this.form.patchValue({dd_l: true});}
        if(cad.find((item: any) => item == '2')){ this.form.patchValue({dd_ma: true});}
        if(cad.find((item: any) => item == '3')){ this.form.patchValue({dd_mi: true});}
        if(cad.find((item: any) => item == '4')){ this.form.patchValue({dd_j: true});}
        if(cad.find((item: any) => item == '5')){ this.form.patchValue({dd_v: true});}
        if(cad.find((item: any) => item == '6')){ this.form.patchValue({dd_s: true});}
        if(cad.find((item: any) => item == '7')){ this.form.patchValue({dd_d: true});}
      }
    }
  }

  public setPuestosReporta(data: any){
    console.log(data);
    var d = [];
    for(let i = 0; i < data.puestos_reporta_dos.length; i++){
      d.push(data.puestos_reporta_dos[i].id_puesto_reporte_pk);
    }
    this.form.patchValue({
      puesto_reporta_aux: d
    });

  }

  public postModificarDescripcion(){
    this.setDL();
    this.setDD();
    this.setPuestoReporta();
    console.log(this.form.value);
    console.log(this.form.value.dias_laborales.split('/'));
    //this.form.value.puesto_reporta = this.form.value.puesto_reporta.toString();
    this.isLoading = true;
    this._rrhhService.postModificarDescripcion(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.getConsultaDescripcion(this.id_dp);
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          //this._router.navigate(['rrhh/gestor-de-puestos-de-trabajo/gestor-area-departamento']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }
  /*fin modificar*/



  public siguiente() {
    this.selectedIndex += 1;
    console.log(this.selectedIndex);
    this.siguienteEvento.emit(this.selectedIndex.toString());
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

  }

  public guardar(){
    console.log(this.form.value);
    this.postRegistroDescripcion();
  }

  public modificar(estado: number){
    console.log(this.form.value);
    this.postModificarEstadoDescripcion(estado);
  }


  public postModificarEstadoDescripcion(estado: number){
    this.isLoading = true;
    var e = this.form.value.estatus;
    this.form.value.estatus = estado;
    this._rrhhService.postModificarEstadoDescripcion(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postModificarDescripcion();
        }else{
          this.form.value.estatus = e;
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.form.value.estatus = e;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }




}
