import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

 
  public getConsultarCalendarioBusqueda(tipo: number, r: number, nmm: number, tc: number, calendario: number = 1):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarCalendarioBusqueda?numero_tipo_busqueda="+tipo+"&filas="+r+"&numero_mes="+nmm+"&tipo_configuracion="+tc+'&calendario='+calendario);
  }









  public postRegistrarConfiguracionCalendario(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarConfiguracionCalendario", datos, {});
  }



  public getCalendarioBeneficiarios():any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/CalendarioBeneficiarios");
  }

  public getCalendarioEmpleados():any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/CalendarioEmpleados");
  }

  public getDatosCalendarioForm():any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/DatosCalendarioForm");
  }


  public postConsultarConfiguracionCalendario(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ConsultarConfiguracionCalendario", datos, {});
  }



  /* control vacacion */
  public postRegistrarDocumentoEventoControlCalendario(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarDocumentoEventoControlCalendario", datos, {});
  }
  public postConsultarControlCalendario(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ConsultarControlCalendario", datos, {});
  }
  public postModificarEventoControlCalendario(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarEventoControlCalendario", datos, {});
  }
  



  

 

  
  

  



  
}
