import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AutorizacionGuard } from './autorizacion.guard';
import { NoGrantComponent } from './errors/no-grant/no-grant.component';
import { AbcUsuariosComponent } from './pages/administrador/abc-usuarios/abc-usuarios.component';
import { UsuariosComponent } from './pages/administrador/usuarios/usuarios.component';
import { CentroDeInformacionInstitucionalComponent } from './pages/centro-de-informacion-institucional/centro-de-informacion-institucional.component';
import { NuevoDocumentoComponent } from './pages/centro-de-informacion-institucional/nuevo-documento/nuevo-documento.component';
import { AltaContactoComponent } from './pages/contactos/alta-contacto/alta-contacto.component';
import { ContactosComponent } from './pages/contactos/contactos.component';
import { DatosGeneralesComponent } from './pages/datos-generales/datos-generales.component';
import { ApruebaComponent } from './pages/etapas-de-atencion/aprueba/aprueba.component';
import { CandidataComponent } from './pages/etapas-de-atencion/candidata/candidata.component';
import { DesarrolloComponent } from './pages/etapas-de-atencion/desarrollo/desarrollo.component';
import { EgresoComponent2 } from './pages/etapas-de-atencion/egreso/egreso.component';
import { EtapasDeAtencionComponent } from './pages/etapas-de-atencion/etapas-de-atencion.component';
import { PreparacionComponent } from './pages/etapas-de-atencion/preparacion/preparacion.component';
import { SanacionComponent } from './pages/etapas-de-atencion/sanacion/sanacion.component';
import { GestorDePruebasComponent } from './pages/evaluaciones/gestor-de-pruebas/gestor-de-pruebas.component';
import { PruebaComponent } from './pages/evaluaciones/gestor-de-pruebas/prueba/prueba.component';
import { EventosDeVidaComponent } from './pages/eventos-de-vida/eventos-de-vida.component';
import { NuevoEventoComponent } from './pages/eventos-de-vida/nuevo-evento/nuevo-evento.component';
import { GestorBeneficiariaComponent } from './pages/gestor-beneficiaria/gestor-beneficiaria.component';
import { LoginComponent } from './pages/login/login.component';
import { ModuleSelectorComponent } from './pages/module-selector/module-selector.component';
import { PrincipalComponent } from './pages/principal/principal.component';
import { VistaDocumentoComponent } from './pages/centro-de-informacion-institucional/vista-documento/vista-documento.component';
import { VistaContactoComponent } from './pages/contactos/vista-contacto/vista-contacto.component';
import { VistaDatosGeneralesComponent } from './pages/vista-datos-generales/vista-datos-generales.component';
import { RrhhComponent } from './pages/rrhh/rrhh.component';
import { GestorPerfilesYDescripcionPuestosComponent } from './pages/rrhh/gestor-perfiles-y-descripcion-puestos/gestor-perfiles-y-descripcion-puestos.component';
import { ContenedorPuestosDeTrabajoComponent } from './pages/rrhh/contenedor-puestos-de-trabajo/contenedor-puestos-de-trabajo.component';
import { RrhhDescripcionDePuestoComponent } from './pages/rrhh/rrhh-descripcion-de-puesto/rrhh-descripcion-de-puesto.component';
import { RrhhPerfilDePuestoComponent } from './pages/rrhh/rrhh-perfil-de-puesto/rrhh-perfil-de-puesto.component';
import { GestorRrhhAreaDepartamentoComponent } from './pages/rrhh/gestor-rrhh-area-departamento/gestor-rrhh-area-departamento.component';
import { AccionesRrhhAreaDepartamentoComponent } from './pages/rrhh/acciones-rrhh-area-departamento/acciones-rrhh-area-departamento.component';
import { AccionesRrhhPuestosComponent } from './pages/rrhh/acciones-rrhh-puestos/acciones-rrhh-puestos.component';
import { GestorRrhhPuestosComponent } from './pages/rrhh/gestor-rrhh-puestos/gestor-rrhh-puestos.component';
import { GestorRrhhOrganigramaDocsComponent } from './pages/rrhh/gestor-rrhh-organigrama-docs/gestor-rrhh-organigrama-docs.component';
import { AccionesRrhhOrganigramaDocsComponent } from './pages/rrhh/acciones-rrhh-organigrama-docs/acciones-rrhh-organigrama-docs.component';
import { MedicoComponent } from './pages/medico/medico.component';
import { GestorDirectorioMedicoComponent } from './pages/medico/gestor-directorio-medico/gestor-directorio-medico.component';
import { GestorContactosAreaMedicaComponent } from './pages/medico/gestor-directorio-medico/gestor-contactos-area-medica/gestor-contactos-area-medica.component';
import { AccionesContactosAreaMedicaComponent } from './pages/medico/gestor-directorio-medico/acciones-contactos-area-medica/acciones-contactos-area-medica.component';
import { AlmacenDeMedicamentosComponent } from './pages/medico/almacen-de-medicamentos/almacen-de-medicamentos.component';
import { GestorAlmInventarioComponent } from './pages/medico/almacen-de-medicamentos/gestor-alm-inventario/gestor-alm-inventario.component';
import { AccionesAlmInventarioComponent } from './pages/medico/almacen-de-medicamentos/acciones-alm-inventario/acciones-alm-inventario.component';
import { GestorAlmIncrementoInventarioComponent } from './pages/medico/almacen-de-medicamentos/gestor-alm-incremento-inventario/gestor-alm-incremento-inventario.component';
import { AccionesAlmIncrementoInventarioComponent } from './pages/medico/almacen-de-medicamentos/acciones-alm-incremento-inventario/acciones-alm-incremento-inventario.component';
import { GestorAlmSalidaInventarioComponent } from './pages/medico/almacen-de-medicamentos/gestor-alm-salida-inventario/gestor-alm-salida-inventario.component';
import { AccionesAlmSalidaInventarioComponent } from './pages/medico/almacen-de-medicamentos/acciones-alm-salida-inventario/acciones-alm-salida-inventario.component';
import { GestorRrhhExpedientesDePersonalComponent } from './pages/rrhh/gestor-rrhh-expedientes-de-personal/gestor-rrhh-expedientes-de-personal.component';
import { ContenedorExpedientePersonalComponent } from './pages/rrhh/contenedor-expediente-personal/contenedor-expediente-personal.component';
import { RrhhExpedienteInformacionEmpleadoComponent } from './pages/rrhh/rrhh-expediente-informacion-empleado/rrhh-expediente-informacion-empleado.component';
import { RrhhExpedienteDocumentosComponent } from './pages/rrhh/rrhh-expediente-documentos/rrhh-expediente-documentos.component';
import { GestorExpedientesMedicosComponent } from './pages/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-expedientes-medicos.component';
import { ExpedientesMedicosComponent } from './pages/medico/expedientes-medicos/expedientes-medicos.component';
import { ContenedorExpedientesMedicosComponent } from './pages/medico/expedientes-medicos/contenedor-expedientes-medicos/contenedor-expedientes-medicos.component';
import { HistorialClinico1Component } from './pages/medico/expedientes-medicos/historial-clinico1/historial-clinico1.component';
import { HistorialClinico2Component } from './pages/medico/expedientes-medicos/historial-clinico2/historial-clinico2.component';
import { EventosMedicos1Component } from './pages/medico/expedientes-medicos/eventos-medicos1/eventos-medicos1.component';
import { TratamientosMedicos1Component } from './pages/medico/expedientes-medicos/tratamientos-medicos1/tratamientos-medicos1.component';
import { GestorEytMedicos1Component } from './pages/medico/expedientes-medicos/gestor-eyt-medicos1/gestor-eyt-medicos1.component';
import { EvMedGenComponent } from './pages/medico/ev-med-gen/ev-med-gen.component';
import { GtControlGrupalPesoTallaComponent } from './pages/medico/ev-med-gen/gt-control-grupal-peso-talla/gt-control-grupal-peso-talla.component';
import { AcControlGrupalPesoTallaComponent } from './pages/medico/ev-med-gen/ac-control-grupal-peso-talla/ac-control-grupal-peso-talla.component';
import { AcControlGrupalPesoTallaBenComponent } from './pages/medico/ev-med-gen/ac-control-grupal-peso-talla-ben/ac-control-grupal-peso-talla-ben.component';
import { BibliotecaGeneralComponent } from './pages/biblioteca-general/biblioteca-general.component';
import { AccionesBibliotecaGeneralComponent } from './pages/biblioteca-general/acciones-biblioteca-general/acciones-biblioteca-general.component';
import { AcControlVacunacionComponent } from './pages/medico/expedientes-medicos/ac-control-vacunacion/ac-control-vacunacion.component';

import { Gt1TerCampComponent } from './pages/medico/ter-cam/gt1-ter-camp/gt1-ter-camp.component';
import { Gt2TerCampComponent } from './pages/medico/ter-cam/gt2-ter-camp/gt2-ter-camp.component';
import { AcTerCampComponent } from './pages/medico/ter-cam/ac-ter-camp/ac-ter-camp.component';

import { AcBitaComponent } from './pages/medico/bitacora/ac-bita/ac-bita.component';
import { GtBitaComponent } from './pages/medico/bitacora/gt-bita/gt-bita.component';

import { VacacionesComponent } from './pages/rrhh/vacaciones/vacaciones.component';
import { AcVacaComponent } from './pages/rrhh/vacaciones/ac-vaca/ac-vaca.component';
import { GtVacaComponent } from './pages/rrhh/vacaciones/gt-vaca/gt-vaca.component';

import { CalendarioComponent } from './pages/calendario/calendario.component';
import { GtCaleComponent } from './pages/calendario/gt-cale/gt-cale.component';

import { CatalogoInventarioComponent } from './pages/medico/catalogo-inventario/catalogo-inventario.component';
import { AcCatalogoInventarioComponent } from './pages/medico/ac-catalogo-inventario/ac-catalogo-inventario.component'; // a plugin!
import { ImpresionExpedienteMedicoComponent } from './pages/medico/expedientes-medicos/impresion-expediente-medico/impresion-expediente-medico.component';


import { RrhhIncidenciasComponent } from './pages/rrhh/rrhh-incidencias/rrhh-incidencias.component';
import { RrhhGtIncidenciasComponent } from './pages/rrhh/rrhh-gt-incidencias/rrhh-gt-incidencias.component';
import { RrhhCapacitacionesComponent } from './pages/rrhh/rrhh-capacitaciones/rrhh-capacitaciones.component';
import { RrhhGtCapacitacionesComponent } from './pages/rrhh/rrhh-gt-capacitaciones/rrhh-gt-capacitaciones.component';
import { RrhhCapacitacionIndividualComponent } from './pages/rrhh/rrhh-capacitacion-individual/rrhh-capacitacion-individual.component';

const routes: Routes = [
  { path: 'security/login', component: LoginComponent },

  {
  path: '',   
  canActivate: [ AutorizacionGuard ],
  children:[
    { path: 'principal',    component: ModuleSelectorComponent }, //apartir de aqui 
    { path: 'module',    component: PrincipalComponent },

    /*beneficiarias*/
    { path: 'beneficiarias',    component: GestorBeneficiariaComponent },
    { path: 'beneficiarias/datos-generales',    component: DatosGeneralesComponent},
    { path: 'beneficiarias/expediente',    component: DatosGeneralesComponent},
    { path: 'beneficiarias/expediente/view',    component: VistaDatosGeneralesComponent},
    { path: 'beneficiarias/ficha-tecnica',    component: DatosGeneralesComponent},
    { path: 'beneficiarias/etapas-de-atencion',    component: EtapasDeAtencionComponent},
    { path: 'beneficiarias/etapas-de-atencion/candidata',    component: CandidataComponent},
    { path: 'beneficiarias/etapas-de-atencion/aprueba',    component: ApruebaComponent},
    { path: 'beneficiarias/etapas-de-atencion/sanacion',    component: SanacionComponent},
    { path: 'beneficiarias/etapas-de-atencion/desarrollo',    component: DesarrolloComponent},
    { path: 'beneficiarias/etapas-de-atencion/preparacion',    component: PreparacionComponent},
    { path: 'beneficiarias/etapas-de-atencion/egreso',    component: EgresoComponent2},
    { path: 'beneficiarias/eventos',    component: EventosDeVidaComponent},
    { path: 'beneficiarias/eventos/view',    component: NuevoEventoComponent},
    
    /*centro de información institucional*/
    { path: 'centro-informacion-institucional',    component: CentroDeInformacionInstitucionalComponent},
    { path: 'centro-informacion-institucional/new',    component: NuevoDocumentoComponent},
    { path: 'centro-informacion-institucional/view',    component: VistaDocumentoComponent},
    { path: 'centro-informacion-institucional/edit',    component: NuevoDocumentoComponent},
    { path: 'centro-informacion-institucional/directorio',    component: ContactosComponent},
    { path: 'centro-informacion-institucional/directorio/new',    component: AltaContactoComponent},
    { path: 'centro-informacion-institucional/directorio/edit',    component: AltaContactoComponent},
    { path: 'centro-informacion-institucional/directorio/view',    component: VistaContactoComponent},

    /*eventos de vida*/
    { path: 'eventos-de-vida',    component: EventosDeVidaComponent},
    { path: 'eventos-de-vida/nuevo',    component: NuevoEventoComponent},
    { path: 'eventos-de-vida/edit',    component: NuevoEventoComponent},

    /*evaluaciones*/
    { path: 'evaluaciones',    component: GestorBeneficiariaComponent},
    { path: 'evaluaciones/gestor-de-pruebas',    component: GestorDePruebasComponent},
    { path: 'evaluaciones/gestor-de-pruebas/alta',    component: PruebaComponent},
    { path: 'evaluaciones/gestor-de-pruebas/prueba',    component: PruebaComponent},

    /*gestor de usuarios*/
    { path: 'administrador/usuarios',    component: UsuariosComponent},
    { path: 'administrador/usuarios/alta',    component: AbcUsuariosComponent},
    { path: 'administrador/usuarios/modificacion',    component: AbcUsuariosComponent},

    /*rrhh*/
    /*gestor de puestos*/
    { path: 'rrhh/gestor-de-puestos-de-trabajo',    component: GestorPerfilesYDescripcionPuestosComponent},
    { path: 'rrhh/gestor-de-puestos-de-trabajo/registrar',    component: ContenedorPuestosDeTrabajoComponent },
    { path: 'rrhh/gestor-de-puestos-de-trabajo/modificar',    component: ContenedorPuestosDeTrabajoComponent },
    { path: 'rrhh/gestor-de-puestos-de-trabajo/consultar',    component: ContenedorPuestosDeTrabajoComponent },
    { path: 'rrhh/gestor-de-puestos-de-trabajo/eliminar',    component: ContenedorPuestosDeTrabajoComponent },
    /*gestor de puesto - organigrama*/
    { path: 'rrhh/gestor-de-puestos-de-trabajo/gestor-organigrama-docs',    component: GestorRrhhOrganigramaDocsComponent},
    { path: 'rrhh/gestor-de-puestos-de-trabajo/gestor-organigrama-docs/registrar',    component: AccionesRrhhOrganigramaDocsComponent },
    { path: 'rrhh/gestor-de-puestos-de-trabajo/gestor-organigrama-docs/modificar',    component: AccionesRrhhOrganigramaDocsComponent },
    /*gestor de puestos - area o dep*/
    { path: 'rrhh/gestor-de-puestos-de-trabajo/gestor-area-departamento',    component: GestorRrhhAreaDepartamentoComponent},
    { path: 'rrhh/gestor-de-puestos-de-trabajo/gestor-area-departamento/registrar',    component: AccionesRrhhAreaDepartamentoComponent },
    { path: 'rrhh/gestor-de-puestos-de-trabajo/gestor-area-departamento/modificar',    component: AccionesRrhhAreaDepartamentoComponent},
    /*gestor de puestos - puestos*/
    { path: 'rrhh/gestor-de-puestos-de-trabajo/gestor-puestos',    component: GestorRrhhPuestosComponent },
    { path: 'rrhh/gestor-de-puestos-de-trabajo/gestor-puestos/registrar',    component: AccionesRrhhPuestosComponent },
    { path: 'rrhh/gestor-de-puestos-de-trabajo/gestor-puestos/modificar',    component: AccionesRrhhPuestosComponent },
    /*expedientes de personal*/
    { path: 'rrhh/gestor-de-expedientes-de-personal',    component: GestorRrhhExpedientesDePersonalComponent},
    { path: 'rrhh/gestor-de-expedientes-de-personal/registrar',    component: ContenedorExpedientePersonalComponent},
    { path: 'rrhh/gestor-de-expedientes-de-personal/modificar',    component: ContenedorExpedientePersonalComponent },
    { path: 'rrhh/gestor-de-expedientes-de-personal/consultar',    component: ContenedorExpedientePersonalComponent },
    { path: 'rrhh/gestor-de-expedientes-de-personal/eliminar',    component: ContenedorExpedientePersonalComponent },
    /* incidencias */
    { path: 'rrhh/gestor-de-expedientes-de-personal/gt-incidencias',    component: RrhhGtIncidenciasComponent  },
    { path: 'rrhh/gestor-de-expedientes-de-personal/gt-incidencias/consultar',    component: RrhhIncidenciasComponent  },
    { path: 'rrhh/gestor-de-expedientes-de-personal/gt-incidencias/modificar',    component: RrhhIncidenciasComponent  },
    /* capacitaciones */
    { path: 'rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones',    component: RrhhGtCapacitacionesComponent  },
    { path: 'rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones/consultar',    component: RrhhCapacitacionesComponent  },
    { path: 'rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones/modificar',    component: RrhhCapacitacionesComponent  },
    { path: 'rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones/registrar',    component: RrhhCapacitacionesComponent  },
    { path: 'rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones/lista',    component: RrhhCapacitacionIndividualComponent  },

    



    /*medico*/
    { path: 'medico',    component: MedicoComponent},
    /*gestor contactos del area medica*/
    { path: 'medico/gestor-contactos-area-medica',    component: GestorContactosAreaMedicaComponent},
    { path: 'medico/gestor-contactos-area-medica/registrar',    component: AccionesContactosAreaMedicaComponent},
    { path: 'medico/gestor-contactos-area-medica/modificar',    component: AccionesContactosAreaMedicaComponent},
    { path: 'medico/gestor-contactos-area-medica/consultar',    component: AccionesContactosAreaMedicaComponent},
    { path: 'medico/gestor-contactos-area-medica/eliminar',    component: AccionesContactosAreaMedicaComponent},
    /*almacen de medicamentos*/
    /*almacen de medicamentos - inventario*/
    { path: 'medico/almacen-de-medicamentos/gestor-inventario',    component: GestorAlmInventarioComponent},
    { path: 'medico/almacen-de-medicamentos/gestor-inventario/registrar',    component: AccionesAlmInventarioComponent},
    { path: 'medico/almacen-de-medicamentos/gestor-inventario/modificar',    component: AccionesAlmInventarioComponent},
    { path: 'medico/almacen-de-medicamentos/gestor-inventario/consultar',    component: AccionesAlmInventarioComponent},
    { path: 'medico/almacen-de-medicamentos/gestor-inventario/eliminar',    component: AccionesAlmInventarioComponent},
    /*almacen de medicamentos - incrementos de inventario*/
    { path: 'medico/almacen-de-medicamentos/gestor-incremento-inventario',    component: GestorAlmIncrementoInventarioComponent},
    { path: 'medico/almacen-de-medicamentos/gestor-incremento-inventario/registrar',    component: AccionesAlmIncrementoInventarioComponent},
    { path: 'medico/almacen-de-medicamentos/gestor-incremento-inventario/modificar',    component: AccionesAlmIncrementoInventarioComponent},
    { path: 'medico/almacen-de-medicamentos/gestor-incremento-inventario/consultar',    component: AccionesAlmIncrementoInventarioComponent},
    { path: 'medico/almacen-de-medicamentos/gestor-incremento-inventario/eliminar',    component: AccionesAlmIncrementoInventarioComponent},
    /*almacen de medicamentos - salida de inventario*/
    { path: 'medico/almacen-de-medicamentos/gestor-salida-inventario',    component: GestorAlmSalidaInventarioComponent},
    { path: 'medico/almacen-de-medicamentos/gestor-salida-inventario/registrar',    component: AccionesAlmSalidaInventarioComponent},
    { path: 'medico/almacen-de-medicamentos/gestor-salida-inventario/modificar',    component: AccionesAlmSalidaInventarioComponent},
    { path: 'medico/almacen-de-medicamentos/gestor-salida-inventario/consultar',    component: AccionesAlmSalidaInventarioComponent},
    { path: 'medico/almacen-de-medicamentos/gestor-salida-inventario/eliminar',    component: AccionesAlmSalidaInventarioComponent},
    /*expedientes medicos*/
    //historial clinico
    { path: 'medico/expedientes-medicos/gestor-expedientes-medicos',    component: GestorExpedientesMedicosComponent },
    { path: 'medico/expedientes-medicos/gestor-expedientes-medicos/registrar',    component: ContenedorExpedientesMedicosComponent },
    { path: 'medico/expedientes-medicos/gestor-expedientes-medicos/modificar',    component: ContenedorExpedientesMedicosComponent },
    { path: 'medico/expedientes-medicos/gestor-expedientes-medicos/consultar',    component: ContenedorExpedientesMedicosComponent },
    //eventos y tratamiento medicos
    { path: 'medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt',    component: GestorEytMedicos1Component },
    { path: 'medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/eventos/registrar',    component: EventosMedicos1Component },
    { path: 'medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/eventos/modificar',    component: EventosMedicos1Component },
    { path: 'medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/eventos/consultar',    component: EventosMedicos1Component },
    { path: 'medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/tratamientos',    component: TratamientosMedicos1Component },
    { path: 'medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/tratamientos/registrar',    component: TratamientosMedicos1Component },
    { path: 'medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/tratamientos/modificar',    component: TratamientosMedicos1Component },
    { path: 'medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/tratamientos/consultar',    component: TratamientosMedicos1Component },
    /*expedientes medicos generales*/
    { path: 'medico/expedientes-medicos-generales/gt-expedientes-medicos-generales',    component: GtControlGrupalPesoTallaComponent },
    { path: 'medico/expedientes-medicos-generales/gt-expedientes-medicos-generales/registrar',    component: AcControlGrupalPesoTallaComponent },
    { path: 'medico/expedientes-medicos-generales/gt-expedientes-medicos-generales/modificar',    component: AcControlGrupalPesoTallaComponent },
    { path: 'medico/expedientes-medicos-generales/gt-expedientes-medicos-generales/consultar',    component: AcControlGrupalPesoTallaComponent },
    { path: 'medico/expedientes-medicos-generales/gt-expedientes-medicos-generales-ben/registrar',    component: AcControlGrupalPesoTallaBenComponent },
    { path: 'medico/expedientes-medicos-generales/gt-expedientes-medicos-generales-ben/modificar',    component: AcControlGrupalPesoTallaBenComponent },
    { path: 'medico/expedientes-medicos-generales/gt-expedientes-medicos-generales-ben/consultar',    component: AcControlGrupalPesoTallaBenComponent },
    /*biblioteca medica*/
    { path: 'medico/biblioteca-general',    component: BibliotecaGeneralComponent },
    { path: 'medico/biblioteca-general/registrar',    component: AccionesBibliotecaGeneralComponent },
    { path: 'medico/biblioteca-general/consultar',    component: AccionesBibliotecaGeneralComponent },
    { path: 'medico/biblioteca-general/modificar',    component: AccionesBibliotecaGeneralComponent },
    /*expedientes medicos - vacunacion*/
    { path: 'medico/expedientes-medicos/control-vacunacion/consultar',    component: AcControlVacunacionComponent },
    { path: 'medico/expedientes-medicos/control-vacunacion/registrar',    component: AcControlVacunacionComponent },
    { path: 'medico/expedientes-medicos/control-vacunacion/modificar',    component: AcControlVacunacionComponent },
    { path: 'medico/expedientes-medicos/control-vacunacion/eliminar',    component: AcControlVacunacionComponent },


    /*expedientes medicos generales - terapias campanas*/
    { path: 'medico/expedientes-medicos-generales/gt-terapias-companas',    component: Gt1TerCampComponent },
    { path: 'medico/expedientes-medicos-generales/gt-terapias-companas/consultar',    component: AcTerCampComponent },
    { path: 'medico/expedientes-medicos-generales/gt-terapias-companas/modificar',    component: AcTerCampComponent },
    { path: 'medico/expedientes-medicos-generales/gt-terapias-companas/registrar',    component: AcTerCampComponent },


    /*expedientes medicos - terapias campanas*/
    { path: 'medico/expedientes-medicos/gt-terapias-companas',    component: Gt2TerCampComponent },
    /*{ path: 'medico/expedientes-medicos/gt-terapias-companas/consultar',    component: AcTerCampComponent },
    { path: 'medico/expedientes-medicos/gt-terapias-companas/modificar',    component: AcTerCampComponent },
    { path: 'medico/expedientes-medicos/gt-terapias-companas/registrar',    component: AcTerCampComponent },*/

    { path: 'medico/gt-bitacora',    component: GtBitaComponent },
    { path: 'medico/gt-bitacora/consultar',    component: AcBitaComponent },
    { path: 'medico/gt-bitacora/modificar',    component: AcBitaComponent },
    { path: 'medico/gt-bitacora/registrar',    component: AcBitaComponent },


    //vacaciones
    { path: 'rrhh/gestor-de-expedientes-de-personal/ac-vacaciones/consultar',    component: AcVacaComponent },
    { path: 'rrhh/gestor-de-expedientes-de-personal/ac-vacaciones/modificar',    component: AcVacaComponent },
    { path: 'rrhh/gestor-de-expedientes-de-personal/ac-vacaciones/registrar',    component: AcVacaComponent },

    //impresion expediente medico
    { path: 'medico/expedientes-medicos/gt-impresion-expdiente-medico/consultar',    component: ImpresionExpedienteMedicoComponent },

    { path: 'calendario',    component: CalendarioComponent },
    { path: 'gt-calendario',    component: GtCaleComponent },
    

    { path: 'catalogo-inventario',    component: CatalogoInventarioComponent },
    { path: 'catalogo-inventario/registrar',    component: AcCatalogoInventarioComponent },
    { path: 'catalogo-inventario/modificar',    component: AcCatalogoInventarioComponent },
    { path: 'catalogo-inventario/consultar',    component: AcCatalogoInventarioComponent },

    { path: 'error/no-grant', component: NoGrantComponent},

  ]},
  
  { path: '',
    redirectTo: '/security/login',
    pathMatch: 'full'},

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
