export class Usuario {
    apellidoMaterno: string;
    apellidoPaterno: string;
    correo: string;
    idEmpresa: string;
    idUsuario: string;
    nombre: string;
    puesto: string;
    telefono: string;
    username: string;
    password:string;
    cedula:string;
    idPerfil:string;
}
