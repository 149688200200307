//General
import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { SesionService } from 'src/app/services/sesion.service';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { BitacoraService } from 'src/app/services/bitacora.service';
import { TratamientosService } from 'src/app/services/tratamientos.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios


@Component({
  selector: 'app-ac-bita',
  templateUrl: './ac-bita.component.html',
  styleUrls: ['./ac-bita.component.scss'],
  providers: [ BitacoraService, TratamientosService ]
})
export class AcBitaComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    numero_bitacora: new UntypedFormControl(-1, []),
    se_presento_incidencia: new UntypedFormControl(3, []),
    describa: new UntypedFormControl('', []),
    boton: new UntypedFormControl(1, []),
    tratamientos: [],

  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id_bitacora: any;
  public laravelapiStorage = this._BitacoraService.getLaravelApiStorage();
  public laravelapi = this._BitacoraService.getLaravelApi();
  public t: any;
  public data: any;
  public bene: any = [];
  public tts: any;
  public titulo: string = '';


  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _BitacoraService: BitacoraService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
    private _sesion:SesionService,
    private _TratamientosService: TratamientosService,
  ) { 
    /*modificar*/
    this.form.markAllAsTouched();
    this._route.queryParams.subscribe(
      params => {
        this.id_bitacora = +params['id_bitacora'];
        if(this.id_bitacora){
          console.log('IDDDD: ' + this.id_bitacora);
          this.form.patchValue({ numero_bitacora: this.id_bitacora});
          this.getTratamientos();
          this.getConsultarBitacora(this.id_bitacora);
          
          //this.getConsultarTerapia(this.id);
          
          //this.getDatosClinicosForm();

          
          

        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[3].split("?")[0];
    console.log(this.accion);
  }

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }

  public getTratamientos(){
    this._TratamientosService.getConsultaDatosFormTratamientos().subscribe(
      response => {console.log(response);
        if(response.estado == 200){
          this.tts = response.data.tratamientos;
        }
      },
      error => {console.log(<any>error);
      });
  }


  public getConsultarBitacora(id: number){
    this.isLoading = true;
    this._BitacoraService.getConsultarBitacora(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.data = response.data;
          this.setForm(this.data);
          for(let i = 0; i < this.data?.tratamientos?.length; i++){
            
            if(!this.data?.tratamientos[i]?.observacion){
              this.data.tratamientos[i].numero_tratamiento = this.data.tratamientos[i].id_tratamiento_pk;
              this.data.tratamientos[i].observacion = this.data.tratamientos[i]?.pivot?.observacion;
            }
            


            if(this.existeBene(this.data?.tratamientos[i])){
              this.bene.push(this.data?.tratamientos[i]);
            }
          }
          console.log(this.bene);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public setForm(data: any){
    this.form.patchValue({
      se_presento_incidencia: data?.se_presento_incidencia,
      describa: data?.describa,
      boton: data?.id_estado_boton_fk,

    });
    console.log(this.form.value);
  }

  public existeBene(b: any){
    var f = true;
    for(let k = 0; k < this.bene.length; k++){
      if(this.bene[k].evento_medico.beneficiario.id == b.evento_medico.beneficiario.id){
        f = false;
      }
    }
    return f;

  }


  public postModificarBitacoras(s: any){

    var estado = s;
    this.form.patchValue({boton: s});
    this.form.patchValue({tratamientos: this.data.tratamientos});
    
    console.log(this.form.value);
    this.isLoading = true;
    this._BitacoraService.postModificarBitacoras(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          //this.getConsultarBitacora(this.id_bitacora);
          this._router.navigate(['medico/gt-bitacora']);
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio', icon:'error'}});
          this.form.patchValue({boton: estado});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.form.patchValue({boton: estado});
        this.isLoading = false;
      });
  }

}
