<footer class="text-center text-lg-start bg-light text-muted footer-3demak">
    <!-- class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom" -->
    <section>
        <div class="container-sponsor sponsors text-align-right">
            <div class="container-legend-sponsor">
                <span class="text-black">Este desarrollo fue posible gracias a la inversión social de: </span>
                <br>
                <div style="display: flex;">
                    <img class="img-sponsor-mp" src="/assets/img/monte-de-piedad.png" alt="">
                    <img class="img-sponsor-sec" src="/assets/img/logo-gob-estatal.png" alt="">
                    <img class="img-sponsor-qro" src="/assets/img/logo-gob-mpal.png" alt="">
                </div> 
            </div>

        </div>
    </section>
    <!-- Copyright -->
    <!-- <div class="text-center p-4">
        <div class="footer-logo-3demak">
            <span>Este desarrollo fue posible a la inversión social de: </span>
            <a href="https://www.3demak.com/">
                <img class="txt-align-img-centered img-footer-3demak" src="/assets/img/logo-3demak.png"/>
            </a>
        </div>
    </div> -->
    <!-- Copyright -->
</footer>