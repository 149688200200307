import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class IncidenciasService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

 

  public postRegistrarPreIncidentes(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarPreIncidentes", datos, {});
  }

  public postRegistrarIncidentes(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarIncidentes", datos, {});
  }

  public postRegistrarFechaIncidentes(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarFechaIncidentes", datos, {});
  }

  public postModificarFechaIncidentes(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarFechaIncidentes", datos, {});
  }

  public postEliminarFechaIncidentes(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarFechaIncidentes", datos, {});
  }

  public postRegModDocumentoIncidentes(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegModDocumentoIncidentes", datos, {});
  }

  public postEliminarDocumentoIncidentes(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarDocumentoIncidentes", datos, {});
  }


  public getConsultarIncidentesPaginador(id: number, id2: number, empleado: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarIncidentesPaginador?filas="+id2+'&page='+id+'&numero_empleado='+empleado);
  }

  public postConsultarIncidente(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ConsultarIncidente", datos, {});
  }

  public getConsultarFormularioIncidentes():any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarFormularioIncidentes");
  }

  

 

  
  

  



  
}
