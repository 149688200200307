import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ParametrosService } from 'src/app/services/parametros.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Beneficiaria } from 'src/app/model/beneficiaria';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios

@Component({
  selector: 'app-info-general',
  templateUrl: './info-general.component.html',
  styleUrls: ['./info-general.component.scss']
})
export class InfoGeneralComponent implements OnInit {
  @Input() beneficiaria:Beneficiaria;
  hostapi:string;
  discapacidadesf = new UntypedFormControl();
  imagen = "";
  discapacidades = ["Intelectual","Motriz","Conductual","Mental"];
  existente=false;
  formulario:UntypedFormGroup;
  isLoading = false;
  fileToUpload: File = null;
  fileToUpload1: File = null;
  fileToUpload2: File = null;
  fileToUpload3: File = null;
  fileToUpload4: File = null;
  fileToUpload5: File = null;
  public t: any;

  constructor(
    private _documentosService:DocumentosService,
    private router:Router,
    private dialog:MatDialog,
    private _parametrosService:ParametrosService,
    private activatedRoute:ActivatedRoute,
    private _beneficiariaService:BeneficiariaService,
    private _config:ConfigurationService,
    private _cdRef:ChangeDetectorRef,
    ) { 
      this.hostapi=_config.getConfiguration().hostapi;
    this.formulario = new UntypedFormGroup({
      nombre: new UntypedFormControl("",[Validators.required]),
      fechaAlta: new UntypedFormControl("",Validators.required),
      fechaNacimiento: new UntypedFormControl("",Validators.required),
      fechaIngreso: new UntypedFormControl("",[]),
      idCurp: new UntypedFormControl("",[]),
      idCanaliza: new UntypedFormControl("",[Validators.required]),
      desCanaliza: new UntypedFormControl("",[]),
      idMotivoIngreso: new UntypedFormControl("",[Validators.required]),
      desMotivoIngreso: new UntypedFormControl("",[]),
      desHitoria: new UntypedFormControl("",[]),
      idDiscapacidad: new UntypedFormControl("",[]),
      desAntecedente: new UntypedFormControl("",[]),
      fechaEgreso: new UntypedFormControl("",[]),
      desLogros:new UntypedFormControl("",[]),
      idEmpresa: new UntypedFormControl("1",[]),
      
      discapacidad: new UntypedFormControl("",[]),
      
      diagnosticoNeurologico: new UntypedFormControl("",[]),
      dnArchivo:new UntypedFormControl("",[]),
      dnDescripcion:new UntypedFormControl("",[]),
      dnRuta:new UntypedFormControl("",[]),

      edadMental: new UntypedFormControl("",[]),
      emArchivo: new UntypedFormControl("",[]),
      emRuta:new UntypedFormControl("",[]),

      diagnosticoMotriz: new UntypedFormControl("",[]),
      dmArchivo: new UntypedFormControl("",[]),
      dmRuta:new UntypedFormControl("",[]),

      diagnosticoPsicologico: new UntypedFormControl("",[]),
      dpArchivo: new UntypedFormControl("",[]),
      dpRuta:new UntypedFormControl("",[]),

      diagnosticoMedico: new UntypedFormControl("",[]),
      dmedArchivo: new UntypedFormControl("",[]),
      dmedRuta:new UntypedFormControl("",[]),
    });
  }
  idBeneficiaria:number;
  ngOnInit(): void {
    
    this.ifg_cargaCatalogos();
    this.activatedRoute.queryParams.subscribe(params=>{
      if(params["id"]){
        this.idBeneficiaria= params["id"];
        this.existente = true;
        this.isLoading = true;
        this._beneficiariaService.findById(this.idBeneficiaria).subscribe(result=>{
          this.beneficiaria = result.data;
          this.isLoading = false;
          this.ifg_setData();
          this.ifg_getDocumentos(this.idBeneficiaria);
        },()=>{
          this.isLoading = false;
        });
        
      }else{
        this.existente = false;
      }
    });
    
  }

  ifg_setData(){
    this.formulario.controls.nombre.setValue(this.beneficiaria.nombre);
    this.formulario.controls.fechaAlta.setValue(new Date(this.beneficiaria.fechaAlta["date"]));
    this.formulario.controls.fechaNacimiento.setValue(new Date(this.beneficiaria.fechaNacimiento["date"]));
    if(this.beneficiaria.fechaIngreso){
      this.formulario.controls.fechaIngreso.setValue(new Date(this.beneficiaria.fechaIngreso["date"]));
    }
    this.formulario.controls.desCanaliza.setValue(this.beneficiaria.desCanaliza);
    this.formulario.controls.idCurp.setValue(this.beneficiaria.idCurp);
    this.formulario.controls.idCanaliza.setValue(this.beneficiaria.idCanaliza);
    this.formulario.controls.idMotivoIngreso.setValue(this.beneficiaria.idMotivoIngreso);
    this.formulario.controls.desHitoria.setValue(this.beneficiaria.desHitoria);

    let arrayDiscapacidades = [];
    if(this.beneficiaria.idDiscapacidad){
      arrayDiscapacidades = this.beneficiaria.idDiscapacidad.split(",");
    }
    this.formulario.controls.idDiscapacidad.setValue(arrayDiscapacidades);

    this.ifg_getFotos(this.beneficiaria.id);
  }
  get form(){
    return this.formulario.controls;
  }

  motivoIngreso:any[];
  canaliza:any[];
  diagnosticoNeurologico:any[];
  discapacidad:any[];

  ifg_cargaCatalogos(){
    //param motivo ingreso
    this._parametrosService.getParametros("3").subscribe(result=>{
      this.motivoIngreso = result.data.parametros;
    });
    //param canaliza
    this._parametrosService.getParametros("2").subscribe(result=>{
      this.canaliza = result.data.parametros;
    });
    this._parametrosService.getParametros("4").subscribe(result=>{
      this.diagnosticoNeurologico = result.data.parametros;
    });
    this._parametrosService.getParametros("5").subscribe(result=>{
      this.discapacidad = result.data.parametros;
    });
  }
  ifg_tieneBandera(id,arreglo:any[]){
    if(arreglo){
      for(let ifg=0; ifg<arreglo.length;ifg++){
        if(arreglo[ifg].idParametro == id){
          return arreglo[ifg].bndEspecificar;
        }
      }
      return 0;
    }else{
      return 0;
    }
    
  }
  ifg_sendForm(){
    if(this.formulario.invalid){
      return false;
    }
    console.info(this.formulario.getRawValue());
    let beneficiaria : Beneficiaria = this.formulario.getRawValue();
    if(!this.existente){
      beneficiaria.foto = "URL FOTO";
    }
    beneficiaria.fechaAlta = this.formulario.controls.fechaAlta.value.toJSON().replace("T"," ").replace(".000Z","");
    beneficiaria.fechaNacimiento = this.formulario.controls.fechaNacimiento.value.toJSON().replace("T"," ").replace(".000Z","");
    let discapacidad = "";
    for(let i = 0; i<beneficiaria.idDiscapacidad.length; i++){
      if(i>0){
        discapacidad += ",";
      }
      discapacidad += beneficiaria.idDiscapacidad[i];
    }
    beneficiaria.idDiscapacidad = discapacidad;

    this.isLoading=true;
    this._beneficiariaService.save(beneficiaria,this.idBeneficiaria).subscribe(result=>{
      this.isLoading=false;
      this.beneficiaria.id = result.data.id;
      this.ifg_cargaExtras(result.data.id);
      
    },()=>{
      this.isLoading=false;
    });
  }
  
  ifg_cargaExtras(id:number){
    if(this.fileToUpload){
      let formData = new FormData();
      formData.append('foto', this.fileToUpload);
      this.isLoading=true;
      this._beneficiariaService.altaFoto(id,formData).subscribe(result=>{
        this.isLoading = false;
        this.fileToUpload = null; //para borrar la img cargada
        
        this.ifg_cargarDocumentos();
      },()=>{
        this.isLoading = false;
      });
    }else{
      this.ifg_cargarDocumentos();
    }
  }
  get edad(): String {
    if(this.form.fechaNacimiento.value=="" || this.form.fechaNacimiento.value==null){
      return "-";
    }else{
      let edad:Number = moment(new Date()).diff(this.form.fechaNacimiento.value, 'years',true);
      let ifg_laedad:String = edad.toString();
      let años = ifg_laedad.split(".")[0];
      let meses = ((Number) ("0."+(ifg_laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let ifg_detalleEdad = años + " años "+ meses + " meses";
      return ifg_detalleEdad;
    }
  }
  

  get edadIngreso(){
    if((this.form.fechaNacimiento.value=="" || this.form.fechaNacimiento.value==null || this.form.fechaIngreso.value=="" || this.form.fechaIngreso.value==null)){
      return "-";
    }else{
      let edad:Number = moment(this.form.fechaIngreso.value).diff(this.form.fechaNacimiento.value, 'years',true);
      let ifg_laedad:String = edad.toString();
      let años = ifg_laedad.split(".")[0];
      let meses = ((Number) ("0."+(ifg_laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let ifg_detalleEdad = años + " años "+ meses + " meses";
      return ifg_detalleEdad;
    }
  }

  get tiempoDeAntiguedad(){
    if( this.form.fechaIngreso.value=="" || this.form.fechaIngreso.value==null ){
      return "0";
    }else{
      let edad:Number = moment(this.form.fechaIngreso.value).diff(new Date(), 'years',true);
      let ifg_laedad:String = edad.toString();
      let años = ifg_laedad.split(".")[0];
      let meses = ((Number) ("0."+(ifg_laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let ifg_detalleEdad = años + " años "+ meses + " meses";
      return ifg_detalleEdad;
    }
  }

  ifg_handleFileInput(ifg_files: FileList) {
    this.fileToUpload = ifg_files.item(0);
  }
  ifg_handleFileInput1(ifg_files: FileList) {
    this.fileToUpload1 = ifg_files.item(0);
  }
  ifg_handleFileInput2(ifg_files: FileList) {
    this.fileToUpload2 = ifg_files.item(0);
  }
  ifg_handleFileInput3(ifg_files: FileList) {
    this.fileToUpload3 = ifg_files.item(0);
  }
  ifg_handleFileInput4(ifg_files: FileList) {
    this.fileToUpload4 = ifg_files.item(0);
  }
  ifg_handleFileInput5(ifg_files: FileList) {
    this.fileToUpload5 = ifg_files.item(0);
  }
  fotos:any;
  ifg_getFotos(id){
    this._beneficiariaService.getFotos(id).subscribe(result=>{
      console.log(result);
      this.fotos=result.data;
    },()=>{
      // This is intentional
    });
  }

  ifg_cargarDocumentos(){
    
    console.info(this.formulario.controls.dnArchivo.value);
    this.ifg_altaDocumento("52",this.formulario.controls.diagnosticoNeurologico.value,this.formulario.controls.dnDescripcion.value,this.fileToUpload1).then(()=>{
      this.ifg_altaDocumento("53","",this.formulario.controls.edadMental.value,this.fileToUpload2).then(()=>{
        this.ifg_altaDocumento("54","",this.formulario.controls.diagnosticoMotriz.value,this.fileToUpload3).then(()=>{
          this.ifg_altaDocumento("55","",this.formulario.controls.diagnosticoPsicologico.value,this.fileToUpload4).then(()=>{
            this.ifg_altaDocumento("56","",this.formulario.controls.diagnosticoMedico.value,this.fileToUpload5).then(()=>{
              this.fileToUpload1 = null;
              this.fileToUpload2 = null;
              this.fileToUpload3 = null;
              this.fileToUpload4 = null;
              this.fileToUpload5 = null;
              if(this.existente){  
                this.ifg_mensajeDeExito2();
              }else{
                this.ifg_mensajeDeExito();
              }
            })
          })
        })
      })
    });
  }
  ifg_altaDocumento(tipoDocumento:string,idTipoCatalogo:string,desTipoCatalogo:string,file:File){
    
    return new Promise((resolve, reject) => {
      //if(file==null){
      //  resolve(true);
      //}else{
        let ifg_formData = new FormData();
        ifg_formData.append('documento', file);
        ifg_formData.append('idBeneficiaria', this.beneficiaria.id.toString());
        ifg_formData.append('idTipoDocumento', tipoDocumento);
        ifg_formData.append('idTipoCatalogo', idTipoCatalogo);
        ifg_formData.append('desTipoCatalogo', desTipoCatalogo);
        this.isLoading=true;
        this._documentosService.save(ifg_formData).subscribe(result=>{
          this.isLoading=false;
          resolve(true);
        },()=>{
          this.isLoading=false;
          resolve(true);
        })
      //}
      
    });
  }
  ifg_mensajeDeExito(){
    const dialogRef= this.dialog.open(MessageBoxComponent,{
      data:{titulo: "Expediente creado" ,mensaje: "Se ha creado un nuevo expediente"}
    });
    this.router.navigate(["/beneficiarias"]);
  }
  ifg_mensajeDeExito2(){
    const dialogRef= this.dialog.open(MessageBoxComponent,{
      data:{titulo: "Expediente modificado" ,mensaje: "Se ha modificado el expediente correctamente"}
    });
    dialogRef.afterClosed().subscribe(()=>{
      document.getElementById("resetForm").click();
      this.ngOnInit();
    })
  }
  documentos:any;
  documento1 = null;
  documento2 = null;
  documento3 = null;
  documento4 = null;
  documento5 = null;

  ifg_getDocumentos(id:number){
    this.isLoading = true;
    this._documentosService.getAllByBeneficiaria(id).subscribe(result=>{
      console.log(result);
      this.isLoading = false;
      this.documentos = result.data.documentos;

      for(let ifg = 0; ifg< this.documentos.length; ifg++){
        if(this.documentos[ifg].idTipoDocumento == 52){
          this.formulario.controls.diagnosticoNeurologico.setValue(this.documentos[ifg].idTipoCatalogo);
          this.formulario.controls.dnDescripcion.setValue(this.documentos[ifg].desTipoCatalogo);

          this.documento1 = this.documentos[ifg].docTipoCatalogo;
        }else if(this.documentos[ifg].idTipoDocumento == 53){
          this.formulario.controls.edadMental.setValue(this.documentos[ifg].desTipoCatalogo);

          this.documento2 = this.documentos[ifg].docTipoCatalogo;
        }else if(this.documentos[ifg].idTipoDocumento == 54){
          this.formulario.controls.diagnosticoMotriz.setValue(this.documentos[ifg].desTipoCatalogo);

          this.documento3 = this.documentos[ifg].docTipoCatalogo;
        }else if(this.documentos[ifg].idTipoDocumento == 55){
          this.formulario.controls.diagnosticoPsicologico.setValue(this.documentos[ifg].desTipoCatalogo);

          this.documento4 = this.documentos[ifg].docTipoCatalogo;
        }else if(this.documentos[ifg].idTipoDocumento == 56){
          this.formulario.controls.diagnosticoMedico.setValue(this.documentos[ifg].desTipoCatalogo);

          this.documento5 = this.documentos[ifg].docTipoCatalogo;
        }
      }

    },()=>{
      this.isLoading = false;
    })
  }

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }

  public getEliminarFotosUsuario(id: number){
    console.log(id);
    this.isLoading=true;
    this._beneficiariaService.getEliminarFotosUsuario(id).subscribe(result=>{
      this.isLoading=false;
      this.beneficiaria.id = result.data.id;
      this.ifg_cargaExtras(result.data.id);
      
    },()=>{
      this.isLoading=false;
    });
  }

  /*public postRegistroAntecedentesNoPatologicos(beneficiario: number, numero_tipo_doc: number){
    console.log(beneficiario, numero_tipo_doc);
    this.isLoading=true;
    this._beneficiariaService.postRegistroAntecedentesNoPatologicos(beneficiario, numero_tipo_doc).subscribe(result=>{
      this.isLoading=false;
      this.beneficiaria.id = result.data.id;
      this.ifg_cargaExtras(result.data.id);
      
    },()=>{
      this.isLoading=false;
    });
  }*/

  //nuevas

  public postRegistroAntecedentesNoPatologicos(id: number, idben: number, url: string){
    console.log(id);
    var u = url.split('public_uploads/');
    console.log(u);

    var data = {
      url: u[1],
      numero_beneficiario: idben,
      numero_foto_beneficiario: id,
    }

    this.isLoading=true;
    this._beneficiariaService.postRegistroAntecedentesNoPatologicos(data).subscribe(result=>{
      console.log(result);
      this.isLoading=false;
      //this.beneficiaria.id = result.data.id;
      this.ifg_sendForm();
      
    },()=>{
      this.isLoading=false;
    });
  }

  public postEliminarDocumentoBene(beneficiario: number, doc: number, url: string){
    //console.log(beneficiario, numero_tipo_doc);

    var j = 0;
    

    for(let i = 0; i < this.documentos.length ; i++){
      if(this.documentos[i].idTipoDocumento == doc){
        j = i;
      }
    }
    console.log(j);
    var u = this.documentos[j].docTipoCatalogo.split('./public_uploads/');
    console.log(u);

    var data = {
      url: u[1],
      numero_documento: doc,
      numero_beneficiario: beneficiario,
    }
    console.log(data); 
    this.isLoading=true;
    this._beneficiariaService.postEliminarDocumentoBene(data).subscribe(result=>{
      console.log(result);
      this.isLoading=false;
      //this.ifg_cargaExtras(result.data.id);
      this.ifg_sendForm();
    },()=>{
      this.isLoading=false;
    });
  }

}
