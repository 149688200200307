<div class="card-container">
    <div>
        <mat-card class="example-card">
            <mat-card-header>
                
                <mat-card-title>
                    Gestor de eventos de vida
                </mat-card-title>
                <mat-card-subtitle>
                    
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>

                <div class="bt col-md-12">
                    <app-datos-generales *ngIf="esBeneficiaria" (fichaTecnica)="recibeFichaTecnica($event)" (beneficiariaSeleccionada)="recibeBeneficiaria($event)" [ficha]="true"></app-datos-generales>
                </div>
                <div class="bt col-md-12">
                    <form [formGroup]="formulario" (ngSubmit)="sendForm()" autocomplete="off">
                        <mat-form-field appearance="fill" class="bt col-md-6">
                            <mat-label>Buscador</mat-label>
                            <input matInput formControlName="buscador">
                        </mat-form-field>
                        <button mat-mini-fab type="submit">
                            <mat-icon>search</mat-icon>
                        </button>
                        &nbsp;
                        <button *ngIf="!id" color="primary" mat-flat-button class="flat" [routerLink]="['nuevo']" type="button">
                            <mat-icon>add</mat-icon>&nbsp;Nuevo evento
                        </button> &nbsp;
                        <a *ngIf="!id" color="accent" mat-flat-button class="flat" href="{{phpPath}}/reporteEventosDeVida.php" download="eventos-de-vida.xls" >Reporte en excel</a>
                    </form>
                    
                    
                </div>
                <div class="table-container">
                    <div class="spinner" *ngIf="isLoading">
                        <mat-spinner color="accent"></mat-spinner>
                    </div>
                    <table mat-table [dataSource]="dataSource">
                        <caption></caption>
                        <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->
                        
                        <!-- Position Column -->
                        <ng-container matColumnDef="position">
                            <th id="position" mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let element;let i=index"> {{i+1}} </td>
                        </ng-container>

                        <ng-container matColumnDef="fecha">
                            <th id="fecha" mat-header-cell *matHeaderCellDef> Fecha </th>
                            <td mat-cell *matCellDef="let element"> 
                                {{element?.fechaEvento?.date | date}}
                            </td>
                        </ng-container>
                    
                        <ng-container matColumnDef="nombreEvento">
                            <th id="nombre" mat-header-cell *matHeaderCellDef> Nombre del evento </th>
                            <td mat-cell *matCellDef="let element"> 
                                {{element?.nombre}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="clasificacion">
                            <th id="clasificacion" mat-header-cell *matHeaderCellDef> Clasificación </th>
                            <td mat-cell *matCellDef="let element"> 
                                {{getClasificacion(element?.clasificacion)}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="evento">
                            <th id="evento" mat-header-cell *matHeaderCellDef> Evento </th>
                            <td mat-cell *matCellDef="let element" > 
                                <button type="button" mat-icon-button color="primary" [routerLink]="['edit']" [queryParams]="{id : element?.idEvento}">
                                    <mat-icon>preview</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="eliminar">
                            <th id="eliminar" mat-header-cell *matHeaderCellDef> Eliminar </th>
                            <td mat-cell *matCellDef="let element"> 
                                <button type="button" mat-icon-button color="accent" (click)="eliminar(element?.idEvento)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="consulta">
                            <th id="consulta" mat-header-cell *matHeaderCellDef> Consulta </th>
                            <td mat-cell *matCellDef="let element"> 
                                <button type="button" mat-icon-button color="primary" [routerLink]="['view']" [queryParams]="{id : element?.idEvento}">
                                    <mat-icon>preview</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="imprimir">
                            <th id="imprimir" mat-header-cell *matHeaderCellDef> Imprimir </th>
                            <td mat-cell *matCellDef="let element"> 
                                <button type="button" mat-icon-button color="primary" (click)="imprimirPdf(element?.idEvento)">
                                    <mat-icon>print</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <mat-paginator
                       [length] = "length"
                       [pageSize]="paginador.pageSize"
                       [pageSizeOptions]="paginador.pageOptions"
                       (page)="pager($event)">
                </mat-paginator>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
