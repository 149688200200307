<br>
<div class="table-container none-egr-aux">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner color="accent"></mat-spinner>
    </div>
    <div *ngIf="!hayEgreso" class="text-align-center">
        <br>
        <h3>No hay información para la etapa de Egreso</h3>
    </div>
    <form *ngIf="hayEgreso" [formGroup]="formulario">
        <div class="bt col-md-12">
            <div class="bt col-md-4 none-egr-aux">
                <img class="bt col-md-12" src="{{hostapi}}/{{urlFoto}}" width="100%" height="auto" alt="foto-de-egreso"/>
            </div>
        </div>
        <div>
            <mat-form-field appearance="fill" class="bt col-md-4 none-egr-aux">
                <mat-label>Fecha de egreso</mat-label>
                <input readonly matInput [matDatepicker]="dp1" formControlName="fechaApertura">
                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                <mat-datepicker touchUi #dp1 disabled="true"></mat-datepicker>
            </mat-form-field>
        </div>

        <mat-form-field appearance="fill" class="bt col-md-4 none-egr-aux">
            
            <mat-label>Motivo de egreso</mat-label>
            <mat-select [disabled]="true" formControlName="idMotivoEgreso">
                <mat-option *ngFor="let motivo of motivosEgreso" value="{{motivo.idParametro}}">{{motivo.valor}}</mat-option>
            </mat-select>
            <mat-progress-bar mode="query" *ngIf="isLoadingMotivoEgreso"></mat-progress-bar>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-8 none-egr-aux" >
            <mat-label>Descripción del motivo de egreso / conclusiones</mat-label>
            <textarea readonly matInput placeholder="" maxlength="2000" #historia formControlName="desMotivoEgreso"></textarea>
            <mat-hint align="start"><strong></strong> </mat-hint>
            <mat-hint align="end">{{historia.value.length}} / 2000</mat-hint>
        </mat-form-field>


        <mat-form-field appearance="fill" class="bt col-md-4 none-egr-aux">
            
            <mat-label>Necesidades de apoyo cognitivas</mat-label>
            <mat-select [disabled]="true" formControlName="autonomiaCognitivoSelect" >
                <mat-option value="{{ac.idParametro}}" *ngFor="let ac of autonomiasConitivas">{{ac.valor}}</mat-option>
            </mat-select>
            <mat-progress-bar mode="query" *ngIf="isLoadingAutonomiasConitivas"></mat-progress-bar>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-8 none-egr-aux" >
            <mat-label>Detalle necesidades de apoyo cognitivas</mat-label>
            <textarea readonly matInput placeholder="" maxlength="300" #descripcion formControlName="autonomiaCognitivoInput"></textarea>
            <mat-hint align="start"><strong></strong> </mat-hint>
            <mat-hint align="end">{{descripcion.value.length}} / 300</mat-hint>
        </mat-form-field>


        <mat-form-field appearance="fill" class="bt col-md-4 none-egr-aux">
            
            <mat-label>Necesidades de apoyo motriz</mat-label>
            <mat-select [disabled]="true" formControlName="autonomiaMotrizSelect" >
                <mat-option value="{{am.idParametro}}" *ngFor="let am of autonomiasMotrices">{{am.valor}}</mat-option>
            </mat-select>
            <mat-progress-bar mode="query" *ngIf="isLoadingAutonomiasMotrices"></mat-progress-bar>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-8 none-egr-aux" >
            <mat-label>Detalle de necesidades de apoyo motriz</mat-label>
            <textarea readonly matInput placeholder="" maxlength="300" #descripcion2 formControlName="autonomiaMotrizInput"></textarea>
            <mat-hint align="start"><strong></strong> </mat-hint>
            <mat-hint align="end">{{descripcion2.value.length}} / 300</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-4 none-egr-aux">
            
            <mat-label>Diagnóstico emocional</mat-label>
            <mat-select [disabled]="true" formControlName="diagnosticoEmocionalSelect" >
                <mat-option value="{{ae.idParametro}}" *ngFor="let ae of autonomiasEmocionales">{{ae.valor}}</mat-option>
            </mat-select>
            <mat-progress-bar mode="query" *ngIf="isLoadingAutonomiasEmocionales"></mat-progress-bar>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-8 none-egr-aux">
            <mat-label>Detalle de diagnóstico emocional</mat-label>
            <textarea readonly matInput placeholder="" maxlength="300" #descripcion3 formControlName="diagnosticoEmocionalInput"></textarea>
            <mat-hint align="start"><strong></strong> </mat-hint>
            <mat-hint align="end">{{descripcion3.value.length}} / 300</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-12" >
            <mat-label>Logros alcanzados</mat-label>
            <textarea  readonly matInput placeholder="" maxlength="2000" #descripcion4 formControlName="desLogrosAlcanzados"></textarea>
            <mat-hint align="start"><strong></strong> </mat-hint>
            <mat-hint align="end">{{descripcion4.value.length}} / 2000</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-4 none-egr-aux">
            
            <mat-label>Lugar de destino</mat-label>
            <mat-select [disabled]="true" formControlName="idLugarDestino">
                <mat-option *ngFor="let destino of lugaresDestino" value="{{destino.tipoParametro}}">{{destino.valor}}</mat-option>
            </mat-select>
            <mat-progress-bar mode="query" *ngIf="isLoadingLugaresDestino"></mat-progress-bar>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-8 none-egr-aux" >
            <mat-label>Detalles del lugar de destino</mat-label>
            <textarea readonly matInput placeholder="" maxlength="2000" #descripcion5 formControlName="desLugarDestino"></textarea>
            <mat-hint align="start"><strong></strong> </mat-hint>
            <mat-hint align="end">{{descripcion5.value.length}} / 2000</mat-hint>
        </mat-form-field>


        <div class="text-align-center">
            <button mat-flat-button class="flat" color="primary">Cerrar</button>
        </div>
    </form>
</div>