import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { MatCardActions } from '@angular/material/card';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ControlVacunacionService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

  public getConsultarCartilla(id: any):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarCartilla?numero_beneficiaria="+id);
  }

  public postRegistrarDocCartilla(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registrarDocCartilla", datos, {});
  }

  public postRegistrarVacuna(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registrarVacuna", datos, {});
  }

  public postModificarVacuna(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarVacuna", datos, {});
  }

  public postEliminarVacuna(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/eliminarVacuna", datos, {});
  }








  

  


}