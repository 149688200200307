import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SesionService } from './services/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class AutorizacionGuard implements CanActivate {
  constructor(
    private router : Router,
    public _session:SesionService
    ){}
  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
    let url: string = state.url;
    
    return this.verifyLogin(url);
  }

  verifyLogin(url) : boolean{
    
    if(!this.isLoggedIn()){
        // return true;
        this.router.navigate(['/security/login']);
    }else{
      if(this.tienePermiso(url)){
        console.log('aasies');
        return true;
      }else{
        this.router.navigate(['/security/login']);
      }
    }
  }

  public isLoggedIn(): boolean{
    let status = false;
    if(this._session.getStatus()){
      status = true;
    }
    console.log("status IsLoggenIn: ", status);
    return status;
  }

  tienePermiso(url){
  //   console.info(url);
  //   let acciones = this._session.getSession().getAcciones();
  //   console.log("acciones: ", acciones);
  //   if(acciones.length>=1){
  //     acciones.forEach(element => {
  //       if(url == element.url){
  //         return true;
  //       }else if(url.indexOf("?")>-1){
  //         return this.tienePermiso(url.split("?")[0]);
  //       }else{
  //         console.info(element.url);
  //         return false;
  //       }
  //     });
  //   }else{
  //     return false;
  //   }
  // }
  console.info(url);
    let acciones = this._session.getSession().getAcciones();
    for( let i = 0; i<acciones.length; i++){
      if(url == acciones[i].url){
        return true;
      }else if(url.indexOf("?")>-1){
        return this.tienePermiso(url.split("?")[0]);
      }
    }
    return false;
  }

  
}
