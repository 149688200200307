import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class VacacionesService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

 

  public getConsultaEmpleadoExpedienteVacacion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ConsultaEmpleadoExpedienteVacacion", datos, {});
  }

  public getConsultarHistoricoVacacion(id: number, id2: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarHistoricoVacacion?numero_puesto="+id+'&numero_control='+id2);
  }





  public postRegistrarVacacion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarVacacion", datos, {});
  }

  public postSubirDocumentoVacacion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/SubirDocumentoVacacion", datos, {});
  }

  public postModificarVacacion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarVacacion", datos, {});
  }

  public postSubirDocumentoPlaneacionVacaciones(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/SubirDocumentoPlaneacionVacaciones", datos, {});
  }




  

 

  
  

  



  
}
