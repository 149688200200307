import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DesitionBoxComponent } from 'src/app/modals/desition-box/desition-box.component';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ParametrosService } from 'src/app/services/parametros.service';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {
  hostapi:string;
  files = [];
  constructor(
    private _conf:ConfigurationService,
    private dialog:MatDialog,
    private _parametrosService:ParametrosService,
    private _documentosService:DocumentosService,
    private activatedRoute:ActivatedRoute
    ) { 
      this.hostapi = _conf.getConfiguration().hostapi;
      this.formulario = new UntypedFormGroup({
        tipoDocumento: new UntypedFormControl("",[Validators.required]),
        archivo: new UntypedFormControl("",[Validators.required]),
        nombre: new UntypedFormControl("",[])
      })
  }
  isLoading = false;
  formulario:UntypedFormGroup;
  fileToUpload: File = null;
  idBeneficiaria:number;
  tipoDocumentos = [];
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info(params["id"]);
      if(params["id"]!=undefined){
        
        this.getTipoDocumentos();
        this.idBeneficiaria = parseInt(params["id"]);
        
        
      }
    });
  }
  filterDocs(documentos:any):any{
    let arreglo:any = [];
    for(let i=0; i<documentos.length;i++){
      if(this.getTipoById(documentos[i].idTipoDocumento) != "Nombre desconocido"){
        arreglo.push(documentos[i]);
      }
    }
    return this.sortingDocs(arreglo);
  }
  sortingDocs(documentos){
    let sortingArray:any=[];
    for(let i=0; i<this.tipoDocumentos.length;i++){
      for(let x=0; x<documentos.length; x++){
        if(this.tipoDocumentos[i].idParametro == documentos[x].idTipoDocumento){
          sortingArray.push(documentos[x]);
        }
      }
    }
    return sortingArray;
  }
  getDocumentos(){
    this.isLoading=true;
    this._documentosService.getAllByBeneficiaria(this.idBeneficiaria).subscribe(result=>{
      this.files = this.filterDocs(result.data.documentos);
      this.isLoading=false;
    },()=>{
      this.isLoading=false;
    });
  }
  getTipoDocumentos(){
    this.isLoading = true;
    this._parametrosService.getParametros("6").subscribe(result=>{
      this.tipoDocumentos = result.data.parametros;
      this.isLoading = false;
      this.getDocumentos();
    });
  }

  altaDocumento(){
    let formData = new FormData();
    formData.append('documento', this.fileToUpload);
    formData.append('idBeneficiaria', this.idBeneficiaria.toString());
    formData.append('idTipoDocumento', this.formulario.controls.tipoDocumento.value);
    formData.append('idTipoCatalogo', "");
    formData.append('desTipoCatalogo', this.formulario.controls.nombre.value);
    this.isLoading=true;
    this._documentosService.save(formData).subscribe(result=>{
      console.info(result);
      this.isLoading=false;
      this.getDocumentos();
      document.getElementById("resetButton").click();
      this.mensajeExitoAltaDoc();
    },()=>{
      this.isLoading=false;
    })
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.formulario.controls.archivo.setValue(this.fileToUpload.name);
  }

  sendForm(){
    console.info(this.formulario.getRawValue());
    if(this.formulario.valid){
      this.confirmaCrearDocumento();
      
    }
  }
  getTipoById(id){
    for(let i = 0; i<this.tipoDocumentos.length; i++){
      if(this.tipoDocumentos[i].idParametro== id){
        return this.tipoDocumentos[i].valor;
      }
    }
    return "Nombre desconocido";
  }


  confirmaEliminacion(file:any): void {
    const dialogRef = this.dialog.open(DesitionBoxComponent, {
      data: {titulo: "Eliminando documento", mensaje: "Está eliminando un documento, la información no podrá ser recuperada, ¿Desea continuar?", desicion:true}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if(result){
        this.eliminar(file);
      }
    });
  }

  confirmaCrearDocumento(): void {
    const dialogRef = this.dialog.open(DesitionBoxComponent, {
      data: {titulo: "Adjuntar documento", mensaje: "Está por adjuntar un documento, si el documento existe, este sera reemplazado y no podrá ser recuperado, ¿Desea continuar?", desicion:true}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if(result){
        this.altaDocumento();
      }
    });
  }

  mensajeExitoAltaDoc(): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {titulo: "Documento adjuntado", mensaje: "El documento se adjunto correctamente", desicion:true}
    });
  }
  mensajeExitoEliminarDoc(): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {titulo: "Documento eliminado", mensaje: "El documento fue eliminado correctamente", desicion:true}
    });
  }
  eliminar(file){
    let data = {
      "idTipoDocumento": file.idTipoDocumento,
      "idBeneficiaria": file.idBeneficiaria,
      "ruta": file.docTipoCatalogo
    };
    this.isLoading=true;
    this._documentosService.delete(data).subscribe(result=>{
      console.info(result);
      this.getDocumentos();
      this.isLoading=false;
      this.mensajeExitoEliminarDoc();
    },()=>{
      this.isLoading=false;
    })
  }

  

}
