import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogoInventarioService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient, 
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

 

  public postRegistrarPresentacion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registrarPresentacion", datos, {});
  }

  public getConsultarPresentacion(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarPresentacion?numero_presentacion="+id);
  }

  public getConsultarPresentaciones(p: number, r: number, s: string):any{
    if(s == ''){
        return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarPresentaciones?filas="+r+'&page='+p);
    }else{
        return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarPresentaciones?filas="+r+'&page='+p+'&buscador='+s);
    }
  }

  public postModificarPresentacion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarPresentacion", datos, {});
  }





  public postRegistrarAnexoUno(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registrarAnexoUno", datos, {});
  }

  public getConsultarAnexoUno(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarAnexoUno?numero_anexo_uno="+id);
  }

  public getConsultarAnexosUnos(p: number, r: number, s: string = ''):any{
    if(s == ''){
        return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarAnexosUnos?filas="+r+'&page='+p);
    }else{
        return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarAnexosUnos?filas="+r+'&page='+p+'&buscador='+s);
    }
  }

  public postModificarAnexoUno(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarAnexoUno", datos, {});
  }




  public postRegistrarAnexoDos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registrarAnexoDos", datos, {});
  }

  public getConsultarAnexoDos(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarAnexoDos?numero_anexo_dos="+id);
  }

  public getConsultarAnexosDos(p: number, r: number, s: string = ''):any{
    if(s == ''){
        return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarAnexosDos?filas="+r+'&page='+p);
    }else{
        return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarAnexosDos?filas="+r+'&page='+p+'&buscador='+s);
    }
  }

  public postModificarAnexoDos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarAnexoDos", datos, {});
  }



  public postRegistrarAnexoTres(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registrarAnexoTres", datos, {});
  }

  public getConsultarAnexoTres(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarAnexoTres?numero_anexo_tres="+id);
  }

  public getConsultarAnexosTres(p: number, r: number, s: string = ''):any{
    if(s == ''){
        return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarAnexosTres?filas="+r+'&page='+p);
    }else{
        return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarAnexosTres?filas="+r+'&page='+p+'&buscador='+s);
    }
  }

  public postModificarAnexoTres(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarAnexoTres", datos, {});
  }


  public getEliminarPresentaciones(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/eliminarPresentaciones?numero_presentacion="+id);
  }

  public getEliminarAnexoDos(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/eliminarAnexoDos?numero_anexo_dos="+id);
  }

  public getEliminarAnexoUno(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/eliminarAnexoUno?numero_anexo_uno="+id);
  }

  public getEliminarAnexoTres(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/eliminarAnexoTres?numero_anexo_tres="+id);
  }




  




  

 

  
  

  



  
}
