<div class="card-container">
    <div class="table-container">
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner color="accent"></mat-spinner>
        </div>
        <mat-card class="example-card">
            <mat-card-header>
                
                <mat-card-title>
                    Etapa candidata
                </mat-card-title>
                <mat-card-subtitle>
                    
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="formulario" (ngSubmit)="sendForm()" autocomplete="off">
                    <mat-form-field appearance="fill" class="bt col-md-4">
                        <mat-label>Fecha de Alta</mat-label>
                        <input matInput [matDatepicker]="dp2" formControlName="fechaAltaInicio">
                        <mat-datepicker-toggle matSuffix [for]="dp2" disabled></mat-datepicker-toggle>
                        <mat-datepicker touchUi #dp2 disabled="false"></mat-datepicker>
                    </mat-form-field>

                    <h3>Conclusiones Relevantes</h3>
                    <mat-radio-group aria-label="Select an option" formControlName="admision" (change)="conclusiones($event)">
                        <mat-radio-button value="true">Admisión</mat-radio-button>
                        <mat-radio-button value="false">No admisión</mat-radio-button>
                    </mat-radio-group>
                    <br>
                    <br>
                    <mat-form-field appearance="fill" class="bt col-md-12">
                        <mat-label>Descripción</mat-label>
                        <textarea matInput placeholder="" formControlName="descripcionConclusiones"></textarea>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-12">
                        <mat-label>Consideraciones para la etapa de prueba</mat-label>
                        <textarea matInput placeholder="" formControlName="consideraciones"></textarea>
                    </mat-form-field>

                    <h3>Carta de respuesta</h3>

                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Oficio Respuesta candidata CMG</mat-label>
                        <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo.value" formControlName="cartaDeRespuestaRuta" readonly>
                        <button mat-icon-button matSuffix (click)="archivo.click()" type="button">
                            <mat-icon>attach_file</mat-icon>
                        </button>
                        <input type="file" #archivo class="hide" formControlName="cartaDeRespuestaArchivo" (change)="handleFileInput1($event.target.files)"/>
                        
                        <a href="{{hostapi}}/{{documento1}}" *ngIf="documento1" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                            <mat-icon>download</mat-icon>
                        </a>
                        <mat-hint *ngIf="documento1">Ya existe un documento cargado</mat-hint>
                    </mat-form-field>
                    <br><br>
                    <div>
                        <mat-form-field appearance="fill" class="bt col-md-4">
                            <mat-label>Fecha de ingreso / declinación</mat-label>
                            <input matInput [matDatepicker]="dp1" formControlName="fechaAlta">
                            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #dp1 disabled="false"></mat-datepicker>
                        </mat-form-field>
                    </div>
                    
                    
                    <!--<mat-form-field appearance="fill" class="bt col-md-12">
                        <mat-label>Oficio Respuesta candidata CMG</mat-label>
                        <input matInput [value]="file.value" formControlName="cartaDeRespuesta" readonly>
                        <button mat-icon-button matSuffix (click)="file.click()" type="button">
                            <mat-icon>attach_file</mat-icon>
                        </button>
                        <input type="file" #file class="hide" (change)="handleFileInput($event.target.files)"/>
                    </mat-form-field>
                    -->


                <br><br>
                    <div>
                        <button class="flat" type="reset" id="resetButton" class="hide"></button>
                        <button class="flat" type="submit" color="primary" mat-flat-button *ngIf="proceso?.indEstatus == 'A'">Guardar</button> &nbsp;
                        <button class="flat" type="button" color="accent" mat-flat-button (click)="cerrarEtapa()" *ngIf="proceso?.indEstatus == 'A'">Cerrar etapa</button>&nbsp;
                        <button class="flat" type="button" color="default" mat-stroked-button (click)="cancelar()">Cancelar</button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>