<app-datos-generales></app-datos-generales>
<div class="card-container" id="top">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Historial clinico</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        
        <mat-card-content>
            <!-- [(selectedIndex)]="selectedIndex" id-->
            
            <mat-tab-group mat-align-tabs="start">
                <mat-tab label="Formulario 1">
                    <fieldset>
                        <legend></legend>
                        <!--[selectedIndex]="selectedIndex" (siguienteEvento)="siguienteEvento($event)"-->
                        <app-historial-clinico1 ></app-historial-clinico1>
                    </fieldset>
                </mat-tab>
                <mat-tab label="Formulario 2">
                    <fieldset>
                        <legend></legend>
                        <!-- [selectedIndex]="selectedIndex" (siguienteEvento)="siguienteEvento($event)"-->
                        <app-historial-clinico2></app-historial-clinico2>
                    </fieldset>
                </mat-tab>
        </mat-tab-group>

    </mat-card-content>
</mat-card>
</div>