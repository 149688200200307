import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {jsPDF} from 'jspdf';
import { DesitionBoxComponent } from 'src/app/modals/desition-box/desition-box.component';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { Beneficiaria } from 'src/app/model/beneficiaria';
import { Paginador } from 'src/app/model/configuracion';
import { Usuario } from 'src/app/model/usuario';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { EventosDeVidaService } from 'src/app/services/eventos-de-vida.service';
import { ParametrosService } from 'src/app/services/parametros.service';
import { environment } from 'src/environments/environment';

const ELEMENT_DATA: Usuario[] = [];

@Component({
  selector: 'app-eventos-de-vida',
  templateUrl: './eventos-de-vida.component.html',
  styleUrls: ['./eventos-de-vida.component.scss']
})
export class EventosDeVidaComponent implements OnInit {
  displayedColumns: string[] = ['position','fecha','nombreEvento','clasificacion','evento','eliminar','imprimir'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading = true;
  paginador:Paginador;
  buscador = "";
  formulario:UntypedFormGroup;
  length:number = 0;
  clasificaciones = [];
  id:number;
  beneficiaria:Beneficiaria;
  ficha:any;
  hostapi:string;
  phpPath:string;
  constructor(
    private _documentosService:DocumentosService,
    private _parametrosService:ParametrosService,
    private _route:Router,
    private _beneficiariasService:BeneficiariaService,
    private activatedRoute:ActivatedRoute,
    private dialog:MatDialog,
    private _config:ConfigurationService,
    private _eventosDeVidaService:EventosDeVidaService
    ) {
      this.hostapi = _config.getConfiguration().hostapi;
      this.phpPath = _config.getConfiguration().embedPhpPath;
      this._parametrosService.getParametros("22").subscribe(result=>{
        this.clasificaciones = result.data.parametros;
      });
      this.paginador = this._config.getConfiguration().ui.paginador;
      this.formulario = new UntypedFormGroup({
        buscador : new UntypedFormControl("",[])
      })
  }
  recibeBeneficiaria($event){
    console.info("Beneficiaria recibida");
    console.info($event);
    this.beneficiaria = $event;
  }
  recibeFichaTecnica($event){
    console.info("Ficha técnica recibida");
    console.info($event);
    this.ficha = $event;
    this.cargaInicial();
  }
  esBeneficiaria = false;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      this.esBeneficiaria = false;
      if(params['id']){
        this.esBeneficiaria = true;
        this.id = params['id'];
        //this.getEventosDeVidaByBeneficiaria(1,this.paginador.pageSize);
        //this.displayedColumns = ['fecha','nombreEvento','clasificacion','consulta','imprimir'];
      }else{
        this.getEventosDeVida(1,this.paginador.pageSize);
        this.esBeneficiaria = false;
      }
    });
  }
  cargaInicial(){
    this.getEventosDeVidaByBeneficiaria(1,this.paginador.pageSize);
    this.displayedColumns = ['fecha','nombreEvento','clasificacion','consulta','imprimir'];
  }

  sendForm(){
    this.buscador = this.formulario.controls.buscador.value;
    if(!this.id){
      this.getEventosDeVida(1,this.paginador.pageSize);
    }else{
      this.getEventosDeVidaByBeneficiaria(1,this.paginador.pageSize);
    }
    
  }
  getEventosDeVida(page:number,size:number){
    this.isLoading = true;
    this._eventosDeVidaService.findAll(this.buscador,page,size).subscribe(result=>{
      if(result.codigo == "EVE000"){
        console.log(result);
        this.dataSource = new MatTableDataSource<any>(result.data.eventos); 
        this.length = result.data.count;
      }else{
        this.dataSource = new MatTableDataSource<any>([]); 
      }
      this.isLoading = false;      
    },()=>{
      this.isLoading = false;
      this.dataSource = new MatTableDataSource<any>([]); 
    });
  }

  getEventosDeVidaByBeneficiaria(page:number,size:number){
    this.isLoading = true;
    this._eventosDeVidaService.findAllByBeneficiaria(this.id,page,size).subscribe(result=>{
      if(result.codigo == "EVE000"){
        this.dataSource = new MatTableDataSource<any>(result.data.eventos); 
        this.length = parseInt(result.data.count[0]["1"]);
      }else{
        this.dataSource = new MatTableDataSource<any>([]); 
      }
      this.isLoading = false;      
    },()=>{
      this.isLoading = false;
      this.dataSource = new MatTableDataSource<any>([]);
    });
  }
  pager(event){
    this.paginator.pageSize = event.pageSize;
    this.paginator.pageIndex = event.pageIndex;
    if(!this.id){
      this.getEventosDeVida(event.pageIndex + 1 ,event.pageSize);
    }else{
      this.getEventosDeVidaByBeneficiaria(event.pageIndex + 1 ,event.pageSize);
    }
    
  }
  eliminar(id){
    const dialogRef = this.dialog.open(DesitionBoxComponent,{
      data : { titulo: "¿Desea eliminar el evento?" , mensaje : "La información del evento será eliminada de manera permanente, ¿Desea continuar?", desicion : true }
    });
    dialogRef.afterClosed().subscribe(result=>{
      console.info(result);
      if(result){
        this.isLoading = true;
        this._eventosDeVidaService.delete(id).subscribe(result=>{
          this.isLoading = false;
          this.mensaje("Evento eliminado","El evento fue eliminado de forma satisfactoria");
        },()=>{
          this.isLoading = false;
        })
      }
    });
  }

  mensaje(titulo, mensaje){
    const dialogRef = this.dialog.open(MessageBoxComponent,{
      data:{titulo: titulo ,mensaje: mensaje}
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.ngOnInit();
    })
  }
  getClasificacion(id){
    for(let i=0; i<this.clasificaciones.length;i++){
      if(this.clasificaciones[i].idParametro == id){
        return this.clasificaciones[i].valor;
      }
    }
    return "N/D";
  }
  imprimirPdf(id){
    this.isLoading=true;
    this._eventosDeVidaService.fidByIdDetail(id).subscribe(result=>{
      this.print(result);
    },()=>{
      this.isLoading = false;
    })
  }
  print(datos){
    console.info("Creando PDF");
        let doc = new jsPDF('p','px','letter');
        
        doc.setFont("helvetica", "bold");
        doc.text("FICHA INFORMATIVA DE EVENTO DE VIDA", 90, 20,{align:"left"},null);
        doc.setFont("helvetica", "normal");
        doc.text("", 90, 35,{align:"left"},null);
        doc.setFontSize(10);
        
        doc.addImage("assets/img/logo-goretti.jpg","JPEG", 8, 0, 80.4, 57.2);
        let iy=55;
        if(this.beneficiaria.foto!="URL FOTO"){
          //this.convertImgToBase64("/apiphp/"+this.beneficiaria.foto,null);
          doc.addImage(this._config.getConfiguration().hostapi+"/"+this.beneficiaria.foto,"JPEG",15, iy+5, 80, 80);
        }else{
          doc.addImage("assets/img/unknown.jpg","JPEG", 12, iy+7, 50, 51);
        }
        
        this.imprimirFichaTecnica(doc,iy);
        //doc.rect(10, iy+125, 425, 120);
        doc.setFont("helvetica", "bold");
        doc.text("Fecha de apertura:".toUpperCase(),20,iy+135);
        doc.setFont("helvetica", "normal");
        doc.text(datos.data[0].fecha_apertura,20,iy+145);

        doc.setFont("helvetica", "bold");
        doc.text("Nombre del evento:".toUpperCase(),220,iy+135);
        doc.setFont("helvetica", "normal");
        doc.text(datos.data[0].nombre,200,iy+145);
        
        doc.setFont("helvetica", "bold");
        doc.text("Descripción:".toUpperCase(),20,iy+155);
        doc.setFont("helvetica", "normal");
        let descripcion = datos.data[0].descripcion;
        let i=0;
        while(descripcion.length>0){
          doc.text(descripcion.substring(0,124),20, (iy+165) + (10*(i+1)));
          descripcion = descripcion.substring(124,descripcion.length);
          i++;
        }
        









        doc.setFont("helvetica", "bold");
        doc.text("Clasificación:".toUpperCase(),20,iy+215);
        doc.setFont("helvetica", "normal");
        doc.text(datos.data[0].clasificacion,20,iy+225);

        doc.setFont("helvetica", "bold");
        doc.text("Beneficiarias participantes:".toUpperCase(),20,iy+235);
        doc.setFont("helvetica", "normal");
        let arrayBenef = datos.data[0].beneficiarias.split(",");
        for(let i=0; i<arrayBenef.length;i++){
          let cont = i+1;
          doc.text(cont +".- "+arrayBenef[i].trim().toUpperCase(),20,(iy+235) + (10*(i+1)));
        }

        doc.setFont("helvetica", "bold");
        doc.text("Terceros involucrados:".toUpperCase(),220,iy+235);
        doc.setFont("helvetica", "normal");
        let arrayTerceros = datos.data[0].terceros.split(",");
        for(let i=0; i<arrayTerceros.length;i++){
          let cont = i+1;
          doc.text(cont +".- " + arrayTerceros[i].trim().toUpperCase(),220,(iy+235) + (10*(i+1)));
        }


        doc.addPage();
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("FICHA INFORMATIVA DE EVENTO DE VIDA", 90, 20,{align:"left"},null);
        doc.setFont("helvetica", "normal");
        doc.text("", 90, 35,{align:"left"},null);
        
        doc.addImage("assets/img/logo-goretti.jpg","JPEG", 8, 0, 80.4, 57.2);
        let x = 20;
        let y = 60;
        let ancho = 150;
        let alto = 150;
        for(let i=1; i<6;i++){
          if(datos.data[0]["foto"+i]!=null){
            if(x + ancho >= 425){
              x = 20;
              y += alto + 10;
            }
            doc.addImage(this._config.getConfiguration().hostapi+"/"+datos.data[0]["foto"+i],"JPEG",x, y, ancho, alto);
            x += ancho + 10;
          }
        }
        /*
        doc.addImage(environment.apiUrl+"/"+datos.data[0].foto1,"JPEG",15, iy+5, 100, 100);
        doc.addImage(environment.apiUrl+"/"+datos.data[0].foto2,"JPEG",15, iy+5, 100, 100);
        doc.addImage(environment.apiUrl+"/"+datos.data[0].foto3,"JPEG",15, iy+5, 100, 100);
        doc.addImage(environment.apiUrl+"/"+datos.data[0].foto4,"JPEG",15, iy+5, 100, 100);
        doc.addImage(environment.apiUrl+"/"+datos.data[0].foto5,"JPEG",15, iy+5, 100, 100);
        */
        doc.save("Ficha informativa de evento de vida"+".pdf");
        this.isLoading=false;
  }
  imprimirFichaTecnica(doc,iy){
    doc.rect(10, iy, 425, 120);
    doc.setFont("helvetica", "bold");
    doc.text("Nombre completo".toUpperCase(),100,iy+10);
    doc.text("Fecha de nacimiento".toUpperCase(),100,iy+25);
    doc.text("Canalizada por".toUpperCase(),100,iy+40);
    doc.text("Fecha de ingreso".toUpperCase(),100,iy+55);
    doc.text("Tipo de discapacidad".toUpperCase(),100,iy+70);
    doc.text("Diagnóstico DN".toUpperCase(),100,iy+85);
    doc.text("Condición relevante".toUpperCase(),100,iy+100);

    doc.text("Curp".toUpperCase(),270,iy+10);
    doc.text("Edad actual".toUpperCase(),270,iy+25);
    doc.text("Motivo de ingreso".toUpperCase(),270,iy+40);
    doc.text("Edad al ingreso".toUpperCase(),270,iy+55);
    doc.text("Tiempo de estancia".toUpperCase(),270,iy+70);
    doc.text("Diagnóstico DM".toUpperCase(),270,iy+85);
    doc.text("Tipo de sangre".toUpperCase(),270,iy+100);
    
    doc.setFont("helvetica", "normal");
    doc.text(this.ficha.nombreCompleto,100,iy+16);
    doc.text(this.ficha.fechaNacimiento,100,iy+31);
    doc.text(this.ficha.canalizadaPor ,100,iy+46);
    doc.text(this.ficha.fechaIngreso,100,iy+61);
    doc.text(this.ficha.tipoDeDiscapacidad,100,iy+76);
    doc.text(this.ficha.diagnosticoDN,100,iy+91);
    doc.text(this.ficha.condicionRelevante,100,iy+106);

    //doc.text(this.beneficiaria.nombre,50,10);

    doc.text(this.ficha.curp ? this.ficha.curp : "-" ,270,iy+16);
    doc.text(this.ficha.edadActual,270,iy+31);
    doc.text(this.ficha.motivoDeIngreso,270,iy+46);
    doc.text(this.ficha.edadIngreso,270,iy+61);
    doc.text(this.ficha.tiempoDeEstancia,270,iy+76);
    doc.text(this.ficha.diagnosticoDM,270,iy+91);
    doc.text(this.ficha.tipoDeSangre,270,iy+106);
  }
  
}
