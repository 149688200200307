<div class="card-container none-vd-aux">
    
    <mat-card class="example-card table-container">
     
        <mat-card-header>
            <mat-card-title>Consulta de datos del documento</mat-card-title>
        </mat-card-header>
        
        <mat-card-content>
           <div class="spinner" *ngIf="isLoading">
            <mat-spinner color="accent"></mat-spinner>
        </div>
        <form #formu [formGroup]="formulario">
            <mat-form-field appearance="fill" class="bt col-md-6 none-vd-aux">
                <mat-label>Categoría</mat-label>
                <mat-select formControlName="categoria" #categoria_selected >
                    <mat-option value="">-- Seleccione una opción --</mat-option>
                    <mat-option  *ngFor="let categoria of categorias" [value]="categoria.id">{{categoria.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="bt col-md-6 none-vd-aux">
                <mat-label>Subcategoría</mat-label>
                <mat-select formControlName="subcategoria" #subcategoria_selected>
                    <mat-option value="">-- Seleccione una opción --</mat-option>
                    <ng-container *ngFor="let subcategoria of subcategorias">
                        <mat-option *ngIf="subcategoria.id_categoria==categoria_selected.value" [value]="subcategoria.id">{{subcategoria.nombre}}</mat-option>
                    </ng-container>
                    
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="bt col-md-6 none-vd-aux">
                <mat-label>Tipo de documento</mat-label>
                <mat-select formControlName="tipo_documento" #tipo_docto>
                    <mat-option value="">-- Seleccione una opción --</mat-option>
                    <ng-container *ngFor="let tipo of tipoDocumentos">
                        <mat-option *ngIf="tipo.id_subcategoria==subcategoria_selected.value" [value]="tipo.id">{{tipo.nombre}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="bt col-md-6 none-vd-aux">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Nombre del documento" formControlName="nombre" maxlength="50">
            </mat-form-field>

            <mat-form-field appearance="fill" class="bt col-md-12">
                <mat-label>Descripción</mat-label>
                <input matInput placeholder="Descripción del documento" formControlName="descripcion" maxlength="1000">
            </mat-form-field>

            <mat-form-field appearance="fill" class="bt col-md-6 none-vd-aux">
                <mat-label>Fecha de alta</mat-label>                    
                <input matInput [matDatepicker]="picker" formControlName="fecha_alta" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="bt col-md-6 none-vd-aux">
                <mat-label>Carga de Documento</mat-label>
                <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo.value" formControlName="documentoRuta" readonly>
                <input type="file" #archivo class="hide" formControlName="documento"/>
                
            </mat-form-field>
            <mat-card-actions>
                <div class="text-align-center">
                    <button type="button" color="primary" mat-flat-button [routerLink]="['/centro-informacion-institucional']">Aceptar</button>
                </div>
            </mat-card-actions>
        </form>
        
    </mat-card-content>
    
</mat-card>
</div>