<h1 mat-dialog-title *ngIf="data?.icon == 'correct'">
  <mat-icon>done</mat-icon>
</h1>
<h1 mat-dialog-title *ngIf="data?.icon == 'error'">
  <mat-icon>close</mat-icon>
</h1>
<h1 mat-dialog-title *ngIf="data?.icon == 'error_outline'">
  <mat-icon>info</mat-icon>
</h1>
<div mat-dialog-content>
  <br>
  <h1>{{data?.titulo}}</h1>
  <p>{{data?.mensaje}}</p>
</div>

<div mat-dialog-actions align="center">
  <button mat-flat-button color="primary" mat-dialog-close>Cerrar</button>
</div>