<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Registro Grupal de Peso y Talla</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <form novalidate [formGroup]="form" autocomplete="off">
                <!-- parte 1 -->
                <div class="row">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Periodo de registro</mat-label>
                        <input type="text" matInput [value]="per" readonly="true">
                    </mat-form-field>
                </div>

                <div class="row">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Fecha de apertura</mat-label>
                        <input type="text" matInput [value]="fa.split('T')[0]" readonly="true">
                    </mat-form-field>
                </div>
                
                <div class="row">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Nombre de quien inicia el registro</mat-label>
                        <input type="text" matInput formControlName="persona_inicio">
                    </mat-form-field>
                </div>

                <div class="row">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Comentarios</mat-label>
                        <input type="text" matInput formControlName="comentarios">
                    </mat-form-field>
                </div>
            </form>

            <div class="mt-2 d-flex justify-content-center gap-2">


                



                <form novalidate [formGroup]="form" (ngSubmit)="postModificarPeriodo()">
                    <button *ngIf="accion == 'modificar' && s == 6" class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>

                <form novalidate [formGroup]="form" (ngSubmit)="postCerrarPeriodo()">
                    <button *ngIf="accion == 'modificar' && s == 6" class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Cerrar registro
                    </button>
                </form>

                
                    <a class="flat" color="accent" mat-flat-button href="{{ghedp_laravelapiStorage}}/Biblioteca/General/biblioteca1.pdf" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon> Tabla PDF
                    </a>

                    <a class="flat" color="accent" mat-flat-button href="{{ghedp_laravelapi}}/consultarTallasPeriodoExcel?numero_periodo={{np}}" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon> Reporte de excel
                    </a>
                


            </div>



                <div class="row example-container mat-elevation-z8 mt-5" >
                    <table  class="table table-sm table-bordered" >
                        <caption></caption>
                        <thead style="position: sticky;top: 0; opacity: 1;" class="table-dark">
                            <tr  class="table-dark">
                                <th   scope="col"></th>
                                
                                <th   scope="col"></th>
                                <th   scope="col"></th>

                                    
                                <th id="ene" colspan="3" class="text-center">ENE</th>
                               
                                <th id="feb" colspan="3" class="text-center">FEB</th>
                               

                                <th id="mar" colspan="3" class="text-center">MAR</th>
                               

                                <th id="abr"  colspan="3" class="text-center">ABR</th>
                               

                                <th id="may"  colspan="3" class="text-center">MAY</th>
                                

                                <th id="jun"  colspan="3" class="text-center">JUN</th>
                                

                                <th id="jul"  colspan="3" class="text-center">JUL</th>
                               

                                <th id="ago"  colspan="3" class="text-center">AGO</th>
                                

                                <th id="sep"  colspan="3" class="text-center">SEP</th>
                                

                                <th id="oct"  colspan="3" class="text-center">OCT</th>
                                

                                <th id="nov" colspan="3" class="text-center">NOV</th>
                                
                                <th id="dic"  colspan="3" class="text-center">DIC</th>
                                
                            </tr>
                            <tr >
                                <th  scope="col">ID</th>
                                <th  scope="col" style="width: 100px !important;">NOMBRE</th>
                                <th  scope="col">EDAD ACTUAL</th>
                                    
                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>
                          
                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>
                                
                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let d of dt; let i = index" >
                                <td style="opacity: 1 !important; background-color: #CFE3FF !important;" class="opacity-100">{{d?.id_periodo_bene_pk}}</td>
                                <td style="opacity: 1 !important; background-color: #CFE3FF !important;" class="text-black opacity-100">
                                    <mat-form-field  class="example-form-field-2 opacity-100" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.beneficiario.nombre"  ></textarea>
                                    </mat-form-field>
                                    
                                    
                                    <button *ngIf="accion == 'modificar' && s == 6" (click)="postModificarTallaBeneficiariaBeneficiaria(i)"  type="button" mat-flat-button color="primary" >
                                        Guardar cambios
                                    </button>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field-2" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="edad(d.beneficiario.fecha_nacimiento)" readonly="true" ></textarea>
                                    </mat-form-field >
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <input matInput type="text" rows="1" [(ngModel)]="d.ene_peso">
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.ene_talla"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="(d.ene_peso/(d.ene_talla*d.ene_talla)).toFixed(2)"  readonly="true"></textarea>
                                    </mat-form-field >
                                </td>
                            
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.febrero_peso"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.febrero_talla"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="(d.febrero_peso/(d.febrero_talla*d.febrero_talla)).toFixed(2)"  readonly="true"></textarea>
                                    </mat-form-field >
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.marzo_peso"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.marzo_talla"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="(d.marzo_peso/(d.marzo_talla*d.marzo_talla)).toFixed(2)"  readonly="true"></textarea>
                                    </mat-form-field >
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.abril_peso"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.abril_talla"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="(d.abril_peso/(d.abril_talla*d.abril_talla)).toFixed(2)"  readonly="true"></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.mayo_peso"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.mayo_talla"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="(d.mayo_peso/(d.mayo_talla*d.mayo_talla)).toFixed(2)"  readonly="true"></textarea>
                                    </mat-form-field >
                                </td>
                            
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.junio_peso"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.junio_talla"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="(d.junio_peso/(d.junio_talla*d.junio_talla)).toFixed(2)"  readonly="true"></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.julio_peso"  ></textarea>
                                    </mat-form-field >
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.julio_talla"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="(d.julio_peso/(d.julio_talla*d.julio_talla)).toFixed(2)"  readonly="true"></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.agosto_peso"  ></textarea>
                                    </mat-form-field >
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.agosto_talla"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="(d.agosto_peso/(d.agosto_talla*d.agosto_talla)).toFixed(2)" readonly="true"></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.septiembre_peso"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.septiembre_talla"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="(d.septiembre_peso/(d.septiembre_talla*d.septiembre_talla)).toFixed(2)" readonly="true" ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.octubre_peso"  ></textarea>
                                    </mat-form-field >
                                </td>
                            
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.octubre_talla"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="(d.octubre_peso/(d.octubre_talla*d.octubre_talla)).toFixed(2)"  readonly="true"></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.nov_peso"  ></textarea>
                                    </mat-form-field >
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.nov_talla"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="(d.nov_peso/(d.nov_talla*d.nov_talla)).toFixed(2)" readonly="true" ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.dic_peso"  ></textarea>
                                    </mat-form-field >
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [(ngModel)]="d.dic_talla"  ></textarea>
                                    </mat-form-field >
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea matInput type="text" rows="1" [value]="(d.dic_peso/(d.dic_talla*d.dic_talla)).toFixed(2)" readonly="true" ></textarea>
                                    </mat-form-field>
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>

                


                
           

            


        </mat-card-content>
    </mat-card>
</div>
