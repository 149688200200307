<app-datos-generales></app-datos-generales>
<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Control de peso y talla</mat-card-title>
            <mat-card-subtitle>Periodos</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>

            <div class="mt-2 d-flex justify-content-center gap-2">



                
                    <a class="flat" color="accent" mat-flat-button href="{{ghedp_laravelapiStorage}}/Biblioteca/General/biblioteca1.pdf" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon> Tabla PDF
                    </a>

                    <a class="flat" color="accent" mat-flat-button href="{{laravelapi}}/consultarTallasBeneficiariaExcel?numero_beneficiario={{id}}" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon> Reporte de excel
                    </a>
                


            </div>
           


                



                <div class="row example-container mat-elevation-z8 mt-5" >
                    <table  class="table table-sm table-bordered " >
                        <caption></caption>
                        <thead style="position: sticky;top: 0" class="table-dark">
                            <tr  class="table-dark">
                                <th   scope="col"></th>
                                

                                    
                                <th id="ene" colspan="3" class="text-center">ENE</th>
                               
                                <th id="feb" colspan="3" class="text-center">FEB</th>
                               

                                <th id="mar" colspan="3" class="text-center">MAR</th>
                               

                                <th id="abr"  colspan="3" class="text-center">ABR</th>
                               

                                <th id="may"  colspan="3" class="text-center">MAY</th>
                                

                                <th id="jun"  colspan="3" class="text-center">JUN</th>
                                

                                <th id="jul"  colspan="3" class="text-center">JUL</th>
                               

                                <th id="ago"  colspan="3" class="text-center">AGO</th>
                                

                                <th id="sep"  colspan="3" class="text-center">SEP</th>
                                

                                <th id="oct"  colspan="3" class="text-center">OCT</th>
                                

                                <th id="nov" colspan="3" class="text-center">NOV</th>
                                
                                <th id="dic"  colspan="3" class="text-center">DIC</th>
                            </tr>
                            <tr >
                                <th  scope="col">PERIODO</th>
                                    
                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>
                          
                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>
                                
                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                <th  scope="col">Peso (Kg)</th>
                                <th  scope="col">Talla (m)</th>
                                <th  scope="col">IMC</th>

                                
                            </tr>
                        </thead>
                        <tbody class="table-secondary">
                            <tr *ngFor="let be of dt; let i = index">
                                <td>
                                    <mat-form-field class="example-form-field"  appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text" rows="1" [value]="be?.periodo?.year_periodo"></textarea>
                                    </mat-form-field >
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.ene_peso" ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.ene_talla"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.ene_imc"  ></textarea>
                                    </mat-form-field>
                                </td>
                            
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.febrero_peso"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.febrero_talla"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.febrero_imc"  ></textarea>
                                    </mat-form-field>
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.marzo_peso"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.marzo_talla"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.marzo_imc"  ></textarea>
                                    </mat-form-field>
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.abril_peso"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.abril_talla"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.abril_imc"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.mayo_peso"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.mayo_talla"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.mayo_imc"  ></textarea>
                                    </mat-form-field>
                                </td>
                            
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.junio_peso"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.junio_talla"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.junio_imc"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.julio_peso"  ></textarea>
                                    </mat-form-field>
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.julio_talla"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.julio_imc"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.agosto_peso"  ></textarea>
                                    </mat-form-field>
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.agosto_talla"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.agosto_imc"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.septiembre_peso"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.septiembre_talla"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.septiembre_imc"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.octubre_peso"  ></textarea>
                                    </mat-form-field>
                                </td>
                            
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.octubre_talla"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.octubre_imc"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.nov_peso"  ></textarea>
                                    </mat-form-field>
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.nov_talla"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.nov_imc"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.dic_peso"  ></textarea>
                                    </mat-form-field>
                                </td>

                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.dic_talla"  ></textarea>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-form-field" appearance="fill" >
                                        <mat-label></mat-label>
                                        <textarea readonly="true" matInput type="text"  [value]="be.dic_imc"  ></textarea>
                                    </mat-form-field>
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>

                


                
           

            


        </mat-card-content>
    </mat-card>
</div>
