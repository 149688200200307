<div class="container-fluid">
    <mat-card >
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <app-datos-generales></app-datos-generales>

            <div class="row mt-3">

                <div class="col">

                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">

              
                <table class="table">
                    <caption><!--sonar--></caption>
                    <thead class="table-dark">
                        <tr>
                            <th scope="col" class="text-center">Tipo</th>
                            <th scope="col">Imprimir</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row" class="text-center">HISTORIAL CLÍNICO</th>
                            <td>
                                <a  mat-flat-button color="primary"target="_blank" href="{{laravelapi}}/consultaDatosHistorialClinico?numero_beneficiario={{id}}&pdf=1" rel=noopener> <!-- href="{{laravelapiStorage}}/{{data?.ruta_relativa+'?t='+t}}" -->
                                    <mat-icon class="material-icons">
                                        file_open
                                    </mat-icon>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-center">CAMPAÑAS Y TERAPIAS</th>
                            <td> <a  mat-flat-button color="primary"target="_blank" href="{{laravelapi}}/ReporteTerapiasPDF?filas=1&numero_beneficiario={{id}}&pdf=1" rel=noopener> <!-- href="{{laravelapiStorage}}/{{data?.ruta_relativa+'?t='+t}}" -->
                                    <mat-icon class="material-icons">
                                        file_open
                                    </mat-icon>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-center">CONTROL DE PESO Y TALLA</th>
                            <td> <a  mat-flat-button color="primary"target="_blank" href="{{laravelapi}}/consultarTallasBeneficiariaExcel?numero_beneficiario={{id}}&pdf=1" rel=noopener> <!-- href="{{laravelapiStorage}}/{{data?.ruta_relativa+'?t='+t}}" -->
                                    <mat-icon class="material-icons">
                                        file_open
                                    </mat-icon>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-center">CONTROL DE VACUNACIÓN</th>
                            <td> <a  mat-flat-button color="primary"target="_blank" href="{{laravelapi}}/reporteVacunasBeneficiariaPDF?numero_beneficiaria={{id}}&pdf=1&fecha_temporal={{fecha_temporal?.split(' ')[0]}}" rel=noopener> <!-- href="{{laravelapiStorage}}/{{data?.ruta_relativa+'?t='+t}}" -->
                                    <mat-icon class="material-icons">
                                        file_open
                                    </mat-icon>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-center">EVENTO MÉDICO</th>
                            <td> <a  mat-flat-button color="primary"target="_blank" href="{{laravelapi}}/consultaEventosMedicos?filas=1&numero_beneficiario={{id}}&pdf=1" rel=noopener> <!-- href="{{laravelapiStorage}}/{{data?.ruta_relativa+'?t='+t}}" -->
                                    <mat-icon class="material-icons">
                                        file_open
                                    </mat-icon>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col">

            </div>


          
            
           
                
            
        </div>

        </mat-card-content>
    </mat-card>
</div>