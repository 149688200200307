//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { MedicoService } from 'src/app/services/medico.service';
import { SesionService } from 'src/app/services/sesion.service';
import { RrhhService } from 'src/app/services/rrhh.service';

@Component({
  selector: 'app-impresion-expediente-medico',
  templateUrl: './impresion-expediente-medico.component.html',
  styleUrls: ['./impresion-expediente-medico.component.scss'],
  providers: [ MedicoService ]
})
export class ImpresionExpedienteMedicoComponent implements OnInit {

  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id: number = -1;
  public laravelapi = this.reie_rrhhService.getLaravelApi();
  public fecha_temporal: any;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _medicoService: MedicoService,
    private _router: Router,
    private _route: ActivatedRoute,  
    private reie_rrhhService: RrhhService,
  ) { 
    /*modificar*/
    this._route.queryParams.subscribe(
      params => {
        this.id = +params['id'];
        this.fecha_temporal = params['fecha_temporal'];
      });
    /*fin modificar*/

  }

  ngOnInit(): void {
  }

}
