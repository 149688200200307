import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';

const CONST_ETAPA : String = "DESARROLLO";

@Component({
  selector: 'app-desarrollo',
  templateUrl: './desarrollo.component.html',
  styleUrls: ['./desarrollo.component.scss']
})


export class DesarrolloComponent implements OnInit {


  notas = [];
  notas2 = [];
  formulario: UntypedFormGroup;
  idProceso:number;
  idBeneficiaria:number;
  isLoading = false;
  indEstatus:any;
  conclusiones = "";
  constructor(private dialog:MatDialog,private _location:Location,private activatedRoute:ActivatedRoute,private _beneficiariaService:BeneficiariaService) {
    this.formulario = new UntypedFormGroup({
      fechaApertura : new UntypedFormControl("",[Validators.required]),
      conclusionesRelevantes: new UntypedFormControl("",[]),
      fechaCierre: new UntypedFormControl(null,[]),

      feecha: new UntypedFormControl("",[]),
      descripcion_noota: new UntypedFormControl("",[]),
    
    })
    this.formulario.controls["fechaApertura"].disable();
  }

  ngOnInit(): void {
    this.notas = [];
    this.notas2 = [];
    this.activatedRoute.queryParams.subscribe(params=>{
      this.idProceso = params["idProceso"];
      this.idBeneficiaria = params["idBeneficiaria"];
      this.isLoading=true;
      this._beneficiariaService.getProcesoDesarrollo(this.idProceso).subscribe(result=>{
        this.isLoading=false;
        
        this.conclusiones = result.data.conclusionesSanacion;
        this.indEstatus = result.data.indEstatus;
        this.notas = result.data.notas;
        if(result.data.fechaCierre){
          this.formulario.controls.fechaCierre.setValue(new Date(result.data.fechaCierre.date));
        }
        if(result.data.fechaApertura){
          console.info(params["fechaAlta"]);
          this.formulario.controls.fechaApertura.setValue(new Date(params["fechaAlta"]));
          //this.formulario.controls.fechaApertura.setValue(new Date(result.data.fechaApertura.date));
        }
        this.formulario.controls.conclusionesRelevantes.setValue(result.data.conclusiones);
      },()=>{
        this.isLoading=false;
      })
    });
    
  }

  
  cancelar__(){
    this._location.back();
  }
  sendForm__(){
    console.info(this.formulario.controls);
    if(this.formulario.valid){
      this.guardar__(0);
    }else{
      console.info("invalid");
    }
  }

  guardar__(indicador){
    let data = {
      "tipoEvento": "EVENTO_GUARDAR",
      "etapaActual": CONST_ETAPA,
      "informacion":{
        "idProceso": this.idProceso,
        "fechaApertura": this.formulario.controls.fechaApertura.value.toJSON().replace("T"," ").replace(".000Z",""),
        "fechaCierre": this.formulario.controls.fechaCierre.value ? this.formulario.controls.fechaCierre.value.toJSON().replace("T"," ").replace(".000Z",""):"",
        "conclusiones":this.formulario.controls.conclusionesRelevantes.value,
        "notas":[
        {"fechaNota": "2020-12-29 00:00:00","descNota":"Nota Extra"}
        ]
      }
    };

    data.informacion.notas = [];
    for(let i=0; i<this.notas2.length; i++){
      data.informacion.notas.push({
        "fechaNota" : this.formulario.controls["fechaNota"+i].value.toJSON().replace("T"," ").replace(".000Z",""),
        "descNota" : this.formulario.controls["descripcionNota"+i].value
      });
    }
    console.info(data);
    this.isLoading=true;
    this._beneficiariaService.updateEtapa(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading=false;
      if(indicador==2){
        this.cerrarEtapa2__();
      }else if(indicador == 3){
        this.cerrarEtapa3__();
      }else{
        this.mensaje2__("Etapa actualizada","La etapa se actualizo correctamente",true);
      }
    },()=>{
      this.isLoading=false;
    })
  }
  cerrarEtapa__(){
    console.info(this.formulario.controls.fechaCierre.value);
    if(this.formulario.controls.fechaCierre.value){
      this.guardar__(2);
    }else{
      this.dialog.open(MessageBoxComponent,{
        data:{titulo:"Ingrese fecha de cierre",mensaje:"Para cerrar la etapa es necesario completar el campo 'Fecha de cierre'"}
      })
    }
  }
  egresar__(){
    console.info(this.formulario.controls.fechaCierre.value);
    if(this.formulario.controls.fechaCierre.value){
      this.guardar__(3);
    }else{
      this.dialog.open(MessageBoxComponent,{
        data:{titulo:"Ingrese fecha de cierre",mensaje:"Para cerrar la etapa es necesario completar el campo 'Fecha de cierre'"}
      })
    }
  }
  cerrarEtapa2__(){
    let data = {
      "tipoEvento": "EVENTO_CERRAR",
      "etapaActual": CONST_ETAPA,
      "informacion":{
        "idProceso": this.idProceso,
      }
    };

    this.auxiliar(data);


  }
  cerrarEtapa3__(){
    let data = {
      "tipoEvento": "EVENTO_EGRESO",
      "etapaActual": CONST_ETAPA,
      "informacion":{
        "idProceso": this.idProceso,
      }
    };

    this.auxiliar(data);


  }

  mensaje(titulo, mensaje, opcion): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {titulo: titulo, mensaje: mensaje, desicion:true}
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.ngOnInit();
    });
  }
  mensaje2__(titulo, mensaje, opcion): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {titulo: titulo, mensaje: mensaje, desicion:true}
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.cancelar__();
    });
  }

  agregarNota__(){
    this.formulario.addControl("fechaNota"+(this.notas2.length),new UntypedFormControl("",[Validators.required]))
    this.formulario.addControl("descripcionNota"+(this.notas2.length),new UntypedFormControl("",[]));
    this.notas2.push(1);
  }

  suspender__(){
    let data = {
      "tipoEvento": "EVENTO_SUSPENDER",
      "etapaActual": CONST_ETAPA,
      "informacion":{
        "idProceso": this.idProceso,
      }
    };

    this.auxiliar(data);
  }

  activar__(){
    let data = {
      "tipoEvento": "EVENTO_REACTIVAR",
      "etapaActual": CONST_ETAPA,
      "informacion":{
        "idProceso": this.idProceso,
      }
    };

    this.auxiliar(data);

    
  }


  auxiliar(data: any){
    this.isLoading=true;
    this._beneficiariaService.updateEtapa(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading=false;
      this.mensaje2__("Etapa actualizada","La etapa se actualizo correctamente",false);
      
    },()=>{
      this.isLoading=false;
    })
  }


  public feecha: string = '';
  public descripcion_noota: string = '';
  public desa_agregarNota(){
    this.formulario.addControl("fechaNota"+(this.notas2.length),new UntypedFormControl(this.formulario.controls.feecha.value,[Validators.required]))
    this.formulario.addControl("descripcionNota"+(this.notas2.length),new UntypedFormControl(this.formulario.controls.descripcion_noota.value ,[]));
    this.notas2.push(1);

    this.formulario.patchValue({
      feecha: '',
      descripcion_noota: ''
    });
  }

  public desa_eliminarNoota(i: number){
    console.log(i);
    this.notas2.splice(i, 1)
  }


}
