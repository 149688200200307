<h1 mat-dialog-title>
  <mat-icon>help_outline</mat-icon>
</h1>
<div mat-dialog-content>
  <br>
  <h1>{{data.titulo}}</h1>
  <p>{{data.mensaje}}</p>
</div>
<div mat-dialog-actions align="center">
  <button mat-stroked-button (click)="onNoClick()">No</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="data.desicion" cdkFocusInitial>Sí</button>
</div>