//General
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';



@Component({
  selector: 'app-rrhh-perfil-de-puesto',
  templateUrl: './rrhh-perfil-de-puesto.component.html',
  styleUrls: ['./rrhh-perfil-de-puesto.component.scss'],
  providers: [ RrhhService ]
})
export class RrhhPerfilDePuestoComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    numero_descripcion: new UntypedFormControl(-1, []),
    estatus: new UntypedFormControl(-1, []),

    genero: new UntypedFormControl('', [Validators.required]),
    desc_puestos: new UntypedFormControl('', []),
    estado_civil: new UntypedFormControl('', []),
    edad_minima: new UntypedFormControl(0, [Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.required]),
    escolaridad: new UntypedFormControl('', [Validators.required]),
    modalidad: new UntypedFormControl('', [Validators.required]),
    describa_hibrido: new UntypedFormControl('', [Validators.maxLength(150)]),
    experiencia: new UntypedFormControl('', [Validators.maxLength(1000)]),

    tiempo_en: new UntypedFormControl('', [Validators.maxLength(20)]),
    experiencia_deseada: new UntypedFormControl('', [Validators.maxLength(2000)]),
    otros_requisitos: new UntypedFormControl('', [Validators.maxLength(2000)]),
    no_requiere: new UntypedFormControl('', [Validators.maxLength(2000)]),
    conocimientos_deseada: new UntypedFormControl('', [Validators.maxLength(2000)]),


    habilidad_deseada: new UntypedFormControl('', [Validators.maxLength(2000)]),
    actitudes: new UntypedFormControl('', [Validators.maxLength(2000)]),
    maneja_financiero: new UntypedFormControl(false, []),
    especificifique_finan: new UntypedFormControl('', [Validators.maxLength(500)]),
    maneja_confidencial: new UntypedFormControl(false, []),
    especifique_confiden: new UntypedFormControl('', [Validators.maxLength(500)]),
    induccion: new UntypedFormControl('', [Validators.required]),
    supervicion_ejercida: new UntypedFormControl('', [Validators.required]),
    analisis_problemas: new UntypedFormControl('', [Validators.required]),
    frecuencia_desiciones: new UntypedFormControl('', [Validators.required]),
    relacion_interna: new UntypedFormControl('', [Validators.required]),
    supervicion_recibida: new UntypedFormControl('', [Validators.required]),
    toma_desicion: new UntypedFormControl('', [Validators.required]),
    autonomia_desicion: new UntypedFormControl('', [Validators.required]),
    relacion_externa: new UntypedFormControl('', [Validators.required]),

    numero_perfil: new UntypedFormControl(-1, []),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  @Input() selectedIndex : number = 0;
  @Output() siguienteEvento = new EventEmitter<string>();
  public id_dp: number = -1;
  public perfil_puesto: any;
  public descripcion_puesto: any;

  public genero: any;
  public estadoCivil: any;
  public escolaridad: any;
  public modalidad: any;
  public induccion: any;
  public supervicionEjercida: any;
  public supervicionRecibida: any;
  public analisisProblmas: any;
  public tomaDesiciones: any;
  public autonomia: any;
  public desiciones: any;
  public relacionInterna: any;
  public relacionExterna: any;


  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _rrhhService: RrhhService,
    private _router: Router,
    private _route: ActivatedRoute,
    ){ 
    this.getConsultaFormPerfil();
    /*modificar*/
    this._route.queryParams.subscribe(
      params => {
        this.id_dp = +params['id_dp'];
        if(this.id_dp){
          console.log('ID: ' + this.id_dp);
          this.form.patchValue({ desc_puestos: this.id_dp});
          this.getConsultaDescripcion(this.id_dp);
        }
      });
    /*fin modificar*/
    this.form.markAllAsTouched();
  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[3].split("?")[0];
    console.log(this.accion);
  }

  /*default*/
  public getConsultaFormPerfil(){
    this.isLoading = true;
    this._rrhhService.getConsultaFormPerfil().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.genero = response.data.Genero;
          this.estadoCivil = response.data.EstadoCivil;
          this.escolaridad = response.data.Escolaridad;
          this.modalidad = response.data.Modalidad;
          this.induccion = response.data.Induccion;
          this.supervicionEjercida = response.data.SupervicionEjercida;
          this.supervicionRecibida = response.data.SupervicionRecibida;
          this.analisisProblmas =  response.data.AnalisisProblmas;
          this.tomaDesiciones = response.data.TomaDesiciones;
          this.autonomia = response.data.Autonomia;
          this.desiciones = response.data.desiciones;
          this.relacionInterna = response.data.RelacionInterna;
          this.relacionExterna = response.data.RelacionExterna;
        } 
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }
  /*fin default*/

  public guardar(){
    console.log(this.form.value);
    if(this.form.value.maneja_financiero){
      this.form.value.maneja_financiero = 1;
    }else{
      this.form.value.maneja_financiero = 0
    }

    if(this.form.value.maneja_confidencial){
      this.form.value.maneja_confidencial = 1
    }else{
      this.form.value.maneja_confidencial = 0;
    }
    this.postRegistroPerfilPuesto();

  }

  public postModificarEstadoDescripcion(estado: number){
    this.isLoading = true;
    var e = this.form.value.estatus;
    this.form.value.estatus = estado;
    this._rrhhService.postModificarEstadoDescripcion(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postModificarPerfilPuesto();
        }else{
          this.form.value.estatus = e;
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.form.value.estatus = e;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }


  public postRegistroPerfilPuesto(){
    this.isLoading = true;
    this._rrhhService.postRegistroPerfilPuesto(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  /*modificar*/
  public getConsultaDescripcion(id: number){
    this.isLoading = true;
    this._rrhhService.getConsultaDescripcion(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          if(response.data?.perfil_puesto){
            console.log('EXISTE');
            this.descripcion_puesto = response.data;
            this.perfil_puesto = response.data.perfil_puesto;
            this.form.patchValue({ numero_perfil: response.data.perfil_puesto.id_perfil_puesto_pk});
            this.form.patchValue({
              numero_descripcion: response.data.id_desc_puestos_pk,
              estatus: response.data.id_estatus_general_fk
            });
            this.setForm(response.data.perfil_puesto);


          }else{
            console.log('NO EXISTE');
          }
          //this.setForm(response.data);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public postModificarPerfilPuesto(){
    this.isLoading = true;
    this._rrhhService.postModificarPerfilPuesto(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          if(this.form.value.estatus == 7){
            this._router.navigate(['rrhh/gestor-de-puestos-de-trabajo']);
          }else{
            this.getConsultaDescripcion(this.id_dp);
          }


          //this._router.navigate(['rrhh/gestor-de-puestos-de-trabajo/gestor-area-departamento']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public modificar(estado: number){
    console.log(this.form.value);
    if(this.form.value.maneja_financiero){
      this.form.value.maneja_financiero = 1;
    }else{
      this.form.value.maneja_financiero = 0
    }

    if(this.form.value.maneja_confidencial){
      this.form.value.maneja_confidencial = 1
    }else{
      this.form.value.maneja_confidencial = 0;
    }
    this.postModificarEstadoDescripcion(estado);
  }

  public setForm(data: any){
    this.form.patchValue({
      

      genero: data.id_genero_fk,
      estado_civil: data.id_estado_civil_fk,
      edad_minima: data.edad_minima,
      escolaridad: data.id_escolaridad_fk,
      modalidad: data.id_modalidad_fk,
      describa_hibrido: data.describa_hubrido,
      experiencia: data.experiencia,

      tiempo_en: data.tiempo_en,
      experiencia_deseada: data.experiencia_deseada,
      otros_requisitos: data.otros_requisitos,
      no_requiere: data.no_requiere,
      conocimientos_deseada: data.conocimientos_deseada,


      habilidad_deseada: data.habilidad_deseada,
      actitudes: data.actitudes,
      maneja_financiero: data.maneja_financiero,
      especificifique_finan: data.especificifique_finan,
      maneja_confidencial: data.maneja_confidencial,
      especifique_confiden: data.especifique_confiden,
      induccion: data.id_induccion_fk,
      supervicion_ejercida: data.id_supervicion_ejercida_fk,
      analisis_problemas: data.id_analisis_problemas_fk,
      frecuencia_desiciones: data.id_frecuencia_desiciones_fk,
      relacion_interna: data.id_relacion_interna_fk,
      supervicion_recibida: data.id_supervicion_recibida_fk,
      toma_desicion: data.id_toma_desicion_fk,
      autonomia_desicion: data.id_autonomia_desicion_fk,
      relacion_externa: data.id_relacion_externa_fk,
    });
  }
  /*fin modificar*/

  public siguiente() {
    this.selectedIndex = this.selectedIndex  - 1;
    console.log(this.selectedIndex);
    this.siguienteEvento.emit(this.selectedIndex.toString());
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

  }

}
