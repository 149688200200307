import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ev-med-gen',
  templateUrl: './ev-med-gen.component.html',
  styleUrls: ['./ev-med-gen.component.scss']
})
export class EvMedGenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
