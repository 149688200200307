import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ParametrosService } from 'src/app/services/parametros.service';

@Component({
  selector: 'app-egreso2',
  templateUrl: './egreso.component.html',
  styleUrls: ['./egreso.component.scss']
})
export class EgresoComponent2 implements OnInit {
  hostapi:string;
  autonomiasEmocionales = [];
  autonomiasMotrices = [];
  autonomiasConitivas = [];
  motivosEgreso = [];
  lugaresDestino = [];
  notas = [];
  notas2 = [];
  formulario: UntypedFormGroup;
  idProceso:number;
  idBeneficiaria:number;
  isLoading = false;
  indEstatus:any;
  conclusiones = "";
  constructor(
    private _documentosService:DocumentosService,
    private _parametrosService:ParametrosService,
    private dialog:MatDialog,
    private _location:Location,
    private activatedRoute:ActivatedRoute,
    private _beneficiariaService:BeneficiariaService,
    private _conf:ConfigurationService
    ) {
      this.hostapi=_conf.getConfiguration().hostapi;
      this.getCatalogos();
    this.formulario = new UntypedFormGroup({
      fechaApertura : new UntypedFormControl("",[Validators.required]),
      idMotivoEgreso: new UntypedFormControl("",[Validators.required]),
      desMotivoEgreso: new UntypedFormControl("",[]),

      autonomiaCognitivoSelect:new UntypedFormControl("",[Validators.required]),
      autonomiaCognitivoInput:new UntypedFormControl("",[]),
      autonomiaMotrizSelect:new UntypedFormControl("",[Validators.required]),
      autonomiaMotrizInput:new UntypedFormControl("",[]),
      diagnosticoEmocionalSelect:new UntypedFormControl("",[Validators.required]),
      diagnosticoEmocionalInput:new UntypedFormControl("",[]),

      desLogrosAlcanzados:new UntypedFormControl("",[]),
      idLugarDestino: new UntypedFormControl("",[Validators.required]),
      desLugarDestino:new UntypedFormControl("",[]),

      solicitudEgresoRuta:new UntypedFormControl("",[]),
      solicitudEgresoArchivo:new UntypedFormControl("",[]),
      fotoEgresoURI:new UntypedFormControl("",[]),
      fotoEgresoArchivo:new UntypedFormControl("",[]),

    })
  }
  setData(result){
    this.indEstatus = result.data.indEstatus;
    if(result.data.fechaApertura){
      this.formulario.controls.fechaApertura.setValue(new Date(result.data.fechaApertura.date));
    }
    if(result.data.idMotivoEgreso!="0"){
      this.formulario.controls.idMotivoEgreso.setValue(result.data.idMotivoEgreso);
    }
    this.formulario.controls.desMotivoEgreso.setValue(result.data.desMotivoEgreso);
    if(result.data.iGradoCognitivo!="0"){
      this.formulario.controls.autonomiaCognitivoSelect.setValue(result.data.iGradoCognitivo);
    }
    this.formulario.controls.autonomiaCognitivoInput.setValue(result.data.desGradoCognitivo);
    if(result.data.idGradoMotriz!="0"){
      this.formulario.controls.autonomiaMotrizSelect.setValue(result.data.idGradoMotriz);
    }
    this.formulario.controls.autonomiaMotrizInput.setValue(result.data.desGradoMotriz);
    if(result.data.idDiagnosticoEmocional!="0"){
      this.formulario.controls.diagnosticoEmocionalSelect.setValue(result.data.idDiagnosticoEmocional);
    }
    this.formulario.controls.diagnosticoEmocionalInput.setValue(result.data.desDiagnosticoEmocional);
    this.formulario.controls.desLogrosAlcanzados.setValue(result.data.desLogrosAlcanzados);
    if(result.data.idLugarDestino!="0"){
      this.formulario.controls.idLugarDestino.setValue(result.data.idLugarDestino);
    }
    this.formulario.controls.desLugarDestino.setValue(result.data.desLugarDestino);
    
  }
  ngOnInit(): void {
    this.notas = [];
    this.notas2 = [];
    this.activatedRoute.queryParams.subscribe(params=>{
      this.idProceso = params["idProceso"];
      this.idBeneficiaria = params["idBeneficiaria"];
      this.isLoading=true;
      this.getDocumentosById(this.idBeneficiaria).then(()=>{
        this._beneficiariaService.getProcesoEgreso(this.idProceso).subscribe(result=>{
          this.isLoading=false;
          this.setData(result);
        },()=>{
          this.isLoading=false;
        });
      });
    });
    
  }

  
  cancelar(){
    this._location.back();
  }
  sendForm(){
    console.info(this.formulario.controls);
    if(this.formulario.valid){
      this.guardar(0);
    }else{
      console.info("invalid");
    }
  }

  guardar(indicador){
    let data = {
      "tipoEvento": "EVENTO_GUARDAR",
      "etapaActual":"EGRESO",
      "informacion":{
        "idProceso": this.idProceso,
        "fechaApertura": this.formulario.controls.fechaApertura.value.toJSON().replace("T"," ").replace(".000Z",""),
        "idMotivoEgreso": this.formulario.controls.idMotivoEgreso.value,
        "desMotivoEgreso": this.formulario.controls.desMotivoEgreso.value,
        "iGradoCognitivo": this.formulario.controls.autonomiaCognitivoSelect.value,
        "desGradoCognitivo": this.formulario.controls.autonomiaCognitivoInput.value,
        "idGradoMotriz": this.formulario.controls.autonomiaMotrizSelect.value,
        "desGradoMotriz": this.formulario.controls.autonomiaMotrizInput.value,
        "idDiagnosticoEmocional": this.formulario.controls.diagnosticoEmocionalSelect.value,
        "desDiagnosticoEmocional": this.formulario.controls.diagnosticoEmocionalInput.value,
        "desLogrosAlcanzados": this.formulario.controls.desLogrosAlcanzados.value,
        "idLugarDestino": this.formulario.controls.idLugarDestino.value,
        "desLugarDestino": this.formulario.controls.desLugarDestino.value
	    }
    };

    
    console.info(data);
    this.isLoading=true;
    this._beneficiariaService.updateEtapa(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading=false;
      this.cargarListaDoc().then(()=>{
        if(indicador==2){
          this.cerrarEtapa2();
        }else{
          this.mensaje2("Etapa actualizada","La etapa se actualizo correctamente",true);
        }
      });
    },()=>{
      this.isLoading=false;
    })
  }
  cerrarEtapa(){
    console.info(this.formulario.controls);
    if(this.formulario.valid){
      this.guardar(2);
    }else{
      console.info("invalid");
    }
  }
  cerrarEtapa2(){
    let data = {
      "tipoEvento": "EVENTO_CERRAR",
      "etapaActual":"EGRESO",
      "informacion":{
        "idProceso": this.idProceso,
      }
    };

    this.isLoading=true;
    this._beneficiariaService.updateEtapa(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading=false;
      this.mensaje2("Etapa actualizada","La etapa se actualizo correctamente",false);
    },()=>{
      this.isLoading=false;
    })


  }

  mensaje(titulo, mensaje, opcion): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {titulo: titulo, mensaje: mensaje, desicion:true}
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.ngOnInit();
    });
  }
  mensaje2(titulo, mensaje, opcion): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {titulo: titulo, mensaje: mensaje, desicion:true}
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.cancelar();
    });
  }

  agregarNota(){
    this.formulario.addControl("fechaNota"+(this.notas2.length),new UntypedFormControl("",[Validators.required]))
    this.formulario.addControl("descripcionNota"+(this.notas2.length),new UntypedFormControl("",[]));
    this.notas2.push(1);
  }

  suspender(){
    let data = {
      "tipoEvento": "EVENTO_SUSPENDER",
      "etapaActual":"EGRESO",
      "informacion":{
        "idProceso": this.idProceso,
      }
    };

    this.isLoading=true;
    this._beneficiariaService.updateEtapa(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading=false;
      this.mensaje2("Etapa actualizada","La etapa se actualizo correctamente",false);
    },()=>{
      this.isLoading=false;
    })
  }

  public ordenarEgreso(array: any, propiedad: any) {
    array.sort(function(a, b) {
      return a[propiedad].localeCompare(b[propiedad], 'es', {sensitivity: 'base'});
    });
    return array;
  }


  getCatalogos(){
    this._parametrosService.getParametros("16").subscribe(result=>{
      this.autonomiasConitivas = result.data.parametros;
    });

    this._parametrosService.getParametros("17").subscribe(result=>{
      this.autonomiasMotrices = result.data.parametros;
    });

    this._parametrosService.getParametros("18").subscribe(result=>{
      this.autonomiasEmocionales = result.data.parametros;
    });

    this._parametrosService.getParametros("19").subscribe(result=>{
      this.motivosEgreso = result.data.parametros;
    });

    this._parametrosService.getParametros("20").subscribe(result=>{
      console.log(result);
      this.lugaresDestino = result.data.parametros;
      this.lugaresDestino = this.ordenarEgreso(this.lugaresDestino, 'valor');
    });
  }
  documento1 = null;
  documento2 = null;
  handleFileInput(files){
    this.fileToUpload1 = files.item(0);
  }
  handleFileInput1(files){
    this.fileToUpload2 = files.item(0);
  }
  
  fileToUpload1:File = null;
  fileToUpload2:File = null;
  arreglo = [];
  cargarArreglo(tipoDocumento:string,idTipoCatalogo:string,desTipoCatalogo:string,file:File){
    this.arreglo.push({"file":file,"idBeneficiaria":this.idBeneficiaria.toString(),"idTipoCatalogo":idTipoCatalogo,"desTipoCatalogo":desTipoCatalogo,"tipoDocumento":tipoDocumento});
  }
  documentos = [];
  getDocumentosById(idBeneficiaria:number){
    return new Promise((resolve, reject) => {
      this._documentosService.getAllByBeneficiaria(idBeneficiaria).subscribe(result=>{
        this.documentos = result.data.documentos;
        for(let i = 0; i< this.documentos.length; i++){
          if(this.documentos[i].idTipoDocumento == 48){
            //this.formulario.controls.diagnosticoNeurologico.setValue(this.documentos[i].idTipoCatalogo);
            //this.formulario.controls.dnDescripcion.setValue(this.documentos[i].desTipoCatalogo);
            //this.formulario.controls.dnRuta.setValue(this.documentos[i].docTipoCatalogo);
            this.documento1 = this.documentos[i].docTipoCatalogo;
          }else if(this.documentos[i].idTipoDocumento == 96){
            this.documento2 = this.documentos[i].docTipoCatalogo;
          }
        }
        resolve(true);
      },()=>{
        this.documentos = [];
        resolve(true);
      })
    });
  }
  cargarListaDoc(){
    return new Promise((resolve, reject) => {

      this.cargarArreglo("48","","",this.fileToUpload2);
      this.cargarArreglo("96","","",this.fileToUpload1);
      //this.cargarArreglo("58","",this.formulario.controls.edadMental.value,this.fileToUpload2);
      
      let formData = new FormData();
      formData.append('contador', this.arreglo.length.toString());
      for(let i=0; i<this.arreglo.length; i++){
        if(i==0){
          formData.append('documento', this.arreglo[i].file);
          formData.append('idBeneficiaria', this.idBeneficiaria.toString());
          formData.append('idTipoDocumento', this.arreglo[i].tipoDocumento);
          formData.append('idTipoCatalogo', this.arreglo[i].idTipoCatalogo);
          formData.append('desTipoCatalogo', this.arreglo[i].desTipoCatalogo);
        }else{
          formData.append('documento'+i, this.arreglo[i].file);
          formData.append('idBeneficiaria'+i, this.idBeneficiaria.toString());
          formData.append('idTipoDocumento'+i, this.arreglo[i].tipoDocumento);
          formData.append('idTipoCatalogo'+i, this.arreglo[i].idTipoCatalogo);
          formData.append('desTipoCatalogo'+i, this.arreglo[i].desTipoCatalogo);
        }
      }

      this.isLoading=true;
      this._documentosService.saveMulti(formData).subscribe(result=>{
        console.info(result);
        this.isLoading=false;
        resolve(true);
      },()=>{
        this.isLoading=false;
        resolve(true);
      })
    });
  }
}