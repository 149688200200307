import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class EventosDeVidaService {
  phpPath:string;
  endpoint = "";
  headers = new HttpHeaders();
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService) {
      this.phpPath = _config.getConfiguration().embedPhpPath;
      this.endpoint = _config.getConfiguration().hostapi + this._config.getConfiguration().endpoints["eventos"].path;
  }

  findAll(buscador:string,page:number,size:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"?page="+page+"&size="+size+"&nombre="+buscador);
  }
  fidByIdDetail(id){
    return this._http.get<ResponseGenerico>(this.phpPath+"/getEventoById.php?id="+id);
  }
  findById(id){
    return this._http.get<ResponseGenerico>(this.endpoint+"/"+id);
  }

  findAllByBeneficiaria(beneficiaria:number,page:number,size:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/beneficiaria?page="+page+"&size="+size+"&beneficiaria="+beneficiaria);
  }
  delete(id:number){
    return this._http.delete<ResponseGenerico>(this.endpoint+"/"+id);
  }

  save(data,@Optional() id:number):Observable<ResponseGenerico>{
    this.headers.set('Content-Type', 'multipart/form-data');
    if(id==0){
      return this._http.post<ResponseGenerico>(this.endpoint,data,{
        headers: this.headers
      });
    }else{
      return this._http.post<ResponseGenerico>(this.endpoint+"/"+id,data,{
        headers: this.headers
      });
    }
  }
}
