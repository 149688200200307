//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { CatalogoInventarioService } from 'src/app/services/catalogo-inventario.service';

@Component({
  selector: 'app-ac-catalogo-inventario',
  templateUrl: './ac-catalogo-inventario.component.html',
  styleUrls: ['./ac-catalogo-inventario.component.scss'],
  providers: [ CatalogoInventarioService ]
})
export class AcCatalogoInventarioComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({

    numero_presentacion: new UntypedFormControl(-1, []),
    presentacion: new UntypedFormControl('', []),

    numero_anexo_uno: new UntypedFormControl(-1, []),
    anexo_uno: new UntypedFormControl('', []),

    numero_anexo_dos: new UntypedFormControl(-1, []),
    anexo_dos: new UntypedFormControl('', []),

    numero_anexo_tres: new UntypedFormControl(-1, []),
    anexo_tres: new UntypedFormControl('', []),



    nombre_todo: new UntypedFormControl('', [Validators.maxLength(100), Validators.required]),


  });
  //All
  public isLoading: boolean = false;
  public id: number = -1;
  public opcion: number = -1;
  public file: any;
  public ruta: string = '';
  public accion: string = '';

  public clasificacion: any;
  public laravelapistorage: any;
  public opcion_texto: string = '';

  constructor(
    public  _dialog: MatDialog,
    private _accionCI: CatalogoInventarioService,
    private _router: Router,
    private _route: ActivatedRoute,  
    private fb: UntypedFormBuilder,
  ){ 
    this.laravelapistorage = this._accionCI.getLaravelApiStorage();
    this.form.markAllAsTouched();
    /*modificar*/
    this._route.queryParams.subscribe(
      params => {
        this.opcion = +params['opcion'];
        if(this.opcion == 1){
          this.opcion_texto = 'Presentaciones';
        }else if(this.opcion == 2){
          this.opcion_texto = 'Concentración';
        }else if(this.opcion == 3){
          this.opcion_texto = 'Nombres genéricos';
        }else if(this.opcion == 4){
          this.opcion_texto = 'Nombre comercial';
        }

        this.id = +params['id'];
        if(this.opcion && this.id){
          console.log('ID: ' + this.id, this.opcion);
          

          if(this.opcion == 1){
            this.form.patchValue({ numero_presentacion: this.id});
            this.getConsultarPresentacion(this.id);
          }else if(this.opcion == 2){
            this.form.patchValue({ numero_anexo_uno: this.id});
            this.getConsultarAnexoUno(this.id);
          }else if(this.opcion == 3){
            this.form.patchValue({ numero_anexo_dos: this.id});
            this.getConsultarAnexoDos(this.id);
          }else if(this.opcion == 4){
            this.form.patchValue({ numero_anexo_tres: this.id});
            this.getConsultarAnexoTres(this.id);
          }



          //this.getConsultaMedicamentoInv(this.id_med);
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[2].split("?")[0];
    console.log(this.accion);
  }

  /*default*/
  public getConsultarPresentacion(id: number){
    this.isLoading = true;
    this._accionCI.getConsultarPresentacion(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({ presentacion: response.data.presentacion });
          this.form.patchValue({ nombre_todo: response.data.presentacion });
        } 
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getConsultarAnexoUno(id: number){
    this.isLoading = true;
    this._accionCI.getConsultarAnexoUno(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({ anexo_uno: response.data.anexo_uno });
          this.form.patchValue({ nombre_todo: response.data.anexo_uno });
        } 
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getConsultarAnexoDos(id: number){
    this.isLoading = true;
    this._accionCI.getConsultarAnexoDos(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({ anexo_dos: response.data.anexo_dos });
          this.form.patchValue({ nombre_todo: response.data.anexo_dos });
        } 
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getConsultarAnexoTres(id: number){
    this.isLoading = true;
    this._accionCI.getConsultarAnexoTres(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({ anexo_tres: response.data.anexo_tres });
          this.form.patchValue({ nombre_todo: response.data.anexo_tres });
        } 
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }
  /*fin default*/

 

  


  public postRegistrarPresentacion(){
    console.log(this.form.value);
    this.isLoading = true;
    this._accionCI.postRegistrarPresentacion(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        this.general_res(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postRegistrarAnexoUno(){
    console.log(this.form.value);
    this.isLoading = true;
    this._accionCI.postRegistrarAnexoUno(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        this.general_res(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postRegistrarAnexoDos(){
    console.log(this.form.value);
    this.isLoading = true;
    this._accionCI.postRegistrarAnexoDos(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        this.general_res(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postRegistrarAnexoTres(){
    console.log(this.form.value);
    this.isLoading = true;
    this._accionCI.postRegistrarAnexoTres(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        this.general_res(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postModificarAnexoUno(){
    console.log(this.form.value);
    this.isLoading = true;
    this._accionCI.postModificarAnexoUno(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        this.general_res(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postModificarAnexoDos(){
    console.log(this.form.value);
    this.isLoading = true;
    this._accionCI.postModificarAnexoDos(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        this.general_res(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postModificarAnexoTres(){
    console.log(this.form.value);
    this.isLoading = true;
    this._accionCI.postModificarAnexoTres(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        this.general_res(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }



  public postModificarPresentacion(){
    console.log(this.form.value);
    this.isLoading = true;
    this._accionCI.postModificarPresentacion(this.form.value).subscribe(
      response => {console.log(response);
        this.general_res(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public general_res(response: any){
    this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this._router.navigate(['catalogo-inventario']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
  }


  /*numero_presentacion: new UntypedFormControl(-1, []),
    presentacion: new UntypedFormControl('', []),

    numero_anexo_uno: new UntypedFormControl(-1, []),
    anexo_uno: new UntypedFormControl('', []),

    numero_numero_anexo_dos: new UntypedFormControl(-1, []),
    numero_anexo_dos: new UntypedFormControl('', []),

    numero_numero_anexo_tres: new UntypedFormControl(-1, []),
    numero_anexo_tres: new UntypedFormControl('', []),*/
 

  public save(){
    if(this.opcion == 1){
      this.form.patchValue({ presentacion: this.form.value.nombre_todo });
      this.postRegistrarPresentacion();
    }else if(this.opcion == 2){
      this.form.patchValue({ anexo_uno: this.form.value.nombre_todo });
      this.postRegistrarAnexoUno();
    }else if(this.opcion == 3){
      this.form.patchValue({ anexo_dos: this.form.value.nombre_todo });
      this.postRegistrarAnexoDos();
    }else if(this.opcion == 4){
      this.form.patchValue({ anexo_tres: this.form.value.nombre_todo });
      this.postRegistrarAnexoTres();
    }
  }

  public modify(){
    if(this.opcion == 1){
      this.form.patchValue({ presentacion: this.form.value.nombre_todo });
      this.postModificarPresentacion();
    }else if(this.opcion == 2){
      this.form.patchValue({ anexo_uno: this.form.value.nombre_todo });
      this.postModificarAnexoUno();
    }else if(this.opcion == 3){
      this.form.patchValue({ anexo_dos: this.form.value.nombre_todo });
      this.postModificarAnexoDos();
    }else if(this.opcion == 4){
      this.form.patchValue({ anexo_tres: this.form.value.nombre_todo });
      this.postModificarAnexoTres();
    }
  }
  

 

}
