//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { BitacoraService } from 'src/app/services/bitacora.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//Fin Tabla
import { SesionService } from 'src/app/services/sesion.service';


@Component({
  selector: 'app-gt-bita',
  templateUrl: './gt-bita.component.html',
  styleUrls: ['./gt-bita.component.scss'],
  providers: [ BitacoraService ]
})
export class GtBitaComponent implements OnInit {

  /*tabla*/
  public length: number;
  public pageSize: number;
  public pageSizeOptions: Array<number>;
  public page: number;
  public displayedColumns: any;
  public dataSource: any;
  /*fin tabla*/
  public isLoading: boolean = false;
  public form: UntypedFormGroup = this.fb.group({
    buscador: new UntypedFormControl('', []),
    numero_periodo: new UntypedFormControl(1, []),
  });
  public laravelapiStorage = this._BitacoraService.getLaravelApiStorage();
  public laravelapi = this._BitacoraService.getLaravelApi();
  public tieneAccionEditar = false;
  public acciones:any=[];
  public periodos: any;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _BitacoraService: BitacoraService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _sesion:SesionService,
  ){
     /*tabla*/
     this.length = 100;
     this.pageSize = 25;
     this.pageSizeOptions = [5, 10, 25, 100];
     this.page = 1;
     this.displayedColumns = [
     'id', 
     'fecha',
     'estado',
     'incidente',
     'bitacora',
     'consultar'];
     /*fin tabla*/
 
     /*modificar*/
     this.getConsultaPeriodosBitacora();
     /*fin modificar*/
     this.tieneAccionEditar = this.permiso("/medico/expedientes-medicos-generales/gt-terapias-companas/modificar");
  }

  ngOnInit(): void {
    
  }

  public permiso(urlRecibida: string){
    if(this._sesion.getSession().getUsuario().nombre!=undefined){
      this.acciones = this._sesion.getSession().getAcciones();
      if(this.acciones.filter(x => x.url == urlRecibida)[0]){
        return true;        
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  public getConsultaPeriodosBitacora(){
    this.isLoading = true;
    this._BitacoraService.getConsultaPeriodosBitacora(1000).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({numero_periodo: response?.data?.data[0]?.id_periodo_bitacora_pk})
          this.periodos = response?.data?.data;
          this.getConsultaBitacoras(1);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getConsultaBitacoras(page: number){
    this.isLoading = true;
    this.page = page;
    this._BitacoraService.getConsultaBitacoras(this.page, this.pageSize, this.form.value.numero_periodo).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.pageSize = event.pageSize;
    this.getConsultaBitacoras(event.pageIndex + 1);
  }

  public postNuevaBitacora(id: number){
    this.isLoading = true;
    this._BitacoraService.getNuevaBitacora(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaBitacoras(this.page);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

}
