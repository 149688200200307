<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Directorio médico</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
        
    
        <form [formGroup]="form" (ngSubmit)="getConsultaDirectoriosMedicos(1)" autocomplete="off">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>Buscador</mat-label>
                        <input matInput formControlName="buscador">
                    </mat-form-field>

                </div>
                <div class="col">
                    <button mat-mini-fab type="submit">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="col">
                    <a class="flat" color="accent" mat-flat-button href="{{laravelapi}}/ConsultaDirMedicoExcel" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon> Reporte de excel
                    </a>
                </div>
                <div class="col mt-1">
                   <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/gestor-contactos-area-medica/registrar']" >
                    <mat-icon>add</mat-icon> Nuevo contacto
                </button>
            </div>
        </div>
    </form>
    



    <!-- tabla -->
    <div class="row mt-3 mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <caption><!--Parrilla--></caption>
            <!-- contenido -->

            <ng-container matColumnDef="gcam_clasificacion">
                <th id="gcam_clasificacion" mat-header-cell *matHeaderCellDef> CLASIFICACIÓN </th>
                <td mat-cell *matCellDef="let element">{{element?.clasificacion?.clasificacion}}</td>
            </ng-container>

            <ng-container matColumnDef="gcam_especialidad">
                <th id="gcam_especialidad" mat-header-cell *matHeaderCellDef> ESPECIALIDAD (SOLO MÉDICOS) </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.clasificacion?.id_clasificacion_pk == 1">
                        {{element?.especialidad?.especialidad}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="gcam_nombre_razon_social">
                <th id="gcam_nombre_razon_social" mat-header-cell *matHeaderCellDef> NOMBRE / RAZÓN SOCIAL </th>
                <td mat-cell *matCellDef="let element">{{element?.razon_social}}</td>
            </ng-container>

            <ng-container matColumnDef="gcam_calificacion">
                <th id="gcam_calificacion" mat-header-cell *matHeaderCellDef> CALIFICACIÓN </th>
                <td mat-cell *matCellDef="let element">{{element?.calificacion?.calificacion}}</td>
            </ng-container>

            <ng-container matColumnDef="gcam_consultar">
                <th id="gcam_consultar" mat-header-cell *matHeaderCellDef> CONSULTAR </th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" mat-mini-fab color="primary" *ngIf="consultar;else elseConsultar">
                        <mat-icon class="material-icons" [routerLink]="['/medico/gestor-contactos-area-medica/consultar']" [queryParams]="{id_cam:element.id_area_medica_pk}">
                            open_in_new
                        </mat-icon>
                    </button>
                    <ng-template #elseConsultar>
                        <button type="button" mat-mini-fab color="primary" disabled>
                            <mat-icon class="material-icons">
                                open_in_new
                            </mat-icon>
                        </button>
                    </ng-template>
                    
                </td>
            </ng-container>

            <ng-container matColumnDef="gcam_modificar">
                <th id="gcam_modificar" mat-header-cell *matHeaderCellDef> MODIFICAR </th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" mat-mini-fab color="primary" *ngIf="modificar; else elseModificar">
                        <mat-icon class="material-icons" [routerLink]="['/medico/gestor-contactos-area-medica/modificar']" [queryParams]="{id_cam:element.id_area_medica_pk}">
                            open_in_new
                        </mat-icon>
                    </button>
                    <ng-template #elseModificar>
                        <button type="button" mat-mini-fab color="primary" disabled>
                            <mat-icon class="material-icons">
                                open_in_new
                            </mat-icon>
                        </button>
                    </ng-template>
                    
                </td>
            </ng-container>

            <ng-container matColumnDef="gcam_eliminar">
                <th id="gcam_eliminar" mat-header-cell *matHeaderCellDef> ELIMINAR </th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" mat-mini-fab color="warn" (click)="getEliminarDir(element.id_area_medica_pk)" *ngIf="eliminar; else elseEliminar">
                        <mat-icon class="material-icons">
                            delete
                        </mat-icon>
                    </button>
                    <ng-template #elseEliminar>
                        <button type="button" mat-mini-fab color="warn" disabled>
                            <mat-icon class="material-icons">
                                delete
                            </mat-icon>
                        </button>
                    </ng-template>
                    
                </td>
            </ng-container>

            <!-- fin contenido -->
            <tr mat-header-row *matHeaderRowDef="gcam_displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: gcam_displayedColumns;"></tr>
        </table>
    </div>
    <!-- fin tabla -->


</mat-card-content>
<mat-card-actions align="start">
    <mat-paginator (page)="pageEvent($event)" [length]="gcam_length" [pageSize]="gcam_pageSize" [pageSizeOptions]="gcam_pageSizeOptions"  aria-label="Selecione una pagina">
    </mat-paginator>
</mat-card-actions>
</mat-card>
</div>