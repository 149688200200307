import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuracion, ExternalConfig } from '../model/configuracion';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private configuration:Configuracion;
  constructor(private _http:HttpClient) { }

  load(){
    return new Promise((resolve, reject) => {
      //this._http.get<ExternalConfig>("assets/json-test/external-config.json").subscribe(ext=>{
        this._http.get<Configuracion>("assets/config.json").subscribe(result=>{
          this.configuration = result;
          resolve(true);
        },error=>{
          this.configuration = null;
          reject(true);
        });
      //},error=>{
        //this.configuration = null;
        //reject(true);
      //});
    });
  }
  

  getConfiguration():Configuracion{
    return this.configuration;
  }
}
