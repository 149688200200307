<div class="container-fluid vh-100">
    <div class="row h-100">
        <div class="col">

        </div>
        <div class="col">

<div class="container ">
    <mat-grid-list cols="2" rowHeight="2:1">
        <mat-grid-tile *ngFor="let module of modules" (click)="go2module(module.href)">
            <img src="/assets/img/{{module.icon}}" width="auto" alt="img-module"/>
            <mat-grid-tile-footer>
                
                <!-- submenus -->
                <mat-card-actions align="center" *ngIf="module.nombre == 'Recursos humanos'">
                    <ng-container >
                        <span  [matMenuTriggerFor]="menu">{{module.nombre}}</span>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/gestor-organigrama-docs']">
                                Biblioteca RRHH
                            </button>
                            <button mat-menu-item [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo']">
                                Gestor de puestos
                            </button>
                            <button mat-menu-item [routerLink]="['/rrhh/gestor-de-expedientes-de-personal']">
                                Expediente de personal
                            </button>
                        </mat-menu>
                    </ng-container>
                </mat-card-actions>

                <mat-card-actions align="center" *ngIf="module.nombre == 'Médico'">
                    
                    
                    <ng-container >
                        <span  [matMenuTriggerFor]="menu">{{module.nombre}}</span>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item [routerLink]="['/medico/biblioteca-general']">
                                Biblioteca Médico
                            </button>
                            <button mat-menu-item [routerLink]="['/medico/gestor-contactos-area-medica']">
                                Directorio médico
                            </button>
                            <button mat-menu-item [matMenuTriggerFor]="almacenMedicamentos">
                                Almacen de medicamentos
                            </button>
                            <button mat-menu-item [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos']">
                                Expedientes médicos
                            </button>
                            <button mat-menu-item [matMenuTriggerFor]="expMedGen">
                                Expedientes médicos generales
                            </button>
                            <button mat-menu-item [routerLink]="['/medico/gt-bitacora']">
                                Bitacora de tratamientos y medicamentos
                            </button>
                        </mat-menu>

                        <!-- submenu -->
                        <mat-menu #almacenMedicamentos="matMenu">
                            <button mat-menu-item [routerLink]="['/catalogo-inventario']">
                                Catalogo Inventario
                            </button>
                            <button mat-menu-item [routerLink]="['/medico/almacen-de-medicamentos/gestor-inventario']">
                                Inventario
                            </button>
                            <button mat-menu-item [routerLink]="['/medico/almacen-de-medicamentos/gestor-incremento-inventario']">
                                Incremento inventario
                            </button>
                            <button mat-menu-item [routerLink]="['/medico/almacen-de-medicamentos/gestor-salida-inventario']">
                                Salida de inventario
                            </button>
                        </mat-menu>
                        <!-- fin menu de submenu -->

                        <!-- submenu -->
                        <mat-menu #expMedGen="matMenu">
                            <button mat-menu-item [routerLink]="['/medico/expedientes-medicos-generales/gt-expedientes-medicos-generales']">
                                Control grupal de peso y talla
                            </button>
                            <button mat-menu-item [routerLink]="['/medico/expedientes-medicos-generales/gt-terapias-companas']">
                                Terapias / Campañas
                            </button>
                            <button mat-menu-item >
                                Calendario médico
                            </button>
                        </mat-menu>
                        <!-- fin menu de submenu -->



                        
                    </ng-container>
                </mat-card-actions>

                <mat-card-actions align="center" *ngIf="module.nombre == 'Calendario'">
                    <ng-container >
                        <span  [matMenuTriggerFor]="menu">{{module.nombre}}</span>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item [routerLink]="['/calendario']" [queryParams]="{tipo: '1'}">
                                Calendario Escolarizada
                            </button>
                            <button mat-menu-item [routerLink]="['/calendario']" [queryParams]="{tipo: '2'}">
                                Calendario Sin Escolarizar
                            </button>
                        </mat-menu>
                    </ng-container>
                </mat-card-actions>

                
                <div *ngIf="module.nombre != 'Recursos humanos' && module.nombre != 'Médico' && module.nombre != 'Calendario'">
                    {{module.nombre}}
                </div>
                
                <!--fin submenus -->

               

            </mat-grid-tile-footer>

            
        </mat-grid-tile>
        
    </mat-grid-list>
</div>
        </div>




        <!--<div class="col align-self-center ">
            
           
            <div class="text-center">

                <button mat-button color="primary" [matMenuTriggerFor]="menu">Calendario</button>
                <mat-menu #menu="matMenu">
                <button mat-menu-item  [routerLink]="['/calendario']" [queryParams]="{tipo: '1'}">Calendario Escolarizada</button>
                <button mat-menu-item  [routerLink]="['/calendario']" [queryParams]="{tipo: '2'}">Calendario Sin Escolarizar</button>
                </mat-menu>


                
            </div>

                

               
          
        </div>-->

        
    </div>
</div>

