import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { ContactosService } from 'src/app/services/contactos.service';
import { DocumentosService } from 'src/app/services/documentos.service';

@Component({
  selector: 'app-alta-contacto',
  templateUrl: './alta-contacto.component.html',
  styleUrls: ['./alta-contacto.component.scss']
})
export class AltaContactoComponent implements OnInit {
  tipoContactos = [{id:"1",nombre:"Autoridad"},{id:"2",nombre:"Patronato"},{id:"3",nombre:"Estratégico"}]
  formulario:UntypedFormGroup=new UntypedFormGroup({
    
    tipoContacto:new UntypedFormControl("",[Validators.required]),
    nombre:new UntypedFormControl("",[Validators.required]),
    organizacion:new UntypedFormControl("",[Validators.required]),
    cargo:new UntypedFormControl("",[Validators.required]),    
    direccion:new UntypedFormControl("",[]),
    telefono:new UntypedFormControl("",[]),
    extencion:new UntypedFormControl("",[]),
    correo:new UntypedFormControl("",[Validators.email]),
    celular:new UntypedFormControl("",[]),
    observaciones:new UntypedFormControl("",[])
  });
  isLoading=false;
  tipo_documentos:any=[];
  fileToUpload:any;
  id=null;
  categorias:any=[];
  subcategorias:any=[];
  tipoDocumentos:any=[];
  documento;

  constructor(private dialog:MatDialog,private contactos_service:ContactosService,private router:Router,private activatedRoute:ActivatedRoute) { 
   this.activatedRoute.queryParams.subscribe(params=>{
    if(params['id']){
      this.id=params['id'];
      this.contactos_service.buscaContactos("",0,0,params['id']).subscribe(res=>{
        this.documento = res.data.documentos[0];
        this.formulario.controls["nombre"].setValue(this.documento.nombre);
        this.formulario.controls["organizacion"].setValue(this.documento.organizacion);
        this.formulario.controls["cargo"].setValue(this.documento.cargo);
        this.formulario.controls["direccion"].setValue(this.documento.direccion);
        this.formulario.controls["telefono"].setValue(this.documento.telefono);
        this.formulario.controls["extencion"].setValue(this.documento.extencion);
        this.formulario.controls["correo"].setValue(this.documento.correo);
        this.formulario.controls["celular"].setValue(this.documento.celular);
        this.formulario.controls["observaciones"].setValue(this.documento.observaciones);
        this.formulario.controls["tipoContacto"].setValue(this.documento.tipo_contacto);
        
      })
    }
  });
 }

 ac_sendForm(){
  if(this.formulario.valid){
    this.isLoading=true;
    this.contactos_service.altaContacto(this.formulario.getRawValue(),this.id).subscribe(res=>{
      this.isLoading=false;
      if(res.code==200){
        const dialogRef= this.dialog.open(MessageBoxComponent,{
          data:{titulo: "Contacto guardado" ,mensaje: "El contacto se ha guardado correctamente."}
        });
        dialogRef.afterClosed().subscribe(()=>{
          this.router.navigate(["/centro-informacion-institucional/directorio"]);
        });
      }else{
        this.dialog.open(MessageBoxComponent,{
          data:{titulo: "Error" ,mensaje: "El contacto no se pudo guardar, por favor intentalo más tarde."}
        })
      }
    },()=>{
      this.isLoading=false;
    });
  }
}

ngOnInit(): void {
  
}

}
