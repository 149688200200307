<div class="container-fluid scroll-menu" id="top">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{titulo}}</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-tab-group mat-align-tabs="start" >
                <mat-tab label="Formulario 1">

                    <div class="row p-3">
                        <div class="d-flex justify-content-end">
                            <button  class="flat" color="primary" mat-flat-button (click)="cambiarConfiguracion()">
                                <mat-icon>
                                    settings
                                </mat-icon>
                            </button>
                        </div>
                    </div>

                    <div class="row mt-3" *ngIf="payload?.payload?.modificar_control">
                        <div class="col">
                            <div class="row">
                                <span><strong>Calendarización:</strong> {{all2?.configuracion_calendario?.catalogo_calendario?.catalogo}}</span>
                            </div>
                            <div class="row">
                                <span><strong>Calendario:</strong> {{all2?.configuracion_calendario?.tipo_calendario?.tipo_calendario}}</span>
                            </div>
                            <div class="row" *ngIf="all2?.configuracion_calendario?.tipo_configuracion == 1">
                                <span><strong>Tipo de configuración:</strong> Estructural</span>
                            </div>
                            <div class="row" *ngIf="all2?.configuracion_calendario?.tipo_configuracion == 2">
                                <span><strong>Tipo de configuración:</strong> Individual</span>
                            </div>
                            <div class="row">
                                <span><strong>Nombre:</strong> {{all2?.configuracion_calendario?.nombre_actividad}}</span>
                            </div>
                            <div class="row" *ngIf="all2?.configuracion_calendario?.frecuencia == 2">
                                <span><strong>Semanal días:</strong> <!--{{all2?.configuracion_calendario?.semanal_dias}}-->
                                    <mat-chip-list>
                                        <mat-chip
                                          cdkDrag
                                          *ngFor="let vegetable of all2?.configuracion_calendario?.semanal_dias?.split('/')">
                                          {{vegetable}}
                                        </mat-chip>
                                      </mat-chip-list>
                                </span>
                            </div>
                            <div class="row" *ngIf="all2?.configuracion_calendario?.frecuencia == 1">
                                <span><strong>Diaria cada:</strong> {{all2?.configuracion_calendario?.diaria_cada}}</span>
                            </div>
                            <div class="row" *ngIf="all2?.configuracion_calendario?.frecuencia == 2">
                                <span><strong>Semanal cada:</strong> {{all2?.configuracion_calendario?.semanal_repetir}}</span>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <span><strong>Fecha inicio:</strong> {{all2?.configuracion_calendario?.fecha_inicio}}</span>
                                </div>
                                <div class="col">
                                    <span><strong>Hora inicio:</strong> {{all2?.configuracion_calendario?.hora_inicio}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <span><strong>Fecha fin:</strong> {{all2?.configuracion_calendario?.fecha_fin}}</span>
                                </div>
                                <div class="col">
                                    <span><strong>Hora fin:</strong> {{all2?.configuracion_calendario?.hora_fin}}</span>
                                </div>
                            </div>


                        </div>
                    </div>


                    <div class="row mt-5">    
                        <form novalidate [formGroup]="form" autocomplete="off">
                            <div class="row"  *ngIf="!payload?.payload?.modificar_control">
                                <mat-form-field appearance="fill" class="input-full-w">
                                    <mat-label>Tipo de configuración</mat-label>
                                    <mat-select formControlName="tipo_configuracion"  >
                                        <mat-option [value]="1">Estructura</mat-option>
                                        <mat-option [value]="2">Individual</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
            
                            <div class="row" *ngIf="!payload?.payload?.modificar_control">
                                <mat-form-field appearance="fill" class="input-full-w" >
                                    <mat-label>Calendario</mat-label>
                                    <mat-select formControlName="numero_calendario" >
                                        <mat-option *ngFor="let a of dataForm?.tipo_calendario" [value]="a.id_tipo_calendario_pk">{{a?.tipo_calendario}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="form.controls['numero_calendario'].hasError('required')">
                                        El campo es requerido
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="row" *ngIf="form.value.tipo_configuracion == 1 && !payload?.payload?.modificar_control">
                                <mat-form-field appearance="fill" class="input-full-w">
                                    <mat-label>Estructura</mat-label>
                                    <mat-select formControlName="numero_estructura" >
                                        <mat-option *ngFor="let a of dataForm?.estructura_tipo" [value]="a.id_estructura_tipo_pk">{{a?.estructura}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="form.controls['numero_estructura'].hasError('required')">
                                        El campo es requerido
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="row" *ngIf="form.value.tipo_configuracion == 2 && !payload?.payload?.modificar_control">
                                <mat-form-field appearance="fill" class="input-full-w">
                                    <mat-label>Programa</mat-label>
                                    <mat-select formControlName="numero_calendario" >
                                        <mat-option *ngFor="let a of dataForm?.Programa_calendario" [value]="a.id_programa_calendario_pk">{{a?.programa_calendario}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="form.controls['numero_calendario'].hasError('required')">
                                        El campo es requerido
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="row" *ngIf="!payload?.payload?.modificar_control">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>Nombre </mat-label>
                                    <input formControlName="nombre" type="text" matInput>
                                    <mat-error *ngIf="form.controls['nombre'].hasError('required')">
                                        El campo es requerido
                                    </mat-error>
                                    <mat-error *ngIf="form.controls['nombre'].hasError('maxlength')">
                                        Máximo 250 caracteres
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="row" *ngIf="payload?.payload?.modificar_control">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>Nombre de la actividad </mat-label>
                                    <input formControlName="nombre_actividad" type="text" matInput>
                                </mat-form-field>
                            </div>

                            <div class="row" *ngIf="payload?.payload?.modificar_control">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>Descripción </mat-label>
                                    <textarea rows="5" formControlName="descripcion" type="text" matInput></textarea>
                                </mat-form-field>
                            </div>
            
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Fecha inicio </mat-label>
                                        <input formControlName="fecha_inicio" type="date" matInput>
                                        <mat-error *ngIf="form.controls['fecha_inicio'].hasError('required')">
                                            El campo es requerido
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Hora inicio </mat-label>
                                        <input formControlName="hora_inicio" type="time" matInput>
                                        <mat-error *ngIf="form.controls['hora_inicio'].hasError('required')">
                                            El campo es requerido
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                
                            </div>
            
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Fecha fin </mat-label>
                                        <input [readonly]="payload?.payload?.modificar_control" formControlName="fecha_fin" type="date" matInput>
                                        <mat-error *ngIf="form.controls['fecha_fin'].hasError('required')">
                                            El campo es requerido
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill"  class="input-full-w" >
                                        <mat-label>Hora fin </mat-label>
                                        <input formControlName="hora_fin" type="time" matInput>
                                        <mat-error *ngIf="form.controls['hora_fin'].hasError('required')">
                                            El campo es requerido
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                
                            </div>
            
                            <div class="row mt-3" *ngIf="!payload?.payload?.modificar_control">
                                <div class="col">
            
                                </div>
                                <div class="col">
                                    <!-- Button trigger modal -->
                                    <button  class="flat" color="primary" mat-flat-button data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Repetificiones
                                    </button>
                                </div>
                            </div>
                        </form>


                        
                        <div class="row" *ngIf="payload?.payload?.modificar_control">
                            <div class="col">
                                <div  class="input-group">
                                    <input   type="file"  class="form-control" (change)="postRegistrarDocumentoEventoControlCalendario($event, all2?.id_control_fecha_pk)" >
                                    
                                </div>
                            </div>
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                                <a *ngIf="all2?.ruta_relativa != null"  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{all2?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                                    
                                    <mat-icon class="material-icons">
                                        file_open
                                    </mat-icon>
                                </a>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col">
                                <div class="row mt-5" *ngIf="form.value.tipo_configuracion == 2">
                                    <div class="col">
                                        Asignación de beneficiarios
                                    </div>
                                    <div class="col">
                                        <div class="row">
                                            <form novalidate [formGroup]="form" autocomplete="off">
                                            <mat-form-field appearance="fill" class="input-full-w">
                                                <mat-label>Asignación de beneficiarios</mat-label>
                                                <mat-select formControlName="aux_ben" >
                                                    <mat-option *ngFor="let a of asinBene" [value]="a.id">{{a?.nombre}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <button  type="button" color="primary"  mat-mini-fab (click)="agregarBene()">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="row" *ngFor="let b of asinBene2; let i = index">
                                    <div class="col"></div>
                                    <div class="col">
                                        <mat-form-field appearance="fill" class="input-full-w">
                                            <mat-label>Beneficiaria</mat-label>
                                            <mat-select [(ngModel)]="b.numero_bene" >
                                                <mat-option *ngFor="let a of asinBene" [value]="a.id">{{a?.nombre}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <button (click)="eliminarBene(i)" type="button" class="btn btn-small btn-danger">x</button>
                                    </div>
                                </div>
                
                
                
                
                                
                
                                <div class="row" *ngIf="form.value.tipo_configuracion == 2">
                                    <div class="col">
                                        Asignación de personal
                                    </div>
                                    <div class="col">
                                        <div class="row">
                                            <form novalidate [formGroup]="form" autocomplete="off">
                                            <mat-form-field appearance="fill" class="input-full-w">
                                                <mat-label>Personal</mat-label>
                                                <mat-select formControlName="aux_per" >
                                                    <mat-option *ngFor="let a of asigPersonal" [value]="a.id_info_empleado_pk">{{a?.nombre}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <button type="button" color="primary"  mat-mini-fab (click)="agregarPerso()">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="row" *ngFor="let b of asigPersonal2; let i = index">
                                    <div class="col"></div>
                                    <div class="col">
                                        <mat-form-field appearance="fill" class="input-full-w">
                                            <mat-label>Personal</mat-label>
                                            <mat-select [(ngModel)]="b.numero_personal" >
                                                <mat-option *ngFor="let a of asigPersonal" [value]="a.id_info_empleado_pk">{{a?.nombre}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <button (click)="eliminarPerso(i)" type="button" class="btn btn-small btn-danger">x</button>
                                    </div>
                                </div>
                
                
                                    
                                <div class="d-flex justify-content-center mt-5 gap-2">
                                    <button id="myModal" #myModal class="btn btn-secondary" mat-dialog-close>Cerrar</button>
                                    <button *ngIf="payload.accion == 'registrar' && !payload?.payload?.modificar_control"  class="flat" color="primary" mat-flat-button (click)="registrar()">
                                        Guardar
                                    </button>
                                    <button *ngIf="payload.accion == 'modificar' && !payload?.payload?.modificar_control"  class="flat" color="primary" mat-flat-button (click)="registrar('modificar')">
                                        Guardar.
                                    </button>

                                    <button *ngIf="payload?.payload?.modificar_control"  class="flat" color="primary" mat-flat-button (click)="registrar2()">
                                        Guardar cambios
                                    </button>

                                    



                                </div>
                            </div>
                        </div>
                    </div>
            
                            



                    


                </mat-tab>
                
                <mat-tab label="Formulario hijos" *ngIf="!payload?.payload?.modificar_control">
                    <div class="row mt-5" *ngFor="let a of all?.control_fecha">
                        <div class="col">
                            <div class="row">
                                <span><strong>Nombre de la nombre_actividad:</strong> {{a?.nombre_actividad}}</span>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <span><strong>Fecha inicio:</strong> {{a?.fecha_inicio}}</span>
                                </div>
                                <div class="col">
                                    <span><strong>Hora inicio:</strong> {{a?.hora_inicio}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <span><strong>Fecha fin:</strong> {{a?.fecha_fin}}</span>
                                </div>
                                <div class="col">
                                    <span><strong>Hora fin:</strong> {{a?.hora_fin}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <span><strong>Descripción:</strong> {{a?.descripcion}}</span>
                            </div>
                            <div class="row">
                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                                    <a *ngIf="a?.ruta_relativa != null"  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{a?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                                        
                                        <mat-icon class="material-icons">
                                            file_open
                                        </mat-icon>
                                    </a>
                                </div>
                                <div class="col">
                                    <div class="d-flex justify-content-center">
                                        <button  class="flat" color="primary" mat-flat-button (click)="cambiarHijo(a?.id_control_fecha_pk)">
                                            Consultar
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>

                </mat-tab>




            </mat-tab-group>



            


            
        </mat-card-content>
    </mat-card>
</div>


  








  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="1000" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Repetir cita</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form novalidate [formGroup]="form" autocomplete="off">
                <div class="row" (click)="setTipoDS()">
                    <div class="col">
                       
                            <mat-radio-group  class="m-0"  formControlName="frecuencia">
                                <br>
                                <mat-radio-button class="m-0" [value]="1">Diaria </mat-radio-button>
                                <br>
                              
                                <mat-radio-button class="m-0" [value]="2">Semanal </mat-radio-button>
                                <!--<br>
                                
                                <mat-radio-button class="m-0" [value]="3">Mensual </mat-radio-button>
                                <br>
                                
                                <mat-radio-button class="m-0" [value]="4">Anual </mat-radio-button>
                                <br>-->
                            </mat-radio-group>
                        
                    </div>
                    
                    <div class="col">

                        <div class="row" *ngIf="form.value.frecuencia == 1">
                            <mat-form-field appearance="fill"  class="input-full-w" >
                                <mat-label>Repetir cada </mat-label>
                                <input formControlName="diaria_cada" type="number" matInput>
                            </mat-form-field>
                        </div>

                        <div class="row" *ngIf="form.value.frecuencia == 2">
                            <mat-form-field appearance="fill"  class="input-full-w" >
                                <mat-label>Repetir cada </mat-label>
                                <input formControlName="semanal_repetir" type="number" matInput>
                            </mat-form-field>
                        </div>

                        <div class="row" *ngIf="form.value.frecuencia == 2" (click)="setTipoDS()">
                            <mat-checkbox  formControlName="L">
                               Lunes
                            </mat-checkbox>
                            <mat-checkbox  formControlName="M">
                               Martes
                            </mat-checkbox>
                            <mat-checkbox  formControlName="Mi">
                               Miercoles
                            </mat-checkbox>
                            <mat-checkbox formControlName="J" >
                               Jueves
                            </mat-checkbox>
                            <mat-checkbox  formControlName="V">
                               Viernes
                            </mat-checkbox>
                            <mat-checkbox  formControlName="S">
                               Sabado
                            </mat-checkbox>
                            <mat-checkbox  formControlName="D">
                               Domingo
                            </mat-checkbox>

                        </div>
                        
                            
                        
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <!--<button class="flat" color="primary" mat-flat-button data-bs-dismiss="modal">Cerrar</button>
            &nbsp;-->
            <button  class="flat" color="primary" mat-flat-button data-bs-dismiss="modal">
                Guardar y cerrar
            </button>

            <!--<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>-->
        </div>
      </div>
    </div>
  </div>