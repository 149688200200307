import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { MatCardActions } from '@angular/material/card';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class EytService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

  public getConsultaEventosMedicos(p: number, r: number, id:number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultaEventosMedicos?filas="+r+'&page='+p+'&numero_beneficiario='+id);
  }

  public postRegistroEventoMedico(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registroEventoMedico", datos, {});
  }

  public postEliminarEventoMedico(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/eliminarEventoMedico", datos, {});
  }

  public getConsultaEventoMedico(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultaEventoMedico?numero_evento_medico="+id);
  }

  public postModificarEventoMedico(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarEventoMedico", datos, {});
  }

  

  


}