//General
import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { SesionService } from 'src/app/services/sesion.service';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { CapacitacionService } from 'src/app/services/capacitacion.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';
import { RrhhService } from 'src/app/services/rrhh.service';

@Component({
  selector: 'app-rrhh-capacitaciones',
  templateUrl: './rrhh-capacitaciones.component.html',
  styleUrls: ['./rrhh-capacitaciones.component.scss'],
  providers: [ CapacitacionService, RrhhService ]
})
export class RrhhCapacitacionesComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    numero_capacitacion: new UntypedFormControl(-1, []),
    tipo_capa: new UntypedFormControl('1', [Validators.required]),
    nombre_capa: new UntypedFormControl('', [Validators.maxLength(1500)]),
    descripcion: new UntypedFormControl('', [Validators.maxLength(2500)]),
    quien_impartio: new UntypedFormControl('', [Validators.maxLength(2500)]),


    fecha_inicio: new UntypedFormControl('', [Validators.required]),
    fecha_fin: new UntypedFormControl('', [Validators.required]),

    id_estatus_general_fk: new UntypedFormControl(1, []),
    trabajadores_aux: new UntypedFormControl([], []),
    trabajadores: new UntypedFormControl([], []),

    trabajador: new UntypedFormControl([], []),

  });

  public accion: string = '';
  public isLoading: boolean = false;
  public id_incidencia: any;
  public id_numero_empleado: any;
  public laravelapiStorage = this._CapacitacionService.getLaravelApiStorage();
  public laravelapi = this._CapacitacionService.getLaravelApi();
  public t: any;
  public data: any;
  public bene: any = [];
  public tts: any;
  public ds: string = '';
  public nds: number = 0;
  public anns: number = 0;
  public data_h: any;
  public cata: any;
  public empleados: any;
  public elegidos: any = [];
  public id_capacitacion: any;


  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _CapacitacionService: CapacitacionService,
    private ghedp_rrhhService: RrhhService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
    private _sesion:SesionService,
  ) { /*modificar*/
  this.form.markAllAsTouched();
  this.getExpedienteConsultas();
  this._route.queryParams.subscribe(
    params => {
      this.id_capacitacion = +params['id_capacitacion'];
      console.log(this.id_capacitacion);
      if(this.id_capacitacion){
        this.postConsultarCapacitacion(this.id_capacitacion);
      }
      
    });
  /*fin modificar*/
 }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[4].split("?")[0];
    console.log(this.accion);
  }


  public postRegistrarCapacitacion(){
    this.isLoading = true;


    var elegidos_aux = [];
    for(let i = 0; i < this.elegidos?.length; i++){
      elegidos_aux.push({numero_empleado: this.elegidos[i].numero_empleado});
    }

    this.form.patchValue({
      trabajadores: elegidos_aux
    });

    this._CapacitacionService.postRegistrarCapacitacion(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this._router.navigate(['rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones/modificar'], { queryParams: { id_capacitacion: response.data.id_capacitaciones_pk }} );
          //this.postConsultarIncidente(this.id_incidencia);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }


  public postModificarCapacitacion(){
    this.isLoading = true;


    var elegidos_aux = [];
    for(let i = 0; i < this.elegidos?.length; i++){
      elegidos_aux.push({numero_empleado: this.elegidos[i].numero_empleado});
    }

    this.form.patchValue({
      trabajadores: elegidos_aux
    });

    this._CapacitacionService.postModificarCapacitacion(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.postConsultarCapacitacion(this.id_capacitacion);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getExpedienteConsultas(page: number = 1){
    this.isLoading = true;
    this.ghedp_rrhhService.getExpedienteConsultas(1, 10000, '', 3).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.empleados = response?.data?.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public postConsultarCapacitacion(id: number){
    this.isLoading = true;
    this._CapacitacionService.postConsultarCapacitacion({id_capacitacion: id}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.data = response?.data;
          this.setForm(response?.data);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public setForm(data: any){
    this.form.patchValue({
      numero_capacitacion: data?.id_capacitaciones_pk,
      nombre_capa: data?.nombre_capa,
      descripcion: data?.descripcion,
      quien_impartio: data?.quien_impartio,
      id_estatus_general_fk: data?.id_estatus_general_fk,
      fecha_inicio: data?.fecha_inicio,
      fecha_fin: data?.fecha_fin,
      tipo_capa: +data?.tipo_capa,


    });

    this.elegidos = [];
    for(let i = 0; i < data?.empleados?.length; i++){
      this.elegidos.push({
        numero_empleado: data?.empleados[i].id_info_empleado_pk, 
        nombre: data?.empleados[i].apellidop + ' ' + data?.empleados[i].apellidom + ' ' + data?.empleados[i].nombre
      });
    }
  }





  public elegir(id: number){
    var flag = true;
    for(let i = 0; i < this.elegidos?.length ; i++){
      if(this.elegidos[i]?.numero_empleado == id){
        flag = false;
      }
      
    }


    if(flag){
      for(let i = 0; i < this.empleados?.length ; i++){
        if(this.empleados[i]?.id_info_empleado_pk == id){
          this.elegidos.push({
            numero_empleado: this.empleados[i].id_info_empleado_pk, 
            nombre: this.empleados[i].apellidop + ' ' + this.empleados[i].apellidom + ' ' + this.empleados[i].nombre
          });
        }
        
      }
      
    }else{
      this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Ya existe', mensaje: 'Ya selecciono este empleado', icon:'correct'}});
    }

    
  }

  public quitar(i: number){
    this.elegidos.splice(i, 1);
  }










  public foto: any;
  public nombreFoto: string = '';
  public regitsrarFoto(event: any, caso: number = 0, element: any = null){
    if(caso == 0){
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        console.log(event.target.files);
        console.log(file.type);
        if (true) {//text/xml
          this.foto = file;
        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato Foto', icon:'error'}});
        }
      }
    }else{
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        console.log(event.target.files);
        console.log(file.type);
        if (true) {//text/xml
          element.fileCargado = file;
          console.log(element);
        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato Foto', icon:'error'}});
        }
      }
    }
    
  }


  /*registrar foto*/
  public RegistrarFotoCapa( _id: number): any{
        const formData = new FormData();
        formData.set('documento', this.foto);
        formData.set('numero_capacitacion',  _id.toString());
        formData.set('nombre_archivo',  this.nombreFoto);
        this.isLoading = true;
        this._CapacitacionService.postRegistrarFotoCapa(formData).subscribe(
          response => {console.log(response);
            this.postConsultarCapacitacion(this.id_capacitacion);
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            this.nombreFoto = '';
          },
          error => {console.log(<any>error);
            this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
            this.isLoading = false;
        });
  }

  /*modificar foto*/
  public postModificarFotoCapa( element: any): any{
    const formData = new FormData();
    formData.set('documento', element.fileCargado);
    formData.set('id_numero_foto',  element.id_foto_pk.toString());
    formData.set('nombre_archivo',  element.nombre_foto);
    formData.set('numero_capacitacion',  this.id_capacitacion);
    this.isLoading = true;
    this._CapacitacionService.postModificarFotoCapa(formData).subscribe(
      response => {console.log(response);
        this.postConsultarCapacitacion(this.id_capacitacion);
        this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
        this.nombreFoto = '';
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
    });
}



  /*eliminar foto*/
  public postEliminarFotoCapa( _id: number): any{
    this.isLoading = true;
    this._CapacitacionService.postEliminarFotoCapa({id_numero_foto: _id}).subscribe(
      response => {console.log(response);
        this.postConsultarCapacitacion(this.id_capacitacion);
        this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa de Eliminar Foto', mensaje: response.mensaje, icon:'correct'}});
        this.nombreFoto = '';
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
    });

}


















  public documento: any;
  public nombreDocumento: string = '';
  public regitsrarDocumento(event: any, caso: number = 0, element: any = null){
    if(caso == 0){
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        console.log(event.target.files);
        console.log(file.type);
        if (file.type.startsWith('application/pdf')) {//text/xml
          this.foto = file;
        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato Documento', icon:'error'}});
        }
      }
    }else{
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        console.log(event.target.files);
        console.log(file.type);
        if (file.type.startsWith('application/pdf')) {//text/xml
          element.fileCargado = file;
          console.log(element);
        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato Documento', icon:'error'}});
        }
      }
    }
    
  }


  /*registrar foto*/
  public RegistrarDocumentoCapa( _id: number): any{
        const formData = new FormData();
        formData.set('documento', this.foto);
        formData.set('numero_capacitacion',  _id.toString());
        formData.set('nombre_archivo',  this.nombreDocumento);
        this.isLoading = true;
        this._CapacitacionService.postRegistrarDocumentoCapa(formData).subscribe(
          response => {console.log(response);
            this.postConsultarCapacitacion(this.id_capacitacion);
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            this.nombreDocumento = '';
          },
          error => {console.log(<any>error);
            this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
            this.isLoading = false;
        });
  }

  /*modificar foto*/
  public postModificarDocumentoCapa( element: any): any{
    const formData = new FormData();
    formData.set('documento', element.fileCargado);
    formData.set('id_numero_documento',  element.id_archivo_pk.toString());
    formData.set('nombre_archivo',  element.nombre_archivo);
    formData.set('numero_capacitacion',  this.id_capacitacion);
    this.isLoading = true;
    this._CapacitacionService.postModificarDocumentoCapa(formData).subscribe(
      response => {console.log(response);
        this.postConsultarCapacitacion(this.id_capacitacion);
        this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
        this.nombreDocumento = '';
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
    });
}



  /*eliminar foto*/
  public postEliminarDocumentoCapa( _id: number): any{
    this.isLoading = true;
    this._CapacitacionService.postEliminarDocumentoCapa({id_numero_documento: _id}).subscribe(
      response => {console.log(response);
        this.postConsultarCapacitacion(this.id_capacitacion);
        this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa de Eliminar Documento', mensaje: response.mensaje, icon:'correct'}});
        this.nombreFoto = '';
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
    });

}









}
