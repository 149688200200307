import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { Beneficiaria } from 'src/app/model/beneficiaria';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ParametrosService } from 'src/app/services/parametros.service';
import * as moment from 'moment';
import { ConfigurationService } from 'src/app/services/configuration.service';


@Component({
  selector: 'app-vista-perfil',
  templateUrl: './vista-perfil.component.html',
  styleUrls: ['./vista-perfil.component.scss']
})
export class VistaPerfilComponent implements OnInit {
  hostapi:string;
  constructor(
    private _documentosService:DocumentosService,
    private dialog:MatDialog,
    private activatedRoute:ActivatedRoute,
    private _parametrosService:ParametrosService,
    private _beneficiariaService:BeneficiariaService,
    private _conf:ConfigurationService
    ) { 
      this.hostapi=_conf.getConfiguration().hostapi;
    }

  discapacidades = ["Intelectual","Motriz","Conductual","Mental"];
  beneficiaria:Beneficiaria = null;
  isLoading=false;
  idBeneficiaria = 0;
  fileToUpload:File = null;
  fileToUpload1:File = null;
  fileToUpload2:File = null;
  fileToUpload3:File = null;
  fileToUpload4:File = null;
  fileToUpload5:File = null;
  fileToUpload6:File = null;
  fileToUpload7:File = null;
  autonomiasConitivas = [];

  formulario = new UntypedFormGroup({
    fechaIngreso : new UntypedFormControl("",[]),
    desAntecedente: new UntypedFormControl("",[]),

    diagnosticoNeurologico: new UntypedFormControl("",[]),
    dnArchivo:new UntypedFormControl("",[]),
    dnDescripcion:new UntypedFormControl("",[]),
    dnRuta:new UntypedFormControl("",[]),

    edadMental: new UntypedFormControl("",[]),
    emArchivo: new UntypedFormControl("",[]),
    emRuta:new UntypedFormControl("",[]),

    diagnosticoMotriz: new UntypedFormControl("",[]),
    dmArchivo: new UntypedFormControl("",[]),
    dmRuta:new UntypedFormControl("",[]),

    diagnosticoPsicologico: new UntypedFormControl("",[]),
    dpArchivo: new UntypedFormControl("",[]),
    dpRuta:new UntypedFormControl("",[]),

    diagnosticoMedico: new UntypedFormControl("",[]),
    dmedArchivo: new UntypedFormControl("",[]),
    dmedRuta:new UntypedFormControl("",[]),

    tipoDeSangre: new UntypedFormControl("",[]),
    tsArchivo: new UntypedFormControl("",[]),
    tsRuta:new UntypedFormControl("",[]),

    condicionRelevante:new UntypedFormControl("",[]),
    condicionRelevanteDescription:new UntypedFormControl("",[]),


    autonomiaCognitivoSelect:new UntypedFormControl("",[]),
    autonomiaCognitivoInput:new UntypedFormControl("",[]),
    autonomiaMotrizSelect:new UntypedFormControl("",[]),
    autonomiaMotrizInput:new UntypedFormControl("",[]),
    diagnosticoEmocionalSelect:new UntypedFormControl("",[]),
    diagnosticoEmocionalInput:new UntypedFormControl("",[]),

    idDiscapacidad: new UntypedFormControl("",[])
  });
  ngOnInit(): void {
    this.vp_cargaCatalogos___();
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info("Parametros");
      console.info(params["id"]);
      if(params["id"]){
        this.idBeneficiaria= params["id"];
        this.isLoading = true;
        this._beneficiariaService.findById(this.idBeneficiaria).subscribe(resss=>{
          this.beneficiaria = resss.data;
          this.vp_setData___();
          this.isLoading = true;
          this._beneficiariaService.getProcesosByBeneficiaria(this.idBeneficiaria).subscribe(resssado=>{
            this.isLoading = false;
            this.form.fechaIngreso.setValue(new Date(resssado.data.procesos[0].etapas.candidata.fechaAlta.date));
            this.form.fechaIngreso.disable();
          },()=>{
            this.isLoading = false;
          });
          console.log("va a cargar docs");
          this.vp_getDocumentos___(this.idBeneficiaria);
        },()=>{
          this.isLoading = false;
        })
      }
    });
    
  }



  tipoDeSangre:any;
  diagnosticoNeurologico:any;
  vp_cargaCatalogos___(){
    //param motivo ingreso
    this._parametrosService.getParametros("11").subscribe(resss=>{
      this.tipoDeSangre = resss.data.parametros;
    });
    this._parametrosService.getParametros("4").subscribe(resss=>{
      this.diagnosticoNeurologico = resss.data.parametros;
    });
    
  }

  /*cargarDocumentos(){
    
    console.info(this.formulario.controls.dnArchivo.value);
    this.altaDocumento("57",this.formulario.controls.diagnosticoNeurologico.value,this.formulario.controls.dnDescripcion.value,this.fileToUpload1).finally(()=>{
      this.altaDocumento("58","",this.formulario.controls.edadMental.value,this.fileToUpload2).finally(()=>{
        this.altaDocumento("59","",this.formulario.controls.diagnosticoMotriz.value,this.fileToUpload3).finally(()=>{
          this.altaDocumento("60","",this.formulario.controls.diagnosticoPsicologico.value,this.fileToUpload4).finally(()=>{
            this.altaDocumento("61","",this.formulario.controls.diagnosticoMedico.value,this.fileToUpload5).finally(()=>{
              setTimeout(()=>{
                this.altaDocumento("62",this.formulario.controls.tipoDeSangre.value,"",this.fileToUpload6).finally(()=>{
                  this.altaDocumento("84",this.formulario.controls.condicionRelevante.value,this.formulario.controls.condicionRelevanteDescription.value,this.fileToUpload7).finally(()=>{
                    this.vp_mensajeDeExito2___();
                  })
                })
              },1000)
            })
          })
        })
      })
    });
  }*/

  arreglo = [];
  vp_cargarArreglo___(tipoDocumento:string,idTipoCatalogo:string,desTipoCatalogo:string,file:File){
    this.arreglo.push({"file":file,"idBeneficiaria":this.idBeneficiaria.toString(),"idTipoCatalogo":idTipoCatalogo,"desTipoCatalogo":desTipoCatalogo,"tipoDocumento":tipoDocumento});
  }

  vp_cargarListaDoc___(){
    return new Promise((resolve: any, reject: any) => {

      this.vp_cargarArreglo___("57",this.formulario.controls.diagnosticoNeurologico.value,this.formulario.controls.dnDescripcion.value,this.fileToUpload1);
      this.vp_cargarArreglo___("58","",this.formulario.controls.edadMental.value,this.fileToUpload2);
      this.vp_cargarArreglo___("59","",this.formulario.controls.diagnosticoMotriz.value,this.fileToUpload3);
      this.vp_cargarArreglo___("60","",this.formulario.controls.diagnosticoPsicologico.value,this.fileToUpload4);
      this.vp_cargarArreglo___("61","",this.formulario.controls.diagnosticoMedico.value,this.fileToUpload5);
      this.vp_cargarArreglo___("62",this.formulario.controls.tipoDeSangre.value,"",this.fileToUpload6);
      this.vp_cargarArreglo___("84",this.formulario.controls.condicionRelevante.value,this.formulario.controls.condicionRelevanteDescription.value,this.fileToUpload7);
      
      this.vp_cargarArreglo___("120","",this.formulario.controls.idDiscapacidad.value,null);

      let vp_formData = new FormData();
      vp_formData.append('contador', "8");
      for(let vp=0; vp<this.arreglo.length; vp++){
        if(vp==0){
          vp_formData.append('documento', this.arreglo[vp].file);
          vp_formData.append('idBeneficiaria', this.idBeneficiaria.toString());
          vp_formData.append('idTipoDocumento', this.arreglo[vp].tipoDocumento);
          vp_formData.append('idTipoCatalogo', this.arreglo[vp].idTipoCatalogo);
          vp_formData.append('desTipoCatalogo', this.arreglo[vp].desTipoCatalogo);
        }else{
          vp_formData.append('documento'+vp, this.arreglo[vp].file);
          vp_formData.append('idBeneficiaria'+vp, this.idBeneficiaria.toString());
          vp_formData.append('idTipoDocumento'+vp, this.arreglo[vp].tipoDocumento);
          vp_formData.append('idTipoCatalogo'+vp, this.arreglo[vp].idTipoCatalogo);
          vp_formData.append('desTipoCatalogo'+vp, this.arreglo[vp].desTipoCatalogo);
        }
      }

      this.isLoading=true;
      this._documentosService.saveMulti(vp_formData).subscribe(resss=>{
        console.info(resss);
        this.isLoading=false;
        this.vp_mensajeDeExito2___();
        resolve(true);
      },()=>{
        this.isLoading=false;
        resolve(true);
      })
        
   
      
    });
  }
  
  vp_mensajeDeExito2___(){
    const dialogRef= this.dialog.open(MessageBoxComponent,{
      data:{titulo: "Expediente modificado" ,mensaje: "Se ha modificado el expediente correctamente"}
    });
    dialogRef.afterClosed().subscribe(()=>{
      document.getElementById("resetForm").click();
      this.ngOnInit();
    })
  }

  vp_setData___(){
    this.formulario.controls.desAntecedente.setValue(this.beneficiaria.desAntecedente);
    this.formulario.controls.desAntecedente.disable();
  }
  documentos:any;
  documento1 = null;
  documento2 = null;
  documento3 = null;
  documento4 = null;
  documento5 = null;
  documento6 = null;

  vp_getDocumentos___(id:number){
    this.isLoading = true;
    this._documentosService.getAllByBeneficiaria(id).subscribe(resss=>{
      this.isLoading = false;
      this.documentos = resss.data.documentos;

      for(let vp = 0; vp< this.documentos.length; vp++){
        if(this.documentos[vp].idTipoDocumento == 57){
          this.formulario.controls.diagnosticoNeurologico.setValue(this.documentos[vp].idTipoCatalogo);
          this.formulario.controls.dnDescripcion.setValue(this.documentos[vp].desTipoCatalogo);
          //this.formulario.controls.dnRuta.setValue(this.documentos[vp].docTipoCatalogo);
          this.documento1 = this.documentos[vp].docTipoCatalogo;
        }else if(this.documentos[vp].idTipoDocumento == 58){
          this.formulario.controls.edadMental.setValue(this.documentos[vp].desTipoCatalogo);
          this.formulario.controls.emRuta.setValue(this.documentos[vp].docTipoCatalogo);
          this.documento2 = this.documentos[vp].docTipoCatalogo;
        }else if(this.documentos[vp].idTipoDocumento == 59){
          this.formulario.controls.diagnosticoMotriz.setValue(this.documentos[vp].desTipoCatalogo);
          //this.formulario.controls.dmRuta.setValue(this.documentos[vp].docTipoCatalogo);
          this.documento3 = this.documentos[vp].docTipoCatalogo;
        }else if(this.documentos[vp].idTipoDocumento == 60){
          this.formulario.controls.diagnosticoPsicologico.setValue(this.documentos[vp].desTipoCatalogo);
          //this.formulario.controls.dpRuta.setValue(this.documentos[f].docTipoCatalogo);
          this.documento4 = this.documentos[vp].docTipoCatalogo;
        }else if(this.documentos[vp].idTipoDocumento == 61){
          this.formulario.controls.diagnosticoMedico.setValue(this.documentos[vp].desTipoCatalogo);
          
          //this.formulario.controls.dmedRuta.setValue(this.documentos[f].docTipoCatalogo);
          this.documento5 = this.documentos[vp].docTipoCatalogo;
        }else if(this.documentos[vp].idTipoDocumento == 62){
          this.formulario.controls.tipoDeSangre.setValue(this.documentos[vp].idTipoCatalogo);
          
          //this.formulario.controls.tsRuta.setValue(this.documentos[i].docTipoCatalogo);
          this.documento6  = this.documentos[vp].docTipoCatalogo;
        }else if(this.documentos[vp].idTipoDocumento == 84){
          this.formulario.controls.condicionRelevante.setValue(this.documentos[vp].idTipoCatalogo);
          this.formulario.controls.condicionRelevanteDescription.setValue(this.documentos[vp].desTipoCatalogo);
          //this.formulario.controls.tsRuta.setValue(this.documentos[i].docTipoCatalogo);
          
        }else if(this.documentos[vp].idTipoDocumento == 120){
          this.formulario.controls.idDiscapacidad.setValue(this.documentos[vp].desTipoCatalogo.split(","));
          
        }

      }
      this.formulario.controls.dnDescripcion.disable();
      this.formulario.controls.diagnosticoNeurologico.disable();
      this.formulario.controls.dnRuta.disable();
      this.formulario.controls.edadMental.disable();
      this.formulario.controls.emRuta.disable();
      this.formulario.controls.dmRuta.disable();
      this.formulario.controls.diagnosticoMotriz.disable();
      this.formulario.controls.diagnosticoPsicologico.disable();
      this.formulario.controls.dpRuta.disable();
      this.formulario.controls.diagnosticoMedico.disable();
      this.formulario.controls.dmedRuta.disable();
      this.formulario.controls.tipoDeSangre.disable();
      this.formulario.controls.tsRuta.disable();
      this.formulario.controls.condicionRelevante.disable();
      this.formulario.controls.condicionRelevanteDescription.disable();
      this.formulario.controls.tsRuta.disable();
      this.formulario.controls.idDiscapacidad.disable();
    },()=>{
      this.isLoading = false;
    })
  }
  get form(){
    return this.formulario.controls;
  }
  get edadIngreso(){
    if(this.beneficiaria==null){
      return "-";
    }
    if((this.beneficiaria.fechaNacimiento["date"]=="" || this.beneficiaria.fechaNacimiento["date"]==null || this.form.fechaIngreso.value=="" || this.form.fechaIngreso.value==null)){
      return "-";
    }else{
      //console.info(this.beneficiaria.fechaIngreso["date"]);
      let vp_edad:Number = moment(this.form.fechaIngreso.value).diff(this.beneficiaria.fechaNacimiento["date"], 'years',true);
      //console.info(edad);
      let laedad:String = vp_edad.toString();
      let años = laedad.split(".")[0];
      let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let vp_detalleEdad = años + " años "+ meses + " meses";
      return vp_detalleEdad;
    }
  }

  get tiempoDeAntiguedad(){
    if( this.form.fechaIngreso.value=="" || this.form.fechaIngreso.value==null ){
      return "0";
    }else{
      //console.info(this.beneficiaria.fechaIngreso["date"]);
      let vp_edad:Number = moment(new Date()).diff(this.form.fechaIngreso.value, 'years',true);
      //console.info(edad);
      let laedad:String = vp_edad.toString();
      let años = laedad.split(".")[0];
      let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let vp_detalleEdad = años + " años "+ meses + " meses";
      return vp_detalleEdad;
    }
  }
}
