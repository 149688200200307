//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import {CapacitacionService } from 'src/app/services/capacitacion.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//Fin Tabla

@Component({
  selector: 'app-rrhh-gt-capacitaciones',
  templateUrl: './rrhh-gt-capacitaciones.component.html',
  styleUrls: ['./rrhh-gt-capacitaciones.component.scss'],
  providers: [ CapacitacionService]
})
export class RrhhGtCapacitacionesComponent implements OnInit {

   //General
   public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
   public form: FormGroup = this.fb.group({
     tipo_incidente: new UntypedFormControl(1, []),
   });
    /*tabla*/
    public length: number;
    public pageSize: number;
    public pageSizeOptions: Array<number>;
    public page: number;
    public displayedColumns: any;
    public dataSource: any;
    /*fin tabla*/
    public isLoading: boolean = false;
    public id_empleado: any;
     public laravelapiStorage = this._CapacitacionService.getLaravelApiStorage();
     public laravelapi = this._CapacitacionService.getLaravelApi();
     public catalogo: any;
     public toshi: any = '2022';
  
    constructor(
      private fb: UntypedFormBuilder,
      public  _dialog: MatDialog,
      private _CapacitacionService: CapacitacionService,
      private _router: Router,
      private _route: ActivatedRoute,
    ){ 
       /*tabla*/
      this.length = 100;
      this.pageSize = 25;
      this.pageSizeOptions = [5, 10, 25, 100];
      this.page = 1;
      this.displayedColumns = [
      '_id', 
      '_puestos',
      '_imparte',
      '_fecha',
      '_modificar',
      '_consultar',
      '_eliminar'];
      /*fin tabla*/
    }
  
    ngOnInit(): void {
     /*modificar*/
     //this.form.markAllAsTouched();
     this._route.queryParams.subscribe(
       params => {
         
        //this.id_empleado = +params['id_empleado'];
         console.log(this.id_empleado);
         //if(this.id_empleado){
           //this.getConsultarFormularioIncidentes();
           this.postConsultarCpacitaciones(1);
         //}
       });
     /*fin modificar*/
     
    }
  
    public postConsultarCpacitaciones(page: number){
      this.isLoading = true;
      this.page = page;
      this._CapacitacionService.postConsultarCpacitaciones({page: this.page, filas: this.pageSize}).subscribe(
        response => {console.log(response);
          this.isLoading = false;
          if(response.estado == 200){
            this.length = response.data.total;
            this.dataSource = response.data.data;
          }
        },
        error => {console.log(<any>error);
          this.isLoading = false;
        });
    }
  
    public pageEvent(event: any){
      console.info(event);
      this.pageSize = event.pageSize;
      this.postConsultarCpacitaciones(event.pageIndex + 1);
    }
  
    /*public postRegistrarPreIncidentes(id: number){
      this.isLoading = true;
      this._CapacitacionService.postRegistrarPreIncidentes({numero_empleado: this.id_empleado, tipo_incidente: this.form.value.tipo_incidente}).subscribe(
        response => {console.log(response);
          this.isLoading = false;
          if(response.estado == 200){
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            this.postConsultarCpacitaciones(this.page);
          }else{
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
          }
        },
        error => {console.log(<any>error);
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
          this.isLoading = false;
        });
    }*/
 
 
    /*public getConsultarFormularioIncidentes(){
     this.isLoading = true;
   
     this._CapacitacionService.getConsultarFormularioIncidentes().subscribe(
       response => {console.log(response);
         this.isLoading = false;
         if(response.estado == 200){
          this.catalogo = response.data;
         }
       },
       error => {console.log(<any>error);
         this.isLoading = false;
       });
   }*/
}
