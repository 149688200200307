
<div class="card-container">
    <mat-card >
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <form novalidate [formGroup]="form" autocomplete="off">
                <!-- parte 1 -->
                <span style="text-transform:uppercase"><strong>{{accion}} {{opcion_texto}}</strong></span>
                <div class="row mt-3">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>NOMBRE</mat-label>
                            <input type="text" matInput maxlength="100" #nombre_todo formControlName="nombre_todo">
                            <mat-error *ngIf="form.controls['nombre_todo'].hasError('maxlength')">
                                El máximo tamaño es de 100 caracteres
                            </mat-error>
                            <mat-hint align="end">{{nombre_todo.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">

                    </div>
                </div>
                
            </form>

            <div class="mt-5 d-flex justify-content-center">
				<div *ngIf="form.invalid">
					<span class="text-danger">
						Faltan campos por llenar
					</span>
				</div>
			</div>

            <div class="mt-2 d-flex justify-content-center gap-2">


                

                <button class="flat" color="primary" mat-flat-button [routerLink]="['/catalogo-inventario']" >
                    Regresar
                </button>

                <form novalidate [formGroup]="form" (ngSubmit)="save()" *ngIf="accion == 'registrar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>
                <form novalidate [formGroup]="form" (ngSubmit)="modify()" *ngIf="accion == 'modificar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>


            </div>

        </mat-card-content>
    </mat-card>
</div>