<div class="card-container">
	<mat-card class="example-card">
		<mat-card-header>
			<mat-card-title class="fl">{{accion}} puesto</mat-card-title>
			<mat-card-subtitle></mat-card-subtitle>
		</mat-card-header>

		<mat-card-content>
			<div class="spinner" *ngIf="isLoading">
				<mat-spinner color="accent"></mat-spinner>
			</div>
			<form novalidate [formGroup]="formPuestos">
				<div class="row">
					<mat-form-field appearance="fill" class="input-full-w">
						<mat-label>PUESTO</mat-label>
						<input type="text" matInput maxlength="250" #titulo_puesto formControlName="puesto">
						<mat-hint align="end">{{titulo_puesto.value.length}} / 250</mat-hint>
						<mat-error *ngIf="formPuestos.controls['puesto'].hasError('maxlength')">
							El maximo tamaño es de 250 caracteres
						</mat-error>
					</mat-form-field>
				</div>
			</form>


			<div class="mt-5 d-flex justify-content-center">
				<div *ngIf="formPuestos.invalid">
					<span class="text-danger">
						Faltan campos por llenar
					</span>
				</div>
			</div>

			<div class="d-flex justify-content-center gap-2">

				<button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/gestor-puestos']" >
					Regresar
				</button>

				<form novalidate [formGroup]="formPuestos" *ngIf="accion == 'registrar'" (ngSubmit)="postRegistrarPuestos()">
					<button class="flat" color="primary" mat-flat-button type="submit" [disabled]="formPuestos.invalid">
						Guardar
					</button>
				</form>
				<form novalidate [formGroup]="formPuestos" *ngIf="accion == 'modificar'" (ngSubmit)="postModificarPuestos()">
					<button class="flat" color="primary" mat-flat-button type="submit"  [disabled]="formPuestos.invalid">
						Modificar
					</button>
				</form>

			</div>
		</mat-card-content>
	</mat-card>
</div>
