import { Component, OnInit } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { ActivatedRoute } from '@angular/router';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ParametrosService } from 'src/app/services/parametros.service';
import { Location } from '@angular/common';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';

export interface Nota {
  name: string;
}

@Component({
  selector: 'app-aprueba',
  templateUrl: './aprueba.component.html',
  styleUrls: ['./aprueba.component.scss']
})
export class ApruebaComponent implements OnInit {
  hostapi:string;
  notas = [];
  notas2 = [];
  consideraciones = "";
  autonomiasEmocionales = [];
  autonomiasMotrices = [];
  autonomiasConitivas = [];
  formulario:UntypedFormGroup;
  isLoading = false;
  idProceso:number=0;
  idBeneficiaria:number=0;
  constructor(
    private dialog:MatDialog,
    private _location:Location,
    private _parametrosService:ParametrosService,
    private _beneficiariaService:BeneficiariaService,
    private activatedRoute:ActivatedRoute,
    private _documentosService:DocumentosService,
    private _conf:ConfigurationService
    ) {
      this.hostapi=_conf.getConfiguration().hostapi;
    this.formulario=new UntypedFormGroup({
      fechaIngreso:new UntypedFormControl("",[Validators.required]),
      autonomiaCognitivoSelect:new UntypedFormControl("",[Validators.required]),
      autonomiaCognitivoInput:new UntypedFormControl("",[]),
      autonomiaMotrizSelect:new UntypedFormControl("",[Validators.required]),
      autonomiaMotrizInput:new UntypedFormControl("",[]),
      diagnosticoEmocionalSelect:new UntypedFormControl("",[Validators.required]),
      diagnosticoEmocionalInput:new UntypedFormControl("",[]),

      estudios:new UntypedFormControl("false",[Validators.required]),
      descripcionEstudios:new UntypedFormControl("",[]),
      fechaCierre:new UntypedFormControl(null,[]),
      cierre:new UntypedFormControl("1",[]),
      fechaProlongada:new UntypedFormControl("",[]),
      conclusiones:new UntypedFormControl("",[]),
      oficioRespuestaAprueba: new UntypedFormControl("",[]),
      oficioRespuestaAprueba2: new UntypedFormControl("",[]),


      feecha: new UntypedFormControl("",[]),
      descripcion_noota: new UntypedFormControl("",[]),
    });
    

   }
   aprb_setData(data: any){
    console.log("setData: ",data);
    this.consideraciones = data.consideracionesCandidata;
    /*if(data.fechaIngreso!=null){
      this.formulario.controls.fechaIngreso.setValue(new Date(data.fechaIngreso.date));
    }*/
    if(data.idGradoCognitivo!="0"){
      this.formulario.controls.autonomiaCognitivoSelect.setValue(data.idGradoCognitivo);
    }
    this.formulario.controls.autonomiaCognitivoInput.setValue(data.desGradoCognitivo);
    if(data.idGradoMotriz!="0"){
      this.formulario.controls.autonomiaMotrizSelect.setValue(data.idGradoMotriz);
    }
    this.formulario.controls.autonomiaMotrizInput.setValue(data.desGradoMotriz);
    if(data.idDiagnosticoEmocional!="0"){
      this.formulario.controls.diagnosticoEmocionalSelect.setValue(data.idDiagnosticoEmocional);
    }
    this.formulario.controls.diagnosticoEmocionalInput.setValue(data.desDiagnosticoEmocional);

    // this.notas2 = data.notas;
    // console.log("notas2: ", this.notas2);

    if(data.indEstudios){
      this.formulario.controls.estudios.setValue(data.indEstudios.toString());
    }
    this.formulario.controls.descripcionEstudios.setValue(data.desEstudios);
    if(data.fechaCierre!=null){
      this.formulario.controls.fechaCierre.setValue(new Date(data.fechaCierre.date));
    }
    if(data.decisionAdmision){
      this.formulario.controls.cierre.setValue(data.decisionAdmision);
    }
    
    /*if(data.fechaProlongarPeriodo!=null){
      this.formulario.controls.fechaProlongada.setValue(new Date(data.fechaProlongarPeriodo.date));
    }*/
    this.formulario.controls.conclusiones.setValue(data.conclusiones);

  }
  indEstatus = "";
  ngOnInit(): void {
    this.notas = [];
    this.notas2 = [];

    this.formulario=new UntypedFormGroup({
      fechaIngreso:new UntypedFormControl("",[Validators.required]),
      autonomiaCognitivoSelect:new UntypedFormControl("",[Validators.required]),
      autonomiaCognitivoInput:new UntypedFormControl("",[]),
      autonomiaMotrizSelect:new UntypedFormControl("",[Validators.required]),
      autonomiaMotrizInput:new UntypedFormControl("",[]),
      diagnosticoEmocionalSelect:new UntypedFormControl("",[Validators.required]),
      diagnosticoEmocionalInput:new UntypedFormControl("",[]),

      estudios:new UntypedFormControl("false",[Validators.required]),
      descripcionEstudios:new UntypedFormControl("",[]),
      fechaCierre:new UntypedFormControl(null,[]),
      cierre:new UntypedFormControl("1",[Validators.required]),
      fechaProlongada:new UntypedFormControl("",[]),
      conclusiones:new UntypedFormControl("",[]),
      oficioRespuestaAprueba: new UntypedFormControl("",[]),
      oficioRespuestaAprueba2: new UntypedFormControl("",[]),

      feecha: new UntypedFormControl("",[]),
      descripcion_noota: new UntypedFormControl("",[]),
    });
    this.formulario.controls["fechaIngreso"].disable();
    this.aprb_getCatalogos();
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info(params["fechaAlta"]);
      this.formulario.controls.fechaIngreso.setValue(new Date(params["fechaAlta"]));
      this.idProceso = params["idProceso"];
      this.idBeneficiaria = params["idBeneficiaria"];
      this.isLoading=true;
      this._beneficiariaService.getProcesoAPrueba(this.idProceso).subscribe(result=>{
        this.isLoading=false;
        this.aprb_setData(result.data);
        this.indEstatus = result.data.indEstatus;
        this.notas = result.data.notas;
        this.aprb_getDocumentos(this.idBeneficiaria);
      },()=>{
        this.isLoading=false;
      })
    });
    
  }

  

  aprb_saveFormulario(){
    let data = {
      "tipoEvento": "EVENTO_GUARDAR",
      "etapaActual":"A_PRUEBA",
      "informacion":{
        "idProceso": this.idProceso,
        "fechaIngreso": this.formulario.controls.fechaIngreso.value.toJSON().replace("T"," ").replace(".000Z",""),
        "idGradoCognitivo": this.formulario.controls.autonomiaCognitivoSelect.value,
        "desGradoCognitivo": this.formulario.controls.autonomiaCognitivoInput.value,
        "idGradoMotriz": this.formulario.controls.autonomiaMotrizSelect.value,
        "desGradoMotriz": this.formulario.controls.autonomiaMotrizInput.value,
        "idDiagnosticoEmocional": this.formulario.controls.diagnosticoEmocionalSelect.value,
        "desDiagnosticoEmocional": this.formulario.controls.diagnosticoEmocionalInput.value,
        "indEstudios": this.formulario.controls.estudios.value,
        "decisionAdmision": this.formulario.controls.cierre.value,
        "conclusiones":this.formulario.controls.conclusiones.value,
        "desEstudios": this.formulario.controls.descripcionEstudios.value,
        "fechaCierre": this.formulario.controls.fechaCierre.value ? this.formulario.controls.fechaCierre.value.toJSON().replace("T"," ").replace(".000Z",""):"",
        //"fechaProlongarPeriodo": this.formulario.controls.fechaProlongada.value != null && this.formulario.controls.fechaProlongada.value != "" ? this.formulario.controls.fechaProlongada.value.toJSON().replace("T"," ").replace(".000Z",""):null,
        "notas":[
          {"fechaNota": "2020-12-29 00:00:00","descNota":"Nota Extra"}
        ]        
      }
    };

    data.informacion.notas = [];
    for(let i=0; i<this.notas2.length; i++){
      data.informacion.notas.push({
        "fechaNota" : this.formulario.controls["fechaNota"+i].value.toJSON().replace("T"," ").replace(".000Z",""),
        "descNota" : this.formulario.controls["descripcionNota"+i].value
      });
    }
      
    // for(let i=0; i<this.notas.length;i++){
    //   data.informacion.notas.push(
    //     {"descNota":this.formulario.controls["descripcionNota"+i].value,
    //     "fechaNota" : this.formulario.controls["fechaNota"+i].value.toJSON().replace("T"," ").replace(".000Z","")
    //     }
    //   );
    // }

    console.info(data);
    console.log("ANTES DE ENVIARSE data:" ,data);
    
    this.isLoading = true;
    this._beneficiariaService.updateEtapa(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading = false;
      this.aprb_cargarListaDoc();
    },()=>{
      this.isLoading = false;
    });
  }
  aprb_sendForm(){
    console.info("send");
    if(this.formulario.valid){
      this.aprb_saveFormulario();
    }else{
      console.log("Aun faltan datos por llenar");
      this.dialog.open(MessageBoxComponent,{
        data:{titulo:"Datos incompletos",mensaje:"Aun faltan datos por llenar"}
      })
    }
  }
  arreglo = [];
  aprb_cargarArreglo(tipoDocumento:string,idTipoCatalogo:string,desTipoCatalogo:string,file:File){
    this.arreglo.push({"file":file,"idBeneficiaria":this.idBeneficiaria.toString(),"idTipoCatalogo":idTipoCatalogo,"desTipoCatalogo":desTipoCatalogo,"tipoDocumento":tipoDocumento});
  }
  aprb_cargarListaDoc(){
    return new Promise((resolve, reject) => {

      this.aprb_cargarArreglo("45","","",this.fileToUpload);
      this.aprb_cargarArreglo("46","","",this.fileToUpload2);
      
      let aprb_formData = new FormData();
      aprb_formData.append('contador', "2");
      for(let aprb=0; aprb<this.arreglo.length; aprb++){
        if(aprb==0){
          aprb_formData.append('documento', this.arreglo[aprb].file);
          aprb_formData.append('idBeneficiaria', this.idBeneficiaria.toString());
          aprb_formData.append('idTipoDocumento', this.arreglo[aprb].tipoDocumento);
          aprb_formData.append('idTipoCatalogo', this.arreglo[aprb].idTipoCatalogo);
          aprb_formData.append('desTipoCatalogo', this.arreglo[aprb].desTipoCatalogo);
        }else{
          aprb_formData.append('documento'+aprb, this.arreglo[aprb].file);
          aprb_formData.append('idBeneficiaria'+aprb, this.idBeneficiaria.toString());
          aprb_formData.append('idTipoDocumento'+aprb, this.arreglo[aprb].tipoDocumento);
          aprb_formData.append('idTipoCatalogo'+aprb, this.arreglo[aprb].idTipoCatalogo);
          aprb_formData.append('desTipoCatalogo'+aprb, this.arreglo[aprb].desTipoCatalogo);
        }
      }

      this.isLoading=true;
      this._documentosService.saveMulti(aprb_formData).subscribe(result=>{
        console.info(result);
        this.isLoading=false;
        //this.mensajeDeExito2();
        this.aprb_mensaje2("Etapa actualizada","La etapa se actualizo correctamente");
        this.ngOnInit();
        resolve(true);
      },()=>{
        this.isLoading=false;
        resolve(true);
      })
        
   
      
    });
  }
  aprb_cargarListaDoc2(data){
    return new Promise((resolve, reject) => {

      this.aprb_cargarArreglo("45","","",this.fileToUpload);
      this.aprb_cargarArreglo("46","","",this.fileToUpload2);
      
      let aprb_formData = new FormData();
      aprb_formData.append('contador', "2");
      for(let aprb=0; aprb<this.arreglo.length; aprb++){
        if(aprb==0){
          aprb_formData.append('documento', this.arreglo[aprb].file);
          aprb_formData.append('idBeneficiaria', this.idBeneficiaria.toString());
          aprb_formData.append('idTipoDocumento', this.arreglo[aprb].tipoDocumento);
          aprb_formData.append('idTipoCatalogo', this.arreglo[aprb].idTipoCatalogo);
          aprb_formData.append('desTipoCatalogo', this.arreglo[aprb].desTipoCatalogo);
        }else{
          aprb_formData.append('documento'+aprb, this.arreglo[aprb].file);
          aprb_formData.append('idBeneficiaria'+aprb, this.idBeneficiaria.toString());
          aprb_formData.append('idTipoDocumento'+aprb, this.arreglo[aprb].tipoDocumento);
          aprb_formData.append('idTipoCatalogo'+aprb, this.arreglo[aprb].idTipoCatalogo);
          aprb_formData.append('desTipoCatalogo'+aprb, this.arreglo[aprb].desTipoCatalogo);
        }
      }

      this.isLoading=true;
      this._documentosService.saveMulti(aprb_formData).subscribe(result=>{
        console.info(result);
        this.isLoading=false;

        this.isLoading = true;
        this._beneficiariaService.updateCandidata(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
          this.isLoading = false;
          //Mensaje de éxito
          this.aprb_mensaje2("Etapa actualizada","La etapa se actualizo correctamente");
        },()=>{
          this.isLoading = false;
        })
        resolve(true);
      },()=>{
        this.isLoading=false;
        resolve(true);
      })
        
   
      
    });
  }
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  // notas: any[] = [
    
  // ];

  // notas2: any[] = [
    
  // ];
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.notas.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: Nota): void {
    const index = this.notas.indexOf(fruit);

    if (index >= 0) {
      this.notas.splice(index, 1);
    }
  }

  fileToUpload :File ;
  fileToUpload2 :File ;
  
  aprb_handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.formulario.controls.archivo.setValue(this.fileToUpload.name);
  }
  aprb_handleFileInput2(files: FileList) {
    this.fileToUpload2 = files.item(0);
    this.formulario.controls.archivo.setValue(this.fileToUpload2.name);
  }

  aprb_cierreChange(event){
    console.info(event.value);
    this.formulario.controls.fechaProlongada.setValue(null);
    if(event.value=="2"){
      this.formulario.controls.fechaProlongada.setValidators([Validators.required]);
    }else{
      this.formulario.controls.fechaProlongada.clearValidators();
      
    }
    this.formulario.get('fechaProlongada').updateValueAndValidity();
  }
  
  aprb_getCatalogos(){
    this._parametrosService.getParametros("16").subscribe(result=>{
      console.log(result);
      this.autonomiasConitivas = result.data.parametros;
    });

    this._parametrosService.getParametros("17").subscribe(result=>{
      console.log(result);
      this.autonomiasMotrices = result.data.parametros;
    });

    this._parametrosService.getParametros("18").subscribe(result=>{
      console.log(result);
      this.autonomiasEmocionales = result.data.parametros;
    });
  }
  aprb_cancelar(){
    this._location.back();
  }

  aprb_cerrarEtapa(){
    console.info(this.formulario.controls.fechaCierre.value);
    if(this.formulario.controls.fechaCierre.value){
      if(this.formulario.valid){
        if(this.formulario.controls.cierre.value == "1"){
          this.continuarCerrarEtapa("EVENTO_CERRAR","A_PRUEBA");
          
        }else if(this.formulario.controls.cierre.value == "3"){
          this.continuarCerrarEtapa("EVENTO_EGRESO","A_PRUEBA");
        }
      }
    }else{
      this.dialog.open(MessageBoxComponent,{
        data:{titulo:"Ingrese fecha de cierre",mensaje:"Para cerrar la etapa es necesario completar el campo 'Fecha de cierre'"}
      })
    }
    
  }
  continuarCerrarEtapa(evento,etapaActual){
    let data = {
      "tipoEvento": evento,
      "etapaActual":etapaActual,
      "informacion":{
        "idProceso": parseInt(this.idProceso.toString()),
        "fechaIngreso": this.formulario.controls.fechaIngreso.value.toJSON().replace("T"," ").replace(".000Z",""),
        "idGradoCognitivo": this.formulario.controls.autonomiaCognitivoSelect.value,
        "desGradoCognitivo": this.formulario.controls.autonomiaCognitivoInput.value,
        "idGradoMotriz": this.formulario.controls.autonomiaMotrizSelect.value,
        "desGradoMotriz": this.formulario.controls.autonomiaMotrizInput.value,
        "idDiagnosticoEmocional": this.formulario.controls.diagnosticoEmocionalSelect.value,
        "desDiagnosticoEmocional": this.formulario.controls.diagnosticoEmocionalInput.value,
        "indEstudios": this.formulario.controls.estudios.value,
        "decisionAdmision": this.formulario.controls.cierre.value,
        "conclusiones":this.formulario.controls.conclusiones.value,
        "desEstudios": this.formulario.controls.descripcionEstudios.value,
        "fechaCierre": this.formulario.controls.fechaCierre.value.toJSON().replace("T"," ").replace(".000Z",""),
        //"fechaProlongarPeriodo": this.formulario.controls.fechaProlongada.value != null ? this.formulario.controls.fechaProlongada.value.toJSON().replace("T"," ").replace(".000Z",""):null
      }
    };

    let data2 = {
      "tipoEvento": "EVENTO_GUARDAR",
      "etapaActual":etapaActual,
      "informacion":{
        "idProceso": parseInt(this.idProceso.toString()),
        "fechaIngreso": this.formulario.controls.fechaIngreso.value.toJSON().replace("T"," ").replace(".000Z",""),
        "idGradoCognitivo": this.formulario.controls.autonomiaCognitivoSelect.value,
        "desGradoCognitivo": this.formulario.controls.autonomiaCognitivoInput.value,
        "idGradoMotriz": this.formulario.controls.autonomiaMotrizSelect.value,
        "desGradoMotriz": this.formulario.controls.autonomiaMotrizInput.value,
        "idDiagnosticoEmocional": this.formulario.controls.diagnosticoEmocionalSelect.value,
        "desDiagnosticoEmocional": this.formulario.controls.diagnosticoEmocionalInput.value,
        "indEstudios": this.formulario.controls.estudios.value,
        "decisionAdmision": this.formulario.controls.cierre.value,
        "conclusiones":this.formulario.controls.conclusiones.value,
        "desEstudios": this.formulario.controls.descripcionEstudios.value,
        "fechaCierre": this.formulario.controls.fechaCierre.value.toJSON().replace("T"," ").replace(".000Z",""),
        //"fechaProlongarPeriodo": this.formulario.controls.fechaProlongada.value != null && this.formulario.controls.fechaProlongada.value != "" ? this.formulario.controls.fechaProlongada.value.toJSON().replace("T"," ").replace(".000Z",""):null,
        "notas":[]
      }
    };
    for(let aprb=0; aprb<this.notas.length;aprb++){
      data2.informacion.notas.push(
        {"descNota":this.notas[aprb].name}
      );
    }
    
    console.info(data);

    this.isLoading = true;
    this._beneficiariaService.updateCandidata(this.idBeneficiaria,this.idProceso,data2).subscribe(result=>{
      this.isLoading = false;
      this.aprb_cargarListaDoc2(data);

    },()=>{
      this.isLoading = false;
    })

    
  }
  documentos:any;
  documento1 = null;
  documento2 = null;  
  aprb_getDocumentos(id:number){
    this.isLoading = true;
    this._documentosService.getAllByBeneficiaria(id).subscribe(result=>{
      this.isLoading = false;
      this.documentos = result.data.documentos;

      for(let aprb = 0; aprb< this.documentos.length; aprb++){
        if(this.documentos[aprb].idTipoDocumento == 45){
          this.formulario.controls.oficioRespuestaAprueba.setValue(this.documentos[aprb].idTipoCatalogo);

          this.documento1 = this.documentos[aprb].docTipoCatalogo;
        }else if(this.documentos[aprb].idTipoDocumento == 46){
          this.formulario.controls.oficioRespuestaAprueba2.setValue(this.documentos[aprb].desTipoCatalogo);
          
          this.documento2 = this.documentos[aprb].docTipoCatalogo;
        }
      }
    });
  }

  aprb_mensaje(titulo, mensaje){
    const dialogRef= this.dialog.open(MessageBoxComponent,{
      data:{titulo: titulo ,mensaje: mensaje}
    });
    
  }

  aprb_mensaje2(titulo, mensaje){
    const dialogRef= this.dialog.open(MessageBoxComponent,{
      data:{titulo: titulo ,mensaje: mensaje}
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.aprb_cancelar();
    });
  }

  get form(){
    return this.formulario.controls;
  }

  aprb_agregarNota(){
    // this.formulario.addControl("fechaNota"+(this.notas.length),new FormControl("",[Validators.required]))
    // this.formulario.addControl("descripcionNota"+(this.notas.length),new FormControl("",[]));
    // this.notas.push(1);
    this.formulario.addControl("fechaNota"+(this.notas2.length),new UntypedFormControl("",[Validators.required]))
    this.formulario.addControl("descripcionNota"+(this.notas2.length),new UntypedFormControl("",[]));
    this.notas2.push(1);
  }
  aprb_egresar(){
    this.form.cierre.setValue("3");
    this.aprb_cerrarEtapa();
  }


  public feecha: string = '';
  public descripcion_noota: string = '';
  public agregarNota(){
    this.formulario.addControl("fechaNota"+(this.notas2.length),new UntypedFormControl(this.formulario.controls.feecha.value,[Validators.required]))
    this.formulario.addControl("descripcionNota"+(this.notas2.length),new UntypedFormControl(this.formulario.controls.descripcion_noota.value ,[]));
    this.notas2.push(1);

    this.formulario.patchValue({
      feecha: '',
      descripcion_noota: ''
    });
  }

  public eliminarNoota(i: number){
    console.log(i);
    this.notas2.splice(i, 1)
  }


}
