<div class="container-aux">
  <mat-card class="example-card">
    <img mat-card-image src="/assets/img/logo-goretti.jpg" alt="Logo goretti">
    <form autocomplete="off" [formGroup]="formulario" (ngSubmit)="login()">
      <mat-card-content>
        <span class="subtitle">Iniciar Sesión</span>

        <mat-form-field appearance="standard">
          <mat-label>Usuario</mat-label>
          <input matInput type="text" formControlName="usr">
          <mat-icon matSuffix color="accent">person</mat-icon>
          <!--<mat-hint>Hint</mat-hint>-->
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>Contraseña</mat-label>
          <input matInput type="password" formControlName="pass">
          <mat-icon matSuffix color="accent">lock</mat-icon>
          <!--<mat-hint>Hint</mat-hint>-->
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button mat-flat-button color="accent" type="submit" [disabled]="isLoading">Entrar</button>
        <br *ngIf="isLoading">
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

        <br>
        <a mat-button (click)="olvido()">¿Olvido su contraseña?</a><br><br>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
<!-- Footer -->
<app-footer></app-footer>
<!-- Footer -->