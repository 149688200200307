<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title *ngIf="!esModificacion">Alta de usuarios</mat-card-title>
            <mat-card-title *ngIf="esModificacion">Modificación de usuarios</mat-card-title>
            <mat-card-subtitle>Complete la información solicitada</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="table-container">
                <div class="spinner" *ngIf="isLoading">
                    <mat-spinner color="accent"></mat-spinner>
                </div>
                <form (ngSubmit)="sendForm()" autocomplete="off" [formGroup]="formulario">
                    <mat-form-field appearance="fill" class="bt col-md-4">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="nombre"  #nombre maxlength="100">
                        <mat-hint align="end">{{nombre.value?.length || 0}}/100</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-4">
                        <mat-label>Apellido Paterno</mat-label>
                        <input matInput formControlName="apellidoPaterno"  #apellidoPaterno maxlength="100">
                        <mat-hint align="end">{{apellidoPaterno.value?.length || 0}}/100</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-4">
                        <mat-label>Apellido Materno</mat-label>
                        <input matInput formControlName="apellidoMaterno"  #apellidoMaterno maxlength="100">
                        <mat-hint align="end">{{apellidoMaterno.value?.length || 0}}/100</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-4">
                        <mat-label>Correo electrónico</mat-label>
                        <input matInput formControlName="correo"  #correo maxlength="100">
                        <mat-hint align="end">{{correo.value?.length || 0}}/100</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-4">
                        <mat-label>Puesto</mat-label>
                        <input matInput formControlName="puesto"  #puesto maxlength="100">
                        <mat-hint align="end">{{puesto.value?.length || 0}}/100</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-4">
                        <mat-label>Licencia</mat-label>
                        <mat-select formControlName="idPerfil">
                            <mat-option *ngFor="let perfil of perfiles" value="{{perfil.idPerfil}}">{{perfil.nombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-8">
                        <mat-label>Cedula profesional</mat-label>
                        <input matInput formControlName="cedula"  #cedula maxlength="50">
                        <mat-hint align="end">{{cedula.value?.length || 0}}/50</mat-hint>
                    </mat-form-field>

                    
                    <div>
                        <mat-form-field appearance="fill" class="bt col-md-4">
                            <mat-label>Usuario</mat-label>
                            <input matInput formControlName="username"  #username maxlength="20">
                            <mat-hint align="end">{{username.value?.length || 0}}/20</mat-hint>
                        </mat-form-field>
                        <div>
                            <mat-form-field appearance="fill" class="bt col-md-4" aria-label="passwro">
                                <mat-label>Contraseña</mat-label>
                                <input matInput formControlName="password"  #password maxlength="20" type="password">
                                <mat-icon matSuffix>lock</mat-icon>
                                <mat-hint align="end">{{password.value?.length || 0}}/20</mat-hint>
                                <mat-error *ngIf="password.invalid">{{leyenda_password}}</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="bt col-md-4">
                                <mat-label>Confirmar Contraseña</mat-label>
                                <input matInput formControlName="password2"  #password2 maxlength="20" type="password">
                                <mat-icon matSuffix>lock</mat-icon>
                                <mat-hint align="end">{{password2.value?.length || 0}}/20</mat-hint>
                                <mat-error *ngIf="password2.invalid">{{leyenda_password}}</mat-error>
                            </mat-form-field>
                            <span class="error" *ngIf="password2.value != password.value">
                                <mat-icon>error_outline</mat-icon>&nbsp;Las contraseñas no coinciden
                            </span>
                        </div>
                    </div>
                    <br><br>
                    <p style="text-align: center;">
                        <button mat-flat-button color="primary" class="flat">
                            <mat-icon>save</mat-icon>&nbsp;Guardar
                        </button>
                        &nbsp;
                        <button class="flat" mat-stroked-button color="default" type="button" [routerLink]="['/administrador/usuarios']">
                            <mat-icon>close</mat-icon>&nbsp;Cancelar
                        </button>
                    </p>             
                </form>
            </div>
        </mat-card-content>
    </mat-card>
</div>