
<div class="card-container" id="top">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Gestor de terapias / campañas</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div class="bt col-md-12 text-align-right">
                <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos-generales/gt-terapias-companas/registrar']">
                    Nuevo evento
                </button>
                &nbsp;
                <a  color="accent" class="flat" mat-flat-button  href="{{laravelapi}}/ReporteExcelTerapias" target="_blank" rel=noopener>
                    <mat-icon>list_alt</mat-icon>&nbsp;Registro en Excel
                </a>
                
                <!--<button class="flat" color="primary" mat-flat-button>Comparativo</button>
                    <button class="flat" color="primary" mat-flat-button>Gráfica (Pizza)</button>-->
                </div>

                <!-- tabla -->
                <div class="row mt-3 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="_no_" >
                            <th id="_no_" mat-header-cell *matHeaderCellDef style="width: 120px !important"> ID </th>
                            <td mat-cell *matCellDef="let element">{{element?.id_terapia_pk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_fecha_">
                            <th id="_fecha" mat-header-cell *matHeaderCellDef  style="width: 120px !important"> FECHA DEL EVENTO </th>
                            <td mat-cell *matCellDef="let element">
                                
                                    {{element?.fecha_ingreso}}
                                
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="_nev_">
                            <th id="_nev_" mat-header-cell *matHeaderCellDef> NOMBRE DEL EVENTO </th>
                            <td mat-cell *matCellDef="let element">{{element?.nombre_evento}}

                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_clas_">
                            <th id="_clas_" mat-header-cell *matHeaderCellDef> CLASIFICACIÓN </th>
                            <td mat-cell *matCellDef="let element">{{element?.clasificacion?.clasificacion}}
                                
                            </td>
                        </ng-container>



                        <ng-container matColumnDef="_ev_">
                            <th id="_ev_" mat-header-cell *matHeaderCellDef class="text-center p-1"> TERAPIAS / CAMPAÑAS </th>
                            <td mat-cell *matCellDef="let element" class="text-center p-1">
                                
                                    <button  type="button" mat-mini-fab color="primary"  >
                                        <mat-icon class="material-icons" [routerLink]="['/medico/expedientes-medicos-generales/gt-terapias-companas/modificar']" [queryParams]="{id: element.id_terapia_pk}">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                               
                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_ver_ev_">
                            <th id="_ver_ev_" mat-header-cell *matHeaderCellDef class="text-center p-1"> VER TERAPIAS / CAMPAÑAS </th>
                            <td mat-cell *matCellDef="let element" class="text-center p-1">
                                
                                    <button  type="button" mat-mini-fab color="primary" >
                                        <mat-icon class="material-icons" [routerLink]="['/medico/expedientes-medicos-generales/gt-terapias-companas/consultar']" [queryParams]="{id: element.id_terapia_pk}">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                               
                                
                            </td>
                        </ng-container>



                        <ng-container matColumnDef="_el_">
                            <th id="_el_" mat-header-cell *matHeaderCellDef class="text-center p-1"> ELIMINAR </th>
                            <td mat-cell *matCellDef="let element" class="text-center p-1">
                                
                                    <button *ngIf="tieneAccionEditar; else edit"  type="button" mat-mini-fab color="warn" (click)="postEliminarTerapia(element.id_terapia_pk)">
                                        <mat-icon class="material-icons">
                                            delete
                                        </mat-icon>
                                    </button>
                                    <ng-template #edit>
                                        <button  type="button" mat-mini-fab color="warn" disabled>
                                            <mat-icon class="material-icons">
                                                delete
                                            </mat-icon>
                                        </button>
                                    </ng-template>
                               
                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_imp_">
                            <th id="_imp_" mat-header-cell *matHeaderCellDef class="text-center p-1 "> IMPRIMIR </th>
                            <td mat-cell *matCellDef="let element" class="text-center p-1">
                                
                                    <button  type="button" mat-mini-fab color="warn" >
                                        
                                       <mat-icon class="material-icons">
                                            print
                                        </mat-icon>
                                    </button>
                               
                                
                            </td>
                        </ng-container>

                        <!-- fin contenido -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- fin tabla -->

                
                <!--<div class="mt-5 d-flex justify-content-center">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos']" >
                        Regresar
                    </button>
                </div>-->

                
            </mat-card-content>
            <mat-card-actions align="start">
                <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"  aria-label="Selecione una pagina">
                </mat-paginator>
            </mat-card-actions>
        </mat-card>
    </div>
