//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';
import { SesionService } from 'src/app/services/sesion.service';



@Component({
  selector: 'app-gestor-perfiles-y-descripcion-puestos',
  templateUrl: './gestor-perfiles-y-descripcion-puestos.component.html',
  styleUrls: ['./gestor-perfiles-y-descripcion-puestos.component.scss'],
  providers: [ RrhhService ]
})
export class GestorPerfilesYDescripcionPuestosComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    buscador: new UntypedFormControl('', []),
    ruta: new UntypedFormControl('', []),
  });
  /*tabla*/
  public length: number = 100;;
  public pageSize: number = 25;
  public pageSizeOptions: Array<number> = [5, 10, 25, 100];
  public page: number = 1;
  public displayedColumns: any =[
    'dep', 
    'nombre_dep',
    'tipo_puesto',
    'num_plazas',
    'estatus',
    'modificar',
    //'consultar',
    'eliminar',
    'imprimir'];
  public dataSource: any;
  /*fin tabla*/
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public docs: any;
  public laravelapi: any;
  public laravelapiStorage: any;
  public acciones: any;
  public eliminar: boolean = false;
  public consultar: boolean = false;
  public modificar: boolean = false;
  public api = this._rrhhService.getLaravelApi();

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _rrhhService: RrhhService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _sesionService:SesionService,
  ){ 
    this.getConsultaDescripcionBuscador(1);
    this.getConsultarDocumentosOrg();
    this.laravelapi = this._rrhhService.getLaravelApi();
    this.laravelapiStorage = this._rrhhService.getLaravelApiStorage();
    this.eliminar = this.consultarAccionUsuario("/rrhh/gestor-de-puestos-de-trabajo/eliminar");
    this.consultar = this.consultarAccionUsuario("/rrhh/gestor-de-puestos-de-trabajo/consultar");
    this.modificar = this.consultarAccionUsuario("/rrhh/gestor-de-puestos-de-trabajo/modificar");
  }

  ngOnInit(): void {
  }

  /*permisos*/
  public consultarAccionUsuario(url: any){
    if(this._sesionService.getSession().getUsuario().nombre != undefined){
      this.acciones = this._sesionService.getSession().getAcciones();
      console.log(this.acciones);
      if(this.acciones.filter(x => x.url == url)[0]){
        return true;        
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  /*fin permisos*/

  public getConsultarDocumentosOrg(){
    this.isLoading = true;
    this._rrhhService.getConsultarDocumentosOrg().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.length = response.data.length;
          this.docs = response.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getConsultaDescripcionBuscador(page: number){
    this.isLoading = true;
    this.page = page;
    this._rrhhService.getConsultaDescripcionBuscador(this.page, this.pageSize, this.form.value.buscador).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.pageSize = event.pageSize;
    this.getConsultaDescripcionBuscador(event.pageIndex + 1);
  }


  public postEliminarDescripcion(id: number){
    this.isLoading = true;
    this._rrhhService.postEliminarDescripcion({numero_descripcion: id}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Correct', mensaje: 'Eliminado Correctamente.', icon:'correct'}});
          this.getConsultaDescripcionBuscador(this.page);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el servicio', icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

}
