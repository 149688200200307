import { Accion } from './accion';
import { Usuario } from './usuario';

export class Sesion {
    usuario:Usuario;
    private active:boolean;
    private timeout:Number;
    private acciones:Accion[];
    
    constructor(){
        this.usuario = new Usuario();
    }
    setActive(active:boolean):void{
        this.active = active;
    }
    getActive():boolean{
        return this.active;
    }
    getUsuario():Usuario{
        return this.usuario;
    }
    setUsuario(usuario:Usuario):void{
        this.usuario = usuario;
    }
    
    setTimeOut(timeout:Number):void{
        this.timeout = timeout;
    }
    getTimeout():Number{
        return this.timeout;
    }
    getAcciones():Accion[]{
        return this.acciones;
    }
    setAcciones(acciones:Accion[]):void{
        console.log(acciones);
        this.acciones = acciones;
    }
}
