<app-rrhh-ficha-informativa></app-rrhh-ficha-informativa>


<div class="card-container" id="top">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Incidencias</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>


            <form *ngIf="data?.tipo_incidente?.id_tipo_catalogo_pk == 1" novalidate [formGroup]="form1_ausentismo" autocomplete="off" (ngSubmit)="postRegistrarIncidentes(form1_ausentismo.value)">
                <div class="row">
                    <strong>AUSENTISMO</strong>
                    <div class="col">
                        <div class="row">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>Motivo breve</mat-label>
                                <textarea type="text" matInput  formControlName="motivo_breve" rows="4"></textarea>
                                <mat-error *ngIf="form1_ausentismo.controls['motivo_breve'].hasError('maxLength')">
                                    Excedió el número de caracteres
                                </mat-error>
                                <mat-error *ngIf="form1_ausentismo.controls['motivo_breve'].hasError('required')">
                                    El campo es obigatorio
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Fecha </mat-label>
                            <input type="date" matInput  formControlName="fecha_ausentismo">
                        </mat-form-field>
                    </div>
                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <button *ngIf="accion == 'modificar'" type="button" color="primary"  mat-mini-fab  (click)="postRegistrarFechaIncidentes(form1_ausentismo.value)" >
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </form>

                <div class="row" *ngFor="let f of data?.fechas">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Fecha </mat-label>
                            <input type="date" matInput  [(ngModel)]="f.fecha_ausentismo">
                        </mat-form-field>
                    </div>
                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <button *ngIf="accion == 'modificar'" type="button" color="primary"  mat-flat-button  (click)="postModificarFechaIncidentes({numero_fecha: f.id_fecha_pk, fecha_ausentismo: f.fecha_ausentismo})" >
                            Modificar
                        </button>
                    </div>
                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <button *ngIf="accion == 'modificar'" type="button" color="warn"  mat-flat-button  (click)="postEliminarFechaIncidentes({numero_fecha: f.id_fecha_pk})" >
                            Eliminar
                        </button>
                    </div>
                </div>

            <form *ngIf="data?.tipo_incidente?.id_tipo_catalogo_pk == 1" novalidate [formGroup]="form1_ausentismo" autocomplete="off" (ngSubmit)="postRegistrarIncidentes(form1_ausentismo.value)">
                <div class="row" >
                    <span>Tipo de ausentismo</span>
                    <mat-radio-group aria-label="Tipo_ausentismo" formControlName="numero_tipo_ausentismo">
                        <mat-radio-button *ngFor="let a of cata?.catTipo" [value]="a.id_tipo_ausentismo_pk">{{a?.ausentismo}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="row mt-3" *ngIf="form1_ausentismo.value.numero_tipo_ausentismo == 3">
                    <div class="col">
                        <div  class="input-group">
                            <input  *ngIf="accion == 'modificar'"  type="file"  class="form-control" (change)="postRegModDocumentoIncidentes($event, data?.id_incidente_pk)" >
                            
                        </div>
                    </div>
                    <div class="col">
                        <a *ngIf="data?.ruta_relativa != null"  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{data?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                            <mat-icon class="material-icons">
                                file_open
                            </mat-icon>
                        </a>
                    </div>
                    <div class="col">
                        <button *ngIf="accion == 'modificar'" type="button" color="warn"  mat-flat-button  (click)="postEliminarDocumentoIncidentes(data?.id_incidente_pk)" >
                            Eliminar
                        </button>
                    </div>
                </div>



                <div class="row mt-3">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>Motivo </mat-label>
                                    <textarea rows="2"  type="text" matInput  formControlName="motivo"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                   
                </div>

                <!--<div class="row">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Motivo capacidad</mat-label>
                        <textarea type="text" matInput  formControlName="motivo" rows="4"></textarea>
                        <mat-error *ngIf="form1_ausentismo.controls['motivo'].hasError('maxLength')">
                            Excedió el número de caracteres
                        </mat-error>
                    </mat-form-field>
                </div>-->

                


               

                <div class="mt-5 d-flex justify-content-center gap-2">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-incidencias']" [queryParams]="{ id_empleado: id_numero_empleado}">
                        Regresar
                    </button>
                    <button  *ngIf="accion == 'modificar'"  type="submit" class="flat" color="primary" mat-flat-button  >
                        Guardar
                    </button>
                  
                </div>
            </form>









         


            <form *ngIf="data?.tipo_incidente?.id_tipo_catalogo_pk == 2" novalidate [formGroup]="form2_administrativa" autocomplete="off" (ngSubmit)="postRegistrarIncidentes(form2_administrativa.value)">
                <div class="row">
                    <strong>ACTAS ADMINISTRATIVAS</strong>
                    <div class="col">
                        <div class="row">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>Motivo breve</mat-label>
                                <textarea type="text" matInput  formControlName="motivo_breve" rows="4"></textarea>
                                <mat-error *ngIf="form2_administrativa.controls['motivo_breve'].hasError('maxLength')">
                                    Excedió el número de caracteres
                                </mat-error>
                                <mat-error *ngIf="form2_administrativa.controls['motivo_breve'].hasError('required')">
                                    El campo es obigatorio
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Fecha </mat-label>
                            <input type="date" matInput  formControlName="fecha_acta">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" >
                    <span>Categorías</span>
                    <mat-radio-group aria-label="Tipo_actas" formControlName="numero_tipo_acta">
                        <mat-radio-button *ngFor="let a of cata?.actas" [value]="a.id_actas_pk">{{a?.actas}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <!--<div class="row mt-3">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>Motivo </mat-label>
                                    <textarea rows="2"  type="text" matInput  formControlName="motivo_acta"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                   
                </div>-->

                <div class="row">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Motivo</mat-label>
                        <textarea type="text" matInput  formControlName="motivo" rows="4"></textarea>
                        <mat-error *ngIf="form2_administrativa.controls['motivo'].hasError('maxLength')">
                            Excedió el número de caracteres
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="mt-5 d-flex justify-content-center gap-2">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-incidencias']" [queryParams]="{ id_empleado: id_numero_empleado}">
                        Regresar
                    </button>
                    <button  *ngIf="accion == 'modificar'"   type="submit" class="flat" color="primary" mat-flat-button  >
                        Guardar
                    </button>
                </div>
            </form>












            <form *ngIf="data?.tipo_incidente?.id_tipo_catalogo_pk == 3" novalidate [formGroup]="form3_observaciones" autocomplete="off" (ngSubmit)="postRegistrarIncidentes(form3_observaciones.value)">
                <div class="row">
                    <strong>Bitácoras</strong>
                    <div class="col">
                        <div class="row">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>Motivo breve</mat-label>
                                <textarea type="text" matInput  formControlName="motivo_breve" rows="4"></textarea>
                                <mat-error *ngIf="form3_observaciones.controls['motivo_breve'].hasError('maxLength')">
                                    Excedió el número de caracteres
                                </mat-error>
                                <mat-error *ngIf="form3_observaciones.controls['motivo_breve'].hasError('required')">
                                    El campo es obigatorio
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Fecha </mat-label>
                            <input type="date" matInput  formControlName="fecha_obs">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" >
                    <span>Categorías</span>
                    <mat-radio-group aria-label="Tipo_observacion" formControlName="tipo_observacion">
                        <mat-radio-button *ngFor="let a of cata?.observaciones" [value]="a.id_cat_obs_pk">{{a?.catalogo}}</mat-radio-button>
                        <!--<mat-radio-button [value]="1">Puntualidad</mat-radio-button>
                        <mat-radio-button [value]="2">Disciplina</mat-radio-button>
                        <mat-radio-button [value]="3">Comportamiento</mat-radio-button>-->
                    </mat-radio-group>
                </div>
                <!--<div class="row mt-3">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>Observación </mat-label>
                                    <textarea rows="2"  type="text" matInput  formControlName="observacion"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                   
                </div>-->

                <div class="row">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Motivo</mat-label>
                        <textarea type="text" matInput  formControlName="motivo" rows="4"></textarea>
                        <mat-error *ngIf="form3_observaciones.controls['motivo'].hasError('maxLength')">
                            Excedió el número de caracteres
                        </mat-error>
                    </mat-form-field>
                </div>

              

                <div class="mt-5 d-flex justify-content-center gap-2">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-incidencias']" [queryParams]="{ id_empleado: id_numero_empleado}">
                        Regresar
                    </button>
                    <button *ngIf="accion == 'modificar'"  type="submit" class="flat" color="primary" mat-flat-button  >
                        Guardar
                    </button>
                </div>
            </form>
          

         

           

        </mat-card-content>
    </mat-card>
</div>