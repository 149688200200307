//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { EytService } from 'src/app/services/eyt.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { TratamientosService } from 'src/app/services/tratamientos.service';
//Fin Tabla

@Component({
  selector: 'app-eventos-medicos1',
  templateUrl: './eventos-medicos1.component.html',
  styleUrls: ['./eventos-medicos1.component.scss'],
  providers: [ EytService, TratamientosService ]
})
export class EventosMedicos1Component implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({

    numero_beneficiario: new UntypedFormControl(-1, []),
    fecha_evento_incluida: new UntypedFormControl('', []),
    peso: new UntypedFormControl('', [ Validators.pattern('^\\d{1,3}(\\.\\d{2})?$')]), //OBLIGATORIO ANTES
    talla: new UntypedFormControl('', [ Validators.pattern('^\\d{1,3}(\\.\\d{2})?$')]), //OBLIGATORIO ANTES
    presion_art: new UntypedFormControl('', []),
    presion_art_dos: new UntypedFormControl('', [ Validators.pattern('^\\d{3}/\\d{3}$')]), //OBLIGATORIO ANTES
    sat: new UntypedFormControl('', [ Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]), //OBLIGATORIO ANTES
    cardiaca: new UntypedFormControl('', [ Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]), //OBLIGATORIO ANTES
    frecuencia: new UntypedFormControl('', [ Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]), //OBLIGATORIO ANTES
    temp: new UntypedFormControl('', [ Validators.pattern('^\\d{1,2}(\\.\\d{1})?$')]), //OBLIGATORIO ANTES

    motivos: new UntypedFormControl('', []), //OBLIGATORIO ANTES
    resumen: new UntypedFormControl('', []), //OBLIGATORIO ANTES
    diagnostico: new UntypedFormControl('', []), //OBLIGATORIO ANTES
    vincular_expediente: new UntypedFormControl(true, []),
    numero_boton: new UntypedFormControl(1, [Validators.required]),

    numero_evento_medico: new UntypedFormControl(1, [Validators.required]),


    fecha_evento_cierre: new UntypedFormControl('', []),


    created_at: new UntypedFormControl('', []),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public departamento: any;
  public puesto: any;
  public id: any;
  public id_eve: any;
  public descripcion_puesto: any;
  public especialista_catalogo: any;
  public concluir: any;
  public data: any;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _EytService: EytService,
    private _TratamientosService: TratamientosService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) { 
    
    /*modificar*/
    this.form.markAllAsTouched();
    this._route.queryParams.subscribe(
      params => {
        let url_list = this._router.url.split("/");
        this.accion = url_list[6].split("?")[0];
        console.log(this.accion);
        
        this.id = +params['id'];
        this.id_eve = +params['id_ev'];
        this.concluir = params['concluir'];
        this.form.patchValue({ numero_beneficiario: this.id});
        if(this.id){
          console.log('IDDDD: ' + this.id, this.id_eve);
          
          //this.getDatosClinicosForm();
          this.getConsultaDatosHistorialClinico(this.id_eve);
          this.postConsultaTratamientosInformaciono(this.id_eve);

        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    
  }

  public postConsultaTratamientosInformaciono(id: number){
    console.log('asjsajsdhas');
    this.isLoading = true;
    this._TratamientosService.postConsultaTratamientosInformaciono(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.data = response.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public val(){
    var b = true;
    for(let i = 0; i < this.data?.tratamientos?.length; i++){
      if(this.data.tratamientos[i].id_estatus_medico_fk == 1){
        b = false;
      }
    }
    return b;
  }

  /*

   numero_beneficiario: new UntypedFormControl(-1, []),
    fecha_evento_incluida: new UntypedFormControl('', []),
    peso: new UntypedFormControl('', [ Validators.pattern('^\\d{1,3}(\\.\\d{2})?$')]), //OBLIGATORIO ANTES
    talla: new UntypedFormControl('', [ Validators.pattern('^\\d{1,3}(\\.\\d{2})?$')]), //OBLIGATORIO ANTES
    presion_art: new UntypedFormControl('', []),
    presion_art_dos: new UntypedFormControl('', [ Validators.pattern('^\\d{3}/\\d{3}$')]), //OBLIGATORIO ANTES
    sat: new UntypedFormControl('', [ Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]), //OBLIGATORIO ANTES
    cardiaca: new UntypedFormControl('', [ Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]), //OBLIGATORIO ANTES
    frecuencia: new UntypedFormControl('', [ Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]), //OBLIGATORIO ANTES
    temp: new UntypedFormControl('', [ Validators.pattern('^\\d{1,2}(\\.\\d{1})?$')]), //OBLIGATORIO ANTES

    motivos: new UntypedFormControl('', []), //OBLIGATORIO ANTES
    resumen: new UntypedFormControl('', []), //OBLIGATORIO ANTES
    diagnostico: new UntypedFormControl('', []), //OBLIGATORIO ANTES
    vincular_expediente: new UntypedFormControl(true, []),
    numero_boton: new UntypedFormControl(1, [Validators.required]),

    numero_evento_medico: new UntypedFormControl(1, [Validators.required]),



    created_at: new UntypedFormControl('', []),

  */
  
  public llenarCampos(){
    /*if(this.form.value.peso == ''){
      this.form.patchValue({
        peso: 0
      });
    }

    if(this.form.value.talla == ''){
      this.form.patchValue({
        talla: 0
      });
    }

    if(this.form.value.presion_art == ''){
      this.form.patchValue({
        presion_art: 0
      });
    }

    if(this.form.value.presion_art_dos   == ''){
      this.form.patchValue({
        presion_art_dos: '000/000'
      });
    }

    if(this.form.value.sat == ''){
      this.form.patchValue({
        sat: 0
      });
    }

    if(this.form.value.cardiaca == ''){
      this.form.patchValue({
        cardiaca: 0
      });
    }

    if(this.form.value.frecuencia == ''){
      this.form.patchValue({
        frecuencia: 0
      });
    }

    if(this.form.value.temp == ''){
      this.form.patchValue({
        temp: 0
      });
    }*/

    if(this.form.value.motivos == ''){
      this.form.patchValue({
        motivos: '-'
      });
    }

    if(this.form.value.resumen == ''){
      this.form.patchValue({
        resumen: '-'
      });
    }

    if(this.form.value.diagnostico == ''){
      this.form.patchValue({
        diagnostico: '-'
      });
    }


  }

  public postRegistroEventoMedico(){
    this.llenarCampos();
    this.form.patchValue({
      vincular_expediente: +this.form.value.vincular_expediente,
      presion_art: +(this.form.value.peso / (this.form.value.talla * this.form.value.talla))?.toFixed(4),
      //fecha_evento_incluida: this.form.value.fecha_evento_incluida.split('T')[0]+this.form.value.fecha_evento_incluida.split('T')[1]
    });
    /*if(isNaN(this.form.value.presion_art) || !isFinite(this.form.value.presion_art)){
      console.log('es naaan');
      this.form.patchValue({
        presion_art: 0
      });
    }*/
    console.log(this.form.value);
    this.isLoading = true;
    this._EytService.postRegistroEventoMedico(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          //this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.postModificarEventoMedico(1);
          this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/eventos/modificar'], { queryParams: { id: this.id, id_ev: response.data.id_evento_medico_pk}});

          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt'], { queryParams: { id: this.id}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public getConsultaDatosHistorialClinico(id: number){
    console.log('asjsajsdhas');
    this.isLoading = true;
    this._EytService.getConsultaEventoMedico(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.setForm(response.data);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public setForm(data: any){
    if(data.fecha_evento_incluida != null){
      data.fecha_evento_incluida = data.fecha_evento_incluida.replace(" ", "T");
    }

    
    this.form.patchValue({
      peso: data.peso,
      talla: data.talla,
      presion_art: data.presion_art,
      presion_art_dos: data.presion_art_dos,
      sat: data.sat,
      cardiaca: data.cardiaca,
      frecuencia: data.frecuencia,
      temp: data.temp,

      motivos: data.motivos,
      resumen: data.resumen,
      diagnostico: data.diagnostico,
      vincular_expediente: data.vincular_expediente,
      numero_boton: data.id_estado_boton_fk,
      created_at: data.created_at,
      numero_evento_medico: data.id_evento_medico_pk,
      fecha_evento_incluida: data.fecha_evento_incluida,
      fecha_evento_cierre: data.fecha_evento_cierre
    });
    console.log(data);
  }




  public postModificarEventoMedico(st: number){
    this.llenarCampos();
    var x = st;
    this.form.patchValue({
      vincular_expediente: +this.form.value.vincular_expediente,
      numero_boton: st,
      presion_art: +(this.form.value.peso / (this.form.value.talla * this.form.value.talla))?.toFixed(4),
      //fecha_evento_incluida: this.form.value.fecha_evento_incluida.split('T')[0]+' '+this.form.value.fecha_evento_incluida.split('T')[1]
    });
    if(isNaN(this.form.value.presion_art) || !isFinite(this.form.value.presion_art)){
      console.log('es naaan');
      this.form.patchValue({
        presion_art: 0
      });
    }
    console.log(this.form.value);
    this.isLoading = true;
    this._EytService.postModificarEventoMedico(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          if(st == 3){
            this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/tratamientos/registrar'], { queryParams: { id: this.id, id_ev: this.id_eve}});
          }
        }else{
          this.form.patchValue({
            numero_boton: x
          });
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.form.patchValue({
          numero_boton: x
        });
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }


}
