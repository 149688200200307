//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { TerCampService } from 'src/app/services/ter-camp.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//Fin Tabla


@Component({
  selector: 'app-gt2-ter-camp',
  templateUrl: './gt2-ter-camp.component.html',
  styleUrls: ['./gt2-ter-camp.component.scss'],
  providers: [ TerCampService ]
})
export class Gt2TerCampComponent implements OnInit {

  /*tabla*/
  public length: number;
  public pageSize: number;
  public pageSizeOptions: Array<number>;
  public page: number;
  public displayedColumns: any;
  public dataSource: any;
  /*fin tabla*/
  public isLoading: boolean = false;
  public form: UntypedFormGroup = this.fb.group({
    buscador: new UntypedFormControl('', []),
  });
  public id: number = -1;
  public laravelapiStorage = this._TerCampService.getLaravelApiStorage();
  public laravelapi = this._TerCampService.getLaravelApi();

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _TerCampService: TerCampService,
    private _router: Router,
    private _route: ActivatedRoute,
  ){ 
     /*tabla*/
    this.length = 100;
    this.pageSize = 25;
    this.pageSizeOptions = [5, 10, 25, 100];
    this.page = 1;
    this.displayedColumns = [
    '_id_', 
    '_f_',
    '_eve_',
    '_c_',
    '_consultar',
    '_imprimir_'];
    /*fin tabla*/

    /*modificar*/
    
    this._route.queryParams.subscribe(
      params => {
        this.id = +params['id'];
        if(this.id){
          console.log('IDDDD: ' + this.id);
          this.form.patchValue({ numero_terapia: this.id});

          
          this.getConsultarTerapiaBeneficiario(1);
          
          //this.getDatosClinicosForm();

          
          

        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    
  }

  public getConsultarTerapiaBeneficiario(page: number){
    this.isLoading = true;
    this.page = page;
    this._TerCampService.getConsultarTerapiaBeneficiario(this.page, this.pageSize, this.form.value.buscador, this.id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.length = response.data.total;
          this.dataSource = response.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.pageSize = event.pageSize;
    this.getConsultarTerapiaBeneficiario(event.pageIndex + 1);
  }

}
