import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-almacen-de-medicamentos',
  templateUrl: './almacen-de-medicamentos.component.html',
  styleUrls: ['./almacen-de-medicamentos.component.scss']
})
export class AlmacenDeMedicamentosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
