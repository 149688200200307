//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { PtService } from 'src/app/services/pt.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//Fin Tabla

@Component({
  selector: 'app-gt-control-grupal-peso-talla',
  templateUrl: './gt-control-grupal-peso-talla.component.html',
  styleUrls: ['./gt-control-grupal-peso-talla.component.scss'],
  providers: [ PtService ]
})
export class GtControlGrupalPesoTallaComponent implements OnInit {

  /*tabla*/
  public length: number;
  public pageSize: number;
  public pageSizeOptions: Array<number>;
  public page: number;
  public displayedColumns: any;
  public dataSource: any;
  /*fin tabla*/
  public isLoading: boolean = false;
  public id: number = -1;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _PtService: PtService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) { 
     /*tabla*/
     this.length = 100;
     this.pageSize = 25;
     this.pageSizeOptions = [5, 10, 25, 100];
     this.page = 1;
     this.displayedColumns = [
      '_id',
      '_prd',
      '_etd',
      '_edt',
      '_cst',
      '_elmn'];
     /*fin tabla*/
 
     /*modificar*/
     this._route.queryParams.subscribe(
       params => {
         //this.id = +params['id'];
         //if(this.id){
         //  console.log('ID: ' + this.id);
        this.getConsultarEventosPeriodos(1);
           //this.getConsultaEventoMedico(this.id);
         //}
       });
     /*fin modificar*/
  }

  ngOnInit(): void {
  }

  public getConsultarEventosPeriodos(page: number){
    this.isLoading = true;
    this.page = page;
    this._PtService.getConsultarEventosPeriodos(this.page, this.pageSize).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.pageSize = event.pageSize;
    this.getConsultarEventosPeriodos(event.pageIndex + 1);
  }

}
