import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { MatCardActions } from '@angular/material/card';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ClinicoService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

  public getDatosClinicosForm():any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/DatosClinicosForm");
  }

  public postRegistroHistorialClinicoUno(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistroHistorialClinicoUno", datos, {});
  }

  public postRegistroHistorialClinicoDos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistroHistorialClinicoDos", datos, {});
  }

  public getConsultaDatosHistorialClinico(id: any):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultaDatosHistorialClinico?numero_beneficiario="+id);
  }

  public getConsultaBeneficiariosMedicos(p: number, r: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultaBeneficiariosMedicos?filas="+r+'&page='+p, {});
  }

  //arrays c2
  public postRegistroBeneficiarioEspecialista(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registroBeneficiarioEspecialista", datos, {});
  }

  public postModificarBeneficiarioEspecialista(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarBeneficiarioEspecialista", datos, {});
  }

  public postEliminarBeneficiarioEspecialista(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/eliminarBeneficiarioEspecialista", datos, {});
  }
  //arrays c1
  public postRegistroAntecedentesPatologicos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registroAntecedentesPatologicos", datos, {});
  }

  public postModificarAntecedentesPatologicos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarAntecedentesPatologicos", datos, {});
  }

  public postEliminarAntecedentesPatologicos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/eliminarAntecedentesPatologicos", datos, {});
  }
  //NO
  public postRegistroAntecedentesNoPatologicos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registroAntecedentesNoPatologicos", datos, {});
  }

  public postModificarAntecedentesNoPatologicos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarAntecedentesNoPatologicos", datos, {});
  }

  public postEliminarAntecedentesNoPatologicos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/eliminarAntecedentesNoPatologicos", datos, {});
  }
  //personales
  public postRegistroAntecedentesPersonalesPatologicos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registroAntecedentesPersonalesPatologicos", datos, {});
  }

  public postModificarAntecedentesPersonalesPatologicos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarAntecedentesPersonalesPatologicos", datos, {});
  }

  public postEliminarAntecedentesPersonalesPatologicos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/eliminarAntecedentesPersonalesPatologicos", datos, {});
  }
  //host
  public postRegistroHospitalizacionPato(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registroHospitalizacionPato", datos, {});
  }

  public postModificarHospitalizacionPato(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarHospitalizacionPato", datos, {});
  }

  public postEliminarHospitalizacionPato(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/eliminarHospitalizacionPato", datos, {});
  }



  

  


}