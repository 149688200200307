//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//Fin Tabla

@Component({
  selector: 'app-gestor-rrhh-puestos',
  templateUrl: './gestor-rrhh-puestos.component.html',
  styleUrls: ['./gestor-rrhh-puestos.component.scss'],
  providers: [ RrhhService ]
})
export class GestorRrhhPuestosComponent implements OnInit {

  /*tabla*/
  public length: number;
  public pageSize: number;
  public pageSizeOptions: Array<number>;
  public page: number;
  public displayedColumns: any;
  public dataSource: any;
  /*fin tabla*/
  public isLoading: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _rrhhService: RrhhService,
    private _router: Router,
    private _route: ActivatedRoute,
  ){ 
     /*tabla*/
    this.length = 100;
    this.pageSize = 25;
    this.pageSizeOptions = [5, 10, 25, 100];
    this.page = 1;
    this.displayedColumns = [
    '_id', 
    '_puestos',
    '_modificar',
    '_eliminar'];
    /*fin tabla*/
  }

  ngOnInit(): void {
    this.getConsultarPuestos(1);
  }

  public getConsultarPuestos(page: number){
    this.isLoading = true;
    this.page = page;
    this._rrhhService.getConsultarPuestos(this.page, this.pageSize).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.pageSize = event.pageSize;
    this.getConsultarPuestos(event.pageIndex + 1);
  }

  public postEliminarPuesto(id: number){
    this.isLoading = true;
    this._rrhhService.postEliminarPuesto({numero_puesto: id}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultarPuestos(this.page);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

}
