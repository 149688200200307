import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {
  endpoint:string;
  constructor(private _http:HttpClient,private _config:ConfigurationService) {
    this.endpoint = _config.getConfiguration().hostapi + _config.getConfiguration().endpoints.parametros.path;
   }

   getParametros(tipo:string):Observable<ResponseGenerico>{
     return this._http.get<ResponseGenerico>(this.endpoint+"/tipo/"+tipo);
   }

}
