//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { MedicoService } from 'src/app/services/medico.service';
import { SesionService } from 'src/app/services/sesion.service';



@Component({
  selector: 'app-acciones-alm-inventario',
  templateUrl: './acciones-alm-inventario.component.html',
  styleUrls: ['./acciones-alm-inventario.component.scss'],
  providers: [ MedicoService ]
})
export class AccionesAlmInventarioComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({

    numero_medicamento: new UntypedFormControl(-1, []),
    numero_clasificacion: new UntypedFormControl('', [Validators.required]),
    marca: new UntypedFormControl('', [Validators.maxLength(100), Validators.required]),
    nombre_medicamento: new UntypedFormControl('', [Validators.maxLength(100), Validators.required]),
    presentacion: new UntypedFormControl('', [Validators.maxLength(100), Validators.required]),
    formula: new UntypedFormControl('', [Validators.maxLength(100)]),
    gramaje: new UntypedFormControl('', [Validators.maxLength(100)]),
    inventario_inicial: new UntypedFormControl(0, [Validators.pattern("^[0-9]*$")]),
  });
  //All
  public isLoading: boolean = false;
  public id_med: number = -1;
  public file: any;
  public ruta: string = '';
  public accion: string = '';

  public clasificacion: any;
  public laravelapistorage: any;

  constructor(
    public  _dialog: MatDialog,
    private _medicoServiceEn: MedicoService,
    private _router: Router,
    private _route: ActivatedRoute,  
    private fb: UntypedFormBuilder,
  ){ 
    this.getConsultaDatosFormInvMedico();
    this.laravelapistorage = this._medicoServiceEn.getLaravelApiStorage();
    this.form.markAllAsTouched();
    /*modificar*/
    this._route.queryParams.subscribe(
      params => {
        this.id_med = +params['id_med'];
        if(this.id_med){
          console.log('ID: ' + this.id_med);
          this.form.patchValue({ numero_medicamento: this.id_med})
          this.getConsultaMedicamentoInv(this.id_med);
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[4].split("?")[0];
    console.log(this.accion);
  }

  /*default*/
  public getConsultaDatosFormInvMedico(){
    this.isLoading = true;
    this._medicoServiceEn.getConsultaDatosFormInvMedico().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.clasificacion = response.data;
        } 
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }
  /*fin default*/

  public onchangeArchivo_en(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      var caso = this.sizeFile_en(event);
      if (caso != 3) {
        if(caso == 1){
          this.file = file;
        }
        console.log(this.file);
        if(caso == 2){
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Maximo tamaño de la imagen 4MB.', icon:'error'}});
        }
      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato.', icon:'error'}});

      }
    }
  }

  public sizeFile_en(event: any){
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(file);
      //file.type.startsWith('application/pdf')
      if(file.type.startsWith('image/png') || file.type.startsWith('image/jpeg') || file.type.startsWith('image/jpg')){
        if(event.target.files[0].size <= 4194304){
          console.log('Maximo tamaño de imagen 4MB');
          return 1;
        }else{
          return 2;
        }
      }else{
        return 3;
      }
    }else{
      return 4;
    }
  }

  /*modificar*/
  public getConsultaMedicamentoInv(id: number){
    this.isLoading = true;
    this._medicoServiceEn.getConsultaMedicamentoInv(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.setFormIncremento(response.data);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public setFormIncremento(data: any){
    this.form.patchValue({
      numero_medicamento: data.id_medicamentos_pk,
      numero_clasificacion: data.id_clasificacion_fk,
      marca: data.marca,
      nombre_medicamento: data.nombre_medicamento,
      presentacion: data.presentacion,
      formula: data.formula,
      gramaje: data.gramaje,
      inventario_inicial: data.inventario_inicial,
    });

    this.ruta = data.ruta_relativa;
  }
  /*fin modificar*/


  public postRegistroInvMedico(){
    console.log(this.form.value);
    if(this.form.value.cedula_profesional == ''){
      this.form.patchValue({ cedula_profesional: '-'});
    }
    this.isLoading = true;
    var formData = this.packFormData();
    this._medicoServiceEn.postRegistroInvMedico(formData).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this._router.navigate(['medico/gestor-contactos-area-medica/modificar'], 
            { queryParams:
              {id_cam: response.data.id_area_medica_pk}
            });
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postModificarInvMedico(){
    console.log(this.form.value);
    if(this.form.value.cedula_profesional == ''){
      this.form.patchValue({ cedula_profesional: '-'});
    }
    this.isLoading = true;
    var formData = this.packFormData();
    this._medicoServiceEn.postModificarInvMedico(formData).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this._router.navigate(['medico/almacen-de-medicamentos/gestor-inventario']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public packFormData(){
    const formData = new FormData();
    formData.set('numero_medicamento', this.form.value.numero_medicamento);
    formData.set('numero_clasificacion', this.form.value.numero_clasificacion);
    formData.set('marca', this.form.value.marca);
    formData.set('nombre_medicamento', this.form.value.nombre_medicamento);
    formData.set('presentacion', this.form.value.presentacion);
    formData.set('formula', this.form.value.formula);
    formData.set('gramaje', this.form.value.gramaje);
    formData.set('inventario_inicial', this.form.value.inventario_inicial);
    if(this.file){
      formData.set('foto', this.file);
    }
    return formData;
  }


  /*operaciones*/
  public guardar(){
    console.log(this.form.value);
    this.postRegistroInvMedico();
  }

  public modificar(){
    console.log(this.form.value);
    this.postModificarInvMedico();
  }
  /*fin operaciones*/

}


