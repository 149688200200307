import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DesitionBoxComponent } from 'src/app/modals/desition-box/desition-box.component';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { Paginador } from 'src/app/model/configuracion';
import { Usuario } from 'src/app/model/usuario';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ParametrosService } from 'src/app/services/parametros.service';
import { PerfilesService } from 'src/app/services/perfiles.service';
import { UsuariosService } from 'src/app/services/usuarios.service';

const ELEMENT_DATA: Usuario[] = [];
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  isLoading = false;
  paginador:Paginador;
  displayedColumns: string[] = ['position','nombre','puesto','licencia','usuario','email','editar','eliminar'];
  dataSource = new MatTableDataSource<Usuario>(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  perfiles : any;
  formulario = new UntypedFormGroup({
    nombre:new UntypedFormControl("",[]),
    perfil:new UntypedFormControl("",[]),
    email: new UntypedFormControl("",[])
  })
  
  constructor(
    public dialog: MatDialog,
    private _usuariosService:UsuariosService,
    private _config:ConfigurationService,
    private _perfilesService:PerfilesService
  ) {
    this.paginador = this._config.getConfiguration().ui.paginador;
   }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this._perfilesService.getCatalogoPerfiles().subscribe(result=>{
      if(result.codigo = "PRF000"){
        this.perfiles = result.data;
      }else{
        this.perfiles = [];
      }
    });
    this.buscar(1,this.paginador.pageSize);
  }

  buscar(page:number,size:number){
    this.isLoading = true;
    this._usuariosService.findAll(this.formulario.controls["nombre"].value,this.formulario.controls["email"].value,this.formulario.controls["perfil"].value,page,size).subscribe(result=>{
      if(result.codigo == "USR000"){
        this.dataSource = new MatTableDataSource<Usuario>(result.data[0].usuarios);
        this.paginator.length = result.data[0].total;
      }else{
        this.dataSource = new MatTableDataSource<Usuario>([]);
        this.paginator.pageIndex = 0;
        this.paginator.length = 0;
      }
      this.isLoading = false;
    },()=>{
      this.isLoading = false;
      this.dataSource = new MatTableDataSource<Usuario>(ELEMENT_DATA);
    });
    //this.paginator.pageIndex = page -1;
  }
  sendForm(){

    this.paginator.pageIndex = 0;
    this.buscar(this.paginator.pageIndex+1,this.paginator.pageSize);
  }

  eliminar(username:string){
    const dialogRef = this.dialog.open(DesitionBoxComponent,{
      data: {titulo: "¿Desea eliminar el usuario?", mensaje: "La información del usuario '"+username+"' será eliminada de manera permanente, ¿Desea continuar?", desicion:true}
    })

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if(result){
        this._usuariosService.deleteByUsername(username).subscribe(resultado=>{
          console.info(resultado);
          this.paginator.pageIndex = 0;
          this.buscar(this.paginator.pageIndex+1,this.paginator.pageSize);
          this.dialog.open(MessageBoxComponent,{
            data: {titulo:"Usuario eliminado", mensaje:"El usuario '"+username+"' ha sido eliminado del sistema"}
          })
        })
      }
    });

  }
  pager(event){
    this.paginator.pageSize = event.pageSize;
    this.paginator.pageIndex = event.pageIndex;
    this.buscar(event.pageIndex + 1 ,event.pageSize);
  }
}