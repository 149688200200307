import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rrhh-expediente-documentos',
  templateUrl: './rrhh-expediente-documentos.component.html',
  styleUrls: ['./rrhh-expediente-documentos.component.scss']
})
export class RrhhExpedienteDocumentosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
