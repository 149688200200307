//General
import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { ClinicoService } from 'src/app/services/clinico.service';


@Component({
  selector: 'app-historial-clinico1',
  templateUrl: './historial-clinico1.component.html',
  styleUrls: ['./historial-clinico1.component.scss'],
  providers: [ ClinicoService ]
})
export class HistorialClinico1Component implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    numero_beneficiario: new UntypedFormControl(-1, []),
    id_bene_fk: new UntypedFormControl(-1, []),
    lugar_nacimiento: new UntypedFormControl('', [Validators.required, Validators.maxLength(200)]),
    actividad_fisica: new UntypedFormControl('', [Validators.required]),
    actividad_espe: new UntypedFormControl('', [ Validators.maxLength(2500)]),
    ante_drogas: new UntypedFormControl('', [Validators.required]),
    drogas_espe: new UntypedFormControl('', [Validators.maxLength(2500)]),
    control_desin: new UntypedFormControl('', [Validators.required]),
    control_espe: new UntypedFormControl('', [ Validators.maxLength(2500)]),
    ortesis: new UntypedFormControl('', []),
    protesis: new UntypedFormControl(1, []),
    protesis1: new UntypedFormControl('', []),
    protesis2: new UntypedFormControl('', []),
    protesis3: new UntypedFormControl('', []),
    protesis4: new UntypedFormControl('', []),


    veces_alimentos: new UntypedFormControl(0, [Validators.required, Validators.maxLength(500)]),
    restringe_alimentos: new UntypedFormControl('', [Validators.required]),
    restringe_espe: new UntypedFormControl('', [ Validators.maxLength(2500)]),
    vacunas: new UntypedFormControl('', [Validators.required]),
    vacunas_text: new UntypedFormControl('', [ Validators.maxLength(2500)]),
    
    concilia_sueno: new UntypedFormControl('', [Validators.required, Validators.maxLength(2500)]),
    socializacion: new UntypedFormControl('', [Validators.required, Validators.maxLength(2500)]),
    estereotipo: new UntypedFormControl('', [Validators.required, Validators.maxLength(2500)]),
    juegos: new UntypedFormControl('', [Validators.required, Validators.maxLength(2500)]),
    hitos: new UntypedFormControl('', [Validators.required, Validators.maxLength(2500)]),
    perinatales: new UntypedFormControl('', [Validators.required, Validators.maxLength(2500)]),
    menarca: new UntypedFormControl('', [Validators.required]),
    ciclo_regular: new UntypedFormControl('', [Validators.required]),
    dias: new UntypedFormControl(0, [Validators.required, Validators.pattern("^[0-9]*$")]),
    //toallas: new UntypedFormControl(0, [Validators.required, Validators.pattern("^[0-9]*$")]),
    vida_sexual: new UntypedFormControl('', [Validators.required]),
    embarazada: new UntypedFormControl('', []),
    numero_embarazos: new UntypedFormControl(0, [ Validators.pattern("^[0-9]*$")]),

    //aux

    ante_pato_fami_edo: new UntypedFormControl('', [Validators.required]),
    ante_pato_no_fami_edo: new UntypedFormControl('', [Validators.required]),
    hos_cirugia_edo: new UntypedFormControl('', [Validators.required]),
    num_toallas_mod: new UntypedFormControl('', [Validators.required]),
    toallas_text: new UntypedFormControl('', [ Validators.maxLength(250), Validators.required]),




    numero_antecedente: new UntypedFormControl('', []),
    familiar: new UntypedFormControl('', []),
    padres: new UntypedFormControl('', []),
    madre: new UntypedFormControl('', []),
    padre: new UntypedFormControl('', []),
    hermanos: new UntypedFormControl('', []),
    otros: new UntypedFormControl('', []),
    especifique: new UntypedFormControl('', []),
    especifique_diagnistico: new UntypedFormControl('', []),
    espe_diagnostico: new UntypedFormControl('', []),
    consumes_drogas: new UntypedFormControl(1, []),
    
    //
    conoce_ante_per_pato: new UntypedFormControl(1, [Validators.required]), //esperando a roque
    numero_personal_patologico: new UntypedFormControl('', []),
    numero_hospital: new UntypedFormControl('', []),
    fecha: new UntypedFormControl('', []),
    especifique_nota: new UntypedFormControl('', []),



  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  @Input() selectedIndex : number = 0;
  @Output() siguienteEvento = new EventEmitter<string>();
  public departamento: any;
  public puesto: any;
  public id: any;
  public descripcion_puesto: any;

  public antecedentes_patologicos: any;
  public antecedentes_personales_patologicos: any;
  public hospitalizacion_cirugia: any;


  //carga
  public l_antecedentes_personales_patologicos: any;
  public l_antecedentes_patologicos: any;
  public l_antecedentes_no_patologicos: any;
  public l_hospitalizacion_clinica: any;


  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _ClinicoService: ClinicoService,
    private _router: Router,
    private _route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) { 
    /*modificar*/
    this.form.markAllAsTouched();
    this._route.queryParams.subscribe(
      params => {
        this.id = +params['id'];
        if(this.id){
          console.log('IDDDD: ' + this.id);
          this.form.patchValue({ numero_beneficiario: this.id, id_bene_fk: this.id});
          this.getConsultaDatosHistorialClinico(this.id);
          this.getDatosClinicosForm();

          
          

        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[4].split("?")[0];
    console.log(this.accion);
  }


  //guardar
  public setProtesis(){
    var c = '';
    if(this.form.value.protesis1 == true){
      c = c + '1/';
    }
    if(this.form.value.protesia2 == true){
      c = c + '2/';
    }
    if(this.form.value.protesis3 == true){
      c = c + '3/';
    }
    if(this.form.value.protesis4 == true){
      c = c + '4/';
    }
    this.form.patchValue({protesis:c});
  }

  public setVaciosCampos(){
    if(this.form.value.actividad_espe == ''){
      this.form.patchValue({actividad_espe: '-'});
    }
    if(this.form.value.drogas_espe == ''){
      this.form.patchValue({drogas_espe: '-'});
    }
    if(this.form.value.control_espe == ''){
      this.form.patchValue({control_espe: '-'});
    }
    if(this.form.value.actividad_espe == ''){
      this.form.patchValue({actividad_espe: '-'});
    }
    if(this.form.value.restringe_espe == ''){
      this.form.patchValue({restringe_espe: '-'});
    }
    if(this.form.value.vacunas_text == ''){
      this.form.patchValue({vacunas_text: '-'});
    }




   

  }

  /*public postRegistroHistorialClinicoUno(caso: number = 0){
    //this.setProtesis();
    this.setVaciosCampos();
    console.log(this.form.value);
    this.isLoading = true;
    //this.form.value.puesto_reporta = this.form.value.puesto_reporta.toString();
    //this.form.value.numero_departamento = this.form.value.numero_departamento.toString();
    //this.form.value.numero_puesto = this.form.value.numero_puesto.toString();
    this._ClinicoService.postRegistroHistorialClinicoUno(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          if(caso == 0){
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
          }
          
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }*/
  async postRegistroHistorialClinicoUno(caso: number = 0){
    //this.setProtesis();
    this.setVaciosCampos();
    console.log(this.form.value);
    this.isLoading = true;
    //this.form.value.puesto_reporta = this.form.value.puesto_reporta.toString();
    //this.form.value.numero_departamento = this.form.value.numero_departamento.toString();
    //this.form.value.numero_puesto = this.form.value.numero_puesto.toString();
    try {
        const response = await new Promise<any>((resolve, reject) => {
            this._ClinicoService.postRegistroHistorialClinicoUno(this.form.value).subscribe(
              response => resolve(response),
              error => reject(error)
            );
        });
        console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          if(caso == 0){
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
          }
        }else{
          if(caso == 0){
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
          }
          
        }
    } catch (error) {
        console.log(error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
    }
}

  
  //fin guardar

  public getConsultaDatosHistorialClinico(id: number, general: number = 1){
    console.log('asjsajsdhas');
    this.isLoading = true;
    this._ClinicoService.getConsultaDatosHistorialClinico(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          //this.descripcion_puesto = response.data;
          this.l_antecedentes_personales_patologicos = response.data.antecedentes_personales_patologicos;
          this.l_antecedentes_patologicos = response.data.antecedentes_patologicos;
          this.l_antecedentes_no_patologicos = response.data.antecedentes_no_patologicos;
          this.l_hospitalizacion_clinica = response.data.hospitalizacion_clinica;
          if(general == 1){
            if(response?.data?.historial_clinico){
              this.setForm(response?.data?.historial_clinico);
            }
          }
          

          

          
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public setForm(data: any){
    this.form.patchValue({
      lugar_nacimiento: data.lugar_nacimiento,
      actividad_fisica: data.actividad_fisica,
      actividad_espe: data.actividad_espe,
      ante_drogas: data.ante_drogas,
      drogas_espe: data.drogas_espe,
      control_desin: data.control_desin,
      control_espe: data.control_espe,
      ortesis: data.ortesis,
      protesis: data.protesis,


      veces_alimentos: data.veces_alimentos?.toString(),
      restringe_alimentos: data.restringe_alimentos,
      restringe_espe: data.restringe_espe,
      vacunas: data.vacunas,
      vacunas_text: data.vacunas_text,
      
      concilia_sueno: data.concilia_sueno,
      socializacion: data.socializacion,
      estereotipo: data.estereotipo,
      juegos: data.juegos,
      hitos: data.hitos,
      perinatales: data.perinatales,
      menarca: data.menarca,
      ciclo_regular: data.ciclo_regular,
      dias: data.dias,
      //toallas: data.toallas,
      vida_sexual: data.vida_sexual,
      embarazada: data.embarazada,
      numero_embarazos: data.numero_embarazos,
      conoce_ante_per_pato: data.conoce_ante_per_pato,

      ante_pato_fami_edo:  data.ante_pato_fami_edo,
      ante_pato_no_fami_edo:  data.ante_pato_no_fami_edo,
      hos_cirugia_edo:  data.hos_cirugia_edo,
      num_toallas_mod:  data.num_toallas_mod,
      toallas_text: data.toallas_text


    });
  }


  public getDatosClinicosForm(){
    console.log('asjsajsdhas');
    this.isLoading = true;
    this._ClinicoService.getDatosClinicosForm().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.antecedentes_patologicos = response.data.antecedentes_patologicos;
          this.antecedentes_personales_patologicos = response.data.antecedentes_personales_patologicos;
          this.hospitalizacion_cirugia = response.data.hospitalizacion_cirugia;
          //this.descripcion_puesto = response.data;
          this.antecedentes_patologicos = this.ordenarAlfabeticamente(this.antecedentes_patologicos, 'nombre');
          this.antecedentes_patologicos = this.otrosAlfinal(this.antecedentes_patologicos, 'nombre');

          this.antecedentes_personales_patologicos = this.ordenarAlfabeticamente(this.antecedentes_personales_patologicos, 'antecedente');
          this.antecedentes_personales_patologicos = this.otrosAlfinal(this.antecedentes_personales_patologicos, 'antecedente');

          response.data.especialista_catalogo = this.ordenarAlfabeticamente(response.data.especialista_catalogo, 'especialista');
          response.data.especialista_catalogo = this.otrosAlfinal(response.data.especialista_catalogo, 'especialista');

          this.hospitalizacion_cirugia = this.ordenarAlfabeticamente(this.hospitalizacion_cirugia, 'hospitalizacion');
          this.hospitalizacion_cirugia = this.otrosAlfinal(this.hospitalizacion_cirugia, 'hospitalizacion');

          //this.changeDetectorRef.detectChanges();
          
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  

  public ordenarAlfabeticamente(jsonArray, propiedad) {
    var posicionOtros = -1;
    for (var i = 0; i < jsonArray.length; i++) {
        if (jsonArray[i][propiedad] === "Otros:") {
            posicionOtros = i;
            break;
        }
    }
    if (posicionOtros != -1) {
        var elementoOtros = jsonArray.splice(posicionOtros, 1)[0];
    }
    jsonArray = jsonArray.sort(function(a, b) {
        var nameA = a[propiedad].toUpperCase(); // ignore upper and lowercase
        var nameB = b[propiedad].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    if (posicionOtros != -1) {
        jsonArray.unshift(elementoOtros);
    }
    return jsonArray;
}

public otrosAlfinal(jsonArray, propiedad) {
  var elementoOtros = jsonArray.filter(function(elemento) {
    return elemento[propiedad].toLowerCase().includes("otro")
});
var jsonArrayFiltrado = jsonArray.filter(function(elemento) {
    return !elemento[propiedad].toLowerCase().includes("otro")
});
jsonArrayFiltrado = jsonArrayFiltrado.sort(function(a, b) {
    var nameA = a[propiedad].toUpperCase(); // ignore upper and lowercase
    var nameB = b[propiedad].toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
});
jsonArrayFiltrado = jsonArrayFiltrado.concat(elementoOtros);
return jsonArrayFiltrado;
}





  public setFamilia(){
    var c = '';
    if(this.form.value.madre == true){
      c = c + '1/';
    }
    if(this.form.value.padre == true){
      c = c + '2/';
    }
    if(this.form.value.hermanos == true){
      c = c + '3/';
    }
    if(this.form.value.otros == true){
      c = c + '4/';
    }
    this.form.patchValue({padres:c});
  }




  //arrays
  //pats
  public async postRegistroAntecedentesPatologicos(){
    //this.setFamilia();
    await this.postRegistroHistorialClinicoUno(1);
    console.log(this.form.value);
    this.isLoading = true;
    this._ClinicoService.postRegistroAntecedentesPatologicos({
      numero_antecedente: this.form.value.numero_antecedente,
      familiar: this.form.value.familiar,
      numero_beneficiario: this.form.value.numero_beneficiario,
      especifique: this.form.value.especifique,
      especifique_diagnistico: this.form.value.especifique_diagnistico,

      //numero_antecedente: this.form.value.numero_antecedente,
      padres: this.form.value.padres,
      //numero_beneficiario: this.form.value.numero_beneficiario,
      //especifique: this.form.value.especifique,
      //especifique_diagnistico: this.form.value.especifique_diagnistico,
      //consumes_drogas: this.form.value.consumes_drogas
        
      }).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id, 2);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
        this.clearAux();
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
        this.clearAux();
      });
  }

  public async postModificarAntecedentesPatologicos(id0: number, id1: number, id2: number, esp: string, esp2: string, id4: number){
    
    await this.postRegistroHistorialClinicoUno(1);
    console.log(this.form.value);
    this.isLoading = true;
    this._ClinicoService.postModificarAntecedentesPatologicos({
      /*numero_beneficiario: id1,
      numero_personal_patologico: id2, 
      especifique: esp,
      numero_antecedente_pat: id3*/
      numero_antecedente: id0,
      familiar: id1,
      numero_beneficiario: id2,
      especifique: esp,
      especifique_diagnistico: esp2,
      numero_atencedente_patologico_reg: id4
        
      }).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id, 2);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public async postEliminarAntecedentesPatologicos(num_esp_reg: number, id2:number){
    //this.setProtesis();
    await this.postRegistroHistorialClinicoUno(1);
    console.log(this.form.value);
    this.isLoading = true;
    //this.form.value.puesto_reporta = this.form.value.puesto_reporta.toString();
    //this.form.value.numero_departamento = this.form.value.numero_departamento.toString();
    //this.form.value.numero_puesto = this.form.value.numero_puesto.toString();
    //this.form.patchValue({ numero_especialista: this.form.value.numero_especialista });
    this._ClinicoService.postEliminarAntecedentesPatologicos({numero_atencedente_patologico_reg: num_esp_reg, numero_beneficiario: id2}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({ numero_especialista: '' });
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id, 2);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  //no pats
  public async postRegistroAntecedentesNoPatologicos(){
    //this.setFamilia();
    await this.postRegistroHistorialClinicoUno(1);
    console.log(this.form.value);
    this.isLoading = true;

    if(this.form.value.consumes_drogas == 2){
      this.form.patchValue({
        numero_antecedente: 1,
        padres: '1',
        especifique: '-',
        espe_diagnostico: '-',
        consumes_drogas: 2
      });
    }

    this._ClinicoService.postRegistroAntecedentesNoPatologicos({
      numero_antecedente: this.form.value.numero_antecedente,
      padres: this.form.value.padres,
      numero_beneficiario: this.form.value.numero_beneficiario,
      especifique: this.form.value.especifique,
      espe_diagnostico: this.form.value.espe_diagnostico,
      consumes_drogas: this.form.value.consumes_drogas
        
      }).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id, 2);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
        this.clearAux();
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
        this.clearAux();
      });
  }

  public async postModificarAntecedentesNoPatologicos(id1: number, id2: number, esp: string, esp2: string, id3: number, id4: number){
    
    await this.postRegistroHistorialClinicoUno(1);
    console.log(this.form.value);
    this.isLoading = true;
    this._ClinicoService.postModificarAntecedentesNoPatologicos({
      /*numero_beneficiario: id1,
      numero_personal_patologico: id2, 
      especifique: esp,
      numero_antecedente_pat: id3*/

      padres: id1,
      numero_beneficiario: id2,
      especifique: esp,
      espe_diagnostico: esp2,
      consumes_drogas: id3,
      numero_atencedente_no_patologico_reg: id4
        
      }).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id, 2);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public async postEliminarAntecedentesNoPatologicos(num_esp_reg: number){
    //this.setProtesis();
    await this.postRegistroHistorialClinicoUno(1);
    this.clearAux();
    console.log(this.form.value);
    this.isLoading = true;
    //this.form.value.puesto_reporta = this.form.value.puesto_reporta.toString();
    //this.form.value.numero_departamento = this.form.value.numero_departamento.toString();
    //this.form.value.numero_puesto = this.form.value.numero_puesto.toString();
    //this.form.patchValue({ numero_especialista: this.form.value.numero_especialista });
    this._ClinicoService.postEliminarAntecedentesNoPatologicos({numero_atencedente_no_patologico_reg: num_esp_reg}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({ numero_especialista: '' });
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id, 2);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  //personales
  public async postRegistroAntecedentesPersonalesPatologicos(){
    await this.postRegistroHistorialClinicoUno(1);
    console.log(this.form.value);
    this.isLoading = true;
    this._ClinicoService.postRegistroAntecedentesPersonalesPatologicos({
      numero_personal_patologico: this.form.value.numero_personal_patologico,
      //numero_antecedente_pat: this.form.value.numero_antecedente_pat,
      numero_beneficiario: this.form.value.numero_beneficiario,
      especifique: this.form.value.especifique,
      especifique_nota: this.form.value.especifique_nota,
        
      }).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id, 2);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
        this.clearAux();
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
        this.clearAux();
      });
  }

  public async postModificarAntecedentesPersonalesPatologicos(id1: number, id2: number, esp: string, id3: number, esp_nota: string){
    
    await this.postRegistroHistorialClinicoUno(1);
    console.log(this.form.value);
    this.isLoading = true;
    this._ClinicoService.postModificarAntecedentesPersonalesPatologicos({
      numero_beneficiario: id1,
      numero_personal_patologico: id2, 
      especifique: esp,
      numero_antecedente_pat: id3,
      especifique_nota: esp_nota
        
      }).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id, 2);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public async postEliminarAntecedentesPersonalesPatologicos(num_esp_reg: number){
    //this.setProtesis();
    
    await this.postRegistroHistorialClinicoUno(1);
    console.log(this.form.value);
    this.isLoading = true;
    //this.form.value.puesto_reporta = this.form.value.puesto_reporta.toString();
    //this.form.value.numero_departamento = this.form.value.numero_departamento.toString();
    //this.form.value.numero_puesto = this.form.value.numero_puesto.toString();
    //this.form.patchValue({ numero_especialista: this.form.value.numero_especialista });
    this._ClinicoService.postEliminarAntecedentesPersonalesPatologicos({numero_antecedente_pat: num_esp_reg}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({ numero_especialista: '' });
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id, 2);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  //host
  public async postRegistroHospitalizacionPato(){
    
    await this.postRegistroHistorialClinicoUno(1);
    console.log(this.form.value);
    this.isLoading = true;
    this._ClinicoService.postRegistroHospitalizacionPato({
      numero_hospital: this.form.value.numero_hospital,
      //numero_antecedente_pat: this.form.value.numero_antecedente_pat,
      numero_beneficiario: this.form.value.numero_beneficiario,
      especifique: this.form.value.especifique,
      fecha: this.form.value.fecha,
        
      }).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id, 2);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
        this.clearAux();
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
        this.clearAux();
      });
  }

  public async postModificarHospitalizacionPato(id1: number, id2: number, esp: string, id3: number, f: string){
    
    await this.postRegistroHistorialClinicoUno(1);
    console.log(this.form.value);
    this.isLoading = true;
    this._ClinicoService.postModificarHospitalizacionPato({
      numero_beneficiario: id1,
      numero_hospital: id2, 
      especifique: esp,
      numero_id_hospitalizacion: id3,
      fecha: f,
        
      }).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id, 2);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public async postEliminarHospitalizacionPato(num_esp_reg: number){
    //this.setProtesis();
    await this.postRegistroHistorialClinicoUno(1);
    console.log(this.form.value);
    this.isLoading = true;
    //this.form.value.puesto_reporta = this.form.value.puesto_reporta.toString();
    //this.form.value.numero_departamento = this.form.value.numero_departamento.toString();
    //this.form.value.numero_puesto = this.form.value.numero_puesto.toString();
    //this.form.patchValue({ numero_especialista: this.form.value.numero_especialista });
    this._ClinicoService.postEliminarHospitalizacionPato({numero_id_hospitalizacion: num_esp_reg}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({ numero_especialista: '' });
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id, 2);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  
  public clearAux(){ 
    this.form.patchValue({
      numero_antecedente: '',
      familiar: '',
      padres: '',
      madre: '',
      padre: '',
      hermanos: '',
      otros: '',
      especifique: '',
      especifique_diagnistico: '',
      consumes_drogas: '',
      

      numero_personal_patologico: '',
      numero_hospital: '',
      fecha: '',
      especifique_nota: '',
      espe_diagnostico: ''
    })
  }

  public auxSec:number = 1;
  public clearSeccion(i: number){
    if(this.auxSec != i){
      this.auxSec = i;
      this.clearAux();
    }
  }






}
