<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Inventario de medicamentos</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
        
    
        <form [formGroup]="form" (ngSubmit)="getConsultaMedicamentosInv(1)" autocomplete="off">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>Buscador</mat-label>
                        <input matInput formControlName="buscador">
                    </mat-form-field>

                </div>
                <div class="col">
                    <button mat-mini-fab type="submit">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <!--<div class="col">
                    <a rel="noopener" *ngIf="form.value.ruta" mat-mini-fab href="{{laravelapiStorage}}/{{form.value.ruta}}" target="_blank">
                        <mat-icon>download</mat-icon>
                    </a>
                </div>-->
                <div class="col">
                    <a class="flat" color="accent" mat-flat-button href="{{laravelapi}}/ExcelMedicamentosInv" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon> Reporte de excel
                    </a>
                </div>
                <div class="col mt-1">
                   <button type="button" class="flat" color="primary" mat-flat-button [routerLink]="['/medico/almacen-de-medicamentos/gestor-inventario/registrar']" >
                    <mat-icon>add</mat-icon> Alta medicamento
                </button>
            </div>
        </div>
    </form>
    



    <!-- tabla -->
    <div class="row mt-3 mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <caption><!--Parrilla--></caption>
            <!-- contenido -->

            <ng-container matColumnDef="gai_foto">
                <th id="gai_foto" mat-header-cell *matHeaderCellDef> FOTO </th>
                <td mat-cell *matCellDef="let element">
                    &nbsp;
                    <img src="{{laravelapistorage+'/'+element.ruta_relativa+'?t='+t}}" alt="..." width="25px" height="25px">
                    &nbsp;
                </td>
            </ng-container>

            <ng-container matColumnDef="gai_tipo">
                <th id="gai_tipo" mat-header-cell *matHeaderCellDef> TIPO </th>
                <td mat-cell *matCellDef="let element">&nbsp;{{element?.clasificacion_medicamento?.clasificacion}}&nbsp;</td>
            </ng-container>

            <ng-container matColumnDef="gai_nombre_razon_social">
                <th id="gai_nombre_razon_social" mat-header-cell *matHeaderCellDef> NOMBRE / RAZÓN SOCIAL </th>
                <td mat-cell *matCellDef="let element">&nbsp;{{element?.razon_social}}&nbsp;</td>
            </ng-container>

            <ng-container matColumnDef="gai_nombre">
                <th id="gai_nombre" mat-header-cell *matHeaderCellDef> NOMBRE </th>
                <td mat-cell *matCellDef="let element">&nbsp;{{element?.nombre_medicamento}}&nbsp;</td>
            </ng-container>

            <ng-container matColumnDef="gai_presentacion">
                <th id="gai_presentacion" mat-header-cell *matHeaderCellDef> PRESENTACIÓN </th>
                <td mat-cell *matCellDef="let element">&nbsp;{{element?.presentacion}}&nbsp;</td>
            </ng-container>

            <ng-container matColumnDef="gai_inventario">
                <th id="gai_inventario" mat-header-cell *matHeaderCellDef> INVENTARIO </th>
                <td mat-cell *matCellDef="let element">&nbsp;{{element?.inventario_inicial}}&nbsp;</td>
            </ng-container>

            <ng-container matColumnDef="gai_consultar">
                <th id="gai_consultar" mat-header-cell *matHeaderCellDef> CONSULTAR </th>
                <td mat-cell *matCellDef="let element">&nbsp;
                    <button type="button" mat-mini-fab color="primary" *ngIf="gai_consultar;else elseConsultar">
                        <mat-icon class="material-icons" [routerLink]="['/medico/almacen-de-medicamentos/gestor-inventario/consultar']" [queryParams]="{id_med:element.id_medicamentos_pk}">
                            open_in_new
                        </mat-icon>
                    </button>
                    <ng-template #elseConsultar>
                        <button type="button" mat-mini-fab color="primary" disabled>
                            <mat-icon class="material-icons">
                                open_in_new
                            </mat-icon>
                        </button>
                    </ng-template>
                    &nbsp;
                </td>
            </ng-container>

            <ng-container matColumnDef="gai_modificar">
                <th id="gai_modificar" mat-header-cell *matHeaderCellDef> MODIFICAR </th>
                <td mat-cell *matCellDef="let element">&nbsp;
                    <button type="button" mat-mini-fab color="primary" *ngIf="gai_modificar; else elseModificar">
                        <mat-icon class="material-icons" [routerLink]="['/medico/almacen-de-medicamentos/gestor-inventario/modificar']" [queryParams]="{id_med:element.id_medicamentos_pk}">
                            open_in_new
                        </mat-icon>
                    </button>
                    <ng-template #elseModificar>
                        <button type="button" mat-mini-fab color="primary" disabled>
                            <mat-icon class="material-icons">
                                open_in_new
                            </mat-icon>
                        </button>
                    </ng-template>
                    &nbsp;
                </td>
            </ng-container>

            <ng-container matColumnDef="gai_eliminar">
                <th id="gai_eliminar" mat-header-cell *matHeaderCellDef> ELIMINAR </th>
                <td mat-cell *matCellDef="let element">&nbsp;
                    <button type="button" mat-mini-fab color="warn" (click)="getEliminarMedicamentosInv(element.id_medicamentos_pk)" *ngIf="gai_eliminar && element?.inventario_inicial > 0; else elseEliminar">
                        <mat-icon class="material-icons">
                            delete
                        </mat-icon>
                    </button>
                    <ng-template #elseEliminar>
                        <button type="button" mat-mini-fab color="warn" disabled>
                            <mat-icon class="material-icons">
                                delete
                            </mat-icon>
                        </button>
                    </ng-template>
                    &nbsp;
                </td>
            </ng-container>

            <!-- fin contenido -->
            <tr mat-header-row *matHeaderRowDef="gai_displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: gai_displayedColumns;"></tr>
        </table>
    </div>
    <!-- fin tabla -->


</mat-card-content>
<mat-card-actions align="start">
    <mat-paginator (page)="pageEvent($event)" [length]="gai_length" [pageSize]="gai_pageSize" [pageSizeOptions]="gai_pageSizeOptions"  aria-label="Selecione una pagina">
    </mat-paginator>
</mat-card-actions>
</mat-card>
</div>