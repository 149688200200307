import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ForgottenPasswordComponent } from 'src/app/modals/forgotten-password/forgotten-password.component';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { Sesion } from 'src/app/model/sesion';
import { SesionService } from 'src/app/services/sesion.service';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  title = "Usuario y/o contraseña incorrectos ";
  text = "Por favor, verifique su usuario y contraseña y vuelva a intentar.";
  icon = "error_outline";
  isLoading=false;
  constructor(
    private router:Router, 
    private _users:UsuariosService,
    private _session:SesionService,
    private dialog:MatDialog
    ) { }
  formulario = new UntypedFormGroup({
    usr:new UntypedFormControl("",[Validators.required]),
    pass:new UntypedFormControl("",[Validators.required])
  });
  ngOnInit(): void {
    // This is intentional
  }
  login(){
    if(this.formulario.valid){
      this.isLoading = true;
      let username = this.formulario.controls["usr"].value;
      let password = this.formulario.controls["pass"].value;
      this._users.login(username,password).subscribe(results=>{
        if(results.codigo=="USR000"){
          let session:Sesion = new Sesion();
          session.setUsuario(results.data.usuario);
          // session.setAcciones(results.data.acciones);
          this._users.getPrivilegios(session.getUsuario().idUsuario).subscribe($privilegios =>{
            session.setAcciones($privilegios.data);
            this._session.sessionCreate(session,true);
            this.isLoading = false;
            this.router.navigate(['/principal']);
          });
        }else{
          this.isLoading = false;
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: this.title, text: this.text, icon:this.icon, href:"/"}});
        }

      },()=>{
        this.isLoading = false;
      });
    }
  }
  olvido(){
    const dialogRef = this.dialog.open(ForgottenPasswordComponent,{data:{desition:true}});

    

  }
}
