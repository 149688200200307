<div class="card-container">
    <mat-card >
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <form novalidate [formGroup]="form" autocomplete="off">
                <!-- parte 1 -->
                <span><strong>I. INFORMACIÓN GENERAL</strong></span>
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>NOMBRE / RAZON SOCIAL</mat-label>
                            <input type="text" matInput  maxlength="250" #razon_social formControlName="razon_social">
                            <mat-error *ngIf="form.controls['razon_social'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['razon_social'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{razon_social.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>CLASIFICACIÓN</mat-label>
                            <mat-select formControlName="numero_clasificacion">
                                <mat-option *ngFor="let p of clasificacion" [value]="p.id_clasificacion_pk">{{p.clasificacion}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.controls['numero_clasificacion'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="form.value.numero_clasificacion == 1">
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>ESPECIALIDAD / SOLO MÉDICOS</mat-label>
                            <mat-select formControlName="numero_especialidad">
                                <mat-option *ngFor="let p of especialidad" [value]="p.id_especialidad_pk">{{p.especialidad}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.controls['numero_especialidad'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col" *ngIf="form.value.numero_especialidad == 15">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>ESPECIFIQUE</mat-label>
                            <input type="text" matInput maxlength="250" #otros_especialidad formControlName="otros_especialidad">
                            <mat-error *ngIf="form.controls['otros_especialidad'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{otros_especialidad.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>CÉDULA PROFESIONAL</mat-label>
                            <input type="text" matInput maxlength="250" #cedula_profesional formControlName="cedula_profesional">
                            <mat-error *ngIf="form.controls['cedula_profesional'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{cedula_profesional.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <span>HONORARIOS</span>
                        <mat-radio-group formControlName="honorarios">
                            <div class="row mt-1 text-justificado">
                                <p> <mat-radio-button [value]="1" ></mat-radio-button> SÍ </p>
                                <p> <mat-radio-button [value]="2" ></mat-radio-button> NO </p>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div class="col" *ngIf="form.value.honorarios == '1'">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>ESPECIFIQUE</mat-label>
                            <input type="text" matInput maxlength="250" #honorarios_si formControlName="honorarios_si">
                            <mat-error *ngIf="form.controls['honorarios_si'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{honorarios_si.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>TELÉFONO FIJO</mat-label>
                            <input type="text" matInput maxlength="10" #telefono formControlName="telefono">
                            <mat-error *ngIf="form.controls['telefono'].hasError('maxlength')">
                                El máximo tamaño es de 10 caracteres
                            </mat-error>
                            <mat-hint align="end">{{telefono.value.length}} / 10</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>CELULAR</mat-label>
                            <input type="text" matInput maxlength="10" #celular formControlName="celular">
                            <mat-error *ngIf="form.controls['celular'].hasError('maxlength')">
                                El máximo tamaño es de 10 caracteres
                            </mat-error>
                            <mat-hint align="end">{{celular.value.length}} / 10</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>CORREO ELECTRÓNICO</mat-label>
                            <input type="email" matInput  maxlength="60" #correo_electronico formControlName="correo">
                            <mat-error *ngIf="form.controls['correo'].hasError('email')">
                                Inválido formato
                            </mat-error>
                            <mat-hint align="end">{{correo_electronico.value.length}} / 60</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>CONSULTORIO</mat-label>
                            <input type="text" matInput maxlength="250" #consultorio formControlName="consultorio">
                            <mat-error *ngIf="form.controls['consultorio'].hasError('maxlength')">
                                El máximo tamaño es de 10 caracteres
                            </mat-error>
                            <mat-hint align="end">{{consultorio.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
               




                <!--<div class="row">
                    
                    <div class="col">
                        <div class="row">
                            <span>HORARIOS DE ATENCIÓN:</span>
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>DE: </mat-label>
                                    <input type="time" matInput formControlName="hora_inicio">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>A: </mat-label>
                                    <input type="time" matInput formControlName="hora_fin">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>-->


                <div class="row mt-3">
                    <span><strong>DÍAS Y HORARIOS DE ATENCIÓN</strong></span>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                       
                            <table class="table table-sm table-bordered">
                                <caption></caption>
                                <thead class="web-bg-primario">
                                    <tr>
                                        <th  scope="col">L</th>
                                        <th  scope="col">M</th>
                                        <th  scope="col">M</th>
                                        <th  scope="col">J</th>
                                        <th  scope="col">V</th>
                                        <th  scope="col">S</th>
                                        <th  scope="col">D</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><mat-checkbox formControlName="ate_lu"></mat-checkbox></td>
                                        <td><mat-checkbox formControlName="ate_mar"></mat-checkbox></td>
                                        <td><mat-checkbox formControlName="ate_mier"></mat-checkbox></td>
                                        <td><mat-checkbox formControlName="ate_jue"></mat-checkbox></td>
                                        <td><mat-checkbox formControlName="ate_vier"></mat-checkbox></td>
                                        <td><mat-checkbox formControlName="ate_sa"></mat-checkbox></td>
                                        <td><mat-checkbox formControlName="ate_do"></mat-checkbox></td>
                                    </tr>
                                </tbody>
                            </table>
                        
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w mt-3">
                            <mat-label>DE: </mat-label>
                            <input type="time" matInput formControlName="hora_inicio">
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w mt-3">
                            <mat-label>A: </mat-label>
                            <input type="time" matInput formControlName="hora_fin">
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>OBSERVACIONES</mat-label>
                            <textarea type="text" matInput maxlength="250" #ate_observacion formControlName="ate_observacion"></textarea>
                            <mat-error *ngIf="form.controls['ate_observacion'].hasError('maxlength')">
                                El máximo tamaño es de 10 caracteres
                            </mat-error>
                            <mat-hint align="end">{{ate_observacion.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                </div>


                <!-- parte 2 -->
                <span><strong>II. DIRECCIÓN</strong></span>
                <div class="row">
                    <span>DIRECCIÓN 1:</span>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>HOSPITAL/NOMBRE DEL LUGAR/UBICACIÓN</mat-label>
                            <textarea type="text" matInput maxlength="250" #direccion formControlName="direccion"></textarea>
                            <mat-error *ngIf="form.controls['direccion'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{direccion.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>CALLE</mat-label>
                            <textarea  type="text" matInput maxlength="250" #calle formControlName="calle"></textarea>
                            <mat-error *ngIf="form.controls['calle'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{calle.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>COLONIA</mat-label>
                            <textarea  type="text" matInput maxlength="250" #colonia formControlName="colonia"></textarea>
                            <mat-error *ngIf="form.controls['colonia'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{colonia.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>MUNICIPIO</mat-label>
                            <textarea type="text" matInput maxlength="250" #municipio formControlName="municipio"></textarea>
                            <mat-error *ngIf="form.controls['municipio'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{municipio.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>ESTADO</mat-label>
                            <textarea type="text" matInput maxlength="250" #estado formControlName="estado"></textarea>
                            <mat-error *ngIf="form.controls['estado'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{estado.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                </div>


                <div class="row">
                    <span>DIRECCIÓN 2:</span>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>HOSPITAL/NOMBRE DEL LUGAR/UBICACIÓN</mat-label>
                            <textarea type="text" matInput maxlength="250" #direccion_dos formControlName="direccion_dos"></textarea>
                            <mat-error *ngIf="form.controls['direccion_dos'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{direccion_dos.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>CALLE</mat-label>
                            <textarea  type="text" matInput maxlength="250" #calle_dos formControlName="calle_dos"></textarea>
                            <mat-error *ngIf="form.controls['calle_dos'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{calle_dos.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>COLONIA</mat-label>
                            <textarea  type="text" matInput maxlength="250" #colonia_dos formControlName="colonia_dos"></textarea>
                            <mat-error *ngIf="form.controls['colonia_dos'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{colonia_dos.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>MUNICIPIO</mat-label>
                            <textarea type="text" matInput maxlength="250" #municipio_dos formControlName="municipio_dos"></textarea>
                            <mat-error *ngIf="form.controls['municipio_dos'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{municipio_dos.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>ESTADO</mat-label>
                            <textarea type="text" matInput maxlength="250" #estado_dos formControlName="estado_dos"></textarea>
                            <mat-error *ngIf="form.controls['estado_dos'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{estado_dos.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                </div>


                <div class="row">
                    <span>DIRECCIÓN 3:</span>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>HOSPITAL/NOMBRE DEL LUGAR/UBICACIÓN</mat-label>
                            <textarea type="text" matInput maxlength="250" #direccion_tres formControlName="direccion_tres"></textarea>
                            <mat-error *ngIf="form.controls['direccion_tres'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{direccion_tres.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>CALLE</mat-label>
                            <textarea type="text" matInput maxlength="250" #calle_tres formControlName="calle_tres"></textarea>
                            <mat-error *ngIf="form.controls['calle_tres'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{calle_tres.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>COLONIA</mat-label>
                            <textarea type="text" matInput maxlength="250" #colonia_tres formControlName="colonia_tres"></textarea>
                            <mat-error *ngIf="form.controls['colonia_tres'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{colonia_tres.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>MUNICIPIO</mat-label>
                            <textarea type="text" matInput maxlength="250" #municipio_tres formControlName="municipio_tres"></textarea>
                            <mat-error *ngIf="form.controls['municipio_tres'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{municipio_tres.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>ESTADO</mat-label>
                            <textarea type="text" matInput maxlength="250" #estado_tres formControlName="estado_tres"></textarea>
                            <mat-error *ngIf="form.controls['estado_tres'].hasError('maxlength')">
                                El máximo tamaño es de 250 caracteres
                            </mat-error>
                            <mat-hint align="end">{{estado_tres.value.length}} / 250</mat-hint>
                        </mat-form-field>
                    </div>
                </div>


                <!-- parte 3 -->
                <span><strong>III. ADICIONALES</strong></span>
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>OBSERVACIONES</mat-label>
                            <textarea matInput type="text" rows="3"  maxlength="2000" #observacion formControlName="observacion"></textarea>
                            <mat-error *ngIf="form.controls['observacion'].hasError('maxlength')">
                                El maximo tamaño es de 2000 caracteres
                            </mat-error>
                            <mat-hint align="end">{{observacion.value.length}} / 2000</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>CALIFICACIÓN</mat-label>
                            <mat-select formControlName="numero_calificacion">
                                <mat-option *ngFor="let p of calificacion" [value]="p.id_cali_medica_pk">{{p.calificacion}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.controls['numero_calificacion'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

            </form>

            <div class="mt-2 d-flex justify-content-center gap-2">


                

                <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/gestor-contactos-area-medica']" >
                    Regresar
                </button>

                <form novalidate [formGroup]="form" (ngSubmit)="guardar()" *ngIf="accion == 'registrar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>
                <form novalidate [formGroup]="form" (ngSubmit)="modificar()" *ngIf="accion == 'modificar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>


            </div>


        </mat-card-content>
    </mat-card>
</div>