//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { PtService } from 'src/app/services/pt.service';

@Component({
  selector: 'app-ac-control-grupal-peso-talla',
  templateUrl: './ac-control-grupal-peso-talla.component.html',
  styleUrls: ['./ac-control-grupal-peso-talla.component.scss'],
  providers: [ PtService ]
})
export class AcControlGrupalPesoTallaComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    numero_periodo: new UntypedFormControl(-1, [Validators.required]),
    persona_inicio: new UntypedFormControl('', [Validators.required]),
    comentarios: new UntypedFormControl('', [Validators.required]),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public np: number = -1;
  public dt: any;
  public per: any;
  public fa: any = '-T-';
  public com: any;
  public ps: any;
  public s: any;
  public ghedp_laravelapi = this._PtService.getLaravelApi();
  public ghedp_laravelapiStorage = this._PtService.getLaravelApiStorage();

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _PtService: PtService,
    private _router: Router,
    private _route: ActivatedRoute,  
  ) { 
    this.form.markAllAsTouched();
    /*modificar*/
    this._route.queryParams.subscribe(
      params => {
        this.np = +params['np'];
        this.per = +params['per'];
        this.fa = params['fa'];

        this.com = params['com'];
        this.ps = params['ps'];
        this.s = +params['s'];

        if(this.np){
          console.log('ID: ' + this.np);
          this.form.patchValue({ numero_periodo: this.np, comentarios: this.com, persona_inicio: this.ps});
          this.postConsultarTallasPeriodo(this.np);
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    //accion
    let url_list = this._router.url.split("/");
    this.accion = url_list[4].split("?")[0];
    console.log(this.accion);
  }

  public postConsultarTallasPeriodo(id: number){
    this.isLoading = true;
    this._PtService.postConsultarTallasPeriodo({numero_periodo: id}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.dt = response.data;
          for(let i = 0; i < this.dt.length; i++){
             this.dt[i].ene_peso = this.dt[i].ene_peso?.toFixed(2);
             this.dt[i].ene_talla = this.dt[i].ene_talla?.toFixed(2);

             this.dt[i].febrero_peso = this.dt[i].febrero_peso?.toFixed(2);
             this.dt[i].febrero_talla = this.dt[i].febrero_talla?.toFixed(2);

             this.dt[i].marzo_peso = this.dt[i].marzo_peso?.toFixed(2);
             this.dt[i].marzo_talla = this.dt[i].marzo_talla?.toFixed(2);

             this.dt[i].abril_peso = this.dt[i].abril_peso?.toFixed(2);
             this.dt[i].abril_talla = this.dt[i].abril_talla?.toFixed(2);

             this.dt[i].mayo_peso = this.dt[i].mayo_peso?.toFixed(2);
             this.dt[i].mayo_talla = this.dt[i].mayo_talla?.toFixed(2);

             this.dt[i].junio_peso = this.dt[i].junio_peso?.toFixed(2);
             this.dt[i].junio_talla = this.dt[i].junio_talla?.toFixed(2);

             this.dt[i].julio_peso = this.dt[i].julio_peso?.toFixed(2);
             this.dt[i].julio_talla = this.dt[i].julio_talla?.toFixed(2);

             this.dt[i].agosto_peso = this.dt[i].agosto_peso?.toFixed(2);
             this.dt[i].agosto_talla = this.dt[i].agosto_talla?.toFixed(2);

             this.dt[i].septiembre_peso = this.dt[i].septiembre_peso?.toFixed(2);
             this.dt[i].septiembre_talla = this.dt[i].septiembre_talla?.toFixed(2);

             this.dt[i].octubre_peso = this.dt[i].octubre_peso?.toFixed(2);
             this.dt[i].octubre_talla = this.dt[i].octubre_talla?.toFixed(2);

             this.dt[i].nov_peso = this.dt[i].nov_peso?.toFixed(2);
             this.dt[i].nov_talla = this.dt[i].nov_talla?.toFixed(2);

             this.dt[i].dic_peso = this.dt[i].dic_peso?.toFixed(2);
             this.dt[i].dic_talla = this.dt[i].dic_talla?.toFixed(2);
          }
          //this.setForm(response.data);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }



  public postModificarTallaBeneficiariaBeneficiaria(i: number){
    this.isLoading = true;
    console.log(this.dt[i]);
    this._PtService.postModificarTallaBeneficiariaBeneficiaria({
      numero_periodo_beneficiaria: this.dt[i].id_periodo_bene_pk,
      ene_peso: parseFloat(this.dt[i].ene_peso)?.toFixed(2),
      ene_talla:parseFloat( (this.dt[i].ene_talla))?.toFixed(2),
      ene_imc: (this.dt[i].ene_peso/(this.dt[i].ene_talla*this.dt[i].ene_talla))?.toFixed(2),
      febrero_peso:parseFloat( this.dt[i].febrero_peso)?.toFixed(2),
      febrero_talla:parseFloat( this.dt[i].febrero_talla)?.toFixed(2),
      febrero_imc: (this.dt[i].febrero_peso/(this.dt[i].febrero_talla*this.dt[i].febrero_talla))?.toFixed(2),
      marzo_peso:parseFloat( this.dt[i].marzo_peso)?.toFixed(2),
      marzo_talla:parseFloat( this.dt[i].marzo_talla)?.toFixed(2),
      marzo_imc: (this.dt[i].marzo_peso/(this.dt[i].marzo_talla*this.dt[i].marzo_talla))?.toFixed(2),

      abril_peso:parseFloat( this.dt[i].abril_peso)?.toFixed(2),
      abril_talla:parseFloat( this.dt[i].abril_talla)?.toFixed(2),
      abril_imc: (this.dt[i].abril_peso/(this.dt[i].abril_talla*this.dt[i].abril_talla))?.toFixed(2),
      mayo_peso:parseFloat( this.dt[i].mayo_peso)?.toFixed(2),
      mayo_talla:parseFloat( this.dt[i].mayo_talla)?.toFixed(2),
      mayo_imc: (this.dt[i].mayo_peso/(this.dt[i].mayo_talla*this.dt[i].mayo_talla))?.toFixed(2),
      junio_peso:parseFloat( this.dt[i].junio_peso)?.toFixed(2),
      junio_talla:parseFloat( this.dt[i].junio_talla)?.toFixed(2),
      junio_imc:(this.dt[i].junio_peso/(this.dt[i].junio_talla*this.dt[i].junio_talla))?.toFixed(2),

      julio_peso:parseFloat( this.dt[i].julio_peso)?.toFixed(2),
      julio_talla:parseFloat( this.dt[i].julio_talla)?.toFixed(2),
      julio_imc:(this.dt[i].julio_peso/(this.dt[i].julio_talla*this.dt[i].julio_talla))?.toFixed(2),
      agosto_peso:parseFloat( this.dt[i].agosto_peso)?.toFixed(2),
      agosto_talla:parseFloat( this.dt[i].agosto_talla)?.toFixed(2),
      agosto_imc:(this.dt[i].agosto_peso/(this.dt[i].agosto_talla*this.dt[i].agosto_talla))?.toFixed(2),
      septiembre_peso:parseFloat( this.dt[i].septiembre_peso)?.toFixed(2),
      septiembre_talla:parseFloat( this.dt[i].septiembre_talla)?.toFixed(2),
      septiembre_imc: (this.dt[i].septiembre_peso/(this.dt[i].septiembre_talla*this.dt[i].septiembre_talla))?.toFixed(2),

      octubre_peso:parseFloat( this.dt[i].octubre_peso)?.toFixed(2),
      octubre_talla:parseFloat( this.dt[i].octubre_talla)?.toFixed(2),
      octubre_imc:(this.dt[i].octubre_peso/(this.dt[i].octubre_talla*this.dt[i].octubre_talla))?.toFixed(2),
      nov_peso:parseFloat( this.dt[i].nov_peso)?.toFixed(2),
      nov_talla:parseFloat( this.dt[i].nov_talla)?.toFixed(2),
      nov_imc:(this.dt[i].nov_peso/(this.dt[i].nov_talla*this.dt[i].nov_talla))?.toFixed(2),
      dic_peso:parseFloat( this.dt[i].dic_peso)?.toFixed(2),
      dic_talla:parseFloat( this.dt[i].dic_talla)?.toFixed(2),
      dic_imc: (this.dt[i].dic_peso/(this.dt[i].dic_talla*this.dt[i].dic_talla))?.toFixed(2),
    }).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postConsultarTallasPeriodo(this.np);
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: 'Revisa que todos tus datos de la fila esten completos.', icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: error.mensaje, icon:'error'}});
      });
  }

  public postModificarPeriodo(){
    this.isLoading = true;
    this._PtService.postModificarPeriodo(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._router.navigate(['medico/expedientes-medicos-generales/gt-expedientes-medicos-generales']);
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: 'Problema con el servidor', icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: error.mensaje, icon:'error'}});
      });
  }

  public postCerrarPeriodo(){
    this.isLoading = true;
    this._PtService.postCerrarPeriodo(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: 'Se ha cerrado el periodo', icon:'correct'}});
          this._router.navigate(['medico/expedientes-medicos-generales/gt-expedientes-medicos-generales']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: 'Problema con el servidor', icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: error.mensaje, icon:'error'}});
      });
  }


  public edad(fechaUsuario: any){
    //El siguiente fragmento de codigo lo uso para igualar la fecha de nacimiento con la fecha de hoy del usuario
    var d = new Date(),
    mh = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (mh.length < 2){
      mh = '0' + mh;
    }
    if (day.length < 2){
      day = '0' + day;
    }
    /*------------*/
    var hoy = new Date([year, mh, day].join('-'));//fecha del sistema con el mismo formato que "fechaUsuario"
    var cum = new Date(fechaUsuario);
    //alert(cum+" "+hoy);
    //Calculamos años
    var edad = hoy.getFullYear() - cum.getFullYear();
    var m = hoy.getMonth() - cum.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cum.getDate())) {
      edad--;
    }
    // calculamos los meses
    var meses=0;
    if(hoy.getMonth()>cum.getMonth()){
          meses=hoy.getMonth()-cum.getMonth();
      }else if(hoy.getMonth()<cum.getMonth()){
          meses=12-(cum.getMonth()-hoy.getMonth());
      }else if(hoy.getMonth()==cum.getMonth() && hoy.getDate()>cum.getDate() ){
          if(hoy.getMonth()-cum.getMonth()==0){
              meses=0;
          }else{
              meses=11;
          }
          
      }
      // Obtener días: día actual - día de cumpleaños
      let dias  = hoy.getDate() - cum.getDate();
      if(dias < 0) {
          // Si días es negativo, día actual es mayor al de cumpleaños,
          // hay que restar 1 mes, si resulta menor que cero, poner en 11
          meses = (meses - 1 < 0) ? 11 : meses - 1;
          // Y obtener días faltantes
          dias = 30 + dias;
      }


      return `${edad} años, ${meses} meses, ${dias} días`;
  }

}
