<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Gestor de salidas de almacén</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
        
    
        <form [formGroup]="form" (ngSubmit)="getConsultaBajasMedicamento(1)" autocomplete="off">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>Buscador</mat-label>
                        <input matInput formControlName="buscador">
                    </mat-form-field>

                </div>
                <div class="col">
                    <button mat-mini-fab type="submit">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <!--<div class="col">
                    <a rel="noopener" *ngIf="form.value.ruta" mat-mini-fab href="{{laravelapiStorage}}/{{form.value.ruta}}" target="_blank">
                        <mat-icon>download</mat-icon>
                    </a>
                </div>-->
                <!--<div class="col">
                    <a class="flat" color="accent" mat-flat-button href="{{laravelapi}}/ExcelMedicamentosInv" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon> Reporte de excel
                    </a>
                </div>-->
                <div class="col mt-1">
                   <button class="flat" type="button" color="primary" mat-flat-button [routerLink]="['/medico/almacen-de-medicamentos/gestor-salida-inventario/registrar']" >
                    <mat-icon>add</mat-icon> Registrar Salida
                </button>
            </div>
        </div>
    </form>
    



    <!-- tabla -->
    <div class="row mt-3 mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <caption><!--Parrilla--></caption>
            <!-- contenido -->

            <ng-container matColumnDef="sa_foto">
                <th id="sa_foto" mat-header-cell *matHeaderCellDef> FOTO </th>
                <td mat-cell *matCellDef="let element">
                    &nbsp;
                    <img src="{{laravelapistorage+'/'+element?.medicamento?.ruta_relativa+'?t='+t}}" alt="..." width="25px" height="25px">
                    &nbsp;
                </td>
            </ng-container>

            <ng-container matColumnDef="sa_tipo">
                <th id="sa_tipo" mat-header-cell *matHeaderCellDef> TIPO </th>
                <td mat-cell *matCellDef="let element">&nbsp;{{element?.clasificacion?.clasificacion}}&nbsp;</td>
            </ng-container>

            <ng-container matColumnDef="sa_nombre_medicamento">
                <th id="sa_nombre_medicamento" mat-header-cell *matHeaderCellDef> NOMBRE DE MEDICAMENTO</th>
                <td mat-cell *matCellDef="let element">&nbsp;{{element?.nombre_medicamento}}&nbsp;</td>
            </ng-container>

            <ng-container matColumnDef="sa_presentacion">
                <th id="sa_presentacion" mat-header-cell *matHeaderCellDef> PRESENTACIÓN </th>
                <td mat-cell *matCellDef="let element">&nbsp;{{element?.presentacion}}&nbsp;</td>
            </ng-container>

            <ng-container matColumnDef="sa_entrada">
                <th id="sa_entrada" mat-header-cell *matHeaderCellDef> ENTRADA </th>
                <td mat-cell *matCellDef="let element">&nbsp;{{element?.tipo_devolucion?.tipo_devolucion}}&nbsp;</td>
            </ng-container>

            <ng-container matColumnDef="sa_retiro">
                <th id="sa_retiro" mat-header-cell *matHeaderCellDef> USUARIO </th>
                <td mat-cell *matCellDef="let element">&nbsp;{{element?.nombre_licencia}}&nbsp;</td>
            </ng-container>

            <ng-container matColumnDef="sa_consultar">
                <th id="sa_consultar" mat-header-cell *matHeaderCellDef> CONSULTAR </th>
                <td mat-cell *matCellDef="let element">&nbsp;
                    <button type="button" mat-mini-fab color="primary" *ngIf="sa_consultar;else elseConsultar">
                        <mat-icon class="material-icons" [routerLink]="['/medico/almacen-de-medicamentos/gestor-incremento-inventario/consultar']" [queryParams]="{id_en:element.id_medicamentos_pk}">
                            open_in_new
                        </mat-icon>
                    </button>
                    <ng-template #elseConsultar>
                        <button type="button" mat-mini-fab color="primary" disabled>
                            <mat-icon class="material-icons">
                                open_in_new
                            </mat-icon>
                        </button>
                    </ng-template>
                    &nbsp;
                </td>
            </ng-container>

            <ng-container matColumnDef="sa_modificar">
                <th id="sa_modificar" mat-header-cell *matHeaderCellDef> MODIFICAR </th>
                <td mat-cell *matCellDef="let element">&nbsp;
                    <button type="button" mat-mini-fab color="primary" *ngIf="sa_modificar; else elseModificar">
                        <mat-icon class="material-icons" [routerLink]="['/medico/almacen-de-medicamentos/gestor-incremento-inventario/modificar']" [queryParams]="{id_en:element.id_medicamentos_pk}">
                            open_in_new
                        </mat-icon>
                    </button>
                    <ng-template #elseModificar>
                        <button type="button" mat-mini-fab color="primary" disabled>
                            <mat-icon class="material-icons">
                                open_in_new
                            </mat-icon>
                        </button>
                    </ng-template>
                    &nbsp;
                </td>
            </ng-container>


            <!-- fin contenido -->
            <tr mat-header-row *matHeaderRowDef="sa_displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: sa_displayedColumns;"></tr>
        </table>
    </div>
    <!-- fin tabla -->


</mat-card-content>
<mat-card-actions align="start">
    <mat-paginator (page)="pageEvent($event)" [length]="sa_length" [pageSize]="sa_pageSize" [pageSizeOptions]="sa_pageSizeOptions"  aria-label="Selecione una pagina">
    </mat-paginator>
</mat-card-actions>
</mat-card>
</div>