import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ParametrosService } from 'src/app/services/parametros.service';


@Component({
  selector: 'app-vista-egreso',
  templateUrl: './vista-egreso.component.html',
  styleUrls: ['./vista-egreso.component.scss']
})
export class VistaEgresoComponent implements OnInit {
  hostapi:string;
  hayEgreso = true;
  isLoading = false;
  urlFoto = "";
  autonomiasEmocionales = [];
  autonomiasMotrices = [];
  autonomiasConitivas = [];
  motivosEgreso = [];
  lugaresDestino = [];

  formulario = new UntypedFormGroup({
    fechaApertura : new UntypedFormControl("",[Validators.required]),
    idMotivoEgreso: new UntypedFormControl("",[Validators.required]),
    desMotivoEgreso: new UntypedFormControl("",[]),

    autonomiaCognitivoSelect:new UntypedFormControl("",[Validators.required]),
    autonomiaCognitivoInput:new UntypedFormControl("",[]),
    autonomiaMotrizSelect:new UntypedFormControl("",[Validators.required]),
    autonomiaMotrizInput:new UntypedFormControl("",[]),
    diagnosticoEmocionalSelect:new UntypedFormControl("",[Validators.required]),
    diagnosticoEmocionalInput:new UntypedFormControl("",[]),

    desLogrosAlcanzados:new UntypedFormControl("",[]),
    idLugarDestino: new UntypedFormControl("",[Validators.required]),
    desLugarDestino:new UntypedFormControl("",[]),

    solicitudEgresoRuta:new UntypedFormControl("",[]),
    solicitudEgresoArchivo:new UntypedFormControl("",[]),
    fotoEgresoURI:new UntypedFormControl("",[]),
    fotoEgresoArchivo:new UntypedFormControl("",[]),

  });
  constructor(
    private _documentosService:DocumentosService,
    private _beneficiariasService:BeneficiariaService,
    private activatedRoute:ActivatedRoute,
    private _parametrosService:ParametrosService,
    private _conf:ConfigurationService
     ) { 
      this.hostapi=_conf.getConfiguration().hostapi;
      
  }
  isLoadingAutonomiasConitivas = false;
  isLoadingAutonomiasMotrices = false;
  isLoadingMotivoEgreso = false;
  isLoadingAutonomiasEmocionales = false;
  isLoadingLugaresDestino = false;
  getCatalogos(){
    this.isLoadingAutonomiasConitivas = true;
    this._parametrosService.getParametros("16").subscribe(result=>{
      this.autonomiasConitivas = result.data.parametros;
      this.isLoadingAutonomiasConitivas = false;
    });
    this.isLoadingAutonomiasMotrices = true;
    this._parametrosService.getParametros("17").subscribe(result=>{
      this.autonomiasMotrices = result.data.parametros;
      this.isLoadingAutonomiasMotrices = false;
    });
    this.isLoadingAutonomiasEmocionales = true;
    this._parametrosService.getParametros("18").subscribe(result=>{
      this.autonomiasEmocionales = result.data.parametros;
      this.isLoadingAutonomiasEmocionales = false;
    });
    this.isLoadingMotivoEgreso = true;
    this._parametrosService.getParametros("19").subscribe(result=>{
      this.motivosEgreso = result.data.parametros;
      this.isLoadingMotivoEgreso = false;
    });

    this.isLoadingLugaresDestino = true;
    this._parametrosService.getParametros("20").subscribe(result=>{
      this.lugaresDestino = result.data.parametros;
      this.isLoadingLugaresDestino = false;
    });
  }
  ngOnInit(): void {
    
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info(params["id"]);
      if(params["id"]!=undefined){
        this.getDocumentos(params["id"]).then(result=>{
          this.urlFoto = this.getFotoEgreso();
          this.getProcesos(params["id"]);
        });
        
      }
    });

    this.formulario.controls.fechaApertura.disable();
    this.formulario.controls.idMotivoEgreso.disable();
    this.formulario.controls.desMotivoEgreso.disable();
    this.formulario.controls.autonomiaCognitivoSelect.disable();
    this.formulario.controls.autonomiaCognitivoInput.disable();
    this.formulario.controls.autonomiaMotrizSelect.disable();
    this.formulario.controls.autonomiaMotrizInput.disable();
    this.formulario.controls.diagnosticoEmocionalSelect.disable();
    this.formulario.controls.diagnosticoEmocionalInput.disable();
    this.formulario.controls.desLogrosAlcanzados.disable();
    this.formulario.controls.idLugarDestino.disable();
    this.formulario.controls.desLugarDestino.disable();
  }

  getFotoEgreso(){
    for(let i=0; i< this.documentos.length;i++){
      if(this.documentos[i].idTipoDocumento == 96){
        return this.documentos[i].docTipoCatalogo;
      }
    }
    return "";
  }
  getProcesos(id:number){
    this.isLoading = true;
    this._beneficiariasService.getProcesosByBeneficiaria(id).subscribe(result=>{
      this.isLoading = false;
      //console.info(result.data.procesos);
      console.info(result.data.procesos[result.data.procesos.length-1].etapas);
      if(result.data.procesos[result.data.procesos.length-1].etapas.egreso){
        this.hayEgreso = true;
        this.getEgreso(result.data.procesos[result.data.procesos.length-1].idProceso);
      }else{
        this.hayEgreso = false;
      }
    },()=>{
      this.isLoading = false;
    });
  }

  getEgreso(idProceso){
    this.isLoading=true;
    this._beneficiariasService.getProcesoEgreso(idProceso).subscribe(result=>{
      console.log("result de serv: ", result);
      this.isLoading=false;
      this.setData(result);
      this.getCatalogos();
    },()=>{
      this.isLoading=false;
    })
    
  }

  documentos = [];
  getDocumentos(idBeneficiaria:any){
    return new Promise((resolve, reject) => {
      this._documentosService.getAllByBeneficiaria(idBeneficiaria).subscribe(result=>{
        this.documentos = result.data.documentos;
        resolve(true);
      },()=>{
        resolve(false);
      });
    });
  }

  setData(result){
    if(result.data.fechaApertura){
      this.formulario.controls.fechaApertura.setValue(new Date(result.data.fechaApertura.date));
    }
    if(result.data.idMotivoEgreso!="0"){
      this.formulario.controls.idMotivoEgreso.setValue(result.data.idMotivoEgreso);
    }
    this.formulario.controls.desMotivoEgreso.setValue(result.data.desMotivoEgreso);
    if(result.data.iGradoCognitivo!="0"){
      this.formulario.controls.autonomiaCognitivoSelect.setValue(result.data.iGradoCognitivo);
    }
    this.formulario.controls.autonomiaCognitivoInput.setValue(result.data.desGradoCognitivo);
    if(result.data.idGradoMotriz!="0"){
      this.formulario.controls.autonomiaMotrizSelect.setValue(result.data.idGradoMotriz);
    }
    this.formulario.controls.autonomiaMotrizInput.setValue(result.data.desGradoMotriz);
    if(result.data.idDiagnosticoEmocional!="0"){
      this.formulario.controls.diagnosticoEmocionalSelect.setValue(result.data.idDiagnosticoEmocional);
    }
    this.formulario.controls.diagnosticoEmocionalInput.setValue(result.data.desDiagnosticoEmocional);
    this.formulario.controls.desLogrosAlcanzados.setValue(result.data.desLogrosAlcanzados);
    if(result.data.idLugarDestino!="0"){
      this.formulario.controls.idLugarDestino.setValue(result.data.idLugarDestino);
    }
    this.formulario.controls.desLugarDestino.setValue(result.data.desLugarDestino);

    
    
  }

}
