//General
import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { SesionService } from 'src/app/services/sesion.service';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { IncidenciasService } from 'src/app/services/incidencias.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'app-rrhh-incidencias',
  templateUrl: './rrhh-incidencias.component.html',
  styleUrls: ['./rrhh-incidencias.component.scss'],
  providers: [ IncidenciasService ]
})
export class RrhhIncidenciasComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form1_ausentismo: UntypedFormGroup = this.fb.group({
    numero_incidente: new UntypedFormControl(-1, []),
    numero_tipo_ausentismo: new UntypedFormControl('', []),
    motivo_ausentismo: new UntypedFormControl('', []),
    numero_empleado: new UntypedFormControl(-1, []),


    fecha_ausentismo: new UntypedFormControl(-1, []),

    motivo_breve: new UntypedFormControl([], [Validators.maxLength(50), Validators.required]),
    motivo: new UntypedFormControl([], [Validators.maxLength(500)]),
  });






  public form2_administrativa: UntypedFormGroup = this.fb.group({
    numero_incidente: new UntypedFormControl(-1, []),
    fecha_acta: new UntypedFormControl('', []),
    motivo_acta: new UntypedFormControl('', []),
    numero_tipo_acta: new UntypedFormControl('', []),

    motivo_breve: new UntypedFormControl([], [Validators.maxLength(50), Validators.required]),
    motivo: new UntypedFormControl([], [Validators.maxLength(500)]),
  });

  public form3_observaciones: UntypedFormGroup = this.fb.group({
    numero_incidente: new UntypedFormControl(-1, []), 
    fecha_obs: new UntypedFormControl('', []),
    observacion: new UntypedFormControl('', []),
    tipo_observacion: new UntypedFormControl('', []),

    motivo_breve: new UntypedFormControl([], [Validators.maxLength(50), Validators.required]),
    motivo: new UntypedFormControl([], [Validators.maxLength(500)]),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id_incidencia: any;
  public id_numero_empleado: any;
  public laravelapiStorage = this._IncidenciasService.getLaravelApiStorage();
  public laravelapi = this._IncidenciasService.getLaravelApi();
  public t: any;
  public data: any;
  public bene: any = [];
  public tts: any;
  public ds: string = '';
  public nds: number = 0;
  public anns: number = 0;
  public data_h: any;
  public cata: any;


  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _IncidenciasService: IncidenciasService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
    private _sesion:SesionService,
  ) { 
     /*modificar*/
    //this.form.markAllAsTouched();
    this._route.queryParams.subscribe(
      params => {
        this.id_incidencia = +params['id_incidencia'];
        this.id_numero_empleado = +params['id_numero_empleado'];

        this.form1_ausentismo.patchValue({
          numero_empleado: this.id_numero_empleado
        });


        console.log(this.id_incidencia);
        if(this.id_incidencia){
          this.getConsultarFormularioIncidentes();
          this.postConsultarIncidente(this.id_incidencia);
        }
      });
    /*fin modificar*/


  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[4].split("?")[0];
    console.log(this.accion);
  }

  public postConsultarIncidente(id: number){
    this.isLoading = true;
    this._IncidenciasService.postConsultarIncidente({numero_incidente: id}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.data = response?.data;
          this.setForm(this.data);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }


  public getConsultarFormularioIncidentes(){
    this.isLoading = true;
  
    this._IncidenciasService.getConsultarFormularioIncidentes().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
         this.cata = response.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }


  public setForm(data: any){
    if(data.tipo_incidente?.id_tipo_catalogo_pk == 1){
      this.form1_ausentismo.patchValue({
        numero_incidente: data?.id_incidente_pk,
        numero_tipo_ausentismo: data?.id_tipo_ausentismo_fk,
        motivo_ausentismo: data?.motivo_ausentismo,


        motivo_breve: data?.motivo_breve,
        motivo: data?.motivo,
      });
    }


    if(data.tipo_incidente?.id_tipo_catalogo_pk == 2){
      this.form2_administrativa.patchValue({
        numero_incidente: data?.id_incidente_pk,
        fecha_acta: data?.fecha_acta,
        motivo_acta: data?.motivo_acta,
        numero_tipo_acta: data?.id_cat_acta_fk,


        motivo_breve: data?.motivo_breve,
        motivo: data?.motivo,
      });





    }


    if(data.tipo_incidente?.id_tipo_catalogo_pk == 3){
      this.form3_observaciones.patchValue({
        numero_incidente: data?.id_incidente_pk,
        fecha_obs: data?.fecha_obs,
        observacion: data?.observacion,
        tipo_observacion: data?.id_cat_obs_fk,


        motivo_breve: data?.motivo_breve,
        motivo: data?.motivo,
      });
    } 







  }







  public postRegistrarIncidentes(form: any, alert: number = 0){
    this.isLoading = true;
    this._IncidenciasService.postRegistrarIncidentes(form).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          if(alert == 0){
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          }
          this.postConsultarIncidente(this.id_incidencia);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public postRegistrarFechaIncidentes(form: any){
    this.isLoading = true;
    this._IncidenciasService.postRegistrarFechaIncidentes(form).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form1_ausentismo.patchValue({
            fecha_ausentismo: ''
          });
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa al Registrar', mensaje: response.mensaje, icon:'correct'}});
          
          this.postRegistrarIncidentes(this.form1_ausentismo.value, 1);
          //this.postConsultarIncidente(this.id_incidencia);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public postModificarFechaIncidentes(form: any){
    this.isLoading = true;
    this._IncidenciasService.postModificarFechaIncidentes(form).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa al Modificar', mensaje: response.mensaje, icon:'correct'}});
          this.postConsultarIncidente(this.id_incidencia);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public postEliminarFechaIncidentes(form: any){
    this.isLoading = true;
    this._IncidenciasService.postEliminarFechaIncidentes(form).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa al Eliminar', mensaje: response.mensaje, icon:'correct'}});
          this.postConsultarIncidente(this.id_incidencia);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }



  public postRegModDocumentoIncidentes(event: any, _id: number): any{

    
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      console.log(file.type);
      if (file.type.startsWith('application/pdf')) {//text/xml
        
        const formData = new FormData();
        formData.set('documento', file);
        formData.set('numero_incidente',  _id.toString());
        this.isLoading = true;
        this._IncidenciasService.postRegModDocumentoIncidentes(formData).subscribe(
          response => {console.log(response);
            //this.postConsultarIncidente(this.id_incidencia);
            this.postRegistrarIncidentes(this.form1_ausentismo.value)
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            
          },
          error => {console.log(<any>error);
            this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
            this.isLoading = false;
        });


      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato', icon:'error'}});
      }
    }  
  }

  public postEliminarDocumentoIncidentes(id: any){
    this.isLoading = true;
    this._IncidenciasService.postEliminarDocumentoIncidentes({numero_incidente: id }).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa al Eliminar', mensaje: response.mensaje, icon:'correct'}});
          this.postConsultarIncidente(this.id_incidencia);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }






















}
