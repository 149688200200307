import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-desition-box',
  templateUrl: './desition-box.component.html',
  styleUrls: ['./desition-box.component.scss']
})
export class DesitionBoxComponent implements OnInit {

  ngOnInit():void{
    // This is intentional
  }
  constructor(
    public dialogRef: MatDialogRef<DesitionBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
