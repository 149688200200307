import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ParametrosService } from 'src/app/services/parametros.service';

@Component({
  selector: 'app-egreso',
  templateUrl: './egreso.component.html',
  styleUrls: ['./egreso.component.scss']
})
export class EgresoComponent implements OnInit {
  hostapi:string;
  hayEgreso = true;
  isLoading = false;
  urlFoto = "";
  autonomiasEmocionales = [];
  autonomiasMotrices = [];
  autonomiasConitivas = [];
  motivosEgreso = [];
  lugaresDestino = [];

  formulario = new UntypedFormGroup({
    fechaApertura : new UntypedFormControl("",[Validators.required]),
    idMotivoEgreso: new UntypedFormControl("",[Validators.required]),
    desMotivoEgreso: new UntypedFormControl("",[]),

    autonomiaCognitivoSelect:new UntypedFormControl("",[Validators.required]),
    autonomiaCognitivoInput:new UntypedFormControl("",[]),
    autonomiaMotrizSelect:new UntypedFormControl("",[Validators.required]),
    autonomiaMotrizInput:new UntypedFormControl("",[]),
    diagnosticoEmocionalSelect:new UntypedFormControl("",[Validators.required]),
    diagnosticoEmocionalInput:new UntypedFormControl("",[]),

    desLogrosAlcanzados:new UntypedFormControl("",[]),
    idLugarDestino: new UntypedFormControl("",[Validators.required]),
    desLugarDestino:new UntypedFormControl("",[]),

    solicitudEgresoRuta:new UntypedFormControl("",[]),
    solicitudEgresoArchivo:new UntypedFormControl("",[]),
    fotoEgresoURI:new UntypedFormControl("",[]),
    fotoEgresoArchivo:new UntypedFormControl("",[]),

  });
  constructor(
    private _documentosService:DocumentosService,
    private _beneficiariasService:BeneficiariaService,
    private _activatedRoute:ActivatedRoute,
    private _parametrosService:ParametrosService,
    private _conf:ConfigurationService
     ) { 
      this.hostapi = _conf.getConfiguration().hostapi;
      
  }
  isLoadingAutonomiasConitivas = false;
  isLoadingAutonomiasMotrices = false;
  isLoadingMotivoEgreso = false;
  isLoadingAutonomiasEmocionales = false;
  isLoadingLugaresDestino = false;
  eg_getCatalogos_(){
    this.isLoadingAutonomiasConitivas = true;
    this._parametrosService.getParametros("16").subscribe(res=>{
      this.autonomiasConitivas = res.data.parametros;
      this.isLoadingAutonomiasConitivas = false;
    });
    this.isLoadingAutonomiasMotrices = true;
    this._parametrosService.getParametros("17").subscribe(res=>{
      this.autonomiasMotrices = res.data.parametros;
      this.isLoadingAutonomiasMotrices = false;
    });
    this.isLoadingAutonomiasEmocionales = true;
    this._parametrosService.getParametros("18").subscribe(res=>{
      this.autonomiasEmocionales = res.data.parametros;
      this.isLoadingAutonomiasEmocionales = false;
    });
    this.isLoadingMotivoEgreso = true;
    this._parametrosService.getParametros("19").subscribe(res=>{
      this.motivosEgreso = res.data.parametros;
      this.isLoadingMotivoEgreso = false;
    });

    this.isLoadingLugaresDestino = true;
    this._parametrosService.getParametros("20").subscribe(res=>{
      this.lugaresDestino = res.data.parametros;
      this.isLoadingLugaresDestino = false;
    });
  }
  ngOnInit(): void {
    
    this._activatedRoute.queryParams.subscribe(params=>{
      console.info(params["id"]);
      if(params["id"]!=undefined){
        this.eg_getDocumentos_(params["id"]).then(res=>{
          this.urlFoto = this.eg_getFotoEgreso();
          this.eg_getProcesos(params["id"]);
        });
        
      }
    });
  }

  public eg_getFotoEgreso(){
    for(let eg=0; eg< this.documentos.length;eg++){
      if(this.documentos[eg].idTipoDocumento == 96){
        return this.documentos[eg].docTipoCatalogo;
      }
    }
    return "";
  }
  public eg_getProcesos(id:number){
    this.isLoading = true;
    this._beneficiariasService.getProcesosByBeneficiaria(id).subscribe(res=>{
      this.isLoading = false;
      //console.info(res.data.procesos);
      console.info(res.data.procesos[res.data.procesos.length-1].etapas);
      if(res.data.procesos[res.data.procesos.length-1].etapas.egreso){
        this.hayEgreso = true;
        this.eg_getEgreso_(res.data.procesos[res.data.procesos.length-1].idProceso);
      }else{
        this.hayEgreso = false;
      }
    },()=>{
      this.isLoading = false;
    });
  }

  public eg_getEgreso_(idProceso: any){
    this.isLoading=true;
    this._beneficiariasService.getProcesoEgreso(idProceso).subscribe(res=>{
      this.isLoading=false;
      this.eg_setData_(res);
      this.eg_getCatalogos_();
    },()=>{
      this.isLoading=false;
    })
  }

  documentos = [];
  public eg_getDocumentos_(idBeneficiaria:any){
    return new Promise((resolve, reject) => {
      this._documentosService.getAllByBeneficiaria(idBeneficiaria).subscribe(res=>{
        this.documentos = res.data.documentos;
        resolve(true);
      },()=>{
        resolve(false);
      });
    });
  }

  public eg_setData_(res: any){
    if(res.data.fechaApertura){
      this.formulario.controls.fechaApertura.setValue(new Date(res.data.fechaApertura.date));
    }
    if(res.data.idMotivoEgreso!="0"){
      this.formulario.controls.idMotivoEgreso.setValue(res.data.idMotivoEgreso);
    }
    this.formulario.controls.desMotivoEgreso.setValue(res.data.desMotivoEgreso);
    if(res.data.iGradoCognitivo!="0"){
      this.formulario.controls.autonomiaCognitivoSelect.setValue(res.data.iGradoCognitivo);
    }
    this.formulario.controls.autonomiaCognitivoInput.setValue(res.data.desGradoCognitivo);
    if(res.data.idGradoMotriz!="0"){
      this.formulario.controls.autonomiaMotrizSelect.setValue(res.data.idGradoMotriz);
    }
    this.formulario.controls.autonomiaMotrizInput.setValue(res.data.desGradoMotriz);
    if(res.data.idDiagnosticoEmocional!="0"){
      this.formulario.controls.diagnosticoEmocionalSelect.setValue(res.data.idDiagnosticoEmocional);
    }
    this.formulario.controls.diagnosticoEmocionalInput.setValue(res.data.desDiagnosticoEmocional);
    this.formulario.controls.desLogrosAlcanzados.setValue(res.data.desLogrosAlcanzados);
    if(res.data.idLugarDestino!="0"){
      this.formulario.controls.idLugarDestino.setValue(res.data.idLugarDestino);
    }
    this.formulario.controls.desLugarDestino.setValue(res.data.desLugarDestino);
    
  }

}
