
<div class="card-container">
    <mat-card class="example-card">
    <mat-card-header *ngIf="!isEvaluaciones">
        <mat-card-title>Gestor de expedientes de beneficiaria</mat-card-title>
        <mat-card-subtitle>Localiza y consulta beneficiarias</mat-card-subtitle>
    </mat-card-header>
    <mat-card-header *ngIf="isEvaluaciones">
        <mat-card-title>Gestor general de evaluaciones</mat-card-title>
        <!--<mat-card-subtitle>Localiza y consulta beneficiarias</mat-card-subtitle>-->
    </mat-card-header>
    <mat-card-content>
        <form (ngSubmit)="sendForm()" autocomplete="off" [formGroup]="formulario">
            <mat-form-field appearance="fill" class="bt col-md-6">
                <mat-label>Buscador</mat-label>
                <input matInput placeholder="Nombre de la beneficiaria" formControlName="buscar">
                
            </mat-form-field>
            &nbsp;
            <mat-form-field appearance="fill" class="bt col-md-4">
                <mat-label>Etapa</mat-label>
                <mat-select formControlName="estatus">
                    <mat-option value="">-- Seleccione una opción --</mat-option>
                    <mat-option *ngFor="let estado of estados" value="{{estado.valor.replace(' ','_') | uppercase}}">{{estado.valor}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button type="submit" mat-mini-fab color="accent">
                <mat-icon>search</mat-icon>
            </button>

            <br/>
            <button type="button" color="primary" class="flat" mat-flat-button [routerLink]="['datos-generales']" *ngIf="!isEvaluaciones && tieneAccionCrear; else elseButtonNew">
                <mat-icon>add</mat-icon>Nuevo expediente
            </button>
            <ng-template #elseButtonNew>
                <button type="button" color="primary" class="flat" mat-flat-button disabled>
                    <mat-icon>add</mat-icon>Nuevo expediente
                </button>
            </ng-template>
            &nbsp;
            <a type="button" download="reporte-beneficiarias.xls" color="accent" class="flat" mat-flat-button *ngIf="!isEvaluaciones" href="{{phpPath}}/reportBeneficiarias.php">
                <mat-icon>list_alt</mat-icon>&nbsp;Reporte en excel
            </a>
            &nbsp;
            <a  color="accent" class="flat" mat-flat-button  href="{{laravelapi}}/ReporteExcelBeneficiario" target="_blank" rel=noopener>
                <mat-icon>list_alt</mat-icon>&nbsp;Reporte en excel sobre el estado
            </a>
        </form>
        
        <div class="table-container">
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <table mat-table [dataSource]="dataSource">
                <caption></caption>
                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
            
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                    <th id="position" mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element;let i=index"> {{i+1}} </td>
                </ng-container>


                <ng-container matColumnDef="nombre">
                <th id="nombre" mat-header-cell *matHeaderCellDef> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                </ng-container>
            
                <!-- Estatus Column -->
                <ng-container matColumnDef="estatus">
                <th id="estatus" mat-header-cell *matHeaderCellDef> Estatus </th>
                <td mat-cell *matCellDef="let element"> 
                    <mat-chip-list aria-label="Fish selection">
                        <mat-chip color="primary" class="active" selected *ngIf="filtroEstatus(element.etapaAtencionActiva)=='ACTIVO'; else NoActivo">
                            <mat-icon>done</mat-icon>Activo
                        </mat-chip>
                        <ng-template #NoActivo>
                            <mat-chip #NoActivo>
                                <mat-icon>history</mat-icon>No activo
                            </mat-chip>
                        </ng-template>
                        
                    </mat-chip-list>
                </td>
                </ng-container>
            
                <ng-container matColumnDef="etapa">
                    <th id="etapa" mat-header-cell *matHeaderCellDef> Etapa </th>
                    <td mat-cell *matCellDef="let element">
                        {{corregirOrtografia(element.etapaAtencionActiva)}}
                    </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="motivo_ingreso">
                <th id="motivoIngreso" mat-header-cell *matHeaderCellDef> Motivo de ingreso </th>
                <td mat-cell *matCellDef="let element"> {{getMotivoIngresoById(element.idMotivoIngreso)}} {{element.desMotivoIngreso}} </td>
                </ng-container>
            
                <!-- Symbol Column -->
                <ng-container matColumnDef="discapacidad">
                <th id="discapacidad" mat-header-cell *matHeaderCellDef> Discapacidad </th>
                <td mat-cell *matCellDef="let element"> {{element.idDiscapacidad}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="canalizador">
                <th id="canalizador" mat-header-cell *matHeaderCellDef> Canalizador </th>
                <td mat-cell *matCellDef="let element"> {{getCanalizadorById(element?.idCanaliza)}} {{element.desCanaliza}} </td>
                </ng-container>

                <ng-container matColumnDef="mas">
                    <th id="acciones" mat-header-cell *matHeaderCellDef> Acciones </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            
                            <button mat-menu-item [routerLink]="['expediente/view']" [queryParams]="{id: element.id}">
                                <mat-icon>folder</mat-icon>
                                <span>Ver Expediente</span>
                            </button>
                            <button mat-menu-item [routerLink]="['expediente']" [queryParams]="{id: element.id}" *ngIf="tieneAccionEditar">
                                <mat-icon>folder</mat-icon>
                                <span>Editar Expediente</span>
                            </button>
                            <button mat-menu-item [routerLink]="['ficha-tecnica']" [queryParams]="{id: element.id}">
                                <mat-icon>assignment_ind</mat-icon>
                                <span>Ficha técnica</span>
                            </button>
                            <button mat-menu-item [routerLink]="['etapas-de-atencion']" [queryParams]="{id: element.id}">
                                <mat-icon>insights</mat-icon>
                                <span>Etapa de atención</span>
                            </button>
                            <button mat-menu-item [routerLink]="['eventos']" [queryParams]="{id: element.id}">
                                <mat-icon>event</mat-icon>
                                <span>Eventos de vida</span>
                            </button>
                            <button mat-menu-item (click)="confirmaEliminacion(element.id)" *ngIf="tieneAccionEliminar">
                                <mat-icon>delete</mat-icon>
                                <span>Eliminar</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <ng-container matColumnDef="habilidades">
                    <th id="habilidades" mat-header-cell *matHeaderCellDef> Habilidades </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" [routerLink]="['gestor-de-pruebas']" [queryParams]="{id: element.id}">
                            <mat-icon>preview</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="general">
                    <th id="datosGenerales" mat-header-cell *matHeaderCellDef> Datos generales </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-mini-fab color="warn">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="expediente">
                    <th id="expediente" mat-header-cell *matHeaderCellDef> Expediente </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-mini-fab color="warn">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="eventos">
                    <th id="eventosVida" mat-header-cell *matHeaderCellDef> Eventos de vida </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-mini-fab color="warn">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="eliminar">
                    <th id="eliminar" mat-header-cell *matHeaderCellDef> Eliminar </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-mini-fab color="warn">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
          

    </mat-card-content>
    <mat-card-actions>

        <mat-paginator
            [pageSize]="paginador.pageSize"
            [pageSizeOptions]="paginador.pageOptions"
            (page)="pager($event)">
        </mat-paginator>

    </mat-card-actions>
    </mat-card>
</div>

