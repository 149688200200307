<h1 mat-dialog-title>
    <mat-icon>help_outline</mat-icon>
</h1>
<form autocomplete="off" [formGroup]="formulario" (ngSubmit)="enviar()">
    <div mat-dialog-content>
        
        <br>
        <h1 *ngIf="isLoading">Recuperando credenciales</h1>
        <mat-progress-bar *ngIf="isLoading" mode="query"></mat-progress-bar>
        <h1 *ngIf="!isLoading">¿Desea recuperar su contraseña?</h1>
        <p *ngIf="!isLoading">Ingrese su correo electrónico y se le enviarán sus credenciales de acceso</p>
        
            <mat-form-field appearance="fill" class="bt col-md-12" *ngIf="!isLoading">
                <mat-label>Correo electrónico</mat-label>
                <input matInput placeholder="" formControlName="correo">
                <mat-icon matSuffix>alternate_email</mat-icon>
            </mat-form-field>
        
    </div>
    <div mat-dialog-actions align="center" *ngIf="!isLoading">
        <button mat-stroked-button [mat-dialog-close] type="button">No</button>
        <button mat-flat-button color="primary" cdkFocusInitial type="submit">Sí</button>
    </div>
</form>