import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { MatCardActions } from '@angular/material/card';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class MedicoService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

  /*directorio medico*/
  public getConsultaDatosFormMedico(): any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaDatosFormMedico");
  }

  public postRegistroDirMedico(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistroDirMedico", datos, {});
  }

  public postModificarDirMedico(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarDirMedico", datos, {});
  }

  public getConsultaDirMedico(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaDirMedico?numero_dir="+id);
  }

  public getConsultaDirectoriosMedicos(p: number, r: number, s: string):any{
    if(s != ''){
      return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaDirectoriosMedicos?filas="+r+'&page='+p+'&buscador='+s);
    }else{
      return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaDirectoriosMedicos?filas="+r+'&page='+p);
    }
  }

  public getEliminarDir(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/EliminarDir?numero_dir="+id);
  }

  /*fin directorio medico*/


  /*inventario de medicamentos*/
  public getConsultaMedicamentosInv(p: number, r: number, s: string):any{
    if(s != ''){
      return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaMedicamentosInv?filas="+r+'&page='+p+'&buscador='+s);
    }else{
      return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaMedicamentosInv?filas="+r+'&page='+p);
    }
  }

  public getEliminarMedicamentosInv(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/EliminarMedicamentosInv?numero_medicamento="+id);
  }

  public getConsultaDatosFormInvMedico(): any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaDatosFormInvMedico");
  }

  public getConsultaMedicamentoInv(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaMedicamentoInv?numero_medicamento="+id);
  }

  public postRegistroInvMedico(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistroInvMedico", datos, {});
  }

  public postModificarInvMedico(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarInvMedico", datos, {});
  }

  /*fin inventario de medicamentos*/


  /*general entrada y salida*/
  public getConsultaMarcaMedicamento(id_clasf: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaMarcaMedicamento?numero_clasificacion="+id_clasf);
  }

  public getConsultaNombresMedicamento(id_clasf: number, marca: string):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaNombresMedicamento?numero_clasificacion="+id_clasf+'&marca='+marca);
  }

  public getConsultaPresentacionMedicamento(id_clasf: number, marca: string, producto: string):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaPresentacionMedicamento?numero_clasificacion="+id_clasf+'&marca='+marca+'&nombre_producto='+producto);
  }
  /*fin general entrada y salida*/


  /*entradas*/
  public getConsultaAltasMedicamento(p: number, r: number, s: string):any{
    if(s != ''){
      return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaAltasMedicamento?filas="+r+'&page='+p+'&buscador='+s);
    }else{
      return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaAltasMedicamento?filas="+r+'&page='+p);
    }
  }

  public postAltaInventarioMedico(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/AltaInventarioMedico", datos, {});
  }

  public getConsultaAltaMedicamento(id_alta: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaAltaMedicamento?numero_alta="+id_alta);
  }
  /*fin entradas*/


  /*salidas*/
  public getConsultaBajasMedicamento(p: number, r: number, s: string):any{
    if(s != ''){
      return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaBajasMedicamento?filas="+r+'&page='+p+'&buscador='+s);
    }else{
      return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaBajasMedicamento?filas="+r+'&page='+p);
    }
  }

  public postBajaInventarioMedico(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/BajaInventarioMedico", datos, {});
  }

  public getConsultaBajaMedicamento(id_alta: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaBajaMedicamento?numero_alta="+id_alta);
  }
  /*fin salidas*/





  //expedientes medicos
  


}