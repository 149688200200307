<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Gestor de perfil y descripción de puestos</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <form [formGroup]="form" autocomplete="off">
                <div class="row">
                    <!--<div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>ORGANIGRAMA INSTITUCIONAL</mat-label>
                            <mat-select formControlName="ruta">
                                <mat-option *ngFor="let p of docs" [value]="p.ruta">{{p.nombre_doc}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <a rel="noopener" *ngIf="form.value.ruta" mat-mini-fab href="{{laravelapiStorage}}/{{form.value.ruta}}" target="_blank">
                            <mat-icon>download</mat-icon>
                        </a>
                    </div>-->
                    <!--<div class="col mt-1">
                        <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/gestor-organigrama-docs']" >
                            Gestor de organigrama
                        </button>
                    </div>-->
                    <div class="col mt-1">
                        <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/gestor-area-departamento']">
                            Gestor de departamentos
                        </button>
                        &nbsp;
                        <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/gestor-puestos']" >
                            Gestor de puestos
                        </button>
                    </div>
                </div>
            </form>
            <br>


            <!--<div class="d-flex justify-content-end gap-2 mt-3">
                <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/registrar']" >
                    Nuevo perfil y descripción
                </button>

                <a rel="noopener" class="flat" color="primary" mat-flat-button href="{{laravelapi}}/ReporteExcel" target="_blank">
                    Reporte excel
                </a>
 
            </div>
        -->




        <form [formGroup]="form" (ngSubmit)="getConsultaDescripcionBuscador(1)" autocomplete="off">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>Buscador</mat-label>
                        <input matInput formControlName="buscador">
                    </mat-form-field>

                </div>
                <div class="col">
                    <button mat-mini-fab type="submit">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="col">
                    <a class="flat" color="accent" mat-flat-button href="{{laravelapi}}/ReporteExcel" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon> Reporte Excel Puestos
                    </a>
                </div>
                <div class="col">
                   <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/registrar']" >
                    <mat-icon>add</mat-icon> Nuevo perfil y descripción
                </button>
            </div>
        </div>
    </form>



    <!-- tabla -->
    <div class="row mt-3 mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <caption><!--Parrilla--></caption>
            <!-- contenido -->

            <ng-container matColumnDef="dep">
                <th id="dep" mat-header-cell *matHeaderCellDef> DEPARTAMENTO </th>
                <td mat-cell *matCellDef="let element">{{element?.departamento?.departamento}}</td>
            </ng-container>

            <ng-container matColumnDef="nombre_dep">
                <th id="nombre_dep" mat-header-cell *matHeaderCellDef> NOMBRE DE PUESTO </th>
                <td mat-cell *matCellDef="let element">{{element?.puestos_reporta_titulo?.puestos_reporte}}</td>
            </ng-container>

            <ng-container matColumnDef="tipo_puesto">
                <th id="tipo_puesto" mat-header-cell *matHeaderCellDef> TIPO PUESTO </th>
                <td mat-cell *matCellDef="let element">{{element?.puestos_reporta?.puestos_reporte}}</td>
            </ng-container>

            <ng-container matColumnDef="num_plazas">
                <th id="num_plazas" mat-header-cell *matHeaderCellDef> NÚMERO DE PLAZAS </th>
                <td mat-cell *matCellDef="let element">{{element?.numero_plazas}}</td>
            </ng-container>

            <ng-container matColumnDef="estatus">
                <th id="estatus" mat-header-cell *matHeaderCellDef> ESTATUS </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.estatus?.id_estatus_general_pk == 1">
                        Borrador
                    </span>
                    <span *ngIf="element?.estatus?.id_estatus_general_pk != 1">
                        {{element?.estatus?.estatus}}
                    </span>

                </td>
            </ng-container>

            <ng-container matColumnDef="modificar">
                <th id="modificar" mat-header-cell *matHeaderCellDef> MODIFICAR </th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" mat-mini-fab color="primary" *ngIf="modificar; else elseModificar">
                        <mat-icon class="material-icons" [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/modificar']" [queryParams]="{id_dp:element.id_desc_puestos_pk}">
                            open_in_new
                        </mat-icon>
                    </button>
                    <ng-template #elseModificar>
                        <button type="button" mat-mini-fab color="primary" disabled>
                            <mat-icon class="material-icons">
                                open_in_new
                            </mat-icon>
                        </button>
                    </ng-template>
                    
                </td>
            </ng-container>

            <ng-container matColumnDef="consultar">
                <th id="consultar" mat-header-cell *matHeaderCellDef> CONSULTAR </th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" mat-mini-fab color="primary" *ngIf="consultar;else elseConsultar">
                        <mat-icon class="material-icons" [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/consultar']" [queryParams]="{id_dp:element.id_desc_puestos_pk}">
                            open_in_new
                        </mat-icon>
                    </button>
                    <ng-template #elseConsultar>
                        <button type="button" mat-mini-fab color="primary" disabled>
                            <mat-icon class="material-icons">
                                open_in_new
                            </mat-icon>
                        </button>
                    </ng-template>
                    
                </td>
            </ng-container>
            
            <ng-container matColumnDef="eliminar">
                <th id="eliminar" mat-header-cell *matHeaderCellDef> ELIMINAR </th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" mat-mini-fab color="warn" (click)="postEliminarDescripcion(element.id_desc_puestos_pk)" *ngIf="eliminar; else elseEliminar">
                        <mat-icon class="material-icons">
                            delete
                        </mat-icon>
                    </button>
                    <ng-template #elseEliminar>
                        <button type="button" mat-mini-fab color="warn" disabled>
                            <mat-icon class="material-icons">
                                delete
                            </mat-icon>
                        </button>
                    </ng-template>
                    
                </td>
            </ng-container>

            <ng-container matColumnDef="imprimir">
                <th id="imprimir" mat-header-cell *matHeaderCellDef> IMPRIMIR </th>
                <td mat-cell *matCellDef="let element">
                    

                    <a color="primary" mat-mini-fab href="{{api}}/ConsultaDescripcionPDF?numero_descripcion={{element?.id_desc_puestos_pk}}" target="_blank" rel="noopener" >
                        <mat-icon class="material-icons">
                            print
                        </mat-icon>
                    </a>

                    
                    
                </td>
            </ng-container>

            <!-- fin contenido -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <!-- fin tabla -->


</mat-card-content>
<mat-card-actions align="start">
    <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"  aria-label="Selecione una pagina">
    </mat-paginator>
</mat-card-actions>
</mat-card>
</div>