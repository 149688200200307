import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DesitionBoxComponent } from 'src/app/modals/desition-box/desition-box.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { SesionService } from 'src/app/services/sesion.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  constructor(private _sesion:SesionService,private route:Router,private _dialog:MatDialog) { }

  ngOnInit(): void {
    console.info(this.route.url);
  }

  validaRuta(){
    return this.route.url.indexOf("login")>0;
  }

  validaMenu(){
    return this.route.url.indexOf("principal")>0;
  }
  salir(){
    const dialogRef = this._dialog.open(DesitionBoxComponent,{
      data:{titulo:"¿Cierre de sesión?",mensaje:"Usted está cerrando la sesión. ¿Desea salir del sistema?",desicion:true}
    });

    dialogRef.afterClosed().subscribe(desition => {
      //console.log('The dialog was closed');
      if(desition){
        this._sesion.logOut();
        this.route.navigate(['/security/login']);
      }
    });
  }

  get esEventosDeVida(){
    if(this.route.url.indexOf("eventos-de-vida")>-1){
      return true;
    }else{
      return false;
    }
  }
  get esBeneficiarias(){
    if(this.route.url.indexOf("beneficiarias")>-1){
      return true;
    }else{
      return false;
    }
  }
  get esCentroInfo(){
    if(this.route.url.indexOf("centro-informacion-institucional")>-1){
      return true;
    }else{
      return false;
    }
  }
  get esAdministrador(){
    if(this.route.url.indexOf("administrador/usuarios")>-1){
      return true;
    }else{
      return false;
    }
  }
  get nombreDeUsuario(){
    if(this._sesion.getSession().getUsuario().nombre!=undefined){
      return this._sesion.getSession().getUsuario().nombre + " " +this._sesion.getSession().getUsuario().apellidoPaterno;
    }
    return "Usuario desconocido";
  }
  get esEvaluaciones(){
    if(this.route.url.indexOf("evaluaciones")>-1){
      return true;
    }else{
      return false;
    }
  }
  get esMedico(){
    if(this.route.url.indexOf("medico/gestor-contactos-area-medica")>-1 /*|| otra condicion */){
      return true;
    }else{
      return false;
    }
  }

  get esMedicoAlmacenMedicamentos(){
    if(this.route.url.indexOf("medico/almacen-de-medicamentos")>-1){
      return true;
    }else{
      return false;
    }
  }

  get esGestorEYT(){
    if(this.route.url.indexOf("medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt")>-1){
      return true;
    }else{
      return false;
    }
  }

  
}
