<div class="container-fluid">
    <mat-card >
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <!--[formGroup]="form"-->
            <form novalidate [formGroup]="form" autocomplete="off">
                <span ><strong>INTERROGATORIO POR APARATOS Y SISTEMAS</strong></span>
                <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12 col-xxl-12 mt-3">
                    
                    <table class="table table-sm  table-bordered">
                        <caption></caption>
                        <thead class="table-dark">
                            <tr>
                                <th  scope="col" class="text-white">INTERROGATORIO</th>
                                <th  scope="col">___</th>
                            </tr>
                        </thead>
                        <tbody class="table-secondary">
                            
                            <tr>
                                
                                <td>Tegumentos (petequias, tatuajes, perforaciones, hematomas, lesiones dermatológicas)</td>
                                <td>
                                <mat-radio-group formControlName="tegumentos">
                                    <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                    <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                </mat-radio-group>
                                </td>
                            </tr>
                            

                            <tr>
                                <td>Ojos (amaurosis, diplopía, dolor, fosfenos, fotofobia, visión borrosa, xeroftalmia)</td>
                                <td>
                                <mat-radio-group formControlName="ojos">
                                    <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                    <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Oídos (acúfenos, hipoacusia, otalgia, otorragia, otorrea, tinitus)</td>
                                <td>
                                    <mat-radio-group formControlName="odios">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Nariz (epistaxis, sinusitis)</td>
                                <td>
                                    <mat-radio-group formControlName="nari">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Boca (gingivorragias, ulceras, caries, pérdidas dentales)</td>
                                <td>
                                    <mat-radio-group formControlName="boca">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Sistema nervioso (cefalea, convulsiones, equilibrio, insomnio, mareo, parálisis, síncope, vértigo)</td>
                                <td>
                                    <mat-radio-group formControlName="sistema_nervioso">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Respiratorio (alteraciones de la voz, cianosis, disnea, dolor torácico, estornudos, hemoptisis, odinotagia, rinorrea, tos)</td>
                                <td>
                                    <mat-radio-group formControlName="respiratorio">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Cardiovascular (dolor precordial, edema, lipotimia, palpitaciones, síncope, disnea por esfuerzo)</td>
                                <td>
                                    <mat-radio-group formControlName="cardio">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Digestivo (estreñimiento, diarrea, dolor o distensión abdominal, flatulencia, meteorismo, náusea, vómito, pirosis)</td>
                                <td>
                                    <mat-radio-group formControlName="digestivo">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Hematológico (equimosis, hemorragias, petequias, anemia)</td>
                                <td>
                                    <mat-radio-group formControlName="hematologo">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Genitourinario (anuria, disuria, enuresis, fimosis, incontinencia, nicturia, oliguria, opsiuria, polaquiuria, poliuria, sangrado genital, tenesmo vesical, urgencia, alteraciones genitales)</td>
                                <td>
                                    <mat-radio-group formControlName="genitouriano">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Músculo esquelético (artralgias, deformidad, mialgias)</td>
                                <td>
                                    <mat-radio-group formControlName="musculo">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Endócrino (bocio, bradilalia, bradipsiquia, alteraciones del desarrollo sexual, galactorrea, ginecomastia, hiperquinesis, intolerancia al calor/frío, letargia, nerviosismo, obesidad, ruborización)</td>
                                <td>
                                    <mat-radio-group formControlName="endcrino">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Gineco-obstétrico (amenorrea, leucorrea, opsomenorrea, proiomenorrea, prurito vulvar, sangrado genital)</td>
                                <td>
                                    <mat-radio-group formControlName="gineco">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Psiquiátrico (afectividad, amnesia, ansiedad, atención, delirios, depresión, emotividad, ideación suicida, pensamiento, voluntad)</td>
                                <td>
                                    <mat-radio-group formControlName="psiqui">
                                        <td><mat-radio-button [value]="1"> Si </mat-radio-button></td>
                                        <td><mat-radio-button [value]="2"> No </mat-radio-button></td>
                                    </mat-radio-group>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row mt-5">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>En caso de responder de forma afirmativa, especificar: </mat-label>
                        <textarea type="text" maxlength="200" #en_caso_espe matInput  formControlName="en_caso_espe" rows="5"></textarea>
                        <mat-error *ngIf="form.controls['en_caso_espe'].hasError('required')">
                            El campo es obligatorio
                        </mat-error>
                        <mat-error *ngIf="form.controls['en_caso_espe'].hasError('maxlength')">
                            El maximo tamaño es de 2500 caracteres
                        </mat-error>
                        <mat-hint align="end">{{en_caso_espe.value.length}} / 2500</mat-hint>
                    </mat-form-field>
                </div>

                <div class="row mt-5">
                    <span ><strong>EXPLORACIÓN FÍSICA DE INGRESO</strong></span>
                </div>
                
                <div class="row mt-3">
                    <div class="col">
                        ANTRIPOMETRÍA
                    </div>
                    <div class="col">
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>peso </mat-label>
                            <input type="text" #antropometria_peso matInput  formControlName="antropometria_peso">
                            <mat-error *ngIf="form.controls['antropometria_peso'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['antropometria_peso'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>-->

                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Peso Kg</mat-label>
                            <input type="text" #antropometria_peso matInput  formControlName="antropometria_peso">
                            <mat-error *ngIf="form.controls['antropometria_peso'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['antropometria_peso'].hasError('pattern')">
                                Formato inválido: Solo 3 enteros y 2 decimales
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>talla M </mat-label>
                            <input type="text" #talla_m matInput  formControlName="talla_m">
                            <mat-error *ngIf="form.controls['talla_m'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['talla_m'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>-->

                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Talla m </mat-label>
                            <input type="text" #talla_m matInput  formControlName="talla_m">
                            <mat-error *ngIf="form.controls['talla_m'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['talla_m'].hasError('pattern')">
                                Formato inválido: Solo 3 enteros y 2 decimales
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>IMC kg/m^2 </mat-label>
                            <input type="text" #imc matInput  formControlName="imc">
                            <mat-error *ngIf="form.controls['imc'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['imc'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>-->
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>IMC Kg/m^2 </mat-label>
                            <input readonly="true" type="text" matInput [value]="(form.value.antropometria_peso / (form.value.talla_m * form.value.talla_m))?.toFixed(4)">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        SIGNOS VITALES
                    </div>
                    <div class="col">
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Presión arterial mmHg </mat-label>
                            <input type="text" #signos_presion matInput  formControlName="signos_presion">
                            <mat-error *ngIf="form.controls['signos_presion'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['signos_presion'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>-->
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Presión arterial mm Hg </mat-label>
                            <input type="text" #signos_presion matInput  formControlName="signos_presion">
                            <mat-error *ngIf="form.controls['signos_presion'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['signos_presion'].hasError('pattern')">
                                Formato inválido: 3 enteros, diagonal y 3 enteros
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>O2SAT% </mat-label>
                            <input type="text" #o_sat matInput  formControlName="o_sat">
                            <mat-error *ngIf="form.controls['o_sat'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['o_sat'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <!--FRECUENCIA CARDIACA-->
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Frecuencia cardiaca LPM </mat-label>
                            <input type="text" #frecuencia matInput  formControlName="frecuencia">
                            <mat-error *ngIf="form.controls['frecuencia'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['frecuencia'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Frecuencia respiratoria RPM </mat-label>
                            <input type="text" #frecuencia_rpm matInput  formControlName="frecuencia_rpm">
                            <mat-error *ngIf="form.controls['frecuencia_rpm'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['frecuencia_rpm'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Temperatura °C </mat-label>
                            <input type="text" #temperatura matInput  formControlName="temperatura">
                            <mat-error *ngIf="form.controls['temperatura'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['temperatura'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>-->
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Temperatura °C </mat-label>
                            <input type="text" #temperatura matInput  formControlName="temperatura">
                            <mat-error *ngIf="form.controls['temperatura'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['temperatura'].hasError('pattern')">
                                Formato inválido: Solo 2 enteros y 1 decimal
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>


                <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12 col-xxl-12 mt-5">
                    <table class="table table-sm  table-bordered">
                        <caption></caption>
                        <thead class="table-dark">
                            <tr>
                                <th  scope="col" class="text-white text-center">EXPLORACIÓN FÍSICA</th>
                                <th  scope="col" class="text-center">NORMAL o ANORMAL</th>
                                <th  scope="col">SÍ SE ENCONTRO ALGUNA ANORMALIDAD, ESPECIFIFCAR: </th>
                            </tr>
                        </thead>
                        <tbody class="table-secondary">
                            <tr>
                                <td>Aspecto general</td>

                                
                                <td>
                                    <mat-radio-group formControlName="aspec_general">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['aspec_general'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="aspec_general_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Piel y mucosas</td>
                                <td>
                                    <mat-radio-group formControlName="piel_mucosas">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['piel_mucosas'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="piel_mucosas_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Cabeza</td>
                                <td>
                                    <mat-radio-group formControlName="cabeza_exp">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['cabeza_exp'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="cabeza_exp_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Oídos</td>
                                <td>
                                    <mat-radio-group formControlName="oidos_exp">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['oidos_exp'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="oidos_exp_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Ojos</td>
                                <td>
                                    <mat-radio-group formControlName="ojos_exp">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['ojos_exp'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="ojos_exp_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Nariz</td>
                                <td>
                                    <mat-radio-group formControlName="nariz_esp">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['nariz_esp'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="nariz_exp_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Boca</td>
                                <td>
                                    <mat-radio-group formControlName="boca_exp">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['boca_exp'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="boca_exp_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Garganta</td>
                                <td>
                                    <mat-radio-group formControlName="garganta_exp">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['garganta_exp'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="garganta_exp_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Cuello</td>
                                <td>
                                    <mat-radio-group formControlName="cuello_exp">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['cuello_exp'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="cuello_exp_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Torax</td>
                                <td>
                                    <mat-radio-group formControlName="torax_exp">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['torax_exp'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="torax_exp_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Abdomen</td>
                                <td>
                                    <mat-radio-group formControlName="abdomen_exp">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['abdomen_exp'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="abdomen_exp_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Extremidades</td>
                                <td>
                                    <mat-radio-group formControlName="extremidades_exp">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['extremidades_exp'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="extremidades_exp_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Neurológico</td>
                                <td>
                                    <mat-radio-group formControlName="neurologico_exp">
                                        <td><mat-radio-button [value]="1"> Normal </mat-radio-button></td><br>
                                        <td><mat-radio-button [value]="2"> Anormal </mat-radio-button></td>
                                    </mat-radio-group>
                                    <mat-error *ngIf="form.controls['neurologico_exp'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                </td>
                                <td>
                                    <mat-form-field appearance="fill"  class="input-full-w">
                                        <mat-label>Especificar </mat-label>
                                        <input type="text"  matInput   formControlName="neurologico_ext_esp">
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <strong>TIPO DE ATENCIÓN</strong>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="d-flex justify-content-start">
                                <span>Tipo de tratamiento </span>
                                <mat-radio-group formControlName="tipo_aten_medico">
                                    &nbsp; 
                                    &nbsp; 
                                    &nbsp; 
                                    <mat-radio-button [value]="1"> Permanente </mat-radio-button>
                                    &nbsp; 
                                    <mat-radio-button [value]="2"> Sin tratamiento </mat-radio-button>
                                </mat-radio-group>
                                <mat-error *ngIf="form.controls['tipo_aten_medico'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        Nota: Registro informativo
                    </div>
                </div>


                <div class="row mt-3">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Diagnóstico a tratar permanente </mat-label>
                        <input  maxlength="2500" #diagnostico_permanente  type="text" matInput  formControlName="diagnostico_permanente" >
                        <mat-hint align="end">{{diagnostico_permanente.value.length}} / 2500</mat-hint>
                    </mat-form-field>
                </div>

                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Especialistas</mat-label>
                            <mat-select formControlName="numero_especialista">
                                <mat-option *ngFor="let p of especialista_catalogo" [value]="p.id_especialista_pk">{{p.especialista}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                        <!--(click)="postModificarEstadoDescripcionFunciones(1, 0, 1)"-->
                        <button  type="button" color="primary"  mat-mini-fab (click)="postRegistroBeneficiarioEspecialista()" *ngIf="accion == 'registrar'">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </form>

            <div class="row"  *ngFor="let especialistas of especialistas">
                <div class="col">
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>Especialista</mat-label>
                        <mat-select [(ngModel)]="especialistas.id_especialista_fk">
                            <mat-option *ngFor="let p of especialista_catalogo" [value]="p.id_especialista_pk">{{p.especialista}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
             
                    <div class="col-xs-2 col-sm-2 col-md-2 col-xl-2 col-xxl-2">
                        <div class="d-flex justify-content-start" *ngIf="accion == 'registrar'">
                            <button (click)="postEliminarBeneficiarioEspecialista(especialistas.id_bene_especialista_pk)" type="button" class="btn btn-small btn-danger">x</button>
                            &nbsp;
                            <button (click)="postModificarBeneficiarioEspecialista(especialistas.id_beneficiario_fk, especialistas.id_especialista_fk, especialistas.id_bene_especialista_pk)"  class="flat" color="primary" mat-flat-button type="button">Guardar</button>
                        </div>
                    </div>
                
            </div>    

            <form novalidate [formGroup]="form" autocomplete="off">

                <div class="row">
                  
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Observaciones </mat-label>
                            <input  maxlength="2500" #obervaciones_text  type="text" matInput  formControlName="obervaciones_text" >
                            <mat-hint align="end">{{obervaciones_text.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                    
                   
                </div>
                
                
            

            </form>

            <div class="mt-2 d-flex justify-content-center gap-2">


                

                <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos']" >
                    Regresar
                </button>

                <form novalidate [formGroup]="form" (ngSubmit)="postRegistroHistorialClinicoDos()" *ngIf="accion == 'registrar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid" >
                        Guardar
                    </button>
                </form>


            </div>

        </mat-card-content>
    </mat-card>
</div>

<!--

    <td><mat-checkbox ></mat-checkbox></td>
                                <td><mat-checkbox ></mat-checkbox></td>
                                <td><mat-checkbox ></mat-checkbox></td>
                                <td><mat-checkbox ></mat-checkbox></td>
                                <td><mat-checkbox ></mat-checkbox></td>
-->
