<div class="card-container">
    <div class="table-container">
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner color="accent"></mat-spinner>
        </div>
        <mat-card class="example-card">
            <mat-card-header>
                <mat-card-title>
                    Egreso / Baja
                </mat-card-title>
                <mat-card-subtitle>
                    
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="formulario" (ngSubmit)="sendForm()" autocomplete="off">
                    <mat-form-field appearance="fill" class="bt col-md-4">
                        <mat-label>Fecha de egreso</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="fechaApertura">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #dp1 disabled="false"></mat-datepicker>
                    </mat-form-field>

                    <div class="bt col-md-12">
                        <mat-form-field appearance="fill" class="bt col-md-4">
                            <mat-label>Motivo de egreso</mat-label>
                            <mat-select formControlName="idMotivoEgreso">
                                <mat-option *ngFor="let motivo of motivosEgreso" value="{{motivo.idParametro}}">{{motivo.valor}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="bt col-md-8">
                            <mat-label>Descripción del motivo de egreso / Conclusiones</mat-label>
                            <textarea matInput placeholder="" formControlName="desMotivoEgreso" maxlength="2000"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="bt col-md-12">
                        <div class="bt col-md-4">
                            <h4>Necesidades de apoyo cognitivas</h4>
                            <mat-form-field appearance="fill" class="bt col-md-6">
                                <mat-label></mat-label>
                                <mat-select formControlName="autonomiaCognitivoSelect" >
                                    <mat-option value="{{ac.idParametro}}" *ngFor="let ac of autonomiasConitivas">{{ac.valor}}</mat-option>
                                </mat-select>
                            </mat-form-field>
            
                            <mat-form-field appearance="fill" class="bt col-md-6">
                                <mat-label></mat-label>
                                <input matInput formControlName="autonomiaCognitivoInput" maxlength="300">
                            </mat-form-field>
                        </div>
            
                        <div class="bt col-md-4">
                            <h4>Necesidades de apoyo motriz</h4>
                            <mat-form-field appearance="fill" class="bt col-md-6">
                                <mat-label></mat-label>
                                <mat-select formControlName="autonomiaMotrizSelect" >
                                    <mat-option value="{{am.idParametro}}" *ngFor="let am of autonomiasMotrices">{{am.valor}}</mat-option>
                                </mat-select>
                            </mat-form-field>
            
                            <mat-form-field appearance="fill" class="bt col-md-6">
                                <mat-label></mat-label>
                                <input matInput formControlName="autonomiaMotrizInput" maxlength="300">
                            </mat-form-field>
                        </div>
            
                        <div class="bt col-md-4">
                            <h4>Diagnóstico emocional</h4>
                            <mat-form-field appearance="fill" class="bt col-md-6">
                                <mat-label></mat-label>
                                <mat-select formControlName="diagnosticoEmocionalSelect" >
                                    <mat-option value="{{ae.idParametro}}" *ngFor="let ae of autonomiasEmocionales">{{ae.valor}}</mat-option>
                                </mat-select>
                            </mat-form-field>
            
                            <mat-form-field appearance="fill" class="bt col-md-6">
                                <mat-label></mat-label>
                                <input matInput formControlName="diagnosticoEmocionalInput" maxlength="300">
                            </mat-form-field>
                        </div>
                    </div>

                    <mat-form-field appearance="fill" class="bt col-md-12">
                        <mat-label>Logros alcanzados</mat-label>
                        <textarea matInput placeholder="" formControlName="desLogrosAlcanzados" maxlength="2000"></textarea>
                    </mat-form-field>
                    
                    <div class="bt col-md-12">
                        <mat-form-field appearance="fill" class="bt col-md-4">
                            <mat-label>Lugar de destino</mat-label>
                            <mat-select formControlName="idLugarDestino">
                                <mat-option *ngFor="let destino of lugaresDestino" value="{{destino.idParametro}}">{{destino.valor}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="bt col-md-8">
                            <mat-label>Detalles del lugar de destino</mat-label>
                            <input matInput formControlName="desLugarDestino" maxlength="2000">
                        </mat-form-field>

                    </div>

                    <div class="bt col-md-12">

                        <div class="bt col-md-6">
                            <mat-form-field appearance="fill" class="bt col-md-12">
                                <mat-label>Oficio de solicitud de egreso</mat-label>
                                <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo.value" formControlName="solicitudEgresoRuta" readonly>
                                <button mat-icon-button matSuffix (click)="archivo.click()" type="button">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <input type="file" #archivo class="hide" formControlName="solicitudEgresoArchivo" (change)="handleFileInput1($event.target.files)"/>
                                
                                <a href="{{hostapi}}/{{documento1}}" *ngIf="documento1" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                                    <mat-icon>download</mat-icon>
                                </a>
                                <mat-hint *ngIf="documento1">Ya existe un documento cargado</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="bt col-md-6">
                            <mat-form-field appearance="fill" class="bt col-md-12">
                                <mat-label>Foto de egreso</mat-label>
                                <input matInput [value]="foto.value" formControlName="fotoEgresoURI" readonly>
                                <button mat-icon-button matSuffix (click)="foto.click()" type="button">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <input type="file" #foto class="hide" formControlName="fotoEgresoArchivo" (change)="handleFileInput($event.target.files)"/>

                                <a href="{{hostapi}}/{{documento2}}" *ngIf="documento2" target="_blank" rel="noopener" matTooltip="Click para ver la foto cargada" color="accent" mat-icon-button matPrefix type="button" >
                                    <mat-icon>download</mat-icon>
                                </a>
                                <mat-hint *ngIf="documento2">Ya existe una foto cargada</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <br><br>
                    <div>
                        <button type="submit" color="primary" mat-flat-button class="flat" *ngIf="indEstatus == 'A'">Guardar</button> &nbsp;
                        <button type="button" color="accent" mat-flat-button class="flat" (click)="cerrarEtapa()" *ngIf="indEstatus == 'A'" [disabled]="formulario.invalid">Cerrar etapa</button>&nbsp;
                        <button type="button" color="default" mat-stroked-button (click)="cancelar()" class="flat" >Cancelar</button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>