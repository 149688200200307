import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ParametrosService } from 'src/app/services/parametros.service';

@Component({
  selector: 'app-vista-situacion-general',
  templateUrl: './vista-situacion-general.component.html',
  styleUrls: ['./vista-situacion-general.component.scss']
})
export class VistaSituacionGeneralComponent implements OnInit {
  hostapi:string;
  sacramentos = ["Bautismo","Primera comunión","Confirmación"];
  constructor(private dialog:MatDialog,
    private _parametrosService:ParametrosService,
    private activatedRoute:ActivatedRoute,
    private router: Router, 
    private _documentosService:DocumentosService,
    private _confg:ConfigurationService) { 
      this.hostapi=_confg.getConfiguration().hostapi;
    }

    idBeneficiaria= 0;
  formulario = new UntypedFormGroup({
    situacionJuridica : new UntypedFormControl("",[]),
    sjDescripcion : new UntypedFormControl("",[]),
    convivencias : new UntypedFormControl("",[]),
    convivenciasDescripcion : new UntypedFormControl("",[]),
    escolaridad : new UntypedFormControl("",[]),
    ultimoGrado : new UntypedFormControl("",[]),
    comprobanteDeEstudios: new UntypedFormControl("",[]),
    ceArchivo:new UntypedFormControl("",[]),

    nombreDocumento1:new UntypedFormControl("",[]),
    rutaDocumento1:new UntypedFormControl("",[]),
    documento1Archivo: new UntypedFormControl("",[]),

    nombreDocumento2:new UntypedFormControl("",[]),
    rutaDocumento2:new UntypedFormControl("",[]),
    documento2Archivo:new UntypedFormControl("",[]),

    nombreDocumento3:new UntypedFormControl("",[]),
    rutaDocumento3:new UntypedFormControl("",[]),
    documento3Archivo:new UntypedFormControl("",[]),
    bautismo:new UntypedFormControl(false,[]),
    comunion:new UntypedFormControl(false,[]),
    confirmacion:new UntypedFormControl(false,[]),

  })
  fileToUpload: File = null;
  fileToUpload1: File = null;
  fileToUpload2: File = null;
  fileToUpload3: File = null;
  fileToUpload4: File = null;
  fileToUpload5: File = null;
  isLoading = false;
  vsg_handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  vsg_handleFileInput1(files: FileList) {
    this.fileToUpload1 = files.item(0);
  }
  vsg_handleFileInput2(files: FileList) {
    this.fileToUpload2 = files.item(0);
  }
  vsg_handleFileInput3(files: FileList) {
    this.fileToUpload3 = files.item(0);
  }
  vsg_handleFileInput4(files: FileList) {
    this.fileToUpload4 = files.item(0);
  }
  vsg_handleFileInput5(files: FileList) {
    this.fileToUpload5 = files.item(0);
  }
  ngOnInit(): void {
    this.vsg_cargaCatalogos();
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info("Parametros");
      console.info(params["id"]);
      
        this.idBeneficiaria= params["id"];
        
        
        this.vsg_getDocumentos(this.idBeneficiaria);
      
      
    });
  }

  vsg_sendForm(){
    this.vsg_cargarListaDoc();
    //this.vsg_cargarDocumentos();
  }
  situacionJuridica:any;
  escolaridad:any;
  vsg_cargaCatalogos(){
    //param motivo ingreso
    this._parametrosService.getParametros("13").subscribe(result=>{
      this.escolaridad = result.data.parametros;
    });

    this._parametrosService.getParametros("12").subscribe(result=>{
      this.situacionJuridica = result.data.parametros;
    });
  }

  arreglo = [];
  vsg_cargarArreglo(tipoDocumento:string,idTipoCatalogo:string,desTipoCatalogo:string,file:File){
    this.arreglo.push({"file":file,"idBeneficiaria":this.idBeneficiaria.toString(),"idTipoCatalogo":idTipoCatalogo,"desTipoCatalogo":desTipoCatalogo,"tipoDocumento":tipoDocumento});
  }

  

  vsg_cargarListaDoc(){
    return new Promise((resolve, reject) => {

      this.vsg_cargarArreglo("63",this.formulario.controls.escolaridad.value,this.formulario.controls.ultimoGrado.value,this.fileToUpload1);
      this.vsg_cargarArreglo("81","",this.formulario.controls.nombreDocumento1.value,this.fileToUpload2);
      this.vsg_cargarArreglo("82","",this.formulario.controls.nombreDocumento2.value,this.fileToUpload3);
      this.vsg_cargarArreglo("83","",this.formulario.controls.nombreDocumento3.value,this.fileToUpload4);

      this.vsg_cargarArreglo("85",this.formulario.controls.situacionJuridica.value,this.formulario.controls.sjDescripcion.value,null);
      this.vsg_cargarArreglo("86",this.formulario.controls.convivencias.value,this.formulario.controls.convivenciasDescripcion.value,null);
      console.info(this.formulario.controls.bautismo.value+","+this.formulario.controls.comunion.value+","+this.formulario.controls.confirmacion.value);
      this.vsg_cargarArreglo("90","",this.formulario.controls.bautismo.value+","+this.formulario.controls.comunion.value+","+this.formulario.controls.confirmacion.value,null);
      let formData = new FormData();
      formData.append('contador', "7");
      for(let i=0; i<this.arreglo.length; i++){
        if(i==0){
          formData.append('documento', this.arreglo[i].file);
          formData.append('idBeneficiaria', this.idBeneficiaria.toString());
          formData.append('idTipoDocumento', this.arreglo[i].tipoDocumento);
          formData.append('idTipoCatalogo', this.arreglo[i].idTipoCatalogo);
          formData.append('desTipoCatalogo', this.arreglo[i].desTipoCatalogo);
        }else{
          formData.append('documento'+i, this.arreglo[i].file);
          formData.append('idBeneficiaria'+i, this.idBeneficiaria.toString());
          formData.append('idTipoDocumento'+i, this.arreglo[i].tipoDocumento);
          formData.append('idTipoCatalogo'+i, this.arreglo[i].idTipoCatalogo);
          formData.append('desTipoCatalogo'+i, this.arreglo[i].desTipoCatalogo);
        }
      }

      this.isLoading=true;
      this._documentosService.saveMulti(formData).subscribe(result=>{
        console.info(result);
        this.isLoading=false;
        this.vsg_mensajeDeExito2();
        resolve(true);
      },()=>{
        this.isLoading=false;
        resolve(true);
      })
        
   
      
    });
  }

  vsg_cargarDocumentos(){
    
    
    this.vsg_altaDocumento("63",this.formulario.controls.escolaridad.value,this.formulario.controls.ultimoGrado.value,this.fileToUpload1).then(()=>{
      this.vsg_altaDocumento("81","",this.formulario.controls.nombreDocumento1.value,this.fileToUpload2).then(()=>{
        this.vsg_altaDocumento("82","",this.formulario.controls.nombreDocumento2.value,this.fileToUpload3).then(()=>{
          this.vsg_altaDocumento("83","",this.formulario.controls.nombreDocumento3.value,this.fileToUpload4).then(()=>{

            this.vsg_mensajeDeExito2();
              
          })
        })
      })
    });
  }

  vsg_altaDocumento(tipoDocumento:string,idTipoCatalogo:string,desTipoCatalogo:string,file:File){
    
    return new Promise((resolve, reject) => {
      //if(file==null){
      //  resolve(true);
      //}else{
        let formData = new FormData();
        formData.append('documento', file);
        formData.append('idBeneficiaria', this.idBeneficiaria.toString());
        formData.append('idTipoDocumento', tipoDocumento);
        formData.append('idTipoCatalogo', idTipoCatalogo);
        formData.append('desTipoCatalogo', desTipoCatalogo);
        this.isLoading=true;
        this._documentosService.save(formData).subscribe(result=>{
          console.info(result);
          this.isLoading=false;
          resolve(true);
        },()=>{
          this.isLoading=false;
          resolve(true);
        })
      //}
      
    });
  }

  
  vsg_mensajeDeExito(){
    const dialogRef= this.dialog.open(MessageBoxComponent,{
      data:{titulo: "Expediente creado" ,mensaje: "Se ha creado un nuevo expediente"}
    });
    this.router.navigate(["/beneficiarias"]);
  }
  vsg_mensajeDeExito2(){
    const dialogRef= this.dialog.open(MessageBoxComponent,{
      data:{titulo: "Expediente modificado" ,mensaje: "Se ha modificado el expediente correctamente"}
    });
    dialogRef.afterClosed().subscribe(()=>{
      document.getElementById("resetForm").click();
      this.ngOnInit();
    })
  }
  documentos:any;
  documento1 = null;
  documento2 = null;
  documento3 = null;
  documento4 = null;
  documento5 = null;
  vsg_getDocumentos(id:number){
    this.isLoading = true;
    this._documentosService.getAllByBeneficiaria(id).subscribe(result=>{
      this.isLoading = false;
      this.documentos = result.data.documentos;

      for(let i = 0; i< this.documentos.length; i++){
        if(this.documentos[i].idTipoDocumento == 63){
          this.formulario.controls.escolaridad.setValue(this.documentos[i].idTipoCatalogo);
          this.formulario.controls.ultimoGrado.setValue(this.documentos[i].desTipoCatalogo);
          //this.formulario.controls.comprobanteDeEstudios.setValue(this.documentos[i].docTipoCatalogo);

          this.documento1 = this.documentos[i].docTipoCatalogo;
        }else if(this.documentos[i].idTipoDocumento == 81){
          this.formulario.controls.nombreDocumento1.setValue(this.documentos[i].desTipoCatalogo);
          //this.formulario.controls.rutaDocumento1.setValue(this.documentos[i].docTipoCatalogo);
          
          this.documento2 = this.documentos[i].docTipoCatalogo;
        }else if(this.documentos[i].idTipoDocumento == 82){
          this.formulario.controls.nombreDocumento2.setValue(this.documentos[i].desTipoCatalogo);
          //this.formulario.controls.rutaDocumento2.setValue(this.documentos[i].docTipoCatalogo);
          
          this.documento3 = this.documentos[i].docTipoCatalogo;
        }else if(this.documentos[i].idTipoDocumento == 83){
          this.formulario.controls.nombreDocumento3.setValue(this.documentos[i].desTipoCatalogo);
          //this.formulario.controls.rutaDocumento3.setValue(this.documentos[i].docTipoCatalogo);
          
          this.documento4 = this.documentos[i].docTipoCatalogo;
        }else if(this.documentos[i].idTipoDocumento == 85){
          this.formulario.controls.situacionJuridica.setValue(this.documentos[i].idTipoCatalogo);
          this.formulario.controls.sjDescripcion.setValue(this.documentos[i].desTipoCatalogo);
          //this.formulario.controls.rutaDocumento3.setValue(this.documentos[i].docTipoCatalogo);
          
        }else if(this.documentos[i].idTipoDocumento == 86){
          this.formulario.controls.convivencias.setValue(this.documentos[i].idTipoCatalogo);
          this.formulario.controls.convivenciasDescripcion.setValue(this.documentos[i].desTipoCatalogo);
          //this.formulario.controls.rutaDocumento3.setValue(this.documentos[i].docTipoCatalogo);

        }else if(this.documentos[i].idTipoDocumento == 90){
          this.vsg_docType90(i);
        }
      }
      this.formulario.controls.escolaridad.disable();
      this.formulario.controls.ultimoGrado.disable();
      this.formulario.controls.comprobanteDeEstudios.disable();
      this.formulario.controls.nombreDocumento1.disable();
      this.formulario.controls.rutaDocumento1.disable();
      this.formulario.controls.nombreDocumento2.disable();
      this.formulario.controls.rutaDocumento2.disable();
      this.formulario.controls.nombreDocumento3.disable();
      this.formulario.controls.rutaDocumento3.disable();
      this.formulario.controls.situacionJuridica.disable();
      this.formulario.controls.sjDescripcion.disable();
      this.formulario.controls.convivencias.disable();
      this.formulario.controls.convivenciasDescripcion.disable();
      this.formulario.controls.bautismo.disable();
      this.formulario.controls.comunion.disable();
      this.formulario.controls.confirmacion.disable();
      
    },()=>{
      this.isLoading = false;
    })
  }
  
  public vsg_docType90(i){
    this.formulario.controls.bautismo.setValue(this.documentos[i].desTipoCatalogo.split(",")[0] == "true" ? true:false);
    this.formulario.controls.comunion.setValue(this.documentos[i].desTipoCatalogo.split(",")[1]  == "true" ? true:false);
    this.formulario.controls.confirmacion.setValue(this.documentos[i].desTipoCatalogo.split(",")[2]  == "true" ? true:false);
  }

}
