import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import localeMX from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon'; 
import {MatButtonModule} from '@angular/material/button';
import { HeaderComponent } from './structure/header/header.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatMenuModule} from '@angular/material/menu'; 

import {MatTooltipModule} from '@angular/material/tooltip';


import {MatSelectModule} from '@angular/material/select'; 

import {MatInputModule} from '@angular/material/input'; 

import {MatFormFieldModule} from '@angular/material/form-field'; 

import {MatCardModule} from '@angular/material/card'; 
import {MatListModule} from '@angular/material/list';
import { LoginComponent } from './pages/login/login.component';
import { PrincipalComponent } from './pages/principal/principal.component';
import { ModuleSelectorComponent } from './pages/module-selector/module-selector.component'; 


import {MatTabsModule} from '@angular/material/tabs'; 



import {MatRadioModule} from '@angular/material/radio'; 
import {MatButtonToggleModule} from '@angular/material/button-toggle'; 

import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator'; 


import {MatDatepickerModule} from '@angular/material/datepicker'; 

import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatTableModule} from '@angular/material/table'; 
import {MatGridListModule} from '@angular/material/grid-list';
import { GestorBeneficiariaComponent } from './pages/gestor-beneficiaria/gestor-beneficiaria.component';
import { DatosGeneralesComponent } from './pages/datos-generales/datos-generales.component';
import { InfoGeneralComponent } from './pages/datos-generales/info-general/info-general.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MessageBoxComponent } from './modals/message-box/message-box.component';
import { DesitionBoxComponent } from './modals/desition-box/desition-box.component';
import { PerfilComponent } from './pages/datos-generales/perfil/perfil.component';
import { SituacionGeneralComponent } from './pages/datos-generales/situacion-general/situacion-general.component';
import { DocumentosComponent } from './pages/datos-generales/documentos/documentos.component';
import {MatExpansionModule} from '@angular/material/expansion';

import { ConfigurationService } from './services/configuration.service';
import { SesionService } from './services/sesion.service';
import { getSpanishPaginatorIntl } from './spanish-paginator-intl';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './http-error.interceptor';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { ErrorSnackBarComponent } from './snacks/error-snack-bar/error-snack-bar.component'; 
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
import {MatDialogModule} from '@angular/material/dialog'; 
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { UsuariosComponent } from './pages/administrador/usuarios/usuarios.component';
import { AbcUsuariosComponent } from './pages/administrador/abc-usuarios/abc-usuarios.component';
import { ForgottenPasswordComponent } from './modals/forgotten-password/forgotten-password.component';
import { EvaluacionesComponent } from './pages/evaluaciones/evaluaciones.component';
import { EventosDeVidaComponent } from './pages/eventos-de-vida/eventos-de-vida.component';
import { EtapasDeAtencionComponent } from './pages/etapas-de-atencion/etapas-de-atencion.component';
import { CandidataComponent } from './pages/etapas-de-atencion/candidata/candidata.component';
import { ApruebaComponent } from './pages/etapas-de-atencion/aprueba/aprueba.component';
import { SanacionComponent } from './pages/etapas-de-atencion/sanacion/sanacion.component';
import { DesarrolloComponent } from './pages/etapas-de-atencion/desarrollo/desarrollo.component';
import { PreparacionComponent } from './pages/etapas-de-atencion/preparacion/preparacion.component';
import {MatChipsModule} from '@angular/material/chips';
import { NuevoEventoComponent } from './pages/eventos-de-vida/nuevo-evento/nuevo-evento.component';
import { CommonModule } from '@angular/common';
import { EgresoComponent } from './pages/datos-generales/egreso/egreso.component';
import { EgresoComponent2 } from './pages/etapas-de-atencion/egreso/egreso.component';
import {MatBadgeModule} from '@angular/material/badge';
import { SplitPipe } from './pipes/split.pipe';
import { GestorDePruebasComponent } from './pages/evaluaciones/gestor-de-pruebas/gestor-de-pruebas.component';
import {MatStepperModule} from '@angular/material/stepper';
import { PruebaComponent } from './pages/evaluaciones/gestor-de-pruebas/prueba/prueba.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { LightboxComponent } from './modals/lightbox/lightbox.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { ChartsModule } from 'ng2-charts';
import { CentroDeInformacionInstitucionalComponent } from './pages/centro-de-informacion-institucional/centro-de-informacion-institucional.component';
import { NuevoDocumentoComponent } from './pages/centro-de-informacion-institucional/nuevo-documento/nuevo-documento.component';
import { ContactosComponent } from './pages/contactos/contactos.component';
import { AltaContactoComponent } from './pages/contactos/alta-contacto/alta-contacto.component';
import { FooterComponent } from './structure/footer/footer.component';
import { NoGrantComponent } from './errors/no-grant/no-grant.component';
import { VistaDocumentoComponent } from './pages/centro-de-informacion-institucional/vista-documento/vista-documento.component';
import { VistaContactoComponent } from './pages/contactos/vista-contacto/vista-contacto.component';
import { VistaDatosGeneralesComponent } from './pages/vista-datos-generales/vista-datos-generales.component';
import { VistaInfoGeneralComponent } from './pages/vista-datos-generales/vista-info-general/vista-info-general.component';
import { VistaPerfilComponent } from './pages/vista-datos-generales/vista-perfil/vista-perfil.component';
import { VistaSituacionGeneralComponent } from './pages/vista-datos-generales/vista-situacion-general/vista-situacion-general.component';
import { VistaDocumentosComponent } from './pages/vista-datos-generales/vista-documentos/vista-documentos.component';
import { VistaEgresoComponent } from './pages/vista-datos-generales/vista-egreso/vista-egreso.component';
import { RrhhComponent } from './pages/rrhh/rrhh.component';
import { GestorPerfilesYDescripcionPuestosComponent } from './pages/rrhh/gestor-perfiles-y-descripcion-puestos/gestor-perfiles-y-descripcion-puestos.component';
import { ContenedorPuestosDeTrabajoComponent } from './pages/rrhh/contenedor-puestos-de-trabajo/contenedor-puestos-de-trabajo.component';
import { RrhhDescripcionDePuestoComponent } from './pages/rrhh/rrhh-descripcion-de-puesto/rrhh-descripcion-de-puesto.component';
import { RrhhPerfilDePuestoComponent } from './pages/rrhh/rrhh-perfil-de-puesto/rrhh-perfil-de-puesto.component';
import { GestorRrhhAreaDepartamentoComponent } from './pages/rrhh/gestor-rrhh-area-departamento/gestor-rrhh-area-departamento.component';
import { AccionesRrhhAreaDepartamentoComponent } from './pages/rrhh/acciones-rrhh-area-departamento/acciones-rrhh-area-departamento.component';
import { AccionesRrhhPuestosComponent } from './pages/rrhh/acciones-rrhh-puestos/acciones-rrhh-puestos.component';
import { GestorRrhhPuestosComponent } from './pages/rrhh/gestor-rrhh-puestos/gestor-rrhh-puestos.component';
import { RrhhFuncionesComponent } from './pages/rrhh/rrhh-funciones/rrhh-funciones.component';
import { GestorRrhhOrganigramaDocsComponent } from './pages/rrhh/gestor-rrhh-organigrama-docs/gestor-rrhh-organigrama-docs.component';
import { AccionesRrhhOrganigramaDocsComponent } from './pages/rrhh/acciones-rrhh-organigrama-docs/acciones-rrhh-organigrama-docs.component';
import { MedicoComponent } from './pages/medico/medico.component';
import { GestorDirectorioMedicoComponent } from './pages/medico/gestor-directorio-medico/gestor-directorio-medico.component';
import { GestorContactosAreaMedicaComponent } from './pages/medico/gestor-directorio-medico/gestor-contactos-area-medica/gestor-contactos-area-medica.component';
import { AccionesContactosAreaMedicaComponent } from './pages/medico/gestor-directorio-medico/acciones-contactos-area-medica/acciones-contactos-area-medica.component';
import { AlmacenDeMedicamentosComponent } from './pages/medico/almacen-de-medicamentos/almacen-de-medicamentos.component';
import { GestorAlmInventarioComponent } from './pages/medico/almacen-de-medicamentos/gestor-alm-inventario/gestor-alm-inventario.component';
import { AccionesAlmInventarioComponent } from './pages/medico/almacen-de-medicamentos/acciones-alm-inventario/acciones-alm-inventario.component';
import { GestorAlmIncrementoInventarioComponent } from './pages/medico/almacen-de-medicamentos/gestor-alm-incremento-inventario/gestor-alm-incremento-inventario.component';
import { AccionesAlmIncrementoInventarioComponent } from './pages/medico/almacen-de-medicamentos/acciones-alm-incremento-inventario/acciones-alm-incremento-inventario.component';
import { GestorAlmSalidaInventarioComponent } from './pages/medico/almacen-de-medicamentos/gestor-alm-salida-inventario/gestor-alm-salida-inventario.component';
import { AccionesAlmSalidaInventarioComponent } from './pages/medico/almacen-de-medicamentos/acciones-alm-salida-inventario/acciones-alm-salida-inventario.component';
import { GestorRrhhExpedientesDePersonalComponent } from './pages/rrhh/gestor-rrhh-expedientes-de-personal/gestor-rrhh-expedientes-de-personal.component';
import { ContenedorExpedientePersonalComponent } from './pages/rrhh/contenedor-expediente-personal/contenedor-expediente-personal.component';
import { RrhhExpedienteInformacionEmpleadoComponent } from './pages/rrhh/rrhh-expediente-informacion-empleado/rrhh-expediente-informacion-empleado.component';
import { RrhhExpedienteDocumentosComponent } from './pages/rrhh/rrhh-expediente-documentos/rrhh-expediente-documentos.component';
import { ExpedientesMedicosComponent } from './pages/medico/expedientes-medicos/expedientes-medicos.component';
import { ContenedorExpedientesMedicosComponent } from './pages/medico/expedientes-medicos/contenedor-expedientes-medicos/contenedor-expedientes-medicos.component';
import { HistorialClinico1Component } from './pages/medico/expedientes-medicos/historial-clinico1/historial-clinico1.component';
import { HistorialClinico2Component } from './pages/medico/expedientes-medicos/historial-clinico2/historial-clinico2.component';
import { GestorExpedientesMedicosComponent } from './pages/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-expedientes-medicos.component';
import { EventosMedicos1Component } from './pages/medico/expedientes-medicos/eventos-medicos1/eventos-medicos1.component';
import { TratamientosMedicos1Component } from './pages/medico/expedientes-medicos/tratamientos-medicos1/tratamientos-medicos1.component';
import { GestorEytMedicos1Component } from './pages/medico/expedientes-medicos/gestor-eyt-medicos1/gestor-eyt-medicos1.component';
import { EvMedGenComponent } from './pages/medico/ev-med-gen/ev-med-gen.component';
import { GtControlGrupalPesoTallaComponent } from './pages/medico/ev-med-gen/gt-control-grupal-peso-talla/gt-control-grupal-peso-talla.component';
import { AcControlGrupalPesoTallaComponent } from './pages/medico/ev-med-gen/ac-control-grupal-peso-talla/ac-control-grupal-peso-talla.component';
import { AcControlGrupalPesoTallaBenComponent } from './pages/medico/ev-med-gen/ac-control-grupal-peso-talla-ben/ac-control-grupal-peso-talla-ben.component';
import { BibliotecaGeneralComponent } from './pages/biblioteca-general/biblioteca-general.component';
import { AccionesBibliotecaGeneralComponent } from './pages/biblioteca-general/acciones-biblioteca-general/acciones-biblioteca-general.component';
import { AcControlVacunacionComponent } from './pages/medico/expedientes-medicos/ac-control-vacunacion/ac-control-vacunacion.component';
import { RrhhFichaInformativaComponent } from './pages/rrhh/rrhh-ficha-informativa/rrhh-ficha-informativa.component';
import { TerCamComponent } from './pages/medico/ter-cam/ter-cam.component';
import { Gt1TerCampComponent } from './pages/medico/ter-cam/gt1-ter-camp/gt1-ter-camp.component';
import { Gt2TerCampComponent } from './pages/medico/ter-cam/gt2-ter-camp/gt2-ter-camp.component';
import { AcTerCampComponent } from './pages/medico/ter-cam/ac-ter-camp/ac-ter-camp.component';
import { BitacoraComponent } from './pages/medico/bitacora/bitacora.component';
import { AcBitaComponent } from './pages/medico/bitacora/ac-bita/ac-bita.component';
import { GtBitaComponent } from './pages/medico/bitacora/gt-bita/gt-bita.component';
import { VacacionesComponent } from './pages/rrhh/vacaciones/vacaciones.component';
import { AcVacaComponent } from './pages/rrhh/vacaciones/ac-vaca/ac-vaca.component';
import { GtVacaComponent } from './pages/rrhh/vacaciones/gt-vaca/gt-vaca.component';
import { CalendarioComponent } from './pages/calendario/calendario.component';


//calendario
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { GtCaleComponent } from './pages/calendario/gt-cale/gt-cale.component';
import { AcCaleComponent } from './pages/calendario/ac-cale/ac-cale.component';
import { ModalCalForm1Component } from './pages/calendario/modal-cal-form1/modal-cal-form1.component';
import { ModalCalForm2Component } from './pages/calendario/modal-cal-form2/modal-cal-form2.component';
import { CatalogoInventarioComponent } from './pages/medico/catalogo-inventario/catalogo-inventario.component';
import { AcCatalogoInventarioComponent } from './pages/medico/ac-catalogo-inventario/ac-catalogo-inventario.component'; // a plugin!
//import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ImpresionExpedienteMedicoComponent } from './pages/medico/expedientes-medicos/impresion-expediente-medico/impresion-expediente-medico.component';
import { RrhhIncidenciasComponent } from './pages/rrhh/rrhh-incidencias/rrhh-incidencias.component';
import { RrhhGtIncidenciasComponent } from './pages/rrhh/rrhh-gt-incidencias/rrhh-gt-incidencias.component';
import { RrhhCapacitacionesComponent } from './pages/rrhh/rrhh-capacitaciones/rrhh-capacitaciones.component';
import { RrhhGtCapacitacionesComponent } from './pages/rrhh/rrhh-gt-capacitaciones/rrhh-gt-capacitaciones.component';
import { RrhhCapacitacionIndividualComponent } from './pages/rrhh/rrhh-capacitacion-individual/rrhh-capacitacion-individual.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);




export function initConfig(config: ConfigurationService,_session:SesionService) {
  return () => config.load().then(
    value=>{
      if(config.getConfiguration()){
        console.info("**This is the configuration");
        console.info(config.getConfiguration());
      }
    },error=>{
      console.log(error);
    }
  );
}

declare global{
 interface Navigator{
    msSaveBlob:(blob: Blob,fileName:string) => boolean
    }
 }
 registerLocaleData(localeMX, 'es-MX');
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    PrincipalComponent,
    ModuleSelectorComponent,
    GestorBeneficiariaComponent,
    DatosGeneralesComponent,
    InfoGeneralComponent,
    MessageBoxComponent,
    DesitionBoxComponent,
    PerfilComponent,
    SituacionGeneralComponent,
    DocumentosComponent,
    EgresoComponent,
    ErrorSnackBarComponent,
    UsuariosComponent,
    AbcUsuariosComponent,
    ForgottenPasswordComponent,
    EvaluacionesComponent,
    EventosDeVidaComponent,
    EtapasDeAtencionComponent,
    CandidataComponent,
    ApruebaComponent,
    SanacionComponent,
    DesarrolloComponent,
    PreparacionComponent,
    NuevoEventoComponent,
    EgresoComponent2,
    SplitPipe,
    GestorDePruebasComponent,
    PruebaComponent,
    LightboxComponent,
    PieChartComponent,
    CentroDeInformacionInstitucionalComponent,
    NuevoDocumentoComponent,
    ContactosComponent,
    AltaContactoComponent,
    FooterComponent,
    NoGrantComponent,
    VistaDocumentoComponent,
    VistaContactoComponent,
    VistaDatosGeneralesComponent,
    VistaInfoGeneralComponent,
    VistaPerfilComponent,
    VistaSituacionGeneralComponent,
    VistaDocumentosComponent,
    VistaEgresoComponent,
    RrhhComponent,
    GestorPerfilesYDescripcionPuestosComponent,
    ContenedorPuestosDeTrabajoComponent,
    RrhhDescripcionDePuestoComponent,
    RrhhPerfilDePuestoComponent,
    GestorRrhhAreaDepartamentoComponent,
    AccionesRrhhAreaDepartamentoComponent,
    AccionesRrhhPuestosComponent,
    GestorRrhhPuestosComponent,
    RrhhFuncionesComponent,
    GestorRrhhOrganigramaDocsComponent,
    AccionesRrhhOrganigramaDocsComponent,
    MedicoComponent,
    GestorDirectorioMedicoComponent,
    GestorContactosAreaMedicaComponent,
    AccionesContactosAreaMedicaComponent,
    AlmacenDeMedicamentosComponent,
    GestorAlmInventarioComponent,
    AccionesAlmInventarioComponent,
    GestorAlmIncrementoInventarioComponent,
    AccionesAlmIncrementoInventarioComponent,
    GestorAlmSalidaInventarioComponent,
    AccionesAlmSalidaInventarioComponent,
    GestorRrhhExpedientesDePersonalComponent,
    ContenedorExpedientePersonalComponent,
    RrhhExpedienteInformacionEmpleadoComponent,
    RrhhExpedienteDocumentosComponent,
    ExpedientesMedicosComponent,
    ContenedorExpedientesMedicosComponent,
    HistorialClinico1Component,
    HistorialClinico2Component,
    GestorExpedientesMedicosComponent,
    EventosMedicos1Component,
    TratamientosMedicos1Component,
    GestorEytMedicos1Component,
    EvMedGenComponent,
    GtControlGrupalPesoTallaComponent,
    AcControlGrupalPesoTallaComponent,
    AcControlGrupalPesoTallaBenComponent,
    BibliotecaGeneralComponent,
    AccionesBibliotecaGeneralComponent,
    AcControlVacunacionComponent,
    RrhhFichaInformativaComponent,
    TerCamComponent,
    Gt1TerCampComponent,
    Gt2TerCampComponent,
    AcTerCampComponent,
    BitacoraComponent,
    AcBitaComponent,
    GtBitaComponent,
    VacacionesComponent,
    AcVacaComponent,
    GtVacaComponent,
    CalendarioComponent,
    GtCaleComponent,
    AcCaleComponent,
    ModalCalForm1Component,
    ModalCalForm2Component,
    CatalogoInventarioComponent,
    AcCatalogoInventarioComponent,
    ImpresionExpedienteMedicoComponent,
    RrhhIncidenciasComponent,
    RrhhGtIncidenciasComponent,
    RrhhCapacitacionesComponent,
    RrhhGtCapacitacionesComponent,
    RrhhCapacitacionIndividualComponent,
  ],
  imports: [
    ChartsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatGridListModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    HttpClientModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatChipsModule,
    CommonModule,
    MatBadgeModule,
    MatStepperModule,
    MatExpansionModule,
    MatAutocompleteModule,

    FullCalendarModule // register FullCalendar with you app
  ],
  providers: [
    ConfigurationService,
    SesionService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [ConfigurationService,SesionService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true  },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
