<div class="container-fluid">
    <mat-card >
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <app-datos-generales></app-datos-generales>
            <!-- i form -->
            <form novalidate [formGroup]="form" autocomplete="off" >
                <div class="d-flex justify-content-between fs-4"><strong>TRATAMIENTOS</strong> <!--<span class="fs-4"> Agregar Tratamiento</span>--> </div>
                <div class="row">
                    <div class="row mt-3">
                        <br>
                        <div class="col">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>ID Tratamiento </mat-label>
                                <input type="text"  matInput formControlName="numero_tratamiento" >
                                <mat-error *ngIf="form.controls['numero_tratamiento'].hasError('pattern')">
                                    Formato a crear: GTTI-EM-Numero-CualquierCaracter
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field appearance="fill" class="input-full-w">
                                <mat-label>Tipo de tratamiento</mat-label>
                                <mat-select formControlName="numero_tratamiento_medico">
                                    <mat-option *ngFor="let p of tratamientos2" [value]="p.id_tratamiento_pk">{{p.tratamiento}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col" *ngIf="form.value.numero_tratamiento_medico == 9">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>Otro </mat-label>
                                <input type="text"  matInput formControlName="otro" >
                            </mat-form-field>
                        </div>
                       
                        <div class="col-xs-12 col-sm-12 col-md-1 col-xl-1 col-xxl-1 mt-1 d-flex justify-content-center">
                            
                            <button *ngIf="valAddTra() && accion == 'registrar' && !form.controls['numero_tratamiento'].hasError('pattern')"  type="button" color="primary"  mat-mini-fab (click)="postRegistrarTratamiento()" >
                                <mat-icon>add</mat-icon>
                            </button>
                            <span class="text-danger text-center" *ngIf="!valAddTra()">
                                No olvide concluir su tratamiento para agregar uno nuevo
                            </span>
                        </div>
    
    
                    </div>
                </div>
                
            </form>
            <!-- f form -->
            <div class="row" *ngFor="let d of data?.tratamientos; let i = index">
                <div class="row mt-5">
                    .
                </div>
                <div class="row">
                    <div class="col">
                        <span ><strong>Tratamiento {{i + 1}}</strong></span>
                    </div>
                    <div class="col">
                        <div class="d-flex justify-content-end gap-2" *ngIf="d.id_estatus_medico_fk == 1">
                            <form novalidate (ngSubmit)="postRegistrarIndicacionesGenerales(i)" *ngIf="accion == 'registrar'">
                                <button class="btn btn-small" color="primary" mat-flat-button type="submit">Guardar Tratamiento</button>
                            </form>
        
                            <button *ngIf="d.id_estatus_medico_fk == 1 && accion == 'registrar'" (click)="postModificarTratamientoEstado(i)" class="btn btn-small" color="primary" mat-flat-button type="button">Concluir tratamiento</button>
        
                            
                        </div>
                    </div>
                </div>
                
                <div class="row mt-3">
                   
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>ID Tratamiento </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.id_trata" >
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field style="display: none !important;" appearance="fill" class="input-full-w">
                            <mat-label class="mat-select-disabled">Tipo de tratamiento</mat-label>
                            <mat-select [(ngModel)]="d.id_tratamiento_medico_fk" [disabled]="true" class="mat-select-disabled">
                                <mat-option *ngFor="let p of tratamientos" [value]="p.id_tratamiento_pk">{{p.tratamiento}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Tipo de tratamiento </mat-label>
                            <input type="text"  matInput readonly="true" [value]="getNombreTratamiento(d.id_tratamiento_medico_fk)" >
                        </mat-form-field>
                        
                    </div>
                    <div class="col" *ngIf="d.id_tratamiento_medico_fk == 9">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Otro </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.otro" >
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-1 col-xl-1 col-xxl-1 mt-1">
                        <button *ngIf="d.id_estatus_medico_fk == 1 && accion == 'registrar'" (click)="postModificarTratamiento(i)" class="btn btn-small" color="primary" mat-flat-button type="button">Guardar</button>
                    </div>
                    
                </div>
                <!-- i med -->
                
                <div class="d-flex justify-content-between mb-3 mt-3"><strong>MEDICAMENTOS</strong> <!--<span> Agregar Medicamento</span>--> </div>
                <div class="row ">
                    
                    
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <!-- antes <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.nombre_medicamento" >
                        </mat-form-field>-->
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <mat-select [(ngModel)]="d.nombre_medicamento">
                                <mat-option *ngFor="let p of medicamentosTodos" [value]="p.nombre_medicamento">{{p.nombre_medicamento}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <mat-select [(ngModel)]="d.numero_anexo_tres">
                                <mat-option *ngFor="let p of anexo_tres" [value]="p.id_anexo_tres_pk">{{p.anexo_tres}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Nombre comercial</mat-label>
                            <input type="text" aria-label="Number" matInput [(ngModel)]="d.nombre_medicamento" [matAutocomplete]="auto_aa">
                            <mat-autocomplete  #auto_aa="matAutocomplete">
                              <mat-option  *ngFor="let p of anexo_tres" [value]="p.anexo_tres" [matTooltip]="p.anexo_tres">{{p.anexo_tres}}</mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <!-- antes <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.nombre_medicamento" >
                        </mat-form-field>-->
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <mat-select [(ngModel)]="d.nombre_medicamento">
                                <mat-option *ngFor="let p of medicamentosTodos" [value]="p.nombre_medicamento">{{p.nombre_medicamento}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre genérico </mat-label>
                            <mat-select [(ngModel)]="d.numero_anexo_dos">
                                <mat-option *ngFor="let p of anexo_dos" [value]="p.id_anexo_dos_pk">{{p.anexo_dos}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Nombre genérico</mat-label>
                            <input type="text" aria-label="Number" matInput [(ngModel)]="d.string_anexo_dos_fk" [matAutocomplete]="auto_bb">
                            <mat-autocomplete  #auto_bb="matAutocomplete">
                              <mat-option *ngFor="let p of anexo_dos" [value]="p.anexo_dos" [matTooltip]="p.anexo_dos">{{p.anexo_dos}}</mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Presentación </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.numero_presentacion" >
                        </mat-form-field>-->

                        <!--<mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Presentación</mat-label>
                            <mat-select [(ngModel)]="d.numero_presentacion">
                                <mat-option *ngFor="let p of presentacion" [value]="p.id_presentacion_pk">{{p.presentacion}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Presentación</mat-label>
                            <input type="text" aria-label="Number" matInput [(ngModel)]="d.string_anexo_tres_fk" [matAutocomplete]="auto_cc">
                            <mat-autocomplete  #auto_cc="matAutocomplete">
                              <mat-option  *ngFor="let p of presentacion" [value]="p.presentacion" [matTooltip]="p.presentacion">{{p.presentacion}}</mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <!-- antes <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.nombre_medicamento" >
                        </mat-form-field>-->
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <mat-select [(ngModel)]="d.nombre_medicamento">
                                <mat-option *ngFor="let p of medicamentosTodos" [value]="p.nombre_medicamento">{{p.nombre_medicamento}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Concentración </mat-label>
                            <mat-select [(ngModel)]="d.numero_anexo_uno">
                                <mat-option *ngFor="let p of anexo_uno" [value]="p.id_anexo_uno_pk">{{p.anexo_uno}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Concentración</mat-label>
                            <input type="text" aria-label="Number" matInput [(ngModel)]="d.string_anexo_uno_fk" [matAutocomplete]="auto_dd">
                            <mat-autocomplete  #auto_dd="matAutocomplete">
                              <mat-option *ngFor="let p of anexo_uno" [value]="p.anexo_uno" [matTooltip]="p.anexo_uno">{{p.anexo_uno}}</mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Dosis </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.dosis" >
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Frecuencia </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.frecuencia" >
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Duración </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.duracion" >
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2 ">
                       
                        
                        <button *ngIf="d.id_estatus_medico_fk == 1 && accion == 'registrar'" type="button" color="primary"  mat-mini-fab (click)="postRegistrarMedicamentos(i)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="row" *ngFor="let s of d.medicamentos; let k = index">
                    <span><strong>Medicamento {{k + 1}}</strong></span>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <input type="text"  matInput [(ngModel)]="s.nombre_medicamento" >
                        </mat-form-field>-->
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <mat-select [(ngModel)]="s.nombre_medicamento">
                                <mat-option *ngFor="let p of medicamentosTodos" [value]="p.nombre_medicamento">{{p.nombre_medicamento}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Nombre comercial</mat-label>
                            <input type="text" aria-label="Number" matInput [(ngModel)]="s.nombre_medicamento" [matAutocomplete]="auto_a">
                            <mat-autocomplete  #auto_a="matAutocomplete">
                              <mat-option *ngFor="let p of anexo_tres" [value]="p.anexo_tres" [matTooltip]="p.anexo_tres">{{p.anexo_tres}}</mat-option>
                            </mat-autocomplete>
                          </mat-form-field>

                        

                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <!-- antes <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.nombre_medicamento" >
                        </mat-form-field>-->
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <mat-select [(ngModel)]="d.nombre_medicamento">
                                <mat-option *ngFor="let p of medicamentosTodos" [value]="p.nombre_medicamento">{{p.nombre_medicamento}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre genérico </mat-label>
                            <mat-select [(ngModel)]="s.numero_anexo_dos">
                                <mat-option *ngFor="let p of anexo_dos" [value]="p.id_anexo_dos_pk">{{p.anexo_dos}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->

                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Nombre genérico</mat-label>
                            <input type="text" aria-label="Number" matInput [(ngModel)]="s.string_anexo_dos_fk" [matAutocomplete]="auto_b">
                            <mat-autocomplete  #auto_b="matAutocomplete">
                              <mat-option *ngFor="let p of anexo_dos" [value]="p.anexo_dos" [matTooltip]="p.anexo_dos">{{p.anexo_dos}}</mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Presentación </mat-label>
                            <input type="text"  matInput [(ngModel)]="s.numero_presentacion" >
                        </mat-form-field>-->
                        <!--<mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Presentación</mat-label>
                            <mat-select [(ngModel)]="s.numero_presentacion">
                                <mat-option *ngFor="let p of presentacion" [value]="p.id_presentacion_pk">{{p.presentacion}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Presentación</mat-label>
                            <input type="text" aria-label="Number" matInput [(ngModel)]="s.string_anexo_tres_fk" [matAutocomplete]="auto_c">
                            <mat-autocomplete  #auto_c="matAutocomplete">
                              <mat-option *ngFor="let p of presentacion" [value]="p.presentacion" [matTooltip]="p.presentacion">{{p.presentacion}}</mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <!-- antes <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.nombre_medicamento" >
                        </mat-form-field>-->
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Nombre del medicamento </mat-label>
                            <mat-select [(ngModel)]="d.nombre_medicamento">
                                <mat-option *ngFor="let p of medicamentosTodos" [value]="p.nombre_medicamento">{{p.nombre_medicamento}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Concentración </mat-label>
                            <mat-select [(ngModel)]="s.numero_anexo_uno">
                                <mat-option *ngFor="let p of anexo_uno" [value]="p.id_anexo_uno_pk">{{p.anexo_uno}}</mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Concentración</mat-label>
                            <input type="text" aria-label="Number" matInput [(ngModel)]="s.string_anexo_uno_fk" [matAutocomplete]="auto_d">
                            <mat-autocomplete  #auto_d="matAutocomplete">
                              <mat-option *ngFor="let p of anexo_uno" [value]="p.anexo_uno" [matTooltip]="p.anexo_uno">{{p.anexo_uno}}</mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Dosis </mat-label>
                            <input type="text"  matInput [(ngModel)]="s.dosis" >
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Frecuencia </mat-label>
                            <input type="text"  matInput [(ngModel)]="s.frecuencia" >
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Duración </mat-label>
                            <input type="text"  matInput [(ngModel)]="s.duracion" >
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <div class="d-flex justify-content-start" *ngIf="d.id_estatus_medico_fk == 1 && accion == 'registrar'">
                            <button (click)="postEliminarMedicamentos(i, k)"  type="button" class="btn btn-small btn-danger">x</button>
                            &nbsp;
                            <button (click)="postModificarMedicamentos(i, k)" class="btn btn-small" color="primary" mat-flat-button type="button">Guardar</button>
                        </div>
                    </div>
                </div>




                <!-- f meds -->








                <!-- ig -->
                <div class="row">
                    <div class="col">
                        <span class="mb-3"><strong>INDICACIONES GENERALES</strong></span>
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Indicación general </mat-label>
                            <textarea type="text"  matInput [(ngModel)]="d.indicacion_general" ></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <row class="row mt-3">
                    
                    <div class="col">
                        <span class="mb-3"><strong>ESPECIALISTA</strong></span>
                        
                       
                       
                    </div>
                    
                </row>
                <div class="row mt-3">
                    <span class="mb-3">¿Se requiere llevar con otro especialista?</span>
                    <br>
                    <div class="col">
                        <mat-radio-group [(ngModel)]="d.require_especialista">
                            &nbsp; 
                            &nbsp; 
                            &nbsp; 
                            <mat-radio-button [value]="1"> Si </mat-radio-button>
                            &nbsp; 
                            <mat-radio-button [value]="2"> No </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" *ngIf="d.require_especialista == 1">
                            <mat-label>Especifique cuál/es </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.especialista_esp" >
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="mb-3"><strong>TERAPIA</strong></span>
                       
                       
                    </div>
                   
                </div>
                <div class="row mt-3">
                    <span class="mb-3">¿Requiere terapia?</span>
                    <br>
                    <div class="col">
                        <mat-radio-group [(ngModel)]="d.require_terapia">
                            &nbsp; 
                            &nbsp; 
                            &nbsp; 
                            <mat-radio-button [value]="1"> Si </mat-radio-button>
                            &nbsp; 
                            <mat-radio-button [value]="2"> No </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" *ngIf="d.require_terapia == 1">
                            <mat-label>Especifique cuál/es </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.terapia_esp" >
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" *ngIf="d.require_terapia == 1">
                            <mat-label>Fecha de inicio de Terapia </mat-label>
                            <input type="date"  matInput [(ngModel)]="d.fecha_inicio" >
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" *ngIf="d.require_terapia == 1">
                            <mat-label>Fecha de fin de Terapia </mat-label>
                            <input type="date"  matInput [(ngModel)]="d.fecha_fin" >
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <span class="mb-3"><strong>EXAMEN DE GABINETE U OTRO</strong></span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        
                        <mat-radio-group [(ngModel)]="d.examen">
                            &nbsp; 
                            &nbsp; 
                            &nbsp; 
                            <mat-radio-button [value]="1"> Si </mat-radio-button>
                            &nbsp; 
                            <mat-radio-button [value]="2"> No </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" *ngIf="d.examen == 1">
                            <mat-label>Especifique cuál/es </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.examen_esp" >
                        </mat-form-field>
                    </div>
                </div>
                <!-- f ig -->

                


                <!-- doc -->
                <div class="row">
                    <!--<span class="mb-3"><strong>DOCUMENTOS</strong></span>
                    <br>-->
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Nombre del examen </mat-label>
                            <input type="text"  matInput [(ngModel)]="d.nombre" >
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Fecha de elaboración </mat-label>
                            <input type="date"  matInput [(ngModel)]="d.fecha_elaboracion" >
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <div  class="input-group">
                            <input type="file"  class="form-control" (change)="onchangeArchivo($event, i)" >
                        </div>
                    </div>
                    <div class="col-xs-2 col-sm-2 col-md-2 col-xl-2 col-xxl-2">
                        <div class="d-flex justify-content-start" *ngIf="d.id_estatus_medico_fk == 1 && accion == 'registrar'">
                            <button  type="button" color="primary"  mat-mini-fab (click)="postRegistrarDocumentosTratamientos(i)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" *ngFor="let dc of d.documentos; let di = index">
                    <span><strong>Documento {{di + 1}}</strong></span>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Nombre del examen </mat-label>
                            <input type="text"  matInput [(ngModel)]="dc.nombre" >
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Fecha de elaboración </mat-label>
                            <input type="date"  matInput [(ngModel)]="dc.fecha" >
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <div  class="input-group">
                            <input type="file"  class="form-control" (change)="onchangeArchivoDos($event, i, di)" >
                        </div>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1 col-xl-1 col-xxl-1">
                        <a mat-mini-fab color="primary" href="{{laravelapiStorage}}/{{dc?.ruta+'?t='+t}}" target="_blank" rel=noopener>
                            <mat-icon class="material-icons">
                                file_open
                            </mat-icon>
                        </a>
                    </div>
                    <div class="col-xs-2 col-sm-2 col-md-2 col-xl-2 col-xxl-2">
                        <div class="d-flex justify-content-start" *ngIf="d.id_estatus_medico_fk == 1 && accion == 'registrar'">
                            <button (click)="postEliminarDocumentosTratamientos(i, di)"  type="button" class="btn btn-small btn-danger">x</button>
                            &nbsp;
                            <button (click)="postRegistrarDocumentosTratamientosDos(i, di)" class="btn btn-small" color="primary" mat-flat-button type="button">Modificar</button>
                        </div>
                    </div>

                </div>
                <!-- f doc -->

                <div class="row mt-3">
                    <div class="col">
                        <span class="mb-3"><strong>RESULTADO DEL TRATAMIENTO</strong></span>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        
                        <mat-radio-group [(ngModel)]="d.resultado_tratamiento">
                            &nbsp; 
                            &nbsp; 
                            &nbsp; 
                            <mat-radio-button [value]="1"> Favorable </mat-radio-button>
                            &nbsp; 
                            <mat-radio-button [value]="2"> No favorable </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <!-- ig -->
                <div class="row mt-3">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Resultados del tratamiento </mat-label>
                            <textarea rows="5" type="text"  matInput [(ngModel)]="d.nombre_examen" ></textarea>
                        </mat-form-field>
                    </div>
                   
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Otros comentarios </mat-label>
                            <textarea rows="5" type="text"  matInput [(ngModel)]="d.otros_comentarios_text" ></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <!-- f ig -->


                

                <!--<div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2 mt-1" >
                </div>-->
                <div class="row">
                    <div class="d-flex justify-content-end gap-2" *ngIf="d.id_estatus_medico_fk == 1">
                        <form novalidate (ngSubmit)="postRegistrarIndicacionesGenerales(i)" *ngIf="accion == 'registrar'">
                            <button class="btn btn-small" color="primary" mat-flat-button type="submit">Guardar Tratamiento</button>
                        </form>
    
                        <button *ngIf="d.id_estatus_medico_fk == 1 && accion == 'registrar'" (click)="postModificarTratamientoEstado(i)" class="btn btn-small" color="primary" mat-flat-button type="button">Concluir tratamiento</button>
    
                        
                    </div>
                </div>
                

            </div>

            <div class="d-flex justify-content-center mt-5 gap-2" >
                <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt']"  [queryParams]="{id: id}" >
                    Regresar al gestor
                </button>

                <button *ngIf="accion == 'registrar'" class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/eventos/modificar']" [queryParams]="{id: id, id_ev: id_ev, concluir: 'concluir'}">
                    Regresar a evento médico
                </button>
            </div>

        </mat-card-content>
    </mat-card>
</div>