import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Beneficiaria } from 'src/app/model/beneficiaria';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import * as moment from 'moment';
import { ParametrosService } from 'src/app/services/parametros.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-vista-datos-generales',
  templateUrl: './vista-datos-generales.component.html',
  styleUrls: ['./vista-datos-generales.component.scss']
})
export class VistaDatosGeneralesComponent implements OnInit {
  hostapi:string;
  existente=false;
  isPerfil = true;
  selected = new UntypedFormControl(0);
  esFichaTecnica = false;
  fechaIngreso = "";
  environment = environment;
  constructor(private _documentosService:DocumentosService,
    private _parametrosService:ParametrosService,
    private _route:Router,private _beneficiariasService:BeneficiariaService,
    private activatedRoute:ActivatedRoute,
    private _config:ConfigurationService) { 
      this.hostapi=_config.getConfiguration().hostapi;
      this.vdg_getCatalogos_();
    
    
  }
  infoBeneficiaria = {};
  @Input() ficha:boolean = false;
  @Output() beneficiariaSeleccionada = new EventEmitter<Beneficiaria>();
  @Output() fichaTecnica = new EventEmitter<any>();
  beneficiaria:Beneficiaria;

  ngOnInit(): void {
    
    if(this._route.url.indexOf("ficha-tecnica")>-1){
      this.esFichaTecnica=true;
    }else if(this.ficha){
      this.esFichaTecnica = true;
    }
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info("Parametros");
      console.info(params["id"]);
      if(params["id"]!=undefined){

        this.existente=true;
        this._beneficiariasService.getProcesosByBeneficiaria(params["id"]).subscribe(resp=>{
          this.isLoading = false;
          this.fechaIngreso = new Date(resp.data.procesos[0].etapas.candidata.fechaAlta.date).toJSON();
          this.vdg_getBeneficiaria(<number>params["id"]);
        },()=>{
          this.isLoading = false;
        });
        
        
      }else{
        this.existente=false;
        this.beneficiaria = new Beneficiaria();
      }
    });
  }
  isLoading = false;
  documentos:any;
  vdg_getBeneficiaria(id:number){
    let etapas = this._config.getConfiguration()["etapasActivas"];
    this.isLoading = true;
    this._beneficiariasService.findById(id).subscribe(resp=>{

      this.beneficiaria = resp.data;
      this._beneficiariasService.findComplementos(id).subscribe(res2=>{
        
        if(res2.data.length>0){
          this.beneficiaria.etapa = res2.data[0].etapa_activa;
          this.beneficiaria.motivoEgreso = res2.data[0].motivo_egreso;
          this.beneficiaria.desMotivoEgreso = res2.data[0].des_motivo_egreso;
          this.beneficiaria.lugarDestino = res2.data[0].lugar_destino;
          this.beneficiaria.desLugarDestino = res2.data[0].des_lugar_destino;

          if(etapas.indexOf(res2.data[0].etapa_activa.toUpperCase())>-1){
            this.beneficiaria.estatus = "ACTIVO";
          }else{
            this.beneficiaria.estatus = "NO ACTIVO";
          }
          this.beneficiaria.fechaEgreso = res2.data[0].fecha_egreso; 
        }
        console.info(this.beneficiaria);

      });
      
      
      this.beneficiariaSeleccionada.emit(resp.data);
      console.info(this.beneficiaria);
      this._documentosService.getAllByBeneficiaria(id).subscribe(resp=>{
        this.documentos=resp.data.documentos;
        this.infoBeneficiaria = {
          "nombreCompleto":this.beneficiaria.nombre,
          "curp":this.beneficiaria.idCurp,
          "fechaNacimiento": this.vdg_getFechaNac_(),
          "edadActual": this.edad,
          "canalizadaPor": this.vdg_getCanaliza_(this.beneficiaria.idCanaliza) + "" + (this.beneficiaria?.desCanaliza == null ? "" : " - "+this.beneficiaria?.desCanaliza),
          "motivoDeIngreso" : this.vdg_getMotivo_(this.beneficiaria?.idMotivoIngreso) + "" + (this.beneficiaria?.desMotivoIngreso == null ? "": " - " + this.beneficiaria?.desMotivoIngreso),
          "fechaIngreso": this.vdg_getFechaIngreso_(),
          "edadIngreso" : this.edadIngreso,
          "tipoDeDiscapacidad" : this.vdg_getDocumento_(120),
          "tiempoDeEstancia" : this.tiempoDeAntiguedad,
          "diagnosticoDN" : this.vdg_getDN_(),
          "diagnosticoDM" : this.vdg_getDocumento_(59),
          "condicionRelevante" : this.vdg_getDocumento_(84),
          "tipoDeSangre" : this.vdg_getTipoSangre_()
        };
        this.fichaTecnica.emit(this.infoBeneficiaria);
        this.isLoading = false;
      },()=>{
        this.isLoading = false;
      });
    });
  }

  vdg_getDocumento_(id: any){
    if(this.documentos){
      for(let vdg=0; vdg<this.documentos.length; vdg++){
        if(this.documentos[vdg].idTipoDocumento == id){
          return this.documentos[vdg].desTipoCatalogo.trim() =="" ? "-":this.documentos[vdg].desTipoCatalogo;
        }
      }
      return "-";
    }else{
      return "-";
    }
  }
  vdg_getIdDocumento_(id: any){
    if(this.documentos){
      for(let vdg=0; vdg<this.documentos.length; vdg++){
        if(this.documentos[vdg].idTipoDocumento == id){
          return this.documentos[vdg].idTipoCatalogo;
        }
      }
      return "-"
    }else{
      return "-";
    }
  }
  get edad(): String {
    if(this.beneficiaria){
      let edad:Number = moment().diff(this.beneficiaria.fechaNacimiento["date"], 'years',true);
      let vdg_laedad:String = edad.toString();
      let años = vdg_laedad.split(".")[0];
      let meses = ((Number) ("0."+(vdg_laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let detalleEdad = años + " años "+ meses + " meses";
      return detalleEdad;
    }else{
      return "-";
    }
    
  }
  get estancia(): String {
    if(!this.beneficiaria){
      return "-";
    }else if(!this.beneficiaria.fechaIngreso){
      return "-";
    }
    let edad:Number = moment().diff(this.beneficiaria.fechaIngreso["date"], 'years',true);
    let vdg_laedad:String = edad.toString();
    let años = vdg_laedad.split(".")[0];
    let meses = ((Number) ("0."+(vdg_laedad.split(".")[1]) ) * 12).toString().split(".")[0];
    let vdg_detalleEdad = años + " años "+ meses + " meses";
    return vdg_detalleEdad;
  }
  get edadIngreso(){
    if(!this.beneficiaria){
      return "-";
    }
    
    //console.info(this.beneficiaria.fechaIngreso["date"]);
    let edad:Number = moment(this.fechaIngreso).diff(this.beneficiaria.fechaNacimiento["date"], 'years',true);
    //console.info(edad);
    let vdg_laedad:String = edad.toString();
    let años = vdg_laedad.split(".")[0];
    let meses = ((Number) ("0."+(vdg_laedad.split(".")[1]) ) * 12).toString().split(".")[0];
    let vdg_detalleEdad = años + " años "+ meses + " meses";
    return vdg_detalleEdad;
  }
  get tiempoDeAntiguedad(){
    if( this.fechaIngreso=="" || this.fechaIngreso==null ){
      return "0";
    }else{
      //console.info(this.beneficiaria.fechaIngreso["date"]);
      let edad:Number = moment(new Date()).diff(this.fechaIngreso, 'years',true);
      //console.info(edad);
      let vdg_laedad:String = edad.toString();
      let años = vdg_laedad.split(".")[0];
      let meses = ((Number) ("0."+(vdg_laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let vdg_detalleEdad = años + " años "+ meses + " meses";
      return vdg_detalleEdad;
    }
  }
  vdg_getFechaNac_(){
    if(this.beneficiaria){
      let arreglo = new Date(this.beneficiaria.fechaNacimiento["date"]).toJSON().split("T")[0].split("-");
      return arreglo[2] + "/" +arreglo[1] + "/" + arreglo[0];
    }else{
      return "-";
    }
  }
  canaliza2=[];
  motivoIngreso=[];
  diagnosticoNeurologico = [];
  tiposDeSangre = [];
  vdg_getCatalogos_(){
    this._parametrosService.getParametros("2").subscribe(resp=>{
      this.canaliza2 = resp.data.parametros;
    });

    this._parametrosService.getParametros("3").subscribe(resp=>{
      this.motivoIngreso = resp.data.parametros;
    });

    this._parametrosService.getParametros("4").subscribe(resp=>{
      this.diagnosticoNeurologico = resp.data.parametros;
    });

    this._parametrosService.getParametros("11").subscribe(resp=>{
      this.tiposDeSangre = resp.data.parametros;
    });
  }

  vdg_getCanaliza_(id: any){
    for(let vdg_=0; vdg_<this.canaliza2.length; vdg_++){
      if(this.canaliza2[vdg_].idParametro==id){
        return this.canaliza2[vdg_].valor;
      }
    }
    return "N/D";
  }
  vdg_getMotivo_(id: any){
    for(let vdg_=0; vdg_<this.motivoIngreso.length; vdg_++){
      if(this.motivoIngreso[vdg_].idParametro==id){
        return this.motivoIngreso[vdg_].valor;
      }
    }
    return "N/D";
  }

  vdg_getDN_(){
    let id = this.vdg_getIdDocumento_(57);
    for(let vdg=0; vdg<this.diagnosticoNeurologico.length; vdg++){
      if(this.diagnosticoNeurologico[vdg].idParametro==id){
        return this.diagnosticoNeurologico[vdg].valor;
      }
    }
    return "-";
  }

  vdg_getTipoSangre_(){
    let id = this.vdg_getIdDocumento_(62);
    for(let vdg=0; vdg<this.tiposDeSangre.length; vdg++){
      if(this.tiposDeSangre[vdg].idParametro==id){
        return this.tiposDeSangre[vdg].valor;
      }
    }
    return "-";
  }

  vdg_getFechaIngreso_(){
    if(this.fechaIngreso!=""){
      let arreglo = this.fechaIngreso.split("T")[0].split("-");
      return arreglo[2] + "/" +arreglo[1] + "/" + arreglo[0];
    }else{
      return "-";
    }
  }
}
