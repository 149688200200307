<app-rrhh-ficha-informativa></app-rrhh-ficha-informativa>

<div class="card-container" id="top">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Expediente del empleado</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <mat-tab-group mat-align-tabs="start" [(selectedIndex)]="selectedIndex">
                <mat-tab label="Información general">
                    <fieldset>
                        <legend></legend>
                        <app-rrhh-expediente-informacion-empleado [selectedIndex]="selectedIndex" (siguienteEvento)="siguienteEvento($event)"></app-rrhh-expediente-informacion-empleado>
                    </fieldset>
                </mat-tab>

                <mat-tab label="Documentos personales">
                    <fieldset>
                        <legend></legend>
                        <div class="container-fluid">
                            <mat-card >
                                <mat-card-content>
                                    <div class="spinner" *ngIf="isLoading">
                                        <mat-spinner color="accent"></mat-spinner>
                                    </div>
                                    <span><strong>I. DOCUMENTOS PERSONALES</strong></span>
                                    <br>
                                    <span><strong>{{total1}} / {{data1?.length}} Documentos</strong></span>
                                    <div class="row mt-3" *ngFor="let d1 of data1; let i = index">
                                        <!--<div class="col">
                                            <span class="fw-bolder">{{d1?.documento_nombre}}</span>
                                            <input  type="file"  class="form-control" (change)="getRegistrarModificarDocumentoExpedienteEmpleado($event, d1?.id_tipo_documento_pk, d1?.numero_documento)">
                                        </div>-->
                                        <div class="col">
                                            <!--<a  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{d1?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                                                Cargar cartilla PDF
                                                <mat-icon class="material-icons">
                                                    file_open
                                                </mat-icon>
                                            </a>-->

                                            <mat-form-field appearance="fill" class="bt col-md-12">
                                                <mat-label *ngIf="d1.obligatorio == 1; else NoObl">
                                                    <div class="alert alert-success" role="alert">
                                                        Documento {{i+1}}: {{d1.documento_nombre}}  (Obligatorio)
                                                    </div>
                                                </mat-label>
                                                <ng-template #NoObl>
                                                    <mat-label>
                                                        <div class="alert alert-dark" role="alert">
                                                            Documento {{i+1}}: {{d1.documento_nombre}}   (No Obligatorio)
                                                        </div>
                                                    </mat-label>
                                                </ng-template>
                                                
                                                <input readonly matInput placeholder="Todos los formatos" [value]="d1?.documento_nombre">
                                                <button mat-icon-button type="button" matSuffix (click)="foto1.click()">
                                                    <mat-icon>attach_file</mat-icon>
                                                </button>
                                                <input type="file" #foto1 class="hide" (change)="getRegistrarModificarDocumentoExpedienteEmpleado($event, d1?.id_tipo_documento_pk, d1?.numero_documento)"/>
                
                                                <a rel="noopener" target="_blank" href="{{laravelapiStorage}}/{{d1?.ruta_relativa+'?t='+t}}" *ngIf="d1?.ruta_relativa" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                                                    <mat-icon>download</mat-icon> 
                                                </a>
                                                <mat-hint *ngIf="d1?.ruta_relativa">El documento se encuentra cargado</mat-hint>
                                            </mat-form-field>


                                        </div>
                                    </div>
                                    <div class="spinner" *ngIf="isLoading">
                                        <mat-spinner color="accent"></mat-spinner>
                                    </div>

                                </mat-card-content>


                                


                            </mat-card>
                        </div>


                    </fieldset>
                </mat-tab>

                <mat-tab label="Documentos internos">
                    <fieldset>
                        <legend></legend>
                        <div class="container-fluid">
                            <mat-card >
                                <mat-card-content>
                                    <div class="spinner" *ngIf="isLoading">
                                        <mat-spinner color="accent"></mat-spinner>
                                    </div>
                                    <span><strong>I. DOCUMENTOS INTERNOS</strong></span>
                                    <br>
                                    <span><strong>{{total2}} / {{data2?.length}} Documentos</strong></span>

                                    
                                    <div class="row mt-2" *ngFor="let d2 of data2; let i = index">
                                        <!--<div class="col">
                                            <span class="fw-bolder">{{d2?.documento_nombre}}</span>
                                            <input  type="file"  class="form-control" (change)="getRegistrarModificarDocumentoExpedienteEmpleado($event, d2?.id_tipo_documento_pk, d2?.numero_documento)">
                                        </div>-->
                                        <div class="col" *ngIf="d2?.id_tipo_documento_pk == 24 || d2?.id_tipo_documento_pk == 25">
                                          

                                            <textarea  placeholder="Nombrel del documento" type="text"  class="form-control" [(ngModel)]="d2.texto_nombre"></textarea>


                                        </div>
                                        <div class="col">
                                            <!--<a  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{d2?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                                                Cargar cartilla PDF
                                                <mat-icon class="material-icons">
                                                    file_open
                                                </mat-icon>
                                            </a>-->

                                            <mat-form-field appearance="fill" class="bt col-md-12" *ngIf="d2?.id_tipo_documento_pk != 16" >
                                                <mat-label *ngIf="d2.obligatorio == 1; else NoObl">
                                                    <div class="alert alert-success" role="alert">
                                                        Documento  {{i+1}}: {{d2.documento_nombre}} (Obligatorio)
                                                    </div>
                                                </mat-label>
                                                <ng-template #NoObl>
                                                    <mat-label >
                                                        <div class="alert alert-dark" role="alert">
                                                            
                                                            <span>Documento {{i+1}}: {{d2.documento_nombre}}  (No Obligatorio)</span>
                                                            

                                                        </div>
                                                    </mat-label>
                                                </ng-template>

                                                <input readonly matInput placeholder="Todos los formatos" [value]="d2?.documento_nombre">
                                                <button mat-icon-button type="button" matSuffix (click)="foto2.click()">
                                                    <mat-icon>attach_file</mat-icon>
                                                </button>
                                                <input type="file" #foto2 class="hide" (change)="getRegistrarModificarDocumentoExpedienteEmpleado($event, d2?.id_tipo_documento_pk, d2?.numero_documento, d2)"/>
                
                                                <a rel="noopener" target="_blank" href="{{laravelapiStorage}}/{{d2?.ruta_relativa+'?t='+t}}" *ngIf="d2?.ruta_relativa" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                                                    <mat-icon>download</mat-icon>
                                                </a>
                                                <mat-hint *ngIf="d2?.ruta_relativa">El documento se encuentra cargado</mat-hint>
                                            </mat-form-field>

                                            

                                        </div>
                                    </div>
                                    <div class="spinner" *ngIf="isLoading">
                                        <mat-spinner color="accent"></mat-spinner>
                                    </div>

                                </mat-card-content>
                            </mat-card>
                        </div>


                    </fieldset>
                </mat-tab>

                <mat-tab label="Documentos baja">
                    <div class="row mt-2" *ngFor="let d3 of data3; let i = index">
                        <!--<div class="col">
                            <span class="fw-bolder">{{d2?.documento_nombre}}</span>
                            <input  type="file"  class="form-control" (change)="getRegistrarModificarDocumentoExpedienteEmpleado($event, d2?.id_tipo_documento_pk, d2?.numero_documento)">
                        </div>-->
                        <div class="col">
                          

                            <textarea  placeholder="Nombrel del documento" type="text"  class="form-control" [(ngModel)]="d3.texto_nombre"></textarea>


                        </div>
                        <div class="col">
                            <!--<a  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{d2?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                                Cargar cartilla PDF
                                <mat-icon class="material-icons">
                                    file_open
                                </mat-icon>
                            </a>-->

                            <mat-form-field appearance="fill" class="bt col-md-12" *ngIf="d3?.id_tipo_documento_pk != 16" >
                                
                                    <mat-label >
                                        <div class="alert alert-dark" role="alert">
                                            
                                            <span>Documento {{i+1}}: {{d3.documento_nombre}}  (No Obligatorio)</span>
                                            

                                        </div>
                                    </mat-label>
                                

                                <input readonly matInput placeholder="Todos los formatos" [value]="d3?.documento_nombre">
                                <button mat-icon-button type="button" matSuffix (click)="foto2.click()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <input type="file" #foto2 class="hide" (change)="getRegistrarModificarDocumentoExpedienteEmpleado($event, d3?.id_tipo_documento_pk, d3?.numero_documento, d3)"/>

                                <a rel="noopener" target="_blank" href="{{laravelapiStorage}}/{{d3?.ruta_relativa+'?t='+t}}" *ngIf="d3?.ruta_relativa" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                                    <mat-icon>download</mat-icon>
                                </a>
                                <mat-hint *ngIf="d3?.ruta_relativa">El documento se encuentra cargado</mat-hint>
                            </mat-form-field>

                            

                        </div>
                    </div>
                    <div class="spinner" *ngIf="isLoading">
                        <mat-spinner color="accent"></mat-spinner>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </mat-card-content>
</mat-card>
</div>