import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Beneficiaria } from 'src/app/model/beneficiaria';
import { DocumentosService } from 'src/app/services/documentos.service';
import * as moment from 'moment';
import { ParametrosService } from 'src/app/services/parametros.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Usuario } from 'src/app/model/usuario';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';


@Component({
  selector: 'app-etapas-de-atencion',
  templateUrl: './etapas-de-atencion.component.html',
  styleUrls: ['./etapas-de-atencion.component.scss']
})
export class EtapasDeAtencionComponent implements OnInit {
  existente=false;
  isPerfil = true;
  selected = new UntypedFormControl(0);
  esFichaTecnica = true;
  canaliza2=[];
  motivoIngreso=[];
  diagnosticoNeurologico = [];
  isLoading = false;
  documentos:any = [];
  displayedColumns: string[] = ['position','fechaAlta','candidata','aPrueba','sanacion','desarrollo','preparacion','egreso','fechaCierre'];
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private dialog:MatDialog,
    private _documentosService:DocumentosService,
    private _parametrosService:ParametrosService,
    private _route:Router,
    private _beneficiariasService:BeneficiariaService,
    private activatedRoute:ActivatedRoute
    ) { 
    this.getCatalogos();
  }

  beneficiaria:Beneficiaria = new Beneficiaria();
  
  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    /*if(this._route.url.indexOf("ficha-tecnica")>-1){
      this.esFichaTecnica=true;
    }
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info("Parametros");
      console.info(params["id"]);
      if(params["id"]!=undefined){

        this.existente=true;
        this.getBeneficiaria(<number>params["id"]);
      }else{
        this.existente=false;
        this.beneficiaria = new Beneficiaria();
      }
    });*/
  }
  getCatalogos(){
    this._parametrosService.getParametros("2").subscribe(result=>{
      this.canaliza2 = result.data.parametros;
    });

    this._parametrosService.getParametros("3").subscribe(result=>{
      this.motivoIngreso = result.data.parametros;
    });

    this._parametrosService.getParametros("4").subscribe(result=>{
      this.diagnosticoNeurologico = result.data.parametros;
    });
  }
  getBeneficiaria(id:number){
    this.isLoading = true;
    this._beneficiariasService.findById(id).subscribe(result=>{
      
      this.beneficiaria = result.data;
      console.info(this.beneficiaria);
      this._documentosService.getAllByBeneficiaria(id).subscribe(result=>{
        this.documentos=result.data.documentos;
        this.isLoading = false;
        this.getProcesos(id);
      },()=>{
        this.isLoading = false;
      });
    });
  }
  getProcesos(id:number){
    this.isLoading = true;
    this._beneficiariasService.getProcesosByBeneficiaria(id).subscribe(result=>{
      this.isLoading = false;
      this.dataSource = new MatTableDataSource<any>(result.data.procesos);

      console.info(result.data.procesos);
    },()=>{
      this.isLoading = false;
    })
  }
  get edad(): String {
    if(this.beneficiaria.fechaNacimiento){
      let edad:Number = moment().diff(this.beneficiaria.fechaNacimiento["date"], 'years',true);
      let laedad:String = edad.toString();
      let años = laedad.split(".")[0];
      let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let detalleEdad = años + " años "+ meses + " meses";
      return detalleEdad;
    }else{
      return "-";
    }
  }
  get estancia(): String {
    if(this.beneficiaria.fechaIngreso==undefined){
      return "-";
    }
    let edad:Number = moment().diff(this.beneficiaria.fechaIngreso["date"], 'years',true);
    let laedad:String = edad.toString();
    let años = laedad.split(".")[0];
    let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];
    let detalleEdad = años + " años "+ meses + " meses";
    return detalleEdad;
  }
  get edadIngreso(){
    if(this.beneficiaria.fechaIngreso ==undefined){
      return "-";
    }
    //console.info(this.beneficiaria.fechaIngreso["date"]);
    let edad:Number = moment(this.beneficiaria.fechaIngreso["date"]).diff(this.beneficiaria.fechaNacimiento["date"], 'years',true);
    //console.info(edad);
    let laedad:String = edad.toString();
    let años = laedad.split(".")[0];
    let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];
    let detalleEdad = años + " años "+ meses + " meses";
    return detalleEdad;
  }
  getCanaliza(id){
    for(let i=0; i<this.canaliza2.length; i++){
      if(this.canaliza2[i].idParametro==id){
        return this.canaliza2[i].valor;
      }
    }
    return "N/D";
  }
  getMotivo(id){
    for(let i=0; i<this.motivoIngreso.length; i++){
      if(this.motivoIngreso[i].idParametro==id){
        return this.motivoIngreso[i].valor;
      }
    }
    return "N/D";
  }

  getDN(){
    let id = this.getIdDocumento(57);
    for(let i=0; i<this.diagnosticoNeurologico.length; i++){
      if(this.diagnosticoNeurologico[i].idParametro==id){
        return this.diagnosticoNeurologico[i].valor;
      }
    }
    return "-";
  }
  getFechaNac(){
    if(this.beneficiaria.fechaNacimiento){
      let arreglo = new Date(this.beneficiaria.fechaNacimiento["date"]).toJSON().split("T")[0].split("-");
      return arreglo[2] + "/" +arreglo[1] + "/" + arreglo[0];
    }else{
      return "-";
    }
  }
  getIdDocumento(id){
    for(let i=0; i<this.documentos.length; i++){
      if(this.documentos[i].idTipoDocumento == id){
        return this.documentos[i].idTipoCatalogo;
      }
    }
    return "-"
  }
  getDocumento(id){
    for(let i=0; i<this.documentos.length; i++){
      if(this.documentos[i].idTipoDocumento == id){
        return this.documentos[i].desTipoCatalogo;
      }
    }
    return "-"
  }

  nuevoProceso(){
    this.isLoading=true;
    this._beneficiariasService.nuevoProceso(this.beneficiaria.id).subscribe(result=>{
      this.isLoading=false;
      if(result.codigo == 'BNF000'){
        this.mensaje("Proceso creado","Hemos generado un nuevo proceso para esta beneficiaria, ya puede darle seguimiento en el gestor de etapas de atención");
        this.getProcesos(this.beneficiaria.id);
      }else{
        this.mensaje("No fue posible crear el nuevo proceso","Ya existe un proceso activo para esta beneficiaria, para crear uno nuevo, primero debe cerrar el actual");
      }
    },()=>{
      this.isLoading=false;
    })
  }

  mensaje(titulo,mensaje){
    this.dialog.open(MessageBoxComponent,{
      data: {titulo:titulo, mensaje:mensaje}
    });
  }

  diffFechas(date1,date2){
    if(!date2 || !date1){
      return "N/D";
    }
    if(date1==null|| date1 ==undefined || date1==""){
      date1 = new Date().toJSON().split("T")[0];
    }
    var fecha1 = moment(date1);
    var fecha2 = moment(date2.split(" ")[0]);

    
    let diferencia = fecha2.diff(fecha1, 'days');
    if(diferencia<0){
      diferencia=diferencia*(-1);
    }
    return diferencia + (diferencia > 1 ? " días": " día");
  }

  diffFechasNum(date1,date2){
    if(!date2 || !date1){
      return "N/D";
    }
    if(date1==null|| date1 ==undefined || date1==""){
      date1 = new Date().toJSON().split("T")[0];
    }
    var fecha1 = moment(date1);
    var fecha2 = moment(date2.split(" ")[0]);

    
    let diferencia = fecha2.diff(fecha1, 'days');
    if(diferencia<0){
      diferencia=diferencia*(-1);
    }
    return diferencia;
  }
  
  ficha:any;
  recibeBeneficiaria($event){
    console.info("Beneficiaria recibida");
    console.info($event);
    this.beneficiaria = $event;
    this.getProcesos(this.beneficiaria.id);
  }
  recibeFichaTecnica($event){
    console.info("Ficha técnica recibida");
    console.info($event);
    this.ficha = $event;
  }
  getDuracionEgreso(fechaCierreEgreso, arrayEtapas){
    //console.log(arrayEtapas);
    let fechaApertura = null;
    if(arrayEtapas.aPrueba != null && arrayEtapas.sanacion == null){
      fechaApertura = arrayEtapas.aPrueba.fechaCierre ? arrayEtapas.aPrueba.fechaCierre.date: null;
    }else if(arrayEtapas.sanacion != null && arrayEtapas.desarrollo == null){
      fechaApertura = arrayEtapas.sanacion.fechaCierre ? arrayEtapas.sanacion.fechaCierre.date:null;
    }else if(arrayEtapas.desarrollo != null && arrayEtapas.preparacion == null){
      fechaApertura = arrayEtapas.desarrollo.fechaCierre ? arrayEtapas.desarrollo.fechaCierre.date:null;
    }else{
      fechaApertura =  arrayEtapas.preparacion ? arrayEtapas.preparacion.fechaCierre.date:null;
    }
    return this.diffFechas(fechaCierreEgreso,fechaApertura);
  }

  getDuracionEgresoNum(fechaCierreEgreso, arrayEtapas){
    //console.log(arrayEtapas);
    let fechaApertura = null;
    if(arrayEtapas.aPrueba != null && arrayEtapas.sanacion == null){
      fechaApertura = arrayEtapas.aPrueba.fechaCierre ? arrayEtapas.aPrueba.fechaCierre.date: null;
    }else if(arrayEtapas.sanacion != null && arrayEtapas.desarrollo == null){
      fechaApertura = arrayEtapas.sanacion.fechaCierre ? arrayEtapas.sanacion.fechaCierre.date:null;
    }else if(arrayEtapas.desarrollo != null && arrayEtapas.preparacion == null){
      fechaApertura = arrayEtapas.desarrollo.fechaCierre ? arrayEtapas.desarrollo.fechaCierre.date:null;
    }else{
      fechaApertura =  arrayEtapas.preparacion ? arrayEtapas.preparacion.fechaCierre.date:null;
    }
    return this.diffFechasNum(fechaCierreEgreso,fechaApertura);
  }
}
