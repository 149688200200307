import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { MatCardActions } from '@angular/material/card';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class TratamientosService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

  public getConsultaDatosFormTratamientos():any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultaDatosFormTratamientos", {});
  }

  //tratamientos

  public postRegistrarTratamiento(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registrarTratamiento", datos, {});
  }

  public postModificarTratamiento(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarTratamiento", datos, {});
  }

  public postModificarTratamientoEstado(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarTratamientoEstado", datos, {});
  }

  public postConsultaTratamientosInformaciono(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultaTratamientosInformacion?numero_evento_medico="+id, {});
  }

  //indicaciones generales
  public postRegistrarIndicacionesGenerales(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registrarIndicacionesGenerales", datos, {});
  }

  //medicamentos
  public postRegistrarMedicamentos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registrarMedicamentos", datos, {});
  }

  public postModificarMedicamentos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarMedicamentos", datos, {});
  }

  public postEliminarMedicamentos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/eliminarMedicamentos", datos, {});
  }

  //documentos
  public postRegistrarDocumentosTratamientos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registrarDocumentosTratamiento", datos, {});
  }

  public postEliminarDocumentosTratamientos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/eliminarDocumentosTratamiento", datos, {});
  }






  



  

  


}