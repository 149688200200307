import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackBarComponent } from './snacks/error-snack-bar/error-snack-bar.component';
import { ConfigurationService } from './services/configuration.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from './modals/message-box/message-box.component';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  durationInSeconds = 5;
  constructor(public dialog:MatDialog,public _snackBar: MatSnackBar,private _config:ConfigurationService) {
    //this.durationInSeconds = _config.getConfiguration().ui.snackBar.durationInSeconds
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: this.durationInSeconds  * 2000,
      horizontalPosition: "end",
      verticalPosition: "bottom",
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    return next.handle(req).pipe(
      catchError((error) => {
        
        //console.log('error is intercept')
        console.error(error);
        if(error.error.codigo){
          if(error.error.codigo == "USR001"){
            this.dialog.open(MessageBoxComponent,{
              data:{titulo: "Usuario y/o contraseña incorrectos" ,mensaje: "Por favor, vuelva a intentar"}
            })
          }else{
            this.dialog.open(MessageBoxComponent,{
              data:{titulo: error.error.codigo ,mensaje: error.error.msg}
            })
          }
          //this.openSnackBar(error.error.msg,"Cerrar");
          
        }else{
          this.openSnackBar("Oops... el siguiente servicio está fallando, ["+req.url.toLocaleLowerCase().split(".com")[1]+"]"+ ", te recomendamos intentar más tarde.","Cerrar");
        }
        
        return throwError(error.message);
      })
    )
  }
}
