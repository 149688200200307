//General
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios

@Component({
  selector: 'app-rrhh-ficha-informativa',
  templateUrl: './rrhh-ficha-informativa.component.html',
  styleUrls: ['./rrhh-ficha-informativa.component.scss'],
  providers: [ RrhhService ]
})
export class RrhhFichaInformativaComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: FormGroup = this.fb.group({
    nombre: new FormControl('', [Validators.required]),
    apellidop: new FormControl('', [Validators.required]),

  });
  //All
  public file: any;
  public accion: string = '';
  public isLoading: boolean = false;
  public id_exp: any;


  public selectedIndex: number = 0;
  public laravelapiStorage = this.rrhhService.getLaravelApiStorage();
  public laravelapi = this.rrhhService.getLaravelApi();
  public data: any;
  public t: any;


  constructor(
    private fb: FormBuilder,
    public  dialog: MatDialog,
    private rrhhService: RrhhService,
    private router: Router,
    private route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
  ) { 
    /*modificar*/
    this.route.queryParams.subscribe(
      params => {
        this.id_exp = +params['id_exp'];

        console.log('ID: ' + this.id_exp);
        if(this.id_exp){
          this.getExpedienteConsulta(this.id_exp);

          
         
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    
  }

  public getExpedienteConsulta(id: number){
    console.log('asjsajsdhas');
    this.isLoading = true;
    this.rrhhService.getExpedienteConsulta(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.data = response.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getEdad(){
    var today = new Date();
    var birthDate = new Date(this.data?.fecha_nacimiento);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age--;
    }
    return age;
  }

  /*public getTiempoLaborando(){
    var today:any = new Date();
    var ingresoDate:any = new Date(this.data?.datos_puesto?.fecha_ingreso);
    //en horas
    //return Math.abs(today - ingresoDate) / 36e5;

    //en dias
    return Math.floor((Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) - Date.UTC(ingresoDate.getFullYear(), ingresoDate.getMonth(), ingresoDate.getDate()) ) /(1000 * 60 * 60 * 24));
  }*/

  public getTiempoLaborando(){
    //El siguiente fragmento de codigo lo uso para igualar la fecha de nacimiento con la fecha de hoy del usuario
    var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2){
      month = '0' + month;
    }
    if (day.length < 2){
      day = '0' + day;
    }
    var k = [year, month, day].join('-')
    /*------------*/
    var hoy = new Date(k);//fecha del sistema con el mismo formato que "fechaUsuario"
    var cumpleanos = new Date(this.data?.datos_puesto?.fecha_ingreso);
    //alert(cumpleanos+" "+hoy);
    //Calculamos años
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    // calculamos los meses
    var meses=0;
    if(hoy.getMonth()>cumpleanos.getMonth()){
          meses=hoy.getMonth()-cumpleanos.getMonth();
      }else if(hoy.getMonth()<cumpleanos.getMonth()){
          meses=12-(cumpleanos.getMonth()-hoy.getMonth());
      }else if(hoy.getMonth()==cumpleanos.getMonth() && hoy.getDate()>cumpleanos.getDate() ){
          if(hoy.getMonth()-cumpleanos.getMonth()==0){
              meses=0;
          }else{
              meses=11;
          }
          
      }
      // Obtener días: día actual - día de cumpleaños
      let dias  = hoy.getDate() - cumpleanos.getDate();
      if(dias < 0) {
          // Si días es negativo, día actual es mayor al de cumpleaños,
          // hay que restar 1 mes, si resulta menor que cero, poner en 11
          meses = (meses - 1 < 0) ? 11 : meses - 1;
          // Y obtener días faltantes
          dias = 30 + dias;
      }
      //console.log(`Tu edad es de ${edad} años, ${meses} meses, ${dias} días`);

      if(edad>=18){
          console.log("Eres un adulto");
      }else{
          // Calcular faltante con base en edad actual
          // 18 menos años actuales
          let edadF = 18 - edad;
          // El mes solo puede ser 0 a 11, se debe restar (mes actual + 1)
          let mesesF = 12 - (meses + 1);
          // Si el mes es mayor que cero, se debe restar 1 año
          if(mesesF > 0) {
              edadF --;
          }
          let diasF = 30 - dias;
          //console.log(`Te faltan ${edadF} años, ${mesesF} meses, ${diasF} días para ser adulto`);
      }

      return `${edad} años, ${meses} meses, ${dias} días`;
  }

}
