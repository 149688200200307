//General
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';
@Component({
  selector: 'app-rrhh-funciones',
  templateUrl: './rrhh-funciones.component.html',
  styleUrls: ['./rrhh-funciones.component.scss'],
  providers: [ RrhhService ]
})
export class RrhhFuncionesComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({

    que_hace: new UntypedFormControl('', [Validators.maxLength(2000)]),
    como_hace: new UntypedFormControl('', [Validators.maxLength(2000)]),
    para_que: new UntypedFormControl('', [Validators.maxLength(2000)]),
    numero_descripcion: new UntypedFormControl(-1, []),

    estatus: new UntypedFormControl(0, []),


    funciones: this.fb.array([]),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id_dp: any;
  public id_aux_funcion: number = -1;
  @Input() selectedIndex : number = 0;
  @Output() siguienteEvento = new EventEmitter<string>();

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _rrhhService: RrhhService,
    private _router: Router,
    private _route: ActivatedRoute,
  ){ 
     /*modificar*/
    this._route.queryParams.subscribe(
      params => {
        this.id_dp = +params['id_dp'];
        if(this.id_dp){
          console.log('ID: ' + this.id_dp);
          this.form.patchValue({ numero_descripcion: this.id_dp})
          this.getConsultaDescripcion(this.id_dp);
        }
      });
    /*fin modificar*/

  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[3].split("?")[0];
    console.log(this.accion);
  }

  /*funciones*/
  public get_funciones(): UntypedFormArray {    
    return this.form.get('funciones') as UntypedFormArray;
  }

  public get_funcion(i: number): UntypedFormArray {    
    return this.get_funciones().at(i) as UntypedFormArray;
  }

  public crearFuncion(data: any = null): UntypedFormGroup{
    var que_hace = '';
    var como_hace = '';
    var para_que = '';
    var numero_descripcion = this.id_dp;
    var numero_funciones_puesto = -1;

    if(data == null){
      que_hace = this.form.value.que_hace;
      como_hace = this.form.value.como_hace;
      para_que = this.form.value.para_que;
      numero_funciones_puesto = this.id_aux_funcion;

    }else{
      que_hace = data.que_hace;
      como_hace = data.como_hace;
      para_que = data.para_que;
      numero_funciones_puesto = data.id_funciones_puesto_pk;
    }

    var form = this.fb.group({
      numero_descripcion: this.id_dp,
      numero_funciones_puesto: new UntypedFormControl(numero_funciones_puesto, []),
      que_hace: new UntypedFormControl(que_hace, [Validators.maxLength(200)]),
      como_hace: new UntypedFormControl(como_hace, [Validators.maxLength(200)]),
      para_que: new UntypedFormControl(para_que, [Validators.maxLength(200)]),
    });
    return form;
  }

  public quitFuncion(i: number){
    this.get_funciones().removeAt(i);
  }

  public agregarFuncion(data: any = null){
    this.get_funciones().push( this.crearFuncion(data) );  
    this.form.patchValue({
      que_hace: '',
      como_hace: '',
      para_que: '',
    });
    console.log(this.form.value);
  }
  /*fin funciones*/

  /*registrar*/
  public postRegistrarPuesto(){
    this.isLoading = true;
    this._rrhhService.postRegistrarPuesto(this.form.value).subscribe(
      response => {
        this.isLoading = false;
        if(response.estado == 200){
          this.id_aux_funcion = response.data.id_funciones_puesto_pk;
          this.agregarFuncion();
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});          
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }
  /*fin registrar*/

  /*modificar*/
  public getConsultaDescripcion(id: number){
    this.isLoading = true;
    this._rrhhService.getConsultaDescripcion(id).subscribe(
      response => {
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({
            numero_descripcion: response.data.id_desc_puestos_pk,
            estatus: response.data.id_estatus_general_fk
          });
          this.setForm_rrhhFunciones(response.data);
        }
      },
      error => {
        this.isLoading = false;
      });
  }

  public setForm_rrhhFunciones(data: any){
    for(let i = 0; i < data.funciones_puestos.length; i++){
      this.agregarFuncion(data.funciones_puestos[i]);
    }
  }

  public postModificarEstadoDescripcionFunciones(estado: number, i: number = 0, caso: number = 0){
    this.isLoading = true;
    var e = this.form.value.estatus;
    this.form.value.estatus = estado;
    this._rrhhService.postModificarEstadoDescripcion(this.form.value).subscribe(
      response => {
        this.isLoading = false;
        if(response.estado == 200){
          if(caso == 1){
            this.postRegistrarPuesto();
          }else if(caso == 2){
            this.postModificarPuestoFuncion(i);
          }else if(caso == 3){
            this.postEliminarPuestoFuncion(i);
          }
          //this.postModificarPerfilPuesto();
        }else{
          this.form.value.estatus = e;
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {
        this.form.value.estatus = e;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postModificarPuestoFuncion(i: number){
    this.isLoading = true;
    this._rrhhService.postModificarPuestoFuncion(this.form.value.funciones[i]).subscribe(
      response => {
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});          
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }
  /*fin modificar*/

  /*eliminar*/
  public postEliminarPuestoFuncion(i: number){
    this.isLoading = true;
    this._rrhhService.postEliminarPuestoFuncion(this.form.value.funciones[i]).subscribe(
      response => {
        this.isLoading = false;
        if(response.estado == 200){
          this.quitFuncion(i);
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});          
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }
  /*fin eliminar*/



  public siguiente() {
    this.selectedIndex += 1;
    console.log(this.selectedIndex);
    this.siguienteEvento.emit(this.selectedIndex.toString());
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    
  }

}
