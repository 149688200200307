<div class="card-container none-aprueba-aux">
    <div class="table-container">

        <mat-card class="example-card">
            <mat-card-header>

                <mat-card-title>
                    Etapa a Prueba
                </mat-card-title>
                <mat-card-subtitle>

                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="spinner" *ngIf="isLoading">
                    <mat-spinner color="accent"></mat-spinner>
                </div>
                <form [formGroup]="formulario" autocomplete="off" (ngSubmit)="aprb_sendForm()">
                    <mat-form-field appearance="fill" class="bt col-md-4 none-aprueba-aux">
                        <mat-label>Fecha de apertura</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="fechaIngreso">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #dp1 ></mat-datepicker>
                    </mat-form-field>

                    <h3>Consideraciones para la etapa de prueba</h3>
                    <p class="fog-background padding-1em" style="color: #949494;">
                        {{consideraciones}}
                    </p>
                    <div class="bt col-md-12 none-aprueba-aux">
                        <div class="bt col-md-4 none-aprueba-aux">
                            <h4>Necesidades de apoyo cognitivas</h4>
                            <mat-form-field appearance="fill" class="bt col-md-6 none-aprueba-aux">
                                <mat-label></mat-label>
                                <mat-select formControlName="autonomiaCognitivoSelect" >
                                    <mat-option value="{{ac.idParametro}}" *ngFor="let ac of autonomiasConitivas">{{ac.valor}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="bt col-md-6 none-aprueba-aux">
                                <mat-label></mat-label>
                                <input matInput formControlName="autonomiaCognitivoInput" >
                            </mat-form-field>
                        </div>

                        <div class="bt col-md-4 none-aprueba-aux">
                            <h4>Necesidades de apoyo motriz</h4>
                            <mat-form-field appearance="fill" class="bt col-md-6 none-aprueba-aux">
                                <mat-label></mat-label>
                                <mat-select formControlName="autonomiaMotrizSelect" >
                                    <mat-option value="{{am.idParametro}}" *ngFor="let am of autonomiasMotrices">{{am.valor}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="bt col-md-6 none-aprueba-aux">
                                <mat-label></mat-label>
                                <input matInput formControlName="autonomiaMotrizInput" >
                            </mat-form-field>
                        </div>

                        <div class="bt col-md-4 none-aprueba-aux">
                            <h4>Diagnóstico emocional</h4>
                            <mat-form-field appearance="fill" class="bt col-md-6 none-aprueba-aux">
                                <mat-label></mat-label>
                                <mat-select formControlName="diagnosticoEmocionalSelect" >
                                    <mat-option value="{{ae.idParametro}}" *ngFor="let ae of autonomiasEmocionales">{{ae.valor}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="bt col-md-6 none-aprueba-aux">
                                <mat-label></mat-label>
                                <input matInput formControlName="diagnosticoEmocionalInput" >
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- <h4>¿Se le hicieron estudios?</h4>
                    <mat-radio-group aria-label="Select an option" formControlName="estudios">
                        <mat-radio-button value="false">NO</mat-radio-button>
                        <mat-radio-button value="true">SI</mat-radio-button>
                    </mat-radio-group>
                    <br>
                    <br> -->

                    <mat-form-field appearance="fill" class="bt col-md-12 none-aprueba-aux">
                        <mat-label>Descripción</mat-label>
                        <textarea matInput placeholder="" formControlName="descripcionEstudios"></textarea>
                    </mat-form-field>

             

                    <!--Notas sobre la adaptación-->

                    <div class="bt col-md-12 none-aprueba-aux">
                        <h3>Notas sobre la sanación</h3>
                        <div class="row">
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w ">
                                    <mat-label>Fecha</mat-label>
                                    <input matInput [matDatepicker]="aux1" formControlName="feecha">
                                    <mat-datepicker-toggle matSuffix [for]="aux1"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #aux1 disabled="false"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="fill" class="input-full-w ">
                                    <mat-label>Descripción de la nota</mat-label>
                                    <input matInput type="text" maxlength="2000" formControlName="descripcion_noota">
                                </mat-form-field>
                            </div>
                            <div class="col">
                               
                                    <button class="flat" mat-flat-button color="accent" type="button" (click)="agregarNota()">
                                        <mat-icon>add</mat-icon>&nbsp;Agregar nota
                                    </button>
                                
                            </div>
                        </div>
                        
                        <div class="bt col-md-12 none-aprueba-aux">
                            <div class="bt col-md-12 none-aprueba-aux">



                                <ng-container class="" *ngFor="let nota of notas2; let i = index">
                                    <mat-form-field appearance="fill" class="bt col-md-2 none-aprueba-aux">
                                        <mat-label>Fecha</mat-label>
                                        <input matInput [matDatepicker]="fn1" formControlName="fechaNota{{i}}">
                                        <mat-datepicker-toggle matSuffix [for]="fn1"></mat-datepicker-toggle>
                                        <mat-datepicker touchUi #fn1 disabled="false"></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field appearance="fill" class="bt col-md-8">
                                        <mat-label>Descripción de la nota</mat-label>
                                        <input matInput type="text" maxlength="2000" formControlName="descripcionNota{{i}}">
                                    </mat-form-field>

                                    <button  class="bt col-md-2" class="flat" mat-flat-button color="warn" type="button" (click)="eliminarNoota(i)">
                                        Eliminar nota
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                        <div class="bt col-md-12 none-aprueba-aux">
                            <mat-list>
                                <div mat-subheader>Notas</div>
                                <ng-container *ngFor="let nota of notas; let i=index">
                                    <mat-list-item >
                                        <mat-icon mat-list-icon>sticky_note_2</mat-icon>
                                        <div mat-line>{{nota.descNota}}</div>
                                        <div mat-line> {{nota?.fechaNota?.date | date:'mediumDate'}} </div>
                                    </mat-list-item>
                                    <mat-divider></mat-divider>
                                </ng-container>
                            </mat-list>
                        </div>
                    </div>

                    <!--Notas sobre la adaptación-->

                    <!--<mat-form-field appearance="fill" class="example-chip-list bt col-md-12 none-aprueba-aux">
                        <mat-label>Notas sobre la adaptación</mat-label>
                        <mat-chip-list #chipList aria-label="Notas">
                          <mat-chip *ngFor="let nota of notas" [selectable]="selectable"
                                   [removable]="removable" (removed)="remove(nota)">
                            {{nota.name}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                          </mat-chip>
                          <input placeholder="Nueva nota..."
                                 [matChipInputFor]="chipList"
                                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                 [matChipInputAddOnBlur]="addOnBlur"
                                 (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                    </mat-form-field>

                    <div class="bt col-md-12 none-aprueba-aux">
                        <mat-list>
                            <div mat-subheader>Notas</div>
                            <ng-container *ngFor="let nota of notas2; let i=index">
                                <mat-list-item >
                                    <mat-icon mat-list-icon>sticky_note_2</mat-icon>
                                    <div mat-line>{{nota.descNota}}</div>
                                    <div mat-line> {{nota?.fechaNota?.date | date:'mediumDate'}} </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                            </ng-container>
                        </mat-list>
                    </div>-->
                    <br><br>

                    <h3>Cierre</h3>

                    <mat-form-field appearance="fill" class="bt col-md-4 none-aprueba-aux">
                        <mat-label>Fecha de cierre</mat-label>
                        <input matInput [matDatepicker]="dp2" formControlName="fechaCierre">
                        <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #dp2 disabled="false"></mat-datepicker>
                    </mat-form-field>

                    <div class="bt col-md-12 none-aprueba-aux">
                        <mat-radio-group aria-label="Select an option" #cierre formControlName="cierre" (change)="aprb_cierreChange($event)">
                            <mat-radio-button value="1">Admisión permanente</mat-radio-button>
                            <mat-radio-button value="2">Egresar</mat-radio-button> <!--antes 3-->
                        </mat-radio-group>
                    </div>

                    <br><br>

                    <mat-form-field appearance="fill" class="bt col-md-4 none-aprueba-aux" *ngIf="formulario.controls.cierre.value=='2'">
                        <mat-label>Prolongar periodo</mat-label>
                        <input matInput [matDatepicker]="dp2" formControlName="fechaProlongada">
                        <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #dp2 disabled="false"></mat-datepicker>
                    </mat-form-field>


                    <mat-form-field appearance="fill" class="bt col-md-12 none-aprueba-aux">
                        <mat-label>Conclusiones relevantes</mat-label>
                        <textarea matInput placeholder="" formControlName="conclusiones"></textarea>
                    </mat-form-field>

                    

                    

                    <mat-form-field appearance="fill" class="bt col-md-12 none-aprueba-aux">
                        <mat-label>Oficio de respuesta a prueba</mat-label>
                        <input matInput [value]="file.value" formControlName="oficioRespuestaAprueba" readonly>
                        <button mat-icon-button matSuffix (click)="file.click()" type="button">
                            <mat-icon>attach_file</mat-icon>
                        </button>
                        <input type="file" #file class="hide" (change)="aprb_handleFileInput($event.target.files)"/>

                        <a href="{{hostapi}}/{{documento1}}" *ngIf="documento1" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                            <mat-icon>download</mat-icon>
                        </a>
                        <mat-hint *ngIf="documento1">Ya existe un documento cargado</mat-hint>
                    </mat-form-field>


                    <mat-form-field appearance="fill" class="bt col-md-12 none-aprueba-aux">
                        <mat-label>2do Oficio de respuesta a prueba</mat-label>
                        <input matInput [value]="file2.value" formControlName="oficioRespuestaAprueba2" readonly>
                        <button mat-icon-button matSuffix (click)="file2.click()" type="button">
                            <mat-icon>attach_file</mat-icon>
                        </button>
                        <input type="file" #file2 class="hide" (change)="aprb_handleFileInput2($event.target.files)"/>
                        
                        <a href="{{hostapi}}/{{documento2}}" *ngIf="documento2" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                            <mat-icon>download</mat-icon>
                        </a>
                        <mat-hint *ngIf="documento2">Ya existe un documento cargado</mat-hint>
                    </mat-form-field>

                    <div>
                        <button type="submit" color="primary" mat-flat-button class="flat" *ngIf="indEstatus == 'A'">Guardar</button> &nbsp;
                        <button type="button" color="accent" mat-flat-button class="flat" (click)="aprb_cerrarEtapa()" *ngIf="indEstatus == 'A'" [disabled]="formulario.invalid ">Cerrar etapa</button>&nbsp; <!--|| form.cierre.value == '2'-->
                        <button type="button" color="default" mat-stroked-button (click)="aprb_cancelar()" class="flat" >Cancelar</button>
                        <button type="button" color="warn" style="float:right" mat-flat-button class="flat" (click)="aprb_egresar()" *ngIf="indEstatus == 'A'" [disabled]="formulario.invalid ">Egresar</button>&nbsp; <!--|| form.cierre.value == '2'-->
                    </div>

                </form>
                
            </mat-card-content>
        </mat-card>
    </div>
</div>