//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { MedicoService } from 'src/app/services/medico.service';
import { SesionService } from 'src/app/services/sesion.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios


@Component({
  selector: 'app-gestor-alm-salida-inventario',
  templateUrl: './gestor-alm-salida-inventario.component.html',
  styleUrls: ['./gestor-alm-salida-inventario.component.scss'],
  providers: [ MedicoService ]
})
export class GestorAlmSalidaInventarioComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    buscador: new UntypedFormControl('', []),
    ruta: new UntypedFormControl('', []),
  });
  /*tabla*/
  public sa_length: number = 100;
  public sa_pageSize: number = 25;
  public sa_pageSizeOptions: Array<number> = [5, 10, 25, 100];
  public sa_page: number = 1;
  public sa_displayedColumns: any =[
    'sa_foto', 
    'sa_tipo',
    'sa_nombre_medicamento',
    'sa_presentacion',
    'sa_entrada',
    'sa_retiro',
    'sa_consultar'];
  public dataSource: any;
  /*fin tabla*/
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public laravelapi: any;
  public laravelapistorage: any;
  public acciones: any;
  public sa_registrar: boolean = false;
  public sa_consultar: boolean = false;
  public sa_modificar: boolean = false;
  public sa_eliminar: boolean = false;
  public t: any;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _medicoService_sa: MedicoService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _sesionService_sa:SesionService,
    private _cdRef:ChangeDetectorRef,
  ){ 
    this.getConsultaBajasMedicamento(1);
    this.laravelapi = this._medicoService_sa.getLaravelApi();
    this.laravelapistorage = this._medicoService_sa.getLaravelApiStorage();
    this.sa_registrar = this.sa_consultarAccionUsuario("/medico/almacen-de-medicamentos/gestor-salida-inventario/registrar");
    this.sa_consultar = this.sa_consultarAccionUsuario("/medico/almacen-de-medicamentos/gestor-salida-inventario/consultar");
    this.sa_modificar = this.sa_consultarAccionUsuario("/medico/almacen-de-medicamentos/gestor-salida-inventario/modificar");
    this.sa_eliminar = this.sa_consultarAccionUsuario("/medico/almacen-de-medicamentos/gestor-salida-inventario/eliminar");
  }

  ngOnInit(): void {
  }

  /*permisos*/
  public sa_consultarAccionUsuario(url: any){
    if(this._sesionService_sa.getSession().getUsuario().nombre != undefined){
      this.acciones = this._sesionService_sa.getSession().getAcciones();
      console.log(this.acciones);
      if(this.acciones.filter(x => x.url == url)[0]){
        return true;        
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  /*fin permisos*/

  public getConsultaBajasMedicamento(page: number){
    this.isLoading = true;
    this.sa_page = page;
    this._medicoService_sa.getConsultaBajasMedicamento(this.sa_page, this.sa_pageSize, this.form.value.buscador).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.sa_length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.sa_pageSize = event.pageSize;
    this.getConsultaBajasMedicamento(event.pageIndex + 1);
  }

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }

}
