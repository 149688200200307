//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { PtService } from 'src/app/services/pt.service';


@Component({
  selector: 'app-ac-control-grupal-peso-talla-ben',
  templateUrl: './ac-control-grupal-peso-talla-ben.component.html',
  styleUrls: ['./ac-control-grupal-peso-talla-ben.component.scss'],
  providers: [ PtService ]
})
export class AcControlGrupalPesoTallaBenComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    numero_periodo: new UntypedFormControl(-1, [Validators.required]),
    persona_inicio: new UntypedFormControl('', [Validators.required]),
    comentarios: new UntypedFormControl('', [Validators.required]),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id: number = -1;
  public dt: any;
  public ghedp_laravelapiStorage = this._PtService.getLaravelApiStorage();
  public laravelapi = this._PtService.getLaravelApi();

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _PtService: PtService,
    private _router: Router,
    private _route: ActivatedRoute,  
  ) { 
    this.form.markAllAsTouched();
    /*modificar*/
    this._route.queryParams.subscribe(
      params => {
        this.id = +params['id'];
        if(this.id){
          console.log('ID: ' + this.id);
          //this.form.patchValue({ numero_depa: this.id})
          this.postConsultarTallasBeneficiaria(this.id);
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
  }

  public postConsultarTallasBeneficiaria(id: number){
    this.isLoading = true;
    this._PtService.postConsultarTallasBeneficiaria({numero_beneficiario: id}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.dt = response.data;
          //this.setForm(response.data);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  

}
