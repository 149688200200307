import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { ContactosService } from 'src/app/services/contactos.service';
import { DocumentosService } from 'src/app/services/documentos.service';

@Component({
  selector: 'app-vista-contacto',
  templateUrl: './vista-contacto.component.html',
  styleUrls: ['./vista-contacto.component.scss']
})
export class VistaContactoComponent implements OnInit {
  tipoContactos = [{id:"1",nombre:"Autoridad"},{id:"2",nombre:"Patronato"},{id:"3",nombre:"Estratégico"}]
  formulario:UntypedFormGroup=new UntypedFormGroup({
    
    tipoContacto:new UntypedFormControl("",[Validators.required]),
    nombre:new UntypedFormControl("",[Validators.required]),
    organizacion:new UntypedFormControl("",[Validators.required]),
    cargo:new UntypedFormControl("",[Validators.required]),    
    direccion:new UntypedFormControl("",[]),
    telefono:new UntypedFormControl("",[]),
    extencion:new UntypedFormControl("",[]),
    correo:new UntypedFormControl("",[Validators.email]),
    celular:new UntypedFormControl("",[]),
    observaciones:new UntypedFormControl("",[])
  });
  isLoading=false;
  tipo_documentos:any=[];
  fileToUpload:any;
  id=null;
  categorias:any=[];
  subcategorias:any=[];
  tipoDocumentos:any=[];

  constructor(private dialog:MatDialog,private contactos_service:ContactosService,private router:Router,private activatedRoute:ActivatedRoute) { 
   
  }
  documento;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      if(params['id']){
        this.id=params['id'];
        this.contactos_service.buscaContactos("",0,0,params['id']).subscribe(result=>{
          this.documento = result.data.documentos[0];
          this.formulario.controls["nombre"].setValue(this.documento.nombre);
          this.formulario.controls["nombre"].disable();
          this.formulario.controls["organizacion"].setValue(this.documento.organizacion);
          this.formulario.controls["organizacion"].disable();
          this.formulario.controls["cargo"].setValue(this.documento.cargo);
          this.formulario.controls["cargo"].disable();
          this.formulario.controls["direccion"].setValue(this.documento.direccion);
          this.formulario.controls["direccion"].disable();
          this.formulario.controls["telefono"].setValue(this.documento.telefono);
          this.formulario.controls["telefono"].disable();
          this.formulario.controls["extencion"].setValue(this.documento.extencion);
          this.formulario.controls["extencion"].disable();
          this.formulario.controls["correo"].setValue(this.documento.correo);
          this.formulario.controls["correo"].disable();
          this.formulario.controls["celular"].setValue(this.documento.celular);
          this.formulario.controls["celular"].disable();
          this.formulario.controls["observaciones"].setValue(this.documento.observaciones);
          this.formulario.controls["observaciones"].disable();
          this.formulario.controls["tipoContacto"].setValue(this.documento.tipo_contacto);
          this.formulario.controls["tipoContacto"].disable();
          
        })
      }
    });
  }

}
