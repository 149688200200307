//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { CalendarioService } from 'src/app/services/calendario.service';
import { ModalCalForm1Component } from 'src/app/pages/calendario/modal-cal-form1/modal-cal-form1.component';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';


@Component({
  selector: 'app-gt-cale',
  templateUrl: './gt-cale.component.html',
  styleUrls: ['./gt-cale.component.scss'],
  providers: [ CalendarioService ]
})
export class GtCaleComponent implements OnInit {

  /*tabla*/
  public length: number;
  public pageSize: number;
  public pageSizeOptions: Array<number>;
  public page: number;
  public displayedColumns: any;
  public dataSource: any;
  /*fin tabla*/
  public isLoading: boolean = false;
  public form: UntypedFormGroup = this.fb.group({
    buscador: new UntypedFormControl('', []),
    numero_periodo: new UntypedFormControl(1, []),
  });
  public laravelapiStorage = this._CalendarioService.getLaravelApiStorage();
  public laravelapi = this._CalendarioService.getLaravelApi();
  public tieneAccionEditar = false;
  public acciones:any=[];
  public periodos: any;
  public titulo: string = '';
  public tipo_general: number = -1;
  public t: number = 1;

  public form2: UntypedFormGroup = this.fb.group({
    tipo: new UntypedFormControl(1, []), //caso base 1, el 2 es para el gestor
    filas: new UntypedFormControl(10, []),
    numero_mes: new UntypedFormControl(12, []),
    numero_configuracion: new UntypedFormControl(1, []), //esctructura o individual
    calendario: new UntypedFormControl(1, []),
  });

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _CalendarioService: CalendarioService,
    private _router: Router,
    private _route: ActivatedRoute,
    //private _sesion:SesionService,
  ){
     /*tabla*/
     this.length = 100;
     this.pageSize = 25;
     this.pageSizeOptions = [5, 10, 25, 100];
     this.page = 1;
     this.displayedColumns = [
     'id', 
     'calendario',
     'estructura',
     'nombre',
     'fi',
     'ft',
     'i',
     't',
     'consultar'];
     /*fin tabla*/
 
     /*modificar*/
     //this.getConsultaPeriodosBitacora();
     /*fin modificar*/
     //this.tieneAccionEditar = this.permiso("/medico/expedientes-medicos-generales/gt-terapias-companas/modificar");
     this.setTitulo();
     this.getConsultarCalendarioBusqueda(1);
  }

  ngOnInit(): void {
    this._route.queryParams.subscribe(
      params => {
        
        if(params['tipo']){
          this.tipo_general = +params['tipo'];
          this.form2.patchValue([{
            calendario: this.tipo_general
          }]);
        }

        if(this.t != +params['t']){
          this.t = +params['t'];
          console.log(this.t);
          this.getConsultarCalendarioBusqueda(1);
        }
            
    });
  }

  public setTitulo(){
    if(this.form.value.tipo == 1){
      this.titulo = 'estructural';
    }else{
      this.titulo = 'individual';
    }
  }

  /*public permiso(urlRecibida: string){
    if(this._sesion.getSession().getUsuario().nombre!=undefined){
      this.acciones = this._sesion.getSession().getAcciones();
      if(this.acciones.filter(x => x.url == urlRecibida)[0]){
        return true;        
      }else{
        return false;
      }
    }else{
      return false;
    }
  }*/

  public getConsultarCalendarioBusqueda(page: number){
    this.setTitulo();

    this._router.navigate(['/gt-calendario'], 
          { queryParams:
            {t: new Date().getSeconds(), tipo: this.form2.value.calendario.toString() }
          });


    this.isLoading = true;
    this.page = page;
    this._CalendarioService.getConsultarCalendarioBusqueda(this.form2.value.tipo, this.form2.value.filas, this.form2.value.numero_mes, this.form2.value.numero_configuracion, this.tipo_general).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.length = response.data.length;
          this.dataSource = response.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.pageSize = event.pageSize;
    this.getConsultarCalendarioBusqueda(event.pageIndex + 1);
  }

  public modalCal(tipo: number, id: number, accion: string, payload: any){
    console.log(tipo, id, accion);
    var data = {tipo: tipo, id: id, accion: accion, payload};
    const dialogRef = this._dialog.open(ModalCalForm1Component, {
      data: data
    });
  }


}
