<div class="container-fluid">
    <mat-card >
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>

            <div *ngIf="!id_dp; else elseDP" class="alert alert-warning" role="alert">
                Rellene primero el formulario de "Descripción de puesto".
            </div>
            <ng-template #elseDP>



                <form novalidate [formGroup]="form" autocomplete="off">

                    <!-- parte 4 -->
                    <span><strong>IV. FUNCIONES PRINCIPALES DEL PUESTO</strong></span>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                            <mat-form-field appearance="fill" class="input-full-w">
                                <mat-label>¿Qué hace?</mat-label>
                                <textarea matInput type="text" rows="3"  maxlength="2000" #resp formControlName="que_hace"></textarea>
                                <mat-hint align="end">{{resp.value.length}} / 2000</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                            <mat-form-field appearance="fill" class="input-full-w">
                                <mat-label>¿Cómo lo hace?</mat-label>
                                <textarea matInput type="text" rows="3"  maxlength="2000" #resp formControlName="como_hace"></textarea>
                                <mat-hint align="end">{{resp.value.length}} / 2000</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                            <mat-form-field appearance="fill" class="input-full-w">
                                <mat-label>¿Para qué lo hace?</mat-label>
                                <textarea matInput type="text" rows="3"  maxlength="2000" #resp formControlName="para_que"></textarea>
                                <mat-hint align="end">{{resp.value.length}} / 2000</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                            <button  *ngIf="accion == 'registrar' || accion == 'modificar'" type="button" color="primary"  mat-mini-fab (click)="postModificarEstadoDescripcionFunciones(1, 0, 1)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div formArrayName="funciones" *ngFor="let m of $any(get_funciones()).controls; let i = index;" class="pt-5">
                            <div [formGroupName]="i">
                                <div class="row">
                                    <p class="ps-2 fw-bold fs-5">FUNCIÓN {{i + 1}}</p>
                                    <div class="col">
                                        <mat-form-field class="input-full-w" appearance="outline">
                                            <mat-label>¿Qué hace?</mat-label>
                                            <input #que maxlength="2000" type="text" matInput formControlName="que_hace" [errorStateMatcher]="matcher">
                                            <mat-hint align="end">{{que.value.length}} / 2000</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="input-full-w" appearance="outline">
                                            <mat-label>¿Cómo lo hace?</mat-label>
                                            <input #como maxlength="2000" type="text" matInput formControlName="como_hace" [errorStateMatcher]="matcher">
                                            <mat-hint align="end">{{como.value.length}} / 2000</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="input-full-w" appearance="outline">
                                            <mat-label>¿Para que lo hace?</mat-label>
                                            <input #para maxlength="2000" type="text" matInput formControlName="para_que" [errorStateMatcher]="matcher">
                                            <mat-hint align="end">{{para.value.length}} / 2000</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-xs-2 col-sm-2 col-md-2 col-xl-2 col-xxl-2">
                                        <div class="d-flex justify-content-start">
                                            <button *ngIf="accion == 'registrar' || accion == 'modificar'" (click)="postModificarEstadoDescripcionFunciones(1, i, 3)"  type="button" class="btn btn-small btn-danger">x</button>
                                            &nbsp;
                                            <button  *ngIf="accion == 'registrar' || accion == 'modificar'" (click)="postModificarEstadoDescripcionFunciones(1, i, 2)" class="flat" color="primary" mat-flat-button type="button">Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- fin parte 4 -->
                    
                    
                </form>
            </ng-template>

            <div class="mt-5 d-flex justify-content-center gap-2">

                <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo']" >
                    Regresar
                </button>

                <!--<form novalidate [formGroup]="form">
                    <button class="flat" color="primary" mat-flat-button type="button">
                        Guardar
                    </button>
                </form>-->
                <form novalidate [formGroup]="form">
                    <button class="flat" color="primary" mat-flat-button type="button" (click)="siguiente()">
                        Siguiente
                    </button>
                </form>


            </div>

        </mat-card-content>
    </mat-card>
</div>