import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {Location} from '@angular/common';
import { ProcesosService } from 'src/app/services/procesos.service';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { ActivatedRoute } from '@angular/router';
import { DocumentosService } from 'src/app/services/documentos.service';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-candidata',
  templateUrl: './candidata.component.html',
  styleUrls: ['./candidata.component.scss']
})
export class CandidataComponent implements OnInit {
  hostapi:string;
  formulario:UntypedFormGroup;
  isLoading = false;
  fileToUpload:File=null;
  documento1 ;
  idBeneficiaria;
  idProceso;
  proceso;
  constructor(private dialog:MatDialog,private _documentosService:DocumentosService,private activatedRoute:ActivatedRoute,private _location:Location, private _beneficiariaService:BeneficiariaService,private _conf:ConfigurationService) { 
    this.hostapi = _conf.getConfiguration().hostapi;
    this.formulario = new UntypedFormGroup({
      admision : new UntypedFormControl(1,[Validators.required]),
      descripcionConclusiones : new UntypedFormControl("",[]),
      consideraciones: new UntypedFormControl("",[]),
      fechaAlta: new UntypedFormControl(null,[]),
      fechaAltaInicio: new UntypedFormControl(null,[]),
      cartaDeRespuestaRuta:new UntypedFormControl("",[]),
      cartaDeRespuestaArchivo:new UntypedFormControl("",[])
    });
    this.formulario.controls["fechaAltaInicio"].disable();
    
  }
  handleFileInput1(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      
      console.info(params["fechaAlta"]);
      this.formulario.controls.fechaAltaInicio.setValue(new Date(params["fechaAlta"]));
      console.info("Parametros");
      console.info(params["idProceso"]);
      this.idProceso = params["idProceso"];
      if(params["idProceso"]!=undefined){
        this.isLoading = true;
        this._beneficiariaService.getProcesoCandidata(params["idProceso"]).subscribe(result=>{
          this.proceso = result.data;
          console.log("result: ", result);
          this.isLoading = false;
          this.formulario.controls.admision.setValue(result.data.indAdmision.toString());
          if(result.data.indAdmision == false){
            // console.log("admision en falso");
            this.formulario.controls["consideraciones"].disable(); 
          }
          this.formulario.controls.descripcionConclusiones.setValue(result.data.conclusiones);
          this.formulario.controls.consideraciones.setValue(result.data.consideraciones);
          this.formulario.controls.fechaAlta.setValue(new Date(result.data.fechaAlta.date));
          this.idBeneficiaria = params["idBeneficiaria"];
          this.getDocumento(params["idBeneficiaria"],41);

          //result.data.indAdmision.toString() == "true" ? this.formulario.controls["descripcionConclusiones"].disable(): this.formulario.controls["descripcionConclusiones"].enable();

          if(new Date(this.formulario.controls.fechaAlta.value).toJSON().split("T")[0] == new Date(this.formulario.controls.fechaAltaInicio.value).toJSON().split("T")[0]){
            this.formulario.controls.fechaAlta.setValue("");
          }
        },()=>{
          this.isLoading = false;
        }) 
      }
    });
  }

  getDocumento(idBeneficiaria,idDocumento){
    this.isLoading = true;
    this._documentosService.getAllByBeneficiariaAndDocumento(idBeneficiaria,idDocumento).subscribe(result=>{
      this.isLoading = false;
      if(result.codigo='DOC000'){
        this.documento1 = result.data.documentos[0].docTipoCatalogo;
      }
      
    },()=>{
      this.isLoading = false;
    });
  }
  altaDocumento(){
    let formData = new FormData();
    formData.append('documento', this.fileToUpload);
    formData.append('idBeneficiaria', this.idBeneficiaria);
    formData.append('idTipoDocumento', "41");
    formData.append('idTipoCatalogo', "");
    formData.append('desTipoCatalogo', '');
    this.isLoading=true;
    this._documentosService.save(formData).subscribe(result=>{
      console.info(result);
      this.isLoading=false;
      document.getElementById("resetButton").click();
      this.mensajeExitoAltaDoc();
      this.ngOnInit();
    },()=>{
      this.isLoading=false;
    })
  }

  altaDocumento2(){

    let formData = new FormData();
    formData.append('documento', this.fileToUpload);
    formData.append('idBeneficiaria', this.idBeneficiaria);
    formData.append('idTipoDocumento', "41");
    formData.append('idTipoCatalogo', "");
    formData.append('desTipoCatalogo', '');
    this.isLoading=true;
    this._documentosService.save(formData).subscribe(result=>{
      console.info(result);
      this.isLoading=false;
      //document.getElementById("resetButton").click();
      this.concluirEtapa();
      //this.ngOnInit();
    },()=>{
      this.isLoading=false;
    });
  }

  mensajeExitoAltaDoc(): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {titulo: "Etapa actualizada", mensaje: "La etapa se actualizo correctamente", desicion:true}
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.cancelar();
    });
  }

  mensajeExitoCambiaEtapa(): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {titulo: "Etapa actualizada", mensaje: "La etapa se actualizo correctamente", desicion:true}
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.cancelar();
    })
  }

  sendForm(){
    if(this.formulario.valid){
      this.guardar();
    }
  }
  guardar(){
    let data = {
      "tipoEvento": "EVENTO_GUARDAR",
      "etapaActual":"CANDIDATA",
      "informacion":{
        "idProceso": parseInt(this.idProceso),
        "indAdmision": this.formulario.controls.admision.value == "true" ? true:false, 
        "conclusiones":this.formulario.controls.descripcionConclusiones.value,
        "consideraciones":this.formulario.controls.consideraciones.value,
        "fechaAlta":this.formulario.controls.fechaAlta.value ? this.formulario.controls.fechaAlta.value.toJSON().replace("T"," ").replace(".000Z",""): ""
      }
    };
    console.info(data);

    this.isLoading = true;
    this._beneficiariaService.updateCandidata(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading = false;
      this.altaDocumento();
    },()=>{
      this.isLoading = false;
    })
  }

  concluirEtapa(){
    let data = {
      "tipoEvento": this.formulario.controls.admision.value == "true" ? "EVENTO_CERRAR":"EVENTO_EGRESO",
      "etapaActual":"CANDIDATA",
      "informacion":{
        "idProceso": parseInt(this.idProceso),
        "indAdmision": this.formulario.controls.admision.value == "true" ? true:false, 
        "conclusiones":this.formulario.controls.descripcionConclusiones.value,
        "consideraciones":this.formulario.controls.consideraciones.value,
        "fechaAlta": this.formulario.controls.fechaAlta.value.toJSON().replace("T"," ").replace(".000Z","")
      }
    };
    console.info(data);

    this.isLoading = true;
    this._beneficiariaService.updateCandidata(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading = false;
      this.mensajeExitoCambiaEtapa();
      
    },()=>{
      this.isLoading = false;
    })
  }
  cerrarEtapa(){

    
    if(this.formulario.controls.fechaAlta.value){
      let data = {
        "tipoEvento": "EVENTO_GUARDAR",
        "etapaActual":"CANDIDATA",
        "informacion":{
          "idProceso": parseInt(this.idProceso),
          "indAdmision": this.formulario.controls.admision.value == "true" ? true:false, 
          "conclusiones":this.formulario.controls.descripcionConclusiones.value,
          "consideraciones":this.formulario.controls.consideraciones.value,
          "fechaAlta":this.formulario.controls.fechaAlta.value.toJSON().replace("T"," ").replace(".000Z","")
        }
      };
      console.info(data);
  
      this.isLoading = true;
      this._beneficiariaService.updateCandidata(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
        this.isLoading = false;
        this.altaDocumento2();
      },()=>{
        this.isLoading = false;
      })
    }else{
      this.dialog.open(MessageBoxComponent,{
        data:{titulo:"Ingrese fecha de cierre",mensaje:"Para cerrar la etapa es necesario completar el campo 'Fecha de cierre'"}
      })
    }
    
  }
  
  cancelar(){
    this._location.back();
  }

  conclusiones(event){
    //console.log(event.value);
    if(event.value == "true"){
      this.formulario.controls["consideraciones"].enable();
      

    } else{
      this.formulario.controls["consideraciones"].setValue("");
      this.formulario.controls["consideraciones"].disable();
    }
  }
}
