import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EvaluacionesService } from 'src/app/services/evaluaciones.service';
import { SesionService } from 'src/app/services/sesion.service';

@Component({
  selector: 'app-prueba',
  templateUrl: './prueba.component.html',
  styleUrls: ['./prueba.component.scss']
})
export class PruebaComponent implements OnInit {

  isEditable = true;
  data:any = {};
  isLoading=false;
  idPregunta = [];
  clasificaciones = [];
  idBeneficiaria="";
  specialCount:any = [];

  formulario:UntypedFormGroup;
  constructor(private session:SesionService,private activatedRoute:ActivatedRoute,private _formBuilder: UntypedFormBuilder,private _evaluacionesService:EvaluacionesService) {
    this.formulario = _formBuilder.group({});
    this.activatedRoute.queryParams.subscribe(params=>{
      if(params["id"]!=undefined && params["idPrueba"]!=undefined){
        this.idBeneficiaria=params["id"];
        let valores = {
          "idEncuesta":  <number>params["idPrueba"],
          "idUsuario": 1,
          "fechaAplicacion": new Date().toJSON().replace("T" , " ").split(".")[0],
          "idBeneficiaria": <number>params["id"]
        };
        this.isLoading = true;
        this._evaluacionesService.createEvaluacion(valores).subscribe(result=>{
          this.idEvaluacion = result.data.evaluacion.idEvaluacion;
          this.isLoading = false;
          this.createForm(result.data.preguntas);
          this.setRespuestas(result.data.resultados);
          
          this.data = result;
          this.obtenerClasificaciones();
          for(let i=0;i<result.data.preguntas.length;i++){
            this.idPregunta.push(false);
          }
          
        },()=>{
          this.isLoading = false;
        });
      }else if(params["prueba"]){
        this.idBeneficiaria=params["id"];
        this.idEvaluacion = params["prueba"];
        this._evaluacionesService.getEvaluacion(params["prueba"]).subscribe(result=>{
          this._evaluacionesService.getSpecialCount(params["prueba"]).subscribe(resultado=>{

            this.specialCount = resultado.data;
            this.isLoading = false;
            this.createForm(result.data.preguntas);
            this.setRespuestas(result.data.resultados);
            
            this.data = result;
            this.obtenerClasificaciones();
          });
        },()=>{
          this.isLoading = false;
        });
      }
    });
    
  }

  getAvance(subcategoria:string, clasificacion:string){
    for(let i=0; i<this.specialCount.length; i++){
      if (subcategoria.toUpperCase().trim() == this.specialCount[i].subcategoria.toUpperCase().trim() && clasificacion.toUpperCase().trim() == this.specialCount[i].clasificacion.toUpperCase().trim() ) {
        return this.specialCount[i].contestadas + " de " + this.specialCount[i].num_preguntas;
      }
    }
    return "";
  }
  setRespuestas(respuestas:any){
    let controls = this.formulario.controls;
    for(let i = 0; i < respuestas.length; i++){
      controls["respuesta"+respuestas[i].idPregunta].setValue(respuestas[i].idRespuesta);
      controls["observacion"+respuestas[i].idPregunta].setValue(respuestas[i].texto);
      controls["prioridad"+respuestas[i].idPregunta].setValue(respuestas[i].prioridad == 1 ? true:false);
    }
  }
  createForm(preguntas:any){
    for(let i = 0; i < preguntas.length; i++){
      this.formulario.addControl("respuesta"+preguntas[i].idPregunta,new UntypedFormControl(0,[]));
      this.formulario.addControl("observacion"+preguntas[i].idPregunta,new UntypedFormControl("",[]));
      this.formulario.addControl("prioridad"+preguntas[i].idPregunta,new UntypedFormControl(false,[]));
    }
  }
  ngOnInit() {
    // This is intentional
  }
  
  obtenerClasificaciones(){
    let clasificaciones=[];
    for(let i=0; i<this.data.data.preguntas.length;i++){
      if(clasificaciones.indexOf(this.data.data.preguntas[i].idClasificacion)<0){
        clasificaciones.push(this.data.data.preguntas[i].idClasificacion);
      }
    }
    console.info(clasificaciones);
    for(let i=0; i<this.data.data.clasificaciones.length;i++){
      if(clasificaciones.indexOf(this.data.data.clasificaciones[i].idClasificacion)>-1){
        this.clasificaciones.push(this.data.data.clasificaciones[i]);
      }
    }
  }
  getSubCategorias(idCategoria:number){
    let subcategorias:any = [];
    for(let i =0; i<this.data.data.preguntas.length;i++){
      let subcategoria = this.data.data.preguntas[i].descripcion.split("|")[0];
      if(subcategorias.indexOf(subcategoria)<0 && this.data.data.preguntas[i].idClasificacion == idCategoria){
        subcategorias.push(subcategoria);
      }
    }
    return subcategorias;
  }
  subcategoriaIndx = 0;
  selectedIndex  = 0;
  getStep(event){
    //this.subcategoriaIndx = 0;
    console.info(event);
    this.selectedIndex = event.selectedIndex;
  }
  idEvaluacion = 0;
  guardaRespuesta(idPregunta,idRespuesta,respuesta,prioridad){
    this.isLoading = true;
    //this.idPregunta[idPregunta] = true;
    this._evaluacionesService.guardarRespuesta(this.idEvaluacion,idPregunta,idRespuesta,respuesta,prioridad).subscribe(result=>{
      //this.idPregunta[idPregunta] = false;
      this.isLoading = false;
    },()=>{
      //this.idPregunta[idPregunta] = false;
      this.isLoading = false;
    });
  }

  changeRespuesta(pregunta){
    //console.info(event);
    //console.info("Pregunta = > "+pregunta);
    //console.info(document.getElementById("respuesta"+pregunta).getElementsByTagName('input'));
    //console.info(document.getElementById("prioridad"+pregunta));
    /*let respuestas:any = document.getElementById("respuesta"+pregunta).getElementsByTagName('input');
    let respuesta:any = {value:0};
    for(let i=0;i<respuestas.length;i++){
      if(respuestas[i].checked){
        respuesta = respuestas[i];
      }
    }*/

    
    
    //let observacion:any = document.getElementById("observaciones"+pregunta);
    //let prioridad:any = document.getElementById("prioridad"+pregunta+"-input");
    

    let respuesta = this.formulario.controls["respuesta"+pregunta].value;
    let observacion = this.formulario.controls["observacion"+pregunta].value;
    let prioridad = this.formulario.controls["prioridad"+pregunta].value;

    console.info("Respuesta => "+respuesta);
    console.info("Obervacion => "+observacion);
    console.info("Prioridad => "+prioridad);
    
    console.info(this.formulario.controls["prioridad"+pregunta].value);
    this.guardaRespuesta(pregunta,respuesta,observacion,prioridad ? 1:0);
  }

  consultaRespuestas(x){
    this.activatedRoute.queryParams.subscribe(params=>{
      this.isLoading = true;
      this._evaluacionesService.getEvaluacion(params["prueba"]).subscribe(result=>{
        this.isLoading = false;
        //this.data = result;
        let resultados:any = result.data.resultados;
        for(let i=0; i<resultados.length;i++){
          try{
            let input:any = document.getElementById("observaciones"+resultados[i].idPregunta);
            input.value = resultados[i].texto;
            let prioridad:any = document.getElementById("prioridad"+resultados[i].idPregunta+"-input");
            prioridad.checked = resultados[i].prioridad == 1 ? true:false;
          }catch(e){

          }
          
        }
        
      },()=>{
        this.isLoading = false;
      });
    });
  }
}
