import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { Usuario } from '../model/usuario';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  endpoint : string;
  phpPath:string;
  constructor(private _http:HttpClient,private _config:ConfigurationService) { 
    this.phpPath = _config.getConfiguration().embedPhpPath;
    this.endpoint = _config.getConfiguration().hostapi + this._config.getConfiguration().endpoints.usuarios.path;
  }

  login(username:string, password:string):Observable<ResponseGenerico>{
    
    console.info(this.endpoint);
    
    let empresa = this._config.getConfiguration().idEmpresa;
    let data = {username:username,password:password,idEmpresaempresa:empresa};
    console.info(data);
    return this._http.post<ResponseGenerico>(this.endpoint+"/login",data);
  }
  findAll(nombre:string,email:string,perfil:string,page,size):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"?page="+page +"&size="+size+"&nombre="+nombre+"&email="+email+"&perfil="+perfil);
  }
  findById(id):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/"+id);
  }
  getPrivilegios(id):Observable<ResponseGenerico>{
    // Esta Linea para Local
    // return this._http.get<ResponseGenerico>("http://localhost/ng-goretti/phps/consultarPrivilegios.php?id="+id);
    // Esta para Servidor
    return this._http.get<ResponseGenerico>(this.phpPath+"/consultarPrivilegios.php?id="+id);
    
  }
  deleteByUsername(username:string):Observable<ResponseGenerico>{
    return this._http.delete<ResponseGenerico>(this.endpoint+"/"+username);
  }
  save(usuario:Usuario):Observable<ResponseGenerico>{
    if(usuario.idUsuario){
      return this._http.put<ResponseGenerico>(this.endpoint,usuario);
    }else{
      return this._http.post<ResponseGenerico>(this.endpoint,usuario);
    }
  }
  reset(username:string):Observable<ResponseGenerico>{
    return this._http.post<ResponseGenerico>(this.endpoint + "/reset",{correo:username});
  }
}
