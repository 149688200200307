<div class="card-container">
    <div class="table-container">
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner color="accent"></mat-spinner>
        </div>
        <mat-card class="example-card">
            <mat-card-header>
                
                <mat-card-title>
                    Evento de vida
                </mat-card-title>
                <mat-card-subtitle>
                    
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form autocomplete="off" [formGroup]="formulario" (ngSubmit)="sendForm()">
                    <mat-form-field appearance="fill" class="bt col-md-4">
                        <mat-label>Fecha de apertura</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="fechaApertura" readonly>
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #dp1 [disabled]="esBeneficiaria()"></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-8" >
                        <mat-label>Nombre del evento</mat-label>
                        <input matInput placeholder="" maxlength="200" formControlName="nombreEvento" [readonly]="esBeneficiaria()">
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-12" >
                        <mat-label>Descripción</mat-label>
                        <textarea matInput placeholder="" maxlength="3000" formControlName="descripcion" [readonly]="esBeneficiaria()"></textarea>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-6" >
                        <mat-label>Clasificación</mat-label>
                        <mat-select formControlName="clasificacion" [disabled]="esBeneficiaria()">
                            <mat-option value="{{clasificacion.idParametro}}" *ngFor="let clasificacion of clasificaciones">{{clasificacion.valor}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-6" >
                        <mat-label>Lugar</mat-label>
                        <input matInput placeholder="" maxlength="300" formControlName="lugar" [readonly]="esBeneficiaria()">
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Beneficiarias participantes</mat-label>
                        <mat-select formControlName="beneficiarias" multiple (selectionChange)="selectedValue($event)" [disabled]="esBeneficiaria()">
                          <mat-select-trigger>
                            {{ selectedData?.value?.length>0 ?  getOne(selectedData?.text): '' }}
                            <span *ngIf="formulario.controls.beneficiarias.value?.length > 1" class="example-additional-selection">
                              (+{{formulario.controls.beneficiarias.value.length - 1}} {{formulario.controls.beneficiarias.value?.length === 2 ? 'otro' : 'otros'}})
                            </span>
                          </mat-select-trigger>
                          <mat-option #allSelected (click)="toggleAllSelection()" [value]="0" >Todas</mat-option>
                          <mat-option *ngFor="let beneficiaria of beneficiariasList" [value]="beneficiaria.id" (click)="tosslePerOne(allSelected.viewValue)">{{beneficiaria.nombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="example-chip-list bt col-md-6">
                        <mat-label>Terceros involucrados</mat-label>
                        <mat-chip-list #chipList aria-label="Notas">
                          <mat-chip *ngFor="let nota of notas" [selectable]="selectable"
                                   [removable]="removable" (removed)="remove(nota)">
                            {{nota.name}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                          </mat-chip>
                          <input placeholder="Nueva nota..."
                                 [matChipInputFor]="chipList"
                                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                 [matChipInputAddOnBlur]="addOnBlur"
                                 (matChipInputTokenEnd)="add($event)" [readonly]="esBeneficiaria()">
                        </mat-chip-list>
                    </mat-form-field>

                    <div style="display: inline-flex;">
                        <div class="bt col-md-6">
                            <h3><mat-icon>photo</mat-icon>&nbsp;Fotografías</h3>

                            <mat-form-field appearance="fill" class="bt col-md-12">
                                <mat-label>Foto 1</mat-label>
                                <input readonly matInput placeholder="Todos los formatos" formControlName="foto1" [value]="foto1.value">
                                <button mat-icon-button type="button" matSuffix (click)="foto1.click()" [disabled]="esBeneficiaria()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <input type="file" #foto1 class="hide" (change)="handleFotoInput1($event.target.files)"/>

                                <button (click)="openImage(hostapi+'/'+laFoto1)" *ngIf="laFoto1" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                                    <mat-icon>download</mat-icon>
                                </button>
                                <mat-hint *ngIf="laFoto1">Ya existe una foto cargada</mat-hint>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="bt col-md-12">
                                <mat-label>Foto 2</mat-label>
                                <input readonly matInput placeholder="Todos los formatos" formControlName="foto2" [value]="foto2.value">
                                <button mat-icon-button type="button" matSuffix (click)="foto2.click()" [disabled]="esBeneficiaria()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <input type="file" #foto2 class="hide" (change)="handleFotoInput2($event.target.files)"/>

                                <button (click)="openImage(hostapi+'/'+laFoto2)"*ngIf="laFoto2" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                                    <mat-icon>download</mat-icon>
                                </button>
                                <mat-hint *ngIf="laFoto2">Ya existe una foto cargada</mat-hint>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="bt col-md-12">
                                <mat-label>Foto 3</mat-label>
                                <input readonly matInput placeholder="Todos los formatos" formControlName="foto3" [value]="foto3.value">
                                <button mat-icon-button type="button" matSuffix (click)="foto3.click()" [disabled]="esBeneficiaria()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <input type="file" #foto3 class="hide" (change)="handleFotoInput3($event.target.files)"/>

                                <button (click)="openImage(hostapi+'/'+laFoto3)" *ngIf="laFoto3" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                                    <mat-icon>download</mat-icon>
                                </button>
                                <mat-hint *ngIf="laFoto3">Ya existe una foto cargada</mat-hint>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="bt col-md-12">
                                <mat-label>Foto 4</mat-label>
                                <input readonly matInput placeholder="Todos los formatos" formControlName="foto4" [value]="foto4.value">
                                <button mat-icon-button type="button" matSuffix (click)="foto4.click()" [disabled]="esBeneficiaria()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <input type="file" #foto4 class="hide" (change)="handleFotoInput4($event.target.files)"/>

                                <button (click)="openImage(hostapi+'/'+laFoto4)" *ngIf="laFoto4" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                                    <mat-icon>download</mat-icon>
                                </button>
                                <mat-hint *ngIf="laFoto4">Ya existe una foto cargada</mat-hint>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="bt col-md-12">
                                <mat-label>Foto 5</mat-label>
                                <input readonly matInput placeholder="Todos los formatos" formControlName="foto5" [value]="foto5.value">
                                <button mat-icon-button type="button" matSuffix (click)="foto5.click()" [disabled]="esBeneficiaria()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <input type="file" #foto5 class="hide" (change)="handleFotoInput5($event.target.files)"/>

                                <button (click)="openImage(hostapi+'/'+laFoto5)" *ngIf="laFoto5" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                                    <mat-icon>download</mat-icon>
                                </button>
                                <mat-hint *ngIf="laFoto5">Ya existe una foto cargada</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="bt col-md-6">
                            <h3><mat-icon>picture_as_pdf</mat-icon>&nbsp;Documentos</h3>
                            
                            <mat-form-field appearance="fill" class="bt col-md-12">
                                <mat-label>Documento 1</mat-label>
                                <input readonly matInput placeholder="Todos los formatos" formControlName="archivo1" [value]="file1.value">
                                <button mat-icon-button type="button" matSuffix (click)="file1.click()" [disabled]="esBeneficiaria()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <input type="file" #file1 class="hide" (change)="handleFileInput1($event.target.files)"/>
                                
                                <a href="{{hostapi}}/{{documento1}}" *ngIf="documento1" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                                    <mat-icon>download</mat-icon>
                                </a>
                                <mat-hint *ngIf="documento1">Ya existe un documento cargado</mat-hint>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="bt col-md-12">
                                <mat-label>Documento 2</mat-label>
                                <input readonly matInput placeholder="Todos los formatos" formControlName="archivo2" [value]="file2.value">
                                <button mat-icon-button type="button" matSuffix (click)="file2.click()" [disabled]="esBeneficiaria()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <input type="file" #file2 class="hide" (change)="handleFileInput2($event.target.files)"/>

                                <a href="{{hostapi}}/{{documento2}}" *ngIf="documento2" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                                    <mat-icon>download</mat-icon>
                                </a>
                                <mat-hint *ngIf="documento2">Ya existe un documento cargado</mat-hint>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="text-align-center">
                        <button *ngIf="!esBeneficiaria()" type="submit" mat-flat-button color="primary" class="flat">{{ id == 0 ? 'Crear evento':'Guardar'}}</button>
                        &nbsp;
                        <button type="button" mat-stroked-button color="default" class="flat" (click)="cancelar()">Cerrar</button>
                    </div>
                </form>

                
            </mat-card-content>
        </mat-card>
    </div>
</div>