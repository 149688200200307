import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gt-vaca',
  templateUrl: './gt-vaca.component.html',
  styleUrls: ['./gt-vaca.component.scss']
})
export class GtVacaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
