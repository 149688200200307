<div class="card-container">
    <mat-card >
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <form novalidate [formGroup]="form" autocomplete="off">
                <!-- parte 1 -->
                <span style="text-transform:uppercase"><strong>{{accion}} MEDICAMENTO</strong></span>
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>CLASIFICACIÓN</mat-label>
                            <mat-select formControlName="numero_clasificacion">
                                <mat-option *ngFor="let p of clasificacion" [value]="p.id_medicamentos_clasificacion_pk">{{p.clasificacion}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.controls['numero_clasificacion'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">

                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>MARCA</mat-label>
                            <input type="text" matInput maxlength="100" #marca formControlName="marca">
                            <mat-error *ngIf="form.controls['marca'].hasError('maxlength')">
                                El máximo tamaño es de 100 caracteres
                            </mat-error>
                            <mat-hint align="end">{{marca.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>NOMBRE DE MEDICAMENTO</mat-label>
                            <input type="text" matInput maxlength="100" #nombre_medicamento formControlName="nombre_medicamento">
                            <mat-error *ngIf="form.controls['nombre_medicamento'].hasError('maxlength')">
                                El máximo tamaño es de 100 caracteres
                            </mat-error>
                            <mat-hint align="end">{{nombre_medicamento.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>PRESENTACIÓN</mat-label>
                            <input type="text" matInput maxlength="100" #presentacion formControlName="presentacion">
                            <mat-error *ngIf="form.controls['presentacion'].hasError('maxlength')">
                                El máximo tamaño es de 100 caracteres
                            </mat-error>
                            <mat-hint align="end">{{presentacion.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>FORMULA</mat-label>
                            <input type="text" matInput maxlength="100" #formula formControlName="formula">
                            <mat-error *ngIf="form.controls['formula'].hasError('maxlength')">
                                El máximo tamaño es de 100 caracteres
                            </mat-error>
                            <mat-hint align="end">{{formula.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>GRAMAJE</mat-label>
                            <input type="text" matInput maxlength="100" #gramaje formControlName="gramaje">
                            <mat-error *ngIf="form.controls['gramaje'].hasError('maxlength')">
                                El máximo tamaño es de 100 caracteres
                            </mat-error>
                            <mat-hint align="end">{{gramaje.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>INVENTARIO INICIAL</mat-label>
                            <input type="text" matInput maxlength="100" #inventario_inicial formControlName="inventario_inicial">
                            <mat-error *ngIf="form.controls['inventario_inicial'].hasError('maxlength')">
                                El máximo tamaño es de 100 caracteres
                            </mat-error>
                            <mat-hint align="end">{{inventario_inicial.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Adjuntar foto</mat-label>
                            <input matInput readonly matInput placeholder="Adjuntar foto">
                            <button mat-icon-button type="button" (click)="file.click()" matSuffix>
                                <mat-icon>attach_file</mat-icon>
                            </button>
                            <input type="file" #file class="hide"  (change)="onchangeArchivo_en($event)"/>
                        </mat-form-field>
                    </div>
                    <div class="col" *ngIf="ruta">
                        <img src="{{laravelapistorage+'/'+ruta}}" alt="..." width="300px" height="300px">
                    </div>
				</div>
            </form>

            <div class="mt-5 d-flex justify-content-center">
				<div *ngIf="form.invalid || !file">
					<span class="text-danger">
						Faltan campos por llenar
					</span>
				</div>
			</div>

            <div class="mt-2 d-flex justify-content-center gap-2">


                

                <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/almacen-de-medicamentos/gestor-inventario']" >
                    Regresar
                </button>

                <form novalidate [formGroup]="form" (ngSubmit)="guardar()" *ngIf="accion == 'registrar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>
                <form novalidate [formGroup]="form" (ngSubmit)="modificar()" *ngIf="accion == 'modificar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>


            </div>

        </mat-card-content>
    </mat-card>
</div>