<div class="container-fluid">
    <mat-card >
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <form novalidate [formGroup]="form" autocomplete="off">
                <!-- parte 1 -->
                <span><strong>I. INFORMACIÓN GENERAL</strong></span>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div class="row">
                            <mat-form-field appearance="fill" class="input-full-w">
                                <mat-label>TÍTULO DEL PUESTO</mat-label>
                                <mat-select formControlName="titulo_puesto">
                                    <mat-option *ngFor="let p of puesto" [value]="p.id_puesto_reporte_pk">{{p.puestos_reporte}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="form.controls['titulo_puesto'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">

                            <mat-form-field appearance="fill" class="input-full-w">
                                <mat-label>AREA O DEPARTAMENTO</mat-label>
                                <mat-select formControlName="numero_departamento">
                                    <mat-option *ngFor="let p of departamento" [value]="p.id_departamento_pk">{{p.departamento}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="form.controls['numero_departamento'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">


                            <mat-form-field appearance="fill" class="input-full-w">
                                <mat-label>PUESTOS A LOS QUE REPORTA</mat-label>
                                <mat-select formControlName="numero_puesto">
                                    <mat-option *ngFor="let p of puesto" [value]="p.id_puesto_reporte_pk">{{p.puestos_reporte}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="form.controls['numero_puesto'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="fill" class="input-full-w">
                                <mat-label>PUESTOS QUE LE REPORTAN</mat-label>
                                <mat-select formControlName="puesto_reporta_aux" multiple>
                                    <mat-option *ngFor="let p of puesto" [value]="p.id_puesto_reporte_pk">{{p.puestos_reporte}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div class="row">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>CÓDIGO DEL PUESTO</mat-label>
                                <input type="text" matInput  maxlength="250" #codigo_puesto formControlName="codigo_puesto">
                                <mat-error *ngIf="form.controls['codigo_puesto'].hasError('maxlength')">
                                    El maximo tamaño es de 250 caracteres
                                </mat-error>
                                <mat-hint align="end">{{codigo_puesto.value.length}} / 250</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>SUB AREA O DEPARTAMENTO</mat-label>
                                <textarea rows="3" type="text" matInput  maxlength="250" #area_dep formControlName="sub_area">
                                </textarea> 
                                <mat-error *ngIf="form.controls['sub_area'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                                <mat-error *ngIf="form.controls['sub_area'].hasError('maxlength')">
                                    El maximo tamaño es de 250 caracteres
                                </mat-error>
                                <mat-hint align="end">{{area_dep.value.length}} / 250</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>NÚMERO DE PLAZAS</mat-label>
                                <input type="text" matInput maxlength="3" #num_plazas formControlName="numero_plazas">
                                <mat-error *ngIf="form.controls['numero_plazas'].hasError('maxlength')">
                                    El maximo tamaño es de 3 caracteres
                                </mat-error>
                                <mat-error *ngIf="form.controls['numero_plazas'].hasError('pattern')">
                                    Invalido formato
                                </mat-error>
                                <mat-hint align="end">{{num_plazas.value.length}} / 3</mat-hint>
                            </mat-form-field>
                        </div>
                        <!--<div class="row">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>NÚMERO DE PERSONAS QUE LE REPORTAN</mat-label>
                                <input type="text" matInput maxlength="3" #num_pers formControlName="numero_personas">
                                <mat-error *ngIf="form.controls['numero_personas'].hasError('maxlength')">
                                    El maximo tamaño es de 3 caracteres
                                </mat-error>
                                <mat-error *ngIf="form.controls['numero_personas'].hasError('pattern')">
                                    Invalido formato
                                </mat-error>
                                <mat-hint align="end">{{num_pers.value.length}} / 3</mat-hint>
                            </mat-form-field>
                        </div>--->
                    </div>
                </div>
                {{helperDiasLaboralesDescanso()}}
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <span><strong>INFORMACIÓN GENERAL</strong></span>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>DE:</mat-label>
                            <input type="time" matInput formControlName="horario_inicio">
                            <mat-error *ngIf="form.controls['horario_inicio'].hasError('required')">
                                El campo es requerido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>A:</mat-label>
                            <input type="time" matInput formControlName="horario_fin">
                            <mat-error *ngIf="form.controls['horario_fin'].hasError('required')">
                                El campo es requerido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                        <span><strong>DÍAS LABORALES</strong></span>
                        <table class="table table-sm table-bordered">
                            <caption></caption>
                            <thead class="web-bg-primario">
                                <tr>
                                    <th  scope="col">L</th>
                                    <th  scope="col">M</th>
                                    <th  scope="col">M</th>
                                    <th  scope="col">J</th>
                                    <th  scope="col">V</th>
                                    <th  scope="col">S</th>
                                    <th  scope="col">D</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><mat-checkbox formControlName="dl_l"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dl_ma"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dl_mi"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dl_j"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dl_v"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dl_s"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dl_d"></mat-checkbox></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                        <span><strong>DÍAS DE DESCANSO</strong></span>
                        <table class="table table-sm table-bordered">
                            <caption></caption>
                            <thead class="web-bg-primario">
                                <tr>
                                    <th  scope="col">L</th>
                                    <th  scope="col">M</th>
                                    <th  scope="col">M</th>
                                    <th  scope="col">J</th>
                                    <th  scope="col">V</th>
                                    <th  scope="col">S</th>
                                    <th  scope="col">D</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><mat-checkbox formControlName="dd_l"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dd_ma"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dd_mi"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dd_j"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dd_v"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dd_s"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dd_d"></mat-checkbox></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- fin parte 1 -->
                <!-- parte 2 -->
                <span><strong>II. FUNCIÓN DEL PUESTO</strong></span>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12 col-xxl-12">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>OBJETIVO PRINCIPAL O MISIÓN DEL PUESTO EN LA ORGANIZACIÓN</mat-label>
                            <textarea matInput type="text" rows="3"  maxlength="2000" #obj_mision formControlName="objetivo_principal"></textarea>
                            <mat-error *ngIf="form.controls['objetivo_principal'].hasError('required')">
                                El campo es requerido
                            </mat-error>
                            <mat-error *ngIf="form.controls['objetivo_principal'].hasError('maxlength')">
                                El maximo tamaño es de 2000 caracteres
                            </mat-error>
                            <mat-hint align="end">{{obj_mision.value.length}} / 2000</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <!-- fin parte 2 -->
                <!-- parte 3 -->
                <span><strong>III. CONTRIBUCIÓN PRINCIPAL / VALOR DE SU TRABAJO</strong></span>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12 col-xxl-12">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>CONTRIBUCIÓN PRINCIPAL / VALOR DE SU TRABAJO</mat-label>
                            <textarea matInput type="text" rows="3"  maxlength="2000" #con formControlName="contribucion"></textarea>
                            <mat-error *ngIf="form.controls['contribucion'].hasError('required')">
                                El campo es requerido
                            </mat-error>
                            <mat-error *ngIf="form.controls['contribucion'].hasError('maxlength')">
                                El maximo tamaño es de 2000 caracteres
                            </mat-error>
                            <mat-hint align="end">{{con.value.length}} / 2000</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-xl-6 col-xxl-6">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>RESPONSABILIDADES</mat-label>
                            <textarea matInput type="text" rows="3"  maxlength="4000" #resp formControlName="responsabilidad"></textarea>
                            <mat-error *ngIf="form.controls['responsabilidad'].hasError('maxlength')">
                                El maximo tamaño es de 4000 caracteres
                            </mat-error>
                            <mat-hint align="end">{{resp.value.length}} / 4000</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-xl-6 col-xxl-6">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>INDICADORES DE PRODUCTIVIDAD</mat-label>
                            <textarea matInput type="text" rows="3"  maxlength="2000" #ind_pro formControlName="indicadores"></textarea>
                            <mat-error *ngIf="form.controls['indicadores'].hasError('maxlength')">
                                El maximo tamaño es de 2000 caracteres
                            </mat-error>
                            <mat-hint align="end">{{ind_pro.value.length}} / 2000</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <!-- fin parte 3 -->
                
                <!-- parte 5 -->
                <span><strong>V. CONTACTOS</strong></span>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-xl-6 col-xxl-6">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>CONTACTOS INTERNOS</mat-label>
                            <textarea matInput type="text" rows="3"  maxlength="500" #contactos_internos formControlName="contactos_internos"></textarea>
                            <mat-error *ngIf="form.controls['contactos_internos'].hasError('maxlength')">
                                El maximo tamaño es de 2000 caracteres
                            </mat-error>
                            <mat-hint align="end">{{contactos_internos.value.length}} / 500</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-xl-6 col-xxl-6">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>CONTACTOS EXTERNOS</mat-label>
                            <textarea matInput type="text" rows="3"  maxlength="500" #contactos_externos formControlName="contactos_externos"></textarea>
                            <mat-error *ngIf="form.controls['contactos_externos'].hasError('maxlength')">
                                El maximo tamaño es de 2000 caracteres
                            </mat-error>
                            <mat-hint align="end">{{contactos_externos.value.length}} / 500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <!-- fin parte 5 -->
            </form>

            <div class="mt-5 d-flex justify-content-center">
                <div *ngIf="form.invalid">
                    <span class="text-danger">
                        Faltan campos por llenar
                    </span>
                </div>
            </div>

            <div class="mt-2 d-flex justify-content-center gap-2">


                

                <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo']" >
                    Regresar
                </button>

                <form novalidate [formGroup]="form" (ngSubmit)="guardar()" *ngIf="accion == 'registrar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>
                <form novalidate [formGroup]="form" (ngSubmit)="modificar(1)" *ngIf="accion == 'modificar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>
                <form novalidate [formGroup]="form" *ngIf="accion == 'modificar'">
                    <button class="flat" color="primary" mat-flat-button type="button" (click)="siguiente()">
                        Siguiente
                    </button>
                </form>


            </div>

        </mat-card-content>
    </mat-card>
</div>