<app-datos-generales></app-datos-generales>
<div class="card-container" id="top">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Gestor de eventos médicos</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div class="bt col-md-12 text-align-right">
                <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/eventos/registrar']" [queryParams]="{id:id}">
                    Nuevo evento
                </button>
                
                
                <!--<button class="flat" color="primary" mat-flat-button>Comparativo</button>
                    <button class="flat" color="primary" mat-flat-button>Gráfica (Pizza)</button>-->
                </div>

                <!-- tabla -->
                <div class="row mt-3 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="_id" >
                            <th id="_id" mat-header-cell *matHeaderCellDef style="width: 250px !important"> ID </th>
                            <td mat-cell *matCellDef="let element">GTTI-EM-{{element?.id_evento_medico_pk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_fecha">
                            <th id="_fecha" mat-header-cell *matHeaderCellDef  style="width: 250px !important" class="p-1"> FECHA DEL EVENTO </th>
                            <td mat-cell *matCellDef="let element">
                                
                                    <!--{{element?.created_at.split('T')[0]}}-->

                                    {{element?.fecha_evento_incluida?.split('T')[0]}}
                                
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="_diagnostico">
                            <th id="_diagnostico" mat-header-cell *matHeaderCellDef style="width: 500px"> DIAGNOSTICO </th>
                            <td mat-cell *matCellDef="let element">{{element?.diagnostico}}

                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_estatus">
                            <th id="_estatus" mat-header-cell *matHeaderCellDef class="text-center"> ESTATUS </th>
                            <td mat-cell *matCellDef="let element" class="p-2 text-center">
                                <div *ngIf="element?.id_estado_boton_fk == 1">
                                    En proceso
                                </div>
                                <div *ngIf="element?.id_estado_boton_fk == 2">
                                    Concluído
                                </div>
                                <div *ngIf="element?.id_estado_boton_fk == 3">
                                    Listo para tratamiento
                                </div>


                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_fecha_cierre">
                            <th id="_fecha_cierre" mat-header-cell *matHeaderCellDef style="width: 250px !important" class="p-1"> FECHA DE CIERRE </th>
                            <td mat-cell *matCellDef="let element" >
                                <!--<div class="text-center" *ngIf="element?.id_estado_boton_fk == 2">-->
                                    {{element?.fecha_evento_cierre?.split('T')[0]}}
                                <!--</div>-->
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_evento">
                            <th id="_evento" mat-header-cell *matHeaderCellDef class="text-center p-1"> EVENTO MÉDICO  </th>
                            <td mat-cell *matCellDef="let element" class="text-center p-1">
                            
                                    <button  type="button" mat-mini-fab color="primary" >
                                        <mat-icon class="material-icons" [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/eventos/modificar']" [queryParams]="{id: id, id_ev: element?.id_evento_medico_pk}">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                               
                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_evento_c">
                            <th id="_evento" mat-header-cell *matHeaderCellDef class="text-center p-1">VER EVENTO MÉDICO </th>
                            <td mat-cell *matCellDef="let element" class="text-center p-1">
                               
                                    <button  type="button" mat-mini-fab color="primary" >
                                        <mat-icon class="material-icons" [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/eventos/consultar']" [queryParams]="{id: id, id_ev: element?.id_evento_medico_pk}">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                               
                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_trat">
                            <th id="_trat" mat-header-cell *matHeaderCellDef class="text-center p-1"> TRATAMIENTO </th>
                            <td mat-cell *matCellDef="let element" class="text-center p-1">
                               
                                    <button  type="button" mat-mini-fab color="primary" *ngIf="element?.id_estado_boton_fk == 3">
                                        <!--[routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/tratamientos/registrar]" [queryParams]="{id_puesto:element.id_puesto_reporte_pk}"-->
                                        <mat-icon class="material-icons" [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/tratamientos/registrar']" [queryParams]="{id: id, id_ev: element?.id_evento_medico_pk}">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                               
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_trat_c">
                            <th id="_trat_c" mat-header-cell *matHeaderCellDef class="text-center p-1"> VER TRATAMIENTO </th>
                            <td mat-cell *matCellDef="let element" class="text-center p-1">
                                
                                    <button  type="button" mat-mini-fab color="primary" *ngIf="element?.id_estado_boton_fk == 3 || element?.id_estado_boton_fk == 2">
                                        <!--[routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/tratamientos/registrar]" [queryParams]="{id_puesto:element.id_puesto_reporte_pk}"-->
                                        <mat-icon class="material-icons" [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/tratamientos/consultar']" [queryParams]="{id: id, id_ev: element?.id_evento_medico_pk}">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                               
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_eliminar">
                            <th id="_eliminar" mat-header-cell *matHeaderCellDef class="text-center p-1"> ELIMINAR  </th>
                            <td mat-cell *matCellDef="let element" class="text-center p-1">
                               
                                    <button type="button" mat-mini-fab color="warn" (click)="postEliminarEventoMedico(element.id_evento_medico_pk)">
                                        <mat-icon class="material-icons">
                                            delete
                                        </mat-icon>
                                    </button>
                                
                            </td>
                        </ng-container>

                        <!-- fin contenido -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- fin tabla -->

                
                <div class="mt-5 text-center p-1">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos']" >
                        Regresar
                    </button>
                </div>

                
            </mat-card-content>
            <mat-card-actions align="start">
                <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"  aria-label="Selecione una pagina">
                </mat-paginator>
            </mat-card-actions>
        </mat-card>
    </div>
