<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Gestor de capacitaciones</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>


            <div class="row">
                <div class="col">
                    <button type="button" class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones/registrar']" >
                        <mat-icon>add</mat-icon> Nueva capacitación
                    </button>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill" class="mat-small input-full-w">
                        <mat-label>Seleccione el año para el reporte excel</mat-label>
                        <mat-select [(ngModel)]="toshi" class="mat-small">
                            <mat-option [value]="2018">{{2018}}</mat-option>
                            <mat-option [value]="2019">{{2019}}</mat-option>
                            <mat-option [value]="2020">{{2020}}</mat-option>
                            <mat-option [value]="2021">{{2021}}</mat-option>
                            <mat-option [value]="2022">{{2022}}</mat-option>
                            <mat-option [value]="2023">{{2023}}</mat-option>
                            <mat-option [value]="2024">{{2024}}</mat-option>
                            <mat-option [value]="2025">{{2025}}</mat-option>
                            <mat-option [value]="2026">{{2026}}</mat-option>
                            <mat-option [value]="2027">{{2027}}</mat-option>
                            <mat-option [value]="2028">{{2028}}</mat-option>
                            <mat-option [value]="2029">{{2029}}</mat-option>
                            <mat-option [value]="2030">{{2030}}</mat-option>
                            <mat-option [value]="2031">{{2031}}</mat-option>
                            <mat-option [value]="2032">{{2032}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <a class="flat" color="accent" mat-flat-button href="{{laravelapi}}/ReporteExcelCapacitaciones?year={{toshi}}" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon> Reporte Excel Capacitaciones
                    </a>
                </div>
            </div>


                <!-- tabla -->
                <div class="row mt-5 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="_id">
                            <th id="_id" mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let element">{{element?.id_capacitaciones_pk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_puestos">
                            <th id="_puestos" mat-header-cell *matHeaderCellDef> NOMBRE DE LA CAPACITACIÓN </th>
                            <td mat-cell *matCellDef="let element">{{element?.nombre_capa}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_imparte">
                            <th id="_imparte" mat-header-cell *matHeaderCellDef> IMPARTE </th>
                            <td mat-cell *matCellDef="let element">{{element?.quien_impartio}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_fecha">
                            <th id="_fecha" mat-header-cell *matHeaderCellDef> FECHA </th>
                            <td mat-cell *matCellDef="let element">{{element?.created_at?.split('T')[0]}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_modificar">
                            <th id="_modificar" mat-header-cell *matHeaderCellDef> MODIFICAR </th>
                            <td mat-cell *matCellDef="let element">
                                <button type="button" mat-mini-fab color="primary"  >
                                    <mat-icon class="material-icons" [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones/modificar']" [queryParams]="{id_capacitacion:element.id_capacitaciones_pk, id_numero_empleado: id_empleado, id_exp: id_empleado}">
                                        open_in_new
                                    </mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_consultar">
                            <th id="_consultar" mat-header-cell *matHeaderCellDef> CONSULTAR </th>
                            <td mat-cell *matCellDef="let element">
                                <button type="button" mat-mini-fab color="primary"  >
                                    <mat-icon class="material-icons" [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones/consultar']" [queryParams]="{id_capacitacion:element.id_capacitaciones_pk, id_numero_empleado: id_empleado, id_exp: id_empleado}">
                                        open_in_new
                                    </mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_eliminar">
                            <th id="_eliminar" mat-header-cell *matHeaderCellDef> ELIMINAR </th>
                            <td mat-cell *matCellDef="let element">
                                <button type="button" mat-mini-fab color="warn" (click)="postEliminarPuesto(element.id_puesto_reporte_pk)">
                                    <mat-icon class="material-icons">
                                        delete
                                    </mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <!-- fin contenido -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- fin tabla -->
                <div class="mt-5 d-flex justify-content-center">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-expedientes-de-personal']" >
                        Regresar
                    </button>
                </div>

                
            </mat-card-content>
            <mat-card-actions align="start">
                <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"  aria-label="Selecione una pagina">
                </mat-paginator>
            </mat-card-actions>
        </mat-card>
    </div>
