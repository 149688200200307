import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class TerCampService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

  /*gestor 1*/
  public getConsultarTerapias(p: number, r: number, s: string):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarTerapias?filas="+r+'&page='+p+'&buscador='+s);
  }

  public postEliminarTerapia(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarTerapia", datos, {});
  }

  /*gestor 2*/

  /*acciones*/
  public getConsultaFormularioTerapiasDatos():any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaFormularioTerapiasDatos", {});
  }

  public postRegistroDeTerapia(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistroDeTerapia", datos, {});
  }

  public postModificarTerapia(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarTerapia", datos, {});
  }
  
  public getConsultarTerapia(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarTerapia?numero_terapia="+id);
  }



  public postRegistrarDocumentoTerapia(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarDocumentoTerapia", datos, {});
  }

  public postModificarDocumentoTerapia(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarDocumentoTerapia", datos, {});
  }

  public postEliminarDocumentoTerapia(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarDocumentoTerapia", datos, {});
  }

  public getConsultarTerapiaBeneficiario(p: number, r: number, s: string, id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarTerapiaBeneficiario?filas="+r+'&page='+p+'&buscador='+s+'&numero_beneficiario='+id);
  }


  

  
}
