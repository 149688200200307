<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Catálogo del inventario</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>


            <div class="d-flex justify-content-start gap-2 mb-5">
                <button type="button" class="flat" color="primary" mat-flat-button (click)="set_opcion_catalogo(1)" >
                    Presentaciones
                </button>

                <button type="button" class="flat" color="primary" mat-flat-button (click)="set_opcion_catalogo(2)" >
                    Concentración
                </button>

                <button type="button" class="flat" color="primary" mat-flat-button (click)="set_opcion_catalogo(3)" >
                    Nombres génericos
                </button>

                <button type="button" class="flat" color="primary" mat-flat-button (click)="set_opcion_catalogo(4)" >
                    Nombre comercial
                </button>
            </div>


        <h2>Catalgo seleccionado: {{opcion_texto}}</h2>
        
    
        <form [formGroup]="form" (ngSubmit)="buscador()" autocomplete="off">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>Buscador</mat-label>
                        <input matInput formControlName="buscador">
                    </mat-form-field>

                </div>
                <div class="col">
                    <button mat-mini-fab type="submit">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <!--<div class="col">
                    <a class="flat" color="accent" mat-flat-button href="{{laravelapi}}/ExcelMedicamentosInv" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon> Reporte de excel
                    </a>
                </div>-->
                <div class="col mt-1">
                   <button type="button" class="flat" color="primary" mat-flat-button [routerLink]="['/catalogo-inventario/registrar']" [queryParams]="{opcion: opcion_catalogo}">
                    <mat-icon>add</mat-icon> Alta {{opcion_texto}}
                </button>
            </div>
        </div>
    </form>
    



    <!-- tabla -->
    <div class="row mt-3 mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <caption><!--Parrilla--></caption>
            <!-- contenido -->


            <ng-container matColumnDef="ccaaii_id">
                <th id="id" mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element">

                    <div *ngIf="opcion_catalogo == 1">
                        {{element?.id_presentacion_pk}}
                    </div>
                    <div *ngIf="opcion_catalogo == 2">
                        {{element?.id_anexo_uno_pk}}
                    </div>
                    <div *ngIf="opcion_catalogo == 3">
                        {{element?.id_anexo_dos_pk}}
                    </div>
                    <div *ngIf="opcion_catalogo == 4">
                        {{element?.id_anexo_tres_pk}}
                    </div>

                </td>
            </ng-container>

            <ng-container matColumnDef="ccaaii_name">
                <th id="name" mat-header-cell *matHeaderCellDef> NOMBRE </th>
                <td mat-cell *matCellDef="let element">

                    <div *ngIf="opcion_catalogo == 1">
                        {{element?.presentacion}}
                    </div>
                    <div *ngIf="opcion_catalogo == 2">
                        {{element?.anexo_uno}}
                    </div>
                    <div *ngIf="opcion_catalogo == 3">
                        {{element?.anexo_dos}}
                    </div>
                    <div *ngIf="opcion_catalogo == 4">
                        {{element?.anexo_tres}}
                    </div>

                </td>
            </ng-container>


            <ng-container matColumnDef="ccaaii_consultar">
                <th id="ccaaii_consultar" mat-header-cell *matHeaderCellDef class="text-center"> CONSULTAR </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <button  *ngIf="opcion_catalogo == 1" type="button" mat-mini-fab color="primary" [routerLink]="['/catalogo-inventario/consultar']" [queryParams]="{id: element.id_presentacion_pk, opcion: opcion_catalogo}">
                        <mat-icon class="material-icons" >
                            open_in_new
                        </mat-icon>
                    </button>
                    
                    <button  *ngIf="opcion_catalogo == 2" type="button" mat-mini-fab color="primary" [routerLink]="['/catalogo-inventario/consultar']" [queryParams]="{id: element.id_anexo_uno_pk, opcion: opcion_catalogo}">
                        <mat-icon class="material-icons" >
                            open_in_new
                        </mat-icon>
                    </button>

                    <button  *ngIf="opcion_catalogo == 3" type="button" mat-mini-fab color="primary" [routerLink]="['/catalogo-inventario/consultar']" [queryParams]="{id: element.id_anexo_dos_pk, opcion: opcion_catalogo}">
                        <mat-icon class="material-icons" >
                            open_in_new
                        </mat-icon>
                    </button>

                    <button  *ngIf="opcion_catalogo == 4" type="button" mat-mini-fab color="primary" [routerLink]="['/catalogo-inventario/consultar']" [queryParams]="{id: element.id_anexo_tres_pk, opcion: opcion_catalogo}">
                        <mat-icon class="material-icons" >
                            open_in_new
                        </mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="ccaaii_modificar">
                <th id="ccaaii_modificar" mat-header-cell *matHeaderCellDef class="text-center"> MODIFICAR </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <button  *ngIf="opcion_catalogo == 1" type="button" mat-mini-fab color="primary" [routerLink]="['/catalogo-inventario/modificar']" [queryParams]="{id: element.id_presentacion_pk, opcion: opcion_catalogo}">
                        <mat-icon class="material-icons" >
                            open_in_new
                        </mat-icon>
                    </button>

                    <button  *ngIf="opcion_catalogo == 2" type="button" mat-mini-fab color="primary" [routerLink]="['/catalogo-inventario/modificar']" [queryParams]="{id: element.id_anexo_uno_pk, opcion: opcion_catalogo}">
                        <mat-icon class="material-icons" >
                            open_in_new
                        </mat-icon>
                    </button>

                    <button  *ngIf="opcion_catalogo == 3" type="button" mat-mini-fab color="primary" [routerLink]="['/catalogo-inventario/modificar']" [queryParams]="{id: element.id_anexo_dos_pk, opcion: opcion_catalogo}">
                        <mat-icon class="material-icons" >
                            open_in_new
                        </mat-icon>
                    </button>

                    <button  *ngIf="opcion_catalogo == 4" type="button" mat-mini-fab color="primary" [routerLink]="['/catalogo-inventario/modificar']" [queryParams]="{id: element.id_anexo_tres_pk, opcion: opcion_catalogo}">
                        <mat-icon class="material-icons" >
                            open_in_new
                        </mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="ccaaii_eliminar">
                <th id="ccaaii_modificar" mat-header-cell *matHeaderCellDef class="text-center"> ELIMNAR </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <button class="text-center pr-0"  *ngIf="opcion_catalogo == 1" type="button" mat-mini-fab color="warn" (click)="eliminar(element.id_presentacion_pk, 1)">
                        <mat-icon class="material-icons" >
                            delete
                        </mat-icon>
                    </button>

                    <button class="text-center"  *ngIf="opcion_catalogo == 2" type="button" mat-mini-fab color="warn" (click)="eliminar(element.id_anexo_uno_pk, 2)">
                        <mat-icon class="material-icons" >
                            delete
                        </mat-icon>
                    </button>

                    <button class="text-center"  *ngIf="opcion_catalogo == 3" type="button" mat-mini-fab color="warn" (click)="eliminar(element.id_anexo_dos_pk, 3)" >
                        <mat-icon class="material-icons" >
                            delete
                        </mat-icon>
                    </button>

                    <button class="text-center"  *ngIf="opcion_catalogo == 4" type="button" mat-mini-fab color="warn" (click)="eliminar(element.id_anexo_tres_pk, 4)">
                        <mat-icon class="material-icons" >
                            delete
                        </mat-icon>
                    </button>
                </td>
            </ng-container>


            <!-- fin contenido -->
            <tr mat-header-row *matHeaderRowDef="ccaaii_displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: ccaaii_displayedColumns;"></tr>
        </table>
    </div>
    <!-- fin tabla -->


</mat-card-content>
<mat-card-actions align="start">
    <mat-paginator (page)="pageEvent($event)" [length]="ccaaii_length" [pageSize]="ccaaii_pageSize" [pageSizeOptions]="ccaaii_pageSizeOptions"  aria-label="Selecione una pagina">
    </mat-paginator>
</mat-card-actions>
</mat-card>
</div>