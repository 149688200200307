import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  size = "500";
  constructor() { 
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

}
