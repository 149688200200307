<div class="card-container none-cii-aux">
    <mat-card class="example-card">
    <mat-card-header>
        <mat-card-title>Gestor de documentos</mat-card-title>
        <mat-card-subtitle>Localiza y administra documentos</mat-card-subtitle>
    </mat-card-header>
   
    <mat-card-content>
        <form (ngSubmit)="buscar(0,pagesize)" autocomplete="off" [formGroup]="formulario">
            <mat-form-field appearance="fill" class="bt col-md-6 none-cii-aux">
                <mat-label>Buscador</mat-label>
                <input matInput placeholder="Nombre del documento" formControlName="buscador">
                
            </mat-form-field>
            &nbsp;
            <!--<mat-form-field appearance="fill" class="bt col-md-4">
                <mat-label>Etapa</mat-label>
                <mat-select formControlName="subcategoria">
                    <mat-option value="">-- Seleccione una opción --</mat-option>
                    <mat-option ></mat-option>
                </mat-select>
            </mat-form-field>-->
            <mat-form-field appearance="fill" class="bt col-md-5 none-cii-aux">
                <mat-label>Tipo de documento</mat-label>
                <mat-select formControlName="tipo">
                    <mat-option>-- Ninguno --</mat-option>
                    <mat-optgroup *ngFor="let categoria of aCategorizacion" [label]="categoria.categorizacion">
                        <ng-container *ngFor="let tipo of aTipoDocumentos">
                            <mat-option [value]="tipo.id_tipo" *ngIf="tipo.categorizacion == categoria.categorizacion">
                                {{tipo.nombre_tipo_doc}}
                            </mat-option>
                        </ng-container>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
            <button type="submit" mat-mini-fab color="accent">
                <mat-icon>search</mat-icon>
            </button>

            <br/>
            <button type="button" color="primary" class="flat" mat-flat-button [routerLink]="['new']" *ngIf="tieneAccionCrear; else elseButtonNew">
                <mat-icon>add</mat-icon>Nuevo documento
            </button>
            <ng-template #elseButtonNew>
                <button type="button" color="primary" class="flat" mat-flat-button disabled>
                    <mat-icon>add</mat-icon>Nuevo documento
                </button>
            </ng-template>
            &nbsp;
            
        </form>
        
        <div class="table-container">
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <table mat-table [dataSource]="dataSource">
                <caption></caption>
                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
                
                <!-- Position Column -->
                <ng-container matColumnDef="position" class="none-cii-aux">
                    <th id="position" mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element;let i=index"> {{i+1}} </td>
                </ng-container>

                <!-- Position Column -->
                <ng-container matColumnDef="nombre" class="none-cii-aux">
                <th id="nombre" mat-header-cell *matHeaderCellDef> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                </ng-container>
            
                
            
                <ng-container matColumnDef="tipo" class="none-cii-aux">
                    <th id="tipo" mat-header-cell *matHeaderCellDef> Tipo </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.tipo}}
                    </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="descripcion" class="none-cii-aux">
                <th id="descripcion" mat-header-cell *matHeaderCellDef> Descripción </th>
                <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
                </ng-container>
            
                <!-- Symbol Column -->
                <ng-container matColumnDef="fecha_alta" class="none-cii-aux">
                <th id="fechaAlta" mat-header-cell *matHeaderCellDef> Fecha de alta </th>
                <td mat-cell *matCellDef="let element"> {{element.fecha_alta | date: 'dd/MM/yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="ver" class="none-cii-aux">
                    <th id="ver" mat-header-cell *matHeaderCellDef> ver </th>
                    <td mat-cell *matCellDef="let element">
                        <a mat-icon-button  color="primary" [routerLink]="['view']" [queryParams]="{id:element.id}">
                            <mat-icon>preview</mat-icon>
                        </a>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="descargar" class="none-cii-aux">
                    <th id="descarga" mat-header-cell *matHeaderCellDef> Descargar </th>
                    <td mat-cell *matCellDef="let element">
                        <a mat-icon-button  color="primary" href="/phps/{{element.descargar}}" download>
                            <mat-icon>download</mat-icon>
                        </a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="editar" class="none-cii-aux"> 
                    <th id="editar" mat-header-cell *matHeaderCellDef> Editar </th>
                    <td mat-cell *matCellDef="let element">
                        <a mat-icon-button  color="primary" [routerLink]="['edit']" [queryParams]="{id:element.id}"  *ngIf="tieneAccionEditar; else elseButtonEdit">
                            <mat-icon>edit</mat-icon>
                        </a>
                        <ng-template #elseButtonEdit>
                            <button mat-icon-button  color="warn" disabled>
                                <mat-icon>edit</mat-icon>
                            </button>
                        </ng-template>
                    </td>
                </ng-container>
                    <ng-container matColumnDef="eliminar" class="none-cii-aux"> 
                        <th id="eliminar" mat-header-cell *matHeaderCellDef> Eliminar </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button  color="warn" (click)="eliminar(element.id)" *ngIf="tieneAccionEliminar; else elseButtonDel">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <ng-template #elseButtonDel>
                                <button mat-icon-button  color="warn" disabled>
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </ng-template>
                        </td>
                    </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card-content>
    <mat-card-actions>

        <mat-paginator
            [length]="count"
            [pageSize]="paginador.pageSize"
            [pageSizeOptions]="paginador.pageOptions"
            (page)="pager($event)">
        </mat-paginator>

    </mat-card-actions>
</mat-card>