import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Sesion } from '../model/sesion';
import { ConfigurationService } from './configuration.service';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class SesionService {

  private _session:Sesion;

  constructor(
    //private router:Router,
    private _cookie:CookieService, 
    private _http:HttpClient,
    private _config:ConfigurationService) {
    this._session = new Sesion();
  }
  login(usr:string,pwd:string):Observable<any>{
    console.info("Iniciando la sessión del usuario");
    let path = this._config.getConfiguration().endpoints.session.path;
    let data = {user:usr,password:pwd};
    return this._http.get(path);
  }
  loginByCookie(){
    return new Promise((resolve, reject) => {
      let cookieAplicativa = this._cookie.getCookie(this._config.getConfiguration().cookieName);
      console.info("## Cookie aplicativa: "+ cookieAplicativa);
      if(cookieAplicativa.length>0){
        //Usuario y password debe de ser obtenido desde la cookie
        this.login("","").subscribe(result=>{
          let resultado:Sesion = new Sesion();
          resultado.setUsuario(result.usuario);
          this.sessionCreate(resultado,true);
          resolve(true);
        },error=>{
          reject();
        });
      }else{
        resolve(false);
      }
    });
  }
  sessionCreate(session:Sesion,active:boolean):void{
    this._session.setUsuario(session.getUsuario());
    this._session.setActive(active);
    this._session.setAcciones(session.getAcciones());
    console.info(this._session);
  }
  getSession():Sesion{
    return this._session;
  }
  logOut():void{
    this._cookie.deleteCookie(this._config.getConfiguration().cookieName);
    this._session = new Sesion();
  }
  getStatus():boolean{
    return this._session.getActive();
  }
  /*crearCookie(){
    this._cookie.setCookie(this._config.getConfiguration().cookieName,"La tested cookie",1);
  }*/

  validaPermisos(url){
    let acciones = this.getSession().getAcciones();
    console.log(acciones);
    for(let i = 0; i<acciones.length; i++){
      if(acciones[i].url == url){
        return true;
      }
    }
    return false
  }
}
