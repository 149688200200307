import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Beneficiaria } from 'src/app/model/beneficiaria';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import * as moment from 'moment';
import { ParametrosService } from 'src/app/services/parametros.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from 'src/app/services/configuration.service';


@Component({
  selector: 'app-datos-generales',
  templateUrl: './datos-generales.component.html',
  styleUrls: ['./datos-generales.component.scss']
})
export class DatosGeneralesComponent implements OnInit {
  hostapi:string;
  existente=false;
  isPerfil = true;
  selected = new UntypedFormControl(0);
  esFichaTecnica = false;
  fechaIngreso = "";
  environment = environment;
  constructor(private _documentosService:DocumentosService,
    private _parametrosService:ParametrosService,
    private _route:Router,private _beneficiariasService:BeneficiariaService,
    private activatedRoute:ActivatedRoute,
    private _config:ConfigurationService,) { 
      this.hostapi = _config.getConfiguration().hostapi;
      this.dgg__getCatalogos();
    
    
  }
  infoBeneficiaria = {};
  @Input() ficha:boolean = false;
  @Output() beneficiariaSeleccionada = new EventEmitter<Beneficiaria>();
  @Output() fichaTecnica = new EventEmitter<any>();
  beneficiaria:Beneficiaria;

  ngOnInit(): void {
    
    if(this._route.url.indexOf("ficha-tecnica")>-1 
    || this._route.url.indexOf("gestor-expedientes-medicos")>-1 
    || this._route.url.indexOf("gt-expedientes-medicos-generales")>-1
    || this._route.url.indexOf("control-vacunacion")>-1
    || this._route.url.indexOf("expedientes-medicos/gt-terapias-companas")>-1
    || this._route.url.indexOf("expedientes-medicos-generales/gt-terapias-companas/consultar")>-1
    || this._route.url.indexOf("expedientes-medicos/gt-impresion-expdiente-medico/consultar")>-1){
      this.esFichaTecnica=true;
    }else if(this.ficha){
      this.esFichaTecnica = true;
    }
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info("Parametros");
      console.info(params["id"]);
      if(params["id"]!=undefined){
        this.existente=true;
        console.log(params);
        if(params["ben"]!=undefined){
          this._beneficiariasService.getProcesosByBeneficiaria(params["ben"]).subscribe(resultado=>{
            console.log(resultado);
            this.both(resultado);
            this.dgg__getBeneficiaria(<number>params["ben"]);
          },()=>{
            this.isLoading = false;
          });
        }else if(params["id"]!=undefined){
          this._beneficiariasService.getProcesosByBeneficiaria(params["id"]).subscribe(resultado=>{
            console.log(resultado);
            this.both(resultado);
            this.dgg__getBeneficiaria(<number>params["id"]);
          },()=>{
            this.isLoading = false;
          });
        }



        
        
        
      }else{
        this.existente=false;
        this.beneficiaria = new Beneficiaria();
      }
    });
  }

  public both(resultado: any){
    this.isLoading = false;
    this.fechaIngreso = new Date(resultado.data.procesos[0].etapas.candidata.fechaAlta.date).toJSON();
  }

  isLoading = false;
  documentos:any;
  dgg__getBeneficiaria(id:number){
    let etapas = this._config.getConfiguration()["etapasActivas"];
    console.log(etapas);
    this.isLoading = true;
    this._beneficiariasService.findById(id).subscribe(result=>{
      console.log(result);
      this.beneficiaria = result.data;
      this._beneficiariasService.findComplementos(id).subscribe(result2=>{
        console.log(result2);
        if(result2.data.length>0){
          this.beneficiaria.etapa = result2.data[0].etapa_activa;
          this.beneficiaria.motivoEgreso = result2.data[0].motivo_egreso;
          this.beneficiaria.desMotivoEgreso = result2.data[0].des_motivo_egreso;
          this.beneficiaria.lugarDestino = result2.data[0].lugar_destino;
          this.beneficiaria.desLugarDestino = result2.data[0].des_lugar_destino;

          if(etapas.indexOf(result2.data[0].etapa_activa.toUpperCase())>-1){
            this.beneficiaria.estatus = "ACTIVO";
          }else{
            this.beneficiaria.estatus = "NO ACTIVO";
          }
          this.beneficiaria.fechaEgreso = result2.data[0].fecha_egreso; 
        }
        console.info(this.beneficiaria);

      });
      
      
      this.beneficiariaSeleccionada.emit(result.data);
      console.info(this.beneficiaria);
      this._documentosService.getAllByBeneficiaria(id).subscribe(result=>{
        this.documentos=result.data.documentos;
        console.log(result);
        this.infoBeneficiaria = {
          "nombreCompleto":this.beneficiaria.nombre,
          "curp":this.beneficiaria.idCurp,
          "fechaNacimiento": this.dgg__getFechaNac(),
          "edadActual": this.edad,
          "canalizadaPor": this.dgg__getCanaliza(this.beneficiaria.idCanaliza) + "" + (this.beneficiaria?.desCanaliza == null ? "" : " - "+this.beneficiaria?.desCanaliza),
          "motivoDeIngreso" : this.dgg__getMotivo(this.beneficiaria?.idMotivoIngreso) + "" + (this.beneficiaria?.desMotivoIngreso == null ? "": " - " + this.beneficiaria?.desMotivoIngreso),
          "fechaIngreso": this.dgg__getFechaIngreso(),
          "edadIngreso" : this.edadIngreso,
          "tipoDeDiscapacidad" : this.dgg__getDocumento(120),
          "tiempoDeEstancia" : this.tiempoDeAntiguedad,
          "diagnosticoDN" : this.dgg__getDN(),
          "diagnosticoDM" : this.dgg__getDocumento(59),
          "condicionRelevante" : this.dgg__getDocumento(84),
          "tipoDeSangre" : this.dgg__getTipoSangre()
        };
        console.log(this.infoBeneficiaria);
        this.fichaTecnica.emit(this.infoBeneficiaria);
        this.isLoading = false;
      },()=>{
        this.isLoading = false;
      });
    });
  }

  dgg__getDocumento(id: any){
    if(this.documentos){
      for(let dgg=0; dgg<this.documentos.length; dgg++){
        if(this.documentos[dgg].idTipoDocumento == id){
          return this.documentos[dgg].desTipoCatalogo.trim() =="" ? "-":this.documentos[dgg].desTipoCatalogo;
        }
      }
      return "-";
    }else{
      return "-";
    }
  }
  dgg__getIdDocumento(id: any){
    if(this.documentos){
      for(let dgg=0; dgg<this.documentos.length; dgg++){
        if(this.documentos[dgg].idTipoDocumento == id){
          return this.documentos[dgg].idTipoCatalogo;
        }
      }
      return "-"
    }else{
      return "-";
    }
  }
  get edad(): String {
    if(this.beneficiaria){
      let edad:Number = moment().diff(this.beneficiaria.fechaNacimiento["date"], 'years',true);
      let laedad:String = edad.toString();
      let años = laedad.split(".")[0];
      let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let detalleEdad = años + " años "+ meses + " meses";
      return detalleEdad;
    }else{
      return "-";
    }
    
  }
  get estancia(): String {
    if(!this.beneficiaria){
      return "-";
    }else if(!this.beneficiaria.fechaIngreso){
      return "-";
    }
    let edad:Number = moment().diff(this.beneficiaria.fechaIngreso["date"], 'years',true);
    let laedad:String = edad.toString();
    let años = laedad.split(".")[0];
    let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];
    let detalleEdad = años + " años "+ meses + " meses";
    return detalleEdad;
  }
  get edadIngreso(){
    if(!this.beneficiaria){
      return "-";
    }
    
    //console.info(this.beneficiaria.fechaIngreso["date"]);
    let edad:Number = moment(this.fechaIngreso).diff(this.beneficiaria.fechaNacimiento["date"], 'years',true);
    //console.info(edad);
    let laedad:String = edad.toString();
    let años = laedad.split(".")[0];
    let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];
    let detalleEdad = años + " años "+ meses + " meses";
    return detalleEdad;
  }
  get tiempoDeAntiguedad(){
    if( this.fechaIngreso=="" || this.fechaIngreso==null ){
      return "0";
    }else{
      //console.info(this.beneficiaria.fechaIngreso["date"]);
      //let edad:Number = moment(new Date()).diff(this.fechaIngreso, 'years',true);
      let edad:Number = moment(this.beneficiaria.fechaEgreso).diff(this.fechaIngreso, 'years',true);
      //console.info(edad);
      let laedad:String = edad.toString();
      let años = laedad.split(".")[0];
      let meses = ((Number) ("0."+(laedad.split(".")[1]) ) * 12).toString().split(".")[0];
      let detalleEdad = años + " años "+ meses + " meses";
      return detalleEdad;
    }
  }
  dgg__getFechaNac(){
    if(this.beneficiaria){
      let arreglo = new Date(this.beneficiaria.fechaNacimiento["date"]).toJSON().split("T")[0].split("-");
      return arreglo[2] + "/" +arreglo[1] + "/" + arreglo[0];
    }else{
      return "-";
    }
  }
  canaliza2=[];
  motivoIngreso=[];
  diagnosticoNeurologico = [];
  tiposDeSangre = [];
  dgg__getCatalogos(){
    this._parametrosService.getParametros("2").subscribe(result=>{
      this.canaliza2 = result.data.parametros;
    });

    this._parametrosService.getParametros("3").subscribe(result=>{
      this.motivoIngreso = result.data.parametros;
    });

    this._parametrosService.getParametros("4").subscribe(result=>{
      this.diagnosticoNeurologico = result.data.parametros;
    });

    this._parametrosService.getParametros("11").subscribe(result=>{
      this.tiposDeSangre = result.data.parametros;
    });
  }

  dgg__getCanaliza(id: any){
    for(let dgg=0; dgg<this.canaliza2.length; dgg++){
      if(this.canaliza2[dgg].idParametro==id){
        return this.canaliza2[dgg].valor;
      }
    }
    return "N/D";
  }
  dgg__getMotivo(id: any){
    for(let dgg=0; dgg<this.motivoIngreso.length; dgg++){
      if(this.motivoIngreso[dgg].idParametro==id){
        return this.motivoIngreso[dgg].valor;
      }
    }
    return "N/D";
  }

  dgg__getDN(){
    let id = this.dgg__getIdDocumento(57);
    for(let dgg=0; dgg<this.diagnosticoNeurologico.length; dgg++){
      if(this.diagnosticoNeurologico[dgg].idParametro==id){
        return this.diagnosticoNeurologico[dgg].valor;
      }
    }
    return "-";
  }

  dgg__getTipoSangre(){
    let id = this.dgg__getIdDocumento(62);
    for(let dgg=0; dgg<this.tiposDeSangre.length; dgg++){
      if(this.tiposDeSangre[dgg].idParametro==id){
        return this.tiposDeSangre[dgg].valor;
      }
    }
    return "-";
  }

  dgg__getFechaIngreso(){
    if(this.fechaIngreso!=""){
      let arreglo = this.fechaIngreso.split("T")[0].split("-");
      localStorage.setItem('fi', arreglo[2] + "/" +arreglo[1] + "/" + arreglo[0]);
      return arreglo[2] + "/" +arreglo[1] + "/" + arreglo[0];
    }else{
      localStorage.setItem('fi', '-');
      return "-";
    }
  }
}
