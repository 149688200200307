import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CapacitacionService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

 

  public postRegistrarCapacitacion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarCapacitacion", datos, {});
  }

  public postModificarCapacitacion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarCapacitacion", datos, {});
  }

  public postRegistrarDocumentoCapa(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarDocumentoCapa", datos, {});
  }

  public postModificarDocumentoCapa(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarDocumentoCapa", datos, {});
  }

  public postRegistrarFotoCapa(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarFotoCapa", datos, {});
  }

  public postModificarFotoCapa(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarFotoCapa", datos, {});
  }

  public postConsultarCapacitacion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ConsultarCapacitacion", datos, {});
  }

  public postConsultarCpacitaciones(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ConsultarCpacitaciones", datos, {});
  }

  public postEliminarFechaIncidentes(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarFechaIncidentes", datos, {});
  }

  

  public postEliminarDocumentoCapa(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarDocumentoCapa", datos, {});
  }


  public postEliminarFotoCapa(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarFotoCapa", datos, {});
  }

  public getExpedienteConsulta(id: any):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ExpedienteConsulta?numero_empleado_reg="+id, {});
  }

  






  

 

  
  

  



  
}
