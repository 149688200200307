import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Beneficiaria } from '../model/beneficiaria';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BeneficiariaService {
  endpoint = "";
  endpoint2 = "";
  headers = new HttpHeaders();
  phpPath = "";
  public laravelapi: any;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService) {
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.phpPath = _config.getConfiguration().embedPhpPath;
      this.endpoint = _config.getConfiguration().hostapi + this._config.getConfiguration().endpoints["beneficiarias"].path;
      this.endpoint2 = _config.getConfiguration().hostapi + this._config.getConfiguration().endpoints["general"].path;;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

  findAll(buscador:string, estatus:string,page:number,size:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"?page="+page+"&size="+size+"&nombre="+buscador+"&estatus="+estatus);
  }
  findDiscapacidades(ids:string){
    return this._http.get<ResponseGenerico>(this.phpPath+"/findDiscapacidades.php?ids="+ids);
  }
  export(buscador:string, estatus:string):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"?nombre="+buscador+"&estatus="+estatus);
  }
  obtenerBenefiariasActivas(){
    return this._http.get<ResponseGenerico>(this.phpPath+"/beneficiariasActivas.php");
  }
  exportarCSV(){
    return this._http.get<ResponseGenerico>(this.phpPath+"/reportBeneficiarias.php");
  }
  findById(id:number){
    return this._http.get<ResponseGenerico>(this.endpoint+"/"+id);
  }
  findComplementos(id:number){
    return this._http.get<ResponseGenerico>(this.phpPath+"/getComplementoFicha.php?id="+id);
  }
  save(data:Beneficiaria,@Optional() id:number):Observable<ResponseGenerico>{
    console.info("Beneficiaria: ");
    console.info(data);
    let peticion:Observable<ResponseGenerico>;
    if(id==null){
      peticion = this._http.post<ResponseGenerico>(this.endpoint,data);
    }else{
      peticion = this._http.put<ResponseGenerico>(this.endpoint+"/"+id,data);
    }
    return peticion;
  }
  savePerfiles(id:number,data):Observable<ResponseGenerico>{
    return this._http.put<ResponseGenerico>(this.endpoint+"/"+id+"/perfiles",data);
  }
  deleteById(id:number):Observable<ResponseGenerico>{
    return this._http.delete<ResponseGenerico>(this.endpoint+"/"+id);
  }
  getFotos(id:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/"+id+"/historia-fotos?nf=30&fa=true");
  }

  altaFoto(id:number,file):Observable<any>{
    this.headers.set('Content-Type', 'multipart/form-data');
    return this._http.post(this.endpoint+"/"+id+"/fotos",file,{
      headers: this.headers
    });
  }

  //Procesos
  getProcesosByBeneficiaria(id:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/"+id+"/etapas-atencion");
  }
  updateEtapa(idBeneficiaria:number,idProceso:number,data:any):Observable<ResponseGenerico>{
    return this._http.patch<ResponseGenerico>(this.endpoint+"/"+idBeneficiaria+"/etapas-atencion",data);
  }
  
  updateCandidata(idBeneficiaria:number,idProceso:number,data:any):Observable<ResponseGenerico>{
    return this._http.patch<ResponseGenerico>(this.endpoint+"/"+idBeneficiaria+"/etapas-atencion",data);
  }

  //EtapaCandidata
  getProcesoCandidata(id:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/etapas-atencion/"+id+"/candidatas");
  }

  //Etapa a prueba
  getProcesoAPrueba(idProceso:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/etapas-atencion/"+idProceso+"/a-pruebas");
  }

  //Etapa sanacion
  getProcesoSanacion(idProceso:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/etapas-atencion/"+idProceso+"/sanaciones");
  }

  //Etapa desarrollo
  getProcesoDesarrollo(idProceso:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/etapas-atencion/"+idProceso+"/desarrollos");
  }

  //Etapa Preparación
  getProcesoPreparacion(idProceso:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/etapas-atencion/"+idProceso+"/preparaciones");
  }

  //Etapa Egreso
  getProcesoEgreso(idProceso:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/etapas-atencion/"+idProceso+"/egresos");
  }

  nuevoProceso(idBeneficiaria:number):Observable<ResponseGenerico>{
    return this._http.post<ResponseGenerico>(this.endpoint+"/"+idBeneficiaria+"/etapas-atencion",{});
  }

  //url
  //numero_foto_beneficiario
  //numero_beneficiario
  getEliminarFotosUsuario(id: number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint2+"/eliminarFotosUsuario?numero_foto="+id);
  }

  //url
  //numero_documento
  //numero_beneficiario
  getEliminarDocumentoBeneficiario(beneficiario: number, numero_tipo_doc: number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint2+"/eliminarDocumentoBeneficiario?numero_foto="+beneficiario+'&numero_tipo_doc='+numero_tipo_doc);
  }


  public postRegistroAntecedentesNoPatologicos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarFotoBeneficiario", datos, {});
  }


  public postEliminarDocumentoBene(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarDocumentoBene", datos, {});
  }

  

  
}
