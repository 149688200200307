<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>

<div class="container mt-5">

    <div class="row">
        <h1 *ngIf="form2.value.calendario == 1">Calendario escolizado</h1>
        <h1 *ngIf="form2.value.calendario == 2">Calendario sin escolarizar</h1>
    </div>
    
    <div class="row">

        <div class="col">
            <form novalidate [formGroup]="form2" autocomplete="off">
                <mat-form-field appearance="fill" class="input-full-w">
                    <mat-label>Tipo de calendario</mat-label>
                    <mat-select formControlName="calendario" (ngModelChange)="getConsultarCalendarioBusqueda()" >
                        <mat-option [value]="1">Calendario escolarizada</mat-option>
                        <mat-option [value]="2">Calendario sin escolarizar</mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>
        
        <div class="col">
            <form novalidate [formGroup]="form2" autocomplete="off">
                <mat-form-field appearance="fill" class="input-full-w">
                    <mat-label>Tipo de configuración</mat-label>
                    <mat-select formControlName="numero_configuracion" (ngModelChange)="getConsultarCalendarioBusqueda()" >
                        <mat-option [value]="1">Estructura</mat-option>
                        <mat-option [value]="2">Individual</mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>
        <div class="col">
            <div class="row">
                
                <div class="col">
                    <button [routerLink]="['/gt-calendario']" [queryParams]="{tipo: tipo_general}" class="flat" color="primary" mat-flat-button>
                        Gestor del calendario
                    </button>

                    <!--<button (click)="modalCal(1, -1, 'registrar')" class="flat" color="primary" mat-flat-button>
                        Nuevo evento
                    </button>
                    <button (click)="modalCal(2, -1, 'registrar')" class="flat" color="primary" mat-flat-button>
                        Nuevo evento
                    </button>-->
                    &nbsp;
                    <button mat-flat-button class="flat" color="primary" [matMenuTriggerFor]="menu">Nuevo evento</button>
                    <mat-menu #menu="matMenu">
                    <button mat-menu-item  (click)="modalCal(1, -1, 'registrar', null)">Estructura</button>
                    <button mat-menu-item  (click)="modalCal(2, -1, 'registrar', null)">Individual</button>
                    </mat-menu>

                </div>
                
            </div>
            
        </div>
    </div>
    <div class="row">
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner color="accent"></mat-spinner>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col">
            <full-calendar #calendar [options]="calendarOptions"></full-calendar>
        </div>
    </div>
</div>
