<div class="card-container">
    <mat-card class="example-card">
    <mat-card-header>
        <mat-card-title>Gestor de Usuarios</mat-card-title>
        <mat-card-subtitle>Localiza y gestiona usuarios del sistema</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <form (ngSubmit)="sendForm()" autocomplete="off" [formGroup]="formulario">
            <mat-form-field appearance="fill" class="bt col-md-5">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="nombre">
                
            </mat-form-field>
            <mat-form-field appearance="fill" class="bt col-md-3">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
            </mat-form-field>
            <mat-form-field appearance="fill" class="bt col-md-3">
                <mat-label>Licencia</mat-label>
                <mat-select formControlName="perfil">
                    <mat-option value="">-- Selecciona una opción --</mat-option>
                    <mat-option *ngFor="let perfil of perfiles" value="{{perfil.idPerfil}}">{{perfil.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!--&nbsp;
            <mat-form-field appearance="fill" class="bt col-md-4">
                <mat-label>Estatus</mat-label>
                <mat-select formControlName="estatus">
                    <mat-option value="">-- Seleccione una opción --</mat-option>
                    <mat-option *ngFor="let estado of estados" value="{{estado.idParametro}}">{{estado.valor}}</mat-option>
                </mat-select>
            </mat-form-field>-->
            <button type="submit" mat-mini-fab color="accent">
                <mat-icon>search</mat-icon>
            </button>

            <br/>
            <button type="button" color="primary" class="flat" mat-flat-button [routerLink]="['alta']">
                <mat-icon>person_add</mat-icon>&nbsp;Nuevo usuario
            </button>
        </form>
        
        <div class="table-container">
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <table mat-table [dataSource]="dataSource">
                <caption></caption>
                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
                
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                    <th id="position" mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element;let i=index"> {{i+1}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="nombre">
                <th id="nombre" mat-header-cell *matHeaderCellDef> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element[0].nombre}} {{element[0].apellidoPaterno}} {{element[0].apellidoMaterno}} </td>
                </ng-container>
            
                <!-- Name Column -->
                <ng-container matColumnDef="puesto">
                <th id="puesto" mat-header-cell *matHeaderCellDef> Puesto </th>
                <td mat-cell *matCellDef="let element"> {{element[0].puesto}} </td>
                </ng-container>
            
                <!-- Weight Column -->
                <ng-container matColumnDef="licencia">
                <th id="idPerfil" mat-header-cell *matHeaderCellDef> Licencia </th>
                <td mat-cell *matCellDef="let element"> {{element[0].idPerfil}} </td>
                </ng-container>
            
                <!-- Symbol Column -->
                <ng-container matColumnDef="usuario">
                <th id="username" mat-header-cell *matHeaderCellDef> Usuario </th>
                <td mat-cell *matCellDef="let element"> {{element[0].username}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="email">
                <th id="email" mat-header-cell *matHeaderCellDef> E-mail </th>
                <td mat-cell *matCellDef="let element"> {{element[0].correo}} </td>
                </ng-container>

                <ng-container matColumnDef="editar">
                    <th id="editar" mat-header-cell *matHeaderCellDef> Editar </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" [routerLink]="['modificacion']" [queryParams]="{id: element[0].idUsuario}">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="eliminar">
                    <th id="eliminar" mat-header-cell *matHeaderCellDef> Eliminar </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="accent" (click)="eliminar(element[0].username)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
          

    </mat-card-content>
    <mat-card-actions>
        <mat-paginator
               [pageSize]="paginador.pageSize"
               [pageSizeOptions]="paginador.pageOptions"
               (page)="pager($event)">
        </mat-paginator>
    </mat-card-actions>
    </mat-card>
</div>