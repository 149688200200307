import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {
  phpPath = "";
  endpoint = "";
  headers = new HttpHeaders();
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService) {
      this.phpPath = _config.getConfiguration().embedPhpPath;
      this.endpoint = _config.getConfiguration().hostapi + this._config.getConfiguration().endpoints["documentos"].path;
  }

  getAllByBeneficiaria(idBeneficiaria:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/beneficiaria/"+idBeneficiaria);
  }
  getAllByBeneficiariaAndDocumento(idBeneficiaria:number,idDocumento:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/beneficiaria/"+idBeneficiaria+"/documento/"+idDocumento);
  }
  save(data):Observable<ResponseGenerico>{
    this.headers.set('Content-Type', 'multipart/form-data');
      return this._http.post<ResponseGenerico>(this.endpoint+"?t="+new Date().toJSON(), data,{
        headers: this.headers
      });
  }
  saveMulti(data):Observable<ResponseGenerico>{
    this.headers.set('Content-Type', 'multipart/form-data');
      return this._http.post<ResponseGenerico>(this.endpoint+"/multi?t="+new Date().toJSON(), data,{
        headers: this.headers
      });
  }
  delete(data:any):Observable<ResponseGenerico>{
    return this._http.post<ResponseGenerico>(this.endpoint+"/borrar", data);
  }
  getCatalogos():Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.phpPath+"/documentos-service.php?opc=catalogos");
  }
  getComboTipos():Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.phpPath+"/documentos-service.php?opc=catalogocombo");
  }
  altaDocumento(file:File,json:any,id:number):Observable<any>{
    const formData = new FormData();
    let headers = new HttpHeaders({
      'Content-Type':'multipart/form-data'
    })
    let options = {headers:headers};
    console.info(json);
    formData.append('file',file);
    formData.append('nombre',json.nombre);
    formData.append('descripcion',json.descripcion);
    formData.append('tipo_docto',json.tipo_documento);
    formData.append('fecha_alta',new Date(json.fecha_alta).toJSON());

    if(id){
      return this._http.post<any>(this.phpPath+"/documentos-service.php?opc=modifica&id="+id,formData);
    }else{
      return this._http.post<any>(this.phpPath+"/documentos-service.php?opc=alta",formData);
    }
    
  }
  buscaDocumentos(buscador:string,page:number,size:number,id:string,tipo:string){
    if(id){
      return this._http.get<ResponseGenerico>(this.phpPath+"/documentos-service.php?opc=consulta&id="+id);
    }else{
      if(tipo){
        return this._http.get<ResponseGenerico>(this.phpPath+"/documentos-service.php?opc=consulta&buscador="+buscador+"&page="+page+"&size="+size+"&tipo="+tipo);
      }else{
        return this._http.get<ResponseGenerico>(this.phpPath+"/documentos-service.php?opc=consulta&buscador="+buscador+"&page="+page+"&size="+size); 
      }
    }
    
  }
  deleteByid(id){
    return this._http.delete<ResponseGenerico>(this.phpPath+"/documentos-service.php?opc=baja&id="+id);
  }
}
