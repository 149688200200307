import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DesitionBoxComponent } from 'src/app/modals/desition-box/desition-box.component';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { Beneficiaria } from 'src/app/model/beneficiaria';
import { Paginador } from 'src/app/model/configuracion';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ParametrosService } from 'src/app/services/parametros.service';

const ELEMENT_DATA: Beneficiaria[] = [];

@Component({
  selector: 'app-evaluaciones',
  templateUrl: './evaluaciones.component.html',
  styleUrls: ['./evaluaciones.component.scss']
})
export class EvaluacionesComponent implements OnInit {
  isLoading = false;
  paginador:Paginador;
  displayedColumns: string[] = ['nombre', 'estatus','etapa', 'motivo_ingreso', 'discapacidad','canalizador','mas'];
  dataSource = new MatTableDataSource<Beneficiaria>(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  canalizadores:any;
  motivosDeIngreso:any;
  constructor(
    public dialog: MatDialog,
    private _beneficiariaService:BeneficiariaService,
    private _config:ConfigurationService,
    private _parametros:ParametrosService
    ) { 
    this.paginador = this._config.getConfiguration().ui.paginador;
  }

  formulario = new UntypedFormGroup({
    buscar:new UntypedFormControl("",[]),
    estatus:new UntypedFormControl("",[])
  })

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    console.info("Entrando a beneficiarias");
    this.eval_getEstatus__();
    this.eval_getCanalizadores__();
    this.eval_getMotivosDeIngreso__();
    this.eval_buscar__(1,10);
  }

  eval_sendForm__(){
    this.eval_buscar__(this.paginator.pageIndex+1,this.paginator.pageSize);
  }
  eval_buscar__(page:number,size:number){
    this.dataSource = new MatTableDataSource<Beneficiaria>([]);
    this.isLoading = true;
    this._beneficiariaService.findAll(this.formulario.controls["buscar"].value,this.formulario.controls["estatus"].value,page,size).subscribe(result=>{
      this.isLoading = false;
      console.info(result);
      this.paginator.length = result.data.count;
      this.dataSource = new MatTableDataSource<Beneficiaria>(result.data.registros);
    },()=>{
      this.isLoading = false;
    });
  }

  eval_pager__(event){
    this.paginator.pageSize = event.pageSize;
    this.paginator.pageIndex = event.pageIndex;
    this.eval_buscar__(event.pageIndex + 1 ,event.pageSize);
  }

  eval_confirmaEliminacion__(id:number): void {
    const dialogRef = this.dialog.open(DesitionBoxComponent, {
      data: {titulo: "Eliminando expediente", mensaje: "Está eliminando un expediente, la información no podrá ser recuperada, ¿Desea continuar?", desicion:true}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if(result){
        this.eval_eliminar__(id);
      }
    });
  }
  eval_eliminar__(id:number){
    this.isLoading = true;
    this._beneficiariaService.deleteById(id).subscribe(result=>{
      this.isLoading = false;
      this.dialog.open(MessageBoxComponent, {
        data: {titulo: "Expediente eliminado", mensaje: "El expediente ha sido eliminado", desicion:true}
      });
      this.eval_buscar__(1,this.paginator.pageSize);
    },()=>{
      this.isLoading = false;
    });
  }
  estados:any[];
  eval_getEstatus__(){
    this._parametros.getParametros("1").subscribe(result=>{
      if(result.codigo = "PRM000"){
        this.estados = result.data.parametros;
      }
    },()=>{
      this.estados = [];
    })
  }
  eval_getCanalizadores__(){
    //param canaliza
    this._parametros.getParametros("2").subscribe(result=>{
      this.canalizadores = result.data.parametros;
    });
  }
  eval_getMotivosDeIngreso__(){
    this._parametros.getParametros("3").subscribe(result=>{
      this.motivosDeIngreso = result.data.parametros;
    });
  }
  eval_getEstatusById__(id:string){
    for(let eval_=0; eval_<this.estados.length; eval_++){
      if(this.estados[eval_].idParametro == id ){
        return this.estados[eval_].valor;
      }
    }
    return "N/D";
  }

  eval_getCanalizadorById__(id){
    for(let eval_=0; eval_<this.canalizadores.length; eval_++){
      if(this.canalizadores[eval_].idParametro == id ){
        return this.canalizadores[eval_].valor;
      }
    }
    return "N/D";
  }

  eval_getMotivoIngresoById__(id){
    for(let eval_=0; eval_<this.motivosDeIngreso.length; eval_++){
      if(this.motivosDeIngreso[eval_].idParametro == id ){
        return this.motivosDeIngreso[eval_].valor;
      }
    }
    return "N/D";
  }
  exportarCSV__(){
    this.isLoading = true;
    this._beneficiariaService.export(this.formulario.controls["buscar"].value,this.formulario.controls["estatus"].value).subscribe(result=>{
      this.isLoading = false;
      console.info(result);
      exportToCsv("beneficiarias-reporte-"+new Date().toLocaleString()+".csv", result.data.registros);
    },()=>{
      this.isLoading = false;
    });
  }

}

export function checkExistRow(row){
  if (!row || !row.length) {
    return;
  }
}

export function checkExistHeader(header, key){
  if (header) {
    return header;
  } else {
    return key;
  }
}


export function assignValueCell(row, ki){
  if (row[ki] === null || row[ki] === undefined) {
    return '';
  } else {
    return row[ki];
  }
}

export const exportToCsv = (filename: string, rows: object[], headers?: string[]): void => {

  checkExistRow(rows);

  // if (!rows || !rows.length) {
  //   return;
  // }
  const separator: string = ",";

  const keys: string[]  = Object.keys(rows[0]);

  // let columHearders: string[];

  let columHearders = checkExistHeader(headers,keys);
  
  // if (headers) {
  //     columHearders = headers;
  // } else {
  //     columHearders = keys;
  // }

  const csvContent =
      "sep=,\n" +
      columHearders.join(separator) +
      '\n' +
      rows.map(row => {
          return keys.map(k => {
              // let cell = row[k] === null || row[k] === undefined ? '' : row[k];
              let cell = assignValueCell(row, k);

              cell = cell instanceof Date 
                  ? cell.toLocaleString()
                  : cell.toString().replace(/"/g, '""');

              if (navigator.msSaveBlob) {
                  cell = cell.replace(/[^\x20-\x7F]/g, ""); //remove non-ascii characters
              }
              if (cell.search(/("|,|\n)/g) >= 0) {
                  cell = `"${cell}"`;
              }
              return cell;
          }).join(separator);
      }).join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // In case of IE 10+
      navigator.msSaveBlob(blob, filename);
  } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
  }
}


