import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ter-cam',
  templateUrl: './ter-cam.component.html',
  styleUrls: ['./ter-cam.component.scss']
})
export class TerCamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
