<div class="card-container none-con-aux">
    
    <mat-card class="example-card table-container">
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner color="accent"></mat-spinner>
        </div>
        <mat-card-header>
            <mat-card-title>Mantenimiento de Contactos</mat-card-title>
            <mat-card-subtitle>Detalles de Información de Contacto</mat-card-subtitle>
        </mat-card-header>
    
        <mat-card-content>
            <form #formu autocomplete="off" [formGroup]="formulario">
                

                <mat-form-field appearance="fill" class="bt col-md-6 none-con-aux">
                    <mat-label>Tipo de Contacto</mat-label>
                    <mat-select formControlName="tipoContacto">
                        <mat-option value="">-- Seleccione una Opción --</mat-option>
                        <ng-container *ngFor="let tipo of tipoContactos">
                            <mat-option [value]="tipo.id">{{tipo.nombre}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6 none-con-aux">
                    <mat-label>Nombre</mat-label>
                    <input matInput placeholder="" formControlName="nombre" maxlength="50">
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6 none-con-aux">
                    <mat-label>Organización</mat-label>
                    <input matInput placeholder="" formControlName="organizacion" maxlength="100">
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6 none-con-aux">
                    <mat-label>Cargo</mat-label>
                    <input matInput placeholder="" formControlName="cargo" maxlength="50">
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6 none-con-aux">
                    <mat-label>Direccion</mat-label>
                    <input matInput placeholder="" formControlName="direccion" maxlength="100">
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-3 none-con-aux">
                    <mat-label>Teléfono</mat-label>
                    <input matInput placeholder="" formControlName="telefono" maxlength="10">
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-3 none-con-aux">
                    <mat-label>Extención</mat-label>
                    <input matInput placeholder="" formControlName="extencion" maxlength="10">
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6 none-con-aux">
                    <mat-label>Correo Electrónico</mat-label>
                    <input matInput placeholder="" formControlName="correo" maxlength="50">
                </mat-form-field>


                <mat-form-field appearance="fill" class="bt col-md-3 none-con-aux">
                    <mat-label>Celular</mat-label>
                    <input matInput placeholder="" formControlName="celular" maxlength="10">
                </mat-form-field>


                <mat-form-field appearance="fill" class="bt col-md-12 none-con-aux">
                    <mat-label>Observaciones</mat-label>
                    <input matInput placeholder="" formControlName="observaciones" maxlength="100">
                </mat-form-field>

                
                <mat-card-actions>
                    <div class="text-align-center">
                        <button type="button" color="primary" mat-flat-button [routerLink]="['/centro-informacion-institucional/directorio']">Aceptar</button>
                    </div>
                </mat-card-actions>
            </form>
            
        </mat-card-content>
        
    </mat-card>
</div>