<div class="topbar">
    <mat-toolbar color="primary" class="header" *ngIf="validaRuta()==false">
        
        <img src="assets/img/logo-goretti.jpg" class="logo" [routerLink]="['/principal']" style="cursor: pointer;" alt="Logo Goretti">
        <div class="container-header-3demak">
            <span class="txt-header-3demak">Desarrollado por: </span>
            <a href="https://www.3demak.com/">
                <img class="txt-align-img-bottom img-header-3demak" src="/assets/img/LOGO-SLOGAN-FINAL-BLANCO.png" alt="Logo 3demak"/>
            </a>
        </div>
        
        
        <span class="example-spacer"></span>
        <button mat-button class="example-icon" aria-label="Example icon-button with share icon">
            <mat-icon>person</mat-icon>&nbsp;Hola, {{nombreDeUsuario}}
        </button>
        <button (click)="salir()" mat-button class="example-icon" aria-label="Example icon-button with share icon">
            Salir&nbsp;<mat-icon>launch</mat-icon>
        </button>
    </mat-toolbar>
    <mat-toolbar class="submenu" *ngIf="validaRuta()==false && validaMenu() ==false">
        <button color="accent" [matMenuTriggerFor]="menu" mat-mini-fab class="example-icon" aria-label="Example icon-button with menu icon">
            <mat-icon>apps</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/beneficiarias']">
                <mat-icon color="accent" >person</mat-icon>
                Beneficiarias
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['/evaluaciones']">
                <mat-icon color="accent">fact_check</mat-icon>
                Evaluaciones
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['/centro-informacion-institucional']">
                <mat-icon color="accent">book</mat-icon>
                Centro de información institucional
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['/eventos-de-vida']">
                <mat-icon color="accent">camera_alt</mat-icon>
                Eventos de vida
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['/founding']">
                <mat-icon color="accent" >monetization_on</mat-icon>
                Procuración de fondos
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [matMenuTriggerFor]="rrhh">
                <mat-icon color="accent" >groups</mat-icon>
                Recursos humanos
            </button>
            <mat-divider></mat-divider> 
            <button mat-menu-item [matMenuTriggerFor]="medico">
                <mat-icon color="accent" >medication_liquid</mat-icon>
                Médico
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['/administrador/usuarios']">
                <mat-icon color="accent" >badge</mat-icon>
                Gestor de usuarios
            </button>
        </mat-menu>
        
        <!-- menu header -->
        <span class="subtitle" *ngIf="esCentroInfo">Centro de información institucional</span>
        <span class="subtitle" *ngIf="esEventosDeVida">Eventos de vida</span>
        <span class="subtitle" *ngIf="esBeneficiarias">Beneficiarias</span>
        <span class="subtitle" *ngIf="esAdministrador">Administrador</span>
        <span class="subtitle" *ngIf="esEvaluaciones">Evaluaciones</span>
        <span class="subtitle" *ngIf="esMedico">Médico</span>
        <span class="subtitle" *ngIf="esMedicoAlmacenMedicamentos">Médico</span>
        <span class="subtitle" *ngIf="esGestorEYT">Gestor de eventos médicos</span>

        
        <button mat-button *ngIf="esAdministrador" class="example-icon" aria-label="Example icon-button with menu icon" [routerLink]="['/administrador/usuarios']">
            Gestor de usuarios
        </button>
        <button *ngIf="esBeneficiarias" mat-button class="example-icon" aria-label="Example icon-button with menu icon" [routerLink]="['/beneficiarias']">
            Gestor de expedientes
        </button>
        <button mat-button *ngIf="esEventosDeVida" class="example-icon" aria-label="Example icon-button with menu icon" [routerLink]="['/eventos-de-vida']">
            Gestor de eventos de vida
        </button>
        <button mat-button *ngIf="esEvaluaciones" class="example-icon" aria-label="Example icon-button with menu icon" [routerLink]="['/evaluaciones']">
            Gestor general de evaluaciones
        </button>

        <button mat-button *ngIf="esCentroInfo" class="example-icon" aria-label="Example icon-button with menu icon" [routerLink]="['/centro-informacion-institucional']">
            Gestor de documentos
        </button>
        <button mat-button *ngIf="esCentroInfo" class="example-icon" aria-label="Example icon-button with menu icon" [routerLink]="['/centro-informacion-institucional/directorio']">
            Directorio
        </button>

        <button mat-button *ngIf="esMedico" class="example-icon" aria-label="Example icon-button with menu icon" [routerLink]="['/medico/gestor-contactos-area-medica']">
            Directorio médico
        </button>
        <button mat-button *ngIf="esMedico" class="example-icon" aria-label="Example icon-button with menu icon" [routerLink]="['/medico/almacen-de-medicamentos/gestor-inventario']">
            Almacén de medicamentos
        </button>

        <button mat-button *ngIf="esMedicoAlmacenMedicamentos" class="example-icon" aria-label="Example icon-button with menu icon" [routerLink]="['/medico/almacen-de-medicamentos/gestor-inventario']">
            Inventario de medicamentos
        </button>
        <button mat-button *ngIf="esMedicoAlmacenMedicamentos" class="example-icon" aria-label="Example icon-button with menu icon" [routerLink]="['/medico/almacen-de-medicamentos/gestor-incremento-inventario']">
            Entradas
        </button>
        <button mat-button *ngIf="esMedicoAlmacenMedicamentos" class="example-icon" aria-label="Example icon-button with menu icon" [routerLink]="['/medico/almacen-de-medicamentos/gestor-salida-inventario']">
           Salidas
        </button>
        <!-- fin menu header -->



        <!-- submenus cuadro -->
        <mat-menu #rrhh="matMenu">
            <button mat-menu-item [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/gestor-organigrama-docs']">
                Biblioteca RRHH
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['rrhh/gestor-de-puestos-de-trabajo']">
                Gestor de puestos
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['rrhh/gestor-de-expedientes-de-personal']">
                Expediente de personal
            </button>
        </mat-menu>

        <mat-menu #medico="matMenu">
            <button mat-menu-item [routerLink]="['/medico/biblioteca-general']">
                Biblioteca Médico
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['medico/almacen-de-medicamentos/gestor-contactos-area-medica']">
                Directorio médico
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [matMenuTriggerFor]="almacenMedicamentos">
                Almacen de medicamentos
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['medico/expedientes-medicos/gestor-expedientes-medicos']">
                Expedientes médicos
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [matMenuTriggerFor]="expMedGen">
                Expedientes médicos generales
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['medico/gt-bitacora']">
                Bitácora de tratamientos y medicamentos
            </button>
        </mat-menu>
        <!-- fin submenus cuadro-->






        <!-- menu de submenu cuadro -->
        <mat-menu #almacenMedicamentos="matMenu">
            <button mat-menu-item [routerLink]="['catalogo-inventario']">
                Catalogo Inventario
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['medico/almacen-de-medicamentos/gestor-inventario']">
                Inventario
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['medico/almacen-de-medicamentos/gestor-incremento-inventario']">
                Incremento inventario
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['medico/almacen-de-medicamentos/gestor-salida-inventario']">
                Salida de inventario
            </button>
        </mat-menu>
        <!-- fin menu de submenu cuardo-->

        <!-- menu de submenu cuadro -->
        <mat-menu #expMedGen="matMenu">
            <button mat-menu-item [routerLink]="['/medico/expedientes-medicos-generales/gt-expedientes-medicos-generales']">
                Control grupal de peso y talla
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item >
                Terapias / Campañas
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item >
                Calendario médico
            </button>
        </mat-menu>
        <!-- fin menu de submenu cuardo -->
        





    </mat-toolbar>
</div>