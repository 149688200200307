<div class="card-container none-preparacion-aux">
    <div class="table-container">
        <mat-card class="example-card">
            <mat-card-header>

                <mat-card-title>
                    Etapa de Preparación
                </mat-card-title>
                <mat-card-subtitle>

                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="spinner" *ngIf="isLoading">
                    <mat-spinner color="accent"></mat-spinner>
                </div>
                <form [formGroup]="formulario" (ngSubmit)="send_Form()" autocomplete="off">
                    <mat-form-field appearance="fill" class="bt col-md-4 none-preparacion-aux">
                        <mat-label>Fecha de Apertura</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="fechaApertura">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #dp1 ></mat-datepicker>
                    </mat-form-field>

                    <div class="bt col-md-12 none-preparacion-aux">
                        <h3>Conclusiones de Etapa a Prueba</h3>
                        <p>{{conclusiones}}</p>
                    </div>

                    <!--<div class="bt col-md-12 none-preparacion-aux">
                        <h3>Notas sobre la sanación</h3>
                        <div class="bt col-md-3 text-align-center">
                            <button class="flat" mat-flat-button color="accent" type="button" (click)="pre_agregarNota()">
                                <mat-icon>add</mat-icon>&nbsp;Agregar Nota
                            </button>
                        </div>
                        <div class="bt col-md-9 none-preparacion-aux">
                            <div class="bt col-md-12 none-preparacion-aux">



                                <ng-container *ngFor="let nota of notas2; let c = index">
                                    <mat-form-field appearance="fill" class="bt col-md-4 none-preparacion-aux">
                                        <mat-label>Fecha</mat-label>
                                        <input matInput [matDatepicker]="fn1" formControlName="fechaNota{{c}}">
                                        <mat-datepicker-toggle matSuffix [for]="fn1"></mat-datepicker-toggle>
                                        <mat-datepicker touchUi #fn1 disabled="false"></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field appearance="fill" class="bt col-md-8">
                                        <mat-label>Descripción de la Nota</mat-label>
                                        <input matInput type="text" maxlength="2000" formControlName="descripcionNota{{c}}">
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </div>
                        <div class="bt col-md-12 none-preparacion-aux">
                            <mat-list>
                                <div mat-subheader>Notas</div>
                                <ng-container *ngFor="let nota of notas; let i=index">
                                    <mat-list-item >
                                        <mat-icon mat-list-icon>sticky_note_2</mat-icon>
                                        <div mat-line>{{nota.descNota}}</div>
                                        <div mat-line> {{nota?.fechaNota?.date | date:'mediumDate'}} </div>
                                    </mat-list-item>
                                    <mat-divider></mat-divider>
                                </ng-container>
                            </mat-list>
                        </div>
                    </div>-->
                    <div class="bt col-md-12 none-aprueba-aux">
                        <h3>Notas sobre la sanación</h3>
                        <div class="row">
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w ">
                                    <mat-label>Fecha</mat-label>
                                    <input matInput [matDatepicker]="aux1" formControlName="feecha">
                                    <mat-datepicker-toggle matSuffix [for]="aux1"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #aux1 disabled="false"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="fill" class="input-full-w ">
                                    <mat-label>Descripción de la nota</mat-label>
                                    <input matInput type="text" maxlength="2000" formControlName="descripcion_noota">
                                </mat-form-field>
                            </div>
                            <div class="col">
                               
                                    <button class="flat" mat-flat-button color="accent" type="button" (click)="prepa_agregarNota()">
                                        <mat-icon>add</mat-icon>&nbsp;Agregar nota
                                    </button>
                                
                            </div>
                        </div>
                        
                        <div class="bt col-md-12 none-aprueba-aux">
                            <div class="bt col-md-12 none-aprueba-aux">



                                <ng-container class="" *ngFor="let nota of notas2; let i = index">
                                    <mat-form-field appearance="fill" class="bt col-md-2 none-aprueba-aux">
                                        <mat-label>Fecha</mat-label>
                                        <input matInput [matDatepicker]="fn1" formControlName="fechaNota{{i}}">
                                        <mat-datepicker-toggle matSuffix [for]="fn1"></mat-datepicker-toggle>
                                        <mat-datepicker touchUi #fn1 disabled="false"></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field appearance="fill" class="bt col-md-8">
                                        <mat-label>Descripción de la nota</mat-label>
                                        <input matInput type="text" maxlength="2000" formControlName="descripcionNota{{i}}">
                                    </mat-form-field>

                                    <button  class="bt col-md-2" class="flat" mat-flat-button color="warn" type="button" (click)="prepa_eliminarNoota(i)">
                                        Eliminar nota
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                        <div class="bt col-md-12 none-aprueba-aux">
                            <mat-list>
                                <div mat-subheader>Notas</div>
                                <ng-container *ngFor="let nota of notas; let i=index">
                                    <mat-list-item >
                                        <mat-icon mat-list-icon>sticky_note_2</mat-icon>
                                        <div mat-line>{{nota.descNota}}</div>
                                        <div mat-line> {{nota?.fechaNota?.date | date:'mediumDate'}} </div>
                                    </mat-list-item>
                                    <mat-divider></mat-divider>
                                </ng-container>
                            </mat-list>
                        </div>
                    </div>

                    <h3>Conclusiones Relevantes</h3>

                    <mat-form-field appearance="fill" class="bt col-md-4 none-preparacion-aux">
                        <mat-label>Fecha de Cierre</mat-label>
                        <input matInput [matDatepicker]="dp2" formControlName="fechaCierre">
                        <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #dp2 disabled="false"></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-12 none-preparacion-aux">
                        <mat-label>Conclusiones</mat-label>
                        <textarea matInput placeholder="" formControlName="conclusionesRelevantes" maxlength="2000"></textarea>
                    </mat-form-field>
                    

                    <div>
                        <button type="submit" color="primary" mat-flat-button class="flat" *ngIf="indEstatus == 'A'">Guardar</button> &nbsp;
                        <button type="button" color="accent" mat-flat-button class="flat" (click)="pre_cerrarEtapa()" *ngIf="indEstatus == 'A'" [disabled]="formulario.invalid">Cerrar etapa</button>&nbsp;
                        <button type="button" color="default" mat-stroked-button (click)="pre_cancelar()" class="flat" >Cancelar</button>
                        <button type="button" color="warn" mat-flat-button (click)="pre_suspender()" class="flat" style="float:right" *ngIf="indEstatus == 'A'">Suspender</button>
                        <button type="button" color="primary" mat-flat-button (click)="pre_activar()" class="flat" style="float:right" *ngIf="indEstatus == 'S'">Activar</button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>