<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Gestor de registros grupales de peso y talla</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div class="bt col-md-12 text-align-right">
                <!--<button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt/eventos/registrar']" [queryParams]="{id:id}">
                    Nuevo evento
                </button>-->
                
                <!--<button class="flat" color="primary" mat-flat-button>Comparativo</button>
                    <button class="flat" color="primary" mat-flat-button>Gráfica (Pizza)</button>-->
                </div>

                <!-- tabla -->
                <div class="row mt-5 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="_id">
                            <th id="_id" mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let element">{{element?.id_periodo_fk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_prd">
                            <th id="_prd" mat-header-cell *matHeaderCellDef> <span class="d-flex justify-content-center">PERIODO</span> </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="d-flex justify-content-center">
                                    {{element?.year_periodo}}
                                </span>
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="_etd">
                            <th id="_etd" mat-header-cell *matHeaderCellDef> ESTATUS </th>
                            <td mat-cell *matCellDef="let element">{{element?.estados_generales?.estatus}}

                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_edt">
                            <th id="_edt" mat-header-cell *matHeaderCellDef> <span class="d-flex justify-content-center">EDITAR </span> </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="d-flex justify-content-center">
                                    <button  type="button" mat-mini-fab color="primary" >
                                        <mat-icon class="material-icons" [routerLink]="['/medico/expedientes-medicos-generales/gt-expedientes-medicos-generales/modificar']" [queryParams]="{np: element?.id_periodo_fk, per: element?.year_periodo, fa:element?.created_at, com: element?.comentarios, ps: element?.persona_inicio, s: element?.id_estatus_medico_fk}">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                                </span>
                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_cst">
                            <th id="_cst" mat-header-cell *matHeaderCellDef> <span class="d-flex justify-content-center">CONSULTAR </span> </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="d-flex justify-content-center">
                                    <button  type="button" mat-mini-fab color="primary">
                                       
                                        <mat-icon class="material-icons" [routerLink]="['/medico/expedientes-medicos-generales/gt-expedientes-medicos-generales/consultar']" [queryParams]="{np: element?.id_periodo_fk, per: element?.year_periodo, fa:element?.created_at, com: element?.comentarios, ps: element?.persona_inicio}">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_elmn">
                            <th id="_elmn" mat-header-cell *matHeaderCellDef> <span class="d-flex justify-content-center">ELIMINAR </span> </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="d-flex justify-content-center"><!--(click)="postEliminarEventoMedico(element.id_evento_medico_pk)"-->
                                    <button class="d-flex justify-content-center" type="button" mat-mini-fab color="warn" >
                                        <mat-icon class="material-icons">
                                            delete
                                        </mat-icon>
                                    </button>
                                </span>
                                
                            </td>
                        </ng-container>

                        <!-- fin contenido -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- fin tabla -->

                
                <div class="mt-5 d-flex justify-content-center">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos']" >
                        Regresar
                    </button>
                </div>

                
            </mat-card-content>
        </mat-card>
    </div>
