<div class="container-fluid">
    <mat-card >
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <app-datos-generales></app-datos-generales>

            
            <div class="row mt-3">
                
                    <div class="col">
                        <div class="row">
                            <div class="col" *ngIf="tieneAccionModificar">
                                <input  type="file"  class="form-control" (change)="postRegistrarDocCartilla($event)">
                            </div>
                            <div class="col">
                                <a  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{data?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                                    Cargar cartilla PDF
                                    <mat-icon class="material-icons">
                                        file_open
                                    </mat-icon>
                                </a>
                            </div>
                        </div>
                        
                        <!---->
                        
                        
                    </div>
                    <div class="col">
                        <a  color="accent" class="flat" mat-flat-button  href="{{laravelapi}}/reporteVacunasBeneficiariaPDF?numero_beneficiaria={{id}}&fecha_temporal={{getFI()}}" target="_blank" rel=noopener>
                            <mat-icon>list_alt</mat-icon>&nbsp;Registro en PDF
                        </a>
                    </div>
                
               
                    
                
            </div>



            <form novalidate [formGroup]="form" autocomplete="off" *ngIf="tieneAccionRegistrar">
                <div class="row mt-3">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Vacuna </mat-label>
                            <input type="text" matInput  formControlName="vacuna">
                            <mat-error *ngIf="form.controls['vacuna'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Fecha de aplicación </mat-label>
                            <input type="date" matInput  formControlName="fecha_aplicacion">
                            <mat-error *ngIf="form.controls['fecha_aplicacion'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Edad de aplicación </mat-label>
                            <input type="text" matInput  formControlName="edad_aplicacion">
                            <mat-error *ngIf="form.controls['edad_aplicacion'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Comentarios </mat-label>
                            <textarea rows="3" type="text" matInput  formControlName="comentarios"></textarea>
                            <mat-error *ngIf="form.controls['comentarios'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <button  type="button" color="primary"  mat-mini-fab (click)="postRegistrarVacuna()" >
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </form>

            <div class="row mt-5">
                <div class="row">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Buscador de vacuna </mat-label>
                        <input type="text" matInput  [(ngModel)]="term" (ngModelChange)="filterData()">
                    </mat-form-field>
                </div>

                
                <div class="row mt-3" *ngFor="let a of filteredData; let i = index">
                    <!--<div class="row mt-3" *ngFor="let a of data?.vacunas; let i = index">-->
                <div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Vacuna </mat-label>
                        <input type="text" matInput  [(ngModel)]="a.vacuna">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Fecha de aplicación </mat-label>
                        <input type="date" matInput  [(ngModel)]="a.fecha_aplicacion">
                    </mat-form-field>
                </div>
                <!--<div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Edad de aplicación </mat-label>
                        <input type="text" matInput  [(ngModel)]="a.edad_aplicacion">
                    </mat-form-field>
                </div>-->
                <div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Edad de aplicación </mat-label>
                        <input type="text" matInput  [value]="calcular(f_c, a.fecha_aplicacion, i)">
                    </mat-form-field>
                </div>
                
                <div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Comentarios </mat-label>
                        <textarea rows="3" type="text" matInput  [(ngModel)]="a.comentarios"></textarea>
                    </mat-form-field>
                </div>
                
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <div class="d-flex justify-content-start" >
                        <button *ngIf="tieneAccionEliminar" type="button" class="btn btn-small btn-danger" (click)="postEliminarVacuna(a.id_vacuna_pk)">x</button>
                        &nbsp;
                        <button *ngIf="tieneAccionModificar" class="flat" color="primary" mat-flat-button type="button" (click)="postModificarVacuna(i)">Guardar</button>
                    </div>
                </div>
            <!--</div>-->


                </div>
            </div>



            


            <div class="mt-5 d-flex justify-content-center gap-2">
                <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos']" >
                    Regresar
                </button>
            </div>


        </mat-card-content>
    </mat-card>
</div>

<!--
<div class="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
    <input type="file"  class="form-control" (change)="postRegistrarDocumentoFinalesSistematizacion($event, 3, a.nombre_doc, a.id_doc_sis_pk)" style="display: none;" id="tipo3{{a.id_doc_sis_pk}}">
    <button *ngIf="(form3.value.estatus == 5 || form3.value.estatus == 1) && a.nombre_doc != ''" type="button" class="btn btn-small btn-primary" (click)="clickChangeFile2(a.id_doc_sis_pk, 'tipo3')">
        <span class="material-icons text-white">
            file_present
        </span>
        Modificar
    </button>
</div>
-->