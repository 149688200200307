<div class="container-fluid">
	<mat-card >
		<mat-card-content>
			<div *ngIf="!id_dp; else elseDP" class="alert alert-warning" role="alert">
				Rellene primero el formulario de "Descripción de puesto".
			</div>
			<ng-template #elseDP>
				<div class="spinner" *ngIf="isLoading">
					<mat-spinner color="accent"></mat-spinner>
				</div>


				<form novalidate [formGroup]="form" autocomplete="off">
					<!-- parte 6 -->
					<br>
					<br>
					<span><strong>VI. REQUISITOS BÁSICOS</strong></span>
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
							
							<mat-form-field appearance="fill" class="input-full-w">
								<mat-label>GÉNERO</mat-label>
								<mat-select formControlName="genero">
									<mat-option *ngFor="let g of genero" [value]="g.id_genero_pk">{{g.genero}}</mat-option>
								</mat-select>
								<mat-error *ngIf="form.controls['genero'].hasError('required')">
									El campo es obligatorio
								</mat-error>
							</mat-form-field>
							
						</div>
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
							
							<mat-form-field appearance="fill" class="input-full-w">
								<mat-label>ESTADO CIVIL</mat-label>
								<mat-select formControlName="estado_civil">
									<mat-option *ngFor="let g of estadoCivil" [value]="g.id_estado_civil_pk">{{g.estado_civil}}</mat-option>
								</mat-select>
							</mat-form-field>
							
						</div>
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
							<mat-form-field class="input-full-w" appearance="fill">
								<mat-label>EDAD MÍNIMA</mat-label>
								<input type="number" #edad_minima maxlength="3"  matInput formControlName="edad_minima">
								<mat-error *ngIf="form.controls['edad_minima'].hasError('maxlength')">
									El tamaño máximo es 3 de caracteres
								</mat-error>
								<mat-error *ngIf="form.controls['edad_minima'].hasError('required')">
									El formato es inválido
								</mat-error>
								<mat-error *ngIf="form.controls['edad_minima'].hasError('required')">
									El campo es obligatorio
								</mat-error>
								<mat-hint align="end">{{edad_minima.value.length}} / 3</mat-hint>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<mat-form-field appearance="fill" class="input-full-w">
							<mat-label>ESCOLARIDAD</mat-label>
							<mat-select formControlName="escolaridad">
								<mat-option *ngFor="let g of escolaridad" [value]="g.id_escolaridad_pk">{{g.escolaridad}}</mat-option>
							</mat-select>
							<mat-error *ngIf="form.controls['escolaridad'].hasError('required')">
								El campo es obligatorio
							</mat-error>
						</mat-form-field>
					</div>
					<div class="row">
						<div class="col">
							
							<mat-form-field appearance="fill" class="input-full-w">
								<mat-label>MODALIDAD</mat-label>
								<mat-select formControlName="modalidad">
									<mat-option *ngFor="let g of modalidad" [value]="g.id_modalidad_pk">{{g.modalidad}}</mat-option>
								</mat-select>
								<mat-error *ngIf="form.controls['modalidad'].hasError('required')">
									El campo es obligatorio
								</mat-error>
							</mat-form-field>
							
						</div>
						<div class="col" *ngIf="form.value.modalidad == 3">
							<mat-form-field class="input-full-w" appearance="fill">
								<mat-label>DESCRIBA HIBRIDO</mat-label>
								<input type="text" #describa_hibrido maxlength="150" matInput rows="3" formControlName="describa_hibrido">
								<mat-error *ngIf="form.controls['describa_hibrido'].hasError('maxlength')">
									El tamaño máximo es 150 de caracteres
								</mat-error>
								<mat-hint align="end">{{describa_hibrido.value.length}} / 150</mat-hint>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<mat-form-field class="input-full-w" appearance="fill">
								<mat-label>EXPERIENCIA REQUERIDA</mat-label>
								<textarea type="text" #exp_requerida maxlength="1000" matInput rows="3" formControlName="experiencia"></textarea>
								<mat-error *ngIf="form.controls['experiencia'].hasError('maxlength')">
									El tamaño máximo es 1000 de caracteres
								</mat-error>
								<mat-hint align="end">{{exp_requerida.value.length}} / 1000</mat-hint>
							</mat-form-field>
						</div>
						<div class="col">
							<mat-form-field class="input-full-w" appearance="fill">
								<mat-label>TIEMPO DE EXPERIENCIA REQUERIDA</mat-label>
								<textarea type="text" #tiempo_en maxlength="20" matInput rows="3" formControlName="tiempo_en"></textarea>
								<mat-error *ngIf="form.controls['tiempo_en'].hasError('maxlength')">
									El tamaño máximo es 20 de caracteres
								</mat-error>
								<mat-hint align="end">{{tiempo_en.value.length}} / 20</mat-hint>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<mat-form-field class="input-full-w" appearance="fill">
							<mat-label>EXPERIENCIA DESEADA</mat-label>
							<textarea type="text" #exp_deseada maxlength="2000" matInput rows="3" formControlName="experiencia_deseada"></textarea>
							<mat-error *ngIf="form.controls['experiencia_deseada'].hasError('maxlength')">
								El tamaño máximo es 2000 de caracteres
							</mat-error>
							<mat-hint align="end">{{exp_deseada.value.length}} / 2000</mat-hint>
						</mat-form-field>
					</div>
					<div class="row">
						<mat-form-field class="input-full-w" appearance="fill">
							<mat-label>OTROS REQUISITOS</mat-label>
							<textarea type="text" #otros_req maxlength="2000" matInput rows="3" formControlName="otros_requisitos"></textarea>
							<mat-error *ngIf="form.controls['otros_requisitos'].hasError('maxlength')">
								El tamaño máximo es 2000 de caracteres
							</mat-error>
							<mat-hint align="end">{{otros_req.value.length}} / 2000</mat-hint>
						</mat-form-field>
					</div>
					<div class="row">
						<mat-form-field class="input-full-w" appearance="fill">
							<mat-label>NO REQUIERE</mat-label>
							<textarea type="text" #no_red maxlength="2000" matInput rows="3" formControlName="no_requiere"></textarea>
							<mat-error *ngIf="form.controls['no_requiere'].hasError('maxlength')">
								El tamaño máximo es 2000 de caracteres
							</mat-error>
							<mat-hint align="end">{{no_red.value.length}} / 2000</mat-hint>
						</mat-form-field>
					</div>
					<div class="row">
						<div class="col">
							<mat-form-field class="input-full-w" appearance="fill">
								<mat-label>CONOCIMIENTOS DESEADOS (saber)</mat-label>
								<textarea type="text" #con_deseados maxlength="2000" matInput rows="3" formControlName="conocimientos_deseada"></textarea>
								<mat-error *ngIf="form.controls['conocimientos_deseada'].hasError('maxlength')">
									El tamaño máximo es 2000 de caracteres
								</mat-error>
								<mat-hint align="end">{{con_deseados.value.length}} / 2000</mat-hint>
							</mat-form-field>
						</div>
						<div class="col">
							<mat-form-field class="input-full-w" appearance="fill">
								<mat-label>HABILIDADES DESEADAS (saber hacer)</mat-label>
								<textarea type="text" #hab_deseadas maxlength="2000" matInput rows="3" formControlName="habilidad_deseada"></textarea>
								<mat-error *ngIf="form.controls['habilidad_deseada'].hasError('maxlength')">
									El tamaño máximo es 2000 de caracteres
								</mat-error>
								<mat-hint align="end">{{hab_deseadas.value.length}} / 2000</mat-hint>
							</mat-form-field>
						</div>
						<div class="col">
							<mat-form-field class="input-full-w" appearance="fill">
								<mat-label>ACTITUDES DESEADAS (ser)</mat-label>
								<textarea type="text" #act_deseadas maxlength="2000" matInput rows="3" formControlName="actitudes"></textarea>
								<mat-error *ngIf="form.controls['actitudes'].hasError('maxlength')">
									El tamaño máximo es 2000 de caracteres
								</mat-error>
								<mat-hint align="end">{{act_deseadas.value.length}} / 2000</mat-hint>
							</mat-form-field>
						</div>
					</div>
					<br>
					<br>
					<span><strong>VII. CONTROL Y MANEJO DE RECURSOS MATERIALES Y FINANCIEROS</strong></span>
					<div class="row">
						<div class="col">
							<span>¿Maneja recursos financieros?</span>
						</div>
						<div class="col-xs-1 col-sm-1 col-md-1 col-xl-1 col-xxl-1">
							<mat-checkbox formControlName="maneja_financiero"></mat-checkbox>
						</div>
						<div class="col">
							<mat-form-field class="input-full-w" appearance="fill" *ngIf="form.value.maneja_financiero">
								<mat-label>ESPECIFIQUE LOS RECURSOS FINANCIEROS</mat-label>
								<textarea type="text" #act_deseadas maxlength="500" matInput rows="3" formControlName="especificifique_finan"></textarea>
								<mat-error *ngIf="form.controls['especificifique_finan'].hasError('maxlength')">
									El tamaño máximo es 500 de caracteres
								</mat-error>
								<mat-hint align="end">{{act_deseadas.value.length}} / 500</mat-hint>
							</mat-form-field>
						</div>
						
					</div>
					<div class="row">
						<div class="col">
							<span>¿Maneja y controla información confidencial?</span>
						</div>
						<div class="col-xs-1 col-sm-1 col-md-1 col-xl-1 col-xxl-1">
							<mat-checkbox formControlName="maneja_confidencial"></mat-checkbox>
						</div>
						<div class="col">
							<mat-form-field class="input-full-w" appearance="fill" *ngIf="form.value.maneja_confidencial">
								<mat-label>ESPECIFIQUE INFORMACIÓN CONFIDENCIAL</mat-label>
								<textarea type="text" #act_deseadas maxlength="500" matInput rows="3" formControlName="especifique_confiden"></textarea>
								<mat-error *ngIf="form.controls['especifique_confiden'].hasError('maxlength')">
									El tamaño máximo es 500 de caracteres
								</mat-error>
								<mat-hint align="end">{{act_deseadas.value.length}} / 500</mat-hint>
							</mat-form-field>
						</div>
					</div>
					<br>
					<br>
					<span><strong>VIII. INDUCCIÓN</strong></span>
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-justificado mb-3">
							<span>Seleccione el tipo de inducción que el puesto requiere para adaptarse a las actividades de la organización y características del puesto.</span>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
							<mat-radio-group formControlName="induccion">
								<div class="row mt-1 text-justificado" *ngFor="let g of induccion">
									<p> <mat-radio-button [value]="g.id_induccion_pk" ></mat-radio-button>
									{{g.induccion}} </p>
								</div>
								<mat-error *ngIf="form.controls['induccion'].hasError('required')">
									El campo es obligatorio
								</mat-error>
							</mat-radio-group>
						</div>
					</div>
					<br>
					<br>
					<span><strong>IX. NIVEL DE SUPERVISIÓN</strong></span>
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-justificado mb-3">
							<span>a) Supervisión Ejercida. Naturaleza del nivel de supervisión que debe ejercer el puesto, para el desarrollo de sus funciones.</span>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
							<mat-radio-group formControlName="supervicion_ejercida">
								<div class="row mt-1 text-justificado" *ngFor="let g of supervicionEjercida">
									<p> <mat-radio-button [value]="g.id_super_ejercida_pk" ></mat-radio-button>
									{{g.supervicion_ejercida}} </p>
								</div>
								<mat-error *ngIf="form.controls['supervicion_ejercida'].hasError('required')">
									El campo es obligatorio
								</mat-error>
							</mat-radio-group>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-justificado mb-3">
							<span>b) Supervisión Recibida. Anote la descripción que más se apegue a la naturaleza del nivel de supervisión ejercida del puesto, para el desarrollo
								de sus funciones (elegir las opciones que apliquen).
								</span>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
							<mat-radio-group formControlName="supervicion_recibida">
								<div class="row mt-1 text-justificado" *ngFor="let g of supervicionRecibida">
									<p> <mat-radio-button [value]="g.id_supervicion_recibida_pk" ></mat-radio-button>
									{{g.supervicion_recibida}} </p>
								</div>
								<mat-error *ngIf="form.controls['supervicion_recibida'].hasError('required')">
									El campo es obligatorio
								</mat-error>
							</mat-radio-group>
						</div>
					</div>
					<br>
					<br>
					<span><strong>X. ANÁLISIS DE PROBLEMAS</strong></span>
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-justificado mb-3">
							<span>Complejidad normal de los problemas a que se enfrenta en el desempeño de sus funciones.</span>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
							<mat-radio-group formControlName="analisis_problemas">
								<div class="row mt-1 text-justificado" *ngFor="let g of analisisProblmas">
									<p> <mat-radio-button [value]="g.id_ana_problemas_pk" ></mat-radio-button>
									{{g.analisis_problemas}} </p>
								</div>
								<mat-error *ngIf="form.controls['analisis_problemas'].hasError('required')">
									El campo es obligatorio
								</mat-error>
							</mat-radio-group>
						</div>
					</div>
					<br>
					<br>
					<span><strong>XI. TOMA DE DECISIONES</strong></span>
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-justificado mb-3">
							<span>Tipo de decisiones que normalmente se toman en el puesto que ocupa.</span>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
							<mat-radio-group formControlName="toma_desicion">
								<div class="row mt-1 text-justificado" *ngFor="let g of tomaDesiciones">
									<p> <mat-radio-button [value]="g.id_toma_desiciones_pk" ></mat-radio-button>
									{{g.toma_desiciones}} </p>
								</div>
								<mat-error *ngIf="form.controls['toma_desicion'].hasError('required')">
									El campo es obligatorio
								</mat-error>
							</mat-radio-group>
						</div>
					</div>
					<br>
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-justificado mb-3">
							<span><strong>Autonomía en la toma de decisiones: </strong> Refiere a la medida en que el ocupante del puesto debe seguir procedimientos preestablecidos o tomar iniciativas para resolver problemas o desarrollar mejoras.</span>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
							<mat-radio-group formControlName="autonomia_desicion">
								<div class="row mt-1 text-justificado" *ngFor="let g of autonomia">
									<p> <mat-radio-button [value]="g.id_autonomia_desiciones_pk" ></mat-radio-button>
									{{g.autonomia_desiciones}} </p>
								</div>
								<mat-error *ngIf="form.controls['autonomia_desicion'].hasError('required')">
									El campo es obligatorio
								</mat-error>
							</mat-radio-group>
						</div>
					</div>
					<br>
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-justificado mb-3">
							<span><strong>Frecuencia en la toma de decisiones </strong></span>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
							<mat-radio-group formControlName="frecuencia_desiciones">
								<div class="row mt-1 text-justificado" *ngFor="let g of desiciones">
									<p> <mat-radio-button [value]="g.id_fre_desicion_pk" ></mat-radio-button>
									{{g.frecuencia_desiciones}} </p>
								</div>
								<mat-error *ngIf="form.controls['frecuencia_desiciones'].hasError('required')">
									El campo es obligatorio
								</mat-error>
							</mat-radio-group>
						</div>
					</div>
					<br>
					<br>
					<span><strong>XII. RELACIONES Y CONTACTOS INTERNOS</strong></span>
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-justificado mb-3">
							<span>Seleccione el tipo de relaciones internas de trabajo a las que tiene acceso el puesto, acorde a sus funciones y responsabilidades, y que se den en forma continua y permamente, manteniendo una relación de trabajo.</span>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
							<mat-radio-group formControlName="relacion_interna">
								<div class="row mt-1 text-justificado" *ngFor="let g of relacionInterna">
									<p> <mat-radio-button [value]="g.id_relacion_interna_pk" ></mat-radio-button>
									{{g.relacion_interna}} </p>
								</div>
								<mat-error *ngIf="form.controls['relacion_interna'].hasError('required')">
									El campo es obligatorio
								</mat-error>
							</mat-radio-group>
						</div>
					</div>
					<br>
					<br>
					<span><strong>XIII. RELACIONES Y CONTACTOS EXTERNOS</strong></span>
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-justificado mb-3">
							<span>Seleccione el tipo de relaciones externas de trabajo que requiere el puesto, acorde a sus funciones y responsabilidades, y que se den en forma continua y permanente, manteniendo una relación de trabajo.</span>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
							<mat-radio-group  formControlName="relacion_externa">
								<div class="row mt-1 text-justificado" *ngFor="let g of relacionExterna">
									<p> <mat-radio-button [value]="g.id_relacion_externas_pk" ></mat-radio-button>
									{{g.relacion_externas}} </p>
								</div>
								<mat-error *ngIf="form.controls['relacion_externa'].hasError('required')">
									El campo es obligatorio
								</mat-error>
							</mat-radio-group>
						</div>
					</div>
					<!-- fin parte 6 -->
				</form>

				<div class="mt-5 d-flex justify-content-center">
					<div *ngIf="form.invalid">
						<span class="text-danger">
							Faltan campos por llenar
						</span>
					</div>
				</div>

				<div class="d-flex justify-content-center gap-2">



					<button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo']" >
						Regresar
					</button>
					<form novalidate [formGroup]="form" (ngSubmit)="guardar()" *ngIf="accion == 'modificar' && !perfil_puesto">
						<button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
							Guardar
						</button>
					</form>
					<form novalidate [formGroup]="form" (ngSubmit)="modificar(1)" *ngIf="accion == 'modificar' && perfil_puesto">
						<button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
							Guardar
						</button>
					</form>
					<form novalidate [formGroup]="form" (ngSubmit)="modificar(7)" *ngIf="accion == 'modificar' && perfil_puesto && descripcion_puesto?.id_estatus_general_fk == 1">
						<button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
							Publicar
						</button>
					</form>
					<form novalidate [formGroup]="form" *ngIf="accion == 'modificar'">
						<button class="flat" color="primary" mat-flat-button type="button" (click)="siguiente()">
							Anterior
						</button>
					</form>



				</div>

			</ng-template>

		</mat-card-content>
	</mat-card>
</div>