<div [ngClass]="{ 'card-container': ficha == false }">
    <div class="table-container">
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner color="accent"></mat-spinner>
        </div>
        <mat-card class="example-card">
            <mat-card-header *ngIf="!ficha">
                
                <mat-card-title>
                    <ng-container *ngIf="!esFichaTecnica">
                        Expediente de beneficiaria
                    </ng-container>
                    <ng-container *ngIf="esFichaTecnica">
                        Ficha técnica
                    </ng-container>
                </mat-card-title>
                <mat-card-subtitle>
                    <ng-container *ngIf="esFichaTecnica">
                        Información general de la beneficiaria
                    </ng-container>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="bt col-md-12" *ngIf="selected.value>0 || esFichaTecnica">
                    <div class="bt col-md-2 image no-padding" *ngIf="!beneficiaria?.foto"></div>
                    <div class="bt col-md-2 image no-padding" *ngIf="beneficiaria?.foto" style="background-image: url({{hostapi}}/{{beneficiaria?.foto}});"></div>
                    <div class="bt col-md-10">
                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Nombre completo:
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.nombreCompleto}}
                            </div>
                        </div>
                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                CURP:
                            </div>
                            <div class="bt col-md-6 content">
                                {{(beneficiaria?.idCurp ? beneficiaria?.idCurp : ' - ') | uppercase }}
                            </div>
                        </div>
                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Fecha de nacimiento:
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.fechaNacimiento}}
                            </div>
                        </div>
                        
                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Edad Actual:
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.edadActual}}
                            </div>
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Canalizada por
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.canalizadaPor}}
                            </div>
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Motivo de ingreso
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.motivoDeIngreso}}
                            </div>
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Fecha de ingreso
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.fechaIngreso}}
                            </div>
                        </div>
                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Edad al ingreso
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.edadIngreso}}
                            </div>
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Tipo de discapacidad
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.tipoDeDiscapacidad == '-' ? beneficiaria?.idDiscapacidad : infoBeneficiaria?.tipoDeDiscapacidad}}
                            </div>
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Tiempo de estancia
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.tiempoDeEstancia}}
                            </div>
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Diagnóstico DN
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.diagnosticoDN}}
                            </div>
                        </div>
                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Diagnóstico DM
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.diagnosticoDM}}
                            </div>
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Condición relevante
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.condicionRelevante}}
                            </div>
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Tipo de sangre
                            </div>
                            <div class="bt col-md-6 content">
                                {{infoBeneficiaria?.tipoDeSangre}}
                            </div>
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Estatus
                            </div>
                            <div class="bt col-md-6 content">
                                {{beneficiaria?.estatus}}
                            </div>
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Etapa
                            </div>
                            <div class="bt col-md-6 content">
                                {{beneficiaria?.etapa}}
                            </div>
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Fecha de egreso
                            </div>
                            <div class="bt col-md-6 content" *ngIf="beneficiaria?.fechaEgreso; else noFechaEgreso">
                                {{beneficiaria?.fechaEgreso | date: 'dd/MM/yyyy'}}
                            </div>
                            <ng-template #noFechaEgreso>
                                <div class="bt col-md-6 content" >
                                    -
                                </div>
                            </ng-template>
                            
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Motivo de egreso
                            </div>
                            <div class="bt col-md-6 content">
                                {{beneficiaria?.motivoEgreso ? beneficiaria?.motivoEgreso:"-"}}
                            </div>
                        </div>

                        <div class="bt col-md-6">
                            <div class="bt col-md-6 label">
                                Lugar de destino
                            </div>
                            <div class="bt col-md-6 content">
                                {{beneficiaria?.lugarDestino ? beneficiaria?.lugarDestino:"-"}}
                            </div>
                        </div>

                    </div>
                    <br><br>
                </div>
                
                <mat-tab-group mat-align-tabs="start" (selectedIndexChange)="selected.setValue($event)" *ngIf="!esFichaTecnica">
                    <mat-tab label="Alta de expediente">
                        <app-info-general *ngIf="beneficiaria && selected.value==0" [beneficiaria]="beneficiaria"></app-info-general>
                    </mat-tab>
                    <mat-tab label="Perfil de ingreso" [disabled]="!existente">
                        <app-perfil *ngIf="selected.value==1"></app-perfil>
                    </mat-tab>
                    <mat-tab label="Situación general" [disabled]="!existente">
                        <app-situacion-general *ngIf="selected.value==2"></app-situacion-general>
                    </mat-tab>
                    <mat-tab label="Documentos" [disabled]="!existente">
                        <app-documentos *ngIf="selected.value==3"></app-documentos>
                    </mat-tab>
                    <mat-tab label="Egreso" [disabled]="!existente">
                        <app-egreso *ngIf="selected.value==4"></app-egreso>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
            <!--<mat-card-actions style="text-align: center;" *ngIf="esFichaTecnica">
                <button *ngIf="!ficha" mat-stroked-button [routerLink]="['/beneficiarias']" class="flat">Volver</button>
            </mat-card-actions>-->
        </mat-card>
    </div>
</div>