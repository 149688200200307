<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Gestor de incidencias</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div class="bt col-md-12 text-align-right">
               

                <form [formGroup]="form" autocomplete="off">
                    <div class="row">
                        <div class="col"></div>
                        <div class="col ">
                            <a class="flat" color="accent" mat-flat-button href="{{laravelapi}}/ReporteExcelincidenciasCompleto?numero_empleado={{id_empleado}}" target="_blank" rel=noopener>
                                <mat-icon>list_alt</mat-icon> Reporte Excel Incidencias
                            </a>
                        </div>
                        <div class="col">
                            <mat-form-field appearance="fill" class="input-full-w">
                                <mat-label>TIPO</mat-label>
                                <mat-select formControlName="tipo_incidente">
                                    <mat-option *ngFor="let a of catalogo?.incidencias" [value]="a.id_tipo_catalogo_pk">{{a?.incidente}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <button class="flat" color="primary" mat-flat-button (click)="postRegistrarPreIncidentes(1)" >
                                Registrar nueva incidencia
                            </button>
                        </div>
                     
                    </div>
               
            </form>


                
                <!--<button class="flat" color="primary" mat-flat-button>Comparativo</button>
                    <button class="flat" color="primary" mat-flat-button>Gráfica (Pizza)</button>-->
                </div>

                <!-- tabla -->
                <div class="row mt-5 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="_id">
                            <th id="_id" mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let element">{{element?.id_incidente_pk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_puestos">
                            <th id="_puestos" mat-header-cell *matHeaderCellDef> TIPO </th>
                            <td mat-cell *matCellDef="let element">{{element?.tipo_incidente?.incidente}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_fecha">
                            <th id="_fecha" mat-header-cell *matHeaderCellDef> FECHA </th>
                            <td mat-cell *matCellDef="let element">{{element?.created_at?.split('T')[0] | date:'dd-MM-yyyy'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_motivo">
                            <th id="_motivo" mat-header-cell *matHeaderCellDef> MOTIVO </th>
                            <td mat-cell *matCellDef="let element">{{element?.motivo_breve }}</td>
                        </ng-container>

                        <ng-container matColumnDef="_modificar">
                            <th id="_modificar" mat-header-cell *matHeaderCellDef> MODIFICAR </th>
                            <td mat-cell *matCellDef="let element">
                                <button type="button" mat-mini-fab color="primary"  >
                                    <mat-icon class="material-icons" [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-incidencias/modificar']" [queryParams]="{id_incidencia:element.id_incidente_pk, id_numero_empleado: id_empleado, id_exp: id_empleado}">
                                        open_in_new
                                    </mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_consultar">
                            <th id="_consultar" mat-header-cell *matHeaderCellDef> CONSULTAR </th>
                            <td mat-cell *matCellDef="let element">
                                <button type="button" mat-mini-fab color="primary"  >
                                    <mat-icon class="material-icons" [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-incidencias/consultar']" [queryParams]="{id_incidencia:element.id_incidente_pk, id_numero_empleado: id_empleado, id_exp: id_empleado}">
                                        open_in_new
                                    </mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_eliminar">
                            <th id="_eliminar" mat-header-cell *matHeaderCellDef> ELIMINAR </th>
                            <td mat-cell *matCellDef="let element">
                                <button type="button" mat-mini-fab color="warn" (click)="postEliminarPuesto(element.id_puesto_reporte_pk)">
                                    <mat-icon class="material-icons">
                                        delete
                                    </mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <!-- fin contenido -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- fin tabla -->
                <div class="mt-5 d-flex justify-content-center">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-expedientes-de-personal']" >
                        Regresar
                    </button>
                </div>

                
            </mat-card-content>
            <mat-card-actions align="start">
                <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"  aria-label="Selecione una pagina">
                </mat-paginator>
            </mat-card-actions>


        </mat-card>
    </div>
