import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ContactosService {
  endpoint = "";
  phpPath:string;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService) {
      this.phpPath = _config.getConfiguration().embedPhpPath;
      this.endpoint = _config.getConfiguration().hostapi + this._config.getConfiguration().endpoints["documentos"].path;
  }

  altaContacto(json:any,id:number):Observable<any>{
    const formData = new FormData();
    if(id){
      return this._http.post<any>(this.phpPath+"/directorio-service.php?opc=modifica&id="+id,json);
    }else{
      return this._http.post<any>(this.phpPath+"/directorio-service.php?opc=alta",json);
    }
    
  }
  buscaContactos(buscador:string,page:number,size:number,id:string){
    if(id){
      return this._http.get<ResponseGenerico>(this.phpPath+"/directorio-service.php?opc=consulta&id="+id);
    }else{
      return this._http.get<ResponseGenerico>(this.phpPath+"/directorio-service.php?opc=consulta&buscador="+buscador+"&page="+page+"&size="+size);
    }
    
  }
  deleteByid(id){
    return this._http.delete<ResponseGenerico>(this.phpPath+"/directorio-service.php?opc=baja&id="+id);
  }
}
