import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';
import { Component, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { LightboxComponent } from 'src/app/modals/lightbox/lightbox.component';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EventosDeVidaService } from 'src/app/services/eventos-de-vida.service';
import { ParametrosService } from 'src/app/services/parametros.service';
export interface Terceros {
  name: string;
}
@Component({
  selector: 'app-nuevo-evento',
  templateUrl: './nuevo-evento.component.html',
  styleUrls: ['./nuevo-evento.component.scss']
})
export class NuevoEventoComponent implements OnInit {
  hostapi:string;
  beneficiarias = new UntypedFormControl();
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  isLoading = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  evento = {};
  formulario : UntypedFormGroup;
  notas: Terceros[] = [
    
  ];
  id:number=0;
  beneficiariasList: string[] = [];
  file2Upload1 : File = null;
  file2Upload2 : File = null;

  foto2Upload1 : File = null;
  foto2Upload2 : File = null;
  foto2Upload3 : File = null;
  foto2Upload4 : File = null;
  foto2Upload5 : File = null;
  documento1: string=null;
  documento2: string=null;
  laFoto1: string = null;
  laFoto2: string = null;
  laFoto3: string = null;
  laFoto4: string = null;
  laFoto5: string = null;
  clasificaciones = [];

  constructor(
    private _conf:ConfigurationService,
    private _location:Location,
    private dialog:MatDialog,
    private _route:Router,
    private activatedRoute:ActivatedRoute,
    private _eventosDeVidaService:EventosDeVidaService,
    private _beneficiariasService:BeneficiariaService,
    private patametrosService:ParametrosService) { 
      this.hostapi=this._conf.getConfiguration().hostapi;
      this.patametrosService.getParametros("22").subscribe(result=>{
        console.log(result);
        this.clasificaciones = result.data.parametros;
      });
      this._beneficiariasService.obtenerBenefiariasActivas().subscribe(result=>{
        this.beneficiariasList = result.data;
      },()=>{
        this.beneficiariasList = [];
      });
      this.formulario = new UntypedFormGroup({
      fechaApertura: new UntypedFormControl("",[Validators.required]),
      nombreEvento : new UntypedFormControl("",[Validators.required]),
      descripcion : new UntypedFormControl("",[Validators.required]),
      beneficiarias:new UntypedFormControl("",[]),
      lugar:new UntypedFormControl("",[]),
      terceros: new UntypedFormControl("",[]),
      foto1:new UntypedFormControl("",[]),
      foto2:new UntypedFormControl("",[]),
      foto3:new UntypedFormControl("",[]),
      foto4:new UntypedFormControl("",[]),
      foto5:new UntypedFormControl("",[]),
      archivo1:new UntypedFormControl("",[]),
      archivo2:new UntypedFormControl("",[]),
      clasificacion: new UntypedFormControl("",[Validators.required])
    });
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      
      if(params['id']){
        this.id = params['id'];
        this.consultarEvento(params['id'])
      }
    });
  }
  consultarEvento(id:number){
    this.isLoading=true;
    this._eventosDeVidaService.findById(id).subscribe(result=>{console.log(result);
      this.isLoading=false;
      if(result.codigo = "EVE000"){
        this.evento = result.data;
        this.setData(result.data);
      }
    },()=>{
      this.isLoading=false;
    });
  }
  setData(data:any){
    this.formulario.controls.fechaApertura.setValue(new Date(data.evento[0].fechaEvento.date));
    this.formulario.controls.nombreEvento.setValue(data.evento[0].nombre);
    this.formulario.controls.descripcion.setValue(data.evento[0].descripcion);
    this.formulario.controls.clasificacion.setValue(data.evento[0].clasificacion);
    this.formulario.controls.lugar.setValue(data.evento[0].lugar);
    for(let i=0;i<data.terceros.length;i++){
      this.notas.push({name:data.terceros[i].nombre});
    }
    this.documento1 = data.evento[0].documento1;
    this.documento2 = data.evento[0].documento2;
    this.laFoto1 = data.evento[0].foto1;
    this.laFoto2 = data.evento[0].foto2;
    this.laFoto3 = data.evento[0].foto3;
    this.laFoto4 = data.evento[0].foto4;
    this.laFoto5 = data.evento[0].foto5;

    let beneficiarias=[];
    for(let i=0; i<data.beneficiarias.length;i++){
      beneficiarias.push(data.beneficiarias[i].idBeneficiaria.toString());
    }

    this.formulario.controls.beneficiarias.setValue(beneficiarias);
  }

  public insertIdBeneficiariaFormData(beneficiarias, formData){
    for(let i=0; i<beneficiarias.length;i++){
      if(i==0){
        formData.append('idBeneficiaria', beneficiarias[i]);
      }else{
        formData.append('idBeneficiaria'+i, beneficiarias[i]);
      }
    }
  }

  public insertTercerosFomData(terceros, formData){
    for(let i=0; i<terceros.length;i++){
      if(i==0){
        formData.append('tercero', terceros[i].name);
      }else{
        formData.append('tercero'+i, terceros[i].name);
      }
    }
  }
  sendForm(){
    
    if(this.formulario.valid){
      let formData = new FormData();
      formData.append('nombre', this.formulario.controls.nombreEvento.value);
      formData.append('fechaApertura', this.formulario.controls.fechaApertura.value.toJSON().split("T")[0]);
      formData.append('descripcion', this.formulario.controls.descripcion.value);
      formData.append('clasificacion',this.formulario.controls.clasificacion.value);
      formData.append('lugar',this.formulario.controls.lugar.value);
      formData.append('documento1', this.file2Upload1);
      formData.append('documento2', this.file2Upload2);

      formData.append('foto1', this.foto2Upload1);
      formData.append('foto2', this.foto2Upload2);
      formData.append('foto3', this.foto2Upload3);
      formData.append('foto4', this.foto2Upload4);
      formData.append('foto5', this.foto2Upload5);
      let beneficiarias = this.formulario.controls.beneficiarias.value;
      formData.append('contadorBeneficiaria', beneficiarias.length);

      this.insertIdBeneficiariaFormData(beneficiarias, formData);

      // for(let i=0; i<beneficiarias.length;i++){
      //   if(i==0){
      //     formData.append('idBeneficiaria', beneficiarias[i]);
      //   }else{
      //     formData.append('idBeneficiaria'+i, beneficiarias[i]);
      //   }
      // }

      let terceros:any = this.notas;
      formData.append('contadorTercero', terceros.length);

      this.insertTercerosFomData(terceros, formData);
      // for(let i=0; i<terceros.length;i++){
      //   if(i==0){
      //     formData.append('tercero', terceros[i].name);
      //   }else{
      //     formData.append('tercero'+i, terceros[i].name);
      //   }
      // }
      this.isLoading=true;
      
      this._eventosDeVidaService.save(formData,this.id).subscribe(result=>{
        this.isLoading = false;
        if(result.codigo=='EVE000'){
          this.mensaje("Evento guardado","La información del evento fue guardada");
        }else{
          this.mensaje("Problemas para guardar","Lo sentimo, tuvimos un problema para guardar tu evento, verifica la información e intenta más tarde.");
        }
      },()=>{
        this.isLoading=false;
      });
    }
  }
  handleFileInput1(files: FileList) {
    this.file2Upload1 = files.item(0);
  }
  handleFileInput2(files: FileList) {
    this.file2Upload2 = files.item(0);
  }

  handleFotoInput1(files: FileList) {
    this.foto2Upload1 = files.item(0);
  }
  handleFotoInput2(files: FileList) {
    this.foto2Upload2 = files.item(0);
  }
  handleFotoInput3(files: FileList) {
    this.foto2Upload3 = files.item(0);
  }
  handleFotoInput4(files: FileList) {
    this.foto2Upload4 = files.item(0);
  }
  handleFotoInput5(files: FileList) {
    this.foto2Upload5 = files.item(0);
  }
  selectedData:any;
  selectedValue(event: MatSelectChange) {
    console.info(event);
    this.selectedData = {
      value: event.value,
      text: event.source.triggerValue
    };
    //console.log(this.selectedData);
  }
  getOne(cadena){
    return cadena.split(",")[0];
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.notas.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: Terceros): void {
    const index = this.notas.indexOf(fruit);

    if (index >= 0) {
      this.notas.splice(index, 1);
    }
  }

  mensaje(titulo, mensaje){
    const dialogRef = this.dialog.open(MessageBoxComponent,{
      data:{titulo: titulo ,mensaje: mensaje}
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.cancelar();
    })
  }

  cancelar(){
    this._location.back();
  }
  
  @ViewChild('allSelected') private allSelected: MatOption;
  tosslePerOne(all){ 
    if (this.allSelected.selected) {  
     this.allSelected.deselect();
     return false;
 }
   if(this.formulario.controls.beneficiarias.value.length==this.beneficiariasList.length)
     this.allSelected.select();
 
 }
   toggleAllSelection() {
     if (this.allSelected.selected) {
       this.formulario.controls.beneficiarias
         .patchValue([...this.beneficiariasList.map(item => item["id"]), 0]);
     } else {
       this.formulario.controls.beneficiarias.patchValue([]);
     }
   }

   openImage(src){
    this.dialog.open(LightboxComponent,{
      data:{src: src}
    });

   }

   esBeneficiaria(){
    return this._route.url.indexOf("/beneficiarias/")>-1;      
   }
}
