//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { CatalogoInventarioService } from 'src/app/services/catalogo-inventario.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//Fin Tabla

@Component({
  selector: 'app-catalogo-inventario',
  templateUrl: './catalogo-inventario.component.html',
  styleUrls: ['./catalogo-inventario.component.scss'],
  providers: [ CatalogoInventarioService ]
})
export class CatalogoInventarioComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    buscador: new UntypedFormControl('', []),
    ruta: new UntypedFormControl('', []),
  });
  /*tabla*/
  public ccaaii_length: number = 100;;
  public ccaaii_pageSize: number = 25;
  public ccaaii_pageSizeOptions: Array<number> = [5, 10, 25, 100];
  public ccaaii_page: number = 1;
  public ccaaii_displayedColumns: any =[
    'ccaaii_id', 
    'ccaaii_name',
    'ccaaii_consultar',
    'ccaaii_modificar',
    'ccaaii_eliminar'];
  public dataSource: any;
  /*fin tabla*/
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public laravelapi: any;
  public laravelapistorage: any;
  public acciones: any;

  public opcion_catalogo: number = 1;
  public opcion_texto: string = 'Presentaciones';
  //public gai_registrar: boolean = false;
  //public gai_consultar: boolean = false;
  //public gai_modificar: boolean = false;
  //public gai_eliminar: boolean = false;
  public t: any;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _catalogoINV: CatalogoInventarioService,
    private _router: Router,
    private _route: ActivatedRoute,
  ){ 


  }

  ngOnInit(): void {
    this.getConsultarPresentaciones(1);
  }

  public set_opcion_catalogo(caso: number){
    if(caso == 1){
      this.opcion_texto = 'Presentaciones';
    }else if(caso == 2){
      this.opcion_texto = 'Concentración';
    }else if(caso == 3){
      this.opcion_texto = 'Nombres genéricos';
    }else if(caso == 4){
      this.opcion_texto = 'Nombre comercial';
    }
    this.opcion_catalogo = caso;
    this.buscador();
  }


  public buscador(){
    if(this.opcion_catalogo == 1){
      this.getConsultarPresentaciones(1);
    }else if(this.opcion_catalogo == 2){
     this.getConsultarAnexosUnos(1);
    }else if(this.opcion_catalogo == 3){
      this.getConsultarAnexosDos(1);
    }else if(this.opcion_catalogo == 4){
      this.getConsultarAnexosTres(1);
    }
  }

  /*buscadores*/
  public getConsultarPresentaciones(page: number){
    this.isLoading = true;
    this.ccaaii_page = page;
    this._catalogoINV.getConsultarPresentaciones(this.ccaaii_page, this.ccaaii_pageSize, this.form.value.buscador).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.ccaaii_length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
      });
  }


  public getConsultarAnexosUnos(page: number){
    this.isLoading = true;
    this.ccaaii_page = page;
    this._catalogoINV.getConsultarAnexosUnos(this.ccaaii_page, this.ccaaii_pageSize, this.form.value.buscador).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.ccaaii_length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
      });
  }


  public getConsultarAnexosDos(page: number){
    this.isLoading = true;
    this.ccaaii_page = page;
    this._catalogoINV.getConsultarAnexosDos(this.ccaaii_page, this.ccaaii_pageSize, this.form.value.buscador).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.ccaaii_length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getConsultarAnexosTres(page: number){
    this.isLoading = true;
    this.ccaaii_page = page;
    this._catalogoINV.getConsultarAnexosTres(this.ccaaii_page, this.ccaaii_pageSize, this.form.value.buscador).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.ccaaii_length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
      });
  }


  /*fin buscadores*/



  public pageEvent(event: any){
    console.info(event);
    this.ccaaii_pageSize = event.pageSize;

    if(this.opcion_catalogo == 1){
      
      this.getConsultarPresentaciones(event.pageIndex + 1);
    }else if(this.opcion_catalogo == 2){
      
      this.getConsultarAnexosUnos(event.pageIndex + 1);
    }else if(this.opcion_catalogo == 3){
      
      this.getConsultarAnexosDos(event.pageIndex + 1);
    }else if(this.opcion_catalogo == 4){
     
      this.getConsultarAnexosTres(event.pageIndex + 1);
    }


    //this.getConsultarPresentaciones(event.pageIndex + 1);
  }





  public getEliminarPresentaciones(id: number){
    this.isLoading = true;
    this._catalogoINV.getEliminarPresentaciones(id).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultarPresentaciones(this.ccaaii_page);
        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        }
      },
      (error: any) => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public getEliminarAnexoUno(id: number){
    this.isLoading = true;
    this._catalogoINV.getEliminarAnexoUno(id).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultarAnexosUnos(this.ccaaii_page);
        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        }
      },
      (error: any) => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public getEliminarAnexoDos(id: number){
    this.isLoading = true;
    this._catalogoINV.getEliminarAnexoDos(id).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultarAnexosDos(this.ccaaii_page);
        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        }
      },
      (error: any) => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public getEliminarAnexoTres(id: number){
    this.isLoading = true;
    this._catalogoINV.getEliminarAnexoTres(id).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultarAnexosTres(this.ccaaii_page);
        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        }
      },
      (error: any) => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public eliminar(id: number, caso: number){
    console.log(id, caso);
    if(caso == 1){
      
      this.getEliminarPresentaciones(id);
    }else if(caso == 2){
      this.getEliminarAnexoUno(id);
      //this.getConsultarAnexosUnos(event.pageIndex + 1);
    }else if(caso == 3){
      this.getEliminarAnexoDos(id);
      //this.getConsultarAnexosDos(event.pageIndex + 1);
    }else if(caso == 4){
      this.getEliminarAnexoTres(id);
      //this.getConsultarAnexosTres(event.pageIndex + 1);
    }



  }

 

}
