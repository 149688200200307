<div class="container-fluid">
    <mat-card >
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <!--[formGroup]="form"-->
            <form novalidate [formGroup]="form" autocomplete="off" (click)="clearSeccion(1)">
                
                <div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Lugar de nacimiento </mat-label>
                        <input type="text" maxlength="200" #lugar_nacimiento matInput  formControlName="lugar_nacimiento">
                        <mat-error *ngIf="form.controls['lugar_nacimiento'].hasError('required')">
                            El campo es obligatorio
                        </mat-error>
                        <mat-error *ngIf="form.controls['lugar_nacimiento'].hasError('maxlength')">
                            El maximo tamaño es de 200 caracteres
                        </mat-error>
                        <mat-hint align="end">{{lugar_nacimiento.value.length}} / 250</mat-hint>
                    </mat-form-field>
                </div>
                <br>
                <br>
                <br>
                <br>
                
                <div class="row">
                    <div class="col">
                        <span class="mt-5"><strong>ANTECEDENTES PATOLÓGICOS HEREDO-FAMILIARES</strong></span><br>
                    </div>
                    <div class="col">
                        <div class="d-flex justify-content-start">
                            <mat-radio-group formControlName="ante_pato_fami_edo">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Se conoce </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> Se desconoce </mat-radio-button>
                                <mat-error *ngIf="form.controls['ante_pato_fami_edo'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="row mt-1" *ngIf="form.value.ante_pato_fami_edo == 1">
                    <div class="row">
                        <div class="col">
                            <mat-form-field appearance="fill" class="input-full-w">
                                <mat-label>Seleccione el antecedente patológico</mat-label>
                                <mat-select formControlName="numero_antecedente">
                                    <mat-option *ngFor="let p of antecedentes_patologicos" [value]="p.id_antecedentes_pk">{{p.nombre}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <span>Familiar: </span>
                            <div class="d-flex justify-content-start">
                                
                                <mat-radio-group formControlName="familiar">
                                    
                                    <mat-radio-button [value]="1"> Madre </mat-radio-button>
                                    &nbsp; 
                                    <mat-radio-button [value]="2"> Padre </mat-radio-button>
                                    &nbsp; 
                                    <mat-radio-button [value]="3"> Hermanos </mat-radio-button>
                                    &nbsp; 
                                    <mat-radio-button [value]="4"> Otros </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col">
                            <mat-form-field appearance="fill"  class="input-full-w" *ngIf="form.value.familiar == 4">
                                <mat-label>Especificar familiar </mat-label>
                                <input type="text" matInput  formControlName="especifique">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row"  *ngIf="form.value.ante_pato_fami_edo == 1">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Especifique diagnóstico </mat-label>
                            <input type="text" matInput  formControlName="especifique_diagnistico">
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                        <!--(click)="postRegistroBeneficiarioEspecialista()"-->
                        <button  type="button" color="primary"  mat-mini-fab (click)="postRegistroAntecedentesPatologicos()" *ngIf="accion == 'registrar'">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
            <div class="row"  *ngIf="form.value.ante_pato_fami_edo == 1">
                <div class="row" *ngFor="let l_antecedentes_patologicos of l_antecedentes_patologicos">
                
                    <div class="row">
    
                   
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Seleccione el antecedente patológico</mat-label>
                            <mat-select [(ngModel)]="l_antecedentes_patologicos.id_antecendetes_fk">
                                <mat-option *ngFor="let p of antecedentes_patologicos" [value]="p.id_antecedentes_pk">{{p.nombre}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <span >Familiar: </span>
                        <div class="d-flex justify-content-start">
                            <mat-radio-group [(ngModel)]="l_antecedentes_patologicos.familiar">
                                
                                <mat-radio-button [value]="1"> Madre </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> Padre </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="3"> Hermanos </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="4"> Otros </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col" >
                        <mat-form-field appearance="fill"  class="input-full-w" *ngIf="l_antecedentes_patologicos.familiar == 4">
                            <mat-label>Especificar familiar </mat-label>
                            <input type="text" matInput  [(ngModel)]="l_antecedentes_patologicos.especifique">
                        </mat-form-field>
                    </div>
                    </div>
    
                    <div class="row">
                        <div class="col">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>Especifique diagnóstico</mat-label>
                                <input type="text" matInput  [(ngModel)]="l_antecedentes_patologicos.especifique_diagnistico">
                            </mat-form-field>
                        </div>
                        <div class="col-xs-2 col-sm-2 col-md-2 col-xl-2 col-xxl-2">
                            <div class="d-flex justify-content-start" *ngIf="accion == 'registrar'">
                                <button  (click)="postEliminarAntecedentesPatologicos(l_antecedentes_patologicos.id_ante_patologico_pk, l_antecedentes_patologicos.id_benerificario_fk)" type="button" class="btn btn-small btn-danger">x</button>
                                &nbsp;
                                <button  (click)="postModificarAntecedentesPatologicos(
                                l_antecedentes_patologicos.id_antecendetes_fk,
                                l_antecedentes_patologicos.familiar,
                                l_antecedentes_patologicos.id_benerificario_fk,
                                l_antecedentes_patologicos.especifique,
                                l_antecedentes_patologicos.especifique_diagnistico,
                                l_antecedentes_patologicos.id_ante_patologico_pk
                                
                                )" class="flat" color="primary" mat-flat-button type="button">Guardar</button>
                            </div>
                        </div>
                    </div>
                    
               
             
                    
                
                </div>
            </div>
            



            <br>
            <br>
            <br>
            <br>

            <form novalidate [formGroup]="form" autocomplete="off" (click)="clearSeccion(2)">
                
                <div class="row">
                    <div class="col">
                        <span class="mt-5"><strong>ANTECEDENTES NO PATOLÓGICOS HEREDO-FAMILIARES</strong></span><br>
                    </div>
                    <div class="col">
                        <div class="d-flex justify-content-start">
                            <mat-radio-group formControlName="ante_pato_no_fami_edo">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Se conoce </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> Se desconoce </mat-radio-button>
                                <mat-error *ngIf="form.controls['ante_pato_no_fami_edo'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-radio-group>
                        </div>
                    </div>
                   
                </div>
                <div class="row" *ngIf="form.value.ante_pato_no_fami_edo == 1">
                    <div class="row mt-3" >
                        <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                            
                            <div class="d-flex justify-content-start">
                                <span>Consumo de drogas: </span>
                                <mat-radio-group formControlName="consumes_drogas">
                                    &nbsp; 
                                    &nbsp; 
                                    &nbsp; 
                                    <mat-radio-button [value]="1"> Si </mat-radio-button>
                                    &nbsp; 
                                    <mat-radio-button [value]="2"> No </mat-radio-button>
                                </mat-radio-group>
                                
                            </div>
                        </div>
                        <div class="col" *ngIf="form.value.consumes_drogas == 1">
                            <span>Familiar: </span>
                            <div class="d-flex justify-content-start"  >
                                
                                <mat-radio-group formControlName="padres">
                                    
                                    <mat-radio-button [value]="1"> Madre </mat-radio-button>
                                    &nbsp; 
                                    <mat-radio-button [value]="2"> Padre </mat-radio-button>
                                    &nbsp; 
                                    <mat-radio-button [value]="3"> Hermanos </mat-radio-button>
                                    &nbsp; 
                                    <mat-radio-button [value]="4"> Otros </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col" *ngIf="form.value.consumes_drogas == 1">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>Especifique </mat-label>
                                <input type="text" matInput  formControlName="especifique">
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-1 col-xl-1 col-xxl-1">
                            <!--(click)="postRegistroBeneficiarioEspecialista()"-->
                            <button  type="button" color="primary"  mat-mini-fab (click)="postRegistroAntecedentesNoPatologicos()" *ngIf="accion == 'registrar'">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="form.value.consumes_drogas == 1 && form.value.ante_pato_no_fami_edo == 1">
                    <div class="col" >
                        <mat-form-field appearance="fill"  class="input-full-w"  *ngIf="accion == 'registrar'">
                            <mat-label>Especifique diagnóstico</mat-label>
                            <input type="text" matInput  formControlName="espe_diagnostico">
                        </mat-form-field>
                    </div>
                    
                </div>
            </form>
            <div class="row"  *ngIf="form.value.ante_pato_no_fami_edo == 1">
                <div class="row mt-4" *ngFor="let l_antecedentes_no_patologicos of l_antecedentes_no_patologicos">
                
                    <div class="row">

                   
                        <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                            
                            <div class="d-flex justify-content-start">
                                <span>Consumo de drogas: </span>
                                
                                <mat-radio-group [(ngModel)]="l_antecedentes_no_patologicos.consumes_drogas">
                                    &nbsp; 
                                    &nbsp; 
                                    &nbsp; 
                                    <mat-radio-button [value]="1"> Si </mat-radio-button>
                                    &nbsp; 
                                    <mat-radio-button [value]="2"> No </mat-radio-button>
                                </mat-radio-group>
                                
                            </div>
                        </div>
                        <div class="col" *ngIf="l_antecedentes_no_patologicos.consumes_drogas == 1">
                            <span >Familiar: </span>
                            <div class="d-flex justify-content-start">
                                
                                <mat-radio-group [(ngModel)]="l_antecedentes_no_patologicos.padres">
                                    
                                    <mat-radio-button [value]="1"> Madre </mat-radio-button>
                                    &nbsp; 
                                    <mat-radio-button [value]="2"> Padre </mat-radio-button>
                                    &nbsp; 
                                    <mat-radio-button [value]="3"> Hermanos </mat-radio-button>
                                    &nbsp; 
                                    <mat-radio-button [value]="4"> Otros </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col" *ngIf="l_antecedentes_no_patologicos.consumes_drogas == 1">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>Especifique </mat-label>
                                <input type="text" matInput  [(ngModel)]="l_antecedentes_no_patologicos.especifique">
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-1 col-xl-1 col-xxl-1">
                           
                        </div>
                    </div>

                    <div class="row" *ngIf="l_antecedentes_no_patologicos.consumes_drogas == 1">
                        <div class="col">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>Especifique diagnóstico</mat-label>
                                <input type="text" matInput  [(ngModel)]="l_antecedentes_no_patologicos.espe_diagnostico">
                            </mat-form-field>
                        </div>
                        <div class="col-xs-2 col-sm-2 col-md-2 col-xl-2 col-xxl-2">
                            <div class="d-flex justify-content-start" *ngIf="accion == 'registrar'">
                                <button  (click)="postEliminarAntecedentesNoPatologicos(l_antecedentes_no_patologicos.id_antecendente_no_pk)" type="button" class="btn btn-small btn-danger">x</button>
                                &nbsp;
                                <button  (click)="postModificarAntecedentesNoPatologicos(l_antecedentes_no_patologicos.padres,
                                l_antecedentes_no_patologicos.id_beneficiario_fk,
                                l_antecedentes_no_patologicos.especifique,
                                l_antecedentes_no_patologicos.espe_diagnostico,
                                l_antecedentes_no_patologicos.consumes_drogas,
                                l_antecedentes_no_patologicos.id_antecendente_no_pk
                                
                                )" class="flat" color="primary" mat-flat-button type="button">Guardar</button>
                            </div>
                        </div>
                    </div>
                    
               
             
                    
                
                </div>   
            </div>
            














            <br>
            <br>
            <br>
            <br>













            <form class="mt-5" novalidate [formGroup]="form" autocomplete="off" (click)="clearSeccion(3)">
               
                <div class="row">
                    <div class="col">
                        <span><strong>ANTECEDENTES PERSONALES PATOLÓGICOS</strong></span><br>
                    </div>
                    <div class="col">
                            
                        <div class="d-flex justify-content-start">
                            
                            <mat-radio-group formControlName="conoce_ante_per_pato">  <!--esperando a roque-->
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Se conoce </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> Se desconoce </mat-radio-button>
                                <mat-error *ngIf="form.controls['conoce_ante_per_pato'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-radio-group>
                            
                        </div>
                    </div>
                </div>
                

                <div class="row mt-3" *ngIf="form.value.conoce_ante_per_pato == 1">
                    
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w" >
                            <mat-label>Seleccione el antecedente patológico</mat-label>
                            <mat-select formControlName="numero_personal_patologico">
                                <mat-option *ngFor="let p of antecedentes_personales_patologicos" [value]="p.id_ante_per_pato_cat_pk">{{p.antecedente}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col" >
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Especifique </mat-label>
                            <input type="text" matInput  formControlName="especifique_nota">
                        </mat-form-field>
                    </div>
                    <div class="col" *ngIf="form.value.numero_personal_patologico == 33">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Especifique otro</mat-label>
                            <input type="text" matInput  formControlName="especifique">
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                        <!--(click)="postRegistroBeneficiarioEspecialista()"-->
                        <button  type="button" color="primary"  mat-mini-fab (click)="postRegistroAntecedentesPersonalesPatologicos()" *ngIf="accion == 'registrar'">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
            <div *ngIf="form.value.conoce_ante_per_pato == 1">
                <div class="row mt-3" *ngFor="let l_antecedentes_personales_patologicos of l_antecedentes_personales_patologicos">
                    <!--<div class="col">
                        <div class="d-flex justify-content-start">
                                
                            <mat-radio-group [(ngModel)]="l_antecedentes_personales_patologicos.id_per_pato_fk">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Se conoce </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> Se desconoce </mat-radio-button>
                            </mat-radio-group>
                            
                        </div>
                    </div>-->
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Antecedente patológico</mat-label>
                            <mat-select [(ngModel)]="l_antecedentes_personales_patologicos.id_per_pato_fk">
                                <mat-option *ngFor="let p of antecedentes_personales_patologicos" [value]="p.id_ante_per_pato_cat_pk">{{p.antecedente}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col" >
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Especifique </mat-label>
                            <input type="text" matInput  [(ngModel)]="l_antecedentes_personales_patologicos.especifique_nota">
                        </mat-form-field>
                    </div>
                    <div class="col" *ngIf="l_antecedentes_personales_patologicos.id_per_pato_fk == 33">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Especifique otro</mat-label>
                            <input type="text" matInput  [(ngModel)]="l_antecedentes_personales_patologicos.especifique">
                        </mat-form-field>
                    </div>
                    
                 
                        <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                            <div class="d-flex justify-content-start" *ngIf="accion == 'registrar'">
                                <button  (click)="postEliminarAntecedentesPersonalesPatologicos(l_antecedentes_personales_patologicos.id_antecendente_per_pk)" type="button" class="btn btn-small btn-danger">x</button>
                                &nbsp;
                                <button  (click)="postModificarAntecedentesPersonalesPatologicos(l_antecedentes_personales_patologicos.id_bene_fk, l_antecedentes_personales_patologicos.id_per_pato_fk, l_antecedentes_personales_patologicos.especifique, l_antecedentes_personales_patologicos.id_antecendente_per_pk, l_antecedentes_personales_patologicos.especifique_nota)" class="flat" color="primary" mat-flat-button type="button">Guardar</button>
                            </div>
                        </div>
                    
                </div>  
            </div>
             












            







            

            <form class="mt-5" novalidate [formGroup]="form" autocomplete="off" (click)="clearSeccion(4)">
                <div class="row mt-5">
                    <div class="col">
                        <span class="mt-5"><strong>HOSPITALIZACIONES Y CIRUGÍAS</strong></span><br>
                    </div>
                    <div class="col">
                        <div class="d-flex justify-content-start">
                            <mat-radio-group formControlName="hos_cirugia_edo">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Se conoce </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> Se desconoce </mat-radio-button>
                                <mat-error *ngIf="form.controls['hos_cirugia_edo'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="row mt-3"  *ngIf="form.value.hos_cirugia_edo == 1">
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Hospitalizaciones y Cirugías</mat-label>
                            <mat-select formControlName="numero_hospital">
                                <mat-option *ngFor="let p of hospitalizacion_cirugia" [value]="p.id_hostpi_pk">{{p.hospitalizacion}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Fecha </mat-label>
                            <input type="date" matInput  formControlName="fecha">
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Especifique </mat-label>
                            <input type="text" matInput  formControlName="especifique">
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                        <!--(click)="postRegistroBeneficiarioEspecialista()"-->
                        <button  type="button" color="primary"  mat-mini-fab (click)="postRegistroHospitalizacionPato()" *ngIf="accion == 'registrar'">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    
                </div>
            </form>
            <div class="row"  *ngIf="form.value.hos_cirugia_edo == 1">
                <div class="row" *ngFor="let l_hospitalizacion_clinica of l_hospitalizacion_clinica">
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Hospitalizaciones y Cirugías</mat-label>
                            <mat-select [(ngModel)]="l_hospitalizacion_clinica.id_hospital_fk">
                                <mat-option *ngFor="let p of hospitalizacion_cirugia" [value]="p.id_hostpi_pk">{{p.hospitalizacion}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Fecha </mat-label>
                            <input type="date" matInput  [(ngModel)]="l_hospitalizacion_clinica.fecha">
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Especifique </mat-label>
                            <input type="text" matInput  [(ngModel)]="l_hospitalizacion_clinica.especifique">
                        </mat-form-field>
                    </div>
                    
                 
                        <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                            <div class="d-flex justify-content-start" *ngIf="accion == 'registrar'">
                                <button  (click)="postEliminarHospitalizacionPato(l_hospitalizacion_clinica.id_hosp_pk)" type="button" class="btn btn-small btn-danger">x</button>
                                &nbsp;
                                <button  (click)="postModificarHospitalizacionPato(l_hospitalizacion_clinica.id_beneficiario_fk, l_hospitalizacion_clinica.id_hosp_pk, l_hospitalizacion_clinica.especifique, l_hospitalizacion_clinica.id_hospital_fk, l_hospitalizacion_clinica.fecha)" class="flat" color="primary" mat-flat-button type="button">Guardar</button>
                            </div>
                        </div>
                    
                </div>   
            </div>
            
                



























            <br>
            <br>
            <br>
            <br>



            <form class="mt-5" novalidate [formGroup]="form" autocomplete="off">



                <span><strong>ANTECEDENTES PERSONALES NO PATOLÓGICOS</strong></span>
                <div class="row mt-3">
                   
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <span>¿Acitividad física? </span>
                            
                        </div>
                    </div>
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <mat-radio-group formControlName="actividad_fisica">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Si </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> No </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="3"> Se desconoce </mat-radio-button>
                                <mat-error *ngIf="form.controls['actividad_fisica'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Describir actividad y tiempo invertido </mat-label>
                            <input type="text" maxlength="2500" #actividad_espe matInput formControlName="actividad_espe" >
                            <mat-hint align="end">{{actividad_espe.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-3">
                    
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <span>¿Antecedentes consumo de drogas? </span>
                            
                           
                        </div>
                    </div>
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <mat-radio-group formControlName="ante_drogas">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Si </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> No </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="3"> Se desconoce </mat-radio-button>
                                <mat-error *ngIf="form.controls['ante_drogas'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Describir cuales y si se suspendió consumo </mat-label>
                            <input type="text" maxlength="2500" #drogas_espe  matInput   formControlName="drogas_espe">
                            <mat-hint align="end">{{drogas_espe.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-3">
                    
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <span>¿Control de esfínteres? </span>
                            
                        </div>
                    </div>
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <mat-radio-group formControlName="control_desin">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Si </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> No </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="3"> Se desconoce </mat-radio-button>
                                <mat-error *ngIf="form.controls['control_desin'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Observaciones </mat-label>
                            <input type="text" maxlength="2500" #control_espe  matInput   formControlName="control_espe">
                            <mat-hint align="end">{{control_espe.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                        
                    </div>
                </div>

                <div class="row mt-3">
                    
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <span>Uso de órtesis, prótesis, férula o silla de ruedas </span>
                            
                        </div>
                    </div>
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <mat-radio-group formControlName="ortesis">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Si </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> No </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="3"> Se desconoce </mat-radio-button>
                                <mat-error *ngIf="form.controls['ortesis'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                        <!--<mat-checkbox formControlName="protesis1"> Órtesis </mat-checkbox>&nbsp;
                        <mat-checkbox formControlName="protesis2"> Prótesis </mat-checkbox>&nbsp;
                        <mat-checkbox formControlName="protesis3"> Férula </mat-checkbox>&nbsp;
                        <mat-checkbox formControlName="protesis4"> Silla de ruedas </mat-checkbox>&nbsp;-->
                        <!--<mat-radio-group formControlName="protesis">
                            
                            <mat-radio-button [value]="1"> Órtesis </mat-radio-button>
                            &nbsp; 
                            <mat-radio-button [value]="2"> Prótesis </mat-radio-button>
                            &nbsp; 
                            <mat-radio-button [value]="3"> Férula </mat-radio-button>
                            &nbsp; 
                            <mat-radio-button [value]="4"> Silla de ruedas </mat-radio-button>
                            
                        </mat-radio-group>-->

                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Observaciones </mat-label>
                            <input type="text" maxlength="2500" #protesis  matInput   formControlName="protesis">
                            <mat-hint align="end">{{protesis.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <br>
                        <span>Número de veces que consume alimento</span>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Número de veces que consume alimento </mat-label>
                            <input type="text" matInput  formControlName="veces_alimentos" >
                            <mat-error *ngIf="form.controls['veces_alimentos'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['veces_alimentos'].hasError('maxlength')">
                                Excedio caracteres
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>



                <div class="row mt-3">
                    
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <span>¿Restringe alimentos? (textura, sabor, color)</span>
                            
                        </div>
                    </div>
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <mat-radio-group formControlName="restringe_alimentos">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Si </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> No </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="3"> Se desconoce </mat-radio-button>
                                <mat-error *ngIf="form.controls['restringe_alimentos'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Describir cuales </mat-label>
                            <input  maxlength="2500" #restringe_espe  type="text" matInput  formControlName="restringe_espe" >
                            <mat-hint align="end">{{restringe_espe.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-3">
                    
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <span>¿Vacunas completas? </span>
                            
                        </div>
                    </div>
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <mat-radio-group formControlName="vacunas">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Si </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> No </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="3"> Se desconoce </mat-radio-button>
                                <mat-error *ngIf="form.controls['vacunas'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Describir cuales </mat-label>
                            <input type="text" #vacunas_text  maxlength="2500" matInput  formControlName="vacunas_text" >
                            <mat-hint align="end">{{vacunas_text.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row ">
                    <div class="col">
                        <br>
                        Detalles de sueño (Concilia, somnolencia diurna, ronca, horas de sueño, tiempo que tarda en dormir)
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Describir </mat-label>
                            <input type="text" #concilia_sueno  maxlength="2500" matInput  formControlName="concilia_sueno" >
                            <mat-error *ngIf="form.controls['concilia_sueno'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['concilia_sueno'].hasError('maxlength')">
                                El maximo tamaño es de 2500 caracteres
                            </mat-error>
                            <mat-hint align="end">{{concilia_sueno.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        Socialización
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Describir </mat-label>
                            <input type="text"  #socializacion  maxlength="2500" matInput  formControlName="socializacion" >
                            <mat-error *ngIf="form.controls['socializacion'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['socializacion'].hasError('maxlength')">
                                El maximo tamaño es de 2500 caracteres
                            </mat-error>
                            <mat-hint align="end">{{socializacion.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        Estereotipias
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Describir </mat-label>
                            <input type="text"  #estereotipo  maxlength="2500" matInput  formControlName="estereotipo" >
                            <mat-error *ngIf="form.controls['estereotipo'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['estereotipo'].hasError('maxlength')">
                                El maximo tamaño es de 2500 caracteres
                            </mat-error>
                            <mat-hint align="end">{{estereotipo.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        Juegos
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Describir </mat-label>
                            <input type="text"  #juegos  maxlength="2500" matInput  formControlName="juegos" >
                            <mat-error *ngIf="form.controls['juegos'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['juegos'].hasError('maxlength')">
                                El maximo tamaño es de 2500 caracteres
                            </mat-error>
                            <mat-hint align="end">{{juegos.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        Hitos
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Describir </mat-label>
                            <input type="text"  #hitos  maxlength="2500" matInput  formControlName="hitos" >
                            <mat-error *ngIf="form.controls['hitos'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['hitos'].hasError('maxlength')">
                                El maximo tamaño es de 2500 caracteres
                            </mat-error>
                            <mat-hint align="end">{{hitos.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        Perinatales (gesta, parto, cesárea, problemas de embarazo, lloró o respiró al nacer)
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Describir </mat-label>
                            <input type="text"  #perinatales  maxlength="2500" matInput  formControlName="perinatales" >
                            <mat-error *ngIf="form.controls['perinatales'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['perinatales'].hasError('maxlength')">
                                El maximo tamaño es de 2500 caracteres
                            </mat-error>
                            <mat-hint align="end">{{perinatales.value.length}} / 2500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <span><strong>ANTECEDENTES GINECO-OBSTÉTRICOS</strong></span>

                <div class="row mt-3">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Menarca (años) </mat-label>
                            <input type="text"  #menarca  maxlength="2500" matInput  formControlName="menarca" >
                            <mat-error *ngIf="form.controls['menarca'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['menarca'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <span>¿Ciclo regular? </span>
                            <mat-radio-group formControlName="ciclo_regular">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Si </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> No </mat-radio-button>
                                <mat-error *ngIf="form.controls['ciclo_regular'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Días </mat-label>
                            <input type="text" matInput  formControlName="dias" >
                            <mat-error *ngIf="form.controls['dias'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['dias'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--<div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>No Tallas (día abundante)</mat-label>
                            <input type="text" matInput  formControlName="toallas" >
                            <mat-error *ngIf="form.controls['toallas'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['toallas'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>
                    </div>--> 
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Número de toallas (día abundante)</mat-label>
                            <input type="number" matInput  formControlName="num_toallas_mod" >
                            <mat-error *ngIf="form.controls['num_toallas_mod'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['num_toallas_mod'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>
                    </div>  
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Observaciones</mat-label>
                            <input type="text" matInput  formControlName="toallas_text" >
                            <mat-error *ngIf="form.controls['toallas_text'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                </div>

                <div class="row">
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <span>Vida sexual </span>
                            <mat-radio-group formControlName="vida_sexual">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Si </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> No </mat-radio-button>
                                <mat-error *ngIf="form.controls['vida_sexual'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col">
                        <br>
                        <div class="d-flex justify-content-start">
                            <span>¿Se ha embarazado? </span>
                            <mat-radio-group formControlName="embarazada">
                                &nbsp; 
                                &nbsp; 
                                &nbsp; 
                                <mat-radio-button [value]="1"> Si </mat-radio-button>
                                &nbsp; 
                                <mat-radio-button [value]="2"> No </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" *ngIf="form.value.embarazada == 1">
                            <mat-label>Número de embarazos </mat-label>
                            <input type="text" matInput  formControlName="numero_embarazos" >
                            <mat-error *ngIf="form.controls['numero_embarazos'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                
            

            </form>

            <div class="mt-5 d-flex justify-content-center gap-2">


                

                <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos']" >
                    Regresar
                </button>

                <form novalidate [formGroup]="form" (ngSubmit)="postRegistroHistorialClinicoUno()" *ngIf="accion == 'registrar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>


            </div>

        </mat-card-content>
    </mat-card>
</div>

