<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Gestor de puestos</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div class="bt col-md-12 text-align-right">
                <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/gestor-puestos/registrar']" >
                    Registrar nuevo puesto
                </button>
                &nbsp;
                <!--<button class="flat" color="primary" mat-flat-button>Comparativo</button>&nbsp;
                    <button class="flat" color="primary" mat-flat-button>Gráfica (Pizza)</button>-->
                </div>

                <!-- tabla -->
                <div class="row mt-5 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="_id">
                            <th id="_id" mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let element">&nbsp;{{element?.id_puesto_reporte_pk}}&nbsp;</td>
                        </ng-container>

                        <ng-container matColumnDef="_puestos">
                            <th id="_puestos" mat-header-cell *matHeaderCellDef> PUESTO </th>
                            <td mat-cell *matCellDef="let element">&nbsp;{{element?.puestos_reporte}}&nbsp;</td>
                        </ng-container>

                        <ng-container matColumnDef="_modificar">
                            <th id="_modificar" mat-header-cell *matHeaderCellDef> MODIFICAR </th>
                            <td mat-cell *matCellDef="let element">&nbsp;
                                <button type="button" mat-mini-fab color="primary" >
                                    <mat-icon class="material-icons" [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/gestor-puestos/modificar']" [queryParams]="{id_puesto:element.id_puesto_reporte_pk}">
                                        open_in_new
                                    </mat-icon>
                                </button>&nbsp;
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_eliminar">
                            <th id="_eliminar" mat-header-cell *matHeaderCellDef> ELIMINAR </th>
                            <td mat-cell *matCellDef="let element">&nbsp;
                                <button type="button" mat-mini-fab color="warn" (click)="postEliminarPuesto(element.id_puesto_reporte_pk)">
                                    <mat-icon class="material-icons">
                                        delete
                                    </mat-icon>
                                </button>&nbsp;
                            </td>
                        </ng-container>

                        <!-- fin contenido -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- fin tabla -->
                <div class="mt-5 d-flex justify-content-center">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo']" >
                        Regresar
                    </button>
                </div>

                
            </mat-card-content>
        </mat-card>
    </div>
