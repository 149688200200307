import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class RrhhService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

  /*area o dep*/
  public postRegistrarDepartamento(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarDepartamento", datos, {});
  }

  public postModificarDepartamento(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarDepartamento", datos, {});
  }

  public getConsultarDepartamentos(p: number, r: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarDepartamentos?filas="+r+'&page='+p);
  }

  public postEliminarDepartamento(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarDepartamento", datos, {});
  }

  public getConsultarDepartamento(id):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarDepartamento?numero_depa="+id);
  }
  /*fin area o dep*/

  /*puestos*/
  public postRegistrarPuestos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarPuestos", datos, {});
  }
  public postModificarPuestos(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarPuestos", datos, {});
  }

  public getConsultarPuestos(p: number, r: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarPuestos?filas="+r+'&page='+p);
  }

  public postEliminarPuesto(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarPuestos", datos, {});
  }

  public getConsultarPuesto(id):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarPuesto?numero_puesto="+id);
  }
  /*fin puestos*/

  /*descripcion puesto*/
  public getDatosFormDescripcionPuesto(): any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/DatosFormDescripcionPuesto");

  }

  public getConsultaDescripcion(id: any):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaDescripcion?numero_descripcion="+id);
  }

  public postRegistroDescripcion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistroDescripcion", datos, {});
  }

  public postEliminarDescripcion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarDescripcion", datos, {});
  }

  public postModificarDescripcion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarDescripcion", datos, {});
  }

  public postModificarEstadoDescripcion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarEstadoDescripcion", datos, {});
  }
  /*fin descripcion puesto*/

  /*perfil*/
  public getConsultaFormPerfil(): any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaFormPerfil");

  }

  public postRegistroPerfilPuesto(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistroPerfilPuesto", datos, {});
  }

  public postModificarPerfilPuesto(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarPerfilPuesto", datos, {});
  }

  /*fin perfil*/

  /*funciones*/
  public postRegistrarPuesto(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarPuesto", datos, {});
  }

  public postEliminarPuestoFuncion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarPuesto", datos, {});
  }

  public postModificarPuestoFuncion(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarPuesto", datos, {});
  }
  /*fin funciones*/

  /*gestor*/
  public getConsultaDescripcionBuscador(p: number, r: number, s: string):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultaDescripcionBuscador?filas="+r+'&page='+p+'&buscador='+s);
  }

  /*fin gestor*/

  /*organigrama*/
  public postRegistrarDocumentosOrg(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistrarDocumentosOrg", datos, {});
  }

  public postModificarDocumentosOrg(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarDocumentosOrg", datos, {});
  }

  public getConsultarDocumentosOrg():any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarDocumentosOrg");
  }

  public postEliminarDocumentosOrg(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarDocumentosOrg", datos, {});
  }
  /*fin organigrama*/



  /*gestor expedientes personal*/
  public postRegistroExpedientePersonal(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/RegistroExpedientePersonal", datos, {});
  }

  public postModificarExpedientePersonal(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarExpedientePersonal", datos, {});
  }

  public getExpedienteConsultas(p: number, r: number, s: string, caso: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ExpedienteConsultas?filas="+r+'&page='+p+'&buscar='+s+'&buscador='+caso);
  }

  public postEliminarEmpleadosExpedientes(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/eliminarEmpleadosExpedientes", datos, {});
  }

  public getDatosPersonalRRHH():any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/DatosPersonalRRHH");
  }

  public getExpedienteConsulta(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ExpedienteConsulta?numero_empleado_reg="+id);
  }
  /*fin gestor expedientes personal*/


  public postRegistroFotoExpediente(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registroFotoExpediente", datos, {});
  }

  public postEliminarFotoExpediente(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/eliminarFotoExpediente", datos, {});
  }



  public getConsultarDocumentosTipoEmpleado(id: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/ConsultarDocumentosTipoEmpleado?numero_tipo_documento="+id);
  }

  public getRegistrarModificarDocumentoExpedienteEmpleado(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/registrarModificarDocumentoExpedienteEmpleado", datos, {});
  }
  
  public getConsultaDocumentosExpediente(id: number, id2: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultaDocumentosExpediente?tipo="+id+'&numero_empleado_reg='+id2);
  }


  //se agregan nuevas
  public postAgregarTarjetaBeneficiarios(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/AgregarTarjetaBeneficiarios", datos, {});
  }

  public postModificarTarjetaBeneficiarios(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/ModificarTarjetaBeneficiarios", datos, {});
  }

  public postEliminarTarjetaBeneficiarios(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/EliminarTarjetaBeneficiarios", datos, {});
  }



  //municipios
  public getMunicipios(id_estados_republica_fk: any):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/getMunicipios?id_estados_republica_fk="+id_estados_republica_fk, {});
  }

  public getRegimenSat():any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/getRegimenSat", {});
  }

  

  






  
}
