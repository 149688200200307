<div class="card-container" id="top">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Bitacora</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <!--<form novalidate [formGroup]="form" autocomplete="off">-->
                
            <!--</form>-->
            <div class="row mt-3">
                <div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Fecha de apertura de bitácora </mat-label>
                        <input disabled class="text-black" type="text" matInput  [value]="data?.fecha_ingreso">
                    </mat-form-field>
                </div>
                <div class="col">
                    
                </div>
            </div>
            <div class="row mt-5" *ngFor="let a of bene; let i = index">
                <div class="col">
                    <div class="row"> <!--beneficiaria-->
                       
                    </div>
                    <div class="row" *ngFor="let r of data?.tratamientos; let g = index">
                        <div class="col">
                            <div class="row" *ngIf="r?.evento_medico?.beneficiario?.id == a?.evento_medico.beneficiario.id">
                                <div class="col">
                                    <div class="row"> <!--tratamientos-->
                                        <div class="col-xs-1 coll-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                                            <button mat-fab color="primary" aria-label="Example icon button with a home icon">
                                                {{i + 1}}
                                            </button>
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="fill"  class="input-full-w">
                                                <mat-label>Nombre de la beneficiaria </mat-label>
                                                <input disabled class="text-black" readonly="true" type="text" matInput  [value]="a?.evento_medico?.beneficiario?.nombre">
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="fill" class="input-full-w">
                                                <mat-label>Tratamiento {{g + 1}}</mat-label>
                                                <mat-select disabled class="text-black" readonly="true" [(ngModel)]="r.id_tratamiento_medico_fk"  >
                                                    <mat-option disabled class="text-black" *ngFor="let p of tts" [value]="p.id_tratamiento_pk">{{p.tratamiento}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="fill"  class="input-full-w">
                                                <mat-label>ID Tratamiento </mat-label>
                                                <input disabled class="text-black" readonly="true" type="text" matInput  [value]="r?.id_trata">
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                    <table class="table">
                                        <caption><!--sonar--></caption>
                                        <thead>
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Nombre del medicamento</th>
                                            <th scope="col">Presentación</th>
                                            <th scope="col">Dosis</th>
                                            <th scope="col">Frecuencia</th>
                                            <th scope="col">Duración</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let x of r.medicamentos; let b = index">
                                            <td >{{b + 1}}</td>
                                            <td>{{x?.nombre_medicamento}}</td>
                                            <td>{{x?.presentacion?.presentacion}}</td>
                                            <td>{{x?.dosis}}</td>
                                            <td>{{x?.frecuencia}}</td>
                                            <td>{{x?.duracion}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>

                                    <!---medicamentos
                                    <div class="row" *ngFor="let x of r.medicamentos; let b = index"> 
                                        <h2><strong>Medicamento {{b + 1}}</strong></h2>
                                        <div class="col">
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field appearance="fill"  class="input-full-w">
                                                        <mat-label>Nombre del medicamento </mat-label>
                                                        <input disabled class="text-black" readonly="true" type="text" matInput  [value]="x?.nombre_medicamento">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col">
                                                    <mat-form-field appearance="fill"  class="input-full-w">
                                                        <mat-label>Presentación </mat-label>
                                                        <input disabled class="text-black" readonly="true" type="text" matInput  [value]="x?.presentacion?.presentacion">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field appearance="fill"  class="input-full-w">
                                                        <mat-label>Dosis </mat-label>
                                                        <input disabled class="text-black"  readonly="true" type="text" matInput  [value]="x?.dosis">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col">
                                                    <mat-form-field appearance="fill"  class="input-full-w">
                                                        <mat-label>Frecuencia </mat-label>
                                                        <input disabled class="text-black"  readonly="true" type="text" matInput  [value]="x?.frecuencia">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col">
                                                    <mat-form-field appearance="fill"  class="input-full-w">
                                                        <mat-label>Duración </mat-label>
                                                        <input disabled class="text-black" readonly="true" type="text" matInput  [value]="x?.duracion">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <mat-form-field appearance="fill"  class="input-full-w">
                                                    <mat-label>Indicaciones generales </mat-label>
                                                    <input disabled class="text-black"  readonly="true" type="text" matInput  [value]="x?.indicacion_general">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>-->


                                    <div class="row">
                                        <mat-form-field appearance="fill"  class="input-full-w">
                                            <mat-label>Indicaciones generales </mat-label>
                                            <textarea disabled class="text-black" readonly="true" type="text" matInput  [value]="r?.indicaciones_generales?.indicacion_general"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <mat-form-field appearance="fill"  class="input-full-w">
                                            <mat-label>Observaciones (1000 caracteres) </mat-label>
                                            <textarea rows="3" type="text" matInput  [(ngModel)]="r.observacion"></textarea>
                                        </mat-form-field>
                                    </div>





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <form novalidate [formGroup]="form" autocomplete="off">
                <div class="row">
                    <div class="col">
                        ¿Se presentaron incidencias en el turno?
                    </div>
                    <div class="col">
                        <mat-radio-group formControlName="se_presento_incidencia">
                                    
                            <mat-radio-button [value]="1"> Sí </mat-radio-button>
                            &nbsp; 
                            <mat-radio-button [value]="2"> No </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="row mt-3">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>Describa (3000 caracteres) </mat-label>
                        <textarea rows="3" type="text" matInput  formControlName="describa" maxlength="3000"></textarea>
                    </mat-form-field>
                </div>
            </form>

            <div class="mt-5 d-flex justify-content-center gap-2">
                <button  class="flat" color="primary" mat-flat-button [routerLink]="['/medico/gt-bitacora']" >
                    Regresar a parilla
                </button>

                <form novalidate [formGroup]="form" (ngSubmit)="postModificarBitacoras(1)" *ngIf="accion == 'modificar' && form.value.boton == 1">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>

                <form novalidate [formGroup]="form" (ngSubmit)="postModificarBitacoras(11)" *ngIf="accion == 'modificar' && form.value.boton == 1">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Cerrar
                    </button>
                </form>


            </div>


        </mat-card-content>
    </mat-card>
</div>