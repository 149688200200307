import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DesitionBoxComponent } from 'src/app/modals/desition-box/desition-box.component';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ParametrosService } from 'src/app/services/parametros.service';

@Component({
  selector: 'app-vista-documentos',
  templateUrl: './vista-documentos.component.html',
  styleUrls: ['./vista-documentos.component.scss']
})
export class VistaDocumentosComponent implements OnInit {
  hostapi:string;
  files = [];
  constructor(
    private dialog:MatDialog,
    private _conf:ConfigurationService,
    private _parametrosService:ParametrosService,
    private _documentosService:DocumentosService,
    private activatedRoute:ActivatedRoute
    ) { 
      this.hostapi=_conf.getConfiguration().hostapi;
      this.formulario = new UntypedFormGroup({
        tipoDocumento: new UntypedFormControl("",[Validators.required]),
        archivo: new UntypedFormControl("",[Validators.required]),
        nombre: new UntypedFormControl("",[])
      })
  }
  isLoading = false;
  formulario:UntypedFormGroup;
  fileToUpload: File = null;
  idBeneficiaria:number;
  tipoDocumentos = [];
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info(params["id"]);
      if(params["id"]!=undefined){
        
        this.getTipoDocumentos();
        this.idBeneficiaria = parseInt(params["id"]);
        
        this.formulario.controls.archivo.disable();
      }
    });
  }
  filterDocs____(documentos:any):any{
    let arreglo:any = [];
    for(let i=0; i<documentos.length;i++){
      if(this.getTipoById____(documentos[i].idTipoDocumento) != "Nombre desconocido"){
        arreglo.push(documentos[i]);
      }
    }
    return this.sortingDocs____(arreglo);
  }
  sortingDocs____(documentos){
    let sortingArray:any=[];
    for(let i=0; i<this.tipoDocumentos.length;i++){
      for(let x=0; x<documentos.length; x++){
        if(this.tipoDocumentos[i].idParametro == documentos[x].idTipoDocumento){
          sortingArray.push(documentos[x]);
        }
      }
    }
    return sortingArray;
  }
  getDocumentos____(){
    this.isLoading=true;
    this._documentosService.getAllByBeneficiaria(this.idBeneficiaria).subscribe(result=>{
      this.files = this.filterDocs____(result.data.documentos);
      this.isLoading=false;
    },()=>{
      this.isLoading=false;
    });
  }
  getTipoDocumentos(){
    this.isLoading = true;
    this._parametrosService.getParametros("6").subscribe(result=>{
      this.tipoDocumentos = result.data.parametros;
      this.isLoading = false;
      this.getDocumentos____();
    });
  }

  handleFileInput____(files: FileList) {
    this.fileToUpload = files.item(0);
    this.formulario.controls.archivo.setValue(this.fileToUpload.name);
    this.formulario.controls.archivo.disable();
  }

  getTipoById____(id){
    for(let i = 0; i<this.tipoDocumentos.length; i++){
      if(this.tipoDocumentos[i].idParametro== id){
        return this.tipoDocumentos[i].valor;
      }
    }
    return "Nombre desconocido";
  }

}

