<br>
<div class="table-container">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner color="accent"></mat-spinner>
    </div>
    <form [formGroup]="formulario" (ngSubmit)="p_sendForm()" autocomplete="off">
        

        <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Fecha de ingreso</mat-label>
            <input matInput [matDatepicker]="dp3" formControlName="fechaIngreso" readonly>
            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
            <mat-datepicker touchUi #dp3 disabled="true"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-2">
            <mat-label>Edad de ingreso</mat-label>
            <input matInput placeholder="" readonly [value]="edadIngreso">
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-2">
            <mat-label>Tiempo de antiguedad</mat-label>
            <input matInput placeholder="" readonly [value]="tiempoDeAntiguedad">
        </mat-form-field>

        <h3>Información complementaria</h3>
        <mat-form-field appearance="fill" class="bt col-md-12">
            <mat-label>Breve antecedente</mat-label>
            <textarea matInput placeholder="" maxlength="300" #antecedente formControlName="desAntecedente"></textarea>
            <mat-hint align="end">{{antecedente.value.length}} / 300</mat-hint>
        </mat-form-field>

        <h3>Perfil diagnóstico Goretti</h3>

        <div>
            <mat-form-field appearance="fill" class="bt col-md-6">
                <mat-label>Discapacidad</mat-label>
                <mat-select formControlName="idDiscapacidad" multiple #discapacidadesf>
                <mat-select-trigger>
                    {{discapacidadesf.value ? discapacidadesf.value[0] : ''}}
                    <span *ngIf="discapacidadesf.value?.length > 1" class="example-additional-selection">
                    (+{{discapacidadesf.value.length - 1}} {{discapacidadesf.value?.length === 2 ? 'más' : 'más'}})
                    </span>
                </mat-select-trigger>
                <mat-option *ngFor="let discapacidad of discapacidades" [value]="discapacidad">{{discapacidad}}</mat-option>
                </mat-select>
            </mat-form-field>      
        </div>

        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Diagnóstico neurológico</mat-label>
            <mat-select formControlName="diagnosticoNeurologico">
                <mat-option value="{{dn.idParametro}}" *ngFor="let dn of diagnosticoNeurologico">{{dn.valor}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento diagnóstico neurológico</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo.value" formControlName="dnRuta" readonly>
            <button matTooltip="Click para seleccionar documento a cargar" mat-icon-button matSuffix (click)="archivo.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            
            <input type="file" #archivo class="hide" formControlName="dnArchivo" (change)="p_handleFileInput1($event.target.files)"/>
            
            <a target="_blank" rel="noopener" href="{{hostapi}}/{{documento1+'?t='+t}}" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" *ngIf="documento1">
                <mat-icon>download</mat-icon>
            </a>
            
            <mat-hint *ngIf="documento1">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento1" (click)="postEliminarDocumentoBene(idBeneficiaria, 57, documentos[6].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>

        <mat-form-field appearance="fill" class="bt col-md-12">
            <mat-label>Descripción</mat-label>
            <textarea matInput placeholder="" maxlength="150" #descripcion formControlName="dnDescripcion"></textarea>
            <mat-hint align="end">{{descripcion.value.length}} / 150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Edad mental</mat-label>
            <input matInput placeholder="" type="number" maxlength="2" formControlName="edadMental">
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento diagnóstico neurológico</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo2.value" formControlName="dmRuta" readonly>
            <button mat-icon-button matSuffix (click)="archivo2.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo2 class="hide" formControlName="emArchivo" (change)="p_handleFileInput2($event.target.files)"/>
            
            <a href="{{hostapi}}/{{documento2+'?t='+t}}" *ngIf="documento2" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
            
            <mat-hint *ngIf="documento2">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento2" (click)="postEliminarDocumentoBene(idBeneficiaria, 58, documentos[7].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>

        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Diagnóstico motríz</mat-label>
            <input matInput placeholder="" maxlength="150" #motriz formControlName="diagnosticoMotriz">
            <mat-hint align="end">{{motriz.value.length}} / 150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento diagnóstico motríz</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo3.value" formControlName="dmRuta" readonly>
            <button mat-icon-button matSuffix (click)="archivo3.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo3 class="hide" formControlName="dmArchivo" (change)="p_handleFileInput3($event.target.files)"/>
            
            <a href="{{hostapi}}/{{documento3+'?t='+t}}" *ngIf="documento3" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
            
            <mat-hint *ngIf="documento3">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento3" (click)="postEliminarDocumentoBene(idBeneficiaria, 59, documentos[8].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>

        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Diagnóstico psicológico</mat-label>
            <input matInput placeholder="" maxlength="150" #psicologico formControlName="diagnosticoPsicologico">
            <mat-hint align="end">{{psicologico.value.length}} / 150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento diagnóstico psicológico</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo4.value" formControlName="dpRuta" readonly>
            <button mat-icon-button matSuffix (click)="archivo4.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo4 class="hide" formControlName="dpArchivo" (change)="p_handleFileInput4($event.target.files)"/>
            
            <a href="{{hostapi}}/{{documento4+'?t='+t}}" *ngIf="documento4" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
            
            <mat-hint *ngIf="documento4">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento4" (click)="postEliminarDocumentoBene(idBeneficiaria, 60, documentos[9].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>

        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Diagnóstico médico</mat-label>
            <input matInput placeholder="" maxlength="150" #medico formControlName="diagnosticoMedico">
            <mat-hint align="end">{{medico.value.length}} / 150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento diagnóstico médico</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo5.value" formControlName="dmedRuta" readonly>
            <button mat-icon-button matSuffix (click)="archivo5.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo5 class="hide" formControlName="dmedArchivo" (change)="p_handleFileInput5($event.target.files)"/>
            
            <a href="{{hostapi}}/{{documento5+'?t='+t}}" *ngIf="documento5" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
           
            <mat-hint *ngIf="documento5">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento5" (click)="postEliminarDocumentoBene(idBeneficiaria, 61, documentos[10].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>

        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Tipo de sangre</mat-label>
            <mat-select formControlName="tipoDeSangre">
                <mat-option *ngFor="let tipo of tipoDeSangre" value="{{tipo.idParametro}}">{{tipo.valor}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento tipo de sangre</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo6.value" formControlName="tsRuta" readonly>
            <button mat-icon-button matSuffix (click)="archivo6.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo6 class="hide" formControlName="tsArchivo" (change)="p_handleFileInput6($event.target.files)"/>

            <a href="{{hostapi}}/{{documento6+'?t='+t}}" *ngIf="documento6" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
           
            <mat-hint *ngIf="documento6">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento6" (click)="postEliminarDocumentoBene(idBeneficiaria, 62, documentos[11].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>

        <br>
        
        <mat-label>Condición relevante</mat-label>
        &nbsp;
        <mat-radio-group aria-label="Condición relevante" formControlName="condicionRelevante">
            <mat-radio-button value="si">Si</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
        </mat-radio-group>
        &nbsp;

        <mat-form-field appearance="fill" class="bt col-md-6">
            <input matInput placeholder="" maxlength="100" #descripcion2 formControlName="condicionRelevanteDescription">
            <mat-hint align="end">{{descripcion2.value.length}} / 100</mat-hint>
        </mat-form-field>

        <div style="display: none;">
            <h3>Desarrollo</h3>
            <div class="bt col-md-4">
                <h4>Necesidades de apoyo cognitivas</h4>
                <mat-form-field appearance="fill" class="bt col-md-12">
                    <mat-label></mat-label>
                    <mat-select formControlName="autonomiaCognitivoSelect" readonly>
                        <mat-option value="{{dn.idParametro}}" *ngFor="let dn of autonomiasConitivas">{{dn.valor}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-12">
                    <mat-label>Descripción</mat-label>
                    <input matInput formControlName="autonomiaCognitivoInput" readonly>
                </mat-form-field>
            </div>

            <div class="bt col-md-4">
                <h4>Necesidades de apoyo motriz</h4>
                <mat-form-field appearance="fill" class="bt col-md-12">
                    <mat-label></mat-label>
                    <mat-select formControlName="autonomiaMotrizSelect" readonly>
                        <mat-option value="{{dn.idParametro}}" *ngFor="let dn of autonomiasConitivas">{{dn.valor}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-12">
                    <mat-label>Descripción</mat-label>
                    <input matInput formControlName="autonomiaMotrizInput" readonly>
                </mat-form-field>
            </div>

            <div class="bt col-md-4">
                <h4>Diagnóstico emocional</h4>
                <mat-form-field appearance="fill" class="bt col-md-12">
                    <mat-label></mat-label>
                    <mat-select formControlName="diagnosticoEmocionalSelect" readonly>
                        <mat-option value="{{dn.idParametro}}" *ngFor="let dn of autonomiasConitivas">{{dn.valor}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-12">
                    <mat-label>Descripción</mat-label>
                    <input matInput formControlName="diagnosticoEmocionalInput" readonly>
                </mat-form-field>
            </div>
        </div>

        <div class="text-align-center">
            <button class="hide" type="reset" id="resetForm">Reset</button>
            <button mat-stroked-button class="flat" type="button" [routerLink]="['/beneficiarias']">Cancelar</button>
            &nbsp;
            <button mat-flat-button color="primary" class="flat">Guardar</button>
        </div>
    </form>
</div>