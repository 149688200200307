<div class="card-container">
	<mat-card class="example-card">
		<mat-card-header>
			<mat-card-title class="fl">{{accion}} departamento</mat-card-title>
			<mat-card-subtitle></mat-card-subtitle>
		</mat-card-header>

		<mat-card-content>
			<div class="spinner" *ngIf="isLoading">
				<mat-spinner color="accent"></mat-spinner>
			</div>
			<form novalidate [formGroup]="form">
				<div class="row">
					<mat-form-field appearance="fill" class="input-full-w">
						<mat-label>AREA O DEPARTAMENTO</mat-label>
						<input type="text" matInput maxlength="250" #titulo_dep formControlName="departamento">
						<mat-hint align="end">{{titulo_dep.value.length}} / 250</mat-hint>
						<mat-error *ngIf="form.controls['departamento'].hasError('maxlength')">
							El maximo tamaño es de 250 caracteres
						</mat-error>
					</mat-form-field>
				</div>
			</form>

			<div class="mt-5 d-flex justify-content-center">
				<div *ngIf="form.invalid">
					<span class="text-danger">
						Faltan campos por llenar
					</span>
				</div>
			</div>

			<div class="d-flex justify-content-center gap-2">

				

				<button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo/gestor-area-departamento']" >
					Regresar
				</button>

				<form novalidate [formGroup]="form" *ngIf="accion == 'registrar'" (ngSubmit)="postRegistrarDepartamento()">
					<button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
						Guardar
					</button>
				</form>
				<form novalidate [formGroup]="form" *ngIf="accion == 'modificar'" (ngSubmit)="postModificarDepartamento()">
					<button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
						Modificar
					</button>
				</form>

			</div>
		</mat-card-content>
	</mat-card>
</div>
