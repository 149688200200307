//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';


@Component({
  selector: 'app-acciones-rrhh-puestos',
  templateUrl: './acciones-rrhh-puestos.component.html',
  styleUrls: ['./acciones-rrhh-puestos.component.scss'],
  providers: [ RrhhService ]
})
export class AccionesRrhhPuestosComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public formPuestos: UntypedFormGroup = this._fb.group({
    puesto: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
    numero_puesto: new UntypedFormControl('', []),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id_puesto: number = -1;

  constructor(
    private _fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _rrhhService: RrhhService,
    private _router: Router,
    private _route: ActivatedRoute,
    ){ 
   /*modificar*/
   this._route.queryParams.subscribe(
    params => {
      this.id_puesto = +params['id_puesto'];
      if(this.id_puesto){
        this.formPuestos.patchValue({ numero_puesto: this.id_puesto})
        this.getConsultarPuesto(this.id_puesto);
      }
    });
   /*fin modificar*/
 }

 ngOnInit(): void {
    //accion
    let url_list = this._router.url.split("/");
    this.accion = url_list[4].split("?")[0];
  }

  public postRegistrarPuestos(){

    this.isLoading = true;
    this._rrhhService.postRegistrarPuestos(this.formPuestos.value).subscribe(
      response => {
        this.respuesta(response);
      },
      error => {
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postModificarPuestos(){

    this.isLoading = true;
    this._rrhhService.postModificarPuestos(this.formPuestos.value).subscribe(
      response => {
        this.respuesta(response);
      },
      error => {
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public respuesta(response: any){
    this.isLoading = false;
    if(response.estado == 200){
      this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
      this._router.navigate(['rrhh/gestor-de-puestos-de-trabajo/gestor-puestos']);
    }else{
      this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
    }
  }



  public getConsultarPuesto(id: number){

    this.isLoading = true;
    this._rrhhService.getConsultarPuesto(id).subscribe(
      response => {
        this.isLoading = false;
        if(response.estado == 200){ this.setForm_rrhhPuestos(response.data);}
      },
      error => {
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public setForm_rrhhPuestos(data: any){
    this.formPuestos.patchValue({
      puesto: data.puestos_reporte,
      numero_puesto: data.id_puesto_reporte_pk,
    });
  }

}
