//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';



@Component({
  selector: 'app-acciones-rrhh-organigrama-docs',
  templateUrl: './acciones-rrhh-organigrama-docs.component.html',
  styleUrls: ['./acciones-rrhh-organigrama-docs.component.scss'],
  providers: [ RrhhService ]
})
export class AccionesRrhhOrganigramaDocsComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    nombre_doc: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
    numero_documento: new UntypedFormControl('', []),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id_org: number = -1;
  public file: any;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _rrhhService: RrhhService,
    private _router: Router,
    private _route: ActivatedRoute,
    ) { 
    /*modificar*/
    this._route.queryParams.subscribe(
      params => {
        this.id_org = +params['id_org'];
        if(this.id_org){
          this.form.patchValue({ numero_depa: this.id_org, nombre_doc: params['nombre']});
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    //accion
    let url_list = this._router.url.split("/");
    this.accion = url_list[4].split("?")[0];
  }

  public postRegistrarDocumentosOrg():any{
    const formData = new FormData();
    formData.set('nombre_doc', this.form.value.nombre_doc);
    formData.set('pdf', this.file);
    this.isLoading = true;
    this._rrhhService.postRegistrarDocumentosOrg(formData).subscribe(
      response => {
        this.respuesta_org(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public onchangeArchivo_org(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      var caso = this.sizeFile_org(event);
      if (caso != 3) {
        if(caso == 1){
          this.file = file;
        }
        console.log(this.file);
        if(caso == 2){
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Maximo tamaño del PDF 10MB.', icon:'error'}});
        }
      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato.', icon:'error'}});

      }
    }
  }

  public sizeFile_org(event: any){
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      //file.type.startsWith('application/pdf')
      if(file.type.startsWith('application/pdf')){
        if(event.target.files[0].size <= 10485760){
          console.log('Maximo tamaño de imagen 10MB');
          return 1;
        }else{
          return 2;
        }
      }else{
        return 3;
      }
    }else{
      return 4;
    }
  }

  /*fin modificar*/
  public postModificarDocumentosOrg():any{
    const formData = new FormData();
    formData.set('nombre_doc', this.form.value.nombre_doc);
    if(this.file){
      formData.set('pdf', this.file);
    }   
    formData.set('numero_documento', this.id_org.toString());
    this.isLoading = true;
    this._rrhhService.postModificarDocumentosOrg(formData).subscribe(
      response => {
        this.respuesta_org(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }
  /*modificar*/

  public respuesta_org(response: any){
    this.isLoading = false;
    if(response.estado == 200){
      this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
      this._router.navigate(['rrhh/gestor-de-puestos-de-trabajo/gestor-organigrama-docs']);
    }else{
      this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error con el servicio', mensaje: response.mensaje, icon:'error'}});
    }
  }

  

}
