//General
import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { SesionService } from 'src/app/services/sesion.service';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { CalendarioService } from 'src/app/services/calendario.service';
import { ModalCalForm1Component } from 'src/app/pages/calendario/modal-cal-form1/modal-cal-form1.component';
import { ModalCalForm2Component } from 'src/app/pages/calendario/modal-cal-form2/modal-cal-form2.component';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios



import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, FullCalendarComponent } from '@fullcalendar/angular';
//import { INITIAL_EVENTS, createEventId } from './event-utils';

import * as $ from 'jquery';
//import { setTimeout } from 'timers';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit {

  calendarVisible = true;
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'today prev next dayGridMonth dayGridDay timeGridWeek timeGridDay listWeek',
      center: 'title',
      right: ''
    },
    initialView: 'dayGridMonth',
    //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    events: [],
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventDrop: this.eventDrop.bind(this),
  };
  currentEvents: EventApi[] = [];

  public form2: UntypedFormGroup = this.fb.group({
    tipo: new UntypedFormControl(2, []), //caso base 1, el 2 es para el gestor
    filas: new UntypedFormControl(10, []),
    numero_mes: new UntypedFormControl(12, []),
    numero_configuracion: new UntypedFormControl(1, []), //esctructura o individual
    calendario: new UntypedFormControl(1, []),
  });

  public data: any;
  public days: any = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  public calendarApi: any;
  public primera_vez: number = 0;
  public candado: boolean = true;
  public t: any;
  public isLoading: boolean = true;
  public tipo_general: number = -1;


  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _CalendarioService: CalendarioService,
    private _router: Router,
    private _route: ActivatedRoute,
    //private selectInfo: DateSelectArg
  ) {
    this.form2.patchValue({
      numero_configuracion: 1
    });
    this.visual();

    this._route.queryParams.subscribe(
      params => {
        
        if(params['tipo']){
          this.tipo_general = +params['tipo'];
          this.form2.patchValue([{
            calendario: this.tipo_general
          }]);
        }
            
            
          

        if(this.t != +params['t']){
          this.t = +params['t'];
          console.log(this.t);
          this.visual();
        }

    });


   }

   

  ngOnInit(): void {
    //this.calendarApi = this.calendarComponent.getApi();
  }

  eventDrop(clickInfo: EventClickArg){
    console.log(clickInfo.event._def);
    console.log(clickInfo.event);
    console.log(clickInfo.event._instance.range);
    const date = new Date(clickInfo.event._instance.range.start);
    let isoString = date.toISOString();
    let formattedDate = isoString.split("T")[0];
    console.log(formattedDate);
    this.modalCal(this.form2.value.numero_configuracion, +clickInfo.event._def.publicId, 'modificar', {modificar_control: true, fecha_inicio: formattedDate});
  }

  public modalCal(tipo: number, id: number, accion: string, payload: any){
    console.log(tipo, id, accion);
    var data = {tipo: tipo, id: id, accion: accion, payload};
    const dialogRef = this._dialog.open(ModalCalForm1Component, {
      data: data
    });
  }

  public getConsultarCalendarioBusqueda(){
    this._router.navigate(['/calendario'], 
          { queryParams:
            {t: new Date().getSeconds(), tipo: this.form2.value.calendario.toString() }
          });

    this.isLoading = true;
    console.log(this.form2.value.numero_mes);
    this._CalendarioService.getConsultarCalendarioBusqueda(this.form2.value.tipo, this.form2.value.filas, this.form2.value.numero_mes, this.form2.value.numero_configuracion, this.tipo_general).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.data = response.data;
          this.configurarData(this.data, this.calendarApi?.currentData?.currentViewType);
        }
      },
      error => {console.log(<any>error);
      });
  }

  public formarDiasDeLaSemana(data: any){

    var k = [];
    var d = data?.semanal_dias?.split('/');
    //console.log(d);
    if(d != undefined){
      if(d?.indexOf('Mon') != '-1' || d?.indexOf('L') != '-1'){
        k.push(1);
      }
      if(d?.indexOf('Tue') != '-1' || d?.indexOf('M') != '-1'){
        k.push(2);
      }
      if(d?.indexOf('Wed') != '-1' || d?.indexOf('Mi') != '-1'){
        k.push(3);
      }
      if(d?.indexOf('Thu') != '-1' || d?.indexOf('J') != '-1'){
        k.push(4);
      }
      if(d?.indexOf('Fri') != '-1' || d?.indexOf('V') != '-1'){
        k.push(5);
      }
      if(d?.indexOf('Sat') != '-1' || d?.indexOf('S') != '-1'){
        k.push(6);
      }
      if(d?.indexOf('Sun') != '-1' || d?.indexOf('D') != '-1'){
        k.push(0);
      }
    }
    //console.log(k);
    return k;
    //public days: any = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];
  }



  handleDateSelect(selectInfo: DateSelectArg) {
    /*const title = prompt('Please enter a new title for your event');
    
    const calendarApi = selectInfo.view.calendar;
    console.log(selectInfo);

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        title,
        startRecur: selectInfo.startStr,
        endRecur: "2022-12-05",
        startTime: '5:00',
        endTime: '7:00',
      });
      console.log(calendarApi);
    }*/
    this.modalCal(1, -1, 'registrar', {
      fecha_inicio: selectInfo.startStr
    });
  }

  handleEventClick(clickInfo: EventClickArg) {
    console.log(clickInfo.event._def.publicId);
    this.modalCal(this.form2.value.numero_configuracion, +clickInfo.event._def.publicId, 'modificar', {modificar_control: true});
    /*if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }*/
    
  }


  
  
  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
    this.calendarApi = this.calendarComponent.getApi();
    let currentDate = this.calendarApi.view.currentStart;
    console.log(this.calendarApi); // result: current calendar start date
    console.log(this.primera_vez);
    
    if(this.primera_vez <= 0){
      this.visual();
      if(this.calendarApi?.currentData?.currentViewType == "dayGridMonth"){
        this.configurarData(this.data, "dayGridMonth");
      }else{
        this.configurarData(this.data, this.calendarApi?.currentData?.currentViewType);
      }
      this.primera_vez++;
    }else if(this.primera_vez == 1 && this.candado){
      this.visual();
      this.candado = false;
      if(this.calendarApi?.currentData?.currentViewType == "dayGridMonth"){
        this.configurarData(this.data, "dayGridMonth");
      }else{
        this.configurarData(this.data, this.calendarApi?.currentData?.currentViewType);
      }
      setTimeout(()=>{                           // <<<---using ()=> syntax
        //this.messageSuccess = false;
        console.log('a');
        this.candado = true;
      }, 2000);
      //this.candado = true;
    }
    
    
  }


  /*public configurarData(data: any, caso: string){

    var a: any = [];
    for(let i = 0; i < data?.length; i++){
      
      if(data[i].frecuencia == 2){ //frecuencia dos
        var auxDaysWeek = [];
        auxDaysWeek = this.formarDiasDeLaSemana(this.data[i]);
        console.log(auxDaysWeek.length);
        if(auxDaysWeek.length > 0){
          a.push({
            id: this.data[i].id_configuracion_calendario_pk,
            title: this.data[i].nombre_actividad, 
            start: this.data[i].fecha_inicio,
            end: this.data[i].fecha_fin,
            daysOfWeek: auxDaysWeek,
            startTime: this.data[i].hora_inicio,
            endTime: this.data[i].hora_fin,
          });
        }
      }else if(data[i].frecuencia == 1){ //frecuencia uno
        //console.log('aqui');
        //console.log(this.data[i]);
        var t_startTime: any
        var t_endTime: any
        if(caso != "dayGridMonth"){
          t_startTime = this.data[i].hora_inicio;
          t_endTime = this.data[i].hora_fin;
          
        }
       
        a.push({
          id: this.data[i].id_configuracion_calendario_pk,
          title: this.data[i].nombre_actividad, 
          start: this.data[i].fecha_inicio,
          end: this.data[i].fecha_fin,
          startTime: t_startTime,
          endTime: t_endTime
        });
      }

      this.calendarOptions.events = a; 
      //console.log(this.currentEvents);
      //console.log(i);
    }

  }*/


  public configurarData(data: any, caso: string){

    var a: any = [];
    for(let i = 0; i < data?.length; i++){

      let nombre = '';
      if(this.data[i]?.nombre_actividad){
        nombre = this.data[i]?.nombre_actividad;
      }else{
        nombre = this.data[i]?.configuracion_calendario?.nombre_actividad;
      }


      a.push({
        id: this.data[i]?.id_control_fecha_pk,
        id_fk: this.data[i]?.id_configuracion_calendario_fk,
        title: nombre, 
        start: this.data[i].fecha_inicio +'T'+this.data[i].hora_inicio,
        end: this.data[i].fecha_inicio +'T'+this.data[i].hora_fin,
      });
      
      

      
      //console.log(this.currentEvents);
      //console.log(i);
    }

    this.calendarOptions.events = a; 

  }


  public visual(){
    console.log('visual');
    var num = 1;
    var d = this.calendarApi?.currentData?.viewTitle?.split(' ');
    console.log(d);
    if(d?.indexOf('January') != '-1'){
      this.form2.patchValue({ numero_mes: 1});
    }else if(d?.indexOf('February') != '-1'){
      this.form2.patchValue({ numero_mes: 1});
    }else if(d?.indexOf('March') != '-1'){
      this.form2.patchValue({ numero_mes: 3});

    }else if(d?.indexOf('April') != '-1'){
      this.form2.patchValue({ numero_mes: 4});

    }else if(d?.indexOf('May') != '-1'){
      this.form2.patchValue({ numero_mes: 5});

    }else if(d?.indexOf('June') != '-1'){
      this.form2.patchValue({ numero_mes: 6});

    }else if(d?.indexOf('July') != '-1'){
      this.form2.patchValue({ numero_mes: 7});

    }else if(d?.indexOf('August') != '-1'){
      this.form2.patchValue({ numero_mes: 8});

    }else if(d?.indexOf('September') != '-1'){
      this.form2.patchValue({ numero_mes: 9});

    }else if(d?.indexOf('Octuber') != '-1'){
      this.form2.patchValue({ numero_mes: 10});

    }else if(d?.indexOf('November') != '-1'){
      this.form2.patchValue({ numero_mes: 11});

    }else if(d?.indexOf('December') != '-1'){
      this.form2.patchValue({ numero_mes: 12});

    }
    this.getConsultarCalendarioBusqueda();




  }








}
