//General
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios


@Component({
  selector: 'app-contenedor-expediente-personal',
  templateUrl: './contenedor-expediente-personal.component.html',
  styleUrls: ['./contenedor-expediente-personal.component.scss'],
  providers: [ RrhhService ]
})
export class ContenedorExpedientePersonalComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: FormGroup = this.fb.group({
    nombre: new FormControl('', [Validators.required]),
    apellidop: new FormControl('', [Validators.required]),

  });
  //All
  public file: any;
  public accion: string = '';
  public isLoading: boolean = false;
  public id_exp: any;


  public selectedIndex: number = 0;
  public laravelapiStorage = this.rrhhService.getLaravelApiStorage();
  public laravelapi = this.rrhhService.getLaravelApi();
  public data1: any;
  public data2: any;
  public data3: any;
  public doc1: any;
  public doc2: any;
  public doc3: any;
  public t: any;
  
  constructor(
    private fb: FormBuilder,
    public  dialog: MatDialog,
    private rrhhService: RrhhService,
    private router: Router,
    private route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
  ) { 

  }

  ngOnInit(): void {
    
    /*modificar*/
    this.route.queryParams.subscribe(
      params => {
        this.id_exp = +params['id_exp'];

        console.log('ID: ' + this.id_exp);
        if(this.id_exp){
          this.getConsultarDocumentosTipoEmpleado(1);
          this.getConsultarDocumentosTipoEmpleado(2);
          this.getConsultarDocumentosTipoEmpleado(3);

          
         
        }
      });
    /*fin modificar*/
  }

  public siguienteEvento(newItem: any) {
    console.log(newItem);
    this.selectedIndex = newItem;
  }

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }

  public getConsultarDocumentosTipoEmpleado(id: number){
    console.log('asjsajsdhas');
    this.isLoading = true;
    this.rrhhService.getConsultarDocumentosTipoEmpleado(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          if(id == 1){
            this.data1 = response.data;
            this.getConsultaDocumentosExpediente(1, this.id_exp);
          }else if(id == 2){
            this.data2 = response.data;
            this.getConsultaDocumentosExpediente(2, this.id_exp);
          }else if(id == 3){
            this.data3 = response.data;
            this.getConsultaDocumentosExpediente(3, this.id_exp);
          }
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }


  public total1 = 0;
  public total2 = 0;
  public total3 = 0;
  public getConsultaDocumentosExpediente(id: number, id2: number){
    console.log('asjsajsdhas');
    this.isLoading = true;
    this.rrhhService.getConsultaDocumentosExpediente(id, id2).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          if(id == 1){
            this.doc1 = response.data;
            this.total1 = 0;
            for(let u = 0; u < this.doc1.length; u++){
              for(let m = 0; m < this.data1.length; m++){
                if(this.data1[m].id_tipo_documento_pk == this.doc1[u].id_tipo_pk){
                  this.data1[m].ruta_relativa = this.doc1[u].ruta_relativa;
                  this.total1 = this.total1 + 1;
                }
              }
            }
            
          }else if(id == 2){
            this.doc2 = response.data;
            this.total2 = 0;
            for(let u = 0; u < this.doc2.length; u++){
              for(let m = 0; m < this.data2.length; m++){
                if(this.data2[m].id_tipo_documento_pk == this.doc2[u].id_tipo_pk){
                  this.data2[m].ruta_relativa = this.doc2[u].ruta_relativa;
                  this.data2[m].texto_nombre = this.doc2[u].texto_nombre;
                  this.total2 = this.total2 + 1;
                }
              }
            }
            this.data2 = this.invertLastWithPenultimateById(this.data2, 22, 23);
            console.log(this.data2);
          }else if(id == 3){
            this.doc3 = response.data;
            this.total3 = 0;
            for(let u = 0; u < this.doc3.length; u++){
              for(let m = 0; m < this.data3.length; m++){
                if(this.data3[m].id_tipo_documento_pk == this.doc3[u].id_tipo_pk){
                  this.data3[m].ruta_relativa = this.doc3[u].ruta_relativa;
                  this.data3[m].texto_nombre = this.doc3[u].texto_nombre;
                  this.total3 = this.total3 + 1;
                }
              }
            }
            //this.data2 = this.invertLastWithPenultimateById(this.data2, 22, 23);
            console.log(this.data3);
          }
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }


  public invertLastWithPenultimateById(jsonArray, id1, id2) {
    const index1 = jsonArray.findIndex(item => item.id_tipo_documento_pk === id1);
    const index2 = jsonArray.findIndex(item => item.id_tipo_documento_pk === id2);
  
    if (index1 === -1 || index2 === -1) {
      // Alguno de los elementos no se encontró en el array
      return jsonArray;
    }
  
    // Intercambiar los elementos
    [jsonArray[index1], jsonArray[index2]] = [jsonArray[index2], jsonArray[index1]];
  
    return jsonArray;
  }


  public getRegistrarModificarDocumentoExpedienteEmpleado(event: any, tipo: number, t: number, d: any): any{

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      console.log(file.type);
      var ext = event.target.files[0].name.split('.')[1];
      console.log(ext);
      if (file.type.startsWith('application/pdf')) {//text/xml
        const formData = new FormData();
        formData.set('documento', file);
        formData.set('numero_tipo_documento',  tipo.toString());
        formData.set('numero_empleado',  this.id_exp.toString());
        formData.set('tipo',  t.toString());
        if(tipo == 24 || tipo == 25 || tipo == 28 || tipo == 29){
          formData.set('texto_nombre',  d.texto_nombre);
        } 


        this.isLoading = true;
        this.rrhhService.getRegistrarModificarDocumentoExpedienteEmpleado(formData).subscribe(
          response => {console.log(response);
            this.isLoading = false;
            if(response.estado == 200){
              if(t == 1){
                this.getConsultaDocumentosExpediente(1, this.id_exp);
              }else if(t == 2){
                this.getConsultaDocumentosExpediente(2, this.id_exp);
              }else if(t == 3){
                this.getConsultaDocumentosExpediente(3, this.id_exp);
              }
              this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            }else{
              this.dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
            }
            
          },
          error => {console.log(<any>error);
            this.dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
            this.isLoading = false;
        });


      }else{
        this.dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato', icon:'error'}});
      }
    }  
  }




}
