


<app-datos-generales></app-datos-generales>
    <div class="container-fluid">
        <mat-card >
            <mat-card-content>
                <div class="spinner" *ngIf="isLoading">
                    <mat-spinner color="accent"></mat-spinner>
                </div>
                <form novalidate [formGroup]="form" autocomplete="off">
                    <span><strong>EXPLORACIÓN FÍSICA DE INGRESO</strong></span>

                <br>
                <span class="mt-5" *ngIf="accion == 'modificar'"><strong>DETALLE DEL EVENTO MÉDICO</strong></span>
                <div class="row mt-3" >
                    
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>ID del evento médico </mat-label>
                            <input type="text"  matInput  readonly="true" [value]="'GTTI-EM-'+id_eve">
                        </mat-form-field>
                    </div>
                    <!--<div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Fecha del evento </mat-label>
                            <input type="text"  matInput  readonly="true" value="{{form.value.created_at | date:'shortDate'}}">
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Hora del evento </mat-label>
                            <input type="text"  matInput  readonly="true" value="{{form.value.created_at | date:'mediumTime'}}">
                        </mat-form-field>
                    </div>-->
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Fecha y hora de apertura de evento</mat-label>
                            <input type="datetime-local" matInput  formControlName="fecha_evento_incluida">
                            <mat-error *ngIf="form.controls['fecha_evento_incluida'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>



                <!--fecha_evento_incluida-->
                <!--fecha_evento_incluida-->

                <br>
                <br>
                <br>                


                <span><strong>DIAGNÓSTICO</strong></span>
                <div class="row mt-3">
                    <div class="col">
                        ANTROPOMETRÍA
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Peso Kg</mat-label>
                            <input type="text" #peso matInput  formControlName="peso">
                            <mat-error *ngIf="form.controls['peso'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['peso'].hasError('pattern')">
                                Formato inválido: Solo 3 enteros y 2 decimales
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Talla m </mat-label>
                            <input type="text" #talla matInput  formControlName="talla">
                            <mat-error *ngIf="form.controls['talla'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['talla'].hasError('pattern')">
                                Formato inválido: Solo 3 enteros y 2 decimales
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>IMC kg/m^2 </mat-label>
                            <input type="text" #presion_art matInput  formControlName="presion_art">
                            <mat-error *ngIf="form.controls['presion_art'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['presion_art'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>-->
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>IMC Kg/m^2 </mat-label>
                            <input readonly="true" type="text" matInput [value]="(form.value.peso / (form.value.talla * form.value.talla))?.toFixed(4)">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        SIGNOS VITALES
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Presión arterial mm Hg </mat-label>
                            <input type="text" #presion_art_dos matInput  formControlName="presion_art_dos">
                            <mat-error *ngIf="form.controls['presion_art_dos'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['presion_art_dos'].hasError('pattern')">
                                Formato inválido: 3 enteros, diagonal y 3 enteros
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>O2SAT% </mat-label>
                            <input type="text" #sat matInput  formControlName="sat">
                            <mat-error *ngIf="form.controls['sat'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['sat'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <!--FRECUENCIA CARDIACA-->
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Frecuencia cardiaca LPM</mat-label>
                            <input type="text" #cardiaca matInput  formControlName="cardiaca">
                            <mat-error *ngIf="form.controls['cardiaca'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['cardiaca'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Frecuencia respiratoria RPM </mat-label>
                            <input type="text" #frecuencia matInput  formControlName="frecuencia">
                            <mat-error *ngIf="form.controls['frecuencia'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['frecuencia'].hasError('pattern')">
                                Formato inválido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Temperatura °C </mat-label>
                            <input type="text" #temp matInput  formControlName="temp">
                            <mat-error *ngIf="form.controls['temp'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="form.controls['temp'].hasError('pattern')">
                                Formato inválido: Solo 2 enteros y 1 decimal
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>


               
                <div class="row">
                    <span class="mt-3"><strong>MOTIVOS DE ATENCIÓN</strong></span>
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>MOTIVOS DE ATENCIÓN </mat-label>
                        <textarea rows="3" type="text"  matInput formControlName="motivos" ></textarea>
                    </mat-form-field>
                </div>
                <div class="row">
                    <span class="mt-3"><strong>RESUMEN DE INTERROGATORIO POR APARATOS Y SISTEMAS Y EXPLORACIÓN FÍSICA</strong></span>
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>RESUMEN DE INTERROGATORIO POR APARATOS Y SISTEMAS Y EXPLORACIÓN FÍSICA< </mat-label>
                        <textarea rows="3" type="text"  matInput formControlName="resumen" ></textarea>
                    </mat-form-field>
                </div>
                <div class="row">
                    <span class="mt-3"><strong>DIAGNÓSTICO</strong></span>
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>DIAGNÓSTICO </mat-label>
                        <textarea rows="3" type="text"  matInput  formControlName="diagnostico"></textarea>
                    </mat-form-field>
                </div>
                

                

                

                <div class="row">
                    <div class="col">
                        <mat-checkbox formControlName="vincular_expediente"> Vincular a expediente médico </mat-checkbox>

                        
                    </div>
                    <div class="col">
                        <div class="d-flex justify-content-end">
                            <mat-form-field appearance="fill"  class="input-full-w">
                                <mat-label>Fecha y hora de evento concluido</mat-label>
                                <input type="datetime-local" matInput  formControlName="fecha_evento_cierre">
                                <mat-error *ngIf="form.controls['fecha_evento_cierre'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    
                   
                </div>
                




                
                </form>

                <div class="mt-2 d-flex justify-content-center gap-2">


                

                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos/gestor-eyt']" [queryParams]="{id: id}" >
                        Regresar
                    </button>
    
                    <form novalidate [formGroup]="form" (ngSubmit)="postRegistroEventoMedico()" *ngIf="accion == 'registrar'">
                        <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                            Guardar
                        </button>
                    </form>

                    <form novalidate [formGroup]="form" (ngSubmit)="postModificarEventoMedico(1)" *ngIf="accion == 'modificar' && form.value.numero_boton == 1">
                        <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                            Guardar_
                        </button>
                    </form>

                    <form novalidate [formGroup]="form" (ngSubmit)="postModificarEventoMedico(3)" *ngIf="accion == 'modificar' && form.value.numero_boton == 1">
                        <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                            Crear tratamiento
                        </button>
                    </form>

                    <form novalidate [formGroup]="form" (ngSubmit)="postModificarEventoMedico(2)" *ngIf="accion == 'modificar' && form.value.numero_boton != 2 && val()">
                        <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                            Concluir evento médico
                        </button>
                    </form>
    
    
                </div>


            </mat-card-content>
        </mat-card>
    </div>
    
    
