<div class="body-error-template">
    <div class="container">
        <div class="row">
            <div class="card-container">
                <mat-card class="example-card">
                    <div class="error-template">
                        <h1>Oops!</h1>
                        <h2>401 Sin Privilegios</h2>
                        <div class="error-details">
                            Lo sentimos, pero tu usuario no tiene acceso a esta página o recurso. <br>¿Piensas que
                            deberias
                            tener acceso?, consulta al administrador del sistema.
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<!-- <div class="body-error-template">

    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="error-template">
                    <h1>
                        Oops!</h1>
                    <h2>
                        404 Not Found</h2>
                    <div class="error-details">
                        Sorry, an error has occured, Requested page not found!
                    </div>
                    <div class="error-actions">
                        <a href="http://www.jquery2dotnet.com" class="btn btn-primary btn-lg"><span
                                class="glyphicon glyphicon-home"></span>
                            Take Me Home </a><a href="http://www.jquery2dotnet.com" class="btn btn-default btn-lg"><span
                                class="glyphicon glyphicon-envelope"></span> Contact Support </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->