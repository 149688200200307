<br>
<h3>Detalles Documentos de Expediente</h3>
<form autocomplete="off" [formGroup]="formulario">
    <mat-form-field appearance="fill" class="bt col-md-4">
        <mat-label>Tipo de Documento</mat-label>
        <mat-select #tipoDocto formControlName="tipoDocumento" disabled>
            <ng-container *ngFor="let documento of tipoDocumentos">
                <mat-option value="{{documento.idParametro}}"
                    *ngIf="documento.idParametro != '41' && documento.idParametro != '45' && documento.idParametro != '46' && documento.idParametro != '48'">
                    {{documento.valor}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
    &nbsp;
    <ng-container
        *ngIf="tipoDocto.value=='51' || tipoDocto.value=='87' || tipoDocto.value=='88' || tipoDocto.value=='89'">
        <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Nombre del Documento</mat-label>
            <input matInput type="text" formControlName="nombre" disabled>
        </mat-form-field>
        &nbsp;
    </ng-container>
    <mat-form-field appearance="fill" class="bt col-md-2">
        <mat-label>Adjuntar documento</mat-label>
        <input matInput placeholder="Todos los formatos" formControlName="archivo" disabled>
        <button mat-icon-button type="button" matSuffix disabled>
            <mat-icon>attach_file</mat-icon>
        </button>
        <input type="file" #file class="hide" />
    </mat-form-field>
    &nbsp;
    <button mat-flat-button color="primary" class="flat" type="button" disabled>Adjuntar</button>
</form>


<div class="table-container">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner color="accent"></mat-spinner>
    </div>
    <mat-list>
        <ng-container *ngFor="let file of files; let i = index">
            <ng-container>
                <mat-divider *ngIf="i>0"></mat-divider>
                <mat-list-item *ngIf="file.docTipoCatalogo.length>0">
                    <mat-icon mat-list-icon>insert_drive_file</mat-icon>

                    <div mat-line *ngIf="!file?.desTipoCatalogo.length>0 || file?.desTipoCatalogo=='null' ">
                        {{getTipoById____(file?.idTipoDocumento)}}</div>
                    <div mat-line *ngIf="file?.desTipoCatalogo.length>0 && file?.desTipoCatalogo!='null'">
                        {{getTipoById____(file?.idTipoDocumento)}} {{file?.desTipoCatalogo}}</div>
                    <div mat-line>
                        &nbsp;
                        <a mat-button color="primary" class="flat"
                            href="{{hostapi}}/{{file.docTipoCatalogo}}" target="_blank" rel="noopener"
                            download="{{getTipoById____(file.idTipoDocumento)}}">
                            <mat-icon>arrow_circle_down</mat-icon>
                            Descargar
                        </a>
                        <button mat-button color="accent" class="flat"
                            *ngIf="![41,45,46,48].includes(file?.idTipoDocumento)" disabled>
                            <mat-icon>delete_putline</mat-icon>
                            Eliminar
                        </button>
                    </div>
                    <div mat-line></div>
                </mat-list-item>
            </ng-container>
        </ng-container>
    </mat-list>
</div>

<br><br>
<div class="text-align-center">
    <button type="button" class="flat" color="primary" mat-flat-button [routerLink]="['/beneficiarias']"
        style="margin-right: 15px;">Cancelar</button>
</div>