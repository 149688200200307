import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-module-selector',
  templateUrl: './module-selector.component.html',
  styleUrls: ['./module-selector.component.scss']
})
export class ModuleSelectorComponent implements OnInit {

  modules = [
    {icon:"icono-perfil-beneficiaria.png",nombre:"Beneficiarias",href:"/beneficiarias"},
    {icon:"icono-evaluacion-beneficiaria.png",nombre:"Evaluaciones",href:"/evaluaciones"},
    {icon:"icono-centro-informacion-institucional.png",nombre:"Centro de información institucional",href:"/centro-informacion-institucional"},
    {icon:"icono-eventosdevida.png",nombre:"Eventos de vida",href:"/eventos-de-vida"},
    {icon:"icono-procuraciondefondos.png",nombre:"Procuración de fondos",href:"#"},
    {icon:"icono-recursos-humanos.png",nombre:"Recursos humanos",href:"#"},
    {icon:"icono-administradordeusuarios.png",nombre:"Gestor de usuarios",href:"/administrador/usuarios"},
    {icon:"icono-medico.png",nombre:"Médico", href:"#"},
    {icon:"cale.jpeg",nombre:"Calendario", href:"#"},
  ]
  constructor(private router:Router) { }

  ngOnInit(): void {
    // This is intentional
  }

  go2module(route: any){
    if(route != '#'){
      this.router.navigate([route]);
    }
  }

}
