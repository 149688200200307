//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { MedicoService } from 'src/app/services/medico.service';
import { SesionService } from 'src/app/services/sesion.service';
import { RrhhService } from 'src/app/services/rrhh.service';


@Component({
  selector: 'app-acciones-contactos-area-medica',
  templateUrl: './acciones-contactos-area-medica.component.html',
  styleUrls: ['./acciones-contactos-area-medica.component.scss'],
  providers: [ MedicoService ]
})
export class AccionesContactosAreaMedicaComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    numero_dir: new UntypedFormControl(-1, []),
    numero_boton: new UntypedFormControl(1, []),

    razon_social: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
    numero_clasificacion: new UntypedFormControl('', [Validators.required]),
    numero_especialidad: new UntypedFormControl(1, []),
    
    otros_especialidad: new UntypedFormControl('', [Validators.maxLength(250)]), //nuevo agregado

    cedula_profesional: new UntypedFormControl('', [Validators.maxLength(250)]),
    honorarios: new UntypedFormControl(1, []),
    honorarios_si: new UntypedFormControl('', [Validators.maxLength(100)]),
    telefono: new UntypedFormControl('', [Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
    celular: new UntypedFormControl('', [Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
    consultorio: new UntypedFormControl('', [Validators.maxLength(250)]),



    ate_lu: new UntypedFormControl('', []),
    ate_mar: new UntypedFormControl('', []),
    ate_mier: new UntypedFormControl('', []),
    ate_jue: new UntypedFormControl('', []),
    ate_vier: new UntypedFormControl('', []),
    ate_sa: new UntypedFormControl('', []),
    ate_do: new UntypedFormControl('', []),
    ate_observacion: new UntypedFormControl('', [Validators.maxLength(250)]),




    correo: new UntypedFormControl('', [Validators.email]),
    hora_inicio: new UntypedFormControl('', []),
    hora_fin: new UntypedFormControl('', []),
    observacion: new UntypedFormControl('', [Validators.maxLength(2000)]),


    direccion: new UntypedFormControl('', [Validators.maxLength(250)]),
    calle: new UntypedFormControl('', [Validators.maxLength(250)]),
    colonia: new UntypedFormControl('', [Validators.maxLength(250)]),
    municipio: new UntypedFormControl('', [Validators.maxLength(250)]),
    estado: new UntypedFormControl('', [Validators.maxLength(250)]),

    direccion_dos: new UntypedFormControl('', [Validators.maxLength(250)]),
    calle_dos: new UntypedFormControl('', [Validators.maxLength(250)]),
    colonia_dos: new UntypedFormControl('', [Validators.maxLength(250)]),
    municipio_dos: new UntypedFormControl('', [Validators.maxLength(250)]),
    estado_dos: new UntypedFormControl('', [Validators.maxLength(250)]),

    direccion_tres: new UntypedFormControl('', [Validators.maxLength(250)]),
    calle_tres: new UntypedFormControl('', [Validators.maxLength(250)]),
    colonia_tres: new UntypedFormControl('', [Validators.maxLength(250)]),
    municipio_tres: new UntypedFormControl('', [Validators.maxLength(250)]),
    estado_tres: new UntypedFormControl('', [Validators.maxLength(250)]),


    numero_calificacion: new UntypedFormControl('', [Validators.required]),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id_cam: number = -1;

  public clasificacion: any;
  public especialidad: any;
  public calificacion: any;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _medicoService: MedicoService,
    private _router: Router,
    private _route: ActivatedRoute,  
    private reie_rrhhService: RrhhService,
  ){ 
    this.getConsultaDatosFormMedico();
    this.getDatosPersonalRRHH();
    this.form.markAllAsTouched();
    /*modificar*/
    this._route.queryParams.subscribe(
      params => {
        this.id_cam = +params['id_cam'];
        if(this.id_cam){
          console.log('ID: ' + this.id_cam);
          this.form.patchValue({ numero_depa: this.id_cam})
          this.getConsultaDirMedico(this.id_cam);
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[3].split("?")[0];
    console.log(this.accion);
  }

  /*default*/
  public getConsultaDatosFormMedico(){
    this.isLoading = true;
    this._medicoService.getConsultaDatosFormMedico().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.clasificacion = response.data.clasificacion;
          this.especialidad = response.data.especialidad;
          this.calificacion = response.data.calificacion;
        } 
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }
  /*fin default*/

  /*modificar*/
  public getConsultaDirMedico(id: number){
    this.isLoading = true;
    this._medicoService.getConsultaDirMedico(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.setForm(response.data);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public setForm(data: any){
    this.form.patchValue({
      numero_dir: data?.id_area_medica_pk,
      numero_boton: data?.id_estado_boton_fk,

      razon_social: data?.razon_social,
      numero_clasificacion: data?.id_clasificacion_fk,
      numero_especialidad: data?.id_especialidad_fk,

      otros_especialidad: data?.otros_especialidad,
      
      cedula_profesional: data?.cedula_profesional,
      honorarios: data?.honorarios,
      honorarios_si: data?.honorarios_si,
      telefono: data?.telefono,
      celular: data?.celular,

      consultorio: data?.consultorio,
      correo: data?.correo,
      
      
      observacion: data?.observacion,


      ate_lu: data?.ate_lu,
      ate_mar: data?.ate_mar,
      ate_mier: data?.ate_mier,
      ate_jue: data?.ate_jue,
      ate_vier: data?.ate_vier,
      ate_sa: data?.ate_sa,
      ate_do: data?.ate_do,
      hora_inicio: data?.hora_inicio,
      hora_fin: data?.hora_fin,
      ate_observacion: data?.ate_observacion,


      direccion: data?.direccion,
      calle: data?.calle,
      colonia: data?.colonia,
      municipio: data?.municipio,
      estado: data?.estado,
      direccion_dos: data?.direccion_dos,
      calle_dos: data?.calle_dos,
      colonia_dos: data?.colonia_dos,
      municipio_dos: data?.municipio_dos,
      estado_dos: data?.estado_dos,
      direccion_tres: data?.direccion_tres,
      calle_tres: data?.calle_tres,
      colonia_tres: data?.colonia_tres,
      municipio_tres: data?.municipio_tres,
      estado_tres: data?.estado_tres,




      numero_calificacion: data?.id_calificacion_fk
    });
  }
  /*fin modificar*/

  public convertirNumberAtencionDias(){
    this.form.patchValue({
      ate_lu: +this.form.value.ate_lu,
      ate_mar: +this.form.value.ate_mar,
      ate_mier: +this.form.value.ate_mier,
      ate_jue: +this.form.value.ate_jue,
      ate_vier: +this.form.value.ate_vier,
      ate_sa: +this.form.value.ate_sa,
      ate_do: +this.form.value.ate_do,
    });
    
  }

  public postRegistroDirMedico(){
    console.log(this.form.value);
    if(this.form.value.cedula_profesional == ''){
      this.form.patchValue({ cedula_profesional: '-'});
    }
    this.convertirNumberAtencionDias();
    this.isLoading = true;
    this._medicoService.postRegistroDirMedico(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this._router.navigate(['medico/gestor-contactos-area-medica/modificar'], 
            { queryParams:
              {id_cam: response.data.id_area_medica_pk}
            });
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postModificarDirMedico(){
    console.log(this.form.value);
    if(this.form.value.cedula_profesional == ''){
      this.form.patchValue({ cedula_profesional: '-'});
    }
    this.convertirNumberAtencionDias();
    this.isLoading = true;
    this._medicoService.postModificarDirMedico(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this._router.navigate(['medico/gestor-contactos-area-medica']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  /*operaciones*/
  public guardar(){
    console.log(this.form.value);
    this.postRegistroDirMedico();
  }

  public modificar(){
    console.log(this.form.value);
    this.postModificarDirMedico();
  }
  /*fin operaciones*/










  public estados_republica: any = [];
  public getDatosPersonalRRHH(){
    this.isLoading = true;
    this.reie_rrhhService.getDatosPersonalRRHH().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.estados_republica = response.data.estados_republica;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }



}
