<div [ngClass]="{ 'card-container': ficha == false }">
    <div class="table-container">
     
        <mat-card class="example-card">
            <mat-card-header *ngIf="!ficha">
                <mat-card-title>
                    <ng-container *ngIf="!esFichaTecnica">
                        Detalles del Expediente de la Beneficiaria
                    </ng-container>
                    <ng-container *ngIf="esFichaTecnica">
                        Detalles De La Ficha Técnica
                    </ng-container>
                </mat-card-title>
                <mat-card-subtitle>
                    <ng-container *ngIf="esFichaTecnica">
                        Información General de la Beneficiaria
                    </ng-container>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
               <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div class="bt col-md-12" *ngIf="selected.value>0 || esFichaTecnica">
                <div class="bt col-md-2 image no-padding" *ngIf="!beneficiaria?.foto">
                </div>
                <div class="bt col-md-2 image no-padding" *ngIf="beneficiaria?.foto" style="background-image: url({{hostapi}}/{{beneficiaria?.foto}});">
                </div>
                <div class="bt col-md-10">
                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Nombre Completo:
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.nombreCompleto}}
                        </div>
                    </div>
                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            CURP:
                        </div>
                        <div class="bt col-md-6 content">
                            {{(beneficiaria?.idCurp ? beneficiaria?.idCurp : ' - ') | uppercase }}
                        </div>
                    </div>
                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Fecha de Nacimiento:
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.fechaNacimiento}}
                        </div>
                    </div>
                    
                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Edad Actual:
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.edadActual}}
                        </div>
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Canalizada Por
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.canalizadaPor}}
                        </div>
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Motivo de Ingreso
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.motivoDeIngreso}}
                        </div>
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Fecha de Ingreso
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.fechaIngreso}}
                        </div>
                    </div>
                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Edad al Ingreso
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.edadIngreso}}
                        </div>
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Tipo de Discapacidad
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.tipoDeDiscapacidad}}
                        </div>
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Tiempo de Estancia
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.tiempoDeEstancia}}
                        </div>
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Diagnóstico DN
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.diagnosticoDN}}
                        </div>
                    </div>
                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Diagnóstico DM
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.diagnosticoDM}}
                        </div>
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Condición Relevante
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.condicionRelevante}}
                        </div>
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Tipo de Sangre
                        </div>
                        <div class="bt col-md-6 content">
                            {{infoBeneficiaria?.tipoDeSangre}}
                        </div>
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Estatus
                        </div>
                        <div class="bt col-md-6 content">
                            {{beneficiaria?.estatus}}
                        </div>
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Etapa
                        </div>
                        <div class="bt col-md-6 content">
                            {{beneficiaria?.etapa}}
                        </div>
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Fecha de Egreso
                        </div>
                        <div class="bt col-md-6 content" *ngIf="beneficiaria?.fechaEgreso; else noFechaEgreso">
                            {{beneficiaria?.fechaEgreso | date: 'dd/MM/yyyy'}}
                        </div>
                        <ng-template #noFechaEgreso>
                            <div class="bt col-md-6 content" >
                                -
                            </div>
                        </ng-template>
                        
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Motivo de Egreso
                        </div>
                        <div class="bt col-md-6 content">
                            {{beneficiaria?.motivoEgreso ? beneficiaria?.motivoEgreso:"-"}}
                        </div>
                    </div>

                    <div class="bt col-md-6">
                        <div class="bt col-md-6 label">
                            Lugar de Destino
                        </div>
                        <div class="bt col-md-6 content">
                            {{beneficiaria?.lugarDestino ? beneficiaria?.lugarDestino:"-"}}
                        </div>
                    </div>

                </div>
                <br><br>
            </div>
            
            <mat-tab-group mat-align-tabs="start" (selectedIndexChange)="selected.setValue($event)" *ngIf="!esFichaTecnica">
                <mat-tab label="Alta de Expediente">
                    <app-vista-info-general *ngIf="beneficiaria && selected.value==0" [beneficiaria]="beneficiaria"></app-vista-info-general>
                </mat-tab>
                <mat-tab label="Perfil de Ingreso" [disabled]="!existente">
                    <app-vista-perfil *ngIf="selected.value==1"></app-vista-perfil>
                </mat-tab>
                <mat-tab label="Situación General" [disabled]="!existente">
                    <app-vista-situacion-general *ngIf="selected.value==2"></app-vista-situacion-general>
                </mat-tab>
                <mat-tab label="Documentos" [disabled]="!existente">
                    <app-vista-documentos *ngIf="selected.value==3"></app-vista-documentos>
                </mat-tab>
                <mat-tab label="Egreso" [disabled]="!existente">
                    <app-vista-egreso *ngIf="selected.value==4"></app-vista-egreso>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
        <mat-card-actions style="text-align: center;" *ngIf="esFichaTecnica">
            <button *ngIf="!ficha" mat-stroked-button [routerLink]="['/beneficiarias']" class="flat">Volver</button>
        </mat-card-actions>
    </mat-card>
</div>
</div>