import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { DocumentosService } from 'src/app/services/documentos.service';

@Component({
  selector: 'app-nuevo-documento',
  templateUrl: './nuevo-documento.component.html',
  styleUrls: ['./nuevo-documento.component.scss']
})
export class NuevoDocumentoComponent implements OnInit {
  formulario:UntypedFormGroup=new UntypedFormGroup({
    categoria:new UntypedFormControl("",[Validators.required]),
    subcategoria:new UntypedFormControl("",[Validators.required]),
    tipo_documento:new UntypedFormControl("",[Validators.required]),
    documentoRuta:new UntypedFormControl("",[Validators.required]),
    // documentoRuta:new FormControl("",[]),
    nombre:new UntypedFormControl("",[Validators.required]),
    descripcion:new UntypedFormControl("",[]),
    fecha_alta:new UntypedFormControl("",[Validators.required]),
    documento:new UntypedFormControl("",[]),

  });
  isLoading=false;
  tipo_documentos:any=[];
  fileToUpload:any;
  id=null;
  categorias:any=[];
  subcategorias:any=[];
  tipoDocumentos:any=[];

  constructor(private dialog:MatDialog,private documentos_service:DocumentosService,private router:Router,private activatedRoute:ActivatedRoute) { 
    this.isLoading=true;
    this.documentos_service.getCatalogos().subscribe(result=>{
      let id_categorias = [];
      let id_subcategorias = [];
      let id_tipo_docto = [];
      for(let q=0;q<result.data.catalogo.length;q++){
        this.isLoading=false;
        if(id_categorias.indexOf(result.data.catalogo[q].id_categoria)==-1){
          id_categorias.push(result.data.catalogo[q].id_categoria);
          this.categorias.push({"id":result.data.catalogo[q].id_categoria,"nombre":result.data.catalogo[q].categoria});
        }
        if(id_subcategorias.indexOf(result.data.catalogo[q].id_subcategoria)==-1){
          id_subcategorias.push(result.data.catalogo[q].id_subcategoria);
          this.subcategorias.push({"id":result.data.catalogo[q].id_subcategoria,"nombre":result.data.catalogo[q].subcategoria,"id_categoria":result.data.catalogo[q].id_categoria});
        }
        if(id_tipo_docto.indexOf(result.data.catalogo[q].id_tipo_documento)==-1){
          id_tipo_docto.push(result.data.catalogo[q].id_tipo_documento);
          this.tipoDocumentos.push({"id":result.data.catalogo[q].id_tipo_documento,"nombre":result.data.catalogo[q].tipo_documento,"id_subcategoria":result.data.catalogo[q].id_subcategoria});
        }
      }
      // console.log("tipoDocumentos: ", this.tipoDocumentos);
    },()=>{
      this.isLoading=false;
    })
  }

  _send_Form_(){
    if(this.formulario.valid){
      this.isLoading=true;
      // console.log("this.formulario.controls: ", this.formulario.controls);

      this.documentos_service.altaDocumento(this.formulario.controls["documento"].value,this.formulario.getRawValue(),this.id).subscribe(result=>{
        this.isLoading=false;
        if(result.code==200){
          const dialogRef= this.dialog.open(MessageBoxComponent,{
            data:{titulo: "Documento guardado" ,mensaje: "El documento se ha guardado correctamente."}
          });
          dialogRef.afterClosed().subscribe(()=>{
            this.router.navigate(["/centro-informacion-institucional"]);
          });
        }else{
          this.dialog.open(MessageBoxComponent,{
            data:{titulo: "Error" ,mensaje: "El documento no se pudo guardar, por favor intentalo más tarde."}
          })
        }
      },()=>{
        this.isLoading=false;
      });
    }
  }
  documento;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      if(params['id']){
        this.id=params['id'];
        this.documentos_service.buscaDocumentos("",0,0,params['id'],null).subscribe(result=>{
          this.documento = result.data.documentos[0];
          this.formulario.controls["nombre"].setValue(this.documento.nombre);
          this.formulario.controls["descripcion"].setValue(this.documento.descripcion);
          this.formulario.controls["fecha_alta"].setValue(new Date(this.documento.fecha_alta));
          this.formulario.controls["categoria"].setValue(this.documento.categoria);
          this.formulario.controls["subcategoria"].setValue(this.documento.subcategoria);
          this.formulario.controls["tipo_documento"].setValue(this.documento.id_tipo_docto);
          this.formulario.controls["documentoRuta"].setValue(this.documento.descargar);
          
        })
      }
    });
  }
  _handle_File_Input_(nv_files: FileList) {
    this.formulario.controls["documentoRuta"].setValue(nv_files.item(0).name);
    this.formulario.controls["documento"].setValue(nv_files.item(0));
  }

  _change_Select_Document_Type_(nv_optionSelected: any){
    let nv_optSel = nv_optionSelected.value;
    if(nv_optSel == 15){
      this.formulario.controls['documentoRuta'].clearValidators();
      this.formulario.controls['documentoRuta'].updateValueAndValidity();
      
    }else{
      this.formulario.controls['documentoRuta'].setValidators([Validators.required]);
      this.formulario.controls['documentoRuta'].updateValueAndValidity();
    }
  }
}
