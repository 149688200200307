//General
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios


@Component({
  selector: 'app-rrhh-expediente-informacion-empleado',
  templateUrl: './rrhh-expediente-informacion-empleado.component.html',
  styleUrls: ['./rrhh-expediente-informacion-empleado.component.scss'],
  providers: [ RrhhService ]
})
export class RrhhExpedienteInformacionEmpleadoComponent implements OnInit {
  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public reie_form: FormGroup = this.reie_fb.group({
    numero_empleado_reg: new FormControl(-1, []),
    numero_datos_puesto: new FormControl(-1, []),
    numero_de_nomina: new FormControl(-1, []),

    nombre: new FormControl('', [Validators.required]),
    apellidop: new FormControl('', [Validators.required]),
    apellidom: new FormControl('', [Validators.required]),
    fecha_nacimiento: new FormControl('', [Validators.required]),
    numero_estado_republica: new FormControl('', [Validators.required]),
    otro: new FormControl('', []),
    direccion: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    colonia: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    numero_municipio: new FormControl('', [Validators.required]),
    cp: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(5), Validators.maxLength(5)]),
    telefono: new FormControl('', [Validators.pattern("^[0-9]*$"), Validators.maxLength(10)]),
    celular: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(10)]),
    correo: new FormControl('', [Validators.email]),
    rfc: new FormControl('', [Validators.required,  Validators.minLength(13), Validators.maxLength(13)]),
    regimen: new FormControl('', [Validators.required,  Validators.maxLength(60)]),
    curp: new FormControl('', [Validators.required, Validators.maxLength(18)]),
    ss: new FormControl('', [Validators.required, Validators.maxLength(20)]),
    infonativ: new FormControl('', [ Validators.maxLength(20)]),

    numero_reporte: new FormControl('', [Validators.required]),
    id_departamento_fk: new FormControl('', [Validators.required]),
    numero_tipo_puesto: new FormControl('', [Validators.required]),
    fecha_ingreso: new FormControl('', [Validators.required]),
    observaciones: new FormControl('', [Validators.required, Validators.maxLength(500)]),
    jornada_inicio: new FormControl('', [Validators.required]),
    jornada_fin: new FormControl('', [Validators.required]),
    dias_laboral: new FormControl('', []),
    dias_descans: new FormControl('', []),
    observacion_renumerado: new FormControl('', [Validators.required, Validators.maxLength(500)]),
    numero_escolaridad: new FormControl('', [Validators.required]),
    numero_estado_escolaridad: new FormControl('', [Validators.required]),
    escolaridad: new FormControl('', [ Validators.maxLength(50)]),
    salarios: this.reie_fb.array([]),

    tarjeta_beneficiarios: this.reie_fb.array([]),

    numero_estado_civil: new FormControl('1', []),
    numero_regimen: new FormControl('1', []),
    baneficiario: new FormControl('-', [ ]), //Validators.maxLength(100)
    parentesco: new FormControl('-', [ ]), //Validators.maxLength(100)
    rfc_dos: new FormControl('1234567890123', [ ]), //Validators.minLength(13), Validators.maxLength(13)
    porcentaje: new FormControl('2', [ ]), //Validators.maxLength(3)
    direccion_dos: new FormControl('-', []), // Validators.maxLength(200)


    baja_empleado: new FormControl(0, [Validators.required]),
    numero_baja: new FormControl('', [Validators.required]),
    descripcion: new FormControl('-', [Validators.required, Validators.maxLength(500)]),
    ultimo_laborando: new FormControl('-', [Validators.required, Validators.maxLength(500)]),

    generica_propia: new FormControl('', [Validators.required]),

    //auxiliare

    fecha_asignacion_aux: new FormControl('', []),
    salario_aux: new FormControl('', []),
    dl_l: new FormControl(false, []),
    dl_ma: new FormControl(false, []),
    dl_mi: new FormControl(false, []),
    dl_j: new FormControl(false, []),
    dl_v: new FormControl(false, []),
    dl_s: new FormControl(false, []),
    dl_d: new FormControl(false, []),
    dd_l: new FormControl(false, []),
    dd_ma: new FormControl(false, []),
    dd_mi: new FormControl(false, []),
    dd_j: new FormControl(false, []),
    dd_v: new FormControl(false, []),
    dd_s: new FormControl(false, []),
    dd_d: new FormControl(false, []),

    //auxiliares para beneficiarios
    baneficiario_aux: new FormControl('', []),
    parentesco_aux: new FormControl('', []),
    rfc_aux: new FormControl('', []),
    porcentaje_aux: new FormControl('', []),
    direccion_aux: new FormControl('', []),
    id_datos_puesto_fk: new FormControl(-1, []),
    id_tar_bene_pk: new FormControl(-1, []),

   


    














  });
  //All
  public file: any;
  public reie_accion: string = '';
  public reie_isLoading: boolean = false;
  @Input() selectedIndex : number = 0;
  @Output() siguienteEvento = new EventEmitter<string>();
  public reie_departamento: any;
  public reie_puesto: any;
  public id_exp: any;
  public reie_descripcion_puesto: any;
  public municipios: any = [];
  public estados_republica: any;
  public gestor_puestos: any;
  public tipo_puesto: any;
  public escolaridad_all: any;
  public estatus_escolarida: any;
  public estado_civil: any;
  public regimenAll: any = [];
  public departamento: any = [];
  public estado_baja: any;
  public data: any;
  public t: any;
  public laravelapiStorage = this.reie_rrhhService.getLaravelApiStorage();
  public laravelapi = this.reie_rrhhService.getLaravelApi();

  constructor(
    private reie_fb: FormBuilder,
    public  reie_dialog: MatDialog,
    private reie_rrhhService: RrhhService,
    private reie_router: Router,
    private reie_route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
  ){
    this.reie_form.markAllAsTouched();
    this.bajaEmpleadoEvento();
    this.getDatosPersonalRRHH();
    this.getRegimenSat();
    /*modificar*/
    this.reie_route.queryParams.subscribe(
      params => {
        this.id_exp = +params['id_exp'];
        if(this.id_exp){
          console.log('ID: ' + this.id_exp);
          this.reie_form.patchValue({ numero_depa: this.id_exp, numero_empleado_reg: this.id_exp})
          this.getExpedienteConsulta(this.id_exp);
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    let url_list = this.reie_router.url.split("/");
    this.reie_accion = url_list[3].split("?")[0];
    console.log(this.reie_accion);
  }

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }

  /*default*/
  public getEdad(){
    var today = new Date();
    var birthDate = new Date(this.reie_form.value.fecha_nacimiento);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age--;
    }
    return age;
  }

  public getTiempoLaborando(){
    var today:any = new Date();
    var ingresoDate:any = new Date(this.reie_form.value.fecha_ingreso);
    //en horas
    //return Math.abs(today - ingresoDate) / 36e5;

    //en dias
    var numeroDias = Math.floor((Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) - Date.UTC(ingresoDate.getFullYear(), ingresoDate.getMonth(), ingresoDate.getDate()) ) /(1000 * 60 * 60 * 24));
    const anios = Math.floor(numeroDias / 365);
    const diasRestantes = numeroDias % 365;
    const meses = Math.floor(diasRestantes / 30);
    const dias = diasRestantes % 30;
  
    return `${anios} años, ${meses} meses y ${dias} días`;
    
    //return Math.floor((Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) - Date.UTC(ingresoDate.getFullYear(), ingresoDate.getMonth(), ingresoDate.getDate()) ) /(1000 * 60 * 60 * 24));
  }

  public getMunicipios(){
    this.reie_isLoading = true;
    this.reie_rrhhService.getMunicipios(this.reie_form?.value?.numero_estado_republica).subscribe(
      response => {console.log(response);
        this.reie_isLoading = false;
        // if(response.estado == 200){

        // }
        this.municipios = response?.municipios;
      },
      error => {console.log(<any>error);
        this.reie_isLoading = false;
      });
  }

  public getDatosPersonalRRHH(){
    this.reie_isLoading = true;
    this.reie_rrhhService.getDatosPersonalRRHH().subscribe(
      response => {console.log(response);
        this.reie_isLoading = false;
        if(response.estado == 200){
          
          this.escolaridad_all = response.data.escolaridad;
          console.log(this.escolaridad_all);
          //this.municipios = response.data.municipios;
          this.estados_republica = response.data.estados_republica;
          this.gestor_puestos = response.data.gestor_puestos;
          this.tipo_puesto = response.data.tipo_puesto;
          this.estatus_escolarida = response.data.estatus_escolarida;
          this.estado_civil = response.data.estado_civil;
          this.regimenAll = response.data.regimen;
          this.estado_baja = response.data.estado_baja;
          this.departamento = response.data.departamento;

          this.estado_baja = this.ordenarPorId(this.estado_baja, 6);
          console.log(this.estado_baja);

        }
      },
      error => {console.log(<any>error);
        this.reie_isLoading = false;
      });
  }

  public eliminarElemento(array, idObjetivo) {
    return array.filter(item => item.id_tipo_baja_pk !== idObjetivo);
  }

  public ordenarPorId(array, idObjetivo) {
    // Encontrar el índice del elemento con el id objetivo
    const indexObjetivo = array.findIndex(item => item.id_tipo_baja_pk === idObjetivo);
  
    // Si se encuentra el elemento con el id objetivo
    if (indexObjetivo !== -1) {
      // Mover el elemento al principio del array
      const elementoObjetivo = array.splice(indexObjetivo, 1)[0];
      array.unshift(elementoObjetivo);
    }
  
    return array;
  }

  public helperDiasLaboralesDescanso(){
    if(this.reie_form.value.dl_l == true && this.reie_form.value.dl_l == this.reie_form.value.dd_l){
      this.reie_form.patchValue({dd_l: false});
    }
    if(this.reie_form.value.dl_ma == true && this.reie_form.value.dl_ma == this.reie_form.value.dd_ma){
      this.reie_form.patchValue({dd_ma: false});
    }
    if(this.reie_form.value.dl_mi == true && this.reie_form.value.dl_mi == this.reie_form.value.dd_mi){
      this.reie_form.patchValue({dd_mi: false});
    }
    if(this.reie_form.value.dl_j == true && this.reie_form.value.dl_j == this.reie_form.value.dd_j){
      this.reie_form.patchValue({dd_j: false});
    }
    if(this.reie_form.value.dl_v == true && this.reie_form.value.dl_v == this.reie_form.value.dd_v){
      this.reie_form.patchValue({dd_v: false});
    }
    if(this.reie_form.value.dl_s == true && this.reie_form.value.dl_s == this.reie_form.value.dd_s){
      this.reie_form.patchValue({dd_s: false});
    }
    if(this.reie_form.value.dl_d == true && this.reie_form.value.dl_d == this.reie_form.value.dd_d){
      this.reie_form.patchValue({dd_d: false});
    }
  }

  public bajaEmpleadoEvento(){
    console.log('baja empleado');
    if(this.reie_form.value.baja_empleado ==  false || this.reie_form.value.baja_empleado ==  0){
      this.reie_form.patchValue({
        numero_baja: 1,
        descripcion: '-',
        ultimo_laborando: '-',
      });
    }
  }
  /*fin default*/

  

  
  /*salarios*/
  public get_salarios(): FormArray {    
    return this.reie_form.get('salarios') as FormArray;
  }

  public get_salario(i: number): FormArray {    
    return this.get_salarios().at(i) as FormArray;
  }

  public crearSalario(data: any = null): FormGroup{
    
    var fecha_asignacion = '';
    var salario = '';
    var numero = 0;
    var numero_salario = 0;

    if(data == null){
      fecha_asignacion = this.reie_form.value.fecha_asignacion_aux;
      salario = this.reie_form.value.salario_aux;

    }else{
      numero = data.id_salario_pk;
      numero_salario = data.id_salario_pk;
      fecha_asignacion = data.fecha_asignacion;
      salario = data.salario;
    }
    

    var form = this.reie_fb.group({
      numero: new FormControl(numero, []),
      numero_salario: new FormControl(numero, []),
      fecha_asignacion: new FormControl(fecha_asignacion, [Validators.required]),
      salario: new FormControl(salario, [Validators.required, Validators.pattern("[+-]?([0-9]*[.])?[0-9]+")]),
    });
    form.markAllAsTouched()
    return form;
  }

  public quitSalario(i: number){
    this.get_salarios().removeAt(i);
  }

  public agregarSalario(data: any = null){
    this.get_salarios().push( this.crearSalario(data) );  
    this.reie_form.patchValue({
      fecha_asignacion_aux: '',
      salario_aux: '',
    });
    console.log(this.reie_form.value);
    
    
  }

  /*fin salarios*/


  /*benficiarios*/
  public get_tarjeta_beneficiarios(): FormArray {    
    return this.reie_form.get('tarjeta_beneficiarios') as FormArray;
  }

  public get_tarjeta_beneficiario(i: number): FormArray {    
    return this.get_tarjeta_beneficiarios().at(i) as FormArray;
  }

  public crearBeneficiario(data: any = null): FormGroup{
    console.log(data);
    
    var baneficiario = '';
    var parentesco = '';
    var rfc = '';
    var porcentaje = '';
    var direccion = '';
    var id_datos_puesto_fk = 0;
    var id_tar_bene_pk = 0;
    

    if(data == null){
      baneficiario = this.reie_form.value.baneficiario_aux;
      parentesco = this.reie_form.value.parentesco_aux;
      baneficiario = this.reie_form.value.baneficiario_aux;
      parentesco = this.reie_form.value.parentesco_aux;
      rfc = this.reie_form.value.rfc_aux;
      porcentaje = this.reie_form.value.porcentaje_aux;
      direccion = this.reie_form.value.direccion_aux;
      id_datos_puesto_fk = this.reie_form.value.id_datos_puesto_fk;
      /*form = this.reie_fb.group({
        baneficiario: new FormControl(baneficiario, []),
        parentesco: new FormControl(parentesco, []),
        rfc: new FormControl(rfc, []),
        porcentaje: new FormControl(porcentaje, []),
        direccion: new FormControl(direccion, []),
        id_datos_puesto_fk: new FormControl(id_datos_puesto_fk, []),
      });*/
  

    }else{
      console.log('aaaaa');
      //baneficiario = data.baneficiario;
      //parentesco = data.parentesco;
      baneficiario = data.baneficiario;
      parentesco = data.parentesco;
      rfc = data.rfc;
      porcentaje = data.porcentaje;
      direccion = data.direccion;
      id_datos_puesto_fk = this.reie_form.value.id_datos_puesto_fk;
      id_tar_bene_pk = data.id_tar_bene_pk;
      //numero = data.id_salario_pk;
      //numero_salario = data.id_salario_pk;
      //baneficiario_aux: new FormControl('', []),
      //parentesco_aux: new FormControl('', []),
      //rfc_aux: new FormControl('', []),
      //id_datos_puesto_fk: new FormControl('', []);
      //id_tar_bene_pk: new FormControl('', []),
     


    }

    var form = this.reie_fb.group({
      baneficiario: new FormControl(baneficiario, []),
      parentesco: new FormControl(parentesco, []),
      rfc: new FormControl(rfc, []),
      porcentaje: new FormControl(porcentaje, []),
      direccion: new FormControl(direccion, []),
      id_datos_puesto_fk: new FormControl(id_datos_puesto_fk, []),
      id_tar_bene_pk: new FormControl(id_tar_bene_pk, []),
    });
    
    console.log(form);

    
    //var form = this.reie_fb.group({});


    /*baneficiario_aux: new FormControl('', []),
      parentesco_aux: new FormControl('', []),
      rfc_aux: new FormControl('', []),
      porcentaje_aux: new FormControl('', []),
      direccion_aux: new FormControl('', []),*/



    form.markAllAsTouched()
    return form;
  }

  public quitBeneficiario(i: number){
    this.get_tarjeta_beneficiarios().removeAt(i);
  }

  public agregarBeneficiarios(data: any = null){
    console.log(data);
    this.get_tarjeta_beneficiarios().push( this.crearBeneficiario(data) );  
    this.reie_form.patchValue({
      baneficiario_aux: '',
      parentesco_aux: '',
      rfc_aux: '',
      porcentaje_aux: '',
      direccion_aux: '',
    });
    console.log(this.reie_form.value);
    
    
  }
  /*fin beneficiarios*/



  








  /*registrar*/
  public setDL(){
    var c = '0/';
    if(this.reie_form.value.dl_l == true){
      c = c + '1/';
    }
    if(this.reie_form.value.dl_ma == true){
      c = c + '2/';
    }
    if(this.reie_form.value.dl_mi == true){
      c = c + '3/';
    }
    if(this.reie_form.value.dl_j == true){
      c = c + '4/';
    }
    if(this.reie_form.value.dl_v == true){
      c = c + '5/';
    }
    if(this.reie_form.value.dl_s == true){
      c = c + '6/';
    }
    if(this.reie_form.value.dl_d == true){
      c = c + '7/';
    }
    this.reie_form.patchValue({dias_laboral:c});
  }

  public setDD(){
    var c = '0/';
    if(this.reie_form.value.dd_l == true){
      c = c + '1/';
    }
    if(this.reie_form.value.dd_ma == true){
      c = c + '2/';
    }
    if(this.reie_form.value.dd_mi == true){
      c = c + '3/';
    }
    if(this.reie_form.value.dd_j == true){
      c = c + '4/';
    }
    if(this.reie_form.value.dd_v == true){
      c = c + '5/';
    }
    if(this.reie_form.value.dd_s == true){
      c = c + '6/';
    }
    if(this.reie_form.value.dd_d == true){
      c = c + '7/';
    }
    this.reie_form.patchValue({dias_descans:c});
  }

  public postRegistroExpedientePersonal(){
    this.setDL();
    this.setDD();
    console.log(this.reie_form.value);
    this.reie_form.value.baja_empleado = +this.reie_form.value.baja_empleado;
    this.reie_isLoading = true;
    this.reie_rrhhService.postRegistroExpedientePersonal(this.reie_form.value).subscribe(
      async response => {console.log(response);
        this.reie_isLoading = false;
        if(response.estado == 200){
          if(this.file){
            await this.postRegistroFotoExpediente(response.data.id_info_empleado_pk);
          }
          
          this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          
          //this._router.navigate(['rrhh/gestor-de-puestos-de-trabajo/gestor-area-departamento']);
          this.reie_router.navigate(['rrhh/gestor-de-expedientes-de-personal/modificar'], 
            { queryParams:
              {id_exp: response.data.id_info_empleado_pk}
            });
        }else{
          this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.reie_dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.reie_isLoading = false;
      });
  }

  /*fin registrar*/




  /*modificar*/
  public getExpedienteConsulta(id: number){
    this.reie_isLoading = true;
    this.reie_rrhhService.getExpedienteConsulta(id).subscribe(
      response => {console.log(response);
        this.reie_isLoading = false;
        if(response.estado == 200){
          this.data = response.data;
          this.setForm(response.data);
        }
      },
      error => {console.log(<any>error);
        this.reie_isLoading = false;
      });
  }

  public setForm(data: any){
    this.reie_form.patchValue({
      nombre: data?.nombre,
      apellidop: data?.apellidop,
      apellidom: data?.apellidom,
      fecha_nacimiento: data?.fecha_nacimiento,
      numero_estado_republica: data?.id_estados_republica_fk,
      otro: data?.otro,
      direccion: data?.direccion,
      colonia: data?.colonia,
      numero_municipio: data?.id_municipio_fk,
      cp: data?.cp,
      telefono: data?.telefono,
      celular: data?.celular,
      correo: data?.correo,
      rfc: data?.rfc,
      regimen:  data?.regimen,
      curp: data?.curp,
      ss: data?.ss,
      infonativ: data?.infonativ,

      numero_reporte: data?.datos_puesto?.id_puestos_reporte_fk,
      id_departamento_fk: data?.datos_puesto?.id_departamento_fk,
      numero_tipo_puesto: data?.datos_puesto?.id_tipo_puesto_fk,
      fecha_ingreso: data?.datos_puesto?.fecha_ingreso,
      observaciones: data?.datos_puesto?.observaciones,
      jornada_inicio: data?.datos_puesto?.jornada_inicio,
      jornada_fin: data?.datos_puesto?.jornada_fin,
      observacion_renumerado: data?.datos_puesto?.observacion_renumerado,
      numero_escolaridad: data?.datos_puesto?.id_escolaridad_fk,
      numero_estado_escolaridad: data?.datos_puesto?.id_estado_escolaridad_fk,
      escolaridad: data?.datos_puesto?.escolaridad,
      //salarios: this?.reie_fb?.array([]),
     
      
      baneficiario: '-',
      parentesco: '-',
      rfc_dos: '1234567890123',
      porcentaje: '2',
      direccion_dos: '-',
      baja_empleado: data?.datos_puesto?.tarjeta_nomina?.baja_empleado,
      numero_baja: data?.datos_puesto?.tarjeta_nomina?.id_tipo_baja_fk,
      descripcion:  data?.datos_puesto?.tarjeta_nomina?.descripcion,
      ultimo_laborando:  data?.datos_puesto?.tarjeta_nomina?.ultimo_laborando,

      generica_propia:  data?.datos_puesto?.tarjeta_nomina?.generica_propia?.toString(),

      numero_datos_puesto: data?.datos_puesto?.id_datos_puestos_pk,
      numero_de_nomina: data?.datos_puesto?.tarjeta_nomina?.id_tarjeta_nomina_pk,



      id_datos_puesto_fk: data?.datos_puesto?.id_datos_puestos_pk,
      

    });

    if(data?.datos_puesto?.tarjeta_nomina?.id_estado_civil_fk != null ){
      this.reie_form.patchValue({
        numero_estado_civil: data?.datos_puesto?.tarjeta_nomina?.id_estado_civil_fk,
      });

    }

    if(data?.datos_puesto?.tarjeta_nomina?.id_regimen_fk != null){
      this.reie_form.patchValue({
        numero_regimen: data?.datos_puesto?.tarjeta_nomina?.id_regimen_fk,
      });
    }


   

    this.setFormDL(data);
    this.setFormDD(data);
    this.setForm_salario(data);
    this.setForm_tarjetaBeneficiarios(data);

   

    setTimeout(() => {
      this.getMunicipios()
    }, 2000);

  }


  public regimen: any = [];
  public getRegimenSat(){
    this.reie_isLoading = true;
    this.reie_rrhhService.getRegimenSat().subscribe(
      response => {console.log(response);
        this.reie_isLoading = false;
        if(response.estatus == 1){
          this.regimen = [];
          for (let key in response?.regimen) {
              this.regimen.push(response?.regimen[key]);
          }
          console.log(this.regimen);
        }
      },
      error => {console.log(<any>error);
        this.reie_isLoading = false;
      });
  }
  
  // Llamada a setTimeout para ejecutar la función después de 3000 milisegundos (3 segundos)


  public setFormDL(data: any){
    if(data?.datos_puesto?.dias_laboral){
      var cad = data.datos_puesto.dias_laboral.split('/');
      console.log(cad);
      if(cad){
        if(cad.find((item: any) => item == '1')){ this.reie_form.patchValue({dl_l: true});}
        if(cad.find((item: any) => item == '2')){ this.reie_form.patchValue({dl_ma: true});}
        if(cad.find((item: any) => item == '3')){ this.reie_form.patchValue({dl_mi: true});}
        if(cad.find((item: any) => item == '4')){ this.reie_form.patchValue({dl_j: true});}
        if(cad.find((item: any) => item == '5')){ this.reie_form.patchValue({dl_v: true});}
        if(cad.find((item: any) => item == '6')){ this.reie_form.patchValue({dl_s: true});}
        if(cad.find((item: any) => item == '7')){ this.reie_form.patchValue({dl_d: true});}
      }
    }
    
  }

  public setFormDD(data: any){
     if(data?.datos_puesto?.dias_descans){
      var cad = data.datos_puesto.dias_descans.split('/');
      console.log(cad);
      if(cad){
        if(cad.find((item: any) => item == '1')){ this.reie_form.patchValue({dd_l: true});}
        if(cad.find((item: any) => item == '2')){ this.reie_form.patchValue({dd_ma: true});}
        if(cad.find((item: any) => item == '3')){ this.reie_form.patchValue({dd_mi: true});}
        if(cad.find((item: any) => item == '4')){ this.reie_form.patchValue({dd_j: true});}
        if(cad.find((item: any) => item == '5')){ this.reie_form.patchValue({dd_v: true});}
        if(cad.find((item: any) => item == '6')){ this.reie_form.patchValue({dd_s: true});}
        if(cad.find((item: any) => item == '7')){ this.reie_form.patchValue({dd_d: true});}
      }
    }
  }

  public setForm_salario(data: any){
   
    // Obtén una referencia al FormArray "salarios" desde tu FormGroup
    const salariosArray = this.reie_form.get('salarios') as FormArray;

    // Obten la longitud actual del FormArray
    const length = salariosArray.length;

    // Itera a través de los controles y elimínalos uno por uno
    for (let i = 0; i < length; i++) {
      salariosArray.removeAt(0); // Elimina el primer control en cada iteración
    }

    for(let i = 0; i < data.datos_puesto.salarios.length; i++){
      this.agregarSalario(data.datos_puesto.salarios[i]);
    }
  }

  public setForm_tarjetaBeneficiarios(data: any){
    console.log(data);
   
    // Obtén una referencia al FormArray "salarios" desde tu FormGroup
    const salariosArray = this.reie_form.get('tarjeta_beneficiarios') as FormArray;

    // Obten la longitud actual del FormArray
    const length = salariosArray.length;

    // Itera a través de los controles y elimínalos uno por uno
    for (let i = 0; i < length; i++) {
      salariosArray.removeAt(0); // Elimina el primer control en cada iteración
    }

    for(let i = 0; i < data.datos_puesto?.tarjeta_beneficiarios?.length; i++){
      console.log(i);
      this.agregarBeneficiarios(data.datos_puesto.tarjeta_beneficiarios[i]);
    }
  }


  public postModificarExpedientePersonal(){
    this.setDL();
    this.setDD();
    console.log(this.reie_form.value);
   
      this.reie_form.patchValue({
        baja_empleado: +this.reie_form.value.baja_empleado
      });
    

    if(this.reie_form.value.baja_empleado == 0 ||  this.reie_form.value.baja_empleado == false){
      this.reie_form.patchValue({
        numero_baja: 6
      });
    }

    this.reie_isLoading = true;
    this.reie_rrhhService.postModificarExpedientePersonal(this.reie_form.value).subscribe(
      async response => {console.log(response);
        this.reie_isLoading = false;
        if(response.estado == 200){
          if(this.file){
            await this.postRegistroFotoExpediente(this.id_exp);
          }
          
          this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          //this._router.navigate(['rrhh/gestor-de-puestos-de-trabajo/gestor-area-departamento']);
          /*this.reie_router.navigate(['rrhh/gestor-de-expedientes-de-personal/modificar'], 
            { queryParams:
              {id_dp: response.data.id_desc_puestos_pk}
            });*/
          this.getExpedienteConsulta(this.id_exp);
        }else{
          this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.reie_dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.reie_isLoading = false;
      });
  }
  /*fin modificar*/










  public siguiente() {
    this.selectedIndex += 1;
    console.log(this.selectedIndex);
    this.siguienteEvento.emit(this.selectedIndex.toString());
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

  }


  /*operaciones*/

  public guardar(){
    console.log(this.reie_form.value);
    this.postRegistroExpedientePersonal();
  }

  public modificar(){
    console.log(this.reie_form.value);
    this.postModificarExpedientePersonal();
  }

  /*fin operaciones*/




  public postRegistroFotoExpediente(id: number): any{
        const formData = new FormData();
        formData.set('documento', this.file);
        formData.set('numero_empleado_reg',  id.toString());
        this.reie_isLoading = true;
        this.reie_rrhhService.postRegistroFotoExpediente(formData).subscribe(
          response => {console.log(response);
            this.reie_isLoading = false;
            /*if(response.estado == 200){
              //this.getConsultarCartilla(this.id);
              this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            }else{
              this.reie_dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
            }*/
            
          },
          error => {console.log(<any>error);
            this.reie_dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
            this.reie_isLoading = false;
        });
  }

  public cargarFoto(event: any){
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      console.log(file.type);
      var ext = event.target.files[0].name.split('.')[1];
      console.log(ext);
      if (file.type.startsWith('image/png') || file.type.startsWith('image/jpg') || file.type.startsWith('image/jpeg')) {//text/xml
        //console.log(this.id);
        this.file = file;

      }else{
        this.reie_dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato', icon:'error'}});
      }
    }  
  }



  public postEliminarFotoExpediente(id: number){
    this.reie_isLoading = true;
    this.reie_rrhhService.postEliminarFotoExpediente({numero_empleado_reg: id}).subscribe(
      response => {console.log(response);
        this.reie_isLoading = false;
        if(response.estado == 200){
          this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getExpedienteConsulta(this.id_exp);
        }else{
          this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.reie_dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.reie_isLoading = false;
      });
  }




  
  public postAgregarTarjetaBeneficiarios(){
    console.log(this.reie_form);
    this.reie_isLoading = true;
    this.reie_rrhhService.postAgregarTarjetaBeneficiarios(
      
      {
       
        id_datos_puesto_fk: this.reie_form.value.id_datos_puesto_fk,
        baneficiario: this.reie_form.value.baneficiario_aux,
        parentesco: this.reie_form.value.parentesco_aux,
        rfc: this.reie_form.value.rfc_aux,
        porcentaje: this.reie_form.value.porcentaje_aux,
        direccion: this.reie_form.value.direccion_aux,
        
      }
      
      
      ).subscribe(
      response => {console.log(response);
        this.reie_isLoading = false;
        if(response.estado == 200){
          //this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getExpedienteConsulta(this.id_exp);
        }else{
          this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.reie_dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.reie_isLoading = false;
      });
  }

  public postModificarTarjetaBeneficiarios(element: any){
    console.log(this.reie_form);
    console.log(element.value);
    this.reie_isLoading = true;
    this.reie_rrhhService.postModificarTarjetaBeneficiarios(
      
      element.value
      
      
      ).subscribe(
      response => {console.log(response);
        this.reie_isLoading = false;
        if(response.estado == 200){
          this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getExpedienteConsulta(this.id_exp);
        }else{
          this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.reie_dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio al modificar.', icon:'error'}});
        this.reie_isLoading = false;
      });
  }

  public postEliminarTarjetaBeneficiarios(element: any){
    console.log(this.reie_form);
    console.log(element.value);
    this.reie_isLoading = true;
    this.reie_rrhhService.postEliminarTarjetaBeneficiarios(
      
      element.value
      
      
      ).subscribe(
      response => {console.log(response);
        this.reie_isLoading = false;
        if(response.estado == 200){
          this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa al Eliminar', mensaje: response.mensaje, icon:'correct'}});
          this.getExpedienteConsulta(this.id_exp);
        }else{
          this.reie_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.reie_dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio el eliminar.', icon:'error'}});
        this.reie_isLoading = false;
      });
  }

  
}
