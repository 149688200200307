import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { Beneficiaria } from 'src/app/model/beneficiaria';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ParametrosService } from 'src/app/services/parametros.service';
import * as moment from 'moment';
import { ConfigurationService } from 'src/app/services/configuration.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  hostapi:string;
  constructor(
    private _documentosService:DocumentosService,
    private dialog:MatDialog,
    private activatedRoute:ActivatedRoute,
    private _parametrosService:ParametrosService,
    private _beneficiariaService:BeneficiariaService,
    private _conf:ConfigurationService,
    private _cdRef:ChangeDetectorRef,
    ) { 
      this.hostapi=_conf.getConfiguration().hostapi;
    }

  discapacidades = ["Intelectual","Motriz","Conductual","Mental"];
  beneficiaria:Beneficiaria = null;
  isLoading=false;
  idBeneficiaria = 0;
  fileToUpload:File = null;
  fileToUpload1:File = null;
  fileToUpload2:File = null;
  fileToUpload3:File = null;
  fileToUpload4:File = null;
  fileToUpload5:File = null;
  fileToUpload6:File = null;
  fileToUpload7:File = null;
  autonomiasConitivas = [];
  public t: any;

  formulario = new UntypedFormGroup({
    fechaIngreso : new UntypedFormControl("",[]),
    desAntecedente: new UntypedFormControl("",[]),

    diagnosticoNeurologico: new UntypedFormControl("",[]),
    dnArchivo:new UntypedFormControl("",[]),
    dnDescripcion:new UntypedFormControl("",[]),
    dnRuta:new UntypedFormControl("",[]),

    edadMental: new UntypedFormControl("",[]),
    emArchivo: new UntypedFormControl("",[]),
    emRuta:new UntypedFormControl("",[]),

    diagnosticoMotriz: new UntypedFormControl("",[]),
    dmArchivo: new UntypedFormControl("",[]),
    dmRuta:new UntypedFormControl("",[]),

    diagnosticoPsicologico: new UntypedFormControl("",[]),
    dpArchivo: new UntypedFormControl("",[]),
    dpRuta:new UntypedFormControl("",[]),

    diagnosticoMedico: new UntypedFormControl("",[]),
    dmedArchivo: new UntypedFormControl("",[]),
    dmedRuta:new UntypedFormControl("",[]),

    tipoDeSangre: new UntypedFormControl("",[]),
    tsArchivo: new UntypedFormControl("",[]),
    tsRuta:new UntypedFormControl("",[]),

    condicionRelevante:new UntypedFormControl("",[]),
    condicionRelevanteDescription:new UntypedFormControl("",[]),


    autonomiaCognitivoSelect:new UntypedFormControl("",[]),
    autonomiaCognitivoInput:new UntypedFormControl("",[]),
    autonomiaMotrizSelect:new UntypedFormControl("",[]),
    autonomiaMotrizInput:new UntypedFormControl("",[]),
    diagnosticoEmocionalSelect:new UntypedFormControl("",[]),
    diagnosticoEmocionalInput:new UntypedFormControl("",[]),

    idDiscapacidad: new UntypedFormControl("",[])
  })
  ngOnInit(): void {
    this.p_cargaCatalogos();
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info("Parametros");
      console.info(params["id"]);
      if(params["id"]){
        this.idBeneficiaria= params["id"];
        this.isLoading = true;
        this._beneficiariaService.findById(this.idBeneficiaria).subscribe(result=>{
          console.log(result);
          this.beneficiaria = result.data;
          this.p_setData();
          this.isLoading = true;
          this._beneficiariaService.getProcesosByBeneficiaria(this.idBeneficiaria).subscribe(resultado=>{
            console.log(resultado);
            this.isLoading = false;
            this.form.fechaIngreso.setValue(new Date(resultado.data.procesos[0].etapas.candidata.fechaAlta.date));
          },()=>{
            this.isLoading = false;
          });
          this.p_getDocumentos(this.idBeneficiaria);
        },()=>{
          this.isLoading = false;
        })
      }
    });
    
  }

  p_sendForm(){
    this.isLoading = true;
    this._beneficiariaService.savePerfiles(this.idBeneficiaria,
      {
        "desAntecedente":this.formulario.controls.desAntecedente.value
      }).subscribe(result=>{
        this.isLoading = false;
        console.info(result);
        this.p_cargarListaDoc();
        
      },()=>{
        this.isLoading = false;
        this.p_cargarListaDoc();
      });
  }

  p_handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  p_handleFileInput1(files: FileList) {
    this.fileToUpload1 = files.item(0);
  }
  p_handleFileInput2(files: FileList) {
    this.fileToUpload2 = files.item(0);
  }
  p_handleFileInput3(files: FileList) {
    this.fileToUpload3 = files.item(0);
  }
  p_handleFileInput4(files: FileList) {
    this.fileToUpload4 = files.item(0);
  }
  p_handleFileInput5(files: FileList) {
    this.fileToUpload5 = files.item(0);
  }
  p_handleFileInput6(files: FileList) {
    this.fileToUpload6 = files.item(0);
  }



  tipoDeSangre:any;
  diagnosticoNeurologico:any;
  p_cargaCatalogos(){
    //param motivo ingreso
    this._parametrosService.getParametros("11").subscribe(result=>{
      this.tipoDeSangre = result.data.parametros;
    });
    this._parametrosService.getParametros("4").subscribe(result=>{
      this.diagnosticoNeurologico = result.data.parametros;
    });
    
  }

  /*cargarDocumentos(){
    
    console.info(this.formulario.controls.dnArchivo.value);
    this.p_altaDocumento("57",this.formulario.controls.diagnosticoNeurologico.value,this.formulario.controls.dnDescripcion.value,this.fileToUpload1).finally(()=>{
      this.p_altaDocumento("58","",this.formulario.controls.edadMental.value,this.fileToUpload2).finally(()=>{
        this.p_altaDocumento("59","",this.formulario.controls.diagnosticoMotriz.value,this.fileToUpload3).finally(()=>{
          this.p_altaDocumento("60","",this.formulario.controls.diagnosticoPsicologico.value,this.fileToUpload4).finally(()=>{
            this.p_altaDocumento("61","",this.formulario.controls.diagnosticoMedico.value,this.fileToUpload5).finally(()=>{
              setTimeout(()=>{
                this.p_altaDocumento("62",this.formulario.controls.tipoDeSangre.value,"",this.fileToUpload6).finally(()=>{
                  this.p_altaDocumento("84",this.formulario.controls.condicionRelevante.value,this.formulario.controls.condicionRelevanteDescription.value,this.fileToUpload7).finally(()=>{
                    this.p_mensajeDeExito2();
                  })
                })
              },1000)
            })
          })
        })
      })
    });
  }*/

  arreglo = [];
  p_cargarArreglo(tipoDocumento:string,idTipoCatalogo:string,desTipoCatalogo:string,file:File){
    this.arreglo.push({"file":file,"idBeneficiaria":this.idBeneficiaria.toString(),"idTipoCatalogo":idTipoCatalogo,"desTipoCatalogo":desTipoCatalogo,"tipoDocumento":tipoDocumento});
  }

  p_cargarListaDoc(){
    return new Promise((resolve, reject) => {

      this.p_cargarArreglo("57",this.formulario.controls.diagnosticoNeurologico.value,this.formulario.controls.dnDescripcion.value,this.fileToUpload1);
      this.p_cargarArreglo("58","",this.formulario.controls.edadMental.value,this.fileToUpload2);
      this.p_cargarArreglo("59","",this.formulario.controls.diagnosticoMotriz.value,this.fileToUpload3);
      this.p_cargarArreglo("60","",this.formulario.controls.diagnosticoPsicologico.value,this.fileToUpload4);
      this.p_cargarArreglo("61","",this.formulario.controls.diagnosticoMedico.value,this.fileToUpload5);
      this.p_cargarArreglo("62",this.formulario.controls.tipoDeSangre.value,"",this.fileToUpload6);
      this.p_cargarArreglo("84",this.formulario.controls.condicionRelevante.value,this.formulario.controls.condicionRelevanteDescription.value,this.fileToUpload7);
      
      this.p_cargarArreglo("120","",this.formulario.controls.idDiscapacidad.value,null);

      let fD = new FormData();
      fD.append('contador', "8");
      for(let pf=0; pf<this.arreglo.length; pf++){
        if(pf==0){
          fD.append('documento', this.arreglo[pf].file);
          fD.append('idBeneficiaria', this.idBeneficiaria.toString());
          fD.append('idTipoDocumento', this.arreglo[pf].tipoDocumento);
          fD.append('idTipoCatalogo', this.arreglo[pf].idTipoCatalogo);
          fD.append('desTipoCatalogo', this.arreglo[pf].desTipoCatalogo);
        }else{
          fD.append('documento'+pf, this.arreglo[pf].file);
          fD.append('idBeneficiaria'+pf, this.idBeneficiaria.toString());
          fD.append('idTipoDocumento'+pf, this.arreglo[pf].tipoDocumento);
          fD.append('idTipoCatalogo'+pf, this.arreglo[pf].idTipoCatalogo);
          fD.append('desTipoCatalogo'+pf, this.arreglo[pf].desTipoCatalogo);
        }
      }

      this.isLoading=true;
      this._documentosService.saveMulti(fD).subscribe(result=>{
        console.info(result);
        this.isLoading=false;
        this.p_mensajeDeExito2();
        resolve(true);
      },()=>{
        this.isLoading=false;
        resolve(true);
      })
        
   
      
    });
  }
  p_altaDocumento(tipoDocumento:string,idTipoCatalogo:string,desTipoCatalogo:string,file:File){
    
    return new Promise((resolve, reject) => {
      //if(file==null &&  desTipoCatalogo=="" && idTipoCatalogo==""){
      //  resolve(true);
      //}else{
        setTimeout(()=>{
          let fD = new FormData();
          fD.append('documento', file);
          fD.append('idBeneficiaria', this.idBeneficiaria.toString());
          fD.append('idTipoDocumento', tipoDocumento);
          fD.append('idTipoCatalogo', idTipoCatalogo);
          fD.append('desTipoCatalogo', desTipoCatalogo);
          this.isLoading=true;
          this._documentosService.save(fD).subscribe(result=>{
            console.info(result);
            this.isLoading=false;
            
            resolve(true);
          },()=>{
            this.isLoading=false;
            resolve(true);
          })
        },0);
      //}
      
    });
  }
  p_mensajeDeExito2(){
    const dialogRef= this.dialog.open(MessageBoxComponent,{
      data:{titulo: "Expediente modificado" ,mensaje: "Se ha modificado el expediente correctamente"}
    });
    dialogRef.afterClosed().subscribe(()=>{
      document.getElementById("resetForm").click();
      this.ngOnInit();
    })
  }

  p_setData(){
    this.formulario.controls.desAntecedente.setValue(this.beneficiaria.desAntecedente);
  }
  documentos:any;
  documento1 = null;
  documento2 = null;
  documento3 = null;
  documento4 = null;
  documento5 = null;
  documento6 = null;

  p_getDocumentos(id:number){
    this.isLoading = true;
    this._documentosService.getAllByBeneficiaria(id).subscribe(result=>{
      console.log(result);
      this.isLoading = false;
      this.documentos = result.data.documentos;

      for(let pf = 0; pf< this.documentos.length; pf++){
        if(this.documentos[pf].idTipoDocumento == 57){
          this.formulario.controls.diagnosticoNeurologico.setValue(this.documentos[pf].idTipoCatalogo);
          this.formulario.controls.dnDescripcion.setValue(this.documentos[pf].desTipoCatalogo);
          //this.formulario.controls.dnRuta.setValue(this.documentos[pf].docTipoCatalogo);
          this.documento1 = this.documentos[pf].docTipoCatalogo;
        }else if(this.documentos[pf].idTipoDocumento == 58){
          this.formulario.controls.edadMental.setValue(this.documentos[pf].desTipoCatalogo);
          this.formulario.controls.emRuta.setValue(this.documentos[pf].docTipoCatalogo);
          this.documento2 = this.documentos[pf].docTipoCatalogo;
        }else if(this.documentos[pf].idTipoDocumento == 59){
          this.formulario.controls.diagnosticoMotriz.setValue(this.documentos[pf].desTipoCatalogo);
          //this.formulario.controls.dmRuta.setValue(this.documentos[pf].docTipoCatalogo);
          this.documento3 = this.documentos[pf].docTipoCatalogo;
        }else if(this.documentos[pf].idTipoDocumento == 60){
          this.formulario.controls.diagnosticoPsicologico.setValue(this.documentos[pf].desTipoCatalogo);
          //this.formulario.controls.dpRuta.setValue(this.documentos[pf].docTipoCatalogo);
          this.documento4 = this.documentos[pf].docTipoCatalogo;
        }else if(this.documentos[pf].idTipoDocumento == 61){
          this.formulario.controls.diagnosticoMedico.setValue(this.documentos[pf].desTipoCatalogo);
          //this.formulario.controls.dmedRuta.setValue(this.documentos[pf].docTipoCatalogo);
          this.documento5 = this.documentos[pf].docTipoCatalogo;
        }else if(this.documentos[pf].idTipoDocumento == 62){
          this.formulario.controls.tipoDeSangre.setValue(this.documentos[pf].idTipoCatalogo);
          //this.formulario.controls.tsRuta.setValue(this.documentos[pf].docTipoCatalogo);
          this.documento6  = this.documentos[pf].docTipoCatalogo;
        }else if(this.documentos[pf].idTipoDocumento == 84){
          this.formulario.controls.condicionRelevante.setValue(this.documentos[pf].idTipoCatalogo);
          this.formulario.controls.condicionRelevanteDescription.setValue(this.documentos[pf].desTipoCatalogo);
          //this.formulario.controls.tsRuta.setValue(this.documentos[pf].docTipoCatalogo);
          
        }else if(this.documentos[pf].idTipoDocumento == 120){
          this.formulario.controls.idDiscapacidad.setValue(this.documentos[pf].desTipoCatalogo.split(","));
        }

      }

    },()=>{
      this.isLoading = false;
    })
  }
  get form(){
    return this.formulario.controls;
  }
  get edadIngreso(){
    if(this.beneficiaria==null){
      return "-";
    }
    if((this.beneficiaria.fechaNacimiento["date"]=="" || this.beneficiaria.fechaNacimiento["date"]==null || this.form.fechaIngreso.value=="" || this.form.fechaIngreso.value==null)){
      return "-";
    }else{
      //console.info(this.beneficiaria.fechaIngreso["date"]);
      let age:Number = moment(this.form.fechaIngreso.value).diff(this.beneficiaria.fechaNacimiento["date"], 'years',true);
      //console.info(age);
      let laage:String = age.toString();
      let ans = laage.split(".")[0];
      let meses = ((Number) ("0."+(laage.split(".")[1]) ) * 12).toString().split(".")[0];
      let dE = ans + " años "+ meses + " meses";
      return dE;
    }
  }

  get tiempoDeAntiguedad(){
    if( this.form.fechaIngreso.value=="" || this.form.fechaIngreso.value==null ){
      return "0";
    }else{
      //console.info(this.beneficiaria.fechaIngreso["date"]);
      let age:Number = moment(new Date()).diff(this.form.fechaIngreso.value, 'years',true);
      //console.info(age);
      let laage:String = age.toString();
      let ans = laage.split(".")[0];
      let meses = ((Number) ("0."+(laage.split(".")[1]) ) * 12).toString().split(".")[0];
      let dE = ans + " años "+ meses + " meses";
      return dE;
    }
  }


  public getEliminarDocumentoBeneficiario(beneficiario: number, numero_tipo_doc: number){
    console.log(beneficiario, numero_tipo_doc);
    this.isLoading=true;
    this._beneficiariaService.getEliminarDocumentoBeneficiario(beneficiario, numero_tipo_doc).subscribe(result=>{
      this.isLoading=false;
      //this.ifg_cargaExtras(result.data.id);
      
    },()=>{
      this.isLoading=false;
    });
  }

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }


  //nuevas

  public postEliminarDocumentoBene(beneficiario: number, doc: number, url: string){
    //console.log(beneficiario, numero_tipo_doc);

    var j = 0;
    

    for(let i = 0; i < this.documentos.length ; i++){
      if(this.documentos[i].idTipoDocumento == doc){
        j = i;
      }
    }
    console.log(j);
    var u = this.documentos[j].docTipoCatalogo.split('./public_uploads/');
    console.log(u);

    var data = {
      url: u[1],
      numero_documento: doc,
      numero_beneficiario: beneficiario,
    }
    console.log(data); 
    this.isLoading=true;
    this._beneficiariaService.postEliminarDocumentoBene(data).subscribe(result=>{
      console.log(result);
      this.isLoading=false;
      this.p_cargarListaDoc();
      
    },()=>{
      this.isLoading=false;
    });
  }


}
