//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { MedicoService } from 'src/app/services/medico.service';
import { SesionService } from 'src/app/services/sesion.service';

@Component({
  selector: 'app-gestor-contactos-area-medica',
  templateUrl: './gestor-contactos-area-medica.component.html',
  styleUrls: ['./gestor-contactos-area-medica.component.scss'],
  providers: [ MedicoService ]
})
export class GestorContactosAreaMedicaComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    buscador: new UntypedFormControl('', []),
    ruta: new UntypedFormControl('', []),
  });
  /*tabla*/
  public gcam_length: number = 100;;
  public gcam_pageSize: number = 25;
  public gcam_pageSizeOptions: Array<number> = [5, 10, 25, 100];
  public gcam_page: number = 1;
  public gcam_displayedColumns: any =[
    'gcam_clasificacion', 
    'gcam_especialidad',
    'gcam_nombre_razon_social',
    'gcam_calificacion',
    'gcam_consultar',
    'gcam_modificar',
    'gcam_eliminar'];
  public dataSource: any;
  /*fin tabla*/
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public laravelapi: any;
  public acciones: any;
  public registrar: boolean = false;
  public consultar: boolean = false;
  public modificar: boolean = false;
  public eliminar: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _medicoService: MedicoService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _sesionService:SesionService,
  ){ 
    this.getConsultaDirectoriosMedicos(1);
    this.laravelapi = this._medicoService.getLaravelApi();
    this.registrar = this.consultarAccionUsuario("/medico/gestor-contactos-area-medica/registrar");
    this.consultar = this.consultarAccionUsuario("/medico/gestor-contactos-area-medica/consultar");
    this.modificar = this.consultarAccionUsuario("/medico/gestor-contactos-area-medica/modificar");
    this.eliminar = this.consultarAccionUsuario("/medico/gestor-contactos-area-medica/eliminar");

    


  }

  ngOnInit(): void {
  }

  /*permisos*/
  public consultarAccionUsuario(url: any){
    if(this._sesionService.getSession().getUsuario().nombre != undefined){
      this.acciones = this._sesionService.getSession().getAcciones();
      console.log(this.acciones);
      if(this.acciones.filter(x => x.url == url)[0]){
        return true;        
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  /*fin permisos*/

  public getConsultaDirectoriosMedicos(page: number){
    this.isLoading = true;
    this.gcam_page = page;
    this._medicoService.getConsultaDirectoriosMedicos(this.gcam_page, this.gcam_pageSize, this.form.value.buscador).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.gcam_length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.gcam_pageSize = event.gcam_pageSize;
    this.getConsultaDirectoriosMedicos(event.pageIndex + 1);
  }


  public getEliminarDir(id: number){
    this.isLoading = true;
    this._medicoService.getEliminarDir(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Correct', mensaje: 'Eliminado Correctamente.', icon:'correct'}});
          this.getConsultaDirectoriosMedicos(this.gcam_page);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el servicio', icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

}
