<br>
<div class="table-container">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner color="accent"></mat-spinner>
    </div>
    <ng-container *ngIf="existente && fotos?.length>0" >
        <div class="polaroid">
            <div class="image" style="background-image: url({{hostapi}}/{{group.value}})"></div>
        </div>
        <div class="text-align-center">
            <mat-button-toggle-group #group="matButtonToggleGroup" aria-label="Font Style" >
                <mat-button-toggle  value="{{fotos[1].activa}}" [checked]="true">
                    Foto 1
                    <!--(click)="getEliminarFotosUsuario(fotos[1].id)"-->
                    <!--<button   type="button" class="btn btn-small btn-danger">x</button>-->
                </mat-button-toggle>
                <mat-button-toggle value="{{foto.foto}}" *ngFor="let foto of fotos[0].hist; let i=index">
                    Foto {{i + 2}}
                    <button (click)="postRegistroAntecedentesNoPatologicos(+fotos[0].hist[i].id, +idBeneficiaria, foto.foto)"  type="button" class="btn btn-small btn-danger">x</button>

                </mat-button-toggle>
                

            </mat-button-toggle-group>
        </div>
    </ng-container>
    <br>

    <form (ngSubmit)="ifg_sendForm()" [formGroup]="formulario" autocomplete="off">
        <div class="text-align-center">
            <mat-form-field appearance="fill" class="bt col-md-6">
                <mat-label>Adjuntar Foto</mat-label>
                <input matInput placeholder="Adjuntar JPG, JPEG" [value]="file.value" readonly>
                <button mat-icon-button matSuffix (click)="file.click()" type="button">
                    <mat-icon>attach_file</mat-icon>
                </button>
                <input type="file" #file class="hide" (change)="ifg_handleFileInput($event.target.files)"/>
            </mat-form-field>
        </div>
        <mat-form-field appearance="fill" class="bt col-md-8">
            <mat-label>Nombre de la beneficiaria</mat-label>
            <input matInput placeholder="" maxlength="100" #nombre formControlName="nombre" [value]="beneficiaria?.nombre">
            <mat-hint align="end">{{nombre.value.length}} / 100</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Fecha de alta de expediente</mat-label>
            <input matInput [matDatepicker]="dp1" formControlName="fechaAlta">
            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
            <mat-datepicker touchUi #dp1 disabled="false"></mat-datepicker>
        </mat-form-field>
        <div>
            <mat-form-field appearance="fill" class="bt col-md-4">
                <mat-label>Fecha de nacimiento</mat-label>
                <input matInput [matDatepicker]="dp2" formControlName="fechaNacimiento">
                <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                <mat-datepicker startView="multi-year" touchUi #dp2 disabled="false"></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="bt col-md-2" >
                <mat-label>Edad</mat-label>
                <input matInput placeholder="" readonly [value]="edad">
            </mat-form-field>
        
            <!--<mat-form-field appearance="fill" class="bt col-md-4">
                <mat-label>Fecha de ingreso</mat-label>
                <input matInput [matDatepicker]="dp3" formControlName="fechaIngreso" [value]="beneficiaria?.fechaIngreso" readonly>
                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                <mat-datepicker touchUi #dp3 disabled="true"></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="bt col-md-2">
                <mat-label>Edad de ingreso</mat-label>
                <input matInput placeholder="" readonly [value]="edadIngreso">
            </mat-form-field>

            <mat-form-field appearance="fill" class="bt col-md-2">
                <mat-label>Tiempo de antiguedad</mat-label>
                <input matInput placeholder="" readonly [value]="tiempoDeAntiguedad">
            </mat-form-field>-->
        </div>
        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>CURP</mat-label>
            <input matInput placeholder="" formControlName="idCurp" maxlength="18" [value]="beneficiaria?.idCurp">
        </mat-form-field>

        <div>
            <mat-form-field appearance="fill" class="bt col-md-6">
                <mat-label>Canaliza</mat-label>
                <mat-select formControlName="idCanaliza">
                    <mat-option *ngFor="let org of canaliza" value="{{org.idParametro}}">{{org.valor}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="bt col-md-6" *ngIf="ifg_tieneBandera(form.idCanaliza?.value,canaliza)">
                <mat-label>Especificar</mat-label>
                <input type="text" matInput formControlName="desCanaliza">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill" class="bt col-md-6">
                <mat-label>Motivo de ingreso</mat-label>
                <mat-select formControlName="idMotivoIngreso">
                    <mat-option value="{{motivo.idParametro}}" *ngFor="let motivo of motivoIngreso" >{{motivo.valor}}</mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="fill" class="bt col-md-6" *ngIf="ifg_tieneBandera(form.idMotivoIngreso.value,motivoIngreso)">
                <mat-label>Especificar</mat-label>
                <input type="text" matInput formControlName="desMotivoIngreso">
            </mat-form-field>
        </div>
        <mat-form-field appearance="fill" class="bt col-md-12" >
            <mat-label>Historia detallada</mat-label>
            <textarea matInput placeholder="" maxlength="2500" #historia formControlName="desHitoria"></textarea>
            <mat-hint align="start"><strong></strong> </mat-hint>
            <mat-hint align="end">{{historia.value.length}} / 2500</mat-hint>
        </mat-form-field>

        <h3>Perfil diagnóstico previo</h3>

        <div>
            <mat-form-field appearance="fill" class="bt col-md-6">
                <mat-label>Discapacidad</mat-label>
                <mat-select formControlName="idDiscapacidad" multiple #discapacidadesf>
                <mat-select-trigger>
                    {{discapacidadesf.value ? discapacidadesf.value[0] : ''}}
                    <span *ngIf="discapacidadesf.value?.length > 1" class="example-additional-selection">
                    (+{{discapacidadesf.value.length - 1}} {{discapacidadesf.value?.length === 2 ? 'más' : 'más'}})
                    </span>
                </mat-select-trigger>
                <mat-option *ngFor="let discapacidad of discapacidades" [value]="discapacidad">{{discapacidad}}</mat-option>
                </mat-select>
            </mat-form-field>      
        </div>
        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Diagnóstico neurológico previo</mat-label>
            <mat-select formControlName="diagnosticoNeurologico">
                <mat-option value="{{dn.idParametro}}" *ngFor="let dn of diagnosticoNeurologico">{{dn.valor}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento diagnóstico neurológico</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo.value" formControlName="dnRuta" readonly>
            <button mat-icon-button matSuffix (click)="archivo.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo class="hide" formControlName="dnArchivo" (change)="ifg_handleFileInput1($event.target.files)"/>
            
            <a href="{{hostapi}}/{{documento1+'?t='+t}}" *ngIf="documento1" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
           
            <mat-hint *ngIf="documento1">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento1" (click)="postEliminarDocumentoBene(+idBeneficiaria, 52, documentos[23].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>

        

        <mat-form-field appearance="fill" class="bt col-md-12">
            <mat-label>Descripción</mat-label>
            <textarea matInput placeholder="" maxlength="150" #descripcion formControlName="dnDescripcion"></textarea>
            <mat-hint align="end">{{descripcion.value.length}} / 150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Edad mental previo</mat-label>
            <input matInput placeholder="" type="number" maxlength="2" formControlName="edadMental">
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento edad mental</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo2.value" formControlName="dmRuta" readonly>
            <button mat-icon-button matSuffix (click)="archivo2.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo2 class="hide" formControlName="emArchivo" (change)="ifg_handleFileInput2($event.target.files)"/>
            
            
            <a href="{{hostapi}}/{{documento2+'?t='+t}}" *ngIf="documento2" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
            
            <mat-hint *ngIf="documento2">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento2" (click)="postEliminarDocumentoBene(+idBeneficiaria, 53, documentos[1].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>

        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Diagnóstico motríz previo</mat-label>
            <input matInput placeholder="" maxlength="150" #motriz formControlName="diagnosticoMotriz">
            <mat-hint align="end">{{motriz.value.length}} / 150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento diagnóstico motríz</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo3.value" formControlName="dmRuta" readonly>
            <button mat-icon-button matSuffix (click)="archivo3.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo3 class="hide" formControlName="dmArchivo" (change)="ifg_handleFileInput3($event.target.files)"/>
            
            
            <a href="{{hostapi}}/{{documento3+'?t='+t}}" *ngIf="documento3" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
            
            <mat-hint *ngIf="documento3">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento3" (click)="postEliminarDocumentoBene(+idBeneficiaria, 54, documentos[0].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>

        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Diagnóstico psicológico previo</mat-label>
            <input matInput placeholder="" maxlength="150" #psicologico formControlName="diagnosticoPsicologico">
            <mat-hint align="end">{{psicologico.value.length}} / 150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento diagnóstico psicológico</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo4.value" formControlName="dpRuta" readonly>
            <button mat-icon-button matSuffix (click)="archivo4.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo4 class="hide" formControlName="dpArchivo" (change)="ifg_handleFileInput4($event.target.files)"/>
            

            <a href="{{hostapi}}/{{documento4+'?t='+t}}" *ngIf="documento4" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
            
            <mat-hint *ngIf="documento4">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento4" (click)="postEliminarDocumentoBene(+idBeneficiaria, 55, documentos[2].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>

        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Diagnóstico médico previo</mat-label>
            <input matInput placeholder="" maxlength="150" #medico formControlName="diagnosticoMedico">
            <mat-hint align="end">{{medico.value.length}} / 150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento diagnóstico médico</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo5.value" formControlName="dmedRuta" readonly>
            <button mat-icon-button matSuffix (click)="archivo5.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo5 class="hide" formControlName="dmedArchivo" (change)="ifg_handleFileInput5($event.target.files)"/>
            
            
            <a href="{{hostapi}}/{{documento5+'?t='+t}}" *ngIf="documento5" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
            
            <mat-hint *ngIf="documento5">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento5" (click)="postEliminarDocumentoBene(+idBeneficiaria, 56, documentos[3].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>

        <br><br>
        <div class="text-align-center">
            <button class="hide" type="reset" id="resetForm">Reset</button>
            <button mat-stroked-button class="flat" type="button" [routerLink]="['/beneficiarias']">Cancelar</button>
            &nbsp;
            <button type="submit" class="flat" color="primary" mat-flat-button *ngIf="!existente">Crear expediente</button>
            
            <button type="submit" class="flat" color="primary" mat-flat-button *ngIf="existente">Guardar</button>

        </div>
    </form>
</div>