import { Sesion } from './sesion';

export interface Objeto{
    text:string;
    icon:string;
    href:string;
    childs:[Objeto];
}

export class Configuracion {
    idEmpresa:string;
    endpoints:any;
    session:Sesion;
    menu:[Objeto];
    cookieName:string;
    appName:String;
    codes:any;
    estatusDoctos:any;
    perfiles:any;
    ui:UI;
    hostapi:string;
    embedPhpPath:string;
    laravelapi:string;
    laravelapiStorage:string;
}
export class UI{
    paginador : Paginador;
    snackBar : SnackBar
}

export class Paginador{
    pageSize:number;
    pageOptions:number[];
}
export class SnackBar{
    durationInSeconds : number;
}
export class ExternalConfig{
    hostname:string;
    projectname:string;
    environment:string;
}