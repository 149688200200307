import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DesitionBoxComponent } from 'src/app/modals/desition-box/desition-box.component';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { Paginador } from 'src/app/model/configuracion';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { SesionService } from 'src/app/services/sesion.service';
const ELEMENT_DATA: any[] = [];
@Component({
  selector: 'app-centro-de-informacion-institucional',
  templateUrl: './centro-de-informacion-institucional.component.html',
  styleUrls: ['./centro-de-informacion-institucional.component.scss']
})
export class CentroDeInformacionInstitucionalComponent implements OnInit {
  acciones:any=[];
  tieneAccionCrear = false;
  tieneAccionEliminar = false;
  tieneAccionEditar = false;
  aCategorizacion:any=[];
  aTipoDocumentos:any=[];
  paginador:Paginador;
  pagesize = 0;
  isLoading=false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  displayedColumns: string[] = ['position','nombre', 'tipo','descripcion', 'fecha_alta','ver','descargar','editar','eliminar'];
  formulario:UntypedFormGroup=new UntypedFormGroup({
    buscador:new UntypedFormControl("",[]),
    tipo:new UntypedFormControl("",[])
  })
  constructor(private _dialog:MatDialog,private _config:ConfigurationService,private _documentos_service:DocumentosService, private _sesion:SesionService) { 
    this.paginador = this._config.getConfiguration().ui.paginador;
    this.pagesize=this.paginador.pageSize;
    this._documentos_service.getComboTipos().subscribe(result=>{
      this.aTipoDocumentos = result.data.catalogo;
      for(let i=0; i<result.data.catalogo.length;i++){
        if(i>0){
          if(result.data.catalogo[i].categorizacion != result.data.catalogo[i-1].categorizacion){
            this.aCategorizacion.push(result.data.catalogo[i]);
          }
        }else{
          this.aCategorizacion.push(result.data.catalogo[i]);
        }
      }
      console.info(this.aCategorizacion);
      console.info(this.aTipoDocumentos);
      this.tieneAccionEliminar = this.consultarAccionUsuario("/centro-informacion-institucional/delete");
      this.tieneAccionEditar = this.consultarAccionUsuario("/centro-informacion-institucional/edit");
      this.tieneAccionCrear = this.consultarAccionUsuario("/centro-informacion-institucional/new");
    });
  }
  

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.buscar(0,this.paginador.pageSize);
    
  }
  count=0;
  buscar(index:number,size:number){
    
    this.isLoading=true;
    this._documentos_service.buscaDocumentos(this.formulario.controls["buscador"].value,index,size,null,this.formulario.controls["tipo"].value).subscribe(result=>{
      this.count = result["size"];
      this.isLoading=false;
      this.dataSource = new MatTableDataSource<any>(result.data.documentos);
    },()=>{
      this.isLoading=false;
    });
  }
  sendForm(){
    // This is intentional
  }
  pager(event){
    this.pagesize=event.pageSize;
    this.paginator.pageSize = event.pageSize;
    this.paginator.pageIndex = event.pageIndex;
    this.buscar(event.pageIndex*event.pageSize ,event.pageSize);
  }
  consultarAccionUsuario(urlRecibida){
    if(this._sesion.getSession().getUsuario().nombre!=undefined){
      this.acciones = this._sesion.getSession().getAcciones();
      if(this.acciones.filter(x => x.url == urlRecibida)[0]){
        return true;        
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  eliminar(id){
    const dialogRef = this._dialog.open(DesitionBoxComponent,{
      data: {titulo: "¿Desea eliminar el documento seleccionado?", mensaje: "La información del documento será eliminada de manera permanente, ¿Desea continuar?", desicion:true}
    })

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if(result){
        this.isLoading=true;
        this._documentos_service.deleteByid(id).subscribe(resultado=>{
          this.isLoading=false;
          console.info(resultado);
          this.paginator.pageIndex = 0;
          this.buscar(this.paginator.pageIndex * this.paginator.pageSize,this.paginator.pageSize);
          this._dialog.open(MessageBoxComponent,{
            data: {titulo:"Documento eliminado", mensaje:"El documento ha sido eliminado del sistema"}
          })
        })
      }
    });
  }
}
