//General
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { TratamientosService } from 'src/app/services/tratamientos.service';
import { MedicoService } from 'src/app/services/medico.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios
import { EytService } from 'src/app/services/eyt.service';


@Component({
  selector: 'app-tratamientos-medicos1',
  templateUrl: './tratamientos-medicos1.component.html',
  styleUrls: ['./tratamientos-medicos1.component.scss'],
  providers: [ TratamientosService, EytService ]
})
export class TratamientosMedicos1Component implements OnInit {
  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    //tratamientos
    numero_tratamiento: new UntypedFormControl('GTTI-EM-', [Validators.pattern(/^GTTI-EM-\d+-.+$/)]),
    numero_medico: new UntypedFormControl(1, []),
    numero_tratamiento_medico: new UntypedFormControl(1, []),
    estado_boton: new UntypedFormControl(1, []),
    otro: new UntypedFormControl('', []),
    resultado_tratamiento: new UntypedFormControl(1, []),


    //indicaciones generales
    require_especialiste: new UntypedFormControl('', []),
    esppecialista_esp: new UntypedFormControl('', []),
    require_terapia: new UntypedFormControl('', []),
    fecha_incio: new UntypedFormControl('', []),
    fecha_fin: new UntypedFormControl('', []),
    examen: new UntypedFormControl('', []),
    examen_esp: new UntypedFormControl('', []),
    nombre_examen: new UntypedFormControl('', []),
    otros_comentarios_text: new UntypedFormControl('', []),

    //docs
    nombre: new UntypedFormControl('', []),
    numero_documento_registrado: new UntypedFormControl('', []),



    



  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  @Input() selectedIndex : number = 0;
  @Output() siguienteEvento = new EventEmitter<string>();
  public id: any;
  public id_ev: any;
  public tratamientos: any = [];
  public tratamientos2: any = [];
  public presentacion: any;
  public data: any;
  public laravelapiStorage: any;
  public t: any;

  



  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _TratamientosService: TratamientosService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
    private _EytService: EytService,
    private _medicoService: MedicoService,
  ) { 
    let url_list = this._router.url.split("/");
    this.accion = url_list[6].split("?")[0];
    console.log(this.accion);

    this.laravelapiStorage = this._TratamientosService.getLaravelApiStorage();
    /*modificar*/
    this.form.markAllAsTouched();
    this._route.queryParams.subscribe(
      async params => {
        this.id_ev = +params['id_ev'];
        this.id = +params['id'];
        if(this.id){
          console.log('IDDDD: ' + this.id);
          this.form.patchValue({ numero_medico: this.id_ev, numero_tratamiento: 'GTTI-EM-'+this.id_ev+'-'});


          
          console.log(this.form.value);
          await this.postConsultaTratamientosInformaciono(this.id_ev);
          
          //this.getConsultaDatosHistorialClinico(this.id);
          //this.getDatosClinicosForm();

          
          

        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    
    //this.getConsultaMedicamentosInv();
  }

  public medicamentosTodos: any = [];
  public getConsultaMedicamentosInv(page: number = 1){
    this.isLoading = true;
    this._medicoService.getConsultaMedicamentosInv(page, 100000, '').subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.medicamentosTodos = response.data.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public async postConsultaTratamientosInformaciono(id: number, especial: number = 0){ //especial es para que no recargue de nuevo todos los datos en caso de solo guardar un mediamento o documento
    console.log('asjsajsdhas');
    this.isLoading = true;
    this._TratamientosService.postConsultaTratamientosInformaciono(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.data = response.data;
          console.log(this.data.tratamientos.length);
          for(let i = 0; i < this.data.tratamientos.length; i++){


            //if(especial == 0){
              //medicamentos
              this.data.tratamientos[i].nombre_medicamento = '';
              this.data.tratamientos[i].presentacion = '';
              this.data.tratamientos[i].concentracion = '';
              this.data.tratamientos[i].nombre_generico = '';


              this.data.tratamientos[i].numero_presentacion = '';
              this.data.tratamientos[i].dosis = '';
              this.data.tratamientos[i].frecuencia = '';
              this.data.tratamientos[i].duracion = '';


              this.data.tratamientos[i].numero_anexo_uno = '';
              this.data.tratamientos[i].numero_anexo_dos = '';
              this.data.tratamientos[i].numero_anexo_tres = '';




              //documentos
              this.data.tratamientos[i].pdf = '';
              this.data.tratamientos[i].numero_tratamiento = '';
              this.data.tratamientos[i].nombre = '';
              this.data.tratamientos[i].fecha_elaboracion = '';


              //indicaciones generales
              if(this.data.tratamientos[i]?.indicaciones_generales){
                this.data.tratamientos[i].indicacion_general = this.data.tratamientos[i]?.indicaciones_generales.indicacion_general;
                this.data.tratamientos[i].require_especialista = this.data.tratamientos[i]?.indicaciones_generales.require_especialista;
                this.data.tratamientos[i].especialista_esp = this.data.tratamientos[i]?.indicaciones_generales.especialista_esp;
                this.data.tratamientos[i].require_terapia = this.data.tratamientos[i]?.indicaciones_generales.require_terapia;
                this.data.tratamientos[i].terapia_esp = this.data.tratamientos[i]?.indicaciones_generales.terapia_esp;
                this.data.tratamientos[i].fecha_inicio = this.data.tratamientos[i]?.indicaciones_generales.fecha_inicio;
                this.data.tratamientos[i].fecha_fin = this.data.tratamientos[i]?.indicaciones_generales.fecha_fin;
                this.data.tratamientos[i].examen = this.data.tratamientos[i]?.indicaciones_generales.examen;
                this.data.tratamientos[i].examen_esp = this.data.tratamientos[i]?.indicaciones_generales.examen_esp;
                this.data.tratamientos[i].nombre_examen = this.data.tratamientos[i]?.indicaciones_generales.nombre_examen;
                this.data.tratamientos[i].otros_comentarios_text = this.data.tratamientos[i]?.indicaciones_generales.otros_comentarios_text;
                //this.data.tratamientos[i].otros_comentarios_text = this.data.tratamientos[i]?.indicaciones_generales.resultado_tratamiento;
              }else{
                this.data.tratamientos[i].indicacion_general = '';
                this.data.tratamientos[i].require_especialista = 1;
                this.data.tratamientos[i].especialista_esp = '';
                this.data.tratamientos[i].require_terapia = 1;
                this.data.tratamientos[i].terapia_esp = '';
                this.data.tratamientos[i].fecha_inicio = '';
                this.data.tratamientos[i].fecha_fin = '';
                this.data.tratamientos[i].examen = 1;
                this.data.tratamientos[i].examen_esp = '';
                this.data.tratamientos[i].nombre_examen = '';
                this.data.tratamientos[i].otros_comentarios_text = '';
                //this.data.tratamientos[i].resultado_tratamiento = 1;
              }
            
            //}

            
            this.data.tratamientos[i].numero_tratamiento = this.data.tratamientos[i].id_tratamiento_pk;

            for(let k = 0; k < this.data.tratamientos[i].medicamentos.length; k++){
              console.log(this.data.tratamientos[i].medicamentos[k]);
              if(!this.data.tratamientos[i].medicamentos[k].presentacion){
                this.data.tratamientos[i].medicamentos[k].presentacion = '';
              }else{
                this.data.tratamientos[i].medicamentos[k].presentacion = this.data.tratamientos[i].medicamentos[k].presentacion.presentacion;
              }
              this.data.tratamientos[i].medicamentos[k].numero_presentacion = this.data.tratamientos[i].medicamentos[k].id_presentacion_fk;
              this.data.tratamientos[i].medicamentos[k].numero_anexo_uno = this.data.tratamientos[i].medicamentos[k].id_anexo_uno_fk;
              this.data.tratamientos[i].medicamentos[k].numero_anexo_dos = this.data.tratamientos[i].medicamentos[k].id_anexo_dos_fk;
              this.data.tratamientos[i].medicamentos[k].numero_anexo_tres = this.data.tratamientos[i].medicamentos[k].id_anexo_tres_fk;

              this.data.tratamientos[i].medicamentos[k].numero_tratamiento = this.data.tratamientos[i].id_tratamiento_pk;
              this.data.tratamientos[i].medicamentos[k].numero_identificador_tratamiento = this.data.tratamientos[i].medicamentos[k].id_medicamento_pk;
            }


          }

          console.log(this.data);

          
          
          
          
          
          

        }

        this.getConsultaDatosFormTratamientos();
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public anexo_uno: any = [];
  public anexo_dos: any = [];
  public anexo_tres: any = [];

  // Función de comparación personalizada
  public ordenarPorPropiedad(array: any, propiedad: any) {
    array.sort(function(a, b) {
      return a[propiedad].localeCompare(b[propiedad], 'es', {sensitivity: 'base'});
    });
    return array;
  }

  public getConsultaDatosFormTratamientos(){
    console.log('asjsajsdhas');
    this.isLoading = true;
    this._TratamientosService.getConsultaDatosFormTratamientos().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.tratamientos = response.data.tratamientos;
          
          response.data.presentacon = this.ordenarPorPropiedad(response.data.presentacon, 'presentacion');
          this.presentacion = response.data.presentacon;

          this.anexo_uno = response.data.anexo_uno;
          this.anexo_dos = response.data.anexo_dos;
          this.anexo_tres = response.data.anexo_tres;

          this.tratamientos = this.ordenarPorPropiedad(this.tratamientos, 'tratamiento');
          //this.tratamientos2 = this.ordenarPorPropiedad(this.tratamientos2, 'tratamiento');
          this.presentacion = this.ordenarPorPropiedad(this.presentacion, 'presentacion');
          this.anexo_uno = this.ordenarPorPropiedad(this.anexo_uno, 'anexo_uno');
          this.anexo_dos = this.ordenarPorPropiedad(this.anexo_dos, 'anexo_dos');
          this.anexo_tres = this.ordenarPorPropiedad(this.anexo_tres, 'anexo_tres');
          console.log(this.presentacion);
          
          this.tratamientosSoloUno(response);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }


  public getNombreTratamiento(id: number){
    for(let y = 0; y < this.tratamientos?.length; y++){
      if(id ==  this.tratamientos[y].id_tratamiento_pk){
        return this.tratamientos[y].tratamiento;
      }
    }
  }


  public tratamientosSoloUno(response: any){
    console.log(this.data);
    console.log(this.tratamientos2);

    //for(let i = 0; i < this.tratamientos?.length; i++){
    //  this.tratamientos2.push(this.tratamientos[i]);
    //}
    //console.log(this.tratamientos2);
    

    if(this.data.tratamientos?.length > 0){
      var id = 0;
      var tratamiento: any;
      if(this.data.tratamientos?.length > 0){
        id = this.data.tratamientos[0].id_tratamiento_medico_fk;
        console.log(id);
      }

      for(let o = 0; o < this.tratamientos?.length; o++){
        console.log(o);
        /*for(let k = 0; k < this.data.tratamientos?.length; k++){
          if(this.data.tratamientos[k].id_tratamiento_medico_fk != this.tratamientos2[o].id_tratamiento_pk){
            console.log('ETRANDIO');
            this.tratamientos2.splice(o, 1);
            o--;
          }
  
  
        }*/
        if(id == this.tratamientos[o].id_tratamiento_pk){
          console.log('yetsvdusf');
          this.tratamientos2 = [];
          this.tratamientos2.push( this.tratamientos[o] );
          this.form.patchValue({
            numero_tratamiento_medico: this.tratamientos[o].id_tratamiento_pk
          });
        }
      }


    }else{
      this.tratamientos2 = response.data.tratamientos;
    }

    

    
    
    
    console.log(this.tratamientos);
    console.log(this.tratamientos2);

    //this.tratamientos = this.tratamientos.slice(0, 1);



  }

  //validando agregar tratamiento
  public valAddTra(){
    var v = true;
    for(let i = 0; i < this.data?.tratamientos.length; i++){
      if(this.data.tratamientos[i].id_estatus_medico_fk == 1){
        v = false;
      }
    }
    return v;
  }

  

  



  //TRATAMIENTO
  public postRegistrarTratamiento(){
    this.isLoading = true;
    this._TratamientosService.postRegistrarTratamiento(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.form.patchValue({ numero_medico: this.id_ev, numero_tratamiento: 'GTTI-EM-'+this.id_ev+'-'});
          this.postConsultaTratamientosInformaciono(this.id_ev);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: 'ID Tratamiento repetido', icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }


  public postModificarTratamiento(i: number, k: number = 0){
    this.isLoading = true;
    this._TratamientosService.postModificarTratamiento({
      numero_tratamiento: this.data.tratamientos[i].id_trata,
      numero_medico: this.id_ev,
      numero_tratamiento_medico: this.data.tratamientos[i].id_tratamiento_medico_fk,
      estado_boton: this.data.tratamientos[i].id_estatus_medico_fk,
      otro: this.data.tratamientos[i].otro,
      numero_identificador_tratamiento: this.data.tratamientos[i].id_tratamiento_pk,
      resultado_tratamiento: this.data.tratamientos[i].resultado_tratamiento,
    }).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postConsultaTratamientosInformaciono(this.id_ev);
          if(k == 0){
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          }
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postModificarTratamientoEstado(i: number){
    this.isLoading = true;
    this._TratamientosService.postModificarTratamientoEstado({
      estado_boton: 2,
      numero_identificador_tratamiento: this.data.tratamientos[i].id_tratamiento_pk,
    }).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postConsultaTratamientosInformaciono(this.id_ev);
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  //INDICACIONES GENERALES
  public postRegistrarIndicacionesGenerales(i: number, especial: number = 0){
    this.isLoading = true;
    console.log(this.data);
    console.log(this.data.tratamientos[i]);
    this._TratamientosService.postRegistrarIndicacionesGenerales(this.data.tratamientos[i]).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postModificarTratamiento(i, 1);
          //this.postConsultaTratamientosInformaciono(this.id_ev);
          if(especial == 0){
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa .', mensaje: response.mensaje, icon:'correct'}});
          }
          
        }else{
          if(especial == 0){
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error .', mensaje: response.mensaje, icon:'error'}});
          }
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  //DOCUMENTOS
  public onchangeArchivo(event: any, i: number) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      var caso = this.sizeFilePDF(event);
      //file.type.startsWith('application/pdf')
      if (file.type.startsWith('application/pdf')) {//application/pdf
        if(caso == 1){
          this.data.tratamientos[i].pdf = file;
        }

        if(caso == 2){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Problema con el servicio', mensaje: 'Máximo 10MB', icon:'correct'}});
        }
      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Inválido formato.', icon:'error'}});
      }
    }
  }

  public sizeFilePDF(event: any){
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      //file.type.startsWith('application/pdf')
      if(true){
        if(event.target.files[0].size <= 10485760){
          console.log('Maximo tamaño de imagen 10MB');
          return 1;
        }else{
          return 2;
        }
      }else{
        return 3;
      }
    }else{
      return 4;
    }
  }

  public async postRegistrarDocumentosTratamientos(i: number){
    this.isLoading = true;
    await this.postRegistrarIndicacionesGenerales(i, 1);
    console.log(this.data.tratamientos[i]);
    var formData = new FormData();
    formData.set('nombre', this.data.tratamientos[i].nombre);
    formData.set('fecha_elaboracion', this.data.tratamientos[i].fecha_elaboracion);
    formData.set('pdf', this.data.tratamientos[i].pdf);
    formData.set('numero_tratamiento', this.data.tratamientos[i].numero_tratamiento);
    this._TratamientosService.postRegistrarDocumentosTratamientos(formData).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postConsultaTratamientosInformaciono(this.id_ev);
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          //this.getConsultaDatosHistorialClinico(this.id);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public onchangeArchivoDos(event: any, i: number, n: number) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      var caso = this.sizeFilePDF(event);
      //file.type.startsWith('application/pdf')
      if (file.type.startsWith('application/pdf')) {//application/pdf
        if(caso == 1){
          this.data.tratamientos[i].documentos[n].pdf = file;
        }

        if(caso == 2){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Problema con el servicio', mensaje: 'Máximo 10MB', icon:'correct'}});
        }
      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Inválido formato.', icon:'error'}});
      }
    }
  }

  public async postRegistrarDocumentosTratamientosDos(i: number, n: number){
    this.isLoading = true;
    await this.postRegistrarIndicacionesGenerales(i, 1);
    console.log(this.data.tratamientos[i]);
    var formData = new FormData();
    formData.set('nombre', this.data.tratamientos[i].documentos[n].nombre);
    formData.set('fecha_elaboracion', this.data.tratamientos[i].documentos[n].fecha);
    formData.set('pdf', this.data.tratamientos[i].documentos[n].pdf);
    formData.set('numero_tratamiento', this.data.tratamientos[i].numero_tratamiento);
    formData.set('numero_documento_registrado', this.data.tratamientos[i].documentos[n].id_documento_evento_pk);
    this._TratamientosService.postRegistrarDocumentosTratamientos(formData).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postConsultaTratamientosInformaciono(this.id_ev);
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public async postEliminarDocumentosTratamientos(i: number, k: number){
    this.isLoading = true;
    await this.postRegistrarIndicacionesGenerales(i, 1);
    this._TratamientosService.postEliminarDocumentosTratamientos(
      {numero_documento_registrado: this.data.tratamientos[i].documentos[k].id_documento_evento_pk}
    ).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postConsultaTratamientosInformaciono(this.id_ev);
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }


  //MEDICAMENTOS
  public postRegistrarMedicamentos(i: number){
    this.isLoading = true;

    /*for(let m = 0; m < this.presentacion.length; m++){
      if(this.presentacion[m].id_presentacion_pk == this.data.tratamientos[i].numero_presentacion){
        this.data.tratamientos[i].presentacion = this.presentacion[m].presentacion;
        this.data.tratamientos[i].string_anexo_tres_fk = this.presentacion[m].presentacion;
        
      }
    }

    for(let m = 0; m < this.anexo_tres.length; m++){
      if(this.anexo_tres[m].id_anexo_tres_pk == this.data.tratamientos[i].numero_anexo_tres){
        this.data.tratamientos[i].nombre_medicamento = this.anexo_tres[m].anexo_tres;
      }
    }

    for(let m = 0; m < this.anexo_dos.length; m++){
      if(this.anexo_dos[m].id_anexo_dos_pk == this.data.tratamientos[i].numero_anexo_dos){
        this.data.tratamientos[i].string_anexo_dos_fk = this.anexo_dos[m].anexo_dos;
      }
    }

    for(let m = 0; m < this.anexo_uno.length; m++){
      if(this.anexo_uno[m].id_anexo_uno_pk == this.data.tratamientos[i].numero_anexo_uno){
        this.data.tratamientos[i].string_anexo_uno_fk = this.anexo_uno[m].anexo_uno;
      }
    }*/

    
    this._TratamientosService.postRegistrarMedicamentos(this.data.tratamientos[i]).subscribe(
      response => {
        this.medicamentosCaso(1, response, i); 
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postModificarMedicamentos(i: number, k: number){
    this.isLoading = true;
    console.log(this.data.tratamientos[i]);
    

    /*for(let m = 0; m < this.presentacion.length; m++){
      if(this.presentacion[m].id_presentacion_pk == this.data.tratamientos[i].medicamentos[k].numero_presentacion){
        this.data.tratamientos[i].medicamentos[k].string_anexo_tres_fk = this.presentacion[m].presentacion.toString();
        console.log(this.presentacion[m]);
        //this.data.tratamientos[i].medicamentos[k].anexo_tres = this.presentacion[m].presentacion;
        this.data.tratamientos[i].medicamentos[k].presentacion = this.presentacion[m].presentacion;
      }
    }

    for(let m = 0; m < this.anexo_tres.length; m++){
      if(this.anexo_tres[m].id_anexo_tres_pk == this.data.tratamientos[i].medicamentos[k].id_tratamiento_fk){
        this.data.tratamientos[i].medicamentos[k].nombre_medicamento = this.anexo_tres[m].anexo_tres;
        //this.data.tratamientos[i].medicamentos[k].anexo_tres = this.anexo_tres[m].id_anexo_tres_pk;
      }
    }

    for(let m = 0; m < this.anexo_dos.length; m++){
      if(this.anexo_dos[m].id_anexo_dos_pk == this.data.tratamientos[i].medicamentos[k].numero_anexo_dos){
        this.data.tratamientos[i].medicamentos[k].string_anexo_dos_fk = this.anexo_dos[m].anexo_dos.toString();
        this.data.tratamientos[i].medicamentos[k].anexo_dos = this.anexo_dos[m].anexo_dos;
      }
    }

    for(let m = 0; m < this.anexo_uno.length; m++){
      if(this.anexo_uno[m].id_anexo_uno_pk == this.data.tratamientos[i].medicamentos[k].numero_anexo_uno){
        this.data.tratamientos[i].medicamentos[k].string_anexo_uno_fk = this.anexo_uno[m].anexo_uno.toString();
        this.data.tratamientos[i].medicamentos[k].anexo_uno = this.anexo_uno[m].anexo_uno;
      }
    }*/

    console.log(this.anexo_uno);
    console.log(this.anexo_dos);

    this._TratamientosService.postModificarMedicamentos(this.data.tratamientos[i].medicamentos[k]).subscribe(
      response => {
        this.medicamentosCaso(2, response, i);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postEliminarMedicamentos(i: number, k: number){
    this.isLoading = true;
    this._TratamientosService.postEliminarMedicamentos(this.data.tratamientos[i].medicamentos[k]).subscribe(
      response => {
        
        this.medicamentosCaso(3, response, i);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public async medicamentosCaso(i: number, response: any, tratamiento_i: number){
    console.log(response);
    this.isLoading = false;

    if(response.estado == 200){
      console.log(tratamiento_i);
      await this.postRegistrarIndicacionesGenerales(tratamiento_i, 1);
      if(i == 1){
        this.postConsultaTratamientosInformaciono(this.id_ev, 1);
      }else if(i == 2){
        this.postConsultaTratamientosInformaciono(this.id_ev, 1);
      }else if(i == 3){
        this.postConsultaTratamientosInformaciono(this.id_ev, 1);
      }
      
      this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
    }else{
      this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
    }
  }

  


  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }


  public valConcluir(){
    var v = true;
    for(let i = 0; i < this.data?.tratamientos?.length; i++){
      if(this.data.tratamientos[i].id_estatus_medico_fk == 1){
        v = false;
      }
    }
    return v;
  }





}
