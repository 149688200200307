import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ParametrosService } from 'src/app/services/parametros.service';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios

@Component({
  selector: 'app-situacion-general',
  templateUrl: './situacion-general.component.html',
  styleUrls: ['./situacion-general.component.scss']
})
export class SituacionGeneralComponent implements OnInit {

  hostapi:string;
  sacramentos = ["Bautismo","Primera comunión","Confirmación"];
  public t: any;
  constructor(private dialog:MatDialog,
    private _parametrosService:ParametrosService,
    private activatedRoute:ActivatedRoute,
    private router: Router, 
    private _documentosService:DocumentosService,
    private _beneficiariaService:BeneficiariaService,
    private _cdRef:ChangeDetectorRef,
    private _conf:ConfigurationService) { 
      this.hostapi = _conf.getConfiguration().hostapi;
    }

    idBeneficiaria= 0;
  formulario = new UntypedFormGroup({
    situacionJuridica : new UntypedFormControl("",[]),
    sjDescripcion : new UntypedFormControl("",[]),
    convivencias : new UntypedFormControl("",[]),
    convivenciasDescripcion : new UntypedFormControl("",[]),
    escolaridad : new UntypedFormControl("",[]),
    ultimoGrado : new UntypedFormControl("",[]),
    comprobanteDeEstudios: new UntypedFormControl("",[]),
    ceArchivo:new UntypedFormControl("",[]),

    nombreDocumento1:new UntypedFormControl("",[]),
    rutaDocumento1:new UntypedFormControl("",[]),
    documento1Archivo: new UntypedFormControl("",[]),

    nombreDocumento2:new UntypedFormControl("",[]),
    rutaDocumento2:new UntypedFormControl("",[]),
    documento2Archivo:new UntypedFormControl("",[]),

    nombreDocumento3:new UntypedFormControl("",[]),
    rutaDocumento3:new UntypedFormControl("",[]),
    documento3Archivo:new UntypedFormControl("",[]),
    bautismo:new UntypedFormControl(false,[]),
    comunion:new UntypedFormControl(false,[]),
    confirmacion:new UntypedFormControl(false,[]),

  })
  fileToUpload: File = null;
  fileToUpload1: File = null;
  fileToUpload2: File = null;
  fileToUpload3: File = null;
  fileToUpload4: File = null;
  fileToUpload5: File = null;
  isLoading = false;
  sg_handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  sg_handleFileInput1(files: FileList) {
    this.fileToUpload1 = files.item(0);
  }
  sg_handleFileInput2(files: FileList) {
    this.fileToUpload2 = files.item(0);
  }
  sg_handleFileInput3(files: FileList) {
    this.fileToUpload3 = files.item(0);
  }
  sg_handleFileInput4(files: FileList) {
    this.fileToUpload4 = files.item(0);
  }
  sg_handleFileInput5(files: FileList) {
    this.fileToUpload5 = files.item(0);
  }
  ngOnInit(): void {
    this.sg_cargaCatalogos();
    this.activatedRoute.queryParams.subscribe(params=>{
      console.info("Parametros");
      console.info(params["id"]);
      
        this.idBeneficiaria= params["id"];
        
        
        this.sg_getDocumentos(this.idBeneficiaria);
      
      
    });
  }

  sg_sendForm(){
    this._cargarListaDoc();
    //this.sg_cargarDocumentos();
  }
  situacionJuridica:any;
  escolaridad:any;
  sg_cargaCatalogos(){
    //param motivo ingreso
    this._parametrosService.getParametros("13").subscribe(result=>{
      this.escolaridad = result.data.parametros;
    });

    this._parametrosService.getParametros("12").subscribe(result=>{
      this.situacionJuridica = result.data.parametros;
    });
  }

  arreglo = [];
  sg_cargarArreglo(tipoDocumento:string,idTipoCatalogo:string,desTipoCatalogo:string,file:File){
    this.arreglo.push({"file":file,"idBeneficiaria":this.idBeneficiaria.toString(),"idTipoCatalogo":idTipoCatalogo,"desTipoCatalogo":desTipoCatalogo,"tipoDocumento":tipoDocumento});
  }

  

  _cargarListaDoc(){
    return new Promise((resolve, reject) => {

      this.sg_cargarArreglo("63",this.formulario.controls.escolaridad.value,this.formulario.controls.ultimoGrado.value,this.fileToUpload1);
      this.sg_cargarArreglo("81","",this.formulario.controls.nombreDocumento1.value,this.fileToUpload2);
      this.sg_cargarArreglo("82","",this.formulario.controls.nombreDocumento2.value,this.fileToUpload3);
      this.sg_cargarArreglo("83","",this.formulario.controls.nombreDocumento3.value,this.fileToUpload4);

      this.sg_cargarArreglo("85",this.formulario.controls.situacionJuridica.value,this.formulario.controls.sjDescripcion.value,null);
      this.sg_cargarArreglo("86",this.formulario.controls.convivencias.value,this.formulario.controls.convivenciasDescripcion.value,null);
      console.info(this.formulario.controls.bautismo.value+","+this.formulario.controls.comunion.value+","+this.formulario.controls.confirmacion.value);
      this.sg_cargarArreglo("90","",this.formulario.controls.bautismo.value+","+this.formulario.controls.comunion.value+","+this.formulario.controls.confirmacion.value,null);
      let formData = new FormData();
      formData.append('contador', "7");
      for(let sg=0; sg<this.arreglo.length; sg++){
        if(sg==0){
          formData.append('documento', this.arreglo[sg].file);
          formData.append('idBeneficiaria', this.idBeneficiaria.toString());
          formData.append('idTipoDocumento', this.arreglo[sg].tipoDocumento);
          formData.append('idTipoCatalogo', this.arreglo[sg].idTipoCatalogo);
          formData.append('desTipoCatalogo', this.arreglo[sg].desTipoCatalogo);
        }else{
          formData.append('documento'+sg, this.arreglo[sg].file);
          formData.append('idBeneficiaria'+sg, this.idBeneficiaria.toString());
          formData.append('idTipoDocumento'+sg, this.arreglo[sg].tipoDocumento);
          formData.append('idTipoCatalogo'+sg, this.arreglo[sg].idTipoCatalogo);
          formData.append('desTipoCatalogo'+sg, this.arreglo[sg].desTipoCatalogo);
        }
      }

      this.isLoading=true;
      this._documentosService.saveMulti(formData).subscribe(result=>{
        console.info(result);
        this.isLoading=false;
        this.sg_mensajeDeExito2();
        resolve(true);
      },()=>{
        this.isLoading=false;
        resolve(true);
      })
        
   
      
    });
  }

  sg_cargarDocumentos(){
    
    
    this.sg_altaDocumento("63",this.formulario.controls.escolaridad.value,this.formulario.controls.ultimoGrado.value,this.fileToUpload1).then(()=>{
      this.sg_altaDocumento("81","",this.formulario.controls.nombreDocumento1.value,this.fileToUpload2).then(()=>{
        this.sg_altaDocumento("82","",this.formulario.controls.nombreDocumento2.value,this.fileToUpload3).then(()=>{
          this.sg_altaDocumento("83","",this.formulario.controls.nombreDocumento3.value,this.fileToUpload4).then(()=>{

            this.sg_mensajeDeExito2();
              
          })
        })
      })
    });
  }

  sg_altaDocumento(tipoDocumento:string,idTipoCatalogo:string,desTipoCatalogo:string,file:File){
    
    return new Promise((resolve, reject) => {
      //if(file==null){
      //  resolve(true);
      //}else{
        let formData = new FormData();
        formData.append('documento', file);
        formData.append('idBeneficiaria', this.idBeneficiaria.toString());
        formData.append('idTipoDocumento', tipoDocumento);
        formData.append('idTipoCatalogo', idTipoCatalogo);
        formData.append('desTipoCatalogo', desTipoCatalogo);
        this.isLoading=true;
        this._documentosService.save(formData).subscribe(result=>{
          console.info(result);
          this.isLoading=false;
          resolve(true);
        },()=>{
          this.isLoading=false;
          resolve(true);
        })
      //}
      
    });
  }

  
  sg_mensajeDeExito(){
    const dialogRef= this.dialog.open(MessageBoxComponent,{
      data:{titulo: "Expediente creado" ,mensaje: "Se ha creado un nuevo expediente"}
    });
    this.router.navigate(["/beneficiarias"]);
  }
  sg_mensajeDeExito2(){
    const dialogRef= this.dialog.open(MessageBoxComponent,{
      data:{titulo: "Expediente modificado" ,mensaje: "Se ha modificado el expediente correctamente"}
    });
    dialogRef.afterClosed().subscribe(()=>{
      document.getElementById("resetForm").click();
      this.ngOnInit();
    })
  }
  documentos:any;
  documento1 = null;
  documento2 = null;
  documento3 = null;
  documento4 = null;
  documento5 = null;
  sg_getDocumentos(id:number){
    this.isLoading = true;
    this._documentosService.getAllByBeneficiaria(id).subscribe(result=>{
      this.isLoading = false;
      this.documentos = result.data.documentos;

      for(let sg = 0; sg< this.documentos.length; sg++){
        if(this.documentos[sg].idTipoDocumento == 63){
          this.formulario.controls.escolaridad.setValue(this.documentos[sg].idTipoCatalogo);
          this.formulario.controls.ultimoGrado.setValue(this.documentos[sg].desTipoCatalogo);
          //this.formulario.controls.comprobanteDeEstudios.setValue(this.documentos[sg].docTipoCatalogo);

          this.documento1 = this.documentos[sg].docTipoCatalogo;
        }else if(this.documentos[sg].idTipoDocumento == 81){
          this.formulario.controls.nombreDocumento1.setValue(this.documentos[sg].desTipoCatalogo);
          //this.formulario.controls.rutaDocumento1.setValue(this.documentos[sg].docTipoCatalogo);
          
          this.documento2 = this.documentos[sg].docTipoCatalogo;
        }else if(this.documentos[sg].idTipoDocumento == 82){
          this.formulario.controls.nombreDocumento2.setValue(this.documentos[sg].desTipoCatalogo);
          //this.formulario.controls.rutaDocumento2.setValue(this.documentos[sg].docTipoCatalogo);
          
          this.documento3 = this.documentos[sg].docTipoCatalogo;
        }else if(this.documentos[sg].idTipoDocumento == 83){
          this.formulario.controls.nombreDocumento3.setValue(this.documentos[sg].desTipoCatalogo);
          //this.formulario.controls.rutaDocumento3.setValue(this.documentos[sg].docTipoCatalogo);

          this.documento4 = this.documentos[sg].docTipoCatalogo;
        }else if(this.documentos[sg].idTipoDocumento == 85){
          this.formulario.controls.situacionJuridica.setValue(this.documentos[sg].idTipoCatalogo);
          this.formulario.controls.sjDescripcion.setValue(this.documentos[sg].desTipoCatalogo);
          //this.formulario.controls.rutaDocumento3.setValue(this.documentos[i].docTipoCatalogo);

        }else if(this.documentos[sg].idTipoDocumento == 86){
          this.formulario.controls.convivencias.setValue(this.documentos[sg].idTipoCatalogo);
          this.formulario.controls.convivenciasDescripcion.setValue(this.documentos[sg].desTipoCatalogo);
          //this.formulario.controls.rutaDocumento3.setValue(this.documentos[sg].docTipoCatalogo);

        }else if(this.documentos[sg].idTipoDocumento == 90){
          this.sg_docType90(sg);
        }
      }

    },()=>{
      this.isLoading = false;
    })
  }

  public sg_docType90(sg: any){
    this.formulario.controls.bautismo.setValue(this.documentos[sg].desTipoCatalogo.split(",")[0] == "true" ? true:false);
    this.formulario.controls.comunion.setValue(this.documentos[sg].desTipoCatalogo.split(",")[1]  == "true" ? true:false);
    this.formulario.controls.confirmacion.setValue(this.documentos[sg].desTipoCatalogo.split(",")[2]  == "true" ? true:false);
  }

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }

  public getEliminarDocumentoBeneficiario(beneficiario: number, numero_tipo_doc: number){
    console.log(beneficiario, numero_tipo_doc);
    this.isLoading=true;
    this._beneficiariaService.getEliminarDocumentoBeneficiario(beneficiario, numero_tipo_doc).subscribe(result=>{
      this.isLoading=false;
      //this.ifg_cargaExtras(result.data.id);
      
    },()=>{
      this.isLoading=false;
    });
  }


  //nuevas

  public postEliminarDocumentoBene(beneficiario: number, doc: number, url: string){
    //console.log(beneficiario, numero_tipo_doc);

    var j = 0;
    

    for(let i = 0; i < this.documentos.length ; i++){
      if(this.documentos[i].idTipoDocumento == doc){
        j = i;
      }
    }
    console.log(j);
    var u = this.documentos[j].docTipoCatalogo.split('./public_uploads/');
    console.log(u);

    var data = {
      url: u[1],
      numero_documento: doc,
      numero_beneficiario: beneficiario,
    }
    console.log(data); 
    this.isLoading=true;
    this._beneficiariaService.postEliminarDocumentoBene(data).subscribe(result=>{
      console.log(result);
      this.isLoading=false;
      this._cargarListaDoc();
      
    },()=>{
      this.isLoading=false;
    });
  }

}
