//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { RrhhService } from 'src/app/services/rrhh.service';
import { SesionService } from 'src/app/services/sesion.service';
import { DesitionBoxComponent } from 'src/app/modals/desition-box/desition-box.component';



@Component({
  selector: 'app-gestor-rrhh-expedientes-de-personal',
  templateUrl: './gestor-rrhh-expedientes-de-personal.component.html',
  styleUrls: ['./gestor-rrhh-expedientes-de-personal.component.scss'],
  providers: [ RrhhService ]
})
export class GestorRrhhExpedientesDePersonalComponent implements OnInit {

  //General
  public ghedp_matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public ghedp_form: UntypedFormGroup = this.ghedp_fb.group({
    buscador: new UntypedFormControl(3, []),
    buscar: new UntypedFormControl('', []),
    ruta: new UntypedFormControl('', []),
  });
  /*tabla*/
  public ghedp_length: number = 100;;
  public ghedp_pageSize: number = 25;
  public ghedp_pageSizeOptions: Array<number> = [5, 10, 25, 100];
  public ghedp_page: number = 1;
  public ghedp_displayedColumns: any =[
    'id_empleado', 
    'nombre_empleado',
    'puesto',
    'estatus',
    'documentos',
    'fecha_ingreso',
    'tiempo_laborando',
    'vacaciones_disponibles',
    'acciones'];
  public dataSource: any;
  /*fin tabla*/
  //All
  public ghedp_accion: string = '';
  public ghedp_isLoading: boolean = false;
  public ghedp_docs: any;
  public ghedp_laravelapi: any;
  public ghedp_laravelapiStorage: any;
  public ghedp_acciones: any;
  public ghedp_eliminar: boolean = false;
  public ghedp_consultar: boolean = false;
  public ghedp_modificar: boolean = false;

  //vacaciones
  public vaca_modificar: boolean = false;


  constructor(
    private ghedp_fb: UntypedFormBuilder,
    public  ghedp_dialog: MatDialog,
    private ghedp_rrhhService: RrhhService,
    private ghedp_router: Router,
    private ghedp_route: ActivatedRoute,
    private ghedp_sesionService:SesionService,
    private _dialog:MatDialog
  ){
    this.getExpedienteConsultas(1);
    //this.getConsultarDocumentosOrg();
    this.ghedp_laravelapi = this.ghedp_rrhhService.getLaravelApi();
    this.ghedp_laravelapiStorage = this.ghedp_rrhhService.getLaravelApiStorage();
    this.ghedp_eliminar = this.consultarAccionUsuario("/rrhh/gestor-de-expedientes-de-personal/eliminar");
    this.ghedp_consultar = this.consultarAccionUsuario("/rrhh/gestor-de-expedientes-de-personal/consultar");
    this.ghedp_modificar = this.consultarAccionUsuario("/rrhh/gestor-de-expedientes-de-personal/modificar");

    this.vaca_modificar = this.consultarAccionUsuario("/rrhh/gestor-de-expedientes-de-personal/ac-vacaciones/modificar");
  }

  ngOnInit(): void {
  }

  /*default*/
  /*public getTiempoLaborando(fecha: any){
    var today:any = new Date();
    var ingresoDate:any = new Date(fecha);
    //en horas
    //return Math.abs(today - ingresoDate) / 36e5;

    //en dias
    return Math.floor((Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) - Date.UTC(ingresoDate.getFullYear(), ingresoDate.getMonth(), ingresoDate.getDate()) ) /(1000 * 60 * 60 * 24));
  }*/

  public getTiempoLaborando(fechaUsuario: any){
    //El siguiente fragmento de codigo lo uso para igualar la fecha de nacimiento con la fecha de hoy del usuario
    var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2){
      month = '0' + month;
    }
    if (day.length < 2){
      day = '0' + day;
    }
    var k = [year, month, day].join('-')
    /*------------*/
    var hoy = new Date(k);//fecha del sistema con el mismo formato que "fechaUsuario"
    var cumpleanos = new Date(fechaUsuario);
    //alert(cumpleanos+" "+hoy);
    //Calculamos años
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    // calculamos los meses
    var meses=0;
    if(hoy.getMonth()>cumpleanos.getMonth()){
          meses=hoy.getMonth()-cumpleanos.getMonth();
      }else if(hoy.getMonth()<cumpleanos.getMonth()){
          meses=12-(cumpleanos.getMonth()-hoy.getMonth());
      }else if(hoy.getMonth()==cumpleanos.getMonth() && hoy.getDate()>cumpleanos.getDate() ){
          if(hoy.getMonth()-cumpleanos.getMonth()==0){
              meses=0;
          }else{
              meses=11;
          }
          
      }
      // Obtener días: día actual - día de cumpleaños
      let dias  = hoy.getDate() - cumpleanos.getDate();
      if(dias < 0) {
          // Si días es negativo, día actual es mayor al de cumpleaños,
          // hay que restar 1 mes, si resulta menor que cero, poner en 11
          meses = (meses - 1 < 0) ? 11 : meses - 1;
          // Y obtener días faltantes
          dias = 30 + dias;
      }
      //console.log(`Tu edad es de ${edad} años, ${meses} meses, ${dias} días`);

      return `${edad} años, ${meses} meses, ${dias} días`;
  }
  /*fin default*/

  /*permisos*/
  public consultarAccionUsuario(url: any){
    if(this.ghedp_sesionService.getSession().getUsuario().nombre != undefined){
      this.ghedp_acciones = this.ghedp_sesionService.getSession().getAcciones();
      console.log(this.ghedp_acciones);
      if(this.ghedp_acciones.filter(x => x.url == url)[0]){
        return true;        
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  /*fin permisos*/

  public getExpedienteConsultas(page: number){
    this.ghedp_isLoading = true;
    this.ghedp_page = page;
    this.ghedp_rrhhService.getExpedienteConsultas(this.ghedp_page, this.ghedp_pageSize, this.ghedp_form.value.buscar, this.ghedp_form.value.buscador).subscribe(
      response => {console.log(response);
        this.ghedp_isLoading = false;
        if(response.estado == 200){
          this.ghedp_length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      error => {console.log(<any>error);
        this.ghedp_isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.ghedp_pageSize = event.pageSize;
    this.getExpedienteConsultas(event.pageIndex + 1);
  }


  public postEliminarDescripcion(id: number){
    this.ghedp_isLoading = true;
    this.ghedp_rrhhService.postEliminarDescripcion({numero_descripcion: id}).subscribe( //por cambiar
      response => {console.log(response);
        this.ghedp_isLoading = false;
        if(response.estado == 200){
          this.ghedp_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Correct', mensaje: 'Eliminado Correctamente.', icon:'correct'}});
          this.getExpedienteConsultas(this.ghedp_page);
        }else{
          this.ghedp_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el servicio', icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.ghedp_dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.ghedp_isLoading = false;
      });
  }



  public validandoDocumentos(documentos: any){
    const obligatorios = [1, 2, 3, 4, 5, 6, 7, 14, 17, 19, 20, 21, 23];

      // Verificar si todos los documentos obligatorios están presentes
      for (let i = 0; i < obligatorios.length; i++) {
        const idTipoDocumento = obligatorios[i];
        if (!documentos?.some((doc: any) => doc.id_tipo_pk === idTipoDocumento)) {
          // Si no se encuentra el documento obligatorio, retornar false
          return false;
        }
      }

      // Si se encontraron todos los documentos obligatorios, retornar true
      return true;


  }


  public eliminarEmpleadosExpedientes(id: number){
    const dialogRef = this._dialog.open(DesitionBoxComponent,{
      data:{titulo:"¿Estas seguro de eliminar?",mensaje:"Usted está apunto de eiminar. ¿Desea continuar?",desicion:true}
    });

    dialogRef.afterClosed().subscribe(desition => {
      //console.log('The dialog was closed');
      if(desition){
        console.log('eliminar');
        this.postEliminarEmpleadosExpedientes(id);
        //this._sesion.logOut();
        //this.route.navigate(['/security/login']);
      }
    });
  }


  public postEliminarEmpleadosExpedientes(id: number){
    this.ghedp_isLoading = true;
    this.ghedp_rrhhService.postEliminarEmpleadosExpedientes({id_info_empleado_pk: id}).subscribe( //por cambiar
      response => {console.log(response);
        this.ghedp_isLoading = false;
        if(response.estado == 200){
          this.ghedp_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Correcto', mensaje: 'Eliminado Correctamente.', icon:'correct'}});
          this.getExpedienteConsultas(this.ghedp_page);
        }else{
          this.ghedp_dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el servicio', icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.ghedp_dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.ghedp_isLoading = false;
      });
  }




}
