<div class="card-container">
    <div class="table-container">
        
        <mat-card class="example-card">
            <mat-card-header>
                
                <mat-card-title>
                    Gestor de etapas de atención
                </mat-card-title>
                <mat-card-subtitle>
                    
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="bt col-md-12">
                    <app-datos-generales (fichaTecnica)="recibeFichaTecnica($event)" (beneficiariaSeleccionada)="recibeBeneficiaria($event)" [ficha]="true"></app-datos-generales>
                </div>
                <div class="bt col-md-12" *ngIf="selected.value>0 || esFichaTecnica">
                    
                    <div class="text-align-right">
                        <button color="accent" type="button" mat-flat-button class="flat" (click)="nuevoProceso()">Nuevo proceso</button>
                    </div>
                    
                    <div class="table-container">
                        <div class="spinner" *ngIf="isLoading">
                            <mat-spinner color="accent"></mat-spinner>
                        </div>
                        <table mat-table [dataSource]="dataSource">
                            <caption></caption>
                            <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->
                        
                            <!-- Position Column -->
                            <ng-container matColumnDef="position">
                                <th id="position" mat-header-cell *matHeaderCellDef> # </th>
                                <td mat-cell *matCellDef="let element;let i=index"> {{i+1}} </td>
                            </ng-container>

                            <!-- Position Column -->
                            <ng-container matColumnDef="fechaAlta">
                            <th id="fechaAlta" mat-header-cell *matHeaderCellDef> Fecha de Alta </th>
                            <td mat-cell *matCellDef="let element"> 
                                {{element?.fechaAlta?.date | date: 'dd/MM/yyyy'}}
                            </td>
                            </ng-container>
                        
                            <!-- Name Column -->
                            <ng-container matColumnDef="candidata">
                            <th id="candidata" mat-header-cell *matHeaderCellDef class="text-align-center"> Candidata </th>
                            <td mat-cell *matCellDef="let element" class="text-align-center">

                                <button color="{{element?.etapas?.candidata?.estatus == 'A' ? 'accent':'primary'}}" mat-mini-fab [disabled]="element.etapas.candidata == null" [routerLink]="['candidata']" [queryParams]="{idProceso: element.idProceso,idBeneficiaria: beneficiaria.id,fechaAlta : element?.fechaAlta.date}">
                                    <mat-icon>find_in_page</mat-icon>
                                </button>
                                <br>
                                {{ diffFechas(element?.etapas?.candidata?.fechaAlta?.date, element?.fechaAlta?.date) }}
                            </td>
                            </ng-container>
                        
                            <!-- Weight Column -->
                            <ng-container matColumnDef="aPrueba">
                            <th id="aprueba" mat-header-cell *matHeaderCellDef class="text-align-center"> A Prueba </th>
                            <td mat-cell *matCellDef="let element" class="text-align-center"> 
                                <button color="{{element?.etapas?.aPrueba?.estatus =='A' ? 'accent':'primary'}}" mat-mini-fab [disabled]="element?.etapas?.aPrueba == null" [routerLink]="['aprueba']" [queryParams]="{idProceso: element.idProceso,idBeneficiaria: beneficiaria.id, fechaAlta: element?.etapas?.candidata?.fechaAlta?.date}">
                                    <mat-icon>find_in_page</mat-icon>
                                </button>
                                <br>
                                <span *ngIf="element?.etapas?.aPrueba != null; else noaplica">
                                    {{ diffFechas(element?.etapas?.aPrueba?.fechaCierre?.date, element?.etapas?.candidata?.fechaAlta?.date) }}
                                </span>
                                <ng-template #noaplica>
                                    <span>N/D</span>
                                </ng-template>
                                <!--{{element?.etapas?.aPrueba?.duracion == null ? 'N/D': element?.etapas?.aPrueba?.duracion>1 ? element?.etapas?.aPrueba?.duracion + ' días':element?.etapas?.aPrueba?.duracion + ' día'}}-->
                            </td>
                            </ng-container>
                        
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="sanacion">
                            <th id="sanacion" mat-header-cell *matHeaderCellDef class="text-align-center"> Sanación </th>
                            <td mat-cell *matCellDef="let element" class="text-align-center">
                                <ng-container *ngIf="element?.etapas?.sanacion?.estatus =='S'">
                                    <button matBadge="S" matBadgePosition="after" matBadgeColor="warn" color="{{element?.etapas?.sanacion?.estatus =='A' || element?.etapas?.sanacion?.estatus =='S'  ? 'accent':'primary'}}" mat-mini-fab [disabled]="element?.etapas?.sanacion == null" [routerLink]="['sanacion']" [queryParams]="{idProceso: element.idProceso,idBeneficiaria: beneficiaria.id,fechaAlta: element?.etapas?.candidata?.fechaAlta?.date}">
                                        <mat-icon>find_in_page</mat-icon>
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="element?.etapas?.sanacion?.estatus !='S'">
                                    <button color="{{element?.etapas?.sanacion?.estatus =='A' || element?.etapas?.sanacion?.estatus =='S'  ? 'accent':'primary'}}" mat-mini-fab [disabled]="element?.etapas?.sanacion == null" [routerLink]="['sanacion']" [queryParams]="{idProceso: element.idProceso,idBeneficiaria: beneficiaria.id,fechaAlta: element?.etapas?.candidata?.fechaAlta?.date}">
                                        <mat-icon>find_in_page</mat-icon>
                                    </button>
                                </ng-container>
                                <br>
                                {{ diffFechas(element?.etapas?.sanacion?.fechaCierre?.date, element?.etapas?.candidata?.fechaAlta?.date) }}
                                <!--{{element?.etapas?.sanacion?.duracion == null ? 'N/D': element?.etapas?.sanacion?.duracion>1 ? element?.etapas?.sanacion?.duracion + ' días':element?.etapas?.sanacion?.duracion + ' día'}}-->
                            </td>
                            </ng-container>
            
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="desarrollo">
                            <th id="desarrollo" mat-header-cell *matHeaderCellDef class="text-align-center"> Desarrollo </th>
                            <td mat-cell *matCellDef="let element" class="text-align-center">
                                <ng-container *ngIf="element?.etapas?.desarrollo?.estatus =='S'">
                                    <button matBadge="S" matBadgePosition="after" matBadgeColor="warn" color="{{element?.etapas?.desarrollo?.estatus =='A' || element?.etapas?.desarrollo?.estatus =='S' ? 'accent':'primary'}}" mat-mini-fab [disabled]="element?.etapas?.desarrollo == null" [routerLink]="['desarrollo']" [queryParams]="{idProceso: element.idProceso,idBeneficiaria: beneficiaria.id,fechaAlta: element?.etapas?.candidata?.fechaAlta?.date}">
                                        <mat-icon>find_in_page</mat-icon>
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="element?.etapas?.desarrollo?.estatus !='S'">
                                    <button color="{{element?.etapas?.desarrollo?.estatus =='A' || element?.etapas?.desarrollo?.estatus =='S' ? 'accent':'primary'}}" mat-mini-fab [disabled]="element?.etapas?.desarrollo == null" [routerLink]="['desarrollo']" [queryParams]="{idProceso: element.idProceso,idBeneficiaria: beneficiaria.id, fechaAlta: element?.etapas?.candidata?.fechaAlta?.date}">
                                        <mat-icon>find_in_page</mat-icon>
                                    </button>
                                </ng-container>
                                <br>
                                {{ diffFechas(element?.etapas?.desarrollo?.fechaCierre?.date, element?.etapas?.candidata?.fechaAlta?.date) }}
                                <!--{{element?.etapas?.desarrollo?.duracion == null ? 'N/D': element?.etapas?.desarrollo?.duracion>1 ? element?.etapas?.desarrollo?.duracion + ' días':element?.etapas?.desarrollo?.duracion + ' día'}}-->
                            </td>
                            </ng-container>
            
                            <ng-container matColumnDef="preparacion">
                                <th id="preparacion" mat-header-cell *matHeaderCellDef class="text-align-center"> Preparación </th>
                                <td mat-cell *matCellDef="let element" class="text-align-center">
                                    <ng-container *ngIf="element?.etapas?.preparacion?.estatus =='S'">
                                        <button matBadge="S" matBadgePosition="after" matBadgeColor="warn" color="accent" mat-mini-fab [disabled]="element?.etapas?.preparacion == null" [routerLink]="['preparacion']" [queryParams]="{idProceso: element.idProceso,idBeneficiaria: beneficiaria.id}">
                                            <mat-icon>find_in_page</mat-icon>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="element?.etapas?.preparacion?.estatus !='S'">
                                        <button color="{{element?.etapas?.preparacion?.estatus =='A' ? 'accent':'primary'}}" mat-mini-fab [disabled]="element?.etapas?.preparacion == null" [routerLink]="['preparacion']" [queryParams]="{idProceso: element.idProceso,idBeneficiaria: beneficiaria.id,fechaAlta: element?.etapas?.desarrollo?.fechaCierre?.date}">
                                            <mat-icon>find_in_page</mat-icon>
                                        </button>
                                    </ng-container>
                                    <br>
                                    {{ diffFechas(element?.etapas?.preparacion?.fechaCierre?.date, element?.etapas?.desarrollo?.fechaCierre?.date) }}
                                    <!--{{element?.etapas?.preparacion?.duracion == null ? 'N/D': element?.etapas?.preparacion?.duracion>1 ? element?.etapas?.preparacion?.duracion + ' días':element?.etapas?.preparacion?.duracion + ' día'}}-->
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="egreso">
                                <th id="egreso" mat-header-cell *matHeaderCellDef class="text-align-center"> Egreso </th>
                                <td mat-cell *matCellDef="let element" class="text-align-center">
                                    <button color="{{element?.etapas?.egreso?.estatus =='A' ? 'accent':'primary'}}" mat-mini-fab [disabled]="element?.etapas?.egreso == null" [routerLink]="['egreso']" [queryParams]="{idProceso: element.idProceso,idBeneficiaria: beneficiaria.id}">
                                        <mat-icon>find_in_page</mat-icon>
                                    </button>
                                    <br>
                                    {{ getDuracionEgreso(element?.fechaCierre?.date,element?.etapas) }}
                                    <!--{{ diffFechas(element?.fechaCierre?.date, element?.etapas?.preparacion?.fechaCierre?.date) }}-->
                                    <!--{{element?.etapas?.egreso?.duracion == null ? 'N/D': element?.etapas?.egreso?.duracion>1 ? element?.etapas?.egreso?.duracion + ' días':element?.etapas?.egreso?.duracion + ' día'}}-->
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="fechaCierre">
                                <th id="fechaCierre" mat-header-cell *matHeaderCellDef> Fecha de cierre </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element?.fechaCierre?.date | date: 'dd/MM/yyyy'}}
                                    <br>
                                    <!-- {{
                                        diffFechas(element?.fechaCierre?.date, element?.fechaAlta?.date) == 'N/D' ? '':diffFechas(element?.fechaCierre?.date, element?.fechaAlta?.date)
                                    }} -->
                                    <!-- <button color="{{element?.etapas?.aPrueba?.estatus =='A' ? 'accent':'primary'}}" mat-mini-fab [disabled]="element?.etapas?.aPrueba == null" [routerLink]="['aprueba']" [queryParams]="{idProceso: element.idProceso,idBeneficiaria: beneficiaria.id, fechaAlta: element?.etapas?.candidata?.fechaAlta?.date}">
                                        <mat-icon>find_in_page</mat-icon>
                                    </button>
                                    <br> -->
                                    <span *ngIf="element?.etapas?.aPrueba != null">
                                        <!-- {{ (
                                            getDuracionEgresoNum(
                                                element?.fechaCierre?.date,element?.etapas
                                                )
                                            - diffFechasNum(
                                                element?.etapas?.aPrueba?.fechaCierre?.date, element?.etapas?.candidata?.fechaAlta?.date
                                                )
                                            ) 
                                        }} Días -->
                                        {{ diffFechasNum(
                                            element?.fechaCierre?.date,
                                            element?.etapas?.candidata?.fechaAlta?.date
                                        ) }} Día(s)
                                    </span>
                                </td>
                            </ng-container>
                            
                        
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>

                </div>
            </mat-card-content>
            <mat-card-actions style="text-align: center;" *ngIf="esFichaTecnica" >
                <button mat-stroked-button [routerLink]="['/beneficiarias']" class="flat">Volver</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>