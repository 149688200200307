<br>
<div class="table-container">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner color="accent"></mat-spinner>
    </div>
    <form [formGroup]="formulario" (ngSubmit)="sg_sendForm()" autocomplete="off">
        <h3>Situación jurídica</h3>
        <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Situación jurídica</mat-label>
            <mat-select formControlName="situacionJuridica">
                <mat-option value="{{situacion.idParametro}}" *ngFor="let situacion of situacionJuridica">{{situacion.valor}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-12">
            <mat-label>Descripción</mat-label>
            <textarea matInput placeholder="" maxlength="2500" #descripcion formControlName="sjDescripcion"></textarea>
            <mat-hint align="end">{{descripcion.value.length}} / 2500</mat-hint>
        </mat-form-field>

        
        <h3>Situación familiar</h3>
        <span>Convivencias</span>
        &nbsp;
        <mat-radio-group aria-label="Convivencias" formControlName="convivencias">
            <mat-radio-button value="si">Si</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
        </mat-radio-group>
        <br><br>
        <mat-form-field appearance="fill" class="bt col-md-12">
            <mat-label>Descripción</mat-label>
            <textarea matInput placeholder="" maxlength="300" #descripcion2 formControlName="convivenciasDescripcion"></textarea>
            <mat-hint align="end">{{descripcion2.value.length}} / 300</mat-hint>
        </mat-form-field>


        <h3>Situación académica</h3>
        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Escolaridad</mat-label>
            <mat-select formControlName="escolaridad">
                <mat-option value="{{nivel.idParametro}}" *ngFor="let nivel of escolaridad">{{nivel.valor}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Último grado</mat-label>
            <input matInput placeholder="" maxlength="150" #descripcion3 type="text" formControlName="ultimoGrado"/>
            <mat-hint align="end">{{descripcion3.value.length}} / 150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Comprobante de estudios</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo1.value" formControlName="comprobanteDeEstudios">
            <button mat-icon-button matSuffix (click)="archivo1.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo1 class="hide" formControlName="ceArchivo" (change)="sg_handleFileInput1($event.target.files)"/>

            <a href="{{hostapi}}/{{documento1+'?t='+t}}" *ngIf="documento1" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
            <mat-hint *ngIf="documento1">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento1" (click)="postEliminarDocumentoBene(idBeneficiaria, 63, documentos[17].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>

        <h3>Sacramentos</h3>


        <section class="example-section">
            <mat-checkbox class="example-margin" formControlName="bautismo">Bautismo</mat-checkbox>
            <mat-checkbox class="example-margin" formControlName="comunion">Primera comunión</mat-checkbox>
            <mat-checkbox class="example-margin" formControlName="confirmacion">Confirmación</mat-checkbox>
        </section>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Nombre del sacramento</mat-label>
            <input matInput placeholder="" maxlength="150" #nombredocto1 formControlName="nombreDocumento1">
            <mat-hint align="end">{{nombredocto1.value.length}} / 150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Documento {{nombredocto1.value}}</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo2.value" formControlName="rutaDocumento1">
            <button mat-icon-button matSuffix (click)="archivo2.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo2 class="hide" formControlName="documento1Archivo" (change)="sg_handleFileInput2($event.target.files)"/>
            
            <a href="{{hostapi}}/{{documento2+'?t='+t}}" *ngIf="documento2" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
            
            <mat-hint *ngIf="documento2">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento2" (click)="postEliminarDocumentoBene(idBeneficiaria, 81, documentos[18].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>


        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Nombre del sacramento</mat-label>
            <input matInput placeholder="" maxlength="150" #nombredocto2 formControlName="nombreDocumento2">
            <mat-hint align="end">{{nombredocto2.value.length}} / 150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento {{nombredocto2.value}}</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo3.value" formControlName="rutaDocumento2">
            <button mat-icon-button matSuffix (click)="archivo3.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo3 class="hide" formControlName="documento2Archivo" (change)="sg_handleFileInput3($event.target.files)"/>
            
            <a href="{{hostapi}}/{{documento3+'?t='+t}}" *ngIf="documento3" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
            
            <mat-hint *ngIf="documento3">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento3" (click)="postEliminarDocumentoBene(idBeneficiaria, 82, documentos[19].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>


        <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Nombre del sacramento</mat-label>
            <input matInput placeholder="" maxlength="150" #nombredocto3 formControlName="nombreDocumento3">
            <mat-hint align="end">{{nombredocto3.value.length}} / 150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="bt col-md-5">
            <mat-label>Documento {{nombredocto3.value}}</mat-label>
            <input matInput placeholder="Adjuntar JPG, PDF" [value]="archivo4.value" formControlName="rutaDocumento3">
            <button mat-icon-button matSuffix (click)="archivo4.click()" type="button">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" #archivo4 class="hide" formControlName="documento1Archivo" (change)="sg_handleFileInput4($event.target.files)"/>
            
            <a href="{{hostapi}}/{{documento4+'?t='+t}}" *ngIf="documento4" target="_blank" rel="noopener" matTooltip="Click para ver el documento cargado" color="accent" mat-icon-button matPrefix type="button" >
                <mat-icon>download</mat-icon>
            </a>
            
            <mat-hint *ngIf="documento4">Ya existe un documento cargado</mat-hint>
        </mat-form-field>

        <div class="bt col-md-1">
            <button *ngIf="documento4" (click)="postEliminarDocumentoBene(idBeneficiaria, 83, documentos[20].docTipoCatalogo)"  type="button" class="btn btn-small btn-danger">x</button>
        </div>



        <br><br>
        <div class="text-align-center">
            <button class="hide" type="reset" id="resetForm">Reset</button>
            <button mat-stroked-button class="flat" type="button" [routerLink]="['/beneficiarias']">Cancelar</button>
            &nbsp;
            <button mat-flat-button color="primary" class="flat">Guardar</button>
        </div>
    </form>
</div>
