import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contenedor-puestos-de-trabajo',
  templateUrl: './contenedor-puestos-de-trabajo.component.html',
  styleUrls: ['./contenedor-puestos-de-trabajo.component.scss']
})
export class ContenedorPuestosDeTrabajoComponent implements OnInit {

  public selectedIndex: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  public siguienteEvento(newItem: any) {
    console.log(newItem);
    this.selectedIndex = newItem;
  }

}
