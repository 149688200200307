<app-rrhh-ficha-informativa></app-rrhh-ficha-informativa>

<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Gestor de capacitaciones individuales</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>



                <!-- tabla -->
                <div class="row mt-5 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="_id">
                            <th id="_id" mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let element">{{element?.id_capacitaciones_pk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_nombre_capa">
                            <th id="_nombre_capa" mat-header-cell *matHeaderCellDef> NOMBRE DE LA CAPACITACIÓN </th>
                            <td mat-cell *matCellDef="let element">{{element?.nombre_capa}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_imparte">
                            <th id="_imparte" mat-header-cell *matHeaderCellDef> IMPARTE </th>
                            <td mat-cell *matCellDef="let element">{{element?.quien_impartio}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_fecha">
                            <th id="_fecha" mat-header-cell *matHeaderCellDef> FECHA </th>
                            <td mat-cell *matCellDef="let element">{{element?.created_at?.split('T')[0]}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_consultar">
                            <th id="_consultar" mat-header-cell *matHeaderCellDef> CONSULTAR </th>
                            <td mat-cell *matCellDef="let element">
                                <button type="button" mat-mini-fab color="primary"  >
                                    <mat-icon class="material-icons" [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones/consultar']" [queryParams]="{id_capacitacion:element.id_capacitaciones_pk, id_numero_empleado: id_empleado, id_exp: id_empleado}">
                                        open_in_new
                                    </mat-icon>
                                </button>
                            </td>
                        </ng-container>


                        <!-- fin contenido -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- fin tabla -->
                <div class="mt-5 d-flex justify-content-center">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-expedientes-de-personal']" >
                        Regresar
                    </button>
                </div>

                
            </mat-card-content>
        </mat-card>
    </div>
