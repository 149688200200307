//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { EytService } from 'src/app/services/eyt.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//Fin Tabla

@Component({
  selector: 'app-gestor-eyt-medicos1',
  templateUrl: './gestor-eyt-medicos1.component.html',
  styleUrls: ['./gestor-eyt-medicos1.component.scss'],
  providers: [ EytService ]
})
export class GestorEytMedicos1Component implements OnInit {

  /*tabla*/
  public length: number;
  public pageSize: number;
  public pageSizeOptions: Array<number>;
  public page: number;
  public displayedColumns: any;
  public dataSource: any;
  /*fin tabla*/
  public isLoading: boolean = false;
  public id: number = -1;

  public laravelapiStorage = this._EytService.getLaravelApiStorage();
  public laravelapi = this._EytService.getLaravelApi();

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _EytService: EytService,
    private _router: Router,
    private _route: ActivatedRoute,
  ){ 
     /*tabla*/
    this.length = 100;
    this.pageSize = 25;
    this.pageSizeOptions = [5, 10, 25, 100];
    this.page = 1;
    this.displayedColumns = [
    '_id', 
    '_fecha',
    '_diagnostico',
    '_estatus',
    '_fecha_cierre',
    '_evento',
    '_evento_c',
    '_trat',
    '_trat_c',
    '_eliminar'];
    /*fin tabla*/

    /*modificar*/
    this._route.queryParams.subscribe(
      params => {
        this.id = +params['id'];
        if(this.id){
          console.log('ID: ' + this.id);
          this.getConsultaEventosMedicos(1);
          //this.getConsultaEventoMedico(this.id);
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    
  }

  public getConsultaEventosMedicos(page: number){
    this.isLoading = true;
    this.page = page;
    this._EytService.getConsultaEventosMedicos(this.page, this.pageSize, this.id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.pageSize = event.pageSize;
    this.getConsultaEventosMedicos(event.pageIndex + 1);
  }

  public postEliminarEventoMedico(id: number){
    this.isLoading = true;
    this._EytService.postEliminarEventoMedico({numero_evento_medico: id}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaEventosMedicos(this.page);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }
  
  

}
