<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Evaluación de beneficiaria</mat-card-title>
            <mat-card-subtitle>Conteste la evaluación</mat-card-subtitle>
        </mat-card-header>
    
        <mat-card-content>
            <div class="bt col-md-12">
                <app-datos-generales [ficha]="true"></app-datos-generales>
            </div>
            <div class=" bt col-md-12">
                <button mat-flat-button color="primary" [routerLink]="['/evaluaciones/gestor-de-pruebas']" [queryParams]="{id: idBeneficiaria}">
                    <mat-icon>arrow_back</mat-icon> Regresar al gestor de evaluaciones
                </button>
                <mat-vertical-stepper labelPosition="bottom" #stepper (selectionChange)="getStep($event)">
                    <mat-step *ngFor="let clasificacion of clasificaciones; let i=index">
                            <ng-template matStepLabel matStepperIcon="done">{{clasificacion.nombre}}</ng-template>
                            <ng-container *ngIf="i==selectedIndex">

                                <mat-accordion>
                                    <mat-expansion-panel *ngFor="let subcategoria of getSubCategorias(clasificacion?.idClasificacion); let x=index">
                                        <mat-expansion-panel-header (click)="subcategoriaIndx = x">
                                        <mat-panel-title>
                                            {{subcategoria | uppercase}}
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <mat-progress-bar mode="indeterminate" *ngIf="isLoading && subcategoriaIndx == x"></mat-progress-bar>
                                        </mat-panel-description>
                                        <mat-panel-description>
                                            <div style="text-align: right; width: 100%;">{{getAvance(subcategoria,clasificacion.nombre)}}</div>
                                        </mat-panel-description>
                                        </mat-expansion-panel-header>

                                        <div class="table-container-2" >
                                            <div class="spinner" *ngIf="isLoading && subcategoriaIndx == x">
                                                <mat-spinner color="accent"></mat-spinner>
                                            </div>
                                            <table>
                                                <caption></caption>
                                                <!--<caption style="position: relative;">{{subcategoria}}
                                                    <button mat-icon-button (click)="subcategoriaIndx = x" style="position: absolute; right: 5px; top:2px">
                                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </caption>-->
                                                <thead>
                                                    <tr>
                                                        <th id="preguntaT"></th>
                                                        <th id="observacionT"></th>
                                                        <th id="prioridadT"></th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="x==subcategoriaIndx" [formGroup]="formulario">
                                                    <ng-container *ngFor="let pregunta of data?.data?.preguntas">
                                                        <!--<form [formGroup]="formulario">-->
                                                            <ng-container *ngIf="pregunta?.idTipoPregunta==1">
                                                                <tr *ngIf="pregunta?.idClasificacion == clasificacion?.idClasificacion && pregunta?.descripcion.split('|')[0] == subcategoria">
                                                                    <td colspan="3">
                                                                        <div class="loader" *ngIf="idPregunta[pregunta.idPregunta]">
                                                                            
                                                                        </div>
                                                                        {{pregunta?.descripcion.split("|")[1]}}
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="pregunta?.idClasificacion == clasificacion?.idClasificacion && pregunta?.descripcion.split('|')[0] == subcategoria">
                                                                    
                                                                    <td width="50%">
                                                                        <div class="loader" *ngIf="idPregunta[pregunta.idPregunta]">
                                                                            
                                                                        </div>
                                                                        <mat-radio-group aria-label="Selecciona una opción" (change)="changeRespuesta(pregunta.idPregunta)" [id]="'respuesta' + pregunta.idPregunta" formControlName="{{'respuesta' + pregunta.idPregunta}}">
                                                                            <ng-container *ngFor="let respuesta of data?.data?.respuestas">
                                                                                <mat-radio-button [value]="respuesta?.idRespuesta" *ngIf="respuesta?.idPregunta == pregunta?.idPregunta" >{{respuesta?.descripcion}}</mat-radio-button>
                                                                            </ng-container>
                                                                        </mat-radio-group>
                                                                    </td>
                                                                    <td width="30%">
                                                                        <div class="loader" *ngIf="idPregunta[pregunta.idPregunta]">
                                                                            
                                                                        </div>
                                                                        <mat-form-field appearance="fill" class="bt col-md-12">
                                                                            <mat-label>Observaciones</mat-label>
                                                                            <textarea formControlName="{{'observacion' + pregunta.idPregunta}}" [id]="'observaciones' + pregunta.idPregunta" matInput placeholder="Escriba aquí sus observaciones..." (change)="changeRespuesta(pregunta.idPregunta)"></textarea>
                                                                        </mat-form-field>
                                                                    </td>
                                                                    <td width="20%">
                                                                        <div class="loader" *ngIf="idPregunta[pregunta.idPregunta]">
                                                                            
                                                                        </div>
                                                                        
                                                                        <mat-slide-toggle formControlName="{{'prioridad' + pregunta.idPregunta}}" [id]="'prioridad'+ pregunta.idPregunta" (change)="changeRespuesta(pregunta.idPregunta)">Prioridad</mat-slide-toggle>
                                                                    </td>
                                                                </tr>
                                                                
                                                            </ng-container>
                                                            <ng-container *ngIf="pregunta?.idTipoPregunta==2">
                                                                <tr *ngIf="pregunta?.idClasificacion == clasificacion?.idClasificacion && pregunta?.descripcion.split('|')[0] == subcategoria">
                                                                    <td colspan="3">
                                                                        <div class="loader" *ngIf="idPregunta[pregunta.idPregunta]">
                                                                            
                                                                        </div>
                                                                        {{pregunta?.descripcion.split("|")[1]}}
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="pregunta?.idClasificacion == clasificacion?.idClasificacion && pregunta?.descripcion.split('|')[0] == subcategoria">
                                                                    
                                                                    <td colspan="3">
                                                                        <div class="loader" *ngIf="idPregunta[pregunta.idPregunta]">
                                                                            
                                                                        </div>
                                                                        <mat-form-field appearance="fill" class="bt col-md-12">
                                                                            <mat-label>Comentarios</mat-label>
                                                                            <textarea (change)="changeRespuesta(pregunta.idPregunta)" formControlName="{{'observacion' + pregunta.idPregunta}}" matInput placeholder="Escriba aquí sus comentarios..."></textarea>
                                                                        </mat-form-field>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        <!--</form>-->
                                                        <!--<mat-form-field>
                                                            <mat-label>Name</mat-label>
                                                            <input matInput placeholder="Last name, First name" required>
                                                        </mat-form-field>-->
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>

                                <ng-container *ngFor="let subcategoria of getSubCategorias(clasificacion?.idClasificacion); let x=index">
                                    
                                </ng-container>
                                <div class="text-align-center" style="display: none;">
                                    <button mat-flat-button matStepperPrevious color="primary" class="flat">Anterior</button> 
                                    &nbsp;
                                    <button mat-flat-button matStepperNext color="primary" class="flat">Siguiente</button>
                                </div>
                            </ng-container>
                    </mat-step>
                </mat-vertical-stepper>
                <button mat-flat-button color="primary" [routerLink]="['/evaluaciones/gestor-de-pruebas']" [queryParams]="{id: idBeneficiaria}">
                    <mat-icon>arrow_back</mat-icon> Regresar al gestor de evaluaciones
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>