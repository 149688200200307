<app-datos-generales *ngIf="ben"></app-datos-generales>

<div class="container-fluid" *ngIf="!ben">
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>

<div class="card-container" id="top">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Terapia / Campaña</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <!--<mat-tab-group mat-align-tabs="start" [(selectedIndex)]="selectedIndex">
                <mat-tab label="Formulario 1">-->

                    <div class="container-fluid">
                        <mat-card >
                            <mat-card-content>
                                

                                <form novalidate [formGroup]="form" autocomplete="off">
                                    <div class="row mt-3">
                                        <div class="col">
                                            <mat-form-field appearance="fill"  class="input-full-w">
                                                <mat-label>Fecha de apertura </mat-label>
                                                <input type="date" matInput  formControlName="fecha_ingreso">
                                                <mat-error *ngIf="form.controls['fecha_ingreso'].hasError('required')">
                                                    El campo es obligatorio
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="fill" class="input-full-w">
                                                <mat-label>Clasificación</mat-label>
                                                <mat-select formControlName="clasificacion">
                                                    <mat-option *ngFor="let p of clasificaciones" [value]="p.id_clasificacion_pk">{{p.clasificacion}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <mat-form-field appearance="fill"  class="input-full-w">
                                            <mat-label>Nombre del evento </mat-label>
                                            <input type="text" matInput  formControlName="nombre_evento">
                                            <mat-error *ngIf="form.controls['nombre_evento'].hasError('required')">
                                                El campo es obligatorio
                                            </mat-error>
                                            <mat-error *ngIf="form.controls['nombre_evento'].hasError('required')">
                                                Máximo de caracteres 200
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <mat-form-field appearance="fill"  class="input-full-w">
                                            <mat-label>Descripción </mat-label>
                                            <textarea type="text" matInput  formControlName="descripcion" rows="5"></textarea>
                                            <mat-error *ngIf="form.controls['descripcion'].hasError('required')">
                                                El campo es obligatorio
                                            </mat-error>
                                            <mat-error *ngIf="form.controls['descripcion'].hasError('required')">
                                                Máximo de caracteres 3000
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </form>
                                    
                                    
                                
                            </mat-card-content>
                        </mat-card>
                    </div>



                    <!--<div class="mt-5 d-flex justify-content-center gap-2">
                        <button *ngIf="!ben" class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos-generales/gt-terapias-companas']" >
                            Regresar a parilla
                        </button>
                        <button *ngIf="ben" class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gt-terapias-companas']" [queryParams]="{id: ben}" >
                            Regresar a parilla.
                        </button>

                        <form novalidate [formGroup]="form" (ngSubmit)="postRegistroDeTerapia()" *ngIf="accion == 'registrar'">
                            <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                                Guardar
                            </button>
                        </form>

                        <form novalidate [formGroup]="form" (ngSubmit)="postModificarTerapia()" *ngIf="accion == 'modificar' && form.value.estado_boton == 1">
                            <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                                Guardar.
                            </button>
                        </form>

                        <button *ngIf="accion == 'modificar'" class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid" (click)="nextStep()">
                            Siguiente
                        </button>
                    </div>-->

                    
                    

                    


                <!--</mat-tab>
              <mat-tab label="Formulario 2">-->
                <div class="container-fluid">
                    <mat-card >
                        <mat-card-content>


                            <div class="row mt-3">
                                <div class="col">
                                    <div class="row">
                                        <form novalidate [formGroup]="form" autocomplete="off">
                                            <mat-form-field appearance="fill" class="input-full-w">
                                                <mat-label>Beneficiarias participantes</mat-label>
                                                <mat-select formControlName="num_ben">
                                                    <mat-option *ngFor="let p of beneficiarias" [value]="p.id">{{p.nombre}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </form>
                                    </div>
                                </div>
                        
                                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                    <button *ngIf="accion == 'registrar' && form.value.estado_boton == 1 && form.value.num_ben != -1" type="button" color="primary"  mat-mini-fab (click)="addBen(form.value.num_ben)" >
                                        <mat-icon>add</mat-icon>
                                    </button>
                                    <button *ngIf="accion == 'modificar' && form.value.estado_boton == 1 && form.value.num_ben != -1" type="button" color="primary"  mat-mini-fab (click)="postModificarTerapia(1, form.value.num_ben)" >
                                        <mat-icon>add</mat-icon>.
                                    </button>
                                </div>
                        
                        
                                <div class="col">
                                    <form novalidate [formGroup]="form" autocomplete="off">
                                        <!--<mat-form-field appearance="fill"  class="input-full-w">
                                            <mat-label>Terceros involucrados </mat-label>
                                            <input type="text" matInput  formControlName="terceros_involucrados">
                                        </mat-form-field>-->

                                        <mat-form-field class="input-full-w" appearance="fill">
                                            <mat-label>Terceros involucrados</mat-label>
                                            <mat-chip-list #chipGrid aria-label="Enter fruits">
                                              <mat-chip *ngFor="let fruit of fruits"
                                                            (removed)="remove(fruit)"
                                                            [editable]="true"
                                                            (edited)="edit(fruit, $event)">
                                                {{fruit.name}}
                                                <button matChipRemove [attr.aria-label]="'remove ' + fruit">
                                                  <mat-icon>cancel</mat-icon>
                                                </button>
                                              </mat-chip>
                                              <input placeholder="New fruit..."
                                                     [matChipInputFor]="chipGrid"
                                                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                     [matChipInputAddOnBlur]="addOnBlur"
                                                     (matChipInputTokenEnd)="add($event)"/>
                                            </mat-chip-list>
                                          </mat-form-field>

                                    </form>
                                    
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="row mt-3" *ngFor="let a of form.value.beneficiarias; let i = index">
                                        <div class="col">
                                            <div class="row">
                                                <div class="col">
                                                    <span class="fw-bold">Beneficiarias {{i+1}}</span><br>
                                                    <mat-form-field appearance="fill"  class="input-full-w">
                                                        <mat-label>Nombre </mat-label>
                                                        <input type="text" matInput [value]="a.nombre" readonly="true" >
                                                    </mat-form-field>
                                                    
                                                </div>
                                                <div class="col">
                                                    <button *ngIf="accion == 'modificar' && form.value.estado_boton == 1" type="button" class="btn btn-small btn-danger" (click)="postModificarTerapia(2, i)">x</button>
                                                    <button *ngIf="accion == 'registrar' && form.value.estado_boton == 1" type="button" class="btn btn-small btn-danger" (click)="delBen(i)">x</button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </mat-card-content>
                    </mat-card>
                </div>

                <!--<div class="mt-5 d-flex justify-content-center gap-2">
                    <button *ngIf="!ben" class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos-generales/gt-terapias-companas']" >
                        Regresar a parilla
                    </button>
                    <button *ngIf="ben" class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gt-terapias-companas']" [queryParams]="{id: ben}" >
                        Regresar a parilla.
                    </button>
    
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid" (click)="backStep()">
                        Anterior
                    </button>

                    <form novalidate [formGroup]="form" (ngSubmit)="postModificarTerapia()" *ngIf="accion == 'modificar' && form.value.estado_boton == 1">
                        <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                            Guardar.
                        </button>
                    </form>

                    <button *ngIf="accion == 'modificar'" class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid" (click)="nextStep()">
                        Siguiente
                    </button>

                    
    
    
                </div>-->

            <!--    </mat-tab>
            
            



            <mat-tab label="Formulario 3">-->

                <div class="container-fluid">
                    <mat-card >
                        <mat-card-content>

                            <div class="spinner" *ngIf="isLoading">
                                <mat-spinner color="accent"></mat-spinner>
                            </div>

                            <div class="row gap-2">
                                <div class="col">
                                    <div class="row" *ngIf="accion == 'registrar'">
                                        <div class="col">
                                            <div class="row">
                                                <div class="col">
                                                    <h3><mat-icon>photo</mat-icon>&nbsp;Fotografías</h3>
                                                </div>
                                            </div>
                                            <div class="row" *ngFor="let d of doc; let i = index">
                                                <div class="col">
                                                    <div class="row">
                                                        <mat-form-field appearance="fill" class="bt col-md-12">
                                                            <mat-label>Foto {{i + 1}}</mat-label>
                                                            <input readonly matInput  [value]="d?.name">
                                                            <button mat-icon-button type="button" (click)="foto.click()" matSuffix >
                                                                <mat-icon>attach_file</mat-icon>
                                                            </button>
                                                            <input type="file" #foto class="hide" (change)="addFile($event, i, 1)"/>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row" *ngIf="accion == 'registrar'">
                                        <div class="col">
                                            <div class="row">
                                                <div class="col">
                                                    <h3><mat-icon>picture_as_pdf</mat-icon>&nbsp;Documentos</h3>
                                                </div>
                                            </div>
                                            <div class="row" *ngFor="let d of doc2; let i = index">
                                                <div class="col">
                                                    <div class="row">
                                                        <mat-form-field appearance="fill" class="bt col-md-12">
                                                            <mat-label>Documento {{i + 1}}</mat-label>
                                                            <input readonly matInput  [value]="d?.name">
                                                            <button mat-icon-button type="button" (click)="doc.click()" matSuffix >
                                                                <mat-icon>attach_file</mat-icon>
                                                            </button>
                                                            <input type="file" #doc class="hide" (change)="addFile($event, i, 2)"/>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                
                            
                    


                    

                    






                    
                    <div *ngIf="accion == 'modificar' || accion == 'consultar'" class="row">
                        
                        <div class="col">
                            <h3><mat-icon>photo</mat-icon>&nbsp;Fotografías</h3>
                            <!--input *ngIf="data?.documentos_terapia?.length < 5 && id != -1" type="file"  class="form-control" (change)="postRegistrarDocumentoTerapia($event, 0)">-->
                            <!--<div class="row">
                                <div class="col">
                                    <input *ngIf="d1 < 5 && id != -1" type="file" type="file"  class="form-control" (change)="addFile($event, d1, 1)">
                                </div>
                                <div class="col">
                                    <button *ngIf="d1 < 5 && id != -1" type="button" color="primary"  mat-mini-fab (click)="postRegDoc1(d1, id, 1)" >
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>-->

                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill" class="bt col-md-12" *ngIf="d1 < 5 && id != -1 && accion == 'modificar' ">
                                        <mat-label>Foto</mat-label>
                                        <input readonly matInput  [value]="doc[d1]?.name">
                                        <button mat-icon-button type="button" (click)="ft.click()" matSuffix >
                                            <mat-icon>attach_file</mat-icon>
                                        </button>
                                        <input type="file" #ft class="hide" (change)="addFile($event, d1, 1)"/>
                                    </mat-form-field>

                                    <!--<input *ngIf="d2 < 5 && id != -1" type="file" type="file"  class="form-control" (change)="addFile($event, d2, 2)">-->
                                </div>
                                <div class="col">
                                    <button *ngIf="d1 < 5 && id != -1 && accion == 'modificar'" type="button" color="primary"  mat-mini-fab (click)="postRegDoc1(d1, id, 1)" >
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                           
                            


                            <div class="row mt-1" *ngFor="let d of doc; let i = index">
                                <div class="col" *ngIf="d.id2 != -1">
                                    <!--<div class="row">
                                        <span class="fw-bold">Foto {{i + 1}}</span>
                                    </div>-->
                                    <div class="row">
                                        <div class="col">
                                            <!--<input *ngIf="d.id2 != -1" type="file"  class="form-control" (change)="postModificarDocumentoTerapia($event, d.id2)">-->
                                            <!--<ng-template #elseReg>
                                                pop
                                                <input type="file"  class="form-control" (change)="addFile($event, i, 1)">
                                            </ng-template>-->
                                            <mat-form-field appearance="fill" class="bt col-md-12">
                                                <mat-label>Foto {{i + 1}}</mat-label>
                                                <input readonly matInput  [value]="d?.name">
                                                <button mat-icon-button type="button" (click)="foto.click()" matSuffix >
                                                    <mat-icon>attach_file</mat-icon>
                                                </button>
                                                <input *ngIf="accion == 'consultar'"  type="file" #foto class="hide" >
                                                <input *ngIf="accion == 'modificar'" type="file" #foto class="hide" (change)="postModificarDocumentoTerapia($event, d.id2)">
                                               
                                                    <a   mat-icon-button  matPrefix color="accent" href="{{laravelapiStorage}}/{{d?.ruta+'?t='+t}}" target="_blank" rel=noopener>
                                                        
                                                        <mat-icon class="material-icons">
                                                            download
                                                        </mat-icon>
                                                    </a>
                                                
                                            </mat-form-field>
                                        </div>
                                        <!--<div class="col">
                                            <a  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{d?.ruta+'?t='+t}}" target="_blank" rel=noopener>
                                                Cargar Foto
                                                <mat-icon class="material-icons">
                                                    file_open
                                                </mat-icon>
                                            </a>
                                            
                                        </div>-->
                                        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                            <button *ngIf="d.id2 != -1 && accion == 'modificar' && form.value.estado_boton == 1" type="button" class="btn btn-small btn-danger" (click)="postEliminarDocumentoTerapia(i, 1)">x</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <h3><mat-icon>picture_as_pdf</mat-icon>&nbsp;Documentos</h3>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill" class="bt col-md-12" *ngIf="d2 < 5 && id != -1 && accion == 'modificar'">
                                        <mat-label>Documento</mat-label>
                                        <input readonly matInput  [value]="doc2[d2]?.name">
                                        <button mat-icon-button type="button" (click)="dc.click()" matSuffix >
                                            <mat-icon>attach_file</mat-icon>
                                        </button>
                                        <input type="file" #dc class="hide" (change)="addFile($event, d2, 2)"/>
                                    </mat-form-field>

                                    <!--<input *ngIf="d2 < 5 && id != -1" type="file" type="file"  class="form-control" (change)="addFile($event, d2, 2)">-->
                                </div>
                                <div class="col">
                                    <button *ngIf="d2 < 5 && id != -1 && accion == 'modificar'" type="button" color="primary"  mat-mini-fab (click)="postRegDoc1(d2, id, 2)" >
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-1" *ngFor="let d of doc2; let i = index">
                                <div class="col" *ngIf="d.id2 != -1">
                                    <div class="row">
                                        <div class="col">
                                            <mat-form-field appearance="fill" class="bt col-md-12">
                                                <mat-label>Documento {{i + 1}}</mat-label>
                                                <input readonly matInput  [value]="d?.name">
                                                <button mat-icon-button type="button" (click)="documento.click()" matSuffix >
                                                    <mat-icon>attach_file</mat-icon>
                                                </button>

                                                <input *ngIf="accion == 'consultar'"  type="file" #foto class="hide" >
                                                <input *ngIf="accion == 'modificar'" type="file" #foto class="hide" (change)="postModificarDocumentoTerapia($event, d.id2)">
                                                <!--<input type="file" #documento class="hide" (change)="postModificarDocumentoTerapia($event, d.id2)">-->
                                               
                                                    <a   mat-icon-button  matPrefix color="accent" href="{{laravelapiStorage}}/{{d?.ruta+'?t='+t}}" target="_blank" rel=noopener>
                                                        
                                                        <mat-icon class="material-icons">
                                                            download
                                                        </mat-icon>
                                                    </a>
                                                
                                            </mat-form-field>

                                            <!--<input *ngIf="d.id2 != -1" type="file"  class="form-control" (change)="postModificarDocumentoTerapia($event, d.id2)">-->
                                            <!--<ng-template #elseReg>
                                                pop
                                                <input type="file"  class="form-control" (change)="addFile($event, i, 1)">
                                            </ng-template>-->
                                        </div>
                                        <!--<div class="col">
                                            <a  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{d?.ruta+'?t='+t}}" target="_blank" rel=noopener>
                                                Cargar documento
                                                <mat-icon class="material-icons">
                                                    file_open
                                                </mat-icon>
                                            </a>
                                            
                                        </div>-->
                                        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                            <button *ngIf="d.id2 != -1 && accion == 'modificar' && form.value.estado_boton == 1" type="button" class="btn btn-small btn-danger" (click)="postEliminarDocumentoTerapia(i, 2)">x</button>
                                        </div>
                                    </div>
                                </div>
                            </div>






                        </div>
                        
                    </div>
                    










                    <div class="mt-5 d-flex justify-content-center gap-2">
                        <button *ngIf="!ben" class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos-generales/gt-terapias-companas']" >
                            Regresar a parilla
                        </button>
                        <button *ngIf="ben" class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gt-terapias-companas']" [queryParams]="{id: ben}" >
                            Regresar a parilla.
                        </button>
        
                        <!--<button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid" (click)="backStep()">
                            Anterior
                        </button>-->

                        <form novalidate [formGroup]="form" (ngSubmit)="postRegistroDeTerapia()" *ngIf="accion == 'registrar'">
                            <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                                Guardar
                            </button>
                        </form>

                        <form novalidate [formGroup]="form" (ngSubmit)="postModificarTerapia()" *ngIf="accion == 'modificar' && form.value.estado_boton == 1">
                            <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                                Guardar.
                            </button>
                        </form>
    
                        <form novalidate [formGroup]="form" (ngSubmit)="cerrar()" *ngIf="accion == 'modificar' && form.value.estado_boton == 1">
                            <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                                Cerrar
                            </button>
                        </form>
        
        
                    </div>

                </mat-card-content>
                </mat-card>
                </div>



            <!--    </mat-tab>
            </mat-tab-group>      -->      
        </mat-card-content>
    </mat-card>
</div>

