import { Timestamp } from 'rxjs';

export class Beneficiaria {
    id:number;
    nombre:string;
    fechaAlta:string;
    fechaNacimiento:string;
    fechaIngreso:string;
    idCanaliza:string;
    desCanaliza:string;
    idMotivoIngreso:string;
    desMotivoIngreso:string;
    idCurp:string;
    foto:string;
    desHitoria:string;
    idDiscapacidad:string;
    desAntecedente:string;
    fechaEgreso:string;
    desLogros:string;
    idEmpresa:string;

    estatus:string;
    etapa:string;
    motivoEgreso:string;
    lugarDestino:string;
    desMotivoEgreso:string;
    desLugarDestino:string;
}
