//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { MedicoService } from 'src/app/services/medico.service';
import { SesionService } from 'src/app/services/sesion.service';


@Component({
  selector: 'app-acciones-alm-incremento-inventario',
  templateUrl: './acciones-alm-incremento-inventario.component.html',
  styleUrls: ['./acciones-alm-incremento-inventario.component.scss'],
  providers: [ MedicoService ]
})
export class AccionesAlmIncrementoInventarioComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({

    numero_clasificacion: new UntypedFormControl(-1, []),
    marca: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
    nombre_medicamento: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
    presentacion: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
    formula: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
    gramaje: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
    inventario_cantidad: new UntypedFormControl(0, [Validators.pattern("^[0-9]*$")]),
    numero_medicamento: new UntypedFormControl('', [Validators.required,]),
    observacion: new UntypedFormControl('', [Validators.maxLength(100)]),
    numero_tipo_devolucion: new UntypedFormControl(1, [Validators.maxLength(100)]),
    nombre_licencia: new UntypedFormControl(this._sesionService.getSession().getUsuario().username, []),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id_en: any;
  public file: any;
  public ruta: string = '';

  public clasificacion: any;
  public marcas: any;
  public medicamentos: any;
  public presentacion: any;
  public laravelapistorage: any;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _medicoService: MedicoService,
    private _sesionService: SesionService,
    private _router: Router,
    private _route: ActivatedRoute,  
  ){ 
    this.getConsultaDatosFormInvMedico_en();
    this.laravelapistorage = this._medicoService.getLaravelApiStorage();
    this.form.markAllAsTouched();
    /*modificar*/
    this._route.queryParams.subscribe(
      params => {
        this.id_en = +params['id_en'];
        console.log(this.id_en);
        if(this.id_en){
          console.log('ID: ' + this.id_en);
          this.form.patchValue({ numero_medicamento: this.id_en})
          this.getConsultaAltaMedicamento_en(this.id_en);
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[4].split("?")[0];
    console.log(this.accion);

  }

  /*default*/
  public getConsultaDatosFormInvMedico_en(){
    this.isLoading = true;
    this._medicoService.getConsultaDatosFormInvMedico().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.clasificacion = response.data;
        } 
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }
  /*fin default*/

  /*modificar*/
  public getConsultaAltaMedicamento_en(id: number){
    this.isLoading = true;
    this._medicoService.getConsultaAltaMedicamento(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.setForm(response.data);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public setForm(data: any){
    this.form.patchValue({
      numero_clasificacion: data.id_clasificacion_fk,
      marca: data.marca,
      nombre_medicamento: data.nombre_medicamento,
      presentacion: data.presentacion,
      formula: data.formula,
      gramaje: data.gramaje,
      inventario_cantidad: data.inventario_cantidad,
      numero_medicamento: data.id_medicamentos_fk,
      observacion: data.observacion,
      numero_tipo_devolucion: data.id_tipo_dev_fk,
      nombre_licencia: data.nombre_licencia,
    });
  }
  /*fin modificar*/


  /*escalera buscadores*/
  public getConsultaMarcaMedicamento_en(){
    this.isLoading = true;
    this._medicoService.getConsultaMarcaMedicamento(this.form.value.numero_clasificacion).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({
            marca: '',
            nombre_medicamento: '',
            numero_medicamento: '',
            presentacion:''
          });
          this.marcas = response.data;
        } 
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getConsultaNombresMedicamento_en(){
   
    this.isLoading = true;
    this._medicoService.getConsultaNombresMedicamento(this.form.value.numero_clasificacion, this.form.value.marca).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.medicamentos = response.data;
          this.form.patchValue({
            nombre_medicamento: '',
            numero_medicamento: '',
            presentacion:''
          });
        } 
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getConsultaPresentacionMedicamento_en(){
    console.log(this.medicamentos);
    if(this.medicamentos != null){
      var index = this.medicamentos.findIndex(obj => obj.id_medicamentos_pk == this.form.value.numero_medicamento);
      console.log(index);
      if(index != -1){
        console.log(this.medicamentos[index].nombre_medicamento);
        this.isLoading = true;
        this._medicoService.getConsultaPresentacionMedicamento(this.form.value.numero_clasificacion, this.form.value.marca, this.medicamentos[index].nombre_medicamento).subscribe(
          response => {console.log(response);
            this.isLoading = false;
            if(response.estado == 200){
              this.presentacion = response.data;
              this.form.patchValue({
                presentacion:'',
                nombre_medicamento: this.medicamentos[index].nombre_medicamento,
              });
            }
          },
          error => {console.log(<any>error);
            this.isLoading = false;
          });
      }
    }    
  }

  public postAltaInventarioMedico_en(){
    console.log(this.form.value);
    this._medicoService.postAltaInventarioMedico(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this._router.navigate(['medico/almacen-de-medicamentos/gestor-incremento-inventario']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  /*fin escalera buscadores*/

  /*operaciones*/
  public guardar(){
    console.log(this.form.value);
    this.postAltaInventarioMedico_en();
  }

  public modificar(){
    console.log(this.form.value);
    //this.postModificarInvMedico();
  }
  /*fin operaciones*/

}
