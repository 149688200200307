
<div class="card-container" id="top">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Gestor bitacoras de tratamientos y medicamentos</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div class="row text-align-right">
                
                
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                    <form novalidate [formGroup]="form" autocomplete="off">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>Periodo</mat-label>
                            <mat-select formControlName="numero_periodo" (ngModelChange)="getConsultaBitacoras(1)">
                                <mat-option *ngFor="let p of periodos" [value]="p.id_periodo_bitacora_pk">{{p.periodo}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                    </form>
                </div>
                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                    <button mat-mini-fab (click)="getConsultaBitacoras(1)">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>

                <div class="col">
                    <button class="flat" color="primary" mat-flat-button (click)="postNuevaBitacora(form.value.numero_periodo)">
                        Nueva bitacora
                    </button>
                </div>
                <div class="col">
                    <a  color="accent" class="flat" mat-flat-button  href="{{laravelapi}}/ExcelBitacoras" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon>&nbsp;Registro en Excel
                    </a>
                </div>
                
                
                
                <!--<a  color="accent" class="flat" mat-flat-button  href="{{laravelapi}}/ReporteExcelTerapias" target="_blank" rel=noopener>
                    <mat-icon>list_alt</mat-icon>&nbsp;Registro en Excel
                </a>-->
                
                <!--<button class="flat" color="primary" mat-flat-button>Comparativo</button>
                    <button class="flat" color="primary" mat-flat-button>Gráfica (Pizza)</button>-->
                </div>

                <!-- tabla -->
                <div class="row mt-3 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="id" >
                            <th id="id" mat-header-cell *matHeaderCellDef style="width: 120px !important"> ID </th>
                            <td mat-cell *matCellDef="let element">{{element?.id_bitacora_pk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="fecha">
                            <th id="fecha" mat-header-cell *matHeaderCellDef  style="width: 120px !important"> FECHA </th>
                            <td mat-cell *matCellDef="let element">
                                
                                    {{element?.fecha_ingreso}}
                                
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="estado">
                            <th id="estado" mat-header-cell *matHeaderCellDef> ESTATUS </th>
                            <td mat-cell *matCellDef="let element">{{element?.estado_boton?.estatus}}

                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="incidente">
                            <th id="incidente" mat-header-cell *matHeaderCellDef> INCIDENTE </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element?.se_presento_incidencia == 2">Sin incidente</span>
                                <span *ngIf="element?.se_presento_incidencia == 1">Con incidente</span>
                            </td>
                        </ng-container>



                        <ng-container matColumnDef="bitacora">
                            <th id="bitacora" mat-header-cell *matHeaderCellDef> <span class="d-flex justify-content-center">BITACORA </span> </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="d-flex justify-content-center">
                                    <button  type="button" mat-mini-fab color="primary" >
                                        <mat-icon class="material-icons" [routerLink]="['/medico/gt-bitacora/modificar']" [queryParams]="{id_bitacora: element.id_bitacora_pk}">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                                </span>
                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="consultar">
                            <th id="consultar" mat-header-cell *matHeaderCellDef> <span class="d-flex justify-content-center">CONSULTAR </span> </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="d-flex justify-content-center">
                                    <button  type="button" mat-mini-fab color="primary" >
                                        <mat-icon class="material-icons" [routerLink]="['/medico/gt-bitacora/consultar']" [queryParams]="{id_bitacora: element.id_bitacora_pk}">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                                </span>
                                
                            </td>
                        </ng-container>



                        <!-- fin contenido -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- fin tabla -->

                
                <!--<div class="mt-5 d-flex justify-content-center">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos']" >
                        Regresar
                    </button>
                </div>-->

                
            </mat-card-content>
            <mat-card-actions align="start">
                <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"  aria-label="Selecione una pagina">
                </mat-paginator>
            </mat-card-actions>
        </mat-card>
    </div>
