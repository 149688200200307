<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Gestor de pruebas de beneficiaria</mat-card-title>
            <mat-card-subtitle>Consulte y administre las pruebas para la beneficiaria</mat-card-subtitle>
        </mat-card-header>
    
        <mat-card-content >
            <div class="bt col-md-12">
                <app-datos-generales (fichaTecnica)="recibeFichaTecnica($event)" (beneficiariaSeleccionada)="recibeBeneficiaria($event)" [ficha]="true"></app-datos-generales>
            </div>
            <div class="bt col-md-12 text-align-right">
                <button class="flat" color="accent" mat-flat-button [matMenuTriggerFor]="menu" *ngIf="cuestionarios.length>0">
                    Nueva prueba
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngFor="let cuestionario of cuestionarios" [routerLink]="['alta']" [queryParams]="{idPrueba: cuestionario?.idCuestionario, id: id}">{{cuestionario?.nombreCuestionario}}</button>
                    
                </mat-menu>
                &nbsp;
                <!--<button class="flat" color="primary" mat-flat-button>Comparativo</button>&nbsp;
                <button class="flat" color="primary" mat-flat-button>Gráfica (Pizza)</button>-->
            </div>

            <div class="table-container">
                <div class="spinner" *ngIf="isLoading">
                    <mat-spinner color="accent"></mat-spinner>
                </div>
                <table mat-table [dataSource]="dataSource">
                    <caption></caption>
                    <!--<ng-container matColumnDef="avance">
                        <th mat-header-cell *matHeaderCellDef> Avance </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
                        </td>
                    <!-- Position Column -->
                    <!-- </ng-container>-->
                    <ng-container matColumnDef="position">
                        <th id="position" mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element;let i=index"> {{i+1}} </td>
                    </ng-container>

                    <ng-container matColumnDef="nombreEncuesta">
                        <th id="nombreEv" mat-header-cell *matHeaderCellDef> Nombre de evaluación </th>
                        <td mat-cell *matCellDef="let element"> {{element?.nombreEncuesta}} </td>
                    </ng-container>
                    <ng-container matColumnDef="fechaDePrueba">
                        <th id="fechaPrueba" mat-header-cell *matHeaderCellDef> Fecha de prueba </th>
                        <td mat-cell *matCellDef="let element"> {{element?.fechaPrueba?.date | date: 'dd/MM/yyyy'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="evaluador">
                        <th id="evaluador" mat-header-cell *matHeaderCellDef> Evaluador </th>
                        <td mat-cell *matCellDef="let element"> {{getEvaluador(element?.idEvaluador)}} </td>
                    </ng-container>

                    <ng-container matColumnDef="estatus">
                        <th id="estatus" mat-header-cell *matHeaderCellDef> Estatus </th>
                        <td mat-cell *matCellDef="let element"> {{element?.estatus}} </td>
                    </ng-container>
                    <ng-container matColumnDef="porcentaje">
                        <th id="avance" mat-header-cell *matHeaderCellDef> Avance </th>
                        <td mat-cell *matCellDef="let element" class="text-align-center"> 
                            <mat-progress-bar mode="determinate" value="{{element?.porcentaje}}"></mat-progress-bar>
                            {{element?.porcentaje}} %
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="prueba">
                        <th id="prueba" mat-header-cell *matHeaderCellDef> Prueba </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button color="primary" [routerLink]="['prueba']" [queryParams]="{prueba: element?.idPrueba, id: id}">
                                <mat-icon>assignment</mat-icon>
                            </button>

                            
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="grafica">
                        <th id="reportes" mat-header-cell *matHeaderCellDef> Reportes </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button color="accent" (click)="exportarPDF(element?.idPrueba)" matTooltip="Reporte General">
                                <mat-icon>table_view</mat-icon>
                            </button>
                            <button mat-icon-button color="accent" (click)="exportarPrioridadesPDF(element?.idPrueba)" matTooltip="Reporte de Prioridades">
                                <mat-icon>star_rate</mat-icon>
                            </button>
                            <button mat-icon-button color="accent" (click)="exportarComparativaPDF(element?.idPrueba)" matTooltip="Comparativo">
                                <mat-icon>compare_arrows</mat-icon>
                            </button>
                            <!-- <button mat-icon-button color="accent" (click)="exportarPizzaPDF(element?.idPrueba)" matTooltip="Gráfico">
                                <mat-icon>local_pizza</mat-icon>
                            </button> -->
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="eliminar">
                        <th id="eliminar" mat-header-cell *matHeaderCellDef> Eliminar </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button color="warn" (click)="eliminar(element?.idPrueba)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="bt col-md-12">
                    <mat-paginator
                        [length]="count"
                        [pageSize]="paginador.pageSize"
                        [pageSizeOptions]="paginador.pageOptions"
                        (page)="pager($event)">
                    </mat-paginator>
                </div>
            </div>
            <div class="width100">
                <div id="content" #content>
                    <div class="bt col-md-12">
                        <div *ngFor="let objeto of dataset">
                            <table class="tabla-reporte">
                                <caption style="text-align: left;">
                                    <div>
                                        <strong *ngIf="objeto.titulo">{{objeto.titulo | uppercase}}</strong>
                                    </div>                            
                                    <span *ngIf="objeto.subtitulo">{{objeto.subtitulo | titlecase}}</span>
                                </caption>
                                <tbody>
                                    <tr>
                                        <th id="actividad" width="33.3%">Actividad</th>
                                        <th id="resultados" width="33.3%">Resultado</th>
                                        <th id="comentarios" width="33.3%">Comentarios</th>
                                    </tr>
                                    <tr *ngFor="let subtitulo of objeto.datos">
                                        <td>{{subtitulo.ACTIVIDAD}}</td>
                                        <td class="{{subtitulo.RESULTADO}}">{{subtitulo.RESULTADO}}</td>
                                        <td>{{subtitulo.COMENTARIOS}}</td>
                                    </tr>
                                </tbody>                       
                            </table>
                        </div>
                    </div>
                
                    <div class="bt col-md-12">
                        <div *ngFor="let clasificacion of comparativo.clasificaciones; let ic = index">
                            <table class="tabla-reporte" *ngFor="let subclasificacion of clasificacion.subclasificaciones; let i = index">
                                <caption style="text-align: left;">
                                    <div *ngIf="i==0">
                                        <strong>{{clasificacion.nombre | uppercase}}</strong>
                                    </div>                            
                                    <span>{{subclasificacion.nombre | titlecase}}</span>
                                </caption>
                                <tbody>
                                    <tr>
                                        <th id="actividadC" width="25%">Actividad</th>
                                        <th id="ultimo1" width="25%">{{ this.ultimos[0]?.fecha_app | date: 'dd/MM/yyyy'}}</th>
                                        <th id="ultimo2" width="25%">{{ this.ultimos[1]?.fecha_app | date: 'dd/MM/yyyy'}}</th>
                                        <th id="ultimo3" width="25%">{{ this.ultimos[2]?.fecha_app | date: 'dd/MM/yyyy'}}</th>
                                    </tr>
                                    <tr *ngFor="let pregunta of subclasificacion.preguntas; let ip = index">
                                        <td>{{pregunta?.descripcion}}</td>
                                        <td class="{{pregunta?.respuesta?.split('|')[0]}}">{{pregunta?.respuesta?.split('|')[0]}}</td>
                                        <td class="{{ ultimos[1]?.clasificaciones[ic].subclasificaciones[i].preguntas[ip].respuesta?.split('|')[0] }}">{{ ultimos[1]?.clasificaciones[ic].subclasificaciones[i].preguntas[ip].respuesta?.split('|')[0] }}</td>
                                        <td class="ultimos[2]?.clasificaciones[ic].subclasificaciones[i].preguntas[ip].respuesta?.split('|')[0]">{{ ultimos[2]?.clasificaciones[ic].subclasificaciones[i].preguntas[ip].respuesta?.split('|')[0] }}</td>
                                    </tr>
                                </tbody>                       
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!--<app-pie-chart></app-pie-chart>-->

            <div>
                <div>
                    <div>
                      <div style="display: block">
                        <canvas baseChart

                            [data]="doughnutChartData"
                            [labels]="doughnutChartLabels"
                            [chartType]="doughnutChartType"
                            [options]="pieChartOptions"
                            [plugins]="pieChartPlugins"
                            [colors]="pieChartColors"
                            [legend]="pieChartLegend"
                            (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)">
                          
                            
                        </canvas>
                      </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>