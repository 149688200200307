import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class EvaluacionesService {
  endpoint = "";
  phpPath:string;
  headers = new HttpHeaders();
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService) {
      this.phpPath = _config.getConfiguration().embedPhpPath;
      this.endpoint = _config.getConfiguration().hostapi + this._config.getConfiguration().endpoints["evaluaciones"].path;
  }

  getEvaluaciones(idBeneficiaria:number, page:number, size:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/beneficiaria/"+idBeneficiaria+"?page="+page+"&size="+size);
  }

  createEvaluacion(data:{
    "idEncuesta": number,
    "idUsuario": number,
    "fechaAplicacion": string,
    "idBeneficiaria": number
  }):Observable<ResponseGenerico>{
    return this._http.post<ResponseGenerico>(this.endpoint,data);
  }

  eliminarEvaluacion(id):Observable<ResponseGenerico>{
    return this._http.delete<ResponseGenerico>(this.endpoint+"/"+id);
  }
  cerrarEvaluacion(id):Observable<ResponseGenerico>{
    let data = {
      "idEvaluacion": id
    }
    return this._http.put<ResponseGenerico>(this.endpoint+"/close",data);
  }
  guardarRespuesta(idEvaluacion,idPregunta,idRespuesta,respuesta,prioridad):Observable<ResponseGenerico>{
    let data = {
      "idEvaluacion":idEvaluacion,
      "idPregunta":idPregunta,
      "idRespuesta":idRespuesta,
      "respuesta":respuesta,
      "prioridad":prioridad
    };
    return this._http.put<ResponseGenerico>(this.endpoint,data);
  }

  getEvaluacion(id:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/evaluacion/"+id);
  }
  getEvaluacionPrioritarias(id:number):Observable<any>{
    return this._http.get<ResponseGenerico>(this.endpoint+"/evaluacion/"+id+"/prioridades");
  }
  getNombreEvaluacion(id:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.phpPath+"/getNombreEvaluacion.php?id="+id);
  }
  getComparativo(idBeneficiaria:number, idEvaluacion:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.phpPath+"/printCompareReport.php?ie="+idEvaluacion+"&ib="+idBeneficiaria);
  }
  getPorcentajeDePruebas(idBeneficiaria:number):Observable<ResponseGenerico>{
    return this._http.get<ResponseGenerico>(this.phpPath+"/percentByBenef.php?id="+idBeneficiaria);
  }
  getSpecialReport(idEvaluacion:number,categoria:string):Observable<ResponseGenerico>{
    if(categoria.length>0){
      return this._http.get<ResponseGenerico>(this.phpPath+"/getSpecialReport2.php?id="+idEvaluacion + "&clasif="+categoria);
    }else{
      return this._http.get<ResponseGenerico>(this.phpPath+"/getSpecialReport.php?id="+idEvaluacion);
    }
  }
  getSpecialCount(idEvaluacion){
    return this._http.get<ResponseGenerico>(this.phpPath+"/specialCount.php?id="+idEvaluacion);
  }
}
