<div class="card-container none-contact-aux">
    <mat-card class="example-card">
    <mat-card-header>
        <mat-card-title>Directorio Institucional VIP</mat-card-title>
        <mat-card-subtitle>Localiza y administra contactos del Directorio</mat-card-subtitle>
    </mat-card-header>
   
    <mat-card-content>
        <form (ngSubmit)="buscar(0,pagesize)" autocomplete="off" [formGroup]="formulario">
            <mat-form-field appearance="fill" class="bt col-md-6 none-contact-aux">
                <mat-label>Buscador</mat-label>
                <input matInput placeholder="Nombre del contacto" formControlName="buscador">
                
            </mat-form-field>
            &nbsp;
            <!--<mat-form-field appearance="fill" class="bt col-md-4">
                <mat-label>Etapa</mat-label>
                <mat-select formControlName="subcategoria">
                    <mat-option value="">-- Seleccione una opción --</mat-option>
                    <mat-option ></mat-option>
                </mat-select>
            </mat-form-field>-->
            <button type="submit" mat-mini-fab color="accent">
                <mat-icon>search</mat-icon>
            </button>

            <br/>
            <button type="button" color="primary" class="flat" mat-flat-button [routerLink]="['new']" *ngIf="tieneAccionCrear; else elseButtonNew">
                <mat-icon>add</mat-icon>Nuevo registro
            </button>
            <ng-template #elseButtonNew>
                <button type="button" color="primary" class="flat" mat-flat-button disabled>
                    <mat-icon>add</mat-icon>Nuevo Registro
                </button>
            </ng-template>
            &nbsp;
            
        </form>
        
        <div class="table-container">
            <div class="spinner none-contact-aux" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <table mat-table [dataSource]="dataSource">
                <caption></caption>
                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
                
                <!-- Position Column -->
                <ng-container matColumnDef="position" class="none-contact-aux">
                    <th id="position" mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element;let i=index"> {{i+1}} </td>
                </ng-container>

                <!-- Position Column -->
                <ng-container matColumnDef="nombre" class="none-contact-aux">
                <th id="nombre" mat-header-cell *matHeaderCellDef> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                </ng-container>
            
                
            
                <ng-container matColumnDef="tipo" class="none-contact-aux">
                    <th id="tipo" mat-header-cell *matHeaderCellDef> Tipo </th>
                    <td mat-cell *matCellDef="let element">
                        {{consultaTipo(element.tipo_contacto)}}
                    </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="cargo" class="none-contact-aux">
                <th id="cargo" mat-header-cell *matHeaderCellDef> Cargo </th>
                <td mat-cell *matCellDef="let element"> {{element.cargo}} </td>
                </ng-container>
            
                <!-- Symbol Column -->
                <ng-container matColumnDef="organizacion" class="none-contact-aux">
                <th id="organizacion" mat-header-cell *matHeaderCellDef> Organización </th>
                <td mat-cell *matCellDef="let element"> {{element.organizacion}} </td>
                </ng-container>

                
                
                <ng-container matColumnDef="mail" class="none-contact-aux">
                    <th id="mail" mat-header-cell *matHeaderCellDef> Mail </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.correo}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="telefono" class="none-contact-aux">
                    <th id="telefono" mat-header-cell *matHeaderCellDef> Teléfono </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.telefono}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="extencion" class="none-contact-aux">
                    <th id="ext" mat-header-cell *matHeaderCellDef> Extención </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.extencion}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="celular" class="none-contact-aux">
                    <th id="celular" mat-header-cell *matHeaderCellDef> Celular </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.celular}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="ver" class="none-contact-aux">
                    <th id="editar" mat-header-cell *matHeaderCellDef> Ver </th>
                    <td mat-cell *matCellDef="let element">
                        <a mat-icon-button color="primary" [routerLink]="['view']" [queryParams]="{id:element.id_contactos}">
                            <mat-icon>preview</mat-icon>
                        </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="editar" class="none-contact-aux">
                    <th id="editar" mat-header-cell *matHeaderCellDef> Editar </th>
                    <td mat-cell *matCellDef="let element">
                        <a mat-icon-button color="primary" [routerLink]="['edit']" [queryParams]="{id:element.id_contactos}" *ngIf="tieneAccionEditar; else elseButtonEdit">
                            <mat-icon>edit</mat-icon>
                        </a>
                        <ng-template #elseButtonEdit>
                            <button mat-icon-button  color="warn" disabled>
                                <mat-icon>edit</mat-icon>
                            </button>
                        </ng-template>
                    </td>
                </ng-container>
                <ng-container matColumnDef="eliminar" class="none-contact-aux">
                    <th id="eliminar" mat-header-cell *matHeaderCellDef> Eliminar </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="warn" (click)="eliminar(element.id_contactos)" *ngIf="tieneAccionEliminar; else elseButtonDel">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <ng-template #elseButtonDel>
                            <button mat-icon-button  color="warn" disabled>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </ng-template>
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card-content>
    <mat-card-actions>

        <mat-paginator
            [length]="count"
            [pageSize]="paginador.pageSize"
            [pageSizeOptions]="paginador.pageOptions"
            (page)="pager($event)">
        </mat-paginator>

    </mat-card-actions>
</mat-card>