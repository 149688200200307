import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { BeneficiariaService } from 'src/app/services/beneficiaria.service';

@Component({
  selector: 'app-sanacion',
  templateUrl: './sanacion.component.html',
  styleUrls: ['./sanacion.component.scss']
})
export class SanacionComponent implements OnInit {
  notas = [];
  notas2 = [];
  formulario: UntypedFormGroup;
  idProceso:number;
  idBeneficiaria:number;
  isLoading = false;
  indEstatus:any;
  conclusiones = "";
  constructor(private dialog:MatDialog,private _location:Location,private activatedRoute:ActivatedRoute,private _beneficiariaService:BeneficiariaService) {
    this.formulario = new UntypedFormGroup({
      fechaApertura : new UntypedFormControl("",[Validators.required]),
      conclusionesRelevantes: new UntypedFormControl("",[]),
      fechaCierre: new UntypedFormControl(null,[]),

      feecha: new UntypedFormControl("",[]),
      descripcion_noota: new UntypedFormControl("",[]),
    })
    this.formulario.controls["fechaApertura"].disable();
   }

  ngOnInit(): void {
    this.notas = [];
    this.notas2 = [];
    this.activatedRoute.queryParams.subscribe(params=>{
      this.idProceso = params["idProceso"];
      this.idBeneficiaria = params["idBeneficiaria"];
      this.isLoading=true;
      this._beneficiariaService.getProcesoSanacion(this.idProceso).subscribe(result=>{
        this.isLoading=false;
        
        this.conclusiones = result.data.conclusionesAPrueba;
        this.indEstatus = result.data.indEstatus;
        this.notas = result.data.notas;
        if(result.data.fechaCierre){
          this.formulario.controls.fechaCierre.setValue(new Date(result.data.fechaCierre.date));
        }
        if(result.data.fechaApertura){
          console.info(params["fechaAlta"]);
          this.formulario.controls.fechaApertura.setValue(new Date(params["fechaAlta"]));
          //this.formulario.controls.fechaApertura.setValue(new Date(result.data.fechaApertura.date));
        }
        this.formulario.controls.conclusionesRelevantes.setValue(result.data.conclusiones);
      },()=>{
        this.isLoading=false;
      })
    });
    
  }

  
  cancelar(){
    this._location.back();
  }
  sa_sendForm(){
    console.info(this.formulario.controls);
    if(this.formulario.valid){
      this.sa_guardar(0);
    }else{
      console.info("invalid");
    }
  }

  sa_guardar(indicador){
    let data = {
      "tipoEvento": "EVENTO_GUARDAR",
      "etapaActual":"SANACION",
      "informacion":{
        "idProceso": this.idProceso,
            "fechaApertura": this.formulario.controls.fechaApertura.value.toJSON().replace("T"," ").replace(".000Z",""),
            "fechaCierre": this.formulario.controls.fechaCierre.value ? this.formulario.controls.fechaCierre.value.toJSON().replace("T"," ").replace(".000Z",""):"",
            "conclusiones":this.formulario.controls.conclusionesRelevantes.value,
            "notas":[
                {"fechaNota": "2020-12-29 00:00:00","descNota":"Nota Extra"}
            ]
      }
    };

    data.informacion.notas = [];
    for(let i=0; i<this.notas2.length; i++){
      data.informacion.notas.push({
        "fechaNota" : this.formulario.controls["fechaNota"+i].value.toJSON().replace("T"," ").replace(".000Z",""),
        "descNota" : this.formulario.controls["descripcionNota"+i].value
      });
    }
    console.info(data);
    this.isLoading=true;
    this._beneficiariaService.updateEtapa(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading=false;
      if(indicador==2){
        this.sa_cerrarEtapa2();
      }else if(indicador==3){
        this.sa_cerrarEtapa3();
      }else{
        this.ngOnInit();
        this.sa_mensaje2("Etapa actualizada","La etapa se actualizo correctamente",true);
      }
    },()=>{
      this.isLoading=false;
    })
  }
  sa_cerrarEtapa(){
    console.info(this.formulario.controls.fechaCierre.value);
    if(this.formulario.controls.fechaCierre.value){
      this.sa_guardar(2);
    }else{
      this.dialog.open(MessageBoxComponent,{
        data:{titulo:"Ingrese fecha de cierre",mensaje:"Para cerrar la etapa es necesario completar el campo 'Fecha de cierre'"}
      })
    }
  }
  sa_egresar(){
    console.info(this.formulario.controls.fechaCierre.value);
    if(this.formulario.controls.fechaCierre.value){
      this.sa_guardar(3);
    }else{
      this.dialog.open(MessageBoxComponent,{
        data:{titulo:"Ingrese fecha de cierre",mensaje:"Para cerrar la etapa es necesario completar el campo 'Fecha de cierre'"}
      })
    }
  }
  sa_cerrarEtapa2(){
    let data = {
      "tipoEvento": "EVENTO_CERRAR",
      "etapaActual":"SANACION",
      "informacion":{
        "idProceso": this.idProceso,
      }
    };

    this.isLoading=true;
    this._beneficiariaService.updateEtapa(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading=false;
      this.sa_mensaje2("Etapa actualizada","La etapa se actualizo correctamente",false);
    },()=>{
      this.isLoading=false;
    })


  }
  sa_cerrarEtapa3(){
    let data = {
      "tipoEvento": "EVENTO_EGRESO",
      "etapaActual":"SANACION",
      "informacion":{
        "idProceso": this.idProceso,
      }
    };

    this.isLoading=true;
    this._beneficiariaService.updateEtapa(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading=false;
      this.sa_mensaje2("Etapa actualizada","La etapa se actualizo correctamente",false);
    },()=>{
      this.isLoading=false;
    })


  }

  sa_mensaje(titulo, mensaje, opcion): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {titulo: titulo, mensaje: mensaje, desicion:true}
    });

    
  }

  sa_mensaje2(titulo, mensaje, opcion): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      data: {titulo: titulo, mensaje: mensaje, desicion:true}
    });

    dialogRef.afterClosed().subscribe(result=>{
      if(result){
        this.ngOnInit();
      }else{
        this.cancelar();
      }
    });
  }

  /*sa_agregarNota(){
    this.formulario.addControl("fechaNota"+(this.notas2.length),new UntypedFormControl("",[Validators.required]))
    this.formulario.addControl("descripcionNota"+(this.notas2.length),new UntypedFormControl("",[]));
    this.notas2.push(1);
  }*/

  sa_suspender(){
    let data = {
      "tipoEvento": "EVENTO_SUSPENDER",
      "etapaActual":"SANACION",
      "informacion":{
        "idProceso": this.idProceso,
      }
    };

    this.isLoading=true;
    this._beneficiariaService.updateEtapa(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading=false;
      this.ngOnInit();
      this.sa_mensaje2("Etapa actualizada","La etapa se actualizo correctamente",false);
      
    },()=>{
      this.isLoading=false;
    })
  }

  sa_activar(){
    let data = {
      "tipoEvento": "EVENTO_REACTIVAR",
      "etapaActual":"SANACION",
      "informacion":{
        "idProceso": this.idProceso,
      }
    };

    this.isLoading=true;
    this._beneficiariaService.updateEtapa(this.idBeneficiaria,this.idProceso,data).subscribe(result=>{
      this.isLoading=false;
      this.ngOnInit();
      this.sa_mensaje2("Etapa actualizada","La etapa se actualizo correctamente",false);
      
    },()=>{
      this.isLoading=false;
    })
  }

  


  public feecha: string = '';
  public descripcion_noota: string = '';
  public sa_agregarNota(){
    this.formulario.addControl("fechaNota"+(this.notas2.length),new UntypedFormControl(this.formulario.controls.feecha.value,[Validators.required]))
    this.formulario.addControl("descripcionNota"+(this.notas2.length),new UntypedFormControl(this.formulario.controls.descripcion_noota.value ,[]));
    this.notas2.push(1);

    this.formulario.patchValue({
      feecha: '',
      descripcion_noota: ''
    });
  }

  public sa_eliminarNoota(i: number){
    console.log(i);
    this.notas2.splice(i, 1)
  }
}
