//General
import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewChild, Inject } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { SesionService } from 'src/app/services/sesion.service';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { CalendarioService } from 'src/app/services/calendario.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios

@Component({
  selector: 'app-modal-cal-form1',
  templateUrl: './modal-cal-form1.component.html',
  styleUrls: ['./modal-cal-form1.component.scss'],
  providers: [ CalendarioService ]
})
export class ModalCalForm1Component implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    //numero_control_fecha: new UntypedFormControl(-1, []),
    tipo_configuracion: new UntypedFormControl(-1, []),
    numero_calendario: new UntypedFormControl('', [Validators.required]),
    nombre: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
    nombre_actividad: new UntypedFormControl('', []),
    diaria_cada: new UntypedFormControl(1, []),
    tipo_diaria: new UntypedFormControl(1, []),
    semanal_repetir: new UntypedFormControl(1, []),
    tipo_semanal: new UntypedFormControl(0, []),
    numero_estructura: new UntypedFormControl(1, []),
    frecuencia: new UntypedFormControl(1, []),
    fecha_inicio: new UntypedFormControl('', [Validators.required]),
    fecha_fin: new UntypedFormControl('', [Validators.required]),
    hora_inicio: new UntypedFormControl('', [Validators.required]),
    hora_fin: new UntypedFormControl('', [Validators.required]),
    numero_catalogo_calendario: new UntypedFormControl(-1, []),
    descripcion: new UntypedFormControl('', []),
    //frecuencia 2
    semanal_dias: new UntypedFormControl('', []),
    L: new UntypedFormControl(false, []),
    M: new UntypedFormControl(false, []),
    Mi: new UntypedFormControl(false, []),
    J: new UntypedFormControl(false, []),
    V: new UntypedFormControl(false, []),
    S: new UntypedFormControl(false, []),
    D: new UntypedFormControl(false, []),
    semanal_dias_aux: new UntypedFormControl([], []),
    aux_ben: new UntypedFormControl([1], []),
    aux_per: new UntypedFormControl([1], []),
    beneficiarios: new UntypedFormControl([], []),
    personal: new UntypedFormControl([], []),
    numero_id_configuracion: new UntypedFormControl(-1, []),
    numero_control_fecha: new UntypedFormControl(-1, []),
  });

  
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id_bitacora: any;
  public laravelapiStorage = this._CalendarioService.getLaravelApiStorage();
  public laravelapi = this._CalendarioService.getLaravelApi();
  public t: any;
  public data: any;
  public bene: any = [];
  public tts: any;
  public titulo: string = 'Formulario Registro de Evento tipo ';
  public asinBene: any;
  public asigPersonal: any;
  public dataForm: any;

  

  public asinBene2: any = [];
  public asigPersonal2: any = [];


  public tipo_evento: number = -1;
  public tipo_general: number = -1;
  public all: any;
  public all2: any;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _CalendarioService: CalendarioService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
    private _sesion:SesionService,
    @Inject(MAT_DIALOG_DATA) public payload: any,
  ) {
    
    this.configurarPayload();



    this._route.queryParams.subscribe(
      params => {
        
        if(params['tipo']){
          this.tipo_general = +params['tipo'];
          console.log(this.tipo_general);
          this.form.patchValue({ numero_catalogo_calendario: +this.tipo_general.toString() }); 
          if(this.payload.accion == 'modificar'){
            if(this.payload?.payload?.modificar_control){
              this.postConsultarControlCalendario(this.payload.id);
            }else{
              this.form.patchValue({numero_control_fecha: this.payload.id})
              this.postConsultarConfiguracionCalendario(this.payload.id);
            }
            
          }
        }
      });

    

   }

  ngOnInit(): void {
    this.getCalendarioBeneficiarios();
    this.getCalendarioEmpleados();
    this.getDatosCalendarioForm();
    this.form.markAllAsTouched();
  }

  public configurarPayload(){
    console.log(this.payload);
    this.tipo_evento = this.payload.tipo;
    this.form.patchValue({
      tipo_configuracion: this.tipo_evento,
      fecha_inicio: this.payload?.payload?.fecha_inicio
    });
    

    if(this.tipo_evento == 1){
      this.titulo = 'Formulario Registro de Evento tipo Estructural';
    }else if(this.tipo_evento == 2){
      this.titulo = 'Formulario Registro de Evento tipo Individual';
    }
  }

  public cambiarConfiguracion(){
    //this.payload.tipo = false;
    this.payload.id = this.all2.id_configuracion_calendario_fk;
    this.payload.accion = 'modificar';
    this.payload.payload.modificar_control = false;
    this.configurarPayload();
    this.postConsultarConfiguracionCalendario(this.all2.id_configuracion_calendario_fk);
    //element?.id_tipo_calendario_fk , element?.id_configuracion_calendario_pk, 'modificar', null
  }

  public cambiarHijo(id: number){
    this.payload.id = id;
    this.payload.accion = 'modificar';
    this.payload = {
      id: id,
      accion: 'modificar',
      payload:{
        modificar_control: true
      }
    }
    this.payload.payload.modificar_control = true;
    this.configurarPayload();
    this.postConsultarControlCalendario(this.payload.id);
  }



  public getCalendarioBeneficiarios(){
    this._CalendarioService.getCalendarioBeneficiarios().subscribe(
      response => {console.log(response);
        if(response.estado == 200){
          this.asinBene = response?.data;
        }
      },
      error => {console.log(<any>error);
      });
  }

  public getCalendarioEmpleados(){
    this._CalendarioService.getCalendarioEmpleados().subscribe(
      response => {console.log(response);
        if(response.estado == 200){
          this.asigPersonal = response?.data;
        }
      },
      error => {console.log(<any>error);
      });
  }

  public getDatosCalendarioForm(){
    this._CalendarioService.getDatosCalendarioForm().subscribe(
      response => {console.log(response);
        if(response.estado == 200){
          this.dataForm = response.data;
        }
      },
      error => {console.log(<any>error);
      });
  }

  /*configuraciones*/
  public postConsultarConfiguracionCalendario(id: number){
    this._CalendarioService.postConsultarConfiguracionCalendario({numero_config_calendario: id}).subscribe(
      response => {console.log(response);
        if(response.estado == 200){
          this.all = response.data;
          this.setForm(this.all);
        }
      },
      error => {console.log(<any>error);
      });
  }

  /*control de fecha*/
  public postConsultarControlCalendario(id: number){
    this._CalendarioService.postConsultarControlCalendario({numero_control_fecha: id}).subscribe(
      response => {console.log(response);
        if(response.estado == 200){
          this.all2 = response.data;
          this.setForm(response.data);
        }
      },
      error => {console.log(<any>error);
      });
  }




  public setForm(data: any){
    let nombre = '';
    let tipo_configuracion = '';
    if(data?.nombre_actividad){
      nombre = data?.nombre_actividad;
    }else{
      nombre = data?.configuracion_calendario?.nombre_actividad;
    }

    if(data?.tipo_configuracion){
      tipo_configuracion = data?.tipo_configuracion;
    }else{
      tipo_configuracion = data?.configuracion_calendario?.tipo_configuracion;
    }
      
    this.form.patchValue({
      tipo_configuracion: tipo_configuracion,
      numero_calendario: data?.tipo_calendario?.id_tipo_calendario_pk,
      nombre: nombre,
      nombre_actividad: nombre,
      descripcion: data?.descripcion,

      diaria_cada: data?.diaria_cada,
      semanal_repetir: data?.semanal_repetir,
      tipo_diaria: data?.tipo_diaria,
      numero_estructura: data?.id_estructura_fk,
      frecuencia: data?.frecuencia,
      fecha_inicio: data?.fecha_inicio,
      fecha_fin: data?.fecha_fin,
      hora_inicio: data?.hora_inicio,
      hora_fin: data?.hora_fin,
      numero_catalogo_calendario: data?.id_catalogo_calendario_fk,
      numero_control_fecha: data?.id_control_fecha_pk, //control de fecha
      //frecuencia 2
      semanal_dias: data?.semanal_dias,

      //L: data?.ssss,
      //M: data?.ssss,
      //Mi: data?.ssss,
      //J: data?.ssss,
      //V: data?.ssss,
      //S: data?.ssss,
      //D: data?.ssss,

      //semanal_dias_aux: data?.ssss,
      //aux_ben: data?.ssss,
      //aux_per: data?.ssss,
      //beneficiarios: data?.ssss,
      //personal: data?.ssss,
    });

    this.asinBene2 = data?.beneficiario;
    this.asigPersonal2 = data?.personal;

    if(this.asinBene2 == null){
      this.asinBene2 = [];
    }else{
      for(let i = 0; i < this.asinBene2?.length; i++){
        this.asinBene2[i].numero_bene = this.asinBene2[i].id;
      }
    }
    if(this.asigPersonal2 == null){
      this.asigPersonal2 = [];
    }else{
      for(let i = 0; i < this.asigPersonal2?.length; i++){
        this.asigPersonal2[i].numero_personal = this.asigPersonal2[i].id_info_empleado_pk;
      }
    }

    var k = [];
    var d = data?.semanal_dias?.split('/');
    //console.log(d);
    if(d != undefined){
      if(d?.indexOf('Mon') != '-1' || d?.indexOf('L') != '-1'){
        this.form.patchValue({ L: true });
      }
      if(d?.indexOf('Tue') != '-1' || d?.indexOf('M') != '-1'){
        this.form.patchValue({ M: true });
      }
      if(d?.indexOf('Wed') != '-1' || d?.indexOf('Mi') != '-1'){
        this.form.patchValue({ Mi: true });
      }
      if(d?.indexOf('Thu') != '-1' || d?.indexOf('J') != '-1'){
        this.form.patchValue({ J: true });
      }
      if(d?.indexOf('Fri') != '-1' || d?.indexOf('V') != '-1'){
        this.form.patchValue({ V: true });
      }
      if(d?.indexOf('Sat') != '-1' || d?.indexOf('S') != '-1'){
        this.form.patchValue({ S: true });
      }
      if(d?.indexOf('Sun') != '-1' || d?.indexOf('D') != '-1'){
        this.form.patchValue({ D: true });
      }
    }
    //console.log(k);

    this.setTipoDS();

    console.log(this.payload?.payload?.fecha_inicio);
    if(this.payload?.payload?.fecha_inicio){
      console.log('entro!!!')
      console.log(this.payload?.payload?.fecha_inicio);
      this.form.patchValue({
        fecha_inicio: this.payload.payload.fecha_inicio
      });
      console.log(this.form.value);
    }
  }

  public setTipoDS(){
    if(this.form.value.frecuencia == 1){
      this.form.patchValue({
        tipo_diaria: 1,
        tipo_semanal: 0
      });
    }else{
      this.form.patchValue({
        tipo_diaria: 0,
        tipo_semanal: 1
      });
    }
    console.log(this.form.value);
  }


  @ViewChild('myModal') myModal:ElementRef | undefined;
  public postRegistrarConfiguracionCalendario(){
    this.ocultarCamposNoNecesarios();
    this._CalendarioService.postRegistrarConfiguracionCalendario(this.form.value).subscribe(
      response => {console.log(response);
        if(response.estado == 200){
          this.myModal?.nativeElement.click();
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this._router.navigate(['/calendario'], 
          { queryParams:
            {t: new Date().getSeconds(), tipo: this.tipo_general}
          });

          

        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio', icon:'error'}});
        }
        this.habilitarCampos();
      },
      error => {console.log(<any>error);
        this.habilitarCampos();
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
      });
  }

  public registrar(accion: string = 'registrar'){
    if(accion == 'registrar'){
      this.ocularIdPrincipal();
    }else if(accion == 'modificar'){
      this.form.patchValue({numero_id_configuracion: this.payload.id})
    }
    if(this.form.value.tipo_configuracion == 1 && this.form.value.frecuencia == 1){
      console.log('caso 1');
      this.ocultarCamposEstructural();
      this.ocultarCampos2();
      this.postRegistrarConfiguracionCalendario();
    }else if(this.form.value.tipo_configuracion == 1 && this.form.value.frecuencia == 2){
      console.log('caso 2');
      this.ocultarCamposEstructural();
      this.ocultarCampos1();
      this.ajusteFrecuencia2();
      if(this.form.value.semanal_dias != ''){
        this.postRegistrarConfiguracionCalendario();
      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Introduce al menos un día Repeticiones -> Semanal', icon:'error'}});
      }
    }else if(this.form.value.tipo_configuracion == 2 && this.form.value.frecuencia == 1){
      console.log('caso 3');
      this.ocultarCampos2();
      this.form.patchValue({
        beneficiarios: this.asinBene2,
        personal: this.asigPersonal2,
      });
      this.postRegistrarConfiguracionCalendario();
    }else if(this.form.value.tipo_configuracion == 2 && this.form.value.frecuencia == 2){
      console.log('caso 4');
      this.ocultarCampos1();
      this.ajusteFrecuencia2();
      this.form.patchValue({
        beneficiarios: this.asinBene2,
        personal: this.asigPersonal2,
      });
      this.postRegistrarConfiguracionCalendario();
    }





    console.log(this.form.value);
  }


  public registrar2(){
    this.ocultarCampos1();
    this.ocultarCampos2();
    this.ocultarCamposNoNecesarios();
    this.ocularIdPrincipal();
    if(this.form.value.tipo_configuracion == 1){
      this.ocultarCamposEstructural();
    }


    this.postModificarEventoControlCalendario();
  }

  public ocultarCampos1(){
    this.form.controls['tipo_diaria'].disable();
    this.form.controls['diaria_cada'].disable();
  }

  public ocultarCampos2(){
    this.form.controls['tipo_semanal'].disable();
    this.form.controls['semanal_repetir'].disable();
  }

  public ocultarCamposEstructural(){
    this.form.controls['beneficiarios'].disable();
    this.form.controls['personal'].disable();
  }

  public ocultarCamposNoNecesarios(){
    this.form.controls['L'].disable();
    this.form.controls['M'].disable();
    this.form.controls['Mi'].disable();
    this.form.controls['J'].disable();
    this.form.controls['V'].disable();
    this.form.controls['S'].disable();
    this.form.controls['D'].disable();


    this.form.controls['aux_ben'].disable();
    this.form.controls['aux_per'].disable();
    this.form.controls['semanal_dias_aux'].disable();
  }

  public ocularIdPrincipal(){
    this.form.controls['numero_id_configuracion'].disable();
  }

  public habilitarCampos(){
    this.form.controls['tipo_diaria'].enable();
    this.form.controls['tipo_semanal'].enable();
    this.form.controls['diaria_cada'].enable();
    this.form.controls['semanal_repetir'].enable();
    this.form.controls['beneficiarios'].enable();
    this.form.controls['personal'].enable();
    this.form.controls['L'].enable();
    this.form.controls['M'].enable();
    this.form.controls['Mi'].enable();
    this.form.controls['J'].enable();
    this.form.controls['V'].enable();
    this.form.controls['S'].enable();
    this.form.controls['D'].enable();

    this.form.controls['aux_ben'].enable();
    this.form.controls['aux_per'].enable();
    this.form.controls['semanal_dias_aux'].enable();
    this.form.controls['numero_id_configuracion'].enable();
  }

  public ajusteFrecuencia2(){
    var c = '';
    if(this.form.value.L){
      c = c + '/Mon';
    }
    if(this.form.value.M){
      c = c + '/Tue';
    }
    if(this.form.value.Mi){
      c = c + '/Wed';
    }
    if(this.form.value.J){
      c = c + '/Thu';
    }
    if(this.form.value.V){
      c = c + '/Fri';
    }
    if(this.form.value.S){
      c = c + '/Sat';
    }
    if(this.form.value.D){
      c = c + '/Sun';
    }
    c = c?.slice(1);
    this.form.patchValue({
      semanal_dias: c
    });
  }

  public agregarBene(){

    if(this.asinBene2?.length == 0){
      this.asinBene2?.push({numero_bene: this.form.value.aux_ben});
    }else{
      let k = true;
      for(let i = 0; i < this.asinBene2?.length; i++){
        if(this.form.value.aux_ben == this.asinBene2[i].numero_bene){
          k = false;
        }
      }
      if(k){  
        this.asinBene2?.push({numero_bene: this.form.value.aux_ben});
      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Ya existe el beneficiario', icon:'error'}});
      }
    }
    console.log(this.asinBene2);

    
  }

  public agregarPerso(){
    if(this.asigPersonal2?.length == 0){
      this.asigPersonal2?.push({numero_personal: this.form.value.aux_per});
    }else{
      let k = true;
      for(let i = 0; i < this.asigPersonal2?.length; i++){
        if(this.form.value.aux_per == this.asigPersonal2[i].numero_personal){
          k = false;
        }
      }
      if(k){  
        this.asigPersonal2?.push({numero_personal: this.form.value.aux_per});
      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Ya existe el personal', icon:'error'}});
      }
    }
    console.log(this.asigPersonal2);
  }

  public eliminarBene(i: number){
    this.asinBene2.splice(i, 1);
  }

  public eliminarPerso(i: number){
    this.asigPersonal2.splice(i, 1);
  }






  

  //getConsultarCalendarioBusqueda(tipo: number, r: number, nmm: number, tc: number)


  public postModificarEventoControlCalendario(){
    this.ocultarCamposNoNecesarios();
    this.form.patchValue({
      beneficiarios: this.asinBene2,
      personal: this.asigPersonal2,
    });
    this._CalendarioService.postModificarEventoControlCalendario(this.form.value).subscribe(
      response => {console.log(response);
        if(response.estado == 200){
          this.myModal?.nativeElement.click();
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this._router.navigate(['/calendario'], 
          { queryParams:
            {t: new Date().getSeconds(), tipo: this.tipo_general}
          });

          

        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio', icon:'error'}});
        }
        this.habilitarCampos();
      },
      error => {console.log(<any>error);
        this.habilitarCampos();
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
      });
  }













  /*archivo*/
  public postRegistrarDocumentoEventoControlCalendario(event: any, identificador: number): any{

    
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      console.log(file.type);
      if (file.type.startsWith('application/pdf')) {//text/xml
        
        const formData = new FormData();
        formData.set('documento', file);
        formData.set('numero_control_fecha',  identificador.toString());
        this.isLoading = true;
        this._CalendarioService.postRegistrarDocumentoEventoControlCalendario(formData).subscribe(
          response => {console.log(response);
            if(this.payload?.payload?.modificar_control){
              this.postConsultarControlCalendario(this.payload.id);
            }
            //else{
            //  this.form.patchValue({numero_control_fecha: this.payload.id})
            //  this.postConsultarConfiguracionCalendario(this.payload.id);
            //}
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            
          },
          error => {console.log(<any>error);
            this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
            this.isLoading = false;
        });


      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato', icon:'error'}});
      }
    }  
  }


}
