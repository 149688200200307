

<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Gestor de expediente personal</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <div class="spinner" *ngIf="ghedp_isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
   


        <form [formGroup]="ghedp_form" (ngSubmit)="getExpedienteConsultas(1)" autocomplete="off">
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>AREA O DEPARTAMENTE</mat-label>
                        <mat-select formControlName="buscador">
                            <mat-option [value]="1">Búsqueda por nombre</mat-option>
                            <mat-option [value]="2">Búsqueda por puesto</mat-option>
                            <mat-option [value]="3">Todo</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5" *ngIf="ghedp_form.value.buscador == 1 || ghedp_form.value.buscador == 2">
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>Buscador</mat-label>
                        <input matInput formControlName="buscar">
                    </mat-form-field>
                </div>
                <div class="col">
                    <button mat-mini-fab type="submit">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
               
                

        
                
                
            </div>
            <div class="row">
                <div class="col">
                    <a class="flat" color="accent" mat-flat-button href="{{ghedp_laravelapi}}/ReporteExcelExpedientesEmpleado" target="_blank" rel=noopener>
                        <mat-icon>list_alt</mat-icon> Reporte EXCEL ficha técnica
                    </a>
                </div>
                <div class="col">
                   <button type="button" class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/registrar']" >
                    <mat-icon>add</mat-icon> Nuevo registro expediente
                </button>
                </div>
                <div class="col">
                    <button type="button" class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones']" >
                     <mat-icon>add</mat-icon> Registro capacitaciones
                 </button>
                 <div class="col">

                 </div>
                 <div class="col">

                 </div>
            </div>
            

     
             
             
         
        </div>
        <br>
    </form>



    <!-- tabla -->
    <div class="row mt-3 mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <caption><!--Parrilla--></caption>
            <!-- contenido -->

            <ng-container matColumnDef="id_empleado">
                <th id="id_empleado" mat-header-cell *matHeaderCellDef class="text-center"> ID EMPLEADO </th>
                <td mat-cell *matCellDef="let element" class="text-center">GTTI-{{element?.id_info_empleado_pk}}</td>
            </ng-container>

            <ng-container matColumnDef="nombre_empleado">
                <th id="nombre_empleado" mat-header-cell *matHeaderCellDef style="width: 180px !important" class="text-center">NOMBRE EMPLEADOS </th>
                <td mat-cell *matCellDef="let element" class="text-center">{{element?.nombre}}</td>
            </ng-container>

            <ng-container matColumnDef="puesto">
                <th id="puesto" mat-header-cell *matHeaderCellDef class="text-center">PUESTO </th>
                <td mat-cell *matCellDef="let element" class="text-center">{{element?.datos_puesto?.puestos_reporte?.puestos_reporte}}</td>
            </ng-container>

            <ng-container matColumnDef="estatus">
                <th id="estatus" mat-header-cell *matHeaderCellDef class="text-center">ESTATUS </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <span *ngIf="element?.datos_puesto?.tarjeta_nomina?.baja_empleado == 1">
                        Baja
                    </span>

                    <span *ngIf="element?.datos_puesto?.tarjeta_nomina?.baja_empleado == 0 || element?.datos_puesto?.tarjeta_nomina?.baja_empleado == null">
                        Activo
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="documentos">
                <th id="documentos" mat-header-cell *matHeaderCellDef class="text-center">DOCUMENTOS </th>
                <td mat-cell *matCellDef="let element">

                    <div class="container-fluid mt-3" >
                        <div class="alert alert-success text-center" role="alert" *ngIf=" validandoDocumentos(element?.documentos) == true">
                            Completo
                        </div>
    
                        <div class="alert alert-warning text-center" role="alert" *ngIf=" validandoDocumentos(element?.documentos) ==  false">
                            Faltan
                        </div>
                       
                    </div>
                    
                </td>
            </ng-container>

            <ng-container matColumnDef="fecha_ingreso">
                <th id="fecha_ingreso" mat-header-cell *matHeaderCellDef class="text-center">FECHA INGRESO </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    
                    {{element?.datos_puesto?.fecha_ingreso}}
                    
                </td>
            </ng-container>

            <ng-container matColumnDef="tiempo_laborando">
                <th id="tiempo_laborando" mat-header-cell *matHeaderCellDef class="text-center">TIEMPO LABORANDO </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    
                    {{getTiempoLaborando(element?.datos_puesto?.fecha_ingreso)}}
                    
                </td>
            </ng-container>

            <ng-container matColumnDef="vacaciones_disponibles">
                <th id="vacaciones_disponibles" mat-header-cell *matHeaderCellDef class="text-center">VACACIONES QUE LE CORRESPONDEN </th>
                <td mat-cell *matCellDef="let element"  class="text-center">{{element?.vacaciones_corresponden   }}</td>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <th id="acciones" mat-header-cell *matHeaderCellDef class="text-center">ACCIONES&nbsp;</th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/consultar']" [queryParams]="{id_exp:element.id_info_empleado_pk}">
                            <span> VER EXPEDIENTE </span>
                        </button>
                        <button mat-menu-item [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/modificar']" [queryParams]="{id_exp:element.id_info_empleado_pk}">
                            <span> EDITAR EXPEDIENTE </span>
                        </button>
                        <button mat-menu-item (click)="eliminarEmpleadosExpedientes(element.id_info_empleado_pk)">
                            <span> ELIMINAR EXPEDIENTE </span>
                        </button>
                        <button mat-menu-item [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/ac-vacaciones/consultar']" [queryParams]="{id_exp:element.id_info_empleado_pk}">
                            <span> VER VACACIONES </span>
                        </button> <!--*ngIf="vaca_modificar" -->
                        <button mat-menu-item [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/ac-vacaciones/modificar']" [queryParams]="{id_exp:element.id_info_empleado_pk}">
                            <span> EDITAR VACACIONES </span>
                        </button>
                        <button mat-menu-item [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-capacitaciones/lista']" [queryParams]="{id_exp:element.id_info_empleado_pk}">
                            <span> CAPACITACIÓN </span>
                        </button>
                        <button mat-menu-item [routerLink]="['/rrhh/gestor-de-expedientes-de-personal/gt-incidencias']" [queryParams]="{id_empleado:element.id_info_empleado_pk}">
                            <span> INCIDENCIAS </span>
                        </button>
                    </mat-menu>
                    
                </td>
            </ng-container>

            

            
            

            

            <!-- fin contenido -->
            <tr mat-header-row *matHeaderRowDef="ghedp_displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: ghedp_displayedColumns;"></tr>
        </table>
    </div>
    <!-- fin tabla -->


</mat-card-content>
<mat-card-actions align="start">
    <mat-paginator (page)="pageEvent($event)" [length]="ghedp_length" [pageSize]="ghedp_pageSize" [pageSizeOptions]="ghedp_pageSizeOptions"  aria-label="Selecione una pagina">
    </mat-paginator>
</mat-card-actions>
</mat-card>
</div>