<app-datos-generales></app-datos-generales>

<div class="card-container" id="top">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Gestor de terapias / campañas</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div class="bt col-md-12 text-align-right">
                <a  color="accent" class="flat" mat-flat-button  href="{{laravelapi}}/ReporteTerapiasExcel?numero_beneficiaria={{id}}" target="_blank" rel=noopener>
                    <mat-icon>list_alt</mat-icon>&nbsp;Registro en Excel
                </a>


                <!--<button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos-generales/gt-terapias-companas/registrar']">
                    Nuevo evento
                </button>-->
                
                <!--<button class="flat" color="primary" mat-flat-button>Comparativo</button>
                    <button class="flat" color="primary" mat-flat-button>Gráfica (Pizza)</button>-->
                </div>

                <!-- tabla -->
                <div class="row mt-3 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="_id_" >
                            <th id="_id_" mat-header-cell *matHeaderCellDef style="width: 120px !important"> ID </th>
                            <td mat-cell *matCellDef="let element">{{element?.id_bene_fk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="_f_">
                            <th id="_f_" mat-header-cell *matHeaderCellDef  style="width: 120px !important"> FECHA DEL EVENTO </th>
                            <td mat-cell *matCellDef="let element">
                                
                                    {{element?.terapia_campana?.fecha_ingreso}}
                                
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="_eve_">
                            <th id="_eve_" mat-header-cell *matHeaderCellDef> NOMBRE DEL EVENTO </th>
                            <td mat-cell *matCellDef="let element">{{element?.terapia_campana?.nombre_evento}}

                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_c_">
                            <th id="_c_" mat-header-cell *matHeaderCellDef> CLASIFICACIÓN </th>
                            <td mat-cell *matCellDef="let element">{{element?.terapia_campana?.clasificacion?.clasificacion}}
                                
                            </td>
                        </ng-container>



                      

                        <ng-container matColumnDef="_consultar">
                            <th id="_consultar" mat-header-cell *matHeaderCellDef> <span class="d-flex justify-content-center">VER EVENTO MÉDICO </span> </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="d-flex justify-content-center">
                                    <button  type="button" mat-mini-fab color="primary" >
                                        <mat-icon class="material-icons" [routerLink]="['/medico/expedientes-medicos-generales/gt-terapias-companas/consultar']" [queryParams]="{id: element.terapia_campana.id_terapia_pk,  ben: element.id_bene_fk}">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                                </span>
                                
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="_imprimir_">
                            <th id="_imprimir_" mat-header-cell *matHeaderCellDef> <span class="d-flex justify-content-center">IMPRIMIR </span> </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="d-flex justify-content-center">
                                    <button  type="button" mat-mini-fab color="warn" >
                                        <mat-icon class="material-icons">
                                            print
                                        </mat-icon>
                                    </button>
                                </span>
                                
                            </td>
                        </ng-container>

                        <!-- fin contenido -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- fin tabla -->

                
                <!--<div class="mt-5 d-flex justify-content-center">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/expedientes-medicos/gestor-expedientes-medicos']" >
                        Regresar
                    </button>
                </div>-->

                
            </mat-card-content>
            <mat-card-actions align="start">
                <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"  aria-label="Selecione una pagina">
                </mat-paginator>
            </mat-card-actions>
        </mat-card>
    </div>
