//General
import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { SesionService } from 'src/app/services/sesion.service';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { TerCampService } from 'src/app/services/ter-camp.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios

import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-ac-ter-camp',
  templateUrl: './ac-ter-camp.component.html',
  styleUrls: ['./ac-ter-camp.component.scss'],
  providers: [ TerCampService ]
})
export class AcTerCampComponent implements OnInit {

  
  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    numero_terapia: new UntypedFormControl(-1, []),

    fecha_ingreso: new UntypedFormControl('', [Validators.required]),
    clasificacion: new UntypedFormControl('', [Validators.required]),
    nombre_evento: new UntypedFormControl('', [Validators.required, Validators.maxLength(200)]),
    descripcion: new UntypedFormControl('', [Validators.required, Validators.maxLength(3000)]),

    estado_boton: new UntypedFormControl(1, [Validators.required]),

    terceros_involucrados: new UntypedFormControl('', []),
    num_ben: new UntypedFormControl(-1, []),
    beneficiarias: new UntypedFormControl([], []),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id: any;
  public laravelapiStorage = this._TerCampService.getLaravelApiStorage();
  public laravelapi = this._TerCampService.getLaravelApi();
  public t: any;
  public data: any;
  public acciones:any=[];
  public f_c: any;
  public clasificaciones: any;
  public beneficiarias: any;
  public selectedIndex: number = 0;

  public doc = [{id:0, ruta: '', id2: -1, file: null, name: ''},{id:1, ruta: '', id2: -1, file: null, name: ''},{id:2, ruta: '', id2: -1, file: null, name: ''},{id:3, ruta: '', id2: -1, file: null, name: ''},{id:4, ruta: '', id2: -1, file: null, name: ''}];
  public doc2 = [{id:0, ruta: '', id2: -1, file: null, name: ''},{id:1, ruta: '', id2: -1, file: null, name: ''},{id:2, ruta: '', id2: -1, file: null, name: ''},{id:3, ruta: '', id2: -1, file: null, name: ''},{id:4, ruta: '', id2: -1, file: null, name: ''}]

  public b: any = [];

  public ben: number = -1;
  public d1: number = 0;
  public d2: number = 0;

 

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _TerCampService: TerCampService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
    private _sesion:SesionService,
  ) { 
    /*modificar*/
    this.ConsultaFormularioTerapiasDatos();
    this.form.markAllAsTouched();
    this._route.queryParams.subscribe(
      params => {
        this.id = +params['id'];
        this.ben = +params['ben'];
        if(this.id){
          console.log('IDDDD: ' + this.id);
          this.form.patchValue({ numero_terapia: this.id});

          
          this.getConsultarTerapia(this.id);
          
          //this.getDatosClinicosForm();

          
          

        }
      });
    /*fin modificar*/



    /*this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.allFruits.slice())),
    );*/

  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[4].split("?")[0];
    console.log(this.accion);
  }

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }

  public ConsultaFormularioTerapiasDatos(){
    console.log('asjsajsdhas');
    this.isLoading = true;
    this._TerCampService.getConsultaFormularioTerapiasDatos().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.clasificaciones = response.data.clasificaciones;
          this.beneficiarias = response.data.beneficiarias
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getConsultarTerapia(id: number){
    this.isLoading = true;
    this._TerCampService.getConsultarTerapia(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.data = response.data
          this.data.documentos_terapia.sort((a, b) => parseFloat(a.id_tipo_pk) - parseFloat(b.id_tipo_pk));
          //for(let u = 0; u < this.data.documentos_terapia.length; u++){
            this.doc = [{id:0, ruta: '', id2: -1, file: null, name: ''},{id:1, ruta: '', id2: -1, file: null, name: ''},{id:2, ruta: '', id2: -1, file: null, name: ''},{id:3, ruta: '', id2: -1, file: null, name: ''},{id:4, ruta: '', id2: -1, file: null, name: ''}];
            this.doc2 = [{id:0, ruta: '', id2: -1, file: null, name: ''},{id:1, ruta: '', id2: -1, file: null, name: ''},{id:2, ruta: '', id2: -1, file: null, name: ''},{id:3, ruta: '', id2: -1, file: null, name: ''},{id:4, ruta: '', id2: -1, file: null, name: ''}];
            this.d1 = 0;
            this.d2 = 0;
            for(let m = 0; m < this.doc.length; m++){
              //if(this.doc[m].id == this.data.documentos_terapia[u].id_tipo_pk){
                if(this.data?.documentos_terapia[m]?.id_documento_pk && this.data?.documentos_terapia[m]?.id_tipo_pk == 1){
                  this.doc[m].ruta = this.data?.documentos_terapia[m]?.ruta_relativa;
                  this.doc[m].id2 = this.data?.documentos_terapia[m]?.id_documento_pk;
                  this.d1 = this.d1 + 1;
                }
              //}
            }
            this.data.documentos_terapia.sort((a, b) => parseFloat(b.id_tipo_pk) - parseFloat(a.id_tipo_pk));
            for(let m = 0; m < this.doc2.length; m++){
              //if(this.doc[m].id == this.data.documentos_terapia[u].id_tipo_pk){
                if(this.data?.documentos_terapia[m]?.id_documento_pk && this.data?.documentos_terapia[m]?.id_tipo_pk == 2){
                  this.doc2[m].ruta = this.data?.documentos_terapia[m]?.ruta_relativa;
                  this.doc2[m].id2 = this.data?.documentos_terapia[m]?.id_documento_pk;
                  this.d2 = this.d2 + 1;
                }
              //}
            }
            this.data.documentos_terapia.sort((a, b) => parseFloat(a.id_tipo_pk) - parseFloat(b.id_tipo_pk));
          //}
          console.log(this.doc);
          console.log(this.doc2);
          this.setForm(this.data);
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public setForm(data: any){
    this.form.patchValue({
      numero_terapia: data?.id_terapia_pk,
      fecha_ingreso: data?.fecha_ingreso,
      clasificacion: data?.id_clasificacion_fk,
      nombre_evento: data?.nombre_evento,
      descripcion: data?.descrpcion,

      estado_boton: data?.id_estado_boton_fk,

      terceros_involucrados: data?.terceros_involucrados,
    });

    for(let i = 0; i < this.data.beneficiarios.length; i++){
      this.data.beneficiarios[i].numero_beneficiaria = this.data.beneficiarios[i].id
    }

    this.form.patchValue({
      beneficiarias: this.data.beneficiarios
    });
    this.setFruits();
    console.log(this.form.value);
  }

  public addBen(id: number = 0){
    var h = true;
    var name = '';
    for(let g = 0; g < this.form.value.beneficiarias.length; g++){
      if(id == this.form.value.beneficiarias[g].numero_beneficiaria){
        h = false;
      }
      
    }
    for(let h = 0; h < this.beneficiarias.length; h++){
      if(id == this.beneficiarias[h].id){
        name = this.beneficiarias[h].nombre;
      }
    }
    if(h){
      this.form.value.beneficiarias.push({numero_beneficiaria: id, nombre: name});
    }else{
      this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Repetida beneficiaria', icon:'error'}});
      return false;
    }
    console.log(this.form.value);
  }

  public delBen(id: number = 0){
    this.form.value.beneficiarias.splice(id, 1);
  }



  public postRegistroDeTerapia(){
    console.log(this.form.value);
    this.isLoading = true;
    this._TerCampService.postRegistroDeTerapia(this.form.value).subscribe(
      async response => {console.log(response);
       
        if(response.estado == 200){
          console.log(this.doc);
          if(this.doc[0].file){await this.postRegDoc1(0, response.data.id_terapia_pk,1);}
          if(this.doc[1].file){await this.postRegDoc1(1, response.data.id_terapia_pk,1);}
          if(this.doc[2].file){await this.postRegDoc1(2, response.data.id_terapia_pk,1);}
          if(this.doc[3].file){await this.postRegDoc1(3, response.data.id_terapia_pk,1);}
          if(this.doc[4].file){await this.postRegDoc1(4, response.data.id_terapia_pk,1);}
          if(this.doc2[0].file){await this.postRegDoc1(0, response.data.id_terapia_pk,2);}
          if(this.doc2[1].file){await this.postRegDoc1(1, response.data.id_terapia_pk,2);}
          if(this.doc2[2].file){await this.postRegDoc1(2, response.data.id_terapia_pk,2);}
          if(this.doc2[3].file){await this.postRegDoc1(3, response.data.id_terapia_pk,2);}
          if(this.doc2[4].file){await this.postRegDoc1(4, response.data.id_terapia_pk,2);}
          this.isLoading = true;
          setTimeout(() => {
            this.isLoading = false;
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            this._router.navigate(['medico/expedientes-medicos-generales/gt-terapias-companas/modificar'], { queryParams: { id: response.data.id_terapia_pk }});
          }, 3000);
          
        }

      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  


  public postModificarTerapia(add: number = 0, id: number = 0){
    console.log(this.form.value.beneficiarias);
    if(add == 1){
      var h = true;
      for(let g = 0; g < this.form.value.beneficiarias.length; g++){
        if(id == this.form.value.beneficiarias[g].numero_beneficiaria){
          h = false;
        }
        
      }
      if(h){
        this.form.value.beneficiarias.push({numero_beneficiaria: id});
      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Repetida beneficiaria', icon:'error'}});
        return false;
      }
      
    }else if(add == 2){
      this.form.value.beneficiarias.splice(id, 1);
    }
    
    this.form.patchValue({
      estado_boton: 1
    });
    console.log(this.form.value.beneficiarias);
    
    console.log(this.form.value);
    this.isLoading = true;
    this._TerCampService.postModificarTerapia(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.getConsultarTerapia(this.id);
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          //this._router.navigate(['medico/expedientes-medicos-generales/gt-terapias-companas/modificar'], { queryParams: { id: 'popular' }});
        }

      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public cerrar(){
    this.form.patchValue({
      estado_boton: 2
    });
    this.postModificarTerapia();
  }


  public postRegDoc1(i: number, id: number, caso: number): any{

        if(i == -1){
          i = 0;
        }
        console.log(this.id);
        const formData = new FormData();
        if(caso == 1){
          this.data?.documentos_terapia?.sort((a, b) => parseFloat(a.id_tipo_pk) - parseFloat(b.id_tipo_pk));
          formData.set('documento', this.doc[i].file);
        }else if(caso == 2){
          this.data?.documentos_terapia?.sort((a, b) => parseFloat(b.id_tipo_pk) - parseFloat(a.id_tipo_pk));
          formData.set('documento', this.doc2[i].file);
        }
        formData.set('numero_terapia',  id.toString());
        formData.set('tipo_documento',  caso.toString());
        this.isLoading = true;
        this._TerCampService.postRegistrarDocumentoTerapia(formData).subscribe(
          response => {console.log(response);
            if(this.id){
              this.isLoading = false;
              this.getConsultarTerapia(this.id);
            }
            //this.getConsultarTerapia(this.id);
            //this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            
          },
          error => {console.log(<any>error);
            if(this.id){
              this.isLoading = false;
              this.getConsultarTerapia(this.id);
            }
            //this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
            //this.isLoading = false;
        });
 
  }



  public postRegistrarDocumentoTerapia(event: any, i: number): any{

    
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      console.log(file.type);
      var ext = event.target.files[0].name.split('.')[1];
      console.log(ext);
      if (file.type.startsWith('image/png') || file.type.startsWith('image/jpeg') || file.type.startsWith('image/jpg') || file.type.startsWith('application/pdf')) {//text/xml
        console.log(this.id);
        const formData = new FormData();
        formData.set('documento', file);
        formData.set('numero_terapia',  this.id.toString());
        formData.set('tipo_documento',  '1');
        this.isLoading = true;
        this._TerCampService.postRegistrarDocumentoTerapia(formData).subscribe(
          response => {console.log(response);
            this.getConsultarTerapia(this.id);
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            
          },
          error => {console.log(<any>error);
            this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
            this.isLoading = false;
        });


      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato', icon:'error'}});
      }
    }  
  }

  public addFile(event: any, i: number, caso: number): any{

    if(i == -1){
      i = 0;
    }
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      console.log(file.type);
      var ext = event.target.files[0].name.split('.')[1];
      console.log(ext);
      console.log(file);
      if (file.type.startsWith('image/png') || file.type.startsWith('image/jpeg') || file.type.startsWith('image/jpg') || file.type.startsWith('application/pdf')) {//text/xmlapplication/pdf
        if(caso == 1){
          this.data?.documentos_terapia?.sort((a, b) => parseFloat(a.id_tipo_pk) - parseFloat(b.id_tipo_pk));
          console.log(i);
          this.doc[i].file =  file;
          this.doc[i].name =  file.name;
          /*if(this.id != -1){
            this.postRegDoc1(i, this.id);
          }*/
        }else if(caso == 2){
          this.data?.documentos_terapia?.sort((a, b) => parseFloat(b.id_tipo_pk) - parseFloat(a.id_tipo_pk));
          console.log(i);
          this.doc2[i].file =  file;
          this.doc2[i].name =  file.name;
        }
        
      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato', icon:'error'}});
      }
    }  
    console.log(this.doc);
    console.log(this.doc2);
  }

  public postModificarDocumentoTerapia(event: any, id: number): any{

    
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      console.log(file.type);
      var ext = event.target.files[0].name.split('.')[1];
      console.log(ext);
      if (file.type.startsWith('image/png') || file.type.startsWith('image/jpeg') || file.type.startsWith('image/jpg')) {//text/xml
        console.log(this.id);
        const formData = new FormData();
        formData.set('documento', file);
        formData.set('numero_doc',  id.toString());
        this.isLoading = true;
        this._TerCampService.postModificarDocumentoTerapia(formData).subscribe(
          response => {console.log(response);
            this.getConsultarTerapia(this.id);
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            
          },
          error => {console.log(<any>error);
            this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
            this.isLoading = false;
        });


      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato', icon:'error'}});
      }
    }  
  }


  public postEliminarDocumentoTerapia(id: number, caso: number){
    console.log(id);
    console.log(this.form.value);
    this.isLoading = true;
    var id = 0;
    if(caso == 1){
      id = this.doc[id].id2;
    }else if(caso == 2){
      id = this.doc2[id].id2;
    }
    console.log(id);

    this._TerCampService.postEliminarDocumentoTerapia({numero_doc: id.toString()}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        this.getConsultarTerapia(this.id);
        //if(response.estado == 200){
          
        //this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
        //}

      },
      error => {console.log(<any>error);
        this.getConsultarTerapia(this.id);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public general(response: any){

  }











  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  //{name: 'Lemon'}, {name: 'Lime'}, {name: 'Apple'}
  fruits: Fruit[] = [];

  public setFruits(){
    this.fruits = [];
    var a = this.form.value.terceros_involucrados?.split(',');
    console.log(a);
    for(let i = 0; i < a?.length; i++){
      if(a[i] != ''){
        this.fruits.push({name: a[i]});
      }
      
    }
  }

  public getFruits(){
    var v = '';
    for(let i = 0; i < this.fruits.length; i++){
      if(this.fruits[i].name != ''){
        v = v + this.fruits[i].name + ',';
      }
      
    }
    console.log(v);
    this.form.patchValue({
      terceros_involucrados: v
    })
   


  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.fruits.push({name: value});
      this.getFruits();
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(fruit: Fruit): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
      this.getFruits();
    }
  }

  edit(fruit: Fruit, event: MatChipInputEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.remove(fruit);
      return;
    }

    // Edit existing fruit
    const index = this.fruits.indexOf(fruit);
    if (index > 0) {
      this.fruits[index].name = value;
    }
  }


  public backStep() {
    this.selectedIndex -= 1;
    let topBack = document.getElementById('top');
    if (topBack !== null) {
      topBack.scrollIntoView();
      topBack = null;
    }
  }

  public nextStep() {
    this.selectedIndex += 1;
    let topNext = document.getElementById('top');
    if (topNext !== null) {
      topNext.scrollIntoView();
      topNext = null;
    }
  }

}
