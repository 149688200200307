//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { IncidenciasService } from 'src/app/services/incidencias.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { CapacitacionService } from 'src/app/services/capacitacion.service';
//Fin Tabla


@Component({
  selector: 'app-rrhh-capacitacion-individual',
  templateUrl: './rrhh-capacitacion-individual.component.html',
  styleUrls: ['./rrhh-capacitacion-individual.component.scss']
})
export class RrhhCapacitacionIndividualComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: FormGroup = this.fb.group({
    tipo_incidente: new UntypedFormControl(1, []),
  });
  /*tabla*/
  public length: number;
  public pageSize: number;
  public pageSizeOptions: Array<number>;
  public page: number;
  public displayedColumns: any;
  public dataSource: any;
  /*fin tabla*/
  public isLoading: boolean = false;
  public id_empleado: any;
  public laravelapiStorage = this._CapacitacionService.getLaravelApiStorage();
  public laravelapi = this._CapacitacionService.getLaravelApi();
  public catalogo: any;
 
   constructor(
     private fb: UntypedFormBuilder,
     public  _dialog: MatDialog,
     private _CapacitacionService: CapacitacionService,
     private _router: Router,
     private _route: ActivatedRoute,
   ){ 
      /*tabla*/
     this.length = 100;
     this.pageSize = 25;
     this.pageSizeOptions = [5, 10, 25, 100];
     this.page = 1;
     this.displayedColumns = [
     '_id', 
     '_nombre_capa',
     '_fecha',
     '_consultar',];
     /*fin tabla*/
   }
 
   ngOnInit(): void {
    /*modificar*/
    //this.form.markAllAsTouched();
    this._route.queryParams.subscribe(
      params => {
        this.id_empleado = +params['id_exp'];
        console.log(this.id_empleado);
        if(this.id_empleado){
          this.getExpedienteConsulta(this.id_empleado);
          //this.getConsultarFormularioIncidentes();
          //this.getConsultarIncidentesPaginador(1);
        }
      });
    /*fin modificar*/
    
   }


   public getExpedienteConsulta(id: number){
    this.isLoading = true;
    this._CapacitacionService.getExpedienteConsulta(id).subscribe( //por cambiar
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.dataSource = response.data.capacitacion;
          //this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Correct', mensaje: 'Eliminado Correctamente.', icon:'correct'}});
          //this.getExpedienteConsultas(this.ghedp_page);
        }else{
          //this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el servicio', icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

}
