import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class PtService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorage;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorage = _config.getConfiguration().laravelapiStorage;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorage);
  }
  public getLaravelApiStorage(){
    return this.laravelapiStorage;
  }

  public getLaravelApi(){
    return this.laravelapi;
  }

  /*area o dep*/

  public getConsultarEventosPeriodos(p: number, r: number):any{
    return this._http.get<ResponseGenerico>(this.laravelapi+"/consultarEventosPeriodos?filas="+r+'&page='+p);
  }

  public postConsultarTallasPeriodo(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/consultarTallasPeriodo", datos, {});
  }
  
  public postConsultarTallasBeneficiaria(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/consultarTallasBeneficiaria", datos, {});
  }

  public postModificarTallaBeneficiariaBeneficiaria(datos: any):any{
    if(datos.ene_peso == "NaN" || !datos.ene_peso){
      datos.ene_peso = 0;
    }
    if(datos.ene_talla == "NaN" || !datos.ene_talla){
      datos.ene_talla = 0;
    }
    if(datos.ene_imc == "NaN" || !datos.ene_imc){
      datos.ene_imc = 0;
    }
    if(datos.febrero_peso == "NaN" || !datos.febrero_peso){
      datos.febrero_peso = 0;
    }
    if(datos.febrero_talla == "NaN" || !datos.febrero_talla){
      datos.febrero_talla = 0;
    }
    if(datos.febrero_imc == "NaN" || !datos.febrero_imc){
      datos.febrero_imc = 0;
    }
    if(datos.marzo_peso == "NaN" || !datos.marzo_peso){
      datos.marzo_peso = 0;
    }
    if(datos.marzo_talla == "NaN" || !datos.marzo_talla){
      datos.marzo_talla = 0;
    }
    if(datos.marzo_imc == "NaN" || !datos.marzo_imc){
      datos.marzo_imc = 0;
    }
    if(datos.abril_peso == "NaN" || !datos.abril_peso){
      datos.abril_peso = 0;
    }
    if(datos.abril_talla == "NaN" || !datos.abril_talla){
      datos.abril_talla = 0;
    }
    if(datos.abril_imc == "NaN" || !datos.abril_imc){
      datos.abril_imc = 0;
    }
    if(datos.mayo_peso == "NaN" || !datos.mayo_peso){
      datos.mayo_peso = 0;
    }
    if(datos.mayo_talla == "NaN" || !datos.mayo_talla){
      datos.mayo_talla = 0;
    }
    if(datos.mayo_imc == "NaN" || !datos.mayo_imc){
      datos.mayo_imc = 0;
    }
    if(datos.junio_peso == "NaN" || !datos.junio_peso){
      datos.junio_peso = 0;
    }
    if(datos.junio_talla == "NaN" || !datos.junio_talla){
      datos.junio_talla = 0;
    }
    if(datos.junio_imc == "NaN" || !datos.junio_imc){
      datos.junio_imc = 0;
    }
    if(datos.julio_peso == "NaN" || !datos.julio_peso){
      datos.julio_peso = 0;
    }
    if(datos.julio_talla == "NaN" || !datos.julio_talla){
      datos.julio_talla = 0;
    }
    if(datos.julio_imc == "NaN" || !datos.julio_imc){
      datos.julio_imc = 0;
    }
    if(datos.agosto_peso == "NaN" || !datos.agosto_peso){
      datos.agosto_peso = 0;
    }
    if(datos.agosto_talla == "NaN" || !datos.agosto_talla){
      datos.agosto_talla = 0;
    }
    if(datos.agosto_imc == "NaN" || !datos.agosto_imc){
      datos.agosto_imc = 0;
    }
    if(datos.septiembre_talla == "NaN" || !datos.septiembre_talla){
      datos.septiembre_talla = 0;
    }
    if(datos.septiembre_peso == "NaN" || !datos.septiembre_peso){
      datos.septiembre_peso = 0;
    }
    if(datos.septiembre_imc == "NaN" || !datos.septiembre_imc){
      datos.septiembre_imc = 0;
    }
    if(datos.octubre_peso == "NaN" || !datos.octubre_peso){
      datos.octubre_peso = 0;
    }
    if(datos.octubre_talla == "NaN" || !datos.octubre_talla){
      datos.octubre_talla = 0;
    }
    if(datos.octubre_imc == "NaN" || !datos.octubre_imc){
      datos.octubre_imc = 0;
    }
    if(datos.nov_peso == "NaN" || !datos.nov_peso){
      datos.nov_peso = 0;
    }
    if(datos.nov_talla == "NaN" || !datos.nov_talla){
      datos.nov_talla = 0;
    }
    if(datos.nov_imc == "NaN" || !datos.nov_imc){
      datos.nov_imc = 0;
    }
    if(datos.dic_peso == "NaN" || !datos.dic_peso){
      datos.dic_peso = 0;
    }
    if(datos.dic_talla == "NaN" || !datos.dic_talla){
      datos.dic_talla = 0;
    }
    if(datos.dic_imc == "NaN" || !datos.dic_imc){
      datos.dic_imc = 0;
    }




    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarTallaBeneficiaria", datos, {});
  }

  public postModificarPeriodo(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/modificarPeriodo", datos, {});
  }

  public postCerrarPeriodo(datos: any):any{
    return this._http.post<ResponseGenerico>(this.laravelapi+"/cerrarPeriodo", datos, {});
  }

  
  /*fin area o dep*/

  




  
}
