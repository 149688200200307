<div class="card-container">
    <mat-card >
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <form novalidate [formGroup]="form" autocomplete="off">
                <!-- parte 1 -->
                <span style="text-transform:uppercase"><strong>{{accion}} SALIDA DE ALMACÉN</strong></span>
                
                <div class="row" *ngIf="!id_en; else elseSa">
                    <div class="row">
                        <div class="col">
                            <mat-form-field appearance="fill" class="input-full-w">
                                <mat-label>CLASIFICACIÓN</mat-label>
                                <mat-select formControlName="numero_clasificacion" (ngModelChange)="getConsultaMarcaMedicamento_sa()">
                                    <mat-option *ngFor="let sa of clasificacion" [value]="sa.id_medicamentos_clasificacion_pk">{{sa.clasificacion}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="form.controls['numero_clasificacion'].hasError('required')">
                                    El campo es obligatorio
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col">
    
                        </div>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>MARCA</mat-label>
                            <mat-select formControlName="marca" (ngModelChange)="getConsultaNombresMedicamento_sa()">
                                <mat-option *ngFor="let sa of marcas" [value]="sa.marca">{{sa.marca}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.controls['numero_clasificacion'].hasError('maxlength')">
                                Máximo de caracteres es 100
                            </mat-error>
                            <mat-error *ngIf="form.controls['numero_clasificacion'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>NOMBRE DE MEDICAMENTO</mat-label>
                            <mat-select formControlName="numero_medicamento" (ngModelChange)="getConsultaPresentacionMedicamento_sa()">
                                <mat-option *ngFor="let sa of medicamentos" [value]="sa.id_medicamentos_pk">{{sa.nombre_medicamento}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.controls['numero_medicamento'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>PRESENTACIÓN</mat-label>
                            <mat-select formControlName="presentacion" >
                                <mat-option *ngFor="let sa of presentacion" [value]="sa.presentacion">{{sa.presentacion}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.controls['presentacion'].hasError('maxlength')">
                                Máximo de caracteres es 100
                            </mat-error>
                            <mat-error *ngIf="form.controls['presentacion'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                           
                        </mat-form-field>
                    </div>
                </div>
                <!-- else -->
                <ng-template #elseSa>
                    <div class="row">
                        <div class="col">
                            <mat-form-field appearance="fill" class="input-full-w">
                                <mat-label>USUARIO</mat-label>
                                <input type="text" matInput maxlength="100" #nombre_licencia_sa formControlName="nombre_licencia" readonly="true">
                                <mat-hint align="end">{{nombre_licencia_sa.value.length}} / 100</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col">
    
                        </div>
                    </div>


                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>CLASIFICACIÓN</mat-label>
                            <mat-select formControlName="numero_clasificacion">
                                <mat-option *ngFor="let sa of clasificacion" [value]="sa.id_medicamentos_clasificacion_pk">{{sa.clasificacion}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.controls['numero_clasificacion'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">

                    </div>
                </div>
                    
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>MARCA</mat-label>
                            <input type="text" matInput maxlength="100" #marca_sa formControlName="marca" readonly="true">
                            <mat-hint align="end">{{marca_sa.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>NOMBRE DE MEDICAMENTO</mat-label>
                            <input type="text" matInput maxlength="100" #nombre_medicamento_sa formControlName="nombre_medicamento" readonly="true">
                            <mat-hint align="end">{{nombre_medicamento_sa.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>PRESENTACIÓN</mat-label>
                            <input type="text" matInput maxlength="100" #presentacion_sa formControlName="presentacion" readonly="true">
                            <mat-hint align="end">{{presentacion_sa.value.length}} / 100</mat-hint>
                        </mat-form-field>    
                    </div>
                </div>
                </ng-template>
                <!-- fin else -->


                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>FORMULA</mat-label>
                            <input type="text" matInput maxlength="100" #formula_sa formControlName="formula">
                            <mat-error *ngIf="form.controls['formula'].hasError('maxlength')">
                                El máximo tamaño es de 100 caracteres
                            </mat-error>
                            <mat-error *ngIf="form.controls['formula'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            
                            <mat-hint align="end">{{formula_sa.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>GRAMAJE</mat-label>
                            <input type="text" matInput maxlength="100" #gramaje_sa formControlName="gramaje">
                            <mat-error *ngIf="form.controls['gramaje'].hasError('maxlength')">
                                El máximo tamaño es de 100 caracteres
                            </mat-error>
                            <mat-error *ngIf="form.controls['gramaje'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                           
                            <mat-hint align="end">{{gramaje_sa.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>INVENTARIO CANTIDAD</mat-label>
                            <input type="text" matInput maxlength="100" #inventario_cantidad_sa formControlName="inventario_cantidad">
                            <mat-error *ngIf="form.controls['inventario_cantidad'].hasError('maxlength')">
                                El máximo tamaño es de 100 caracteres
                            </mat-error>
                            <mat-hint align="end">{{inventario_cantidad_sa.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                </div>     
                <div class="row">
                    <mat-form-field appearance="fill"  class="input-full-w">
                        <mat-label>OBSERVACIONES</mat-label>
                        <input type="text" matInput maxlength="100" #observacion_sa formControlName="observacion">
                        <mat-error *ngIf="form.controls['observacion'].hasError('maxlength')">
                            El máximo tamaño es de 100 caracteres
                        </mat-error>
                        <mat-hint align="end">{{observacion_sa.value.length}} / 100</mat-hint>
                    </mat-form-field>
                </div>          
            </form>

            <div class="mt-5 d-flex justify-content-center">
				<div *ngIf="form.invalid">
					<span class="text-danger">
						Faltan campos por llenar
					</span>
				</div>
			</div>

            <div class="mt-2 d-flex justify-content-center gap-2">


                

                <button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/almacen-de-medicamentos/gestor-salida-inventario']" >
                    Regresar
                </button>

                <form novalidate [formGroup]="form" (ngSubmit)="guardar()" *ngIf="accion == 'registrar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>
                <!--<form novalidate [formGroup]="form" (ngSubmit)="modificar()" *ngIf="accion == 'modificar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                        Guardar
                    </button>
                </form>-->


            </div>

            
        </mat-card-content>
    </mat-card>
</div>