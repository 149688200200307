import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DesitionBoxComponent } from 'src/app/modals/desition-box/desition-box.component';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { Paginador } from 'src/app/model/configuracion';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ContactosService } from 'src/app/services/contactos.service';
import { SesionService } from 'src/app/services/sesion.service';

const ELEMENT_DATA: any[] = [];

@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.scss']
})
export class ContactosComponent implements OnInit {
  acciones:any=[];
  tieneAccionCrear = false;
  tieneAccionEditar = false;
  tieneAccionEliminar = false;
  paginador:Paginador;
  pagesize = 0;
  isLoading=false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  displayedColumns: string[] = ['position','tipo', 'nombre','organizacion', 'cargo', 'mail','telefono','extencion','celular','ver','editar','eliminar'];
  formulario:UntypedFormGroup=new UntypedFormGroup({
    buscador:new UntypedFormControl("",[]),
    subcategoria:new UntypedFormControl("",[])
  })
  constructor(private _dialog:MatDialog,private _config:ConfigurationService,private contactos_service:ContactosService, private _sesion:SesionService) { 
    this.paginador = this._config.getConfiguration().ui.paginador;
    this.pagesize=this.paginador.pageSize;

    this.tieneAccionEliminar = this.consultarAccionUsuario("/centro-informacion-institucional/directorio/delete");
    console.log("this.tieneAccionEliminar: ", this.tieneAccionEliminar);
    this.tieneAccionEditar = this.consultarAccionUsuario("/centro-informacion-institucional/directorio/edit");
    console.log("this.tieneAccionEditar: ", this.tieneAccionEditar);
    this.tieneAccionCrear = this.consultarAccionUsuario("/centro-informacion-institucional/directorio/new");
    console.log("this.tieneAccionCrear: ", this.tieneAccionCrear);
    
  }
  

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.buscar(0,this.paginador.pageSize);
  }
  count=0;
  buscar(index:number,size:number){
    
    this.isLoading=true;
    this.contactos_service.buscaContactos(this.formulario.controls["buscador"].value,index,size,null).subscribe(result=>{
      this.count = result["size"];
      this.isLoading=false;
      this.dataSource = new MatTableDataSource<any>(result.data.documentos);
    },()=>{
      this.isLoading=false;
    });
  }
  consultarAccionUsuario(urlRecibida){
    if(this._sesion.getSession().getUsuario().nombre!=undefined){
      this.acciones = this._sesion.getSession().getAcciones();
      if(this.acciones.filter(x => x.url == urlRecibida)[0]){
        return true;        
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  sendForm(){
    // This is intentional
  }
  pager(event){
    this.pagesize=event.pageSize;
    this.paginator.pageSize = event.pageSize;
    this.paginator.pageIndex = event.pageIndex;
    this.buscar(event.pageIndex*event.pageSize ,event.pageSize);
  }

  eliminar(id){
    const dialogRef = this._dialog.open(DesitionBoxComponent,{
      data: {titulo: "¿Desea eliminar el contacto seleccionado?", mensaje: "La información del contacto será eliminada de manera permanente, ¿Desea continuar?", desicion:true}
    })

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if(result){
        this.isLoading=true;
        this.contactos_service.deleteByid(id).subscribe(resultado=>{
          this.isLoading=false;
          console.info(resultado);
          this.paginator.pageIndex = 0;
          this.buscar(this.paginator.pageIndex * this.paginator.pageSize,this.paginator.pageSize);
          this._dialog.open(MessageBoxComponent,{
            data: {titulo:"Contacto eliminado", mensaje:"El contacto ha sido eliminado del sistema"}
          })
        })
      }
    });
  }
  consultaTipo(id){
    switch(id){
      case "1":
        return "Autoridad";
      case "2":
        return "Patronato";
      case "3":
        return "Estratégico";
      default:
        return "-"
    }
  }

}
