import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {
  isLoading = false;
  formulario = new UntypedFormGroup({
    correo : new UntypedFormControl("",[Validators.email,Validators.required])
  });
  constructor(
    private usuariosService:UsuariosService,
    public dialogRef: MatDialogRef<ForgottenPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(){
    // This is intentional
  }
  enviar(){
    if(this.formulario.valid){
      this.isLoading = true;
      this.usuariosService.reset(this.formulario.controls.correo.value).subscribe(result=>{
        this.isLoading = false;
        console.info(result);
        this.onNoClick();
      });
    }
  }

}
