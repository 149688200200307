//General
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { ClinicoService } from 'src/app/services/clinico.service';


@Component({
  selector: 'app-historial-clinico2',
  templateUrl: './historial-clinico2.component.html',
  styleUrls: ['./historial-clinico2.component.scss'],
  providers: [ ClinicoService ]
})
export class HistorialClinico2Component implements OnInit {
  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    numero_beneficiario: new UntypedFormControl(-1, []),
    tegumentos: new UntypedFormControl('', [Validators.required]),
    ojos: new UntypedFormControl('', [Validators.required]),
    odios: new UntypedFormControl('', [Validators.required]),
    nari: new UntypedFormControl('', [Validators.required]),
    boca: new UntypedFormControl('', [Validators.required]),
    sistema_nervioso: new UntypedFormControl('', [Validators.required]),
    respiratorio: new UntypedFormControl('', [Validators.required]),
    cardio: new UntypedFormControl('', [Validators.required]),
    digestivo: new UntypedFormControl('', [Validators.required]),
    hematologo: new UntypedFormControl('', [Validators.required]),
    genitouriano: new UntypedFormControl('', [Validators.required]),
    musculo: new UntypedFormControl('', [Validators.required]),
    endcrino: new UntypedFormControl('', [Validators.required]),
    gineco: new UntypedFormControl('', [Validators.required]),
    psiqui: new UntypedFormControl('', [Validators.required]),


    en_caso_espe: new UntypedFormControl('', [Validators.required, Validators.maxLength(2500)]),


    antropometria_peso: new UntypedFormControl('', [ Validators.pattern('^\\d{1,3}(\\.\\d{2})?$')]),
    talla_m: new UntypedFormControl('', [ Validators.pattern('^\\d{1,3}(\\.\\d{2})?$')]),
    imc: new UntypedFormControl('', []),
    signos_presion: new UntypedFormControl('', [ Validators.pattern('^\\d{3}/\\d{3}$')]),
    o_sat: new UntypedFormControl('', [ Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]),
    frecuencia: new UntypedFormControl('', [ Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]),
    frecuencia_rpm: new UntypedFormControl('', [ Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]),
    temperatura: new UntypedFormControl('', [ Validators.pattern('^\\d{1,2}(\\.\\d{1})?$')]),

    aspec_general: new UntypedFormControl('', [Validators.required]),
    aspec_general_esp: new UntypedFormControl('', []),
    piel_mucosas: new UntypedFormControl('', [Validators.required]),
    piel_mucosas_esp: new UntypedFormControl('', []),
    cabeza_exp: new UntypedFormControl('', [Validators.required]),
    cabeza_exp_esp: new UntypedFormControl('', []),
    oidos_exp: new UntypedFormControl('', [Validators.required]),
    oidos_exp_esp: new UntypedFormControl('', []),
    ojos_exp: new UntypedFormControl('', [Validators.required]),
    ojos_exp_esp: new UntypedFormControl('', []),
    nariz_esp: new UntypedFormControl('', [Validators.required]),
    nariz_exp_esp: new UntypedFormControl('', []),
    boca_exp: new UntypedFormControl('', [Validators.required]),
    boca_exp_esp: new UntypedFormControl('', []),
    garganta_exp: new UntypedFormControl('', [Validators.required]),
    garganta_exp_esp: new UntypedFormControl('', []),
    cuello_exp: new UntypedFormControl('', [Validators.required]),
    cuello_exp_esp: new UntypedFormControl('', []),
    torax_exp: new UntypedFormControl('', [Validators.required]),
    torax_exp_esp: new UntypedFormControl('', []),
    abdomen_exp: new UntypedFormControl('', [Validators.required]),
    abdomen_exp_esp: new UntypedFormControl('', []),
    extremidades_exp: new UntypedFormControl('', [Validators.required]),
    extremidades_exp_esp: new UntypedFormControl('', []),
    neurologico_exp: new UntypedFormControl('', [Validators.required]),
    neurologico_ext_esp: new UntypedFormControl('', []),

    tipo_aten_medico: new UntypedFormControl('', [Validators.required]),
    diagnostico_permanente: new UntypedFormControl('', [Validators.required]),

    obervaciones_text: new UntypedFormControl('', [Validators.required,  Validators.maxLength(2500)]),

    //aux
    numero_especialista: new UntypedFormControl(-1, []),






  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  @Input() selectedIndex : number = 0;
  @Output() siguienteEvento = new EventEmitter<string>();
  public departamento: any;
  public puesto: any;
  public id: any;
  public descripcion_puesto: any;
  public especialista_catalogo: any;
  public especialistas: any;

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _ClinicoService: ClinicoService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) { 
    let url_list = this._router.url.split("/");
    this.accion = url_list[4].split("?")[0];
    console.log(this.accion);
  }

  ngOnInit(): void {
    /*modificar*/
    this.form.markAllAsTouched();
    this._route.queryParams.subscribe(
      params => {
        this.id = +params['id'];
        if(this.id){
          console.log('IDDDD: ' + this.id);
          this.form.patchValue({ numero_beneficiario: this.id});
          this.getDatosClinicosForm();
          this.getConsultaDatosHistorialClinico(this.id);
          

        }
      });
    /*fin modificar*/
  }


  public llenarCampos(){
    if(this.form.value.antropometria_peso == ''){
      this.form.patchValue({
        antropometria_peso: 0
      });
    }

    if(this.form.value.talla_m == ''){
      this.form.patchValue({
        talla_m: 0
      });
    }

   

    if(this.form.value.signos_presion   == ''){
      this.form.patchValue({
        signos_presion: '000/000'
      });
    }

    if(this.form.value.o_sat == ''){
      this.form.patchValue({
        o_sat: 0
      });
    }

    if(this.form.value.frecuencia == ''){
      this.form.patchValue({
        frecuencia: 0
      });
    }

    if(this.form.value.frecuencia_rpm == ''){
      this.form.patchValue({
        frecuencia_rpm: 0
      });
    }

    if(this.form.value.temperatura == ''){
      this.form.patchValue({
        temperatura: 0
      });
    }

   


  }

  /*

  imc: new UntypedFormControl('', []),
    signos_presion: new UntypedFormControl('', [ Validators.pattern('^\\d{3}/\\d{3}$')]),
    o_sat: new UntypedFormControl('', [ Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]),
    frecuencia: new UntypedFormControl('', [ Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]),
    frecuencia_rpm: new UntypedFormControl('', [ Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]),
    temperatura: new UntypedFormControl('', [ Validators.pattern('^\\d{1,2}(\\.\\d{1})?$')]),

    */

  //guardar

  public setVaciosCampos(){
    if(this.form.value.aspec_general_esp == ''){
      this.form.patchValue({aspec_general_esp: '-'});
    }
    if(this.form.value.piel_mucosas_esp == ''){
      this.form.patchValue({piel_mucosas_esp: '-'});
    }
    if(this.form.value.cabeza_exp_esp == ''){
      this.form.patchValue({cabeza_exp_esp: '-'});
    }
    if(this.form.value.oidos_exp_esp == ''){
      this.form.patchValue({oidos_exp_esp: '-'});
    }
    if(this.form.value.ojos_exp_esp == ''){
      this.form.patchValue({ojos_exp_esp: '-'});
    }
    if(this.form.value.nariz_exp_esp == ''){
      this.form.patchValue({nariz_exp_esp: '-'});
    }
    if(this.form.value.boca_exp_esp == ''){
      this.form.patchValue({boca_exp_esp: '-'});
    }
    if(this.form.value.garganta_exp_esp == ''){
      this.form.patchValue({garganta_exp_esp: '-'});
    }
    if(this.form.value.cuello_exp_esp == ''){
      this.form.patchValue({cuello_exp_esp: '-'});
    }
    if(this.form.value.torax_exp_esp == ''){
      this.form.patchValue({torax_exp_esp: '-'});
    }
    if(this.form.value.abdomen_exp_esp == ''){
      this.form.patchValue({abdomen_exp_esp: '-'});
    }
    if(this.form.value.extremidades_exp_esp == ''){
      this.form.patchValue({extremidades_exp_esp: '-'});
    }
    if(this.form.value.neurologico_ext_esp == ''){
      this.form.patchValue({neurologico_ext_esp: '-'});
    }




   

  }

  public postRegistroHistorialClinicoDos(){
    //this.llenarCampos();
    this.setVaciosCampos();
    console.log(this.form.value);
    this.isLoading = true;

    this.form.patchValue({
      imc: +(this.form.value.antropometria_peso / (this.form.value.talla_m * this.form.value.talla_m))?.toFixed(4),
      //fecha_evento_incluida: this.form.value.fecha_evento_incluida.split('T')[0]+' '+this.form.value.fecha_evento_incluida.split('T')[1]
    })

    //this.form.value.puesto_reporta = this.form.value.puesto_reporta.toString();
    //this.form.value.numero_departamento = this.form.value.numero_departamento.toString();
    //this.form.value.numero_puesto = this.form.value.numero_puesto.toString();
    this._ClinicoService.postRegistroHistorialClinicoDos(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postRegistroBeneficiarioEspecialista(){
    //this.setProtesis();
    console.log(this.form.value);
    this.isLoading = true;
    
    //this.form.value.puesto_reporta = this.form.value.puesto_reporta.toString();
    //this.form.value.numero_departamento = this.form.value.numero_departamento.toString();
    //this.form.value.numero_puesto = this.form.value.numero_puesto.toString();
    //this.form.patchValue({ numero_especialista: this.form.value.numero_especialista });
    this._ClinicoService.postRegistroBeneficiarioEspecialista(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({ numero_especialista: '' });
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
        this.form.patchValue({
          numero_especialista: ''
        })
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
        this.form.patchValue({
          numero_especialista: ''
        })
      });
  }

  public postModificarBeneficiarioEspecialista(id_ben: number, num_esp: number, num_esp_reg: number){
    //this.setProtesis();
    console.log(this.form.value);
    this.isLoading = true;
    //this.form.value.puesto_reporta = this.form.value.puesto_reporta.toString();
    //this.form.value.numero_departamento = this.form.value.numero_departamento.toString();
    //this.form.value.numero_puesto = this.form.value.numero_puesto.toString();
    //this.form.patchValue({ numero_especialista: this.form.value.numero_especialista });
    this._ClinicoService.postModificarBeneficiarioEspecialista({numero_beneficiario: id_ben, numero_especialista: num_esp, numero_especialista_reg: num_esp_reg}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({ numero_especialista: '' });
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postEliminarBeneficiarioEspecialista(num_esp_reg: number){
    //this.setProtesis();
    console.log(this.form.value);
    this.isLoading = true;
    //this.form.value.puesto_reporta = this.form.value.puesto_reporta.toString();
    //this.form.value.numero_departamento = this.form.value.numero_departamento.toString();
    //this.form.value.numero_puesto = this.form.value.numero_puesto.toString();
    //this.form.patchValue({ numero_especialista: this.form.value.numero_especialista });
    this._ClinicoService.postEliminarBeneficiarioEspecialista({numero_especialista_reg: num_esp_reg}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.form.patchValue({ numero_especialista: '' });
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaDatosHistorialClinico(this.id);
          //this._router.navigate(['medico/expedientes-medicos/gestor-expedientes-medicos']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }
  //fin guardar

  public getDatosClinicosForm(){
    console.log('asjsajsdhas');
    this.isLoading = true;
    this._ClinicoService.getDatosClinicosForm().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.especialista_catalogo = response.data.especialista_catalogo;

          this.especialista_catalogo = this.ordenarAlfabeticamente(this.especialista_catalogo, 'especialista');
          //this.especialista_catalogo = this.ordenarConOtrosAlFinal(this.especialista_catalogo, 'especialista');
          console.log(this.especialista_catalogo );

          //this.descripcion_puesto = response.data;
          
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }


  public ordenarAlfabeticamente(jsonArray, propiedad) {
    var posicionOtros = -1;
    /*for (var i = 0; i < jsonArray.length; i++) {
        if (jsonArray[i][propiedad] === "Otro/s:") {
            posicionOtros = i;
            break;
        }
    }
    if (posicionOtros != -1) {
        var elementoOtros = jsonArray.splice(posicionOtros, 1)[0];
    }*/
    jsonArray = jsonArray.sort(function(a, b) {
        var nameA = a[propiedad].toUpperCase(); 
        var nameB = b[propiedad].toUpperCase(); 
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    //if (posicionOtros != -1) {
    //    jsonArray.unshift(elementoOtros);
    //}
    return jsonArray;
}


public ordenarConOtrosAlFinal(jsonArray, propiedad) {
  var elementosOtros = jsonArray.filter(function(elemento) {
    return elemento[propiedad].toLowerCase().includes("otro")
});
var arrayFiltrado = jsonArray.filter(function(elemento) {
    return !elemento[propiedad].toLowerCase().includes("otro")
});
arrayFiltrado = arrayFiltrado.sort(function(a, b) {
    var nombreA = a[propiedad].toUpperCase(); // ignore upper and lowercase
    var nombreB = b[propiedad].toUpperCase(); // ignore upper and lowercase
    if (nombreA < nombreB) {
        return -1;
    }
    if (nombreA > nombreB) {
        return 1;
    }
    return 0;
});
arrayFiltrado = arrayFiltrado.concat(elementosOtros);
return arrayFiltrado;
}




  public getConsultaDatosHistorialClinico(id: number){
    console.log('asjsajsdhas');
    this.isLoading = true;
    this._ClinicoService.getConsultaDatosHistorialClinico(id).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.especialistas = response.data.beneficiario_especialista;
          if(response.data.historial_clinico_dos){
            this.setForm(response.data.historial_clinico_dos);
          }
          
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public setForm(data: any){
    this.form.patchValue({
      tegumentos: data.tegumentos,
      ojos: data.ojos,
      odios: data.odios,
      nari: data.nari,
      boca: data.boca,
      sistema_nervioso: data.sistema_nervioso,
      respiratorio: data.respiratorio,
      cardio: data.cardio,
      digestivo: data.digestivo,
      hematologo: data.hematologo,
      genitouriano: data.genitouriano,
      musculo: data.musculo,
      endcrino: data.endcrino,
      gineco: data.gineco,
      psiqui: data.psiqui,


      en_caso_espe: data.en_caso_espe,


      antropometria_peso: data.antropometria_peso,
      talla_m: data.talla_m,
      imc: data.imc,
      signos_presion: data.signos_presion,
      o_sat: data.o_sat,
      frecuencia: data.frecuencia,
      frecuencia_rpm: data.frecuencia_rpm,
      temperatura: data.temperatura,

      aspec_general: data.aspec_general,
      aspec_general_esp: data.aspec_general_esp,
      piel_mucosas: data.piel_mucosas,
      piel_mucosas_esp: data.piel_mucosas_esp,
      cabeza_exp: data.cabeza_exp,
      cabeza_exp_esp: data.cabeza_exp_esp,
      oidos_exp: data.oidos_exp,
      oidos_exp_esp: data.oidos_exp_esp,
      ojos_exp: data.ojos_exp,
      ojos_exp_esp: data.ojos_exp_esp,
      nariz_esp: data.nariz_esp,
      nariz_exp_esp: data.nariz_exp_esp,
      boca_exp: data.boca_exp,
      boca_exp_esp: data.boca_exp_esp,
      garganta_exp: data.garganta_exp,
      garganta_exp_esp: data.garganta_exp_esp,
      cuello_exp: data.cuello_exp,
      cuello_exp_esp: data.cuello_exp_esp,
      torax_exp: data.torax_exp,
      torax_exp_esp: data.torax_exp_esp,
      abdomen_exp: data.abdomen_exp,
      abdomen_exp_esp: data.abdomen_exp_esp,
      extremidades_exp: data.extremidades_exp,
      extremidades_exp_esp: data.extremidades_exp_esp,
      neurologico_exp: data.neurologico_exp,
      neurologico_ext_esp: data.neurologico_ext_esp,

      tipo_aten_medico: data.tipo_aten_medico,
      diagnostico_permanente: data.diagnostico_permanente,

      obervaciones_text: data.obervaciones_text,

    });
  }

}
