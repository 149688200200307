

<div class="container-fluid">
    <mat-card >
        <mat-card-content>
            <div class="spinner" *ngIf="reie_isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div class="row" *ngIf="data?.ruta_relativa">

                

                <div class="d-flex flex-column justify-content-center align-items-center">
                    <img src="{{laravelapiStorage}}/{{data?.ruta_relativa+'?t='+t}}" alt="img-exp-inf-emp" width="200rem" height="auto">
                    <br>
                    <button *ngIf="id_exp" (click)="postEliminarFotoExpediente(id_exp)" type="button" class="btn btn-small btn-danger" >x</button>
                </div>
                
            </div>
            <div class="row ps-5 pe-5 mt-3 pb-3">
                <span class="text-center"><strong>ADJUNTAR FOTO DEL EMPLEADO</strong></span>    
                <div class="d-flex justify-content-center" >
                   
                    <input  type="file"  class="form-control" (change)="cargarFoto($event)">
                    
                </div>
                
                    
                    <!--<a  mat-flat-button color="primary" href="{{laravelapiStorage}}/{{data?.ruta_relativa+'?t='+t}}" target="_blank" rel=noopener>
                        Cargar cartilla PDF
                        <mat-icon class="material-icons">
                            file_open
                        </mat-icon>
                    </a>-->
                
            </div>

            <!--<div >
                <button (click)="postRegistroFotoExpediente()">
                    dfndfn
                </button> 
            </div>-->


            <form novalidate [formGroup]="reie_form" autocomplete="off">
                <!-- parte 1 -->
                <span><strong>I. INFORMACIÓN GENERAL</strong></span>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>NOMBRE</mat-label>
                            <input type="text" matInput formControlName="nombre">
                            <mat-error *ngIf="reie_form.controls['nombre'].hasError('required')">
                                El campo es requerido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>APELLIDO PATERNO</mat-label>
                            <input type="text" matInput formControlName="apellidop">
                            <mat-error *ngIf="reie_form.controls['apellidop'].hasError('required')">
                                El campo es requerido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>APELLIDO MATERNO</mat-label>
                            <input type="text" matInput formControlName="apellidom">
                            <mat-error *ngIf="reie_form.controls['apellidom'].hasError('required')">
                                El campo es requerido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>FECHA DE NACIMIENTO</mat-label>
                            <input type="date" matInput formControlName="fecha_nacimiento">
                            <mat-error *ngIf="reie_form.controls['fecha_nacimiento'].hasError('required')">
                                El campo es requerido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>AñOS CUMPLIDOS</mat-label>
                            <input type="text" matInput [value]="getEdad()" readonly="true">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                   
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>LUGAR DE NACIMIENTO</mat-label>
                            <mat-select formControlName="numero_estado_republica" (ngModelChange)="getMunicipios()">
                                <mat-option *ngFor="let p of estados_republica" [value]="p.id_estado_republica_pk">{{p.estado_republica}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="reie_form.controls['numero_estado_republica'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>MUNICIPIO</mat-label>
                            <mat-select formControlName="numero_municipio">
                                <mat-option *ngFor="let p of municipios" [value]="p.id_municipios_pk">{{p.nombre}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="reie_form.controls['numero_municipio'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>COLONIA</mat-label>
                            <input type="text" matInput  maxlength="150" #colonia formControlName="colonia">
                            <mat-error *ngIf="reie_form.controls['colonia'].hasError('maxlength')">
                                El máximo tamaño es de 150 caracteres
                            </mat-error>
                            <mat-hint align="end">{{colonia.value.length}} / 150</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>CALLE</mat-label>
                            <input type="text" matInput  maxlength="150" #direccion formControlName="direccion">
                            <mat-error *ngIf="reie_form.controls['direccion'].hasError('maxlength')">
                                El máximo tamaño es de 150 caracteres
                            </mat-error>
                            <mat-hint align="end">{{direccion.value.length}} / 150</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col" *ngIf="reie_form.value.numero_estado_republica == 33">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>Otro</mat-label>
                            <input type="text" matInput  maxlength="150" #otro formControlName="otro">
                            <mat-error *ngIf="reie_form.controls['otro'].hasError('maxlength')">
                                El máximo tamaño es de 150 caracteres
                            </mat-error>
                            <mat-hint align="end">{{otro.value.length}} / 150</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <!--otra linea -->
                <div class="row">
                    
                  
                   
                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>CP</mat-label>
                            <input type="text" matInput  maxlength="5" #cp formControlName="cp">
                            <mat-error *ngIf="reie_form.controls['cp'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['cp'].hasError('pattern')">
                                Inválido formato
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['cp'].hasError('minlength')">
                                El máximo tamaño es de 5 caracteres
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['cp'].hasError('maxlength')">
                                El máximo tamaño es de 5 caracteres
                            </mat-error>
                            <mat-hint align="end">{{cp.value.length}} / 5</mat-hint>
                        </mat-form-field>
                    </div>
              
                    <div class="col-sm-12 col-xs-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>TÉLEFONO</mat-label>
                            <input type="text" matInput  maxlength="10" #telefono formControlName="telefono">
                            <mat-error *ngIf="reie_form.controls['telefono'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['telefono'].hasError('pattern')">
                                Inválido formato
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['telefono'].hasError('maxlength')">
                                El máximo tamaño es de 10 caracteres
                            </mat-error>
                            <mat-hint align="end">{{telefono.value.length}} / 10</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-xs-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>CELULAR</mat-label>
                            <input type="text" matInput  maxlength="10" #celular formControlName="celular">
                            <mat-error *ngIf="reie_form.controls['celular'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['celular'].hasError('pattern')">
                                Inválido formato
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['celular'].hasError('maxlength')">
                                El máximo tamaño es de 10 caracteres
                            </mat-error>
                            <mat-hint align="end">{{telefono.value.length}} / 10</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>CURP</mat-label>
                            <input type="text" matInput  maxlength="18" #curp formControlName="curp">
                            <mat-error *ngIf="reie_form.controls['curp'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['curp'].hasError('maxlength')">
                                El máximo tamaño es de 18 caracteres
                            </mat-error>
                            <mat-hint align="end">{{curp.value.length}} / 18</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>MAIL</mat-label>
                            <input type="text" matInput  formControlName="correo">
                            <mat-error *ngIf="reie_form.controls['correo'].hasError('email')">
                                Inválido formato
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <!-- otra linea -->
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>RFC</mat-label>
                            <input type="text" matInput  maxlength="13" #rfc formControlName="rfc">
                            <mat-error *ngIf="reie_form.controls['rfc'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['rfc'].hasError('minlength')">
                                El máximo tamaño es de 13 caracteres
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['rfc'].hasError('maxlength')">
                                El máximo tamaño es de 13 caracteres
                            </mat-error>
                            <mat-hint align="end">{{rfc.value.length}} / 13</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <!-- <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>REGIMÉN</mat-label>
                            <input type="text" matInput  maxlength="60" #regimen formControlName="regimen">
                            <mat-error *ngIf="reie_form.controls['regimen'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['regimen'].hasError('minlength')">
                                El máximo tamaño es de 60 caracteres
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['regimen'].hasError('maxlength')">
                                El máximo tamaño es de 60 caracteres
                            </mat-error>
                            <mat-hint align="end">{{regimen.value.length}} / 60</mat-hint>
                        </mat-form-field> -->

                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>REGIMÉN</mat-label>
                            <mat-select formControlName="regimen">
                                <mat-option *ngFor="let p of regimen" [value]="p">{{p}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="reie_form.controls['regimen'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>#SS</mat-label>
                            <input type="text" matInput  maxlength="20" #ss formControlName="ss">
                            <mat-error *ngIf="reie_form.controls['ss'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['ss'].hasError('maxlength')">
                                El máximo tamaño es de 20 caracteres
                            </mat-error>
                            <mat-hint align="end">{{ss.value.length}} / 20</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>NÚMERO DE INFONAVIT</mat-label>
                            <input type="text" matInput  maxlength="20" #infonativ formControlName="infonativ">
                            <mat-error *ngIf="reie_form.controls['infonativ'].hasError('maxlength')">
                                El máximo tamaño es de 20 caracteres
                            </mat-error>
                            <mat-hint align="end">{{infonativ.value.length}} / 20</mat-hint>
                        </mat-form-field>
                    </div>
                    
                </div>

                <!-- parte 2 -->
                <span><strong>II. DATOS DEL PUESTO</strong></span>
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>NOMBRE DEL PUESTO</mat-label>
                            <mat-select formControlName="numero_reporte">
                                <mat-option *ngFor="let p of gestor_puestos" [value]="p.id_puesto_reporte_pk">{{p.puestos_reporte}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="reie_form.controls['numero_reporte'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>TIPO DE PUESTO</mat-label>
                            <mat-select formControlName="numero_tipo_puesto">
                                <mat-option *ngFor="let p of tipo_puesto" [value]="p.id_tipo_puesto_pk">{{p.tipo_puesto}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="reie_form.controls['numero_tipo_puesto'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>DEPARTAMENTO</mat-label>
                            <mat-select formControlName="id_departamento_fk">
                                <mat-option *ngFor="let p of departamento" [value]="p.id_departamento_pk">{{p.departamento}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="reie_form.controls['id_departamento_fk'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>FECHA DE INGRESO</mat-label>
                            <input type="date" matInput formControlName="fecha_ingreso">
                            <mat-error *ngIf="reie_form.controls['fecha_ingreso'].hasError('required')">
                                El campo es requerido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>TIEMPO LABORANDO</mat-label>
                            <input type="text" matInput [value]="getTiempoLaborando()" readonly="true">
                        </mat-form-field>
                    </div>
                </div>
                <!-- otra linea -->
                <div class="row" >
					<div class="col">
						<mat-form-field appearance="fill" class="input-full-w">
							<mat-label>Salario neto semanal</mat-label>
							<input type="text" formControlName="salario_aux" matInput [errorStateMatcher]="matcher">
						</mat-form-field>
					</div>
					<div class="col">
						<mat-form-field appearance="fill" class="input-full-w">
							<mat-label>Fecha de asignación</mat-label>
							<input type="date" formControlName="fecha_asignacion_aux" matInput [errorStateMatcher]="matcher">
						</mat-form-field>
					</div>
                    <div class="col">
                        <button type="button" color="primary"  mat-mini-fab (click)="agregarSalario()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
                <!-- otra linea -->
                <div class="row">
                    <div formArrayName="salarios" *ngFor="let m of $any(get_salarios()).controls; let i = index;" class="pt-5">
						<div [formGroupName]="i">
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill" class="input-full-w">
                                        <mat-label>Salario otorgado</mat-label>
                                        <input type="text" formControlName="salario" matInput [errorStateMatcher]="matcher">
                                        <mat-error *ngIf="m.get('salario').hasError('required')">
                                            El campo es obligatorio
                                        </mat-error>
                                        <mat-error *ngIf="m.get('salario').hasError('pattern')">
                                            Inválido formato
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="input-full-w">
                                        <mat-label>Fecha de asignación</mat-label>
                                        <input type="date" formControlName="fecha_asignacion" matInput [errorStateMatcher]="matcher">
                                        <mat-error *ngIf="m.get('fecha_asignacion').hasError('required')">
                                            El campo es obligatorio
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <button type="button" class="btn btn-small btn-danger" (click)="quitSalario()">
                                        x
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- otra linea -->
                <div class="row">
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>OBSERVACIONES</mat-label>
                        <textarea matInput type="text" rows="3"  maxlength="500" #observaciones formControlName="observaciones" [errorStateMatcher]="matcher"></textarea>
                        <mat-error *ngIf="reie_form.controls['observaciones'].hasError('maxlength')">
                            El maximo tamaño es de 500 caracteres
                        </mat-error>
                        <mat-hint align="end">{{observaciones.value.length}} / 500</mat-hint>
                    </mat-form-field>
                </div>
                <!-- otra linea -->
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>JORNADA LABORAL DE:</mat-label>
                                    <input type="time" matInput formControlName="jornada_inicio" [errorStateMatcher]="matcher">
                                    <mat-error *ngIf="reie_form.controls['jornada_inicio'].hasError('required')">
                                        El campo es requerido
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>JORNADA LABORAL A:</mat-label>
                                    <input type="time" matInput formControlName="jornada_fin" [errorStateMatcher]="matcher">
                                    <mat-error *ngIf="reie_form.controls['jornada_fin'].hasError('required')">
                                        El campo es requerido
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    {{helperDiasLaboralesDescanso()}}
                    <div class="col">   
                        <span><strong>DÍAS LABORALES</strong></span>
                        <table class="table table-sm table-bordered">
                            <caption></caption>
                            <thead class="web-bg-primario">
                                <tr>
                                    <th  scope="col">L</th>
                                    <th  scope="col">M</th>
                                    <th  scope="col">M</th>
                                    <th  scope="col">J</th>
                                    <th  scope="col">V</th>
                                    <th  scope="col">S</th>
                                    <th  scope="col">D</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><mat-checkbox formControlName="dl_l"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dl_ma"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dl_mi"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dl_j"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dl_v"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dl_s"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dl_d"></mat-checkbox></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col">
                        <span><strong>DÍAS DE DESCANSO</strong></span>
                        <table class="table table-sm table-bordered">
                            <caption></caption>
                            <thead class="web-bg-primario">
                                <tr>
                                    <th  scope="col">L</th>
                                    <th  scope="col">M</th>
                                    <th  scope="col">M</th>
                                    <th  scope="col">J</th>
                                    <th  scope="col">V</th>
                                    <th  scope="col">S</th>
                                    <th  scope="col">D</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><mat-checkbox formControlName="dd_l"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dd_ma"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dd_mi"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dd_j"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dd_v"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dd_s"></mat-checkbox></td>
                                    <td><mat-checkbox formControlName="dd_d"></mat-checkbox></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- otra linea -->
                <div class="row">
                    <mat-form-field appearance="fill" class="input-full-w">
                        <mat-label>OBSERVACIONES</mat-label>
                        <textarea matInput type="text" rows="3"  maxlength="500" #observacion_renumerado formControlName="observacion_renumerado" [errorStateMatcher]="matcher"></textarea>
                        <mat-error *ngIf="reie_form.controls['observacion_renumerado'].hasError('maxlength')">
                            El maximo tamaño es de 500 caracteres
                        </mat-error>
                        <mat-hint align="end">{{observacion_renumerado.value.length}} / 500</mat-hint>
                    </mat-form-field>
                </div>
                <!-- otra linea -->
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>ESCOLARIDAD GRADO</mat-label>
                            <mat-select formControlName="numero_escolaridad" >
                                <mat-option *ngFor="let e of escolaridad_all" [value]="e.id_escolaridad_pk">{{e.escolaridad}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="reie_form.controls['numero_escolaridad'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>ESCOLARIDAD ESTADO</mat-label>
                            <mat-select formControlName="numero_estado_escolaridad">
                                <mat-option *ngFor="let p of estatus_escolarida" [value]="p.id_escolaridad_pk">{{p.estado_escolaridad}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="reie_form.controls['numero_estado_escolaridad'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w">
                            <mat-label>CARRERA / ESPECIALIDAD</mat-label>
                            <input type="text" matInput  maxlength="50" #escolaridad formControlName="escolaridad" [errorStateMatcher]="matcher">
                            <mat-error *ngIf="reie_form.controls['escolaridad'].hasError('maxlength')">
                                El máximo tamaño es de 50 caracteres
                            </mat-error>
                            <mat-hint align="end">{{escolaridad.value.length}} / 50</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <!-- parte 3 -->
                <span class="mt-3"><strong>III. TARJETA DE NÓMINA</strong></span>
                <div class="row">
                    <mat-radio-group aria-label="Condición relevante" formControlName="generica_propia">
                        <mat-radio-button value="1">Genérica</mat-radio-button>
                        <mat-radio-button value="2">Propia</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="row" *ngIf="reie_form.value.generica_propia == 1 || reie_form.value.generica_propia == '1'">
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>ESTADO CIVIL</mat-label>
                            <mat-select formControlName="numero_estado_civil">
                                <mat-option *ngFor="let p of estado_civil" [value]="p.id_estado_civil_pk">{{p.estado_civil}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="reie_form.controls['numero_estado_civil'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>RÉGIMEN</mat-label>
                            <mat-select formControlName="numero_regimen">
                                <mat-option *ngFor="let p of regimenAll" [value]="p.id_regimen_pk">{{p.regimen}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="reie_form.controls['numero_regimen'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>BENEFICIARIO</mat-label>
                                    <input type="text" matInput  maxlength="100" #baneficiario_aux formControlName="baneficiario_aux" [errorStateMatcher]="matcher">
                                    <mat-error *ngIf="reie_form.controls['baneficiario_aux'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                    <mat-error *ngIf="reie_form.controls['baneficiario_aux'].hasError('maxlength')">
                                        El máximo tamaño es de 100 caracteres
                                    </mat-error>
                                    <mat-hint align="end">{{baneficiario_aux.value.length}} / 100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>PARENTESCO</mat-label>
                                    <input type="text" matInput  maxlength="100" #parentesco_aux formControlName="parentesco_aux" [errorStateMatcher]="matcher">
                                    <mat-error *ngIf="reie_form.controls['parentesco_aux'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                    <mat-error *ngIf="reie_form.controls['parentesco_aux'].hasError('maxlength')">
                                        El máximo tamaño es de 100 caracteres
                                    </mat-error>
                                    <mat-hint align="end">{{parentesco_aux.value.length}} / 100</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>RFC BENEFICIARIO</mat-label>
                                    <input type="text" matInput  maxlength="13" #rfc_aux formControlName="rfc_aux" [errorStateMatcher]="matcher">
                                    <mat-error *ngIf="reie_form.controls['rfc_aux'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                    <mat-error *ngIf="reie_form.controls['rfc_aux'].hasError('minlength')">
                                        El máximo tamaño es de 13 caracteres
                                    </mat-error>
                                    <mat-error *ngIf="reie_form.controls['rfc_aux'].hasError('maxlength')">
                                        El máximo tamaño es de 13 caracteres
                                    </mat-error>
                                    <mat-hint align="end">{{rfc_aux.value.length}} / 13</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>%</mat-label>
                                    <input type="text" matInput  maxlength="3" #porcentaje_aux formControlName="porcentaje_aux" [errorStateMatcher]="matcher">
                                    <mat-error *ngIf="reie_form.controls['porcentaje_aux'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                    <mat-error *ngIf="reie_form.controls['porcentaje_aux'].hasError('maxlength')">
                                        El máximo tamaño es de 3 caracteres
                                    </mat-error>
                                    <mat-hint align="end">{{porcentaje_aux.value.length}} / 3</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="fill"  class="input-full-w">
                                    <mat-label>DIRECCIÓN BENEFICIARIO</mat-label>
                                    <input type="text" matInput  maxlength="200" #direccion_aux formControlName="direccion_aux" [errorStateMatcher]="matcher">
                                    <mat-error *ngIf="reie_form.controls['direccion_aux'].hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                    <mat-error *ngIf="reie_form.controls['direccion_aux'].hasError('maxlength')">
                                        El máximo tamaño es de 200 caracteres
                                    </mat-error>
                                    <mat-hint align="end">{{direccion_aux.value.length}} / 200</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1 col-xl-1 col-xxl-1">
                        <button *ngIf="reie_accion == 'registrar'" type="button" color="primary"  mat-mini-fab (click)="agregarBeneficiarios()">
                            <mat-icon>add</mat-icon>
                        </button>

                        <button *ngIf="reie_accion == 'modificar'" type="button" color="primary"  mat-mini-fab  (click)="postAgregarTarjetaBeneficiarios()">
                            <mat-icon>add</mat-icon>
                        </button>

                       
                    </div>
                </div>
                <!-- otra linea -->
                <div class="row">
                    <div formArrayName="tarjeta_beneficiarios" *ngFor="let m of $any(get_tarjeta_beneficiarios()).controls; let i = index;" class="pt-5">
						<div [formGroupName]="i">
                            <div class="row">
                                
                                <!--<div class="col">
                                    <mat-form-field appearance="fill" class="input-full-w">
                                        <mat-label>Salario otorgado</mat-label>
                                        <input type="text" formControlName="salario" matInput [errorStateMatcher]="matcher">
                                        <mat-error *ngIf="m.get('salario').hasError('required')">
                                            El campo es obligatorio
                                        </mat-error>
                                        <mat-error *ngIf="m.get('salario').hasError('pattern')">
                                            Inválido formato
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="input-full-w">
                                        <mat-label>Fecha de asignación</mat-label>
                                        <input type="date" formControlName="fecha_asignacion" matInput [errorStateMatcher]="matcher">
                                        <mat-error *ngIf="m.get('fecha_asignacion').hasError('required')">
                                            El campo es obligatorio
                                        </mat-error>
                                    </mat-form-field>
                                </div>-->
                                <div class="col">
                                    <div class="row">

                                    
                                        <div class="col">
                                            <mat-form-field appearance="fill"  class="input-full-w">
                                                <mat-label>BENEFICIARIO</mat-label>
                                                <input type="text" matInput  maxlength="100" #baneficiario formControlName="baneficiario" [errorStateMatcher]="matcher">
                                                <mat-error *ngIf="reie_form.controls['baneficiario'].hasError('required')">
                                                    El campo es obligatorio
                                                </mat-error>
                                                <mat-error *ngIf="reie_form.controls['baneficiario'].hasError('maxlength')">
                                                    El máximo tamaño es de 100 caracteres
                                                </mat-error>
                                                <mat-hint align="end">{{baneficiario.value.length}} / 100</mat-hint>
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="fill"  class="input-full-w">
                                                <mat-label>PARENTESCO</mat-label>
                                                <input type="text" matInput  maxlength="100" #parentesco formControlName="parentesco" [errorStateMatcher]="matcher">
                                                <mat-error *ngIf="reie_form.controls['parentesco'].hasError('required')">
                                                    El campo es obligatorio
                                                </mat-error>
                                                <mat-error *ngIf="reie_form.controls['parentesco'].hasError('maxlength')">
                                                    El máximo tamaño es de 100 caracteres
                                                </mat-error>
                                                <mat-hint align="end">{{parentesco.value.length}} / 100</mat-hint>
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="fill"  class="input-full-w">
                                                <mat-label>RFC BENEFICIARIO</mat-label>
                                                <input type="text" matInput  maxlength="13" #rfc formControlName="rfc" [errorStateMatcher]="matcher">
                                                <mat-error *ngIf="reie_form.controls['rfc'].hasError('required')">
                                                    El campo es obligatorio
                                                </mat-error>
                                                <mat-error *ngIf="reie_form.controls['rfc'].hasError('minlength')">
                                                    El máximo tamaño es de 13 caracteres
                                                </mat-error>
                                                <mat-error *ngIf="reie_form.controls['rfc'].hasError('maxlength')">
                                                    El máximo tamaño es de 13 caracteres
                                                </mat-error>
                                                <mat-hint align="end">{{rfc.value.length}} / 13</mat-hint>
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="fill"  class="input-full-w">
                                                <mat-label>%</mat-label>
                                                <input type="text" matInput  maxlength="3" #porcentaje formControlName="porcentaje" [errorStateMatcher]="matcher">
                                                <mat-error *ngIf="reie_form.controls['porcentaje'].hasError('required')">
                                                    El campo es obligatorio
                                                </mat-error>
                                                <mat-error *ngIf="reie_form.controls['porcentaje'].hasError('maxlength')">
                                                    El máximo tamaño es de 3 caracteres
                                                </mat-error>
                                                <mat-hint align="end">{{porcentaje.value.length}} / 3</mat-hint>
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="fill"  class="input-full-w">
                                                <mat-label>DIRECCIÓN BENEFICIARIO</mat-label>
                                                <input type="text" matInput  maxlength="200" #direccion formControlName="direccion" [errorStateMatcher]="matcher">
                                                <mat-error *ngIf="reie_form.controls['direccion'].hasError('required')">
                                                    El campo es obligatorio
                                                </mat-error>
                                                <mat-error *ngIf="reie_form.controls['direccion'].hasError('maxlength')">
                                                    El máximo tamaño es de 200 caracteres
                                                </mat-error>
                                                <mat-hint align="end">{{direccion.value.length}} / 200</mat-hint>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>



                                
                                <div class="col-xs-2 col-sm-2 col-md-2 col-xl-2 col-xxl-2">
                                    <div class="row">
                                        <div class="col">
                                            <button *ngIf="reie_accion == 'modificar'" type="button" color="primary"  mat-flat-button  (click)="postModificarTarjetaBeneficiarios(m)">
                                                Modificar
                                            </button>
                                            &nbsp;
                                            <button *ngIf="reie_accion == 'registrar'" type="button" class="btn btn-small btn-danger" (click)="quitBeneficiario(i)">
                                                x
                                            </button>

                                            &nbsp;
                                            <button *ngIf="reie_accion == 'modificar'" type="button" class="btn btn-small btn-danger" (click)="postEliminarTarjetaBeneficiarios(m)">
                                                x
                                            </button>



                                            
                                        </div>
                                    </div>
                                    
                                    
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- otra linea -->



                
                <!-- parte 4 -->
                <br>
                <span class="mt-3"><strong>IV. BAJA DEL EMPLEADO</strong></span>
                <div class="row mt-3">
                    <div class="col">
                        <mat-checkbox formControlName="baja_empleado" (ngModelChange)="bajaEmpleadoEvento()">Baja del empleado</mat-checkbox>
                    </div>
                    <div class="col" *ngIf="reie_form.value.baja_empleado ==  true || reie_form.value.baja_empleado ==  1">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>MOTIVO DE SALIDA</mat-label>
                            <mat-select formControlName="numero_baja" >
                                <mat-option *ngFor="let e of estado_baja" [value]="e.id_tipo_baja_pk">{{e.tipo_baja}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="reie_form.controls['numero_baja'].hasError('required')">
                                El campo es obligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col" class="col" *ngIf="reie_form.value.baja_empleado ==  true || reie_form.value.baja_empleado ==  1">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>OBSERVACIONES</mat-label>
                            <textarea matInput type="text" rows="3"  maxlength="500" #descripcion formControlName="descripcion" [errorStateMatcher]="matcher"></textarea>
                            <mat-error *ngIf="reie_form.controls['descripcion'].hasError('required')">
                                El campo es requerido
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['descripcion'].hasError('maxlength')">
                                El maximo tamaño es de 500 caracteres
                            </mat-error>
                            <mat-hint align="end">{{descripcion.value.length}} / 500</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col" class="col" *ngIf="reie_form.value.baja_empleado ==  true || reie_form.value.baja_empleado ==  1">
                        <mat-form-field appearance="fill" class="input-full-w">
                            <mat-label>ÚLTIMO DÍA LABORANDO</mat-label>
                            <textarea matInput type="text" rows="3"  maxlength="500" #ultimo_laborando formControlName="ultimo_laborando" [errorStateMatcher]="matcher"></textarea>
                            <mat-error *ngIf="reie_form.controls['ultimo_laborando'].hasError('required')">
                                El campo es requerido
                            </mat-error>
                            <mat-error *ngIf="reie_form.controls['ultimo_laborando'].hasError('maxlength')">
                                El maximo tamaño es de 500 caracteres
                            </mat-error>
                            <mat-hint align="end">{{ultimo_laborando.value.length}} / 500</mat-hint>
                        </mat-form-field>
                    </div>
                </div>


            </form>

            <div class="mt-5 d-flex justify-content-center">
                <div *ngIf="reie_form.invalid">
                    <span class="text-danger">
                        Faltan campos por llenar
                    </span>
                </div>
            </div>

            <div class="mt-2 d-flex justify-content-center gap-2">


                

                <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-expedientes-de-personal']" >
                    Regresar
                </button>

                <form novalidate [formGroup]="reie_form" (ngSubmit)="guardar()" *ngIf="reie_accion == 'registrar'"> <!--*ngIf="reie_accion == 'registrar'"-->
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="reie_form.invalid">
                        Guardar
                    </button>
                </form>
                <form novalidate [formGroup]="reie_form" (ngSubmit)="modificar()" *ngIf="reie_accion == 'modificar'">
                    <button class="flat" color="primary" mat-flat-button type="submit" [disabled]="reie_form.invalid">
                        Guardar(Modificar)
                    </button>
                </form>
                <form novalidate [formGroup]="reie_form" *ngIf="reie_accion == 'modificar'">
                    <button class="flat" color="primary" mat-flat-button type="button" (click)="siguiente()">
                        Siguiente
                    </button>
                </form>


            </div>

        </mat-card-content>
    </mat-card>
</div>