//General
import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modals/message-box/message-box.component';
import { SesionService } from 'src/app/services/sesion.service';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { VacacionesService } from 'src/app/services/vacaciones.service';
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'app-ac-vaca',
  templateUrl: './ac-vaca.component.html',
  styleUrls: ['./ac-vaca.component.scss'],
  providers: [ VacacionesService ]
})
export class AcVacaComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    intervalos_vacaciones: new UntypedFormControl('', []),
    numero_datos_puestos: new UntypedFormControl(-1, []),
    estado_boton: new UntypedFormControl(1, []),
    comentarios: new UntypedFormControl('', [Validators.maxLength(250)]),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id_exp: any;
  public laravelapiStorage = this._VacacionesService.getLaravelApiStorage();
  public laravelapi = this._VacacionesService.getLaravelApi();
  public t: any;
  public data: any;
  public bene: any = [];
  public tts: any;
  public ds: string = '';
  public nds: number = 0;
  public anns: number = 0;
  public data_h: any;


  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _VacacionesService: VacacionesService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
    private _sesion:SesionService,
  ) {
    /*modificar*/
    this.form.markAllAsTouched();
    this._route.queryParams.subscribe(
      params => {
        this.id_exp = +params['id_exp'];
        console.log(this.id_exp);
        if(this.id_exp){
          this.getConsultaEmpleadoExpedienteVacacion(this.id_exp);
        }
      });
    /*fin modificar*/
   }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[4].split("?")[0];
    console.log(this.accion);
  }

  public getConsultaEmpleadoExpedienteVacacion(id: number){
    this.isLoading = true;
    this._VacacionesService.getConsultaEmpleadoExpedienteVacacion({numero_empleado_reg: id}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.data = response.data;
          this.form.patchValue({
            numero_datos_puestos: this.data?.empleado?.datos_puesto?.id_datos_puestos_pk
          });
          for(let k = 0; k < this.data?.lista_vacaciones.length ;k++){
            this.data.lista_vacaciones[k].model = [];
            if(!this.data.lista_vacaciones[k].comentarios){
              this.data.lista_vacaciones[k].comentarios = '';
            }

            for(let h = 0; h < this.data?.lista_vacaciones[k]?.intervalos_vacaciones?.split('/')?.length; h++){
              //console.log(this.data?.intervalos_vacaciones?.split('/')[h]);
              if(this.data?.intervalos_vacaciones?.split('/')[h] != ''){
                console.log(this.data?.intervalos_vacaciones?.split('/')[h]);
                this.data.lista_vacaciones[k].model.push(this.data?.lista_vacaciones[k]?.intervalos_vacaciones?.split('/')[h]);
              }
            }
          }
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public modelModificados(model){
    let cadena = '';
    for(let t = 0; t < model.length; t++){
      cadena = model[t].split('-')[2] + '/' + cadena;
    }
    return cadena;
  }

  public diasSeleccionados(){
    var y = '';
    for(let i = 0; i < this.model.length; i++){
      y = this.model[i].split('-')[2] + '/' + y;
    }
    this.ds = y;
  }

  public noDias(){
    this.nds = this.model.length;
  }

  public postRegistrarVacacion(){
    console.log(this.model);
    var g = '';
    for(let i = 0; i < this.model.length; i++){
      g = this.model[i] + '/' + g;
    } 
    console.log(g);
    this.form.patchValue({
      intervalos_vacaciones: g
    });
    console.log(this.form.value.intervalos_vacaciones.slice(0,-1));
    if(this.form.value?.intervalos_vacaciones[this.form.value?.intervalos_vacaciones?.length - 1] == '/'){
      console.log('entra');
      this.form.patchValue({
        intervalos_vacaciones: this.form.value.intervalos_vacaciones.slice(0,-1)
      });
      
    }

    this.isLoading = true;
    this._VacacionesService.postRegistrarVacacion(this.form.value).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.clearForm();
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getConsultaEmpleadoExpedienteVacacion(this.id_exp);
        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});

      });
  }

  public clearForm(){
    this.form.patchValue({
      intervalos_vacaciones: '',
      comentarios: ''
    });
    this.model = [];
  }




  @ViewChild('picker', { static: true }) _picker: MatDatepicker<Date>;

  public CLOSE_ON_SELECTED = false;
  public init = new Date('yyyy-mm-dd');
  public resetModel = new Date('2022-01-02');
  public model = [
  ];

  public dateClass = (date: Date, model: any) => {
    if (this._findDate(date, model) !== -1) {
      return [ 'selected' ];
    }
    return [ ];
  }

  public dateChanged(event: MatDatepickerInputEvent<Date>, model: any): void {
    console.log(event.value);
    var date = new Date(event.value);
    console.log(date.getFullYear());
    console.log(date.getMonth());
    console.log(date.getDay());
    var a = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
    //event.value = new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000)); 
    if (event.value) {
      const date: any = a;
      const index = this._findDate(date, model);
      if (index === -1) {
        model.push(date);
      } else {
        model.splice(index, 1)
      }
      this.resetModel = new Date('2022-01-02');
      if (!this.CLOSE_ON_SELECTED) {
        const closeFn = this._picker.close;
        this._picker.close = () => { };
        //this._picker['_popupComponentRef'].instance._calendar.monthView._createWeekCells()
        setTimeout(() => {
          this._picker.close = closeFn;
        });
      }
      console.log(model);
    }
  }

  public remove(date: Date, model: any): void {
    const index = this._findDate(date, model);
    model.splice(index, 1)
  }

  private _findDate(date: any, model: any): number {
    return model.map((m: any) => m).indexOf(date);
  }





  public getConsultarHistoricoVacacion(id: number, id2: number){
    this.isLoading = true;
    this._VacacionesService.getConsultarHistoricoVacacion(id, id2).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.data_h = response.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }



  

  public postModificarVacacion(q: any, estado: number = 0){
    console.log(q.model);
    var g = '';
    for(let i = 0; i < q.model.length; i++){
      g = q.model[i] + '/' + g;
    } 
    
    console.log(g);
    q.intervalos_vacaciones = g;
    q.estado_boton = q.id_estado_boton_fk;
    q.numero_vacacion = q.id_vacacion_empleado;
    console.log(q);
    console.log(q?.intervalos_vacaciones[q?.intervalos_vacaciones?.length - 1]);
    if(q?.intervalos_vacaciones[q?.intervalos_vacaciones?.length - 1] == '/'){
      q.intervalos_vacaciones = q.intervalos_vacaciones.slice(0, -1);
    }

    if(estado == 11){
      q.estado_boton = estado;
    }else{
      q.estado_boton = 1;
    }

    this.isLoading = true;
    this._VacacionesService.postModificarVacacion(q).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.getConsultaEmpleadoExpedienteVacacion(this.id_exp);
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
        }else{
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }


  public postSubirDocumentoVacacion(event: any, _id: number): any{

    
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      console.log(file.type);
      if (file.type.startsWith('application/pdf')) {//text/xml
        
        const formData = new FormData();
        formData.set('documento', file);
        formData.set('numero_vacacion',  _id.toString());
        this.isLoading = true;
        this._VacacionesService.postSubirDocumentoVacacion(formData).subscribe(
          response => {console.log(response);
            this.getConsultaEmpleadoExpedienteVacacion(this.id_exp);
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            
          },
          error => {console.log(<any>error);
            this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
            this.isLoading = false;
        });


      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato', icon:'error'}});
      }
    }  
  }



  public postSubirDocumentoPlaneacionVacaciones(event: any, _id: number, id2: number = null): any{

    
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      console.log(file.type);
      if (file.type.startsWith('application/pdf')) {//text/xml
        
        const formData = new FormData();
        formData.set('documento', file);
        formData.set('id_datos_puesto_fk',  _id.toString());

        if(id2 != null){
          formData.set('id_vaca_emp_doc_pk',  id2.toString());
        }

        this.isLoading = true;
        this._VacacionesService.postSubirDocumentoPlaneacionVacaciones(formData).subscribe(
          response => {console.log(response);
            this.getConsultaEmpleadoExpedienteVacacion(this.id_exp);
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
            
          },
          error => {console.log(<any>error);
            this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
            this.isLoading = false;
        });


      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Invalido formato', icon:'error'}});
      }
    }  
  }




  



  

  

}
